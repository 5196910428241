<template>
  <v-menu
    content-class="m-0 p-0"
    transition="scale-transition"
    offset-y
    bottom
    :left="!$vuetify.rtl"
    :right="$vuetify.rtl"
    rounded
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        v-on="on"
        v-bind="attrs"
        class="poppins btn btn--export-filter font-weight-bolder px-5"
      >
        <span class="svg-icon">
          <v-icon size="18">mdi-printer</v-icon>
        </span>
        <span>{{ $t("print") }}</span>
        <span class="svg-icon mr-0 ml-2">
          <v-icon size="18">mdi-chevron-down</v-icon>
        </span>
      </button>
    </template>
    <div class="py-1 w-175px bg-white">
      <div
        class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
        v-for="(action, index) in types"
        :key="index"
        @click="() => handleClick(action.url)"
      >
        <div
          class="py-2 w-100 text-left poppins font-size-sm font-weight-medium"
          :class="[
            { 'mt-2': index === 0 },
            { 'mb-2': index === types.length - 1 },
            { 'text-right': $vuetify.rtl },
          ]"
        >
          {{ action.name }}
        </div>
      </div>
    </div>
  </v-menu>
</template>
<script>
import ApiService from "@/core/services/api.service";
import {
  SET_PAGE_LOADING,
  SET_REPORT_MODAL_DATA,
  SET_REPORT_MODAL_STATE,
} from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "Print",
  props: ["selectedItems", "types", "refresher"],
  methods: {
    handleClick(url) {
      if (this.selectedItems.length > 0) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let data = { returns: this.selectedItemsClientReference };
        ApiService.post(`/shipping/returns_management/${url}`, data)
          .then(({ data }) => {
            this.$store.commit(SET_REPORT_MODAL_DATA, {
              total_orders_count: data.total_orders_count,
              successful_orders_count: data.successful_orders_count,
              failed_orders_count: data.failed_orders_count,
              successful_orders: data.success_orders,
              failed_orders: data.error_orders,
              message: data.message,
            });
            this.$store.commit(SET_REPORT_MODAL_STATE, true);
            if (data.successful_orders_count > 0) {
              if (data.path) {
                data.path.forEach((element) => {
                  const link = document.createElement("a");
                  link.href = element;
                  link.target = "_blank";
                  link.download = "orders.csv";
                  link.click();
                });
              }
            }
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        SwalService.warningMessage({
          title: this.$t("warning"),
          html: this.$t("please_select_at_least_one_order"),
          icon: "warning",
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
        });
      }
    },
  },
  computed: {
    selectedItemsClientReference: function () {
      return this.selectedItems.map((item) => item.client_reference);
    },
  },
};
</script>

<style scoped lang="scss">
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}
.drop-item:hover {
  font-weight: 600;
  /*background-color: #eee5ff;*/
  /*color: #8950fcff !important;*/

  background-color: var(--iq-button_hover_bg_color) !important;
  color: var(--iq-button_hover_text_color) !important;
}
.drop-text {
  font-size: 9px;
}
</style>
