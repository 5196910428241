<template>
  <span>
    <v-btn
      icon
      tile
      v-bind:id="data.name"
      target="_blank"
      :href="urlPath"
      v-if="url && url.length > 0"
    >
      <v-icon large v-bind:color="data.color">
        {{ data.icon }}
      </v-icon>
    </v-btn>
    <a v-if="data.name == 'error'">{{ $t("unknown") }}</a>
  </span>
</template>

<script>
export default {
  name: "Social",
  props: ["data", "url"],
  computed: {
    urlPath: function () {
      if (this.url.indexOf("http") > -1) {
        return this.url;
      } else return "https://" + this.url;
    },
  },
};
</script>
