<template>
  <div
    class="p-4 bg-white rounded-lg"
    v-if="isDataLoaded && permissionChecker('view')"
  >
    <div class="pa-4 py-6">
      <div class="col-12 d-flex justify-content-end">
        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
          v-if="permissionChecker('create')"
        >
          <button
            class="btn btn--export-filter px-4"
            @click.prevent="showCreateModal"
          >
            Create Release
          </button>
          <AddItem :refresher="loadDataFromServer" ref="addRelease"> </AddItem>
        </div>
      </div>
      <div class="mt-6" style="max-height: 70vh; overflow-y: scroll">
        <v-expansion-panels flat>
          <template v-if="releases.length > 0">
            <v-expansion-panel
              class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
              v-for="(release, index) in releases"
              :key="index"
            >
              <v-expansion-panel-header class="ps-0">
                <span class="font-weight-black font-size-lg ms-5">
                  <span>{{ release.name }} </span>
                  <span>v{{ release.version }}</span>
                  <span> - {{ release.upload_date }}</span>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <b-btn
                  class="my-5"
                  text
                  outlined
                  @click="downloadItem(release.apk_path)"
                  >Download Apk</b-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
          <template v-else>
            <span>NO DATA HERE!</span>
          </template>
        </v-expansion-panels>
        <div v-if="nextPageUrl" class="col-12 d-flex justify-content-center">
          <v-btn text outlined @click="loadMoreData">load more</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import AddItem from "@/own/components/warehouseManagement/wmsAppApks/AddItem.vue";

export default {
  name: "WMSAppApks",
  components: { AddItem },
  data: () => ({
    dataFromServer: null,
    nextPageUrl: null,
  }),
  beforeMount() {
    this.initTableContent();
  },
  mounted() {},
  computed: {
    isDataLoaded: function () {
      return !!this.dataFromServer;
    },
    releases() {
      return this.dataFromServer.values;
    },
  },
  methods: {
    showCreateModal() {
      this.$refs.addRelease.toggleModal();
    },
    downloadItem(url) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // console.log("order table state", this.$store.getters.getORDERSTableState);

      const link = document.createElement("a");
      link.href = `${process.env.VUE_APP_BASE_URL}/` + url;
      link.download = "Export.csv";
      link.click();
      setTimeout(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      }, 1500);
      link.remove();
    },
    permissionChecker(permType) {
      let result = false;
      this.dataFromServer.permissions.forEach((element) => {
        if (element.name === permType) {
          result = true;
        }
      });
      return result;
    },
    loadMoreData() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(this.nextPageUrl)
        .then((response) => {
          this.dataFromServer.values = [
            ...this.dataFromServer.values,
            ...response.data.values,
          ];
          if (response.data.next_page_url) {
            this.nextPageUrl = response.data.next_page_url;
          } else {
            this.nextPageUrl = false;
          }
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/wms_app_apks")
        .then((response) => {
          this.dataFromServer = response.data;
          if (response.data.next_page_url) {
            this.nextPageUrl = response.data.next_page_url;
          } else {
            this.nextPageUrl = false;
          }
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // this.nextPageUrl = "salam";
      // this.dataFromServer = {
      //   success: true,
      //   permissions: [
      //     {
      //       name: "view",
      //       columns: [
      //         {
      //           value: "id",
      //           text: "ID",
      //           order: -100,
      //         },
      //         {
      //           value: "name",
      //           text: "Name",
      //           order: -100,
      //         },
      //         {
      //           value: "version",
      //           text: "Version",
      //           order: -100,
      //         },
      //         {
      //           value: "description",
      //           text: "Description",
      //           order: -100,
      //         },
      //         {
      //           value: "apk_path",
      //           text: "APK path",
      //           order: -100,
      //         },
      //         {
      //           value: "uploaded_at",
      //           text: "Created at",
      //           order: -100,
      //         },
      //       ],
      //     },
      //     {
      //       name: "create",
      //       columns: [],
      //     },
      //   ],
      //   values: [
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //           "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //     {
      //       id: 2,
      //       name: "WMS APP",
      //       version: "1.0.1",
      //       description: null,
      //       apk_path:
      //         "files/wms_apps/2023-02-08_115237_wms-app_63e354c5b9c8a.apk",
      //       upload_date: "9th Feb, 2023",
      //     },
      //     {
      //       id: 5,
      //       name: "WMS APP",
      //       version: "2.0.0",
      //       description: null,
      //       apk_path:
      //         "files/wms_apps/2023-02-08_120901_wms-app_63e3589d6c569.apk",
      //       upload_date: "8th Feb, 2023",
      //     },
      //   ],
      //   next_page_url:
      //     "http://iqh-backend.test/warehouse_management/wms_app_apks?cursor=eyJjcmVhdGVkX2F0IjpudWxsLCJfcG9pbnRzVG9OZXh0SXRlbXMiOnRydWV9",
      //   total: 5,
      // };
    },
    async initTableContent() {
      this.loadDataFromServer();
      // await setTimeout(() => {
      //   this.loadDataFromServer();
      // }, 500);
    },
  },
};
</script>
