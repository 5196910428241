export default {
  methods: {
    getDateRange(filterData) {
      const type = filterData.date_range_type;
      const ranges = {
        today: function () {
          const date = new Date().toISOString().split("T")[0];
          return [date, date];
        },

        this_week: function () {
          const now = new Date(); // current date
          const firstDay = now.getDate() - now.getDay(); // date of th 1st day of current week
          const firstDayDate = new Date(now.setDate(firstDay))
            .toISOString()
            .split("T")[0];
          const today = new Date().toISOString().split("T")[0];
          return [firstDayDate, today];
        },

        last_week: function () {
          const lastSunday = new Date().setDate(
            new Date().getDate() - new Date().getDay() - 7
          );
          const lastFriday = new Date().setDate(
            new Date().getDate() - new Date().getDay() - 1
          );

          const lastSundayDate = new Date(lastSunday)
            .toISOString()
            .split("T")[0];
          const lastFridayDate = new Date(lastFriday)
            .toISOString()
            .split("T")[0];

          return [lastSundayDate, lastFridayDate];
        },

        last_7_days: function () {
          const firstDay = new Date().setDate(new Date().getDate() - 7);
          const firstDayDate = new Date(firstDay).toISOString().split("T")[0];

          const lastDay = new Date().setDate(new Date().getDate() - 1);
          const lastDayDate = new Date(lastDay).toISOString().split("T")[0];

          return [firstDayDate, lastDayDate];
        },

        this_month: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          const lastDay = new Date().toISOString().split("T")[0];
          return [firstDay, lastDay];
        },

        last_month: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth() - 1, 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          const lastDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 0) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          return [firstDay, lastDay];
        },

        last_30_days: function () {
          const today = new Date();
          const firstDay = new Date(new Date().setDate(today.getDate() - 30))
            .toISOString()
            .split("T")[0];
          return [firstDay, today.toISOString().split("T")[0]];
        },

        this_year: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return [firstDay, date.toISOString().split("T")[0]];
        },

        last_year: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear() - 1, date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          const lastDay = new Date(
            new Date(date.getFullYear() - 1, date.getMonth() + 11, 31) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return [firstDay, lastDay];
        },

        all_time: function () {
          const firstDay = new Date(0).toISOString().split("T")[0];
          const lastDay = new Date().toISOString().split("T")[0];

          return [firstDay, lastDay];
        },

        custom: function () {
          const firstDay = filterData.date_range[0];
          const lastDay = filterData.date_range[1];

          return [firstDay, lastDay];
        },
      };
      // return ranges[type]();
      return { name: "date_range", value: ranges[type]() };
    },

    getOrderStatus(name) {
      const status = {
        return_orders_counter_chart: function () {
          // return [1400, 1410, 1420, 1430, 1440, 1450, 1460, 1480, 1500];
          return null;
        },
        return_picked_counter_chart: function () {
          return [1410];
        },
        return_delivery_status_in_transit_counter_chart: function () {
          return [1420];
        },
        return_delivery_status_returned_counter_chart: function () {
          return [1460];
        },
      };
      return {
        name: "return_management_status",
        value: status[name](),
      };
    },
  },
};
