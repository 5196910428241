<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-bolder font-size-lg ms-5">
        <!-- <v-icon class="mr-3">mdi-{{ headerIcon }}-circle-outline</v-icon> -->
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- Allow over receiving -->
          <v-row>
            <v-col>
              <v-checkbox
                v-model="formData.allowOverReceivingEnabled"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow Over Receiving
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row v-if="formData.allowOverReceivingEnabled" class="ms-3">
                <v-col md="6">
                  <v-select
                    v-model="formData.allowOverReceivingRules"
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="allowOverReceivingRuleErrors"
                    @input="$v.formData.allowOverReceivingRules.$touch()"
                    @blur="$v.formData.allowOverReceivingRules.$touch()"
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.allowOverReceivingRules !== 'all' &&
                      formData.allowOverReceivingRules !== null
                  "
                >
                  <v-select
                    v-model="formData.allowOverRecevingCustomers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="allowOverReceivingCustomersSelectErrors"
                    @input="$v.formData.allowOverRecevingCustomers.$touch()"
                    @blur="$v.formData.allowOverRecevingCustomers.$touch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- receiving location -->
          <v-row v-if="serverData">
            <v-col md="6">
              <v-autocomplete
                v-model="formData.receivingLocation"
                :value="stagingWarehouseSearch"
                label="Default staging location"
                dense
                outlined
                :loading="stagingWarehouseLoading"
                rounded
                clearable
                item-text="name"
                item-value="id"
                :search-input.sync="stagingWarehouseSearch"
                :items="serverData.stagingLocations"
                @keydown="searchStagingLocation"
                @change="clearDefaultWarehouseLocation"
                :error-messages="stagingLocationErrors"
                @input="$v.formData.receivingLocation.$touch()"
                @blur="$v.formData.receivingLocation.$touch()"
              />
            </v-col>
          </v-row>
          <!-- transfer location -->
          <v-row v-if="serverData">
            <v-col md="6">
              <v-autocomplete
                v-model="formData.transferLocation"
                :value="transferLocationSearch"
                label="Default transfer location"
                dense
                outlined
                clearable
                :loading="transferLocationLoading"
                rounded
                item-text="name"
                item-value="id"
                :search-input.sync="transferLocationSearch"
                :items="serverData.transferLocations"
                @keydown="searchTransferLocation"
                @change="clearDefaultTransferLocation"
                :error-messages="transferLocationErrors"
                @input="$v.formData.transferLocation.$touch()"
                @blur="$v.formData.transferLocation.$touch()"
              />
            </v-col>
          </v-row>
          <v-row v-if="serverData">
            <v-col>
              <v-checkbox
                v-model="
                  formData.is_allow_user_to_decide_receiving_type_while_receiving
                "
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow user to decide receiving type while receiving
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="serverData">
            <v-col>
              <v-checkbox
                v-model="formData.is_upfront_tote_scan_enabled_when_receiving"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Upfront tote scan enabled while receiving
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Submit -->
          <v-row class="justify-center mb-3">
            <v-col md="2" class="justify-content-end">
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "ExpansionPanel",
  mixins: [validationMixin],
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  components: {},
  data() {
    return {
      expanded: false,
      serverData: null,
      serverDataLoaded: false,
      stagingWarehouseSearch: "",
      transferLocationSearch: "",
      stagingWarehouseLoading: false,
      transferLocationLoading: false,
      formData: {
        allowOverReceivingEnabled: false,
        allowOverReceivingRules: null,
        allowOverRecevingCustomers: [],

        is_allow_user_to_decide_receiving_type_while_receiving: false,

        generatePutawayTaskEnabled: false,
        generatePutawayTaskRules: null,
        generatePutawayTaskCustomers: [],

        allowSameToteEnabled: false,
        allowSameToteRules: null,
        allowSameToteCustomers: [],

        allowSameToteAsnEnabled: false,
        allowSameToteAsnRtules: null,
        allowSameToteAsnCustomers: [],

        validateWDEnabled: false,
        validateWDRules: null,
        validateWDCustomers: [],

        receivingLocation: null,
        transferLocation: null,

        is_upfront_tote_scan_enabled_when_receiving: false,
      },
    };
  },
  validations: {
    formData: {
      allowOverReceivingRules: {
        required: requiredIf(function() {
          return this.formData.allowOverReceivingEnabled;
        }),
      },
      allowOverRecevingCustomers: {
        required: requiredIf(function() {
          return (
            (this.formData.allowOverReceivingEnabled &&
              this.formData.allowOverReceivingRules === "only") ||
            this.formData.allowOverReceivingRules === "except"
          );
        }),
      },
      generatePutawayTaskRules: {
        required: requiredIf(function() {
          return this.formData.generatePutawayTaskEnabled;
        }),
      },
      generatePutawayTaskCustomers: {
        required: requiredIf(function() {
          return (
            (this.formData.generatePutawayTaskEnabled &&
              this.formData.generatePutawayTaskRules === "only") ||
            this.formData.generatePutawayTaskRules === "except"
          );
        }),
      },
      allowSameToteRules: {
        required: requiredIf(function() {
          return this.formData.allowSameToteEnabled;
        }),
      },
      allowSameToteCustomers: {
        required: requiredIf(function() {
          return (
            (this.formData.allowSameToteEnabled &&
              this.formData.allowSameToteRules === "only") ||
            this.formData.allowSameToteRules === "except"
          );
        }),
      },
      allowSameToteAsnRules: {
        required: requiredIf(function() {
          return this.formData.allowSameToteAsnEnabled;
        }),
      },
      allowSameToteAsnCustomers: {
        required: requiredIf(function() {
          return (
            (this.formData.allowSameToteAsnEnabled &&
              this.formData.allowSameToteAsnRules === "only") ||
            this.formData.allowSameToteAsnRules === "except"
          );
        }),
      },
      validateWDRules: {
        required: requiredIf(function() {
          return this.formData.validateWDEnabled;
        }),
      },
      validateWDCustomers: {
        required: requiredIf(function() {
          return (
            (this.formData.validateWDEnabled &&
              this.formData.validateWDRules === "only") ||
            this.formData.validateWDRules === "except"
          );
        }),
      },
      receivingLocation: { required },
      transferLocation: { required },
    },
  },
  methods: {
    togglePanel: function() {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function() {
      this.serverDataLoaded = false;
      const payload = {
        type: "receiving",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.formData.receivingLocation =
              data.configurations.default_location_id;
            this.formData.transferLocation =
              data.configurations.default_transfer_location_id;
            this.serverData.stagingLocations = data.locations;
            this.serverData.transferLocations = data.transfer_locations;
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    setConfigurationData: function(data) {
      this.formData = {
        ...this.formData,
        allowOverReceivingEnabled: data[0].value,
        allowOverReceivingRules: data[0].apply_rule_type,
        allowOverRecevingCustomers: data[0].customers,

        generatePutawayTaskEnabled: data[1].value,
        generatePutawayTaskRules: data[1].apply_rule_type,
        generatePutawayTaskCustomers: data[1].customers,

        allowSameToteEnabled: data[2].value,
        allowSameToteRules: data[2].apply_rule_type,
        allowSameToteCustomers: data[2].customers,

        allowSameToteAsnEnabled: data[3].value,
        allowSameToteAsnRules: data[3].apply_rule_type,
        allowSameToteAsnCustomers: data[3].customers,

        validateWDEnabled: data[4].value,
        validateWDRules: data[4].apply_rule_type,
        validateWDCustomers: data[4].customers,

        is_allow_user_to_decide_receiving_type_while_receiving: data[5].value,
        is_upfront_tote_scan_enabled_when_receiving: data[6].value,
      };
    },

    handleFormValidation: function(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },

    submitConfiguration: function() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = this.formData;
        const payload = {
          type: "receiving",
          warehouse_id: this.warehouseDetails.index,
          default_location_id: data.receivingLocation,
          default_transfer_location_id: data.transferLocation,
          configurations: [
            {
              key: "is_allow_over_receiving",
              value: data.allowOverReceivingEnabled,
              apply_rule_type: data.allowOverReceivingRules,
              customers:
                data.allowOverReceivingRules === "all"
                  ? []
                  : data.allowOverRecevingCustomers,
            },
            {
              key: "is_allow_user_to_decide_receiving_type_while_receiving",
              value:
                data.is_allow_user_to_decide_receiving_type_while_receiving,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_upfront_tote_scan_enabled_when_receiving",
              value: data.is_upfront_tote_scan_enabled_when_receiving,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_generate_putaway_task_when_asn_received",
              value: data.generatePutawayTaskEnabled,
              apply_rule_type: data.generatePutawayTaskRules,
              customers:
                data.generatePutawayTaskRules === "all"
                  ? []
                  : data.generatePutawayTaskCustomers,
            },
            {
              key: "is_allow_same_tote_for_different_skus",
              value: data.allowSameToteEnabled,
              apply_rule_type: data.allowSameToteRules,
              customers:
                data.allowSameToteRules === "all"
                  ? []
                  : data.allowSameToteCustomers,
            },
            {
              key: "is_allow_same_tote_for_multiple_asns",
              value: data.allowSameToteAsnEnabled,
              apply_rule_type: data.allowSameToteAsnRules,
              customers:
                data.allowSameToteAsnRules === "all"
                  ? []
                  : data.allowSameToteAsnCustomers,
            },
            {
              key: "is_validate_weight_and_dimension_before_receiving",
              value: data.validateWDEnabled,
              apply_rule_type: data.validateWDRules,
              customers:
                data.validateWDRules === "all" ? [] : data.validateWDCustomers,
            },
          ],
        };
        this.$store.commit(SET_PAGE_LOADING, true);
        return new Promise(() => {
          ApiService.post(
            `/warehouse_management/main_configuration/store`,
            payload
          )
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: "The Configuration settings are updated",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              // this.expanded = false;
            })
            .catch()
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        });
      }
    },
    searchStagingLocation() {
      setTimeout(() => {
        if (this.stagingWarehouseSearch) {
          this.stagingWarehouseLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.stagingWarehouseSearch,
            location_type: "staging",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload).then(
              ({ data }) => {
                this.serverData.stagingLocations = data.locations;
                this.stagingWarehouseLoading = false;
                resolve(data);
              }
            );
          });
        }
      }, 200);
    },

    searchTransferLocation() {
      setTimeout(() => {
        if (this.transferLocationSearch) {
          this.transferLocationLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.transferLocationSearch,
            location_type: "transfer",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload).then(
              ({ data }) => {
                this.serverData.transferLocations = data.locations;
                this.transferLocationLoading = false;
                resolve(data);
              }
            );
          });
        }
      }, 200);
    },

    clearDefaultWarehouseLocation() {
      if (!this.stagingWarehouseSearch && !this.formData.receivingLocation) {
        this.serverData.stagingLocations = [];
      }
    },

    clearDefaultTransferLocation() {
      if (!this.transferLocationSearch && !this.formData.transferLocation) {
        this.serverData.transferLocations = [];
      }
    },
  },
  computed: {
    headerIcon: function() {
      return this.expanded ? "minus" : "plus";
    },
    allowOverReceivingRuleErrors: function() {
      return this.handleFormValidation("allowOverReceivingRules", this);
    },
    allowOverReceivingCustomersSelectErrors: function() {
      return this.handleFormValidation("allowOverRecevingCustomers", this);
    },
    generatePutawayRuleErrors: function() {
      return this.handleFormValidation("generatePutawayTaskRules", this);
    },
    generatePuawayCustomersSelectErrors: function() {
      return this.handleFormValidation("generatePutawayTaskCustomers", this);
    },

    allowSameToteRuleErrors: function() {
      return this.handleFormValidation("allowSameToteRules", this);
    },
    allowSameToteCustomersSelectErrors: function() {
      return this.handleFormValidation("allowSameToteCustomers", this);
    },
    allowSameToteAsnRuleErrors: function() {
      return this.handleFormValidation("allowSameToteAsnRules", this);
    },
    allowSameToteAsnCustomersSelectErrors: function() {
      return this.handleFormValidation("allowSameToteAsnCustomers", this);
    },
    validateWDRuleErrors: function() {
      return this.handleFormValidation("validateWDRules", this);
    },
    validateWDCustomersSelectErrors: function() {
      return this.handleFormValidation("validateWDCustomers", this);
    },
    stagingLocationErrors: function() {
      return this.handleFormValidation("receivingLocation", this);
    },
    transferLocationErrors: function() {
      return this.handleFormValidation("transferLocation", this);
    },
  },
};
</script>
