import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const GET_WES_CUSTOMERS = "getActionWESCustomers";

// mutation types
export const SET_WES_CUSTOMERS = "setWESCustomers";
export const SET_WES_CONNECTOR_PERMISSIONS = "setWESConnectorPermissions";

const state = {
  customers: [],
  connectorPermissions: [],
};

const getters = {
  getWESCustomers(state) {
    return state.customers;
  },
  getWESConnectorPermissions(state) {
    return state.connectorPermissions;
  },
};

const actions = {
  [GET_WES_CUSTOMERS](context) {
    context.commit(SET_PAGE_LOADING, true);
    ApiService.post("/wes/connectors")
      .then(({ data }) => {
        // console.log("data", data);
        context.commit(SET_WES_CONNECTOR_PERMISSIONS, data.data.permissions);
      })
      .finally(() => {
        ApiService.post("/wes/connectors/datatable")
          .then(({ data }) => {
            context.commit(SET_WES_CUSTOMERS, data.data);
          })
          .finally(() => {
            context.commit(SET_PAGE_LOADING, false);
          });
      });
  },
};

const mutations = {
  [SET_WES_CONNECTOR_PERMISSIONS](state, data) {
    state.connectorPermissions = data;
  },
  [SET_WES_CUSTOMERS](state, data) {
    state.customers = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
