<template>
  <div class="d-flex">
    <button
      @click.prevent="generateCode"
      class="btn btn--export-filter font-weight-bolder mr-2 ml-3 mb-1"
    >
      <span class="svg-icon">
        <v-icon size="18">mdi-play</v-icon>
      </span>
      {{ $t("generate") }}
    </button>
    <button
      @click.prevent="() => onPrint(selected)"
      class="btn btn--export-filter font-weight-bolder ml-2 mr-3 mb-1"
    >
      <span class="svg-icon">
        <v-icon size="18">mdi-cloud-print-outline</v-icon>
      </span>
      {{ $t("print") }}
    </button>
    <GenerateCodeDialog ref="generateModal" :onPrint="onPrint" />
  </div>
</template>

<script>
import Swal from "sweetalert2";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import GenerateCodeDialog from "@/own/components/warehouseManagement/packageCode/GenerateCodeDialog.vue";
import ApiService from "@/core/services/api.service";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";
import { UPDATE_TABLE_DATA } from "@/core/services/store/packageCode.module.js";

export default {
  name: "PackageCodeActions",
  components: { GenerateCodeDialog },
  props: ["selected", "clearSelected"],
  methods: {
    generateCode() {
      this.$refs.generateModal.toggleModal();
    },

    onPrint(items = []) {
      if (!items.length) {
        Swal.fire({
          title: this.$t("warning"),
          text: this.$t("please_select_atleast_one_item"),
          icon: "warning",
          showConfirmButton: true,
          timer: 2500,
        });
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let itemSystemCodes = items.map((item) => item.code);
      let data = { codes: itemSystemCodes };
      ApiService.post(
        "warehouse_management/custom/package_codes/print_barcode",
        data
      )
        .then((response) => {
          this.$store.commit(SET_REPORT_MODAL_DATA, {
            successful_orders_count: response.data.total_successful,
            failed_orders_count: response.data.total_failed,
            successful_orders: response.data.successful_orders,
            failed_orders: response.data.failed_codes,
            message: response.data.message,
            total_orders_count: response.data.total,
          });
          this.$store.commit(SET_REPORT_MODAL_STATE, true);

          if (response.data.total_successful > 0) {
            if (response.data.path) {
              const link = document.createElement("a");
              link.href = response.data.path;
              link.target = "_blank";
              link.download = "orders.csv";
              link.click();
            }
          }
        })
        .then(() => {
          return this.$store.dispatch(UPDATE_TABLE_DATA, {});
        })
        .finally(() => {
          this.clearSelected();
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
};
</script>
