<template>
  <div :class="['card-header flex-wrap border-0 ma-0 pa-0', grid]">
    <div class="card-title ma-0 pa-0">
      <h1
        class="poppins card-label pl-4"
        :class="{ 'text-right pr-4': $vuetify.rtl }"
      >
        <span class="text-dark-65 font-size-h2 display4-md font-weight-bolder">
          {{ $t(table_properties.title) }}
        </span>
        <span class="d-block text-dark-50 mt-1 font-size-lg">{{
          $t(table_properties.description)
        }}</span>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatatableDetail",
  props: {
    table_properties: Object,
    grid: {
      type: String,
      required: false,
      default: "col-6 col-sm-2 col-md-3 col-xxl-4",
    },
  },
};
</script>
