<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Create Product</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-650px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->

          <form id="createForm" class="pa-3 pb-0">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <template v-for="n in steps">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="e1 > n"
                    :step="n"
                    editable
                  >
                    <span v-if="n == 1">Settings</span>
                    <span v-if="n == 2">Edit Name</span>
                    <span v-if="n == 3">Edit Description</span>
                    <span v-if="n == 4">Edit Seo</span>
                    <span v-if="n == 5">Edit Image</span>
                  </v-stepper-step>

                  <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content :step="1" :key="`1-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-autocomplete
                        v-model="store_id"
                        :items="stores"
                        outlined
                        item-name="text"
                        item-value="index"
                        dense
                        clearable
                        label="Select Store"
                        @change="ChangeLanguage"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-if="categories"
                        v-model="category_id"
                        :items="categories"
                        outlined
                        item-name="text"
                        item-value="index"
                        dense
                        clearable
                        label="Categories"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-model="brand_id"
                        :items="brands"
                        :search-input.sync="searchBrand"
                        outlined
                        item-name="text"
                        item-value="index"
                        dense
                        clearable
                        label="Brands"
                        ><template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              To Create this new brand
                              <span class="ml-3"
                                ><v-btn
                                  color="primary"
                                  @click="CreateBrand"
                                  :loading="brandCreateLoading"
                                >
                                  Create
                                </v-btn></span
                              >
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div class="stepper-footer">
                      <div></div>
                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(1)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="2" :key="`2-content`">
                  <div
                    v-if="category_id"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-text-field
                        v-for="(item, index) in languages"
                        :key="'name' + index"
                        v-model="formData['name_' + item.index]"
                        :label="'Name(' + item.text + ')'"
                        required
                        outlined
                        dense
                        clearable
                      ></v-text-field>
                    </div>
                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(2)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(2)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="3" :key="`3-content`">
                  <div
                    v-if="category_id"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <div class="d-flex justify-content-end">
                        <div class="w-25">
                          <v-autocomplete
                            v-if="languages"
                            v-model="description_language"
                            :items="languages"
                            outlined
                            item-name="text"
                            item-value="index"
                            dense
                            clearable
                            label="Language"
                          ></v-autocomplete>
                        </div>
                      </div>
                      <div
                        v-for="(item, index) in languages"
                        :key="'body' + index"
                      >
                        <ckeditor
                          v-model="formData['description_' + item.index]"
                          :config="editorConfig"
                          v-if="description_language == item.index"
                        ></ckeditor>
                      </div>
                    </div>
                    <div class="stepper-footer mt-3">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(3)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(3)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="4" :key="`4-content`">
                  <div
                    v-if="category_id"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-contetnt">
                      <v-text-field
                        v-for="(item, index) in seos"
                        :key="'seo' + index"
                        v-model="formData[item]"
                        :label="'Seo(' + item + ')'"
                        required
                        outlined
                        dense
                        clearable
                      ></v-text-field>
                    </div>

                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(4)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(4)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="5" :key="`5-content`">
                  <div
                    v-if="category_id"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-contetnt">
                      <div v-if="!!formData.image">
                        <div
                          v-for="(item, index) in formData.image"
                          :key="index"
                        >
                          <span>{{ item.name }}</span>
                          <span
                            ><v-icon left @click="removeImageItem(index)">
                              mdi-delete
                            </v-icon></span
                          >
                        </div>
                      </div>
                      <cropper
                        :insertImage="insertImage"
                        class="w-100"
                      ></cropper>
                      <v-switch
                        v-model="formData.home_page"
                        color="primary"
                        label="Home Page"
                      ></v-switch>
                    </div>

                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(5)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="submitCreateForm"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </form>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import Cropper from "@/view/pages/plugins/UploadCropper.vue";
import { UPDATE_CREATE_DATA } from "@/core/services/store/products.module";

export default {
  name: "AddProducts",
  props: ["refresher", "loader", "brands", "stores", "parameters"],
  components: {
    Cropper,
  },
  data: () => ({
    dialog: false,
    tabIndex: 0,
    editorConfig: {},
    name_language: null,
    description_language: null,
    languages: null,
    categories: null,
    store_id: null,
    category_id: null,
    brand_id: null,
    formData: null,
    searchBrand: null,
    brandCreateLoading: false,
    imageCount: 0,
    e1: 1,
    steps: 5,
    seos: ["keywords", "meta_title", "meta_description"],
  }),
  mounted() {
    this.resetData();
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    closeModal() {
      this.resetData();
      this.dialog = false;
    },
    converFormData() {
      let data = new FormData();
      data.append("store_id", this.store_id);
      data.append("category_id", this.category_id);
      data.append("brand_id", this.brand_id);
      for (var key in this.formData) {
        if (this.formData[key]) {
          if (key == "home_page") {
            if (this.formData[key] == true) data.append(key, 1);
            else data.append(key, 0);
          } else if (key == "image") {
            if (this.formData[key].length == 0) {
              return null;
            } else {
              this.formData[key].map((item) => {
                data.append("cover_images[]", item.image);
              });
            }
          } else data.append(key, this.formData[key]);
        } else {
          if (key == "home_page") {
            if (this.formData[key] == false) data.append(key, 0);
          } else {
            return null;
          }
        }
      }
      return data;
    },
    insertImage(image) {
      this.formData.image.push(image);
    },
    CreateBrand() {
      if (!this.searchBrand) {
        this.errorMessage("Should Input Cover Image Upload!");
      }
      this.brandCreateLoading = true;
      const data = {
        title: this.searchBrand,
      };
      ApiService.post("/stores/portal/brands/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `The product data has been Created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.$store.dispatch(UPDATE_CREATE_DATA, {}).then(() => {
            this.brandCreateLoading = false;
          });
          this.$forceUpdate();
        })
        .catch(() => {
          this.brandCreateLoading = false;
          this.loader(false);
        });
    },
    submitCreateForm() {
      const data = this.converFormData();
      if (!data) {
        this.errorMessage("You should input all data", "error", "Error");
        return;
      }
      this.loader(true);
      ApiService.post("/stores/portal/products/store", data)
        .then(() => {
          this.loader(false);
          swalAdded();
          this.toggleModal();
          this.refresher();
          this.$forceUpdate();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    errorMessage(message) {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetData() {
      this.formData = null;
      this.store_id = null;
      this.category_id = null;
      this.brand_id = null;
      this.languages = null;
      this.body_language = null;
      this.header_language = null;
      this.nextStep(5);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n == 1) {
          if (!this.store_id || !this.category_id)
            this.errorMessage("Should input Store and Category fristly!");
          else this.e1 = n + 1;
        } else if (n == 2) {
          var test = true;
          this.languages.map((item) => {
            if (
              !this.formData["name_" + item.index] ||
              this.formData["name_" + item.index] == ""
            ) {
              test = false;
              return;
            }
          });
          if (test) {
            this.e1 = n + 1;
            this.description_language = this.languages[0].index;
          } else
            this.errorMessage("Should Complete Name data in all languages!");
        } else if (n == 3) {
          var descriptionTest = true;
          this.languages.map((item) => {
            if (
              !this.formData["description_" + item.index] ||
              this.formData["description_" + item.index] == ""
            ) {
              descriptionTest = false;
              return;
            }
          });
          if (descriptionTest) this.e1 = n + 1;
          else
            this.errorMessage(
              "Should Complete Description data in all languages!"
            );
        } else this.e1 = n + 1;
      }
    },
    previewStep(n) {
      if (n == 1) this.el = this.steps;
      else this.e1 = n - 1;
    },
    ChangeLanguage() {
      var selectedItem = this.stores.filter(
        (item) => item.index == this.store_id
      );
      this.languages = selectedItem[0].languages;
      this.categories = selectedItem[0].categories;
      this.createFormData();
    },
    createFormData() {
      this.formData = {
        image: [],
        home_page: false,
      };
      this.languages.map((item) => {
        this.formData["name_" + item.index] = "";
        this.formData["description_" + item.index] = "";
      });
    },
    removeImageItem(index) {
      this.formData.image = this.formData.image.filter(
        (item, key) => key != index
      );
    },
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>
