<template>
  <v-dialog
    v-model="dialog"
    :max-width="value.header && value.header.length < 6 ? 850 : 1050"
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }" v-if="value.header">
      <button
        class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
        @click="toggleModal"
      >
        {{ $t("view") }}
      </button>
    </template>
    <!--<div class="bg-white poppins">
      <v-card-text class="px-8 pt-6 pb-0">
        <div class="table-responsive">
          <table
            v-if="value.header"
            class="table table-head-custom table-vertical-center table-head-bg table-row-bordered poppins"
          >
            <thead>
              <tr>
                <th
                  class="pl-3"
                  v-for="(item, index) in value.header"
                  :key="index"
                >
                  <span class="text-dark-75">{{ item }}</span>
                </th>
              </tr>
            </thead>
            <tbody v-if="value.body.length > 0">
              <tr v-for="(item, index) in value.body" :key="index">
                <td
                  class="px-3 text-dark-65 font-weight-bolder font-size-lg"
                  v-for="(col, colIndex) in item"
                  :key="colIndex"
                >
                  {{ col }}
                </td>
              </tr>
            </tbody>
            <caption
              v-else
              class="mt-2 text-dark-50 text-center font-weight-bolder font-size-lg"
            >
              {{
                $t("no_data")
              }}
            </caption>
          </table>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <button
          type="reset"
          class="btn btn-light-info mr-3 px-5 py-3 ls1"
          @click="dialog = false"
        >
          {{ $t("close") }}
        </button>
      </v-card-actions>
    </div> -->

    <v-card>
      <div class="modal-header py-3 align-center">
        <h4 class="mb-0 font-weight-bolder">Details</h4>
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <v-card-text class="pt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(item, index) in value.header"
                  :key="index"
                  class="text-left font-size-lg font-weight-bolder"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in value.body" :key="index">
                <td
                  v-for="(col, colIndex) in item"
                  :key="colIndex"
                  class="font-size-base font-weight-medium"
                >
                  {{ col }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <button
          @click="toggleModal"
          class="btn iq-btn px-3 px-sm-4 mx-3 mb-5 py-2 poppins-ls"
        >
          {{ $t("close") }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ShowSkusTable",
  props: ["value"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
