<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :detailsComponent="componentForDetails"
      :delete_url="delete_url"
      :urls="urls"
      :isWarehouseRequired="true"
      :actionsComponent="componentForActions"
    >
      <template v-slot:topActions>
        <TopActions :permissions="permissions" /> </template
    ></DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/warehouseManagement/inventoryAdjustments/AddItem.vue";
import EditItem from "@/own/components/warehouseManagement/inventoryAdjustments/EditItem";
import Details from "@/own/components/warehouseManagement/inventoryAdjustments/Details";
import TopActions from "@/own/components/warehouseManagement/inventoryAdjustments/TopActions.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/inventoryAdjustment.module";
import DataTableActionSelectorNew from "@/own/components/warehouseManagement/inventoryAdjustments/DataTableActionSelectorNew.vue";

export default {
  name: "InventoryAdjustment",
  components: { DataTable, TopActions },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    componentForDetails: Details,
    componentForActions: DataTableActionSelectorNew,
    delete_url: `${process.env.VUE_APP_BASE_URL}/warehouse_management/inventory_adjustments/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINVENTADJUSTTableFilters",
      getTableState: "getINVENTADJUSTTableState",
      getTableData: "getINVENTADJUSTTableData",
      getTableHeaders: "getINVENTADJUSTTableHeaders",
      getTableProperties: "getINVENTADJUSTTableProperties",
      getTableExportUrl: "getINVENTADJUSTExportUrl",
      getTablePermissions: "getINVENTADJUSTTablePermissions",
      // getUploadType: "getINVENTADJUSTUploadType",
    },
    urls: {
      approve: `/warehouse_management/inventory_adjustments/approve`,
      delete: `/warehouse_management/inventory_adjustments/destroy`,
      complete: `/warehouse_management/inventory_adjustments/complete`,
      cancel: `/warehouse_management/inventory_adjustments/cancel`,
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    permissions: function () {
      /** @type {Array<string>} */
      let permissions = [];
      try {
        if (this.$store.getters[this.store_names.getTablePermissions]) {
          permissions = this.$store.getters[
            this.store_names.getTablePermissions
          ].map(function (perm) {
            return perm.name;
          });
        }
      } catch (error) {
        // this.$swal("Can not load table permissions!");
      }
      return permissions;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
