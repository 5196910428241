<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1200px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add SKU Movement</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-block pa-4">
        <!--begin::Body-->
        <v-stepper
          v-model="tab"
          non-linear
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
          elevation="0"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="0" class="pt-2">
              <v-card color="basil" flat class="row pt-2 pb-8">
                <div
                  class="col-12 col-sm-6 my-0 py-0"
                  :class="{ '': isClient }"
                >
                  <v-autocomplete
                    v-model="formData.warehouse_id"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    clearable
                    outlined
                    dense
                    :error-messages="warehouse_idErrors"
                    @input="$v.formData.warehouse_id.$touch()"
                    @blur="$v.formData.warehouse_id.$touch()"
                    @change="onDataChange"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6 my-0 py-0" v-if="!isClient">
                  <v-autocomplete
                    v-model="formData.customer_id"
                    label="Customer"
                    item-text="text"
                    item-value="index"
                    :items="serverData.clients"
                    clearable
                    outlined
                    dense
                    :error-messages="customer_idErrors"
                    @input="$v.formData.customer_id.$touch()"
                    @blur="$v.formData.customer_id.$touch()"
                    @change="onDataChange"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6 my-0 py-0" v-if="!isClient">
                  <v-text-field
                    v-model="formData.sku_barcode"
                    label="SKU Barcode"
                    clearable
                    outlined
                    dense
                    :error-messages="skubarcode_Errors"
                    @input="$v.formData.sku_barcode.$touch()"
                    @blur="$v.formData.sku_barcode.$touch()"
                    @change="onDataChange"
                  ></v-text-field>
                </div>

                <div class="col-12 col-sm-6 my-0 py-0">
                  <v-text-field
                    v-model="formData.from_location"
                    label="From location"
                    clearable
                    outlined
                    dense
                    :error-messages="from_locationErrors"
                    @input="$v.formData.from_location.$touch()"
                    @blur="$v.formData.from_location.$touch()"
                    @change="onDataChange"
                    append-icon="mdi-barcode-scan"
                    @keyup.enter="processFromLocation"
                    @click:append="processFromLocation"
                  ></v-text-field>
                </div>

                <div class="col-12 col-sm-6 my-0 py-0">
                  <v-text-field
                    v-model="formData.to_location"
                    label="To location"
                    :disabled="!fromLocationValidated"
                    clearable
                    outlined
                    dense
                    :error-messages="to_locationErrors"
                    @input="$v.formData.to_location.$touch()"
                    @blur="$v.formData.to_location.$touch()"
                    append-icon="mdi-barcode-scan"
                    @keyup.enter="processToLocation"
                    @click:append="processToLocation"
                  ></v-text-field>
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="1" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12">
                  <v-data-table
                    fixed-header
                    height="40vh"
                    v-model="formData.tasks"
                    :headers="headers"
                    :items="all_tasks"
                    item-key="inventory_balance_id"
                    show-select
                    class="elevation-0"
                    @toggle-select-all="selectAllManage"
                  >
                    <template #item.moved_qty="{ item, header, value }">
                      <v-text-field
                        @change="
                          onMoveQtyChange(
                            item.moved_qty,
                            item.available_qty,
                            item
                          )
                        "
                        dense
                        type="number"
                        min="0"
                        :max="item.available_qty"
                        v-model="item.moved_qty"
                      ></v-text-field>
                    </template>
                  </v-data-table>
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div class="justify-content-between align-center d-flex col-12">
            <v-btn
              v-if="backState"
              class="order-1 order-sm-1"
              @click="nextStep('prev')"
              color="primary"
              dark
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <div v-else class="order-1 order-sm-1"></div>
            <div
              class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
              :class="{ invisible: nextState }"
            >
              <button
                type="submit"
                class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                @click="resetEditForm"
              >
                Clear
              </button>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submitEditForm"
              >
                Create
              </button>
            </div>
            <v-btn
              v-if="nextState"
              class="order-3 order-sm-3"
              @click="nextStep('next')"
              color="primary"
              dark
              ><v-icon>mdi-arrow-right</v-icon></v-btn
            >
            <div v-else class="order-3 order-sm-3"></div>
          </div>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  components: {},
  props: {
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
  },
  validations() {
    return {
      formData: {
        warehouse_id: { required },
        customer_id: {
          required: requiredIf(() => {
            return !this.isClient;
          }),
        },
        sku_barcode: { required },
        from_location: { required },
        to_location: { required },
      },
    };
  },
  data: () => ({
    formData: {
      warehouse_id: null,
      customer_id: null,
      from_location: null,
      to_location: null,
      tasks: [],
      sku_barcode: null,
    },
    dialog: false,
    all_tasks: [],
    headers: [
      {
        text: "Inventory balance id",
        align: "start",
        sortable: true,
        value: "inventory_balance_id",
      },
      { text: "SKU id", value: "sku_id" },
      { text: "SKU barcode", value: "sku_barcode" },
      { text: "Available quantity", value: "available_qty" },
      { text: "Moved quantity", value: "moved_qty" },
    ],
    doneTypingInterval: 1500,
    typingTimer: null,
    serverData: null,
    tab: 0,
    categories: [
      {
        name: "SKU Movement",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`formData`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "Tasks",
        valid: true,
        rules: (el) => {
          try {
            return !(el.formData.tasks.length > 0);
          } catch {
            return true;
          }
        },
      },
    ],
    fromLocationValidated: false,
    toLocationValidated: false,
  }),
  methods: {
    nextStep(type) {
      this.updateValidationStateOfSteps();
      if (type == "next") {
        if (this.tab < 1) {
          if (!this.fromLocationValidated || !this.toLocationValidated) {
            let html = "";
            if (!this.fromLocationValidated) {
              html += "<div>From location not validated</div>";
            }
            if (!this.toLocationValidated) {
              html += "<div>To location not validated</div>";
            }
            Swal.fire({
              title: "Warning",
              html: html,
              icon: "warning",
              showConfirmButton: true,
              // timer: 2500,
            });
            return;
          }
          if (this.$v.$invalid) {
            this.$v.$touch();

            return;
          }
          this.getInventoryBalance();
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    updateValidationStateOfSteps() {
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    async validateLocation(type) {
      this.pageLoader(true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
        location: this.formData[type],
      };
      try {
        await ApiService.post(
          `/warehouse_management/warehouses/check_locked_location`,
          payload
        );
      } finally {
        this.pageLoader(false);
      }
    },
    processFromLocation() {
      if (this.formData.from_location) {
        this.validateLocation("from_location").then(() => {
          this.fromLocationValidated = true;
          this.onDataChange();
        });
      }
    },
    processToLocation() {
      if (this.formData.to_location) {
        this.validateLocation("to_location").then(() => {
          this.toLocationValidated = true;
          this.onDataChange();
        });
      }
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/warehouse_management/sku_movements/create`, data)
        .then((response) => {
          this.serverData = response.data;
          this.formData.customer_id = this.userId;
          this.pageLoader(false);
          this.updateValidationStateOfSteps();
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.updateValidationStateOfSteps();
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }
      this.$v.$touch();
      if (this.formData.tasks.length === 0) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one task should be added!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.pageLoader(true);
      let data = {
        ...this.formData,
      };

      ApiService.post(`/warehouse_management/sku_movements/store`, data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetEditForm();
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetEditForm() {
      this.$v.$reset();
      this.formData = {
        warehouse_id: null,
        customer_id: null,
        from_location: null,
        to_location: null,
        tasks: [],
      };
      this.formData.customer_id = this.userId;
      this.all_tasks = [];

      this.tab = 0;
      this.resetValidationStateOfSteps();
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    onMoveQtyChange(value, maxValue, item) {
      if (value > maxValue) {
        item.moved_qty = maxValue;
      } else if (value < 0) {
        item.moved_qty = 0;
      }
    },
    onDataChange() {
      this.updateValidationStateOfSteps();
      this.all_tasks = [];
      this.formData.tasks = [];
    },
    selectAllManage() {
      if (
        this.formData.tasks.length == this.serverData.inventory_balances.length
      ) {
        this.formData.tasks = [];
      } else {
        this.formData.tasks = this.serverData.inventory_balances;
      }
      this.updateValidationStateOfSteps();
    },
    async getInventoryBalance() {
      if (
        this.formData.warehouse_id &&
        this.formData.customer_id &&
        this.formData.from_location
      ) {
        let data = {
          warehouse_id: this.formData.warehouse_id,
          customer_id: this.formData.customer_id,
          from_location: this.formData.from_location,
          sku: this.formData.sku_barcode,
        };
        this.all_tasks = [];
        this.pageLoader(true);
        await ApiService.post(
          "/warehouse_management/sku_movements/get_inventory_balances",
          data
        )
          .then((response) => {
            this.formData.tasks = [...response.data.inventory_balances];
            this.all_tasks = [...response.data.inventory_balances];
            this.serverData["inventory_balances"] = [
              ...response.data.inventory_balances,
            ];
            this.tab = 1;
            this.updateValidationStateOfSteps();
            this.pageLoader(false);
          })
          .catch(() => {
            this.tab = 0;
            // this.formData.sku_id = null;
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
  },
  computed: {
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 1;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },

    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    isSkuFieldEditable() {
      return !this.isClient && !this.formData.customer_id;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    userId: function () {
      return this.isClient
        ? this.$store.getters.currentUser.data.id
        : this.formData.customer_id;
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id", this);
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    skubarcode_Errors: function () {
      return this.handleFormValidation("sku_barcode", this);
    },
    from_locationErrors: function () {
      return this.handleFormValidation("from_location", this);
    },
    to_locationErrors: function () {
      return this.handleFormValidation("to_location", this);
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  mounted() {
    this.formData.warehouse_id = this.selectedWarehouse;
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  align-content: flex-end;
}
.modal-root {
  background-color: white !important;
  height: 100%;
}
.modal-action {
  justify-self: flex-end;
  height: 100%;
}
.form-body {
  max-height: 80vh !important;
  height: 80vh !important;
  overflow-y: scroll;
}
.step-head {
}
.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
