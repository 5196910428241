<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="480"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Scan Carton</h3>
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div
        class="modal-body d-flex flex-column justify-space-between scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="row pb-4">
          <div class="h-75px col-12">
            <v-text-field
              dense
              outlined
              v-model="formData.carton"
              label="Carton"
              required
              clearable
              :error-messages="cartonErrors"
              @input="$v.formData.carton.$touch()"
              @blur="$v.formData.carton.$touch()"
            ></v-text-field>
          </div>
        </div>

        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog2" persistent max-width="600">
      <div class="bg-white poppins" v-if="results">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">
            <div class="pl-2">
              {{ results.success_inventories }} of {{ results.total }} performed
              successfully
            </div>
          </h3>
          <div class="btn btn-sm btn-icon btn-active-light-info" @click="final">
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-3 mx-xl-15 py-4">
          <!--begin::Body-->

          <div class="scrollable overflow-y-auto" style="max-height: 30vh">
            <div v-for="(mes, index) in results.messages" :key="index">
              <span>{{ mes }}</span>
            </div>
          </div>
          <!--end::Body-->
          <div class="mt-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="final"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      carton: { required },
    },
  },
  name: "ScanCarton",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    dialog2: false,
    results: null,
    formData: {
      carton: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("email" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal() {
      this.resetCreateForm();
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.formData;

        ApiService.post(
          "/warehouse_management/inventory_balances/create_putaway_task_by_carton",
          data
        )
          .then((response) => {
            Swal.fire({
              title: "Created",
              text: "Putaway performed!",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });

            this.pageLoader(false);
            this.results = response.data;
            this.$nextTick(() => {
              this.dialog2 = true;
            });
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    final() {
      this.dialog2 = false;
      this.toggleModal();
      this.results = null;
      this.refresher();
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        carton: null,
      };
    },
  },
  computed: {
    cartonErrors() {
      return this.handleFormValidation("carton");
    },
  },
};
</script>
