<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="snackbar">
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  name: "Snackbar",
  props: ["message"],
  data: () => ({
    snackbar: false,
  }),
  methods: {
    trigger() {
      this.snackbar = true;
    },
  },
};
</script>
