import { render, staticRenderFns } from "./ReturnNew.vue?vue&type=template&id=34d80f6c&scoped=true&v-if=isDataLoaded"
import script from "./ReturnNew.vue?vue&type=script&lang=js"
export * from "./ReturnNew.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d80f6c",
  null
  
)

export default component.exports