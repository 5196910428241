<template>
  <v-container fluid>
    <v-row class="page-wrapper">
      <v-col
        cols="12"
        md="6"
        lg="7"
        class="banner-wrapper loin-new__container p-0"
      >
        <div
          class="d-flex flex-column justify-content-between"
          style="height: 65%"
        >
          <img
            class="img-fluid rounded-lg mt-7 ml-10 iq-purple-logo"
            src="/img/iqplatform.png"
            alt="logo"
          />
          <div class="d-flex justify-content-center" v-if="logoIntegration">
            <div class="w-75">
              <div class="d-flex align-items-center mb-3">
                <h1 class="hi-text">Hi</h1>
                <img
                  src="/img/waving-hand.png"
                  alt="powering-ecommerce"
                  class="pr-2 w-55px h-55px ml-5"
                />
              </div>
              <h1 class="text-lg-2">
                Your
                <span :style="'color:' + logoIntegration.color">{{
                  logoIntegration.name
                }}</span>
                integration is almost done...
              </h1>
              <div class="d-flex justify-content-between align-items-center">
                <img
                  :src="logoIntegration.logo"
                  class="p-2 img-fluid rounded-lg w-150px h-150px mr-5"
                />
                <div class="mx-3">
                  <img
                    class="loading-image p-5"
                    :src="item.path"
                    v-for="item in loadingImages"
                    :key="item.id"
                  />
                </div>
                <img
                  src="/img/iqplatform.png"
                  class="p-2 img-fluid rounded-lg w-150px h-150px ml-5"
                />
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="5"
        class="overflow-auto d-flex flex-column justify-content-between poppins container-wrapper-right h-100"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-grow-1 mt-lg-0 signin-container-wrapper"
        >
          <div class="signin-container w-325px w-lg-400px w-md-50 px-3 px-sm-0">
            <div class="font-weight-boldest mb-12">
              <h1 class="font-weight-boldest title mb-3 mt-15 mt-lg-0">
                Log in
              </h1>
              <p class="subtitle">Run, Manage & Scale your eCommerce</p>
            </div>
            <b-form @submit="signinUser">
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 ml-3">
                      <span class="svg-icon">
                        <v-icon size="28">mdi-account-outline</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    placeholder="Username"
                    required
                    v-model="form.email"
                    name="email"
                    type="email"
                    id="email"
                    class="email-input"
                    ref="email"
                    :state="isEmailValid"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 mt-lg-6 ml-3">
                      <span class="svg-icon">
                        <v-icon size="24">mdi-lock</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    required
                    v-model="form.password"
                    :type="passwordHidden ? 'password' : 'text'"
                    name="password"
                    id="password"
                    class="password-input"
                    ref="password"
                    placeholder="Password"
                  ></b-form-input>
                  <b-input-group-prepend
                    @click="passwordHidden = !passwordHidden"
                    class="password-prepend"
                  >
                    <div class="mt-5 mt-lg-mt-6 mr-5">
                      <span class="svg-icon">
                        <v-icon size="20"
                          >mdi-{{
                            passwordHidden ? "eye-off-outline" : "eye-outline"
                          }}</v-icon
                        >
                      </span>
                    </div>
                  </b-input-group-prepend>
                </b-input-group>
                <div class="text-white font-size-lg mt-6">
                  <router-link class="link-white" to="/forgot_password"
                    >Forgot Password?</router-link
                  >
                </div>
                <div class="text-white font-size-lg mt-6 link-white">
                  Don't have an account?

                  <router-link
                    class="link-white text-decoration-underline"
                    :to="{ path: '/integration/register', query: $route.query }"
                  >
                    Sign up</router-link
                  >
                </div>
              </div>
              <div
                class="d-flex submit-button-container justify-content-center mb-14 mt-14 p-1"
              >
                <button
                  type="submit"
                  ref="submit"
                  class="submit-button m-1 font-weight-bolder"
                  :class="[
                    !isSubmitButtonClickable ? 'disabled' : '',
                    loading ? ' loading' : '',
                    isSubmitButtonClickable && !loading ? '' : '',
                  ]"
                >
                  <template v-if="loading">
                    LOGING IN...
                    <v-progress-circular
                      :size="20"
                      width="3"
                      color="primary"
                      indeterminate
                      class="ml-2 mb-1"
                    ></v-progress-circular>
                  </template>
                  <template v-else>LOGIN</template>
                </button>
              </div>
            </b-form>
          </div>
        </div>
        <div
          class="d-flex justify-content-center text-white mb-3 mt-14 ml-3 ml-md-0"
        >
          <div>
            <img
              src="/img/iqfulfillment-white-foreground.png"
              alt="logo"
              class="img-fluid w-125px mr-2 mb-2"
            />
            <span class="font-size-h6"
              >© {{ currentYear }} - All rights reserved.</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  LOGIN,
  LOGOUT,
  GET_INITIAL,
} from "@/core/services/store/integration_auth.module";
import Swal from "sweetalert2";

export default {
  name: "LoginNew",
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    loading: false,
    passwordHidden: true,
    currentYear: new Date().getFullYear(),
    integration: {
      title: "Shopify",
      class: "text-green",
    },
    loadingImages: [
      { path: "/img/aarow_small.svg", id: 1 },
      { path: "/img/aarow_big.svg", id: 2 },
      { path: "/img/aarow_small.svg", id: 3 },
      { path: "/img/aarow_small.svg", id: 4 },
      { path: "/img/aarow_small.svg", id: 5 },
    ],
  }),
  computed: {
    isSubmitButtonClickable: function () {
      return this.isEmailValid && this.isPasswordValid;
    },

    logoIntegration: function () {
      return this.$store.getters.getLogoIntegration;
    },

    isEmailValid: function () {
      const reg = RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      return reg.test(this.form.email);
    },

    isPasswordValid: function () {
      return this.form.password.length !== 0;
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.platform)
      this.$store.dispatch(GET_INITIAL, {
        platform: this.$route.query.platform,
      });
    setInterval(this.changeImage, 300);
  },
  methods: {
    signinUser(event) {
      event.preventDefault();
      if (this.isSubmitButtonClickable && !this.loading) {
        let email = this.form.email;
        let password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        this.loading = true;

        // send login request
        this.$store
          .dispatch(LOGIN, { email, password, ...this.$route.query })
          // go to which page after successfully login
          .then((data) => {
            if (data.success === false) {
              return;
            } else if (data.success === true) {
              Swal.fire({
                title: "Congratulation",
                text: data.message,
                icon: "success",
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: "#7e04b7",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.push({ name: "dashboard.dashboardDelivery" });
                }
              });
            }
          })
          .catch(() => {})
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          });
      }
    },
    changeImage() {
      let temp = [...this.loadingImages];
      let tempItem = temp.pop();
      temp.unshift(tempItem);
      this.loadingImages = [...temp];
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/pages/login/login_new.scss";
</style>
