<template>
  <v-container class="d-flex flex-column flex-fill">
    <div class="flex-fill">
      <v-container>
        <v-row no-gutters>
          <v-col
            v-for="(item, index) in serverData?.customer"
            :key="index"
            cols="12"
            lg="8"
            class="py-2"
          >
            <FieldContainer
              v-if="item.field_type === 'select'"
              :label="$t(item.key)"
              :divider="index === serverData.customer.length - 1 ? false : true"
            >
              <v-autocomplete
                v-model="formData[item.key]"
                :items="getSelectOptions(item.field_values)"
                clearable
                dense
                outlined
                class="rounded-lg h-50px p-0 m-0"
                required
                item-text="text"
                item-value="index"
              ></v-autocomplete>
            </FieldContainer>

            <FieldContainer
              v-if="item.field_type === 'radio'"
              :label="$t(item.key)"
              :divider="index === serverData.customer.length - 1 ? false : true"
            >
              <v-switch
                class="h-50px"
                v-model="formData[item.key]"
                inset
                dense
              ></v-switch>
            </FieldContainer>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-row v-if="serverData">
      <v-col cols="12" lg="8">
        <v-row class="d-flex justify-content-end mt-15">
          <v-col cols="auto" class="pr-0">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              {{ this.$t("submit") }}
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import FieldContainer from "@/own/components/customers/clients/FormParts/FieldContainer.vue";

export default {
  name: "InboundFormFields",
  props: ["pageLoader", "actionItem", "refresher"],
  components: { FieldContainer },
  data: () => ({
    serverData: null,
    formData: {},
  }),
  methods: {
    loadServerData() {
      if (!this.serverData) {
        this.pageLoader(true);
        let payload = {
          tab_name: "inbound",
        };

        if (this.actionItem) {
          payload.id = this.actionItem.id;
        }

        return ApiService.post("authorizations/clients/edit", payload)
          .then(({ data }) => {
            this.serverData = data;
            this.initializeFormData(data?.customer);
          })
          .finally(() => {
            this.pageLoader(false);
          });
      }
    },
    initializeFormData(customerData) {
      customerData.forEach((item) => {
        this.$set(this.formData, item.key, item.value);
      });
    },
    getSelectOptions(field_values_key) {
      if (!this.serverData) return [];
      return this.serverData[field_values_key] || [];
    },
    submit() {
      this.pageLoader(true);
      const configurations = this.serverData?.customer.map((item) => ({
        key: item.key,
        value: this.formData[item.key],
      }));
      const payload = {
        tab_name: "inbound",
        id: this.actionItem.id,
        configurations,
      };

      ApiService.post("authorizations/clients/update", payload)
        .then(() => {
          SwalService.successMessage({
            title: this.$t(this.$t("updated")),
            html: this.$t("updated_successfully"),
          });
          this.refresher();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    reset() {
      this.serverData = null;
      this.formData = {};
    },
  },
  created() {
    this.loadServerData();
  },
};
</script>

<style scoped>
.v-row {
  margin-bottom: 20px; /* Adjust this value as needed */
}

.v-row:last-child {
  margin-bottom: 0; /* Remove margin for the last row */
}
</style>
