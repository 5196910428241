<template>
  <b-dropdown
    ref="print-dropdown"
    id="print-dropdown"
    text="Print"
    class="mr-3 mb-1"
    toggle-class="poppins btn--export-filter"
    menu-class="m-0 p-0 "
    variant="upload"
    no-caret
  >
    <template v-slot:button-content>
      <div class="px-2">
        <span class="svg-icon">
          <v-icon size="18">mdi-printer</v-icon>
        </span>
        <span>Print</span>
        <span class="svg-icon mr-0 ml-2">
          <v-icon size="18">mdi-chevron-down</v-icon>
        </span>
      </div>
    </template>
    <div class="q-status-menu py-1 w-160px">
      <div
        class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
        v-for="(print, index) in types"
        :key="index"
        @click="() => onPrint(print.value, print.name, items)"
      >
        <div
          class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
          :class="[
            { 'mt-2': index === 0 },
            { 'mb-2': index === types.length - 1 },
          ]"
        >
          {{ print.name }}
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "Print",
  props: {
    /** @type {Array<{name:string, value: string}>} */
    types: {
      required: true,
      type: Array,
    },
    /** @type {Array<{systemId: string}>} */
    items: {
      required: true,
      type: Array,
    },
  },
  methods: {
    showReport(type, items) {
      let total = items.length;
      let available = items.filter(
        (item) => item.extra_data.print[`${type}`] === true
      );
      let notAvailable = items.filter(
        (item) => item.extra_data.print[`${type}`] === false
      );
      let typeAmountAvailable = available.length;
      if (typeAmountAvailable !== total) {
        let message = notAvailable.map((item) => `<li>${item.id}</li>`);

        Swal.fire({
          title: "Warning",
          html: `<div>
                    <div>The ${typeAmountAvailable} of ${total} available for ${type}</div>
                    <div>
                      <span>Orders that are not printed:</span>
                      <ul class="text-left pl-6">${message.join("")}</ul>
                    </div>
                  </div>`,
          icon: "warning",
          showConfirmButton: true,
        });
      }
      return {
        total: total,
        availableAmount: typeAmountAvailable,
        available: available,
        notAvailable: notAvailable,
      };
    },
    onPrint(val, name, items = []) {
      if (items.length > 0) {
        let isRepotShown = this.showReport(val, items);
        if (isRepotShown.availableAmount > 0) {
          this.$store.commit(SET_PAGE_LOADING, true);
          let ids = items.map((item) => item.id);
          let data = { type: val, returns: ids };
          ApiService.post("/returns/print", data)
            .then((response) => {
              this.$store.commit(SET_REPORT_MODAL_DATA, {
                total_orders_count: response.data.total_orders_count,
                successful_orders_count: response.data.successful_orders_count,
                failed_orders_count: response.data.failed_orders_count,
                successful_orders: response.data.successful_orders,
                failed_orders: response.data.failed_orders,
                message: response.data.message,
              });
              this.$store.commit(SET_REPORT_MODAL_STATE, true);
              if (response.data.successful_orders_count > 0) {
                if (response.data.path) {
                  response.data.path.forEach((element) => {
                    const link = document.createElement("a");
                    link.href = element;
                    link.target = "_blank";
                    link.download = "orders.csv";
                    link.click();
                  });
                }

                // setTimeout(() => {
                // }, 1000);
              }
            })
            .catch(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        } else {
          Swal.fire({
            title: "Warning",
            text: `${name} is not ready for selected orders.`,
            icon: "warning",
            showConfirmButton: false,
          });
        }
      } else {
        // Swal.fire({
        //   title: "Warning",
        //   text: "Please, select at least one order!",
        //   icon: "warning",
        //   showConfirmButton: false,
        //   showCancelButton: true,
        //   cancelButtonText: "OK",
        //   cancelButtonColor: "#FFA800",
        // });
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
        });
      }
    },
  },
  // computed: {
  //   selectedItemsClientReference: function () {
  //     return this.selectedItems.map((item) => item.client_reference);
  //   },
  // },
};
</script>

<style scoped>
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}
.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
.drop-text {
  font-size: 9px;
}
</style>
