<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :recordDetailComponent="componentForRecordDetail"
      :urls="urls"
      :overrideActionsWith="actions"
      @closeShipmentFn="closeShipmentFn"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/sortationSKUs.module";
import RecordDetail from "@/own/components/RecordDetail.vue";
import SwalService from "@/core/services/swal.service";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "SortationSKUs",
  components: { DataTable },
  data: () => ({
    componentForRecordDetail: RecordDetail,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      // EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getSORTATIONSKUSTableFilters",
      getTableState: "getSORTATIONSKUSTableState",
      getTableData: "getSORTATIONSKUSTableData",
      getTableHeaders: "getSORTATIONSKUSTableHeaders",
      getTableProperties: "getSORTATIONSKUSTableProperties",
      // getTableExportUrl: "getSORTATIONSKUSExportUrl",
      getTablePermissions: "getSORTATIONSKUSTablePermissions",
      getUploadType: "getSORTATIONSKUSUploadType",
    },
    urls: {
      details: "/warehouse_management/sortation_skus/show",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.$store.getters.getSelectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
      }
    },
    closeShipmentFn(item) {
      // console.log(item);
      SwalService.warningConditionMessage(
        {
          title: SwalService.titles.are_you_sure,
          html: SwalService.messages.you_wont_be_able_to_revert_this,
          // confirmButtonText: "OK",
          confirmButtonText: this.$t("yes"),
        },
        () => {
          this.$store.commit(SET_PAGE_LOADING, true);
          ApiService.post(
            "/warehouse_management/sortation_skus/close_shipment",
            { id: item.id }
          )
            .then(async () => {
              SwalService.successMessage({
                // title: SwalService.titles.deleted,
                // html: SwalService.messages.deleted(),
                title: "Closed",
                html: "Item was successfully closed",
              });

              await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
                ...this.$store.getters.getSORTATIONSKUSTableState,
              });
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        }
      );
    },
  },
  computed: {
    actions: function () {
      return [
        {
          name: "close_shipment",
          // title: this.$t("close_shipment"),
          title: "Close",
          type: "normal",
          method: "closeShipmentFn",
          isVisible: true,
        },
      ];
    },
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
      }
    },
  },
  beforeMount() {
    this.initTableContent();
  },
};
</script>
