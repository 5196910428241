<template>
  <div>
    <v-select
      outlined
      class="px-2"
      dense
      v-model="filterData['select']"
      :items="dateTypes"
      item-text="text"
      item-value="value"
      @change="updateData"
      :label="$t(filter.title.en)"
    >
      <template v-slot:prepend-inner>
        <v-icon>mdi-calendar-range</v-icon>
      </template>
      <template v-slot:item="{ item }"
        ><div
          class="py-4"
          style="height: 100%; width: 100%; display: block"
          @click="updateDateOfCharts(item.value)"
        >
          <!-- <v-icon>mdi-calendar-range</v-icon> -->
          {{ item.text }}
        </div>
      </template>
    </v-select>
    <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
      <v-date-picker
        v-model="filterData['date']"
        range
        type="month"
        scrollable
        @change="updateData"
        :max="new Date().toISOString().split('T')[0]"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="
            () => {
              dialog = false;
              isVisible = true;
              dateType = 'this_month';
            }
          "
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn text color="primary" @click="updateDateOfCharts('custom')">
          {{ $t("OK") }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
export default {
  name: "FilterDateMonthRange",
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: {},
    dialog: false,
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}.date`]) {
      this.filterData.date = this.filterAllData[`${this.filter.name}.date`];
    }
    if (this.filterAllData[`${this.filter.name}.select`]) {
      this.filterData.select = this.filterAllData[`${this.filter.name}.select`];
    }
  },
  computed: {
    dateTypes() {
      return [
        { text: this.$t("this_year"), value: "this_year" },
        { text: this.$t("all_time"), value: "all_time" },
        { text: this.$t("custom"), value: "custom" },
      ];
    },
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    updateDateOfCharts(val) {
      if (val === "custom") {
        if (!this.dialog) {
          this.dialog = !this.dialog;
        } else {
          this.dialog = !this.dialog;
        }
      }
    },
    resetFilterData() {
      this.filterData = {};
    },
    updateData() {
      const all = this.filterAllData;
      all[`${this.filter.name}.date`] = this.filterData["date"];
      all[`${this.filter.name}.select`] = this.filterData["select"];
      this.$store.commit(SET_FILTERS_DATA, all);
    },
  },
  filterData: {
    handler() {
      const all = this.filterAllData;
      all[`${this.filter.name}.date`] = this.filterData.date;
      all[`${this.filter.name}.select`] = this.filterData.select;
      this.$store.commit(SET_FILTERS_DATA, all);
    },
    deep: true,
  },
};
</script>
