<template>
  <v-row
    class="mt-6 mb-16 pb-6 mx-1"
    :class="{ 'remove-margin': removeSomeMargins === true }"
  >
    <v-col class="ma-0 pa-0" cols="12" v-for="(filter, i) in filters" :key="i">
      <div
        v-if="filter['type'] === 'report_date_month_range'"
        class="myfilters poppins"
      >
        <FilterDateMonthRange
          :filter="filter"
          ref="filter"
        ></FilterDateMonthRange>
      </div>
      <div v-if="filter['type'] === 'select2Single'" class="myfilters poppins">
        <FilterSelect :filter="filter" ref="filter"></FilterSelect>
      </div>
      <div
        v-if="filter['type'] === 'autocompleteMultiple'"
        class="myfilters poppins"
      >
        <FilterMultiAutocomplete
          :filter="filter"
          ref="filter"
        ></FilterMultiAutocomplete>
      </div>
      <div v-if="filter['type'] === 'range'" class="myfilters poppins">
        <FilterRangeSlider :filter="filter" ref="filter"></FilterRangeSlider>
      </div>
      <div v-if="filter['type'] === 'phone'" class="myfilters poppins">
        <FilterPhone :filter="filter" ref="filter"></FilterPhone>
      </div>
      <div
        v-if="filter['type'] === 'multiImageSelect'"
        class="myfilters poppins"
      >
        <FilterMultiSelectWithImage
          :filter="filter"
          ref="filter"
        ></FilterMultiSelectWithImage>
      </div>
      <div v-if="filter['type'] === 'multipleSelect'" class="myfilters poppins">
        <FilterMultiSelect :filter="filter" ref="filter"></FilterMultiSelect>
      </div>
      <div v-else-if="filter['type'] === 'date'" class="myfilters poppins">
        <FilterDate :filter="filter" ref="filter"></FilterDate>
      </div>
      <div
        v-else-if="filter['type'] === 'date_unlimited'"
        class="myfilters poppins"
      >
        <FilterDateUnlimited
          :filter="filter"
          ref="filter"
        ></FilterDateUnlimited>
      </div>
      <div
        v-else-if="filter['type'] === 'select2DateRange'"
        class="myfilters poppins"
      >
        <!--        <FilterDateRangeWithSelect-->
        <!--          :filter="filter"-->
        <!--          ref="filter"-->
        <!--        ></FilterDateRangeWithSelect>-->
        <FilterDateRangeWithSelectNew
          :filter="filter"
          ref="filter"
        ></FilterDateRangeWithSelectNew>
      </div>
      <div v-else-if="filter['type'] === 'inputText'" class="myfilters poppins">
        <FilterTextInput
          :submitFilterValues="submitFilterValues"
          :filter="filter"
          ref="filter"
        ></FilterTextInput>
      </div>
      <div
        v-else-if="filter['type'] === 'inputTextMultiple'"
        class="myfilters poppins me-2 mb-6 min-h-25px"
      >
        <FilterTextInputMultipleNew :filter="filter" ref="filter" />

        <!--        <FilterTextInputMultiple-->
        <!--          :filter="filter"-->
        <!--          ref="filter"-->
        <!--        ></FilterTextInputMultiple>-->
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FilterMultiSelectWithImage from "@/own/components/filter/filterTypes/FilterMultiSelectWithImage.vue";
import FilterMultiSelect from "@/own/components/filter/filterTypes/FilterMultiSelect.vue";
import FilterSelect from "@/own/components/filter/filterTypes/FilterSelect.vue";
import FilterPhone from "@/own/components/filter/filterTypes/FilterPhone.vue";
import FilterRangeSlider from "@/own/components/filter/filterTypes/FilterRangeSlider.vue";
import FilterDateMonthRange from "@/own/components/filter/filterTypes/FilterDateMonthRange.vue";
import FilterDate from "@/own/components/filter/filterTypes/FilterDate.vue";
import FilterDateUnlimited from "@/own/components/filter/filterTypes/FilterDateUnlimited.vue";
// import FilterDateRangeWithSelect from "@/own/components/filter/filterTypes/FilterDateRangeWithSelect.vue";
import FilterDateRangeWithSelectNew from "@/own/components/filter/filterTypes/FilterDateRangeWithSelectNew.vue";

import FilterTextInput from "@/own/components/filter/filterTypes/FilterTextInput.vue";
import FilterTextInputMultipleNew from "@/own/components/filter/filterTypes/FilterTextInputMultipleNew.vue";
import FilterMultiAutocomplete from "@/own/components/filter/filterTypes/FilterMultiAutocomplete.vue";

export default {
  name: "FilterSelector",
  components: {
    FilterMultiSelect,
    FilterDateMonthRange,
    FilterSelect,
    FilterRangeSlider,
    FilterPhone,
    FilterMultiSelectWithImage,
    FilterDate,
    // FilterDateRangeWithSelect,
    FilterDateRangeWithSelectNew,
    FilterTextInput,
    FilterTextInputMultipleNew,
    FilterDateUnlimited,
    FilterMultiAutocomplete,
  },
  // props: ["filters", "submitFilterValues"],
  props: {
    /**
     * @type {
     {
     name: String,
     title: String,
     related_columns: Array,
     type: String,
     values: Array,
     }
     }
     */
    filters: {
      required: true,
      type: Array,
    },
    submitFilterValues: {
      required: true,
      type: Function,
    },
    removeSomeMargins: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clearData: function () {
      this.$refs.filter.forEach((filter) => {
        filter.resetFilterData();
      });
    },
  },
};
</script>

<style scoped>
.remove-margin {
  padding-bottom: 0 !important;
  margin: 12px 8px 0px !important;
}
</style>
