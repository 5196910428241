<template>
  <div v-if="itemForAction">
    <span class="second-text">
      <v-btn color="primary" small @click="toggleModal">{{
        itemForAction.title
      }}</v-btn>
    </span>
    <div>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        persistent
        max-width="1000px"
      >
        <!--begin::Modal dialog-->
        <div class="bg-white poppins">
          <!--begin::Modal header-->
          <div class="modal-header align-center">
            <h3 class="mb-0 font-weight-bolder">{{ itemForAction.title }}</h3>
            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-light-info"
              @click="dialog = false"
            >
              <span class="svg-icon">
                <v-icon size="22">mdi-close</v-icon>
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--begin::Modal header-->
          <!--begin::Modal body-->
          <div
            class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7"
          >
            <!--begin::Body-->
            <div
              v-for="(item, index) in itemForAction.data"
              :key="item.name + index"
            >
              <span v-if="item">{{ item.name }} </span>
            </div>
            <!--end::Body-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal dialog-->
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowServices",
  props: ["itemForAction"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
.tab-title {
  font-size: 1.3rem;
}
.tab-box {
  font-size: 1.2rem;
}
</style>
