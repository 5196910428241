import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_MAIN_MENU = "updateMAINMenu";

// mutation types
export const SET_MAIN_MENU = "setMAINMenu";
export const SET_ITEM_FOR_ACTION = "setItemForAction";
export const SET_POPUP_CLOSED_FLAG = "setPopupClosedFlagForResetting";
export const SET_HAS_THEME_SET = "setHasThemeSet";
export const MAKE_DEFAULT_HAS_THEME_SET = "makeDefaultHasThemeSet";

const state = {
  menus: [],
  itemForAction: null,
  popupClosedFlagForResetting: false,
  hasThemeSet: 0, // it is like flag, we will stop assigning global theme variables if it reaches 2
};

const getters = {
  getMAINMenu(state) {
    return state.menus;
  },
  getItemForAction(state) {
    return state.itemForAction;
  },
  getPopupClosedFlagForResetting(state) {
    return state.popupClosedFlagForResetting;
  },
  getHasThemeSet(state) {
    return state.hasThemeSet;
  },
};

const actions = {
  [UPDATE_MAIN_MENU](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      // ApiService.post("login", credentials)
      ApiService.setHeader();
      ApiService.post("/home", payload)
        .then(({ data }) => {
          // console.log("data of datatable ", data);
          context.commit(SET_MAIN_MENU, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 1000);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_MAIN_MENU](state, menus) {
    state.menus = menus;
  },
  [SET_ITEM_FOR_ACTION](state, item) {
    state.itemForAction = null;
    state.itemForAction = item;
  },
  [SET_POPUP_CLOSED_FLAG](state, flag) {
    state.popupClosedFlagForResetting = flag;
  },
  [SET_HAS_THEME_SET](state, qty) {
    state.hasThemeSet += qty;
  },
  [MAKE_DEFAULT_HAS_THEME_SET](state, qty) {
    state.hasThemeSet = qty;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
