<template>
  <section>
    <v-row class="ma-0">
      <v-col cols="12" class="p-4 bg-white rounded-lg custom-container-height">
        <div class="mx-0 mx-sm-8 mt-4">
          <datatable-detail
            class="col-9 col-md-9 col-sm-9"
            :table_properties="qcProperties"
          ></datatable-detail>
        </div>
        <v-col class="mt-8 mb-12 mx-0 mx-sm-10 pa-sm-0" cols="12" sm="6" md="4">
          <div class="mb-10 d-flex align-center">
            <v-icon color="#9EA1B6">info</v-icon>
            <h3
              class="ml-4 mb-0 poppins"
              style="color: #263238; font-size: 24px"
            >
              Scan Unit
            </h3>
          </div>
          <v-text-field
            :disabled="!$store.getters.getSelectedWarehouse"
            outlined
            clearable
            label="Scan barcode or condition code"
            append-icon="mdi-barcode-scan"
            autofocus
            class="poppins"
            @blur="$event.target.focus()"
            @click:append="handleClickBarcode"
            @keyup.enter="handleClickBarcode"
            v-model="barcode"
          />
        </v-col>
        <div v-if="result">
          <v-row
            v-for="(sku, index) in skus"
            :key="index"
            class="mx-0 mx-sm-10 mb-10 poppins"
          >
            <v-col cols="12" md="8" class="mb-8 mb-md-0 pa-0">
              <v-card class="mx-auto" outlined>
                <v-card-title class="mt-4 ml-3 text-h4 text--primary poppins"
                  >SKU</v-card-title
                >
                <v-card-text class="pl-8">
                  <div class="text--primary">
                    <div
                      class="d-flex flex-sm-row align-sm-center flex-column font-size-h5 mb-4 custom-content"
                    >
                      <div>
                        <span class="text-h6">Code</span>:
                        {{ sku.code }}
                      </div>
                      <div class="mt-4 mt-sm-0 w-500px">
                        <span class="text-h6">Delivery number</span>:
                        {{ sku.delivery_no }}
                      </div>
                    </div>
                    <p class="font-size-h5">
                      <span class="text-h6">Description</span>:
                      {{ sku.description }}
                    </p>
                    <div class="d-flex align-center flex-wrap font-size-h5">
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">Brand</span>:
                        {{ sku.brand }}
                      </div>
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">Category</span>:
                        {{ sku.category }}
                      </div>
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">Sub category</span>:
                        {{ sku.sub_category }}
                      </div>
                    </div>
                    <div class="d-flex align-center flex-wrap font-size-h5">
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">Size</span>:
                        {{ sku.size }}
                      </div>
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">Condition</span>:
                        {{ sku.condition_type }}
                      </div>
                      <span
                        class="custom-content-33 text-h6 d-flex align-center mb-4"
                        >Authentication:
                        <span
                          class="label label-lg label-inline font-weight-bold ml-2 py-4"
                          :class="
                            sku.need_authentication === 'Yes'
                              ? 'label-light-success'
                              : 'label-light-danger'
                          "
                          >{{ sku.need_authentication }}</span
                        >
                      </span>
                    </div>
                    <div class="d-flex align-center flex-wrap font-size-h5">
                      <div class="custom-content-20 mb-4">
                        <span class="text-h6">Weight</span>:
                        {{ sku.weight }}
                      </div>
                      <div class="custom-content-20 mb-4">
                        <span class="text-h6">Cube</span>:
                        {{ sku.cube }}
                      </div>
                      <div class="custom-content-20 mb-4">
                        <span class="text-h6">Length</span>:
                        {{ sku.length }}
                      </div>
                      <div class="custom-content-20 mb-4">
                        <span class="text-h6">Width</span>:
                        {{ sku.width }}
                      </div>
                      <div class="custom-content-20 mb-4">
                        <span class="text-h6">Height</span>:
                        {{ sku.height }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <v-divider class="mx-6 my-0"></v-divider>
                <v-card-title class="mt-4 ml-3 text-h4 text--primary poppins"
                  >Supplier</v-card-title
                >
                <v-card-text class="pl-8">
                  <div class="text--primary">
                    <div
                      class="d-flex align-center flex-wrap font-size-h5 custom-content"
                    >
                      <div class="max-w-50 max-w-sm-220px mb-4">
                        <span class="text-h6">ID</span>:
                        {{ sku.supplier.supplier_id }}
                      </div>
                      <div class="mb-4">
                        <span class="text-h6">Name</span>:
                        {{ sku.supplier.supplier_name }}
                      </div>
                    </div>
                    <div
                      class="d-flex align-center flex-wrap font-size-h5 custom-content"
                    >
                      <div class="max-w-50 max-w-sm-220px mb-4">
                        <span class="text-h6"
                          >Phone:
                          <span
                            class="font-weight-regular"
                            :class="
                              sku.supplier.phone !== '-' &&
                              'text-decoration-underline'
                            "
                            >{{ sku.supplier.phone }}</span
                          >
                        </span>
                      </div>
                      <span class="text-h6 mb-4"
                        >Email:
                        <span
                          class="font-weight-regular"
                          :class="
                            sku.supplier.email !== '-' &&
                            'text-decoration-underline'
                          "
                          >{{ sku.supplier.email }}</span
                        >
                      </span>
                    </div>
                    <div class="d-flex align-center flex-wrap font-size-h5">
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">Country</span>:
                        {{ sku.supplier.country }}
                      </div>
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">City</span>:
                        {{ sku.supplier.city }}
                      </div>
                      <div class="custom-content-33 mb-4">
                        <span class="text-h6">Address</span>:
                        {{ sku.supplier.address_1 }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="pa-0" v-if="sku.image_url">
              <div
                class="d-flex justify-content-center align-center max-h-275px"
              >
                <img :src="sku.image_url" alt="image" class="max-h-275px" />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import { UPDATE_QC_DATA } from "@/core/services/store/qc.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "QC",
  components: { DatatableDetail },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  data: function () {
    return {
      barcode: "",
      result: false,
    };
  },
  computed: {
    qcProperties: function () {
      return {
        title: "QC",
        description: "SKU Unit Management",
      };
    },
    skus: function () {
      if (this.$store.getters.getQCData) {
        let skusGroup = this.$store.getters.getQCData;
        skusGroup.forEach((item) => {
          if (item.need_authentication !== null) {
            if (item.need_authentication !== 0)
              item.need_authentication = "Yes";
            else item.need_authentication = "No";
          } else {
            item.need_authentication = "-";
          }
        });
        skusGroup.forEach((sku) => {
          Object.keys(sku).forEach((prop) => {
            if (sku[prop] === null && sku[prop] !== "supplier") sku[prop] = "-";
          });
          Object.keys(sku.supplier).forEach((prop) => {
            if (sku.supplier[prop] === null) sku.supplier[prop] = "-";
          });
        });
        return skusGroup;
      }
      return [];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    handleClickBarcode: function () {
      this.$store.dispatch(UPDATE_QC_DATA, { sku: this.barcode }).then(() => {
        if (this.$store.getters.getQCData) this.result = true;
        this.barcode = "";
      });
    },
  },
};
</script>

<style scoped>
.v-application .text-h6 {
  font-family: "Poppins", sans-serif !important;
}

.custom-content div:first-of-type {
  width: 33%;
}

.custom-content-20 {
  width: 20%;
}

.custom-content-33 {
  width: 33%;
}

.custom-content div:nth-of-type(2) {
  width: 210px;
}

.custom-content span + span {
  margin-left: 50px;
}

@media (max-width: 600px) {
  .custom-content {
    width: 100% !important;
  }
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
