<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Close Cycle count</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <v-text-field
          ref="ccnumber"
          v-model="cycle_count_number"
          label="Cycle count number"
          clearable
          type="text"
          outlined
          dense
          :error-messages="errorMessage"
          @blur="
            cycle_count_number.length ? (isToggled = false) : (isToggled = true)
          "
          append-icon="mdi-barcode-scan"
        ></v-text-field>
        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            class="btn btn--print-upload mr-3 px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import {
  CLOSE_CYCLE_COUNT,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/cycleCounts.module";

export default {
  name: "CloseCycleCount",
  props: ["pageLoading", "refresher"],
  data: () => ({
    dialog: false,
    cycle_count_number: null,
    isToggled: false,
  }),
  computed: {
    errorMessage: function () {
      return this.isToggled ? "This field is required" : "";
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    table_state: function () {
      return this.$store.getters.getRANDOMCYCLECOUNTTableState;
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      if (this.dialog) {
        setTimeout(() => {
          this.$refs.ccnumber.focus();
        }, 100);
      } else {
        this.cycle_count_number = null;
      }
    },
    submit() {
      if (!this.cycle_count_number) {
        this.isToggled = true;
        return;
      }
      this.pageLoading(true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
        cycle_count_number: this.cycle_count_number,
      };
      this.$store
        .dispatch(CLOSE_CYCLE_COUNT, payload)
        .then(() => {
          this.toggleModal();
          Swal.fire({
            title: "Success",
            icon: "success",
            text: `Cycle Count Task Closed`,
            timer: 2500,
          });
        })
        .then(() => {
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            ...this.table_state,
            warehouse_id: this.selectedWarehouse,
          });
        });
    },
  },
};
</script>
