<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.measurement_type_id"
            dense
            label="Measurement types"
            item-text="text"
            item-value="index"
            clearable
            outlined
            :items="serverData.measurement_types"
            hide-no-data
            hide-selected
            :error-messages="measurement_type_idErrors"
            @blur="$v.formData.measurement_type_id.$touch()"
          />
        </div>

        <div class="h-75px">
          <v-autocomplete
            v-model="formData.measurement_class_id"
            :items="serverData.measurement_classes"
            item-text="text"
            item-value="index"
            label="Measurement classes"
            dense
            outlined
            clearable
            :error-messages="measurement_class_idErrors"
            @blur="$v.formData.measurement_class_id.$touch()"
          />
        </div>

        <div class="h-75px">
          <v-text-field
            v-model="formData.code"
            label="Code"
            dense
            outlined
            clearable
            :error-messages="codeErrors"
            @blur="$v.formData.code.$touch()"
          />
        </div>
        <div class="h-75px">
          <v-text-field
            v-model="formData.description"
            label="Description"
            dense
            outlined
            clearable
          />
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  validations: {
    formData: {
      measurement_type_id: { required },
      measurement_class_id: { required },
      code: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: [],
    formData: {
      id: null,
      measurement_type_id: null,
      measurement_class_id: null,
      code: null,
      description: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/organization/uom_and_package_types/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = data.uom_and_package_type;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        this.pageLoader(true);
        ApiService.post("/organization/uom_and_package_types/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      let sendData = {
        ...this.formData,
      };

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      this.pageLoader(true);
      ApiService.post(
        `/organization/uom_and_package_types/${actionUrl}`,
        sendData
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.formData = {
        measurement_type_id: null,
        measurement_class_id: null,
        code: null,
        description: null,
      };
    },
  },
  computed: {
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    measurement_type_idErrors: function () {
      return this.handleFormValidation("measurement_type_id");
    },
    measurement_class_idErrors: function () {
      return this.handleFormValidation("measurement_class_id");
    },
    codeErrors: function () {
      return this.handleFormValidation("code");
    },
  },
};
</script>

<style>
.v-radio label {
  margin-bottom: 0;
}
</style>
