<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="serverData"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          {{ $t("add_new_staff_account") }}
        </h3>
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div class="row">
          <div
            class="h-75px col-12"
            :class="{ 'col-md-6': !$store.getters.isClient }"
            v-if="!$store.getters.isClient"
          >
            <v-autocomplete
              dense
              outlined
              v-model="formData.customer_id"
              :items="serverData.clients"
              item-name="text"
              item-value="index"
              :label="$t('customer')"
              clearable
              :error-messages="customer_idErrors"
              @blur="$v.formData.customer_id.$touch()"
            ></v-autocomplete>
          </div>

          <div
            class="h-75px col-12"
            :class="{ 'col-md-6': !$store.getters.isClient }"
          >
            <v-text-field
              dense
              outlined
              v-model="formData.name"
              :label="$t('name')"
              required
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div :class="['h-75px col-12', isOrganizationSPL ? '' : 'col-md-6']">
            <v-text-field
              dense
              outlined
              v-model="formData.email"
              :label="$t('email')"
              clearable
              :error-messages="emailErrors"
              @blur="$v.formData.email.$touch()"
            />
          </div>

          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.username"
              :label="$t('Username')"
              dense
              outlined
            />
          </div>

          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.password"
              :label="$t('password')"
              dense
              outlined
              clearable
              :error-messages="passwordErrors"
              @blur="$v.formData.password.$touch()"
            />
          </div>

          <div class="h-75px col-12 col-sm-6">
            <v-text-field
              v-model="formData.password_confirmation"
              :label="$t('confirm_password')"
              dense
              outlined
              clearable
              :error-messages="password_confirmationErrors"
              @blur="$v.formData.password_confirmation.$touch()"
            />
          </div>

          <div class="h-75px col-12">
            <v-file-input
              outlined
              v-model="formData.photo"
              multiple
              dense
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              :label="$t('photo')"
              clearable
            />
          </div>

          <div class="h-75px col-12">
            <v-text-field
              dense
              outlined
              v-model="formData.description"
              :label="$t('description')"
              clearable
            />
          </div>
          <div
            class="h-75px col-12"
            v-if="serverData.hasOwnProperty('warehouses')"
          >
            <v-autocomplete
              dense
              outlined
              v-model="formData.warehouses"
              :items="serverData.warehouses"
              item-name="text"
              item-value="index"
              :label="$t('Warehouses')"
              clearable
              multiple
              hide-selected
              chips
              :error-messages="warehousesErrors"
              @blur="$v.formData.warehouses.$touch()"
            ></v-autocomplete>
          </div>
        </div>

        <v-card class="my-6" v-if="serverData.is_role_required">
          <v-toolbar-title
            class="h3 text-white text-center pa-1"
            :style="{
              'background-color':
                $store.getters.currentUser.theme['text_color'],
            }"
            >{{ $t("roles") }}
          </v-toolbar-title>
          <div
            v-if="formData.roles.length === 0"
            class="text-danger text-left text-h6 ml-4"
            :class="{ 'text-right mr-4': $vuetify.rtl }"
          >
            {{ $t("role_is_required") }}
          </div>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="4"
                v-for="role in serverData.roles"
                :key="role.value"
              >
                <v-switch
                  class="mt-0 pt-0"
                  hide-details
                  v-model="formData.roles"
                  :color="$store.getters.currentUser.theme['text_color']"
                  :label="role.text"
                  :value="role.index"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mx-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn--export-filter px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            {{ $t("submit") }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import axios from "axios";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import SwalService from "@/core/services/swal.service";
import { addItemMessage } from "@/core/constants/globalVariables";

export default {
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        name: { required },
        email: {
          required: requiredIf(function() {
            return !this.formData.username;
          }),
          email,
        },
        password: {
          required: requiredIf(function() {
            return this.formData.username;
          }),
        },
        password_confirmation: {
          sameAsPassword: () => {
            return (
              this.formData.password === this.formData.password_confirmation
            );
          },
          required: requiredIf(function() {
            return this.formData.username;
          }),
        },
        customer_id: { required },
        warehouses: requiredIf(function() {
          return this.serverData.hasOwnProperty("warehouses");
        }),
      },
    };
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      name: "",
      email: "",
      username: "",
      password: "",
      password_confirmation: "",
      description: "",
      parent_id: null,
      customer_id: null,
      photo: null,
      roles: [],
      warehouses: [],
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/create`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
          this.serverData = response.data;
          if (this.user_data.is_client) {
            this.formData.customer_id = this.user_data.id;
          }
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it, index) => {
              if (typeof it === "object" && !Array.isArray(it) && it !== null) {
                Object.entries(it).forEach((el) => {
                  data.append(`${item[0]}[${index}][${el[0]}]`, el[1]);
                });
              } else {
                data.append(`${item[0]}[]`, it);
              }
            });
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/authorizations/accounts/store`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            SwalService.successMessage({
              title: this.$t("created"),
              html: `${addItemMessage}`,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        name: null,
        email: null,
        username: "",
        password: "",
        password_confirmation: "",
        description: null,
        parent_id: null,
        customer_id: null,
        photo: null,
        roles: [],
        warehouses: [],
      };
    },
  },
  computed: {
    user_data: function() {
      return this.$store.getters.currentUser.data;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    emailErrors() {
      return this.handleFormValidation("email", this);
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    passwordErrors() {
      return this.handleFormValidation("password", this);
    },
    password_confirmationErrors() {
      if (this.formData.username && this.formData.password)
        return this.handleFormValidation("password_confirmation", this);
      else return [];
    },
    warehousesErrors() {
      return this.handleFormValidation("warehouses", this);
    },
    isOrganizationSPL() {
      return (
        this.$store.getters.currentUser.data.organization_system_code === "SPL"
      );
    },
  },
};
</script>
