<template>
  <div>
    <div class="">
      <v-alert
        dense
        border="left"
        type="warning"
        dismissible
        v-if="exampleFileData[0].need_to_show_message"
      >
        <span v-html="exampleFileData[0].message"></span>
      </v-alert>
    </div>
    <div class="px-6">
      <div class="row">
        <div class="col-12 h-75px" v-if="importTypeData.data?.organizations">
          <v-select
            clearable
            dense
            outlined
            :label="$t('organization')"
            v-model="formData.organization_id"
            :items="importTypeData.data.organizations"
            item-text="text"
            item-value="index"
            class="px-2"
            :error-messages="organizationErrors"
            @input="$v.formData.organization_id.$touch()"
            @blur="$v.formData.organization_id.$touch()"
          />
        </div>
        <div class="col-12" v-if="importTypeData.data?.customers">
          <v-select
            clearable
            dense
            outlined
            :label="$t('customer')"
            v-model="formData.customer_id"
            :items="importTypeData.data.customers"
            item-text="text"
            item-value="index"
            class="px-2"
            :error-messages="customerErrors"
            @input="$v.formData.customer_id.$touch()"
            @blur="$v.formData.customer_id.$touch()"
          />
        </div>
        <div class="col-12 h-75px" v-if="importTypeData.data?.customers">
          <v-select
            clearable
            dense
            outlined
            :label="$t('customer')"
            v-model="formData.customer_id"
            :items="importTypeData.data.customers"
            item-text="text"
            item-value="index"
            class="px-2"
            :error-messages="customerErrors"
            @input="$v.formData.customer_id.$touch()"
            @blur="$v.formData.customer_id.$touch()"
          ></v-select>
        </div>
        <div class="col-12 col-sm-8 col-md-9 h-75px">
          <v-file-input
            v-model="formData.file"
            clearable
            ref="file_uploader"
            :label="$t('file_input')"
            outlined
            dense
            required
            :error-messages="fileErrors"
            @input="$v.formData.file.$touch()"
            @blur="$v.formData.file.$touch()"
          ></v-file-input>
        </div>
        <div class="col-12 col-sm-4 col-md-3 text-center">
          <v-btn class="primary--text" outlined text :href="exampleFileData">
            {{ $t("download_template") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
      <button
        type="reset"
        class="btn btn-light mr-3 px-5 py-3 ls1"
        @click="resetForm"
      >
        {{ $t("clear") }}
      </button>
      <button
        type="submit"
        class="btn btn--export-filter px-5 py-3 ls1"
        @click="submitForm"
      >
        {{ $t("upload") }}
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "UMOConversions",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        file: { required },
        organization_id: {
          required: requiredIf(() => {
            return this.importTypeData.data?.organizations;
          }),
        },
        customer_id: {
          required: requiredIf(() => {
            return this.importTypeData.data?.customers;
          }),
        },
      },
    };
  },
  props: {
    importTypeData: {
      required: true,
      type: Object,
    },
    submit: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    formData: {
      file: null,
      organization_id: null,
      customer_id: null,
    },
  }),
  computed: {
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    clients: function() {
      return this.importTypeData.data.customers;
    },
    organizations: function() {
      return this.importTypeData.data.organizations;
    },
    exampleFileData: function() {
      const sample = this.importTypeData.data.files[0].url;
      return sample;
    },
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
    organizationErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    customerErrors() {
      return this.handleFormValidation("customer_id", this);
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let data = new FormData();
      data.append("file", this.formData.file, this.formData.file.name);
      data.append("type", this.importTypeData.type);
      if (this.formData.organization_id) {
        data.append("organization_id", this.formData.organization_id);
      }
      data.append("customer_id", this.formData.customer_id);
      this.submit(data);
      this.$nextTick(() => {
        this.resetForm();
      });
    },
    resetForm() {
      this.formData = {
        file: null,
        organization_id: null,
        customer_id: null,
      };
    },
  },
};
</script>

<style></style>
