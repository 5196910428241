<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Countries</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-200px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-autocomplete
            v-model.trim="$v.selectedCountries.$model"
            :error-messages="
              validateState('selectedCountries') ? errorMessages : ''
            "
            :items="countries"
            dense
            outlined
            chips
            small-chips
            item-text="name"
            item-value="id"
            label="Validations"
            multiple
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @click="selectAll">
                <v-list-item-action>
                  <v-checkbox v-model="checkbox" disabled></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </template>
          </v-autocomplete>
        </form>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
    <div style="flex: 1 1 auto"></div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    selectedCountries: { required },
  },
  name: "AddItem",
  props: ["refresher", "loader", "countries", "integration_id"],
  data: () => ({
    dialog: false,
    selectedCountries: [],
    errorMessages: ["This Field is required"],
    checkbox: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? $error : null;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.loader(true);
      let data = {
        integration_id: this.integration_id,
        countries: this.selectedCountries,
      };
      ApiService.post("/validations/countriesMapping/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: "The Countries data were added",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    resetCreateForm() {
      this.selectedCountries = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    selectAll() {
      this.checkbox = !this.checkbox;
      if (this.checkbox) {
        this.selectedCountries = this.countries.map((item) => item.id);
      } else {
        this.selectedCountries = [];
      }
    },
  },
};
</script>
