<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon svg-icon-5">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-6 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0 row">
          <!--          <div class="h-75px col-12 my-0 py-0 col-sm-6">-->
          <!--            <v-select-->
          <!--              v-model="formData.customer_id"-->
          <!--              label="Customer"-->
          <!--              item-text="text"-->
          <!--              item-value="index"-->
          <!--              :items="serverData.customers"-->
          <!--              clearable-->
          <!--              outlined-->
          <!--              dense-->
          <!--              hide-details-->
          <!--              required-->
          <!--              :error-messages="customer_idErrors"-->
          <!--              @input="$v.formData.customer_id.$touch()"-->
          <!--              @blur="$v.formData.customer_id.$touch()"-->
          <!--            ></v-select>-->
          <!--          </div>-->
          <div class="h-75px col-12 my-0 py-0">
            <v-text-field
              v-model="formData.output"
              :label="$t('category')"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.from_weight"
              :label="$t('from_weight_in_kg')"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.to_weight"
              :label="$t('to_weight_in_kg')"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.from_volume"
              :label="$t('from_volume_in_cm')"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.to_volume"
              :label="$t('to_volume_in_cm')"
              clearable
              outlined
              dense
            />
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetForm"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  name: "ActionsDialog",
  // mixins: [validationMixin],
  // validations: {
  //   formData: {
  //     customer_id: { required },
  //   },
  // },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      // customer_id: null,
      from_weight: null,
      to_weight: null,
      from_volume: null,
      to_volume: null,
      output: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        this.pageLoader(true);
        let data = { id: this.$store.getters.getItemForAction.id };
        ApiService.post("/wes/wesSplSortingItemConfiguration/edit", data)
          .then((response) => {
            this.serverData = response.data;
            this.formData = response.data.data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        this.pageLoader(true);
        ApiService.post("/wes/wesSplSortingItemConfiguration/create")
          .then((response) => {
            this.serverData = response.data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.loader(false);
          });
      }
    },

    toggleModal() {
      if (this.dialog) {
        this.resetForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },

    submitForm() {
      // this.$v.$touch();
      this.pageLoader(true);
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/wes/wesSplSortingItemConfiguration/${actionUrl}`, {
        ...this.formData,
      })
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetForm() {
      // this.$v.$reset();
      this.formData = {
        // customer_id: null,
        from_weight: null,
        to_weight: null,
        from_volume: null,
        to_volume: null,
        output: null,
      };
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return this.$t("update");
      else return this.$t("submit");
    },
    headerText: function () {
      if (this.actionType) return this.$t("edit_item");
      else return this.$t("add_new_item");
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    // customer_idErrors: function () {
    //   return this.handleFormValidation("customer_id", this);
    // },
  },
};
</script>
