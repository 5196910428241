<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="mb-7 mx-10 mt-6 d-flex align-center justify-space-between">
      <datatable-detail class="col-md-9" :table_properties="tableProperties" />

      <div class="justify-content-end px-4">
        <button
          class="btn btn--export-filter float-end"
          @click.prevent="submitPlan"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-cloud-upload</v-icon>
          </span>
          Submit
        </button>
      </div>
    </div>

    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="plans"
        :handleGetFunction="handlePlanSelect"
        :handleCreatePlan="handleCreatePlan"
      />
      <NestedDataTable
        tableName="chutes"
        :handleGetFunction="handleChuteSelect"
      />
      <!--      <NestedForm ref="nestedForm" />-->

      <NestedDataTable
        tableName="locations"
        :handleGetFunction="handleLocationSelect"
      />
      <NestedDataTable tableName="categories" />
    </div>
    <CreatePlan ref="createPlan" />
  </div>
</template>

<script>
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import wesSortPlan from "@/own/mixins/wesSortPlanMixin";
import NestedDataTable from "@/own/components/warehouseExecutionSystem/sortPlan/NestedDatatable.vue";
import CreatePlan from "@/own/components/warehouseExecutionSystem/sortPlan/CreatePlan.vue";
// import NestedForm from "@/own/components/warehouseExecutionSystem/sortPlan/NestedForm.vue";

export default {
  name: "WESSortPlan",
  components: {
    NestedDataTable,
    CreatePlan,
    DatatableDetail,
    // NestedForm,
  },
  mixins: [wesSortPlan],
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 327px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 300px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 285px) !important;
  }
}

@media (max-width: 768px) {
  .custom-container-height {
    height: calc(100vh - 275px) !important;
  }
}
</style>
