<template>
  <div>
    <v-menu
      content-class="m-0 p-0"
      transition="scale-transition"
      offset-y
      bottom
      nudge-bottom="5"
      :left="!$vuetify.rtl"
      :right="$vuetify.rtl"
      rounded
      max-height="75%"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-on="on"
          v-bind="attrs"
          class="poppins btn btn--export-filter px-5"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-dns</v-icon>
          </span>
          <span>Actions</span>
          <span class="svg-icon mr-0 ml-2">
            <v-icon size="18">mdi-chevron-down</v-icon>
          </span>
        </button>
      </template>
      <div class="py-1 w-175px bg-white px-3">
        <div
          class="drop-item poppins px-3 rounded-lg cursor-pointer"
          v-for="(action, index) in types"
          :key="index"
          @click="() => handleClick(action.url)"
        >
          <div
            class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
            :class="[
              { 'mt-2': index === 0 },
              { 'mb-2': index === types.length - 1 },
            ]"
          >
            {{ action.name }}
          </div>
        </div>
      </div>
    </v-menu>
    <component
      ref="reassignCarreir"
      v-bind:is="reassignCarrierModal"
      @onSelectCarrier="onSelectCarrier"
    ></component>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import ReassignCarrierModal from "@/own/components/fulfillment/orders/ReassignCarrierModal.vue";
// import swalEdited from "@/core/services/AlertServices/SwalEdited";

export default {
  name: "ActionsButton",
  props: ["items", "types", "refresher"],
  components: { ReassignCarrierModal },
  data: () => ({
    reassignCarrierModal: ReassignCarrierModal,
  }),
  methods: {
    handleClick(url) {
      if (this.items.length > 0) {
        if (url === "order_reassign_carrier_bulk") {
          this.$refs.reassignCarreir.toggleModal();
        } else {
          const isConfirmationRequired = this.permissions?.find(
            (permission) => permission.name === url
          )?.is_confirmation_required;

          if (isConfirmationRequired) {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes!",
            }).then((result) => {
              if (result.isConfirmed) {
                this.actionRequest(url);
              }
            });
          } else {
            this.actionRequest(url);
          }
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
        });
      }
    },
    onSelectCarrier(carrier) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let payload = { orders: this.selectedItems, carrier_id: carrier };

      ApiService.post(
        `/fulfillment/orders/order_reassign_carrier_bulk`,
        payload
      )
        .then(({ data }) => {
          // swalEdited();
          Swal.fire({
            title: "Completed",
            html: `<div>${data.messages
              .map((mes) => `<div>${mes}</div>`)
              .join("")}</div>`,
            icon: "info",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "OK",
          });
          this.refresher();
        })
        .catch(() => {
          // Swal.fire({
          //   title: "Error",
          //   html: `<div>${data.messages.map((mes)=> `<div>${mes}</div>`).join('')}</div>`,
          //   icon: "error",
          //   showConfirmButton: false,
          //   showCancelButton: true,
          //   cancelButtonText: "OK",
          // });
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    actionRequest(url) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data =
        url == "reset_replenishment"
          ? { orders: this.items.map((it) => it.id.id) }
          : { orders: this.items.map((it) => it.systemId) };

      ApiService.post(`/fulfillment/orders/${url}`, data)
        .then(({ data }) => {
          // swalEdited();
          try {
            Swal.fire({
              title: "Completed",
              html: data?.messages?.length
                ? `<div>${data?.messages
                    ?.map((mes) => `<div>${mes}</div>`)
                    .join("")}</div>`
                : "Record modified successfully",
              icon: "info",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
          } catch {
            Swal.fire({
              title: "Failed",
              html: "Opps..Something went wrong.",
              icon: "error",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
          }

          this.refresher();
        })
        .catch(() => {
          // Swal.fire({
          //   title: "Error",
          //   html: `<div>${data.messages.map((mes)=> `<div>${mes}</div>`).join('')}</div>`,
          //   icon: "error",
          //   showConfirmButton: false,
          //   showCancelButton: true,
          //   cancelButtonText: "OK",
          // });
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    selectedItems: function () {
      return this.items.map((item) => item.systemId);
    },
    permissions: function () {
      return this.$store.getters.getORDERSTablePermissions;
    },
  },
};
</script>
