<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600px">
    <v-card>
      <v-card-title class="d-flex justify-content-between h-75px">
        <div>
          <span class="font-weight-medium m-4 text-h5">Generate code</span>
        </div>
        <button @click="toggleModal">
          <span class="svg-icon mr-2">
            <v-icon size="18">mdi-close</v-icon>
          </span>
        </button>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="11" class="px-8">
              <FieldContainer
                v-if="serverData.organizations"
                :label="$t('organization')"
                :divider="true"
              >
                <v-autocomplete
                  v-model="formData.organization_id"
                  clearable
                  dense
                  outlined
                  class="rounded-lg h-50px"
                  required
                  item-text="text"
                  item-value="index"
                  :items="serverData.organizations"
                  :error-messages="organizationsErrors"
                  @blur="$v.formData.organization_id.$touch()"
                  @input="$v.formData.organization_id.$touch()"
                ></v-autocomplete>
              </FieldContainer>

              <FieldContainer :label="$t('type')" :divider="true">
                <v-autocomplete
                  v-model="formData.type_id"
                  clearable
                  dense
                  outlined
                  class="rounded-lg h-50px"
                  required
                  item-text="text"
                  item-value="index"
                  :items="serverData.types"
                  :error-messages="typeErrors"
                  @blur="$v.formData.type_id.$touch()"
                  @input="$v.formData.type_id.$touch()"
                ></v-autocomplete>
              </FieldContainer>

              <FieldContainer :label="$t('system_code')" :divider="true">
                <v-text-field
                  dense
                  outlined
                  class="rounded-lg h-50px"
                  clearable
                  v-model="formData.system_code"
                  :error-messages="systemCodeErrors"
                  @blur="$v.formData.system_code.$touch()"
                  @input="$v.formData.system_code.$touch()"
                />
              </FieldContainer>

              <FieldContainer :label="$t('description')" :divider="false">
                <v-text-field
                  v-model="formData.description"
                  dense
                  class="rounded-lg h-50px"
                  required
                  persistent-hint
                  outlined
                  clearable
                  :error-messages="descriptionErrors"
                  @blur="$v.formData.description.$touch()"
                  @input="$v.formData.description.$touch()"
                ></v-text-field>
              </FieldContainer>
            </v-col>
          </v-row>
          <v-row class="mt-10 mb-4">
            <v-col cols="11" class="pr-0">
              <v-row class="d-flex justify-content-end">
                <v-col cols="auto" class="mr-5">
                  <button
                    @click="submit"
                    type="submit"
                    class="btn btn-info px-5 py-3"
                  >
                    {{ this.$t("submit") }}
                  </button>
                </v-col>
              </v-row></v-col
            ></v-row
          >
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FieldContainer from "@/own/components/FieldContainer.vue";
import validationMixin from "@/own/mixins/validationMixin.js";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";
import { UPDATE_TABLE_DATA } from "@/core/services/store/reasonCode.module";

import { required, requiredIf, minLength } from "vuelidate/lib/validators";

export default {
  name: "ActionsDialog",
  mixins: [validationMixin, fieldValueValidation],
  components: { FieldContainer },
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return !!this.serverData.organizations;
        }),
      },
      type_id: {
        required: requiredIf(function () {
          return !!this.serverData.types;
        }),
      },
      system_code: { required, minLength: minLength(2) },
      description: {
        required,
      },
    },
  },
  data() {
    return {
      formData: {
        organization_id: null,
        type_id: null,
        system_code: null,
        description: null,
      },
      dialog: false,
      serverData: {
        organizations: [],
        types: [],
      },
    };
  },
  methods: {
    async toggleModal() {
      if (!this.dialog) {
        await this.loadServerData();
      }
      this.dialog = !this.dialog;
    },
    loadServerData() {
      this.$store.commit(SET_PAGE_LOADING, true);
      const slug = this.actionId ? "edit" : "create";
      const payload = { ...(this.actionId && { id: this.actionId.id }) };
      return ApiService.post(`organization/reason_codes/${slug}`, payload)
        .then((response) => {
          const { data } = response;
          this.serverData = data;
          if (data.reason_code) {
            this.formData = data.reason_code;
          }
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit(SET_PAGE_LOADING, true);

      const slug = this.actionId ? "update" : "store";

      return ApiService.post(
        `/organization/reason_codes/${slug}`,
        this.formData
      )
        .then(() => {
          SwalService.successMessage({
            title: this.actionId
              ? this.$t(this.$t("updated"))
              : this.$t(this.$t("created")),
            html: this.actionId
              ? this.$t(this.$t("updated_successfully"))
              : this.$t(this.$t("created_successfully")),
          });
        })
        .then(() => {
          return this.$store.dispatch(UPDATE_TABLE_DATA, {});
        })
        .then(() => {
          this.toggleModal();
          this.clearFields();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    clearFields() {
      this.formData = {
        organization_id: null,
        type_id: null,
        system_code: null,
        description: null,
      };
    },
  },
  computed: {
    organizationsErrors() {
      return this.handleFormValidation("organization_id", this, "formData");
    },
    typeErrors() {
      return this.handleFormValidation("type_id", this);
    },
    systemCodeErrors() {
      return this.handleFormValidation("system_code", this, "formData", {
        minLength: 2,
      });
    },
    descriptionErrors() {
      return this.handleFormValidation("description", this);
    },
    actionId() {
      return this.$store.getters.getItemForAction;
    },
  },
};
</script>
