<template>
  <ReportDynamicFilterDataTable :urls="urls" :mandatory-filter="false" />
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ReportDynamicFilterDataTable from "@/own/components/reports/ReportDynamicFilterDataTable.vue";
export default {
  name: "DynamicReport",
  components: { ReportDynamicFilterDataTable },
  data: () => ({
    urls: {
      data_url: "/reports/reportDynamic",
      show_url: "/reports/reportDynamic/show",
    },
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
