<template>
  <v-dialog
    v-model="dialog"
    max-width="850"
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
        @click="toggleModal"
      >
        {{ $t("view") }}
      </button>
    </template>
    <div class="bg-white poppins">
      <v-card-text class="px-8 pt-6 pb-0">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-row-bordered poppins"
          >
            <thead>
              <tr>
                <th class="pl-3" v-for="(item, index) in headers" :key="index">
                  <span class="text-dark-75">{{ item }}</span>
                </th>
              </tr>
            </thead>
            <tbody v-if="value.length > 0">
              <tr v-for="(item, index) in value" :key="index">
                <td class="px-3 text-dark-65 font-weight-bolder font-size-lg">
                  {{ item.id }}
                </td>
                <td class="px-3 text-dark-65 font-weight-bolder font-size-lg">
                  {{ item.is_repetitive === true ? "Repetitive" : "One time" }}
                </td>
                <td class="px-3 text-dark-65 font-weight-bolder font-size-lg">
                  {{ item.occur_days }}
                </td>
                <td class="px-3 text-dark-65 font-weight-bolder font-size-lg">
                  <button
                    class="btn btn-light-info py-1 poppins"
                    @click="() => toggleViewSKUSDialog(item.skus)"
                  >
                    {{ $t("view_skus") }}
                  </button>
                </td>
              </tr>
            </tbody>
            <caption
              v-else
              class="mt-2 text-dark-50 text-center font-weight-bolder font-size-lg"
            >
              {{
                $t("no_data")
              }}
            </caption>
          </table>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <button
          type="reset"
          class="btn btn-light-info mr-3 px-5 py-3 ls1"
          @click="dialog = false"
        >
          {{ $t("close") }}
        </button>
      </v-card-actions>

      <ViewSKUSModal ref="skus" :forView="false" />
    </div>
  </v-dialog>
</template>

<script>
import ViewSKUSModal from "@/own/components/orderManagement/subscriptionManagement/ViewSKUSModal";
import { SET_RULES_SKUS } from "@/core/services/store/subscriptionManagement.module";
export default {
  name: "ShowRulesTable",
  components: { ViewSKUSModal },
  props: ["value"],
  data: () => ({
    dialog: false,
  }),
  computed: {
    headers: function () {
      return [
        this.$t("id"),
        this.$t("occurrence_days"),
        this.$t("type_of_occurrence"),
        this.$t("skus"),
      ];
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    toggleViewSKUSDialog(item) {
      this.$refs.skus.toggleDialog();
      this.$store.commit(SET_RULES_SKUS, item);
    },
  },
};
</script>
