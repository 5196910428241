<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
      v-if="serverDataLoaded"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Invoice</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm ">
            <v-row>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-text-field
                  v-model="formData.invoice_number"
                  label="Invoice number"
                  clearable
                  outlined
                  :error-messages="invoice_numberErrors"
                  @input="$v.formData.invoice_number.$touch()"
                  @blur="$v.formData.invoice_number.$touch()"
                ></v-text-field>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-text-field
                  v-model="formData.reference"
                  label="Reference"
                  clearable
                  outlined
                ></v-text-field>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.payable_date"
                      label="Payable date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="payable_dateErrors"
                      @input="$v.formData.payable_date.$touch()"
                      @blur="$v.formData.payable_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.payable_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                <v-text-field
                  v-model="formData.description"
                  label="Description"
                  clearable
                  outlined
                ></v-text-field>
              </div>
            </v-row>
            <div>
              <v-card>
                <v-card-title>Services</v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="serverData.invoice.services"
                  >
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>General</v-card-title>
                <v-card-text>
                  <div>
                    <span>Id: </span>
                    <span>{{ serverData.invoice.id }}</span>
                  </div>
                  <div>
                    <span>Currency: </span>
                    <span>{{ serverData.invoice.currency }}</span>
                  </div>
                  <div>
                    <span>Discount: </span>
                    <span>{{ serverData.invoice.discount }}</span>
                  </div>
                  <div>
                    <span>Date from: </span>
                    <span>{{ serverData.invoice.date_from }}</span>
                  </div>
                  <div>
                    <span>Date to: </span>
                    <span>{{ serverData.invoice.date_to }}</span>
                  </div>
                  <div>
                    <span>Confirmed at: </span>
                    <span>{{ serverData.invoice.confirmed_at }}</span>
                  </div>
                  <div>
                    <span>Total subtotal: </span>
                    <span>{{ serverData.invoice.total_subtotal }}</span>
                  </div>
                  <div>
                    <span>Total tax: </span>
                    <span>{{ serverData.invoice.total_tax }}</span>
                  </div>
                  <div>
                    <span>Total: </span>
                    <span>{{ serverData.invoice.total }}</span>
                  </div>
                  <div>
                    <span>Total In Sentence: </span>
                    <span>{{ serverData.invoice.totalInSentence }}</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Client</v-card-title>
                <v-card-text>
                  <div>
                    <span>Name: </span>
                    <span>{{ serverData.invoice.client.name }}</span>
                  </div>
                  <div>
                    <span>System code: </span>
                    <span>{{ serverData.invoice.client.system_code }}</span>
                  </div>
                  <div>
                    <span>TRN: </span>
                    <span>{{ serverData.invoice.client.trn }}</span>
                  </div>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Organization</v-card-title>
                <v-card-text>
                  <div>
                    <span>Name: </span>
                    <span>{{ serverData.invoice.organization.name }}</span>
                  </div>
                  <div>
                    <span>TRN: </span>
                    <span>{{ serverData.invoice.organization.trn }}</span>
                  </div>
                  <div>
                    <span>Country: </span>
                    <span>{{
                      serverData.invoice.organization.address.country
                    }}</span>
                  </div>
                  <div>
                    <span>City: </span>
                    <span>{{
                      serverData.invoice.organization.address.city
                    }}</span>
                  </div>
                  <div>
                    <span>Address: </span>
                    <span>{{
                      serverData.invoice.organization.address.address
                    }}</span>
                  </div>
                  <hr />
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Bank detail</v-card-title>
                <v-card-text>
                  <div>
                    <span>Account name: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.account_name
                    }}</span>
                  </div>
                  <div>
                    <span>Account number: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.account_number
                    }}</span>
                  </div>
                  <div>
                    <span>Iban: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.iban
                    }}</span>
                  </div>
                  <div>
                    <span>Bank: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.bank
                    }}</span>
                  </div>
                  <div>
                    <span>Currency: </span>
                    <span>{{
                      serverData.invoice.organization.bank_detail.currency
                    }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      payable_date: { required },
      invoice_number: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    menu2: false,
    headers: [
      { text: "id", value: "service_id" },
      { text: "service_name", value: "service_name" },
      { text: "subtotal", value: "subtotal" },
      { text: "tax_total", value: "tax_total" },
      { text: "discount", value: "discount" },
      { text: "total", value: "total" },
    ],
    formData: {
      id: null,
      invoice_number: null,
      reference: null,
      payable_date: null,
      description: null,
    },
  }),
  beforeMount() {
    // this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      if (this.$store.getters.getItemForAction.invoice_status !== "Confirmed") {
        let data = { id: this.$store.getters.getItemForAction.id };
        ApiService.post("/cod/invoices/edit", data)
          .then((response) => {
            this.serverData = response.data;
            this.resetEditForm();
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        Swal.fire({
          title: "Update",
          text: `Invoice status has already confirmed !`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        this.dialog = false;
        this.pageLoader(false);
      }
    },

    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    validateData() {
      return true;
    },
    convertToFormData() {
      return this.formData;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        // window.scrollTo(0, 0);
        // this.$el.scrollTop = 0;
        this.$refs.content.scrollTop = 0;
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          let data = this.convertToFormData();
          ApiService.post("/cod/invoices/store", data)
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: `Invoice data has been updated`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.toggleModal();
              this.resetEditForm();
              this.refresher();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        }
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.invoice };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.invoice).forEach((serverD) => {
            // console.log(localD, serverD );
            if (localD[0] === serverD[0] && localD[0] !== "image") {
              // console.log('-----equal-------',localD, serverD );
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
      } else {
        this.formData = {
          id: null,
          invoice_number: null,
          reference: null,
          payable_date: null,
          description: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    serverDataLoaded: function () {
      return !!this.serverData;
    },
    payable_dateErrors: function () {
      return this.handleFormValidation("payable_date", this);
    },
    invoice_numberErrors: function () {
      return this.handleFormValidation("invoice_number", this);
    },
  },
};
</script>
