import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const UPDATE_TABLE_STRUCTURE = "getCOUNTRIESTableStructure";
export const UPDATE_TABLE_DATA = "getCOUNTRIESTableData";
export const EXPORT_TABLE_DATA = "getCOUNTRIESEXPORTTABLEDATA";

// mutation types
export const SET_TABLE_STRUCTURE = "setCOUNTRIESTableStructure";
export const SET_TABLE_DATA = "setCOUNTRIESTableData";
export const SET_TABLE_FILTER = "setCOUNTRIESTableFilter";
export const SET_TABLE_PAGE = "setCOUNTRIESTablePage";
export const SET_TABLE_PERPAGE = "setCOUNTRIESTablePerPage";
export const SET_TABLE_SORTBY = "setCOUNTRIESTableSortBy";
export const SET_TABLE_SORTORDER = "setCOUNTRIESTableSortOrder";
export const SET_FILTERS_DATA = "setCOUNTRIESFiltersData";
export const SET_EXPORT_LINK = "setCOUNTRIESExportLink";

const state = {
  table_structure: {},
  create_data: {},
  table_data: {},
  filters_data: [],
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getCOUNTRIESTableState(state) {
    return state.table_state;
  },

  getCOUNTRIESTableHeaders(state) {
    return state.table_structure.columns;
  },

  getCOUNTRIESTableFilters(state) {
    return state.table_structure.filters;
  },

  getCOUNTRIESTablePermissions(state) {
    return state.table_structure.permissions;
  },

  getCOUNTRIESTableData(state) {
    return state.table_data.data;
  },

  getCOUNTRIESCreateData(state) {
    return state.create_data;
  },

  getCOUNTRIESTableProperties(state) {
    return state.table_structure.properties;
  },

  getCOUNTRIESData(state) {
    return state.table_structure.countries;
  },

  getCOUNTRIESExportUrl(state) {
    return state.export_link;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context, integration_id) {
    return new Promise((resolve) => {
      ApiService.post("validations/countriesMapping", {
        integration_id: integration_id,
      })
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, integration_id, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/validations/countriesMapping/datatable", {
        ...payload,
        integration_id: integration_id,
      })
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/validations/countriesMapping/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_FILTERS_DATA](state, filter_data) {
    state.filters_data = filter_data;
  },
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
