import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SORT_PLAN_CREATE_API,
  SET_SELECTED_PLAN_DETAILS,
  GET_SORT_PLAN_DATA,
} from "@/core/services/store/wesSortPlan.module";
import ApiService from "@/core/services/api.service";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  mounted() {
    this.$store.dispatch(GET_SORT_PLAN_DATA).finally(() => {
      this.$store.dispatch(SORT_PLAN_CREATE_API);
      this.$store.commit(SET_PAGE_LOADING, false);
    });
  },
  // `.${tableName}-tb > .list-container  > tr > td.d-flex .bg-light-info`
  methods: {
    toggleNextButton(id, event, tableName) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;

      elem.classList.add("bg-light-info");
      elem.firstElementChild.firstElementChild.style.color = "#8950fc";

      const rows = document.querySelectorAll(
        `.${tableName}-tb > .list-container  > tr > td.d-flex .bg-light-info`
      );
      rows.forEach((element) => {
        if (!element.classList.contains(`${tableName}-tb-${id}-next-btn`)) {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        }
      });

      if (tableName === "plans") {
        const chutesRows = document.querySelectorAll(
          `.chutes-tb > .list-container  > tr > td.d-flex .bg-light-info`
        );
        chutesRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });

        const locationsRows = document.querySelectorAll(
          `.locations-tb > .list-container  > tr > td.d-flex .bg-light-info`
        );
        locationsRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });
      } else if (tableName === "chutes") {
        const locationsRows = document.querySelectorAll(
          `.locations-tb > .list-container  > tr > td.d-flex .bg-light-info`
        );
        locationsRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });
      }

      this.$store.commit(SET_PAGE_LOADING, false);
    },
    handlePlanSelect(item, event, tableName, plan_index) {
      this.$store.commit(SET_PAGE_LOADING, true);

      this.$store.commit(SET_SELECTED_PLAN_DETAILS, {
        plan_id: item.id,
        plan_index,
        chute_index: -1,
        location_index: -1,
      });
      this.$store.getters.getWESSORTPLANChutes.forEach((chute) => {
        chute.is_selected = Boolean(
          item.chutes.find((item) => item.id === chute.id)
        );
      });

      this.$store.getters.getWESSORTPLANLocations.forEach((location) => {
        location.is_selected = false;
      });

      this.$store.getters.getWESSORTPLANCategories.forEach((category) => {
        category.is_selected = false;
      });

      this.toggleNextButton(item.id, event, tableName);
    },
    handleChuteSelect(item, event, tableName, plan_index) {
      this.$store.commit(SET_PAGE_LOADING, true);

      const chute_index = this.$store.getters.getWESSORTPLANPlans[
        plan_index
      ].chutes.findIndex((chute) => chute.id === item.id);

      this.$store.commit(SET_SELECTED_PLAN_DETAILS, {
        chute_index,
        location_index: -1,
      });

      this.$store.getters.getWESSORTPLANLocations.forEach((location) => {
        location.is_selected = Boolean(
          this.$store.getters.getWESSORTPLANPlans[plan_index].chutes[
            chute_index
          ].locations.find(
            (planChuteLocation) => planChuteLocation.id === location.id
          )
        );
      });

      this.$store.getters.getWESSORTPLANCategories.forEach((category) => {
        category.is_selected = false;
      });

      this.toggleNextButton(item.id, event, tableName);
    },
    handleLocationSelect(item, event, tableName, plan_index, chute_index) {
      this.$store.commit(SET_PAGE_LOADING, true);

      const location_index = this.$store.getters.getWESSORTPLANPlans[
        plan_index
      ].chutes[chute_index].locations.findIndex(
        (location) => location.id === item.id
      );

      this.$store.commit(SET_SELECTED_PLAN_DETAILS, {
        location_index,
      });

      this.$store.getters.getWESSORTPLANCategories.forEach((category) => {
        category.is_selected = Boolean(
          this.$store.getters.getWESSORTPLANPlans[plan_index].chutes[
            chute_index
          ].locations[location_index].categories.find(
            (planChuteLocationCategory) =>
              planChuteLocationCategory.id === category.id
          )
        );
      });

      this.toggleNextButton(item.id, event, tableName);
    },
    handleCreatePlan(item = null, isForDuplicate = false) {
      this.$refs.createPlan.toggleModal(item, isForDuplicate);
    },
    submitPlan() {
      const selectedPlanId =
        this.$store.getters.getWESSORTPLANSelectedPlanDetails.plan_id;

      if (selectedPlanId) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const plan = this.$store.getters.getWESSORTPLANPlans.find(
          (item) => item.id === selectedPlanId
        );

        const payload = {
          ...plan,
          chutes: plan.chutes.map((chute) => ({
            ...chute,
            locations: chute.locations.map((location) => ({
              ...location,
              // categories: location.categories.map((category) => ({
              //   ...category,
              // })),
            })),
            // categories: chute.categories.map((item) => item.id),
          })),
        };
        // console.log(payload);
        // return;

        ApiService.post(`/wes/wesSortPlan/update`, payload)
          .then(() => {
            swalAdded();
          })
          .finally(() => {
            this.$store.dispatch(GET_SORT_PLAN_DATA).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          });
      }
    },
  },
  computed: {
    tableProperties: function () {
      return {
        title: "Sort Plans",
        description: "Manage Sort Plans",
      };
    },
  },
};
