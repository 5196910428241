<template>
  <div class="d-flex justify-end align-center flex-wrap flex-md-nowrap">
    <!-- <circle-counter></circle-counter> -->
    <div
      class="w-100 w-lg-200px ma-0 pa-0"
      v-for="filter in filters"
      :key="filter.name"
    >
      <FilterType
        :filter="filter"
        :updateFunction="updateDataOfCharts"
      ></FilterType>
    </div>
  </div>
</template>

<script>
import FilterType from "@/own/components/dashboard/filters/FilterTypeNew";
// import CircleCounter from "@/own/components/dashboard/CircleCounter.vue";

export default {
  name: "FilterSelector",
  props: ["filters", "updateDataOfCharts"],
  components: {
    FilterType,
    // CircleCounter
  },
};
</script>
