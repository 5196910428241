<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="800px"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">SKU</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5">
        <!--begin::Body-->
        <form class="pb-8 row">
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.sku_id"
              label="SKU"
              item-text="systemCode"
              item-value="skuId"
              no-filter
              clearable
              outlined
              dense
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              :error-messages="sku_idErrors"
              @input="$v.formData.sku_id.$touch()"
              @blur="$v.formData.sku_id.$touch()"
            ></v-autocomplete>
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.batch_number"
              label="Batch number"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.carton_number"
              :label="carton_numberLabel"
              clearable
              outlined
              dense
              :error-messages="carton_numberErrors"
              @input="$v.formData.carton_number.$touch()"
              @blur="$v.formData.carton_number.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.expected_qty"
              label="Expected quantity"
              type="number"
              @change="() => validateMinValue('expected_qty', 0, 'formData')"
              clearable
              outlined
              dense
              :error-messages="expected_qtyErrors"
              @input="$v.formData.expected_qty.$touch()"
              @blur="$v.formData.expected_qty.$touch()"
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6">
            <v-menu
              outlined
              v-model="menu2"
              class=""
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              dense
            >
              <v-date-picker
                v-model="formData.exp_date"
                clearable
              ></v-date-picker>

              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="formData.exp_date"
                  label="Expiry date"
                  append-icon="mdi-calendar"
                  clearable
                  dense
                  class="mx-1"
                  elevation-0
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
            </v-menu>
          </div>

          <div class="row mx-3">
            <div
              class="col-12 col-sm-6 d-flex align-center justify-space-between"
            >
              <h5 class="mb-0 font-weight-regular">Transfer</h5>
              <v-switch
                v-model="formData.transfer_flag"
                dense
                hide-details
                class="mt-0"
              ></v-switch>
            </div>
            <div
              class="col-12 col-sm-6 d-flex align-center justify-space-between"
            >
              <h5 class="mb-0 font-weight-regular">Require Labeling</h5>
              <v-switch
                v-model="formData.label_flag"
                dense
                hide-details
                class="mt-0"
              ></v-switch>
            </div>
            <div
              class="col-12 col-sm-6 d-flex align-center justify-space-between"
            >
              <h5 class="mb-0 font-weight-regular">Require Bundling</h5>
              <v-switch
                v-model="formData.bundle_flag"
                dense
                hide-details
                class="mt-0"
              ></v-switch>
            </div>
            <div
              class="col-12 col-sm-6 d-flex align-center justify-space-between"
            >
              <h5 class="mb-0 font-weight-regular">Require QC</h5>
              <v-switch
                v-model="formData.qc_flag"
                dense
                hide-details
                class="mt-0"
              ></v-switch>
            </div>
            <div
              class="col-12 col-sm-6 d-flex align-center justify-space-between"
            >
              <h5 class="mb-0 font-weight-regular">Require Assembling</h5>
              <v-switch
                v-model="formData.assemble_flag"
                dense
                hide-details
                class="mt-0"
              ></v-switch>
            </div>
          </div>
        </form>

        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "EditSku",
  mixins: [validationMixin, fieldValueValidation],
  validations() {
    return {
      formData: {
        sku_id: { required },
        expected_qty: { required },
        carton_number: {
          required: requiredIf(function () {
            return this.receiving_type == "3";
          }),
        },
      },
    };
  },
  props: ["actionFunction", "carriers", "clientId", "receiving_type"],
  data: () => ({
    dialog: false,
    isLoading: false,
    menu2: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      sku_id: null,
      sku_barcode: null,
      sku_carton_number: null,
      expected_qty: null,
      label_flag: false,
      bundle_flag: false,
      qc_flag: false,
      assemble_flag: false,

      exp_date: null,
      batch_number: null,
      carton_number: null,
      transfer_flag: false,
    },
    itemForEdit: false,
    doneTypingInterval: 1500,
    typingTimer: null,
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        try {
          this.formData.sku_barcode = this.entries.filter(
            (sku) => sku.skuId == this.formData.sku_id
          )[0].systemCode;
        } catch {
          // console.log(this.formData);
        }

        this.actionFunction(this.formData, this.itemForEdit ? true : false);
        this.dialog = false;
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        sku_id: null,
        sku_barcode: null,
        expected_qty: null,
        label_flag: false,
        bundle_flag: false,
        qc_flag: false,
        assemble_flag: false,
        exp_date: null,

        batch_number: null,
        carton_number: null,
        sku_carton_number: null,
        transfer_flag: false,
      };
    },
    toggleModal(item) {
      if (item) {
        this.formData = { ...item };
        Object.entries(this.formData).map((data) => {
          if (data[1] === "Yes") {
            this.formData[data[0]] = true;
          } else if (data[1] === "No") {
            this.formData[data[0]] = false;
          }
        });
        this.entries = [];
        this.getData(this.formData.sku_barcode);
        this.itemForEdit = { ...item };
      } else {
        this.resetFormData();
        this.entries = [];
        this.itemForEdit = false;
      }
      this.dialog = true;
    },
    async getData(value) {
      let data = { q: value, only_enabled: true };
      if (this.clientId) {
        data.client = this.clientId;
      } else {
        data.client = this.$store.getters.currentUser.data.id;
      }

      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search_sku`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .finally(() => (this.isLoading = false));
    },
  },

  computed: {
    carton_numberLabel() {
      return this.receiving_type == "1" ? "SKU carton number" : "Carton number";
    },
    carton_numberErrors: function () {
      return this.handleFormValidation("carton_number", this);
    },
    items: function () {
      return this.entries;
    },
    sku_idErrors: function () {
      return this.handleFormValidation("sku_id", this);
    },
    expected_qtyErrors: function () {
      return this.handleFormValidation("expected_qty", this);
    },
  },
  watch: {
    search(val) {
      clearTimeout(this.typingTimer);
      if (!val) {
        return;
      }
      this.typingTimer = setTimeout(() => {
        this.isLoading = true;
        this.getData(val.trim());
      }, this.doneTypingInterval);
    },
  },
};
</script>
