<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row ma-0 max-h-300px">
          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              dense
              outlined
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-select
              v-model="formData.apply_type_id"
              label="Apply type"
              :items="serverData.apply_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              hide-no-data
              hide-selected
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.priority"
              label="Priority"
              type="number"
              dense
              outlined
              clearable
              :error-messages="priorityErrors"
              @blur="$v.formData.priority.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              :items="statusValues"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="statusErrors"
              @blur="$v.formData.status.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="serverData?.organizations">
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              :items="serverData.organizations"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="organization_idErrors"
              @blur="$v.formData.organization_id.$touch()"
            />
          </div>

          <div
            class="col-12 col-sm-6 h-75px"
            v-if="!$store.getters.isClient && formData.apply_type_id == '2'"
          >
            <v-combobox
              v-model="formData.clients"
              label="Customer"
              :items="clientsAccordingToOrganization"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              multiple
              small-chips
              deletable-chips
              :error-messages="customerIdErrors"
              @blur="$v.formData.clients.$touch()"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                  <v-list-item-action>
                    <v-icon>
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-sm">
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              dense
              outlined
              clearable
            />
          </div>
        </form>

        <div class="mt-4 pa-0 w-100">
          <RuleBuilder
            :query="query"
            :options="operands"
            :operators="operators"
            :userId="userId"
          />
        </div>
        <div class="mt-4 pa-0 w-100">
          <ActionBuilder
            :query="formData.parameters"
            :options="serverData.parameters"
            :operators="serverData"
            :userId="userId"
            ref="actionBuilder"
          />
        </div>

        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import RuleBuilder from "@/own/components/warehouseManagement/pickingStrategyRules/ruleBuilder/RuleBuilder";
import ActionBuilder from "@/own/components/warehouseManagement/pickingStrategyRules/actionBuilder/ActionBuilder";
import Swal from "sweetalert2";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { RuleBuilder, ActionBuilder },
  validations() {
    return {
      formData: {
        organization_id: {
          required: requiredIf(function () {
            return !!this.serverData.organizations;
          }),
        },
        clients: {
          required: requiredIf(function () {
            return (
              this.formData.apply_type_id != "1" && !!this.serverData.clients
            );
          }),
        },
        name: { required },
        status: { required },
        priority: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      organization_id: null,
      clients: null,
      name: null,
      priority: null,
      status: null,
      description: null,
      parameters: [],
      apply_type_id: 2,
    },
    query: {
      condition: "and",
      rules: [],
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.formData = {
        organization_id: null,
        clients: null,
        name: null,
        priority: null,
        status: null,
        description: null,
        parameters: [],
        apply_type_id: 2,
      };
      this.query = {
        condition: "and",
        rules: [],
      };
    },
    loadDataFromServer() {
      // this.$store.dispatch(GET_DATA);
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        ApiService.post(
          "warehouse_management/picking_strategy_rules/edit",
          sendData
        )
          .then(({ data }) => {
            if (data.allocation_rule.condition)
              this.query = JSON.parse(data.allocation_rule.condition);
            delete data.allocation_rule.condition;
            this.serverData = data;
            this.formData = data.allocation_rule;
            this.formData.clients = data.clients.filter((item) =>
              data.allocation_rule.clients.includes(item.index)
            );
            // console.log(" this.query", this.query);
            // console.log(" this.formData", this.formData);
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        ApiService.post("warehouse_management/picking_strategy_rules/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    validateActionBuilderData() {
      let isNotValid = false;
      this.formData.parameters.forEach((param) => {
        if (
          param.value === undefined ||
          param.value === null ||
          param.value === ""
        ) {
          isNotValid = true;
        }
      });
      return isNotValid;
    },
    submitForm() {
      // console.log("sendData 1");
      this.$v.$touch();
      // console.log("sendData 2");
      if (!this.query.rules.length) {
        Swal.fire({
          title: "Warning",
          text: "Please, add at least one rule!",
          icon: "warning",
        });
        return;
      }

      // console.log("sendData 3");
      if (
        this.$v.$invalid ||
        this.$store.getters.getALLOCATIONSTATEGYRULESNotValid
      ) {
        return;
      } //else console.log("noooo");
      // if (this.formData.actions === 3) {
      if (!this.formData.parameters.length) {
        Swal.fire({
          title: "Warning",
          text: "Please, add at least one action!",
          icon: "warning",
        });
        return;
      }
      if (this.validateActionBuilderData()) {
        return;
      }
      // } else {
      //   this.formData.parameters = [];
      // }

      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let sendData = {
        ...this.formData,
      };
      if (this.formData.apply_type_id != "1") {
        sendData.clients = this.formData.clients.map((item) => item.index);
      }
      sendData.condition = JSON.stringify(this.query);

      // sendData.parameters = [];

      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      // console.log("sendData", sendData);
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(
        `warehouse_management/picking_strategy_rules/${actionUrl}`,
        sendData
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    toggle() {
      if (this.selectedAll) {
        this.$set(this.formData, "clients", null);
      } else {
        let arr = [...this.customers];
        this.$set(this.formData, "clients", arr);
      }
    },
  },
  watch: {},
  computed: {
    clientsAccordingToOrganization() {
      if (this.serverData?.organizations) {
        return this.serverData.clients.filter(
          (client) => client.organization_id === this.formData.organization_id
        );
      } else {
        return this.serverData.clients;
      }
    },
    organization_idErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    selectedAll() {
      if (Array.isArray(this.formData.clients)) {
        return this.customers.length === this.formData.clients.length;
      } else return false;
    },
    selectedSome() {
      if (Array.isArray(this.formData.clients)) {
        return this.formData.clients.length > 0;
      } else return false;
    },
    icon() {
      if (this.selectedAll) return "mdi-checkbox-marked";
      if (this.selectedSome) return "mdi-minus-box-outline";
      return "mdi-checkbox-blank-outline";
    },
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    userId: function () {
      return this.$store.getters.isClient
        ? this.$store.getters.currentUser.data.id
        : this.formData.clients;
    },
    customers: function () {
      return this.serverData.clients;
    },
    statusValues: function () {
      return this.serverData.statuses;
    },
    operands: function () {
      return this.serverData.operands;
    },
    operators: function () {
      return this.serverData.operators;
    },
    customerIdErrors: function () {
      return this.handleFormValidation("clients");
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    priorityErrors: function () {
      return this.handleFormValidation("priority");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
  },
};
</script>
