<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center py-4">
        <h4 class="mb-0 font-weight-bolder">Edit Translation</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="pa-3">
          <div class="row">
            <!-- <div class="py-0 my-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.project"
                label="Project"
                clearable
                outlined
                dense
                :error-messages="projectErrors"
                @input="$v.formData.project.$touch()"
                @blur="$v.formData.project.$touch()"
              ></v-text-field>
            </div>
            <div class="py-0 my-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.group"
                label="Group"
                clearable
                outlined
                dense
                :error-messages="groupErrors"
                @input="$v.formData.group.$touch()"
                @blur="$v.formData.group.$touch()"
              ></v-text-field>
            </div> -->
            <div class="py-0 my-0 col-12">
              <v-text-field
                v-model="formData.label"
                label="Label"
                clearable
                outlined
                dense
                :error-messages="labelErrors"
                @input="$v.formData.label.$touch()"
                @blur="$v.formData.label.$touch()"
              ></v-text-field>
            </div>

            <div class="py-0 my-0 col-12">
              <v-text-field
                v-model="formData.value.en"
                label="Value for english"
                clearable
                outlined
                dense
                :error-messages="valueErrors"
                @input="$v.formData.value.$touch()"
                @blur="$v.formData.value.$touch()"
              ></v-text-field>
            </div>
            <div class="py-0 my-0 col-12">
              <v-text-field
                v-model="formData.value.fr"
                label="Value for french"
                clearable
                outlined
                dense
                :error-messages="valueErrors"
                @input="$v.formData.value.$touch()"
                @blur="$v.formData.value.$touch()"
              ></v-text-field>
            </div>
            <div class="py-0 my-0 col-12">
              <v-text-field
                v-model="formData.value.ar"
                label="Value for arabic"
                clearable
                outlined
                dense
                :error-messages="valueErrors"
                @input="$v.formData.value.$touch()"
                @blur="$v.formData.value.$touch()"
              ></v-text-field>
            </div>
          </div>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center my-4">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetData"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import axios from "axios";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required } from "vuelidate/lib/validators";
// import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      // project: { required },
      // group: { required },
      label: { required },
      value: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      // project: null,
      // group: null,
      label: null,
      value: {},
    },
    serverData: null,
  }),
  computed: {
    // projectErrors() {
    //   return this.handleFormValidation("project", this);
    // },
    // groupErrors() {
    //   return this.handleFormValidation("group", this);
    // },
    labelErrors() {
      return this.handleFormValidation("label", this);
    },
    valueErrors() {
      return this.handleFormValidation("value", this);
    },
  },
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/settings/translations/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData.translation };
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (!this.dialog) {
        this.loadDataFromServer();
      } else {
        this.resetData();
      }
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submit() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      data.id = this.$store.getters.getItemForAction.id;
      ApiService.post("/settings/translations/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "The translation has been Updated!",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetData() {
      this.formData = { ...this.serverData.translation };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
