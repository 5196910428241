<template>
  <div
    class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
    :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
  >
    <b-dropdown
      toggle-class="btn btn--export-filter px-2"
      menu-class="pl-0"
      no-caret
      variant="--export-filter"
    >
      <template v-slot:button-content>
        <span class="ml-3">Count</span>
        <span class="svg-icon ml-1 mr-0">
          <v-icon size="18">mdi-menu-down</v-icon>
        </span>
      </template>
      <b-dropdown-item @click="startCycleCount('new_count')"
        >New count</b-dropdown-item
      >
      <b-dropdown-item @click="startCycleCount('re_count')"
        >Re-count</b-dropdown-item
      >
    </b-dropdown>
    <button @click="closeCycleCount" class="btn btn--export-filter ml-2">
      Close
    </button>
    <CycleCountModal ref="cycleCountModal" :page-loading="pageLoading" />
    <CloseCycleCount
      ref="closeCycleCount"
      :page-loading="pageLoading"
      :refresher="refresher"
    />
  </div>
</template>

<script>
import {
  CREATE_CYCLE_COUNT,
  SET_COUNT_TYPE,
} from "@/core/services/store/randomCycleCount.module";
import CycleCountModal from "@/own/components/warehouseManagement/randomCycleCount/CycleCountModal.vue";
import CloseCycleCount from "@/own/components/warehouseManagement/randomCycleCount/CloseCycleCount.vue";

export default {
  name: "CycleCountActions",
  props: ["pageLoading", "refresher"],
  components: { CycleCountModal, CloseCycleCount },
  data: () => ({}),
  methods: {
    startCycleCount(type) {
      this.pageLoading(true);
      this.$store.commit(SET_COUNT_TYPE, type);
      const payload = {
        warehouse_id: this.selectedWarehouse,
      };
      this.$store.dispatch(CREATE_CYCLE_COUNT, payload).then(() => {
        this.$refs.cycleCountModal.toggleModal();
        this.pageLoading(false);
      });
    },
    closeCycleCount() {
      this.$refs.closeCycleCount.toggleModal();
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  mounted() {},
};
</script>
