<template>
  <!-- <button
    @click.prevent="onPrint(item)"
    class="btn btn--export-filter font-weight-bolder ml-2 mr-3"
  >
    {{ $t("print") }}
  </button> -->
  <v-menu left transition="scale-transition" offset-y bottom rounded>
    <template v-slot:activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
        class="btn btn-light-light btn-hover-secondary show poppins min-w-100px min-w-md-110px"
      >
        {{ $t("actions") }}
        <span class="svg-icon m-0">
          <v-icon small>mdi-chevron-down</v-icon>
        </span>
      </a>
    </template>
    <v-card class="poppins py-1" elevation="1">
      <div>
        <v-btn
          class="w-100 text-left"
          :disabled="!item.extra_data.detail.actions?.print"
          :class="
            !item.extra_data.detail.actions?.print ? 'text-muted' : 'text-dark'
          "
          @click.prevent="onPrint(item)"
          text
          >{{ $t("print") }}</v-btn
        >
      </div>
      <div>
        <v-btn
          class="w-100 text-left"
          :disabled="!item.extra_data.detail.actions?.update"
          :class="
            !item.extra_data.detail.actions?.update ? 'text-muted' : 'text-dark'
          "
          @click.prevent="toggleDimensionUpdateModal"
          text
          >{{ $t("update_package") }}</v-btn
        >
      </div>
      <UpdateDimension ref="updateDimension" :item="item" />
    </v-card>
  </v-menu>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";
import { UPDATE_TABLE_DATA } from "@/core/services/store/packageCode.module.js";
import UpdateDimension from "@/own/components/warehouseManagement/packageCode/UpdateDimension.vue";

export default {
  name: "ActionsComponent",
  props: ["item"],
  components: { UpdateDimension },
  methods: {
    onPrint(item = []) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let itemSystemCodes = [item.code];
      let data = { codes: itemSystemCodes };
      ApiService.post(
        "warehouse_management/custom/package_codes/print_barcode",
        data
      )
        .then((response) => {
          this.$store.commit(SET_REPORT_MODAL_DATA, {
            successful_orders_count: response.data.total_successful,
            failed_orders_count: response.data.total_failed,
            successful_orders: response.data.successful_orders,
            failed_orders: response.data.failed_codes,
            message: response.data.message,
            total_orders_count: response.data.total,
          });
          this.$store.commit(SET_REPORT_MODAL_STATE, true);

          if (response.data.total_successful > 0) {
            if (response.data.path) {
              const link = document.createElement("a");
              link.href = response.data.path;
              link.target = "_blank";
              link.download = "orders.csv";
              link.click();
            }
          }
        })
        .then(() => {
          return this.$store.dispatch(UPDATE_TABLE_DATA, {});
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleDimensionUpdateModal() {
      this.$refs.updateDimension.toggleModal();
    },
  },
};
</script>
