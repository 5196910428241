<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Discover</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm">
            <v-select
              v-model="formData.store_id"
              label="Store"
              item-text="text"
              item-value="index"
              :items="serverData.stores"
              clearable
              outlined
              :error-messages="store_idErrors"
              @input="$v.formData.store_id.$touch()"
              @blur="$v.formData.store_id.$touch()"
            ></v-select>

            <v-text-field
              v-model="formData.url"
              label="URL"
              clearable
              outlined
              :error-messages="urlErrors"
              @input="$v.formData.url.$touch()"
              @blur="$v.formData.url.$touch()"
            ></v-text-field>

            <v-file-input
              class="col-12"
              v-model="formData.image"
              show-size
              counter
              multiple
              dense
              outlined
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Image"
              clearable
            ></v-file-input>
          </form>

          <!--end::Body-->
          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      store_id: { required },
      url: { required, url },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      store_id: null,
      url: null,
      image: null,
    },
  }),
  beforeMount() {
    this.resetEditForm();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/stores/portal/discovers/edit`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: data,
      };
      axios(config)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: `${error.response.data?.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });

          this.dialog = false;
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      data.append("id", this.$store.getters.getItemForAction.id);
      Object.entries(this.formData).forEach((item) => {
        if ((item[1] && item[0] != "image") || item[1] == 0) {
          data.append(item[0], item[1]);
        } else if (item[1] && item[0] == "image") {
          data.append(item[0], item[1][0]);
        }
      });

      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/stores/portal/discovers/update`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Discover data has been updated!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.resetEditForm();
            this.refresher();
          })
          .catch((response) => {
            Swal.fire({
              title: "Error",
              text: response.message,
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.discover };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.discover).forEach((serverData) => {
            if (localData[0] !== "image") {
              if (localData[0] == serverData[0]) {
                this.formData[localData[0]] = copy[serverData[0]];
              }
            }
          });
        });
      } else {
        this.formData = {
          store_id: null,
          url: null,
          image: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    store_idErrors: function () {
      return this.handleFormValidation("store_id", this);
    },
    urlErrors: function () {
      return this.handleFormValidation("url", this);
    },
  },
};
</script>
