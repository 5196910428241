<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins h-100">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Select Formula</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-8 py-4">
        <!--begin::Body-->
        <form id="createForm" class=" ">
          <div
            class="py-1"
            v-for="formula in selectedFormulasArray"
            :key="formula.text"
          >
            <div class="row align-items-center px-4 my-0 py-0">
              <div class="pe-4">
                <v-checkbox v-model="formula.is_available"></v-checkbox>
              </div>
              <div class="pe-4">{{ formula.text }}</div>
              <div class="w-450px">
                <v-select
                  v-model="formula.type"
                  label="Type"
                  :items="availableFormulas"
                  item-text="text"
                  item-value="index"
                  outlined
                  dense
                  hide-details
                  :disabled="!formula.is_available"
                  clearable
                />
              </div>
            </div>
          </div>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 pt-10 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitInsertionForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
export default {
  name: "InsertFormula",
  props: {
    availableFormulas: {
      required: true,
      type: Array,
    },
    selectedFormulas: {
      required: true,
      type: Array,
    },
    insertFormulaToTemplate: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    dialog: false,
    selectedFormulasArray: [],
    availableFormulasArray: [
      {
        text: "formula_1",
        type: null,
        is_available: false,
      },
      {
        text: "formula_2",
        type: null,
        is_available: false,
      },
      {
        text: "formula_3",
        type: null,
        is_available: false,
      },
      {
        text: "formula_4",
        type: null,
        is_available: false,
      },
      {
        text: "formula_5",
        type: null,
        is_available: false,
      },
    ],
  }),
  computed: {},
  methods: {
    toggleModal() {
      if (!this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitInsertionForm() {
      this.insertFormulaToTemplate(
        this.selectedFormulasArray
          .filter((formula) => formula.is_available && formula.type)
          .map((formula) => ({ text: formula.text, type: formula.type }))
      );
      this.toggleModal();
    },
    updateFormulasAccordingToDataFromServer() {
      this.selectedFormulas.forEach((formula) => {
        const target = this.selectedFormulasArray.find(
          (formula2) => formula2.text === formula.text
        );
        target.is_available = true;
        target.type = formula.type;
      });
    },
    resetCreateForm() {
      this.selectedFormulasArray = JSON.parse(
        JSON.stringify(this.availableFormulasArray)
      );
      this.updateFormulasAccordingToDataFromServer();
    },
  },
};
</script>
<style></style>
