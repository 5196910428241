<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600px"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Service</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="editForm" class="pa-3 pt-0 pb-0 row">
          <div class="col-12">
            <v-autocomplete
              class="mx-1 poppins"
              v-model="formData.id"
              label="Service"
              item-text="text"
              item-value="index"
              multiple
              clearable
              outlined
              dense
              messages=""
              small-chips
              deletable-chips
              :items="services"
              :error-messages="idErrors"
              @input="$v.formData.id.$touch()"
              @blur="$v.formData.id.$touch()"
            ></v-autocomplete>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";

export default {
  name: "AddSku",
  mixins: [validationMixin],
  validations: {
    formData: {
      id: { required },
    },
  },
  props: ["actionFunction", "services", "pageLoader", "contract"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.step2Form = null;
      this.pageLoader(true);
      let data = { service_id: this.formData.id };
      // console.log(data, this.formData);
      ApiService.post("/cod/contracts/services", data)
        .then((response) => {
          this.serverData = response.data;
          this.setStep2Form();
        })
        .catch(() => {
          this.step = 1;
          this.loader(false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        // this.formData.system_code = this.items.map(sku =>
        //   sku.skuId == this.formData.sku_id ? sku.systemCode : null
        // )[0];
        // console.log("supermode is ", this.formData, this.items);
        this.actionFunction(this.formData.id);
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();

      this.formData = {
        id: [],
      };
    },

    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
      this.serverData = null;
    },
  },
  computed: {
    idErrors: function () {
      return this.handleFormValidation("id", this);
    },
  },
};
</script>

<style></style>
