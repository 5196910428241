<template>
  <div>
    <div class="d-flex align-center mb-4 h-35px px-1">
      <div class="mr-1 btn-group">
        <button
          @click="currentQuery.condition = 'and'"
          class="btn btn-sm"
          :class="andConditionButtonVariant"
        >
          AND
        </button>
        <button
          @click="currentQuery.condition = 'or'"
          class="btn btn-sm"
          :class="orConditionButtonVariant"
        >
          OR
        </button>
      </div>
      <b-button-group class="ml-auto" size="sm">
        <b-button @click="deleteGroup" variant="danger" v-if="level > 0">
          <b-icon-trash-fill /> Delete Group
        </b-button>
        <button @click="addRule" class="btn btn-outline-info">
          <b-icon-box /> Add Rule
        </button>
        <button @click="addGroup" class="btn btn-outline-info">
          <b-icon-folder-plus /> Add Group
        </button>
      </b-button-group>
    </div>
    <template v-for="(item, index) in currentQuery.rules">
      <b-card
        no-body
        class="mt-2 border border-info"
        :key="index"
        v-if="typeof item.condition === 'string'"
      >
        <b-card-body class="p-2">
          <RuleGroup
            :current-query="item"
            :options="options"
            :operators="operators"
            :userId="userId"
            :level="level + 1"
          />
        </b-card-body>
      </b-card>
      <Rule
        :rule="item"
        :options="options"
        :userId="userId"
        :key="index"
        :updateComponent="updateComponent"
        v-else
        class="mt-2 px-1"
      />
    </template>
  </div>
</template>

<script>
import Rule from "@/own/components/shipping/courierAssignRules/ruleBuilder/Rule.vue";
export default {
  name: "RuleGroup",
  components: { Rule },
  props: {
    currentQuery: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
    },
    operators: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: false,
      default: 0,
    },
    userId: {
      required: false,
    },
  },
  data: function () {
    return {
      condition: "and",
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      if (this.currentQuery.rules.length === 0) {
        this.addRule();
      }
    });
  },
  computed: {
    andConditionButtonVariant: function () {
      if (this.currentQuery.condition === "and") {
        return "btn-info";
      }
      return "btn-light-info";
    },
    orConditionButtonVariant: function () {
      if (this.currentQuery.condition === "or") {
        return "btn-info";
      }
      return "btn-light-info";
    },
  },
  methods: {
    addRule() {
      let options = [...this.options];
      const newGroup = {
        id: options[0].name,
        operator: this.operators[0].name,
        value: "",
      };
      this.currentQuery.rules.push(newGroup);
    },
    updateComponent() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    addGroup() {
      const newRule = {
        condition: "and",
        rules: [],
      };
      this.currentQuery.rules.push(newRule);
    },
    deleteGroup() {
      const self = this;
      const parentRules = self.$parent.currentQuery.rules;
      parentRules.splice(parentRules.indexOf(self.currentQuery), 1);
    },
  },
};
</script>
