export const ROUTES = [
  "/receiving",
  "/putaway_task",
  "/picking",
  "/packing",
  "/shipping",
  "/b2b_pick_and_pack",
  "/qc",
  "/fulfillment/orders",
  "/fulfillment/inventory",
  "/asn",
  "/putaway_rule_based_templates",
  "/putaway_rules",
  "/fulfillment_tasks",
  "/picking_assignment",
  "/warehouse_management/transaction_logs",
  "/warehouse_management/inventory_balances",
  "/allocation_rule_based_templates",
  "/allocation_rules",
  "/warehouse_management/inventory_snapshots",
  "/warehouse_management/inventory_adjustments",
  "/warehouse_management/location_movements",
  "/warehouse_management/sku_movements",
  "/skipped_fulfillment_tasks",
  "/warehouse_management/random_cycle_counts",
  "/warehouse_management/standard_cycle_counts",
  "/waves",
  "/wave_logs",
  "/allocation_strategy_rules",
  "/customer_cases",
  "/sortation_orders",
  "/warehouse_management/standard_quality_checks",
  "/warehouse_management/rco_picking_lists",
  "/sortation_skus",
  "/sortation_sku_cases",
  "/standard_receiving",
  "/standard_putaway",
  "/inbound_packaging",
  "/warehouse_management/standard_grn",
  "/outbound/standard_order_picking",
  "/outbound/standard_order_packing",
  "/outbound/standard_order_putaway",
  "/outbound/standard_order_dispatch",
  "/outbound/standard_order_packaging",
  "/warehouse_management/inventory/cycle_counts",
  "/warehouse_management/package_movements",
  "/picking_strategy_rules",
  "/parsing_rules",
  "/warehouse_management/inventory_overview",
];
