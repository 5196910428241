<template>
  <v-dialog
    v-model="dialog"
    :max-width="value.header && value.header.length < 6 ? 850 : 1050"
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }" v-if="value.header">
      <button
        class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
        @click="toggleModal"
      >
        {{ $t("view") }}
      </button>
    </template>
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ $t("token") }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <h6>{{ value.body[0] }}</h6>
        <!--end::Body-->
      </div>
      <!--end::Modal body-->
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ShowToken",
  props: ["value"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
