<template>
  <div>
    <v-menu left rounded>
      <template v-slot:activator="{ on, attrs }">
        <button class="btn btn-sm btn-icon" v-bind="attrs" v-on="on">
          <span class="svg-icon">
            <v-icon size="19"> mdi-dots-vertical </v-icon>
          </span>
        </button>
      </template>

      <v-list class="py-0">
        <v-list-item
          @click.prevent="() => showItemDetail(itemId)"
          class="text-center bg-hover-light-info"
        >
          <v-list-item-title class="poppins iq-color">Show</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click.prevent="() => editItem()"
          class="text-center bg-hover-light-info"
        >
          <v-list-item-title class="poppins iq-color">Edit</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click.prevent="() => removeItem(itemId)"
          class="text-center bg-hover-light-info"
        >
          <v-list-item-title class="poppins iq-color">Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <EditWarehouse ref="editWarehouse" :id="itemId" :editType="editType" />
    <EditZoneGroup ref="editZoneGroup" :id="itemId" :editType="editType" />
    <EditZone ref="editZone" :id="itemId" :editType="editType" />
    <EditLocation ref="editLocation" :id="itemId" :editType="editType" />

    <ShowDetail
      ref="showDetail"
      :type-of-component="editType"
      :data-to-show="getdetailData"
    ></ShowDetail>
  </div>
</template>

<script>
import swalDeleteQuery from "@/core/services/AlertServices/SwalDeleteQuery";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import EditWarehouse from "@/own/components/warehouseManagement/warehouses/Edit/EditWarehouse";
// import ActionMove from "@/own/components/warehouseManagement/warehouses/ActionMove";

import EditZoneGroup from "@/own/components/warehouseManagement/warehouses/Edit/EditZoneGroup";
import EditZone from "@/own/components/warehouseManagement/warehouses/Edit/EditZone";
import EditLocation from "@/own/components/warehouseManagement/warehouses/Edit/EditLocation";
import {
  GET_ZONEGROUPS,
  GET_LOCATIONS,
  GET_WAREHOUSES,
  GET_ZONES,
} from "@/core/services/store/warehouses.module";
import ShowDetail from "@/own/components/warehouseManagement/warehouses/ShowDetail";

export default {
  name: "ActionsMenu",
  components: {
    ShowDetail,
    EditLocation,
    EditZone,
    EditZoneGroup,
    EditWarehouse,
    // ActionMove,
  },
  props: ["itemId", "editType"],
  data: () => ({
    editWarehouse: false,
    editZoneGroup: false,
    editZone: false,
    editArea: false,
    detailData: {},
  }),
  computed: {
    getdetailData() {
      return this.detailData;
    },
  },
  methods: {
    // moveItem() {
    //   this.$refs.actionMove.toggleModal();
    // },
    editItem() {
      if (this.editType === "warehouse") this.$refs.editWarehouse.toggleModal();
      else if (this.editType === "zone_group")
        this.$refs.editZoneGroup.toggleModal();
      else if (this.editType === "zone") this.$refs.editZone.toggleModal();
      else this.$refs.editLocation.toggleModal();
    },
    showItemDetail(id) {
      const requestData = {};
      if (this.editType === "warehouse") {
        requestData.id = id;
        requestData.type = this.editType;
      } else if (this.editType === "zone_group") {
        requestData.id = id;
        requestData.type = this.editType;
        requestData.warehouse_id =
          this.$store.getters.getWAREHOUSESSelectedWarehouseId;
      } else if (this.editType === "zone") {
        requestData.id = id;
        requestData.type = this.editType;
        requestData.warehouse_id =
          this.$store.getters.getWAREHOUSESSelectedWarehouseId;
        requestData.zone_group_id =
          this.$store.getters.getWAREHOUSESSelectedZoneGroupId;
      } else {
        requestData.id = id;
        requestData.type = this.editType;
        requestData.warehouse_id =
          this.$store.getters.getWAREHOUSESSelectedWarehouseId;
        requestData.zone_group_id =
          this.$store.getters.getWAREHOUSESSelectedZoneGroupId;
        requestData.zone_id = this.$store.getters.getWAREHOUSESSelectedZoneId;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        "/warehouse_management/warehouses/show",
        requestData
      ).then((response) => {
        this.detailData = {};
        this.detailData = response.data.value;
        this.$refs.showDetail.toggleModal();
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    removeItem(id) {
      swalDeleteQuery().then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(SET_PAGE_LOADING, true);
          ApiService.post("/warehouse_management/warehouses/destroy", {
            id: id,
            type: this.editType,
          })
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success").then(
                (res) => {
                  if (res.isConfirmed) {
                    if (this.editType === "warehouse")
                      this.$store.dispatch(GET_WAREHOUSES).finally(() => {
                        this.$store.commit(SET_PAGE_LOADING, false);
                      });
                    else if (this.editType === "zone_group") {
                      let payload = {
                        warehouse_id:
                          this.$store.getters.getWAREHOUSESSelectedWarehouseId,
                        type: "zone_group",
                      };
                      this.$store
                        .dispatch(GET_ZONEGROUPS, payload)
                        .finally(() => {
                          this.$store.commit(SET_PAGE_LOADING, false);
                        });
                    } else if (this.editType === "zone") {
                      let payload = {
                        warehouse_id:
                          this.$store.getters.getWAREHOUSESSelectedWarehouseId,
                        zone_group_id:
                          this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
                        type: "zone",
                      };
                      this.$store.dispatch(GET_ZONES, payload).finally(() => {
                        this.$store.commit(SET_PAGE_LOADING, false);
                      });
                    } else {
                      let payload = {
                        warehouse_id:
                          this.$store.getters.getWAREHOUSESSelectedWarehouseId,
                        zone_group_id:
                          this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
                        zone_id:
                          this.$store.getters.getWAREHOUSESSelectedZoneId,
                        type: "location",
                      };
                      this.$store
                        .dispatch(GET_LOCATIONS, payload)
                        .finally(() => {
                          this.$store.commit(SET_PAGE_LOADING, false);
                        });
                    }
                  }
                }
              );
            })
            .catch(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        }
      });
    },
  },
};
</script>
