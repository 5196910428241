<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="850"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Cycle count</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-475px scroll-y py-7">
        <!--begin::Body-->
        <v-card>
          <v-card-title
            tag="h1"
            class="text--secondary pb-0 ml-5 font-weight-medium font-size-h3 pt-9"
          >
            Completed Cycle Counts
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="completedTasks"
              item-key="system_order_number"
              class="my-6 pl-4 pr-8"
              hide-default-footer
              mobile-breakpoint="300"
              :item-class="secondText"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
        <!--end::Body-->
        <!--begin::Actions-->
        <div class="mb-4 my-5 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn--export-filter mr-3 px-5 py-3 ls1"
            @click="toggleModal"
          >
            Close
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { SET_COMPLETED_COUNT_TASKS } from "@/core/services/store/randomCycleCount.module";

export default {
  name: "CompletedSummary",
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Cycle count number",
        align: "start",
        sortable: false,
        value: "cycle_count_number",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Location",
        align: "start",
        sortable: false,
        value: "location",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "SKU",
        align: "start",
        sortable: false,
        value: "sku",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Count Quantity",
        align: "start",
        sortable: false,
        value: "count_qty",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Status",
        align: "status",
        sortable: false,
        value: "status",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
    ],
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.$store.commit(SET_COMPLETED_COUNT_TASKS, 0);
      } else {
        if (!this.completedTasks.length) {
          Swal.fire({
            title: "No Cycle count",
            icon: "warning",
            text: `No Cycle count summary is available`,
            // timer: 2500,
          });
          return;
        }
      }
      this.dialog = !this.dialog;
    },
    secondText() {
      return "second-text";
    },
  },
  computed: {
    completedTasks: function () {
      const completed = this.$store.getters.getRANDOMCYCLECOUNTCompletedTasks;
      const uniques = [];
      completed.map((item) => {
        const itemIndex = uniques.findIndex(
          (task) =>
            task.sku === item.sku &&
            task.location === item.location &&
            task.batch_number === item.batch_number
        );
        if (itemIndex === -1) {
          uniques.push(item);
        } else {
          uniques[itemIndex].count_qty = item.count_qty;
        }
      });
      return uniques;
    },
  },
};
</script>
