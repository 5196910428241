<template>
  <div>
    <div class="">
      <h4 class="pt-4">Organization</h4>
      <div class="d-block d-sm-flex justify-space-between pb-4">
        <div>
          <div class="d-flex">
            <span>Name:</span>
            <h5 class="pl-2">{{ detailData.organization.name }}</h5>
          </div>
          <div class="d-flex">
            <span>TRN:</span>
            <h6 class="pl-2">{{ detailData.organization.trn }}</h6>
          </div>
        </div>
        <div>
          <div>{{ detailData.organization.address.address }}</div>
          <div>{{ detailData.organization.address.city }}</div>
          <div>{{ detailData.organization.address.country }}</div>
        </div>
      </div>
      <div class="d-flex pt-4">
        <span>Warehouse type:</span>
        <h6 class="pl-2">{{ detailData.warehouse_type.title }}</h6>
      </div>
      <div class="d-flex">
        <span>System code:</span>
        <h6 class="pl-2">{{ detailData.system_code }}</h6>
      </div>
      <div class="d-flex">
        <span>Warehouse name:</span>
        <h6 class="pl-2">{{ detailData.warehouse_name }}</h6>
      </div>
      <div class="d-flex">
        <span>Timezone:</span>
        <h6 class="pl-2">{{ detailData.timezone }}</h6>
      </div>
      <div class="d-flex">
        <span>Company code:</span>
        <h6 class="pl-2">{{ detailData.company_code }}</h6>
      </div>
      <div class="d-flex">
        <span>Description:</span>
        <h6 class="pl-2">{{ detailData.description }}</h6>
      </div>
      <div>
        <h4 class="pt-4">Address</h4>
        <div>
          <div class="d-flex">
            <span>Country:</span>
            <h6 class="pl-2" v-if="detailData.country">
              {{ detailData.country.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>State:</span>
            <h6 class="pl-2" v-if="detailData.state_id">
              {{ detailData.state_id.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>City:</span>
            <h6 class="pl-2" v-if="detailData.city_id">
              {{ detailData.city_id.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>Area:</span>
            <h6 class="pl-2" v-if="detailData.area_id">
              {{ detailData.area_id.title }}
            </h6>
          </div>
          <div class="d-flex">
            <span>Address:</span>
            <h6 class="pl-2">{{ detailData.address }}</h6>
          </div>
          <div class="d-flex">
            <span>Address 2:</span>
            <h6 class="pl-2">{{ detailData.address_2 }}</h6>
          </div>
          <div class="d-flex">
            <span>Postal code:</span>
            <h6 class="pl-2">{{ detailData.postal_code }}</h6>
          </div>
        </div>
      </div>

      <div>
        <h4 class="pt-4">Contact</h4>
        <div>
          <div class="d-flex">
            <span>Name:</span>
            <h6 class="pl-2">{{ detailData.contact_name }}</h6>
          </div>
          <div class="d-flex">
            <span>Phone:</span>
            <h6 class="pl-2">{{ detailData.contact_phone }}</h6>
          </div>
          <div class="d-flex">
            <span>Email:</span>
            <h6 class="pl-2">{{ detailData.contact_email }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Warehouse.vue",
  props: {
    detailData: {
      required: true,
      type: Object,
    },
  },
};
</script>
