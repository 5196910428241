<template>
  <ReportSimpleDataTable
    :urls="urls"
    :mandatory-filter="false"
    :getDataOnPageOpening="false"
  />
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ReportSimpleDataTable from "@/own/components/reports/ReportSimpleDataTable.vue";
export default {
  name: "CasePriority",
  components: { ReportSimpleDataTable },
  data: () => ({
    urls: {
      data_url: "/reports/reportCasePriority",
      show_url: "/reports/reportCasePriority/show",
    },
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
