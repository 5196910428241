<template>
  <div>
    <div
      class="font-weight-boldest mb-12"
      :class="{ 'text-right': $vuetify.rtl }"
    >
      <h1 class="font-weight-boldest title mb-3 mt-15 mt-lg-0">
        {{ $t("reset_password") }}
      </h1>
      <p class="subtitle">
        {{ $t("run_manage_and_scale_your_ecommerce") }}
      </p>
    </div>
    <b-form @submit="submitPasswordReset">
      <div>
        <div class="mt-10">
          <b-input-group class="input-group">
            <b-input-group-append>
              <div class="mt-5 ml-3">
                <span class="svg-icon">
                  <v-icon size="28">mdi-account-outline</v-icon>
                </span>
              </div>
            </b-input-group-append>
            <b-form-input
              :placeholder="$t('email')"
              required
              v-model="email"
              name="email"
              type="email"
              id="email"
              class="email-input"
              ref="email"
              :state="isEmailValid"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div
        class="d-flex submit-button-container justify-content-center mb-14 mt-14 p-1"
      >
        <button
          type="submit"
          ref="submit"
          class="submit-button m-1 font-weight-bolder"
          :class="[
            !isEmailValid ? 'v-btn--disabled opacity-30' : '',
            loading ? ' loading' : '',
          ]"
        >
          <template v-if="loading">
            Submitting...
            <v-progress-circular
              :size="20"
              width="3"
              color="primary"
              indeterminate
              class="ml-2 mb-1"
            ></v-progress-circular>
          </template>
          <template v-else>{{ $t("submit") }}</template>
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";

export default {
  name: "PasswordReset",
  data: () => ({
    email: "",
    loading: false,
  }),

  computed: {
    isEmailValid: function() {
      const reg = RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      return reg.test(this.email);
    },
  },

  methods: {
    submitPasswordReset(event) {
      event.preventDefault();
      if (this.isEmailValid && !this.loading) {
        const data = { email: this.email, action: "reset" };
        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        this.loading = true;

        ApiService.post("/auth/password/reset", data)
          .then((data) => {
            if (data.data.success) {
              SwalService.successMessage({
                html: data.data.message,
              });
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, 2000);
            }
          })
          .catch(() => {})
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/auth/generate-password.scss";
</style>
