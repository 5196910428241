<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="700"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Record</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon svg-icon-5">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm">
            <div class="row">
              <div
                class="h-75px my-0 py-0 col-12 col-sm-6"
                v-if="serverData?.organizations"
              >
                <v-select
                  v-model="formData.organization_id"
                  label="Organization"
                  item-text="text"
                  item-value="index"
                  :items="serverData.organizations"
                  clearable
                  outlined
                  dense
                  required
                  :error-messages="organization_idErrors"
                  @input="$v.formData.organization_id.$touch()"
                  @blur="$v.formData.organization_id.$touch()"
                ></v-select>
              </div>
              <div class="h-75px my-0 py-0 col-12 col-sm-6">
                <v-select
                  v-model="formData.category_id"
                  label="Category"
                  item-text="text"
                  item-value="index"
                  :items="serverData.categories"
                  clearable
                  outlined
                  dense
                  required
                  :error-messages="category_idErrors"
                  @input="$v.formData.category_id.$touch()"
                  @blur="$v.formData.category_id.$touch()"
                ></v-select>
              </div>
              <div class="h-75px my-0 py-0 col-12 col-sm-6">
                <v-select
                  v-model="formData.size_id"
                  label="Size"
                  item-text="text"
                  item-value="index"
                  :items="serverData.sizes"
                  clearable
                  outlined
                  dense
                  required
                ></v-select>
              </div>
              <div class="h-75px my-0 py-0 col-12 col-sm-6">
                <v-select
                  v-model="formData.type_id"
                  label="Type"
                  item-text="text"
                  item-value="index"
                  :items="serverData.types"
                  clearable
                  outlined
                  dense
                  hide-details
                  required
                  :error-messages="type_idErrors"
                  @input="$v.formData.type_id.$touch()"
                  @blur="$v.formData.type_id.$touch()"
                ></v-select>
              </div>

              <div class="h-75px my-0 py-0 col-12 col-sm-6">
                <v-text-field
                  readonly
                  v-model="formData.vehicle_number"
                  label="Vehicle number"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div class="h-75px my-0 py-0 col-12 col-sm-6" v-if="isContainer">
                <v-text-field
                  v-model="formData.serial_number"
                  label="Seal number"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div class="h-75px my-0 py-0 col-12 col-sm-6">
                <v-text-field
                  v-model="formData.driver_number"
                  label="Driver name"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div class="h-75px my-0 py-0 col-12 col-sm-6">
                <v-text-field
                  v-model="formData.mobile_number"
                  label="Mobile number"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </div>
            </div>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { required, requiredIf } from "vuelidate/lib/validators";
import validationMixin from "@/own/mixins/validationMixin";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      type_id: { required },
      organization_id: {
        required: requiredIf(function() {
          return this.serverData?.organizations;
        }),
      },
      category_id: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      organization_id: null,
      category_id: null,
      type_id: null,
      size_id: null,
      vehicle_number: null,
      serial_number: null,
      driver_number: null,
      mobile_number: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/organization/dispatchVehicle/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.vehicle;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/organization/dispatchVehicle/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `The record is updated!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();

      this.formData = {
        id: null,
        organization_id: null,
        category_id: null,
        type_id: null,
        size_id: null,
        vehicle_number: null,
        serial_number: null,
        driver_number: null,
        mobile_number: null,
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isContainer() {
      return this.serverData?.categories.find(
        (cat) => cat.index == this.formData.category_id
      )?.text == "Container"
        ? true
        : false;
    },
    isOrganization() {
      return this.formData.type_id === 1;
    },
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
    type_idErrors: function() {
      return this.handleFormValidation("type_id", this);
    },
    category_idErrors: function() {
      return this.handleFormValidation("category_id", this);
    },
    organization_idErrors: function() {
      return this.handleFormValidation("organization_id", this);
    },
  },
};
</script>
