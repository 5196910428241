<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Create Integration</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <form id="createForm" class="pa-3 pb-0">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <template v-for="n in steps">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="e1 > n"
                    :step="n"
                  >
                    <span v-if="n === 1">Integration</span>
                    <span v-if="n === 2">Actions</span>
                    <span v-if="n === 3">Validations</span>
                  </v-stepper-step>

                  <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content :step="1" :key="`1-content`" class="pt-3">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <div class="m-3">
                        <v-autocomplete
                          v-model.trim="$v.formData.customer_id.$model"
                          :error-messages="
                            validateState('customer_id') ? requiredMessage : ''
                          "
                          :items="customers"
                          outlined
                          item-name="text"
                          item-value="index"
                          clearable
                          dense
                          label="Select Customer*"
                        ></v-autocomplete>
                        <v-autocomplete
                          v-model.trim="$v.formData.platform_id.$model"
                          :error-messages="
                            validateState('platform_id') ? requiredMessage : ''
                          "
                          :items="platforms"
                          outlined
                          item-text="name"
                          item-value="id"
                          dense
                          chips
                          clearable
                          label="Select Platform*"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              @click="data.select"
                            >
                              <v-avatar left>
                                <v-img :src="data.item.image"></v-img>
                              </v-avatar>
                              {{ data.item.name }}
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <show-url-image :url="item.image" class="mr-3">
                            </show-url-image>
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.name"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                        <v-text-field
                          v-if="formData.platform_id === 12"
                          v-model.trim="$v.formData.instance_id.$model"
                          :error-messages="
                            validateState('instance_id') ? requiredMessage : ''
                          "
                          label="Instance Id"
                          clearable
                          outlined
                          dense
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="$v.formData.url.$model"
                          :error-messages="
                            validateState('url') ? requiredMessage : ''
                          "
                          label="URL*"
                          clearable
                          outlined
                          dense
                        ></v-text-field>
                        <v-text-field
                          v-model="formData.prefix"
                          label="Prefix"
                          clearable
                          outlined
                          dense
                        ></v-text-field>
                        <div
                          class="row"
                          v-if="
                            formData.platform_id &&
                            formData.platform_id === shopify_id
                          "
                        >
                          <div class="col-6">
                            <v-text-field
                              v-model="formData.shopify_location_id"
                              type="number"
                              @change="
                                () =>
                                  validateMinValue(
                                    'shopify_location_id',
                                    0,
                                    'formData'
                                  )
                              "
                              label="Shopify Location ID*"
                              clearable
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                          <div class="col-6">
                            <v-text-field
                              v-model="formData.api_version"
                              label="Shopify API Version*"
                              clearable
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-4">
                            <v-text-field
                              v-model="formData.api_key"
                              label="API Key"
                              clearable
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                          <div class="col-4">
                            <v-text-field
                              v-model="formData.api_secret"
                              label="API Secret"
                              clearable
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                          <div class="col-4">
                            <v-text-field
                              v-model="formData.api_url"
                              label="API URL"
                              clearable
                              outlined
                              dense
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                      <v-card class="mb-4">
                        <v-toolbar-title
                          class="primary lighten-1 h3 text-white text-center mt-4 pa-1"
                          >Status</v-toolbar-title
                        >
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12" md="6" lg="4">
                              <v-switch
                                v-model="formData.is_test"
                                color="primary"
                                label="Is Test?"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="4">
                              <v-switch
                                v-model="formData.update_status"
                                color="primary"
                                label="Update Status"
                              ></v-switch>
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="4">
                              <v-switch
                                v-model="formData.update_inventory"
                                color="primary"
                                label="Update Inventory"
                              ></v-switch>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                    <div class="stepper-footer">
                      <div></div>
                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(1)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="2" :key="`2-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <div v-if="formData.platform_id">
                        <div
                          class="d-flex"
                          v-for="(i, index) in itemCount"
                          :key="index"
                        >
                          <v-autocomplete
                            v-model="action_id[index]"
                            :items="actionsData"
                            outlined
                            item-text="action"
                            item-value="id"
                            dense
                            clearable
                            label="Action Name"
                            class="mr-4"
                          >
                            <template v-slot:item="{ item }">
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="item.action"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                          <v-text-field
                            v-model="system_action[index]"
                            label="System Actions"
                            clearable
                            outlined
                            dense
                            class="mr-4"
                          ></v-text-field>
                          <span class="mt-2"
                            ><v-icon left @click="removeImageItem(i - 1)">
                              mdi-delete
                            </v-icon></span
                          >
                        </div>
                        <v-btn
                          class="mr-4 mt-2 mb-4"
                          color="primary"
                          @click="addActions"
                        >
                          Add Action
                        </v-btn>
                      </div>
                    </div>
                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(2)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(2)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="3" :key="`3-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-autocomplete
                        v-model="formData.validations"
                        :items="validations"
                        dense
                        chips
                        small-chips
                        item-text="name"
                        item-value="id"
                        label="Validations"
                        multiple
                        outlined
                      ></v-autocomplete>
                    </div>
                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(3)"
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="submitCreateForm"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </form>

          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ShowUrlImage from "@/own/components/datatable/ShowUrlImage.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { messages } from "@/core/constants/message";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "AddIntegration",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      customer_id: { required },
      platform_id: { required },
      url: { required },
      instance_id: { required },
    },
  },
  components: {
    ShowUrlImage,
  },
  props: ["pageLoader", "refresher"],
  data: () => ({
    dialog: false,
    languages: null,
    parent_id: null,
    value: null,
    action_id: [],
    system_action: [],
    formData: {
      customer_id: null,
      platform_id: null,
      instance_id: null,
      url: null,
      prefix: null,
      is_test: null,
      update_status: null,
      api_version: null,
      api_key: null,
      api_secret: null,
      api_url: null,
      update_inventory: null,
      shopify_location_id: null,
      actions: null,
      validations: [],
    },
    itemCount: 0,
    e1: 1,
    steps: 3,
  }),
  computed: {
    actionsData: function () {
      if (this.formData.platform_id) {
        let result = this.platforms.filter(
          (item) => item.id === this.formData.platform_id
        );
        return result[0].actions;
      } else return null;
    },
    requiredMessage: function () {
      return messages.requiredMessage;
    },
    customers: function () {
      return this.$store.getters.getINTEGRATIONSCustomers;
    },
    platforms: function () {
      return this.$store.getters.getINTEGRATIONSPlatforms;
    },
    validations: function () {
      return this.$store.getters.getINTEGRATIONSValidations;
    },
    shopify_id: function () {
      if (this.formData.platform_id) {
        let result = this.platforms.find((item) => item.name === "Shopify");
        return result.id;
      } else return null;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    toggleModal() {
      if (this.dialog) this.resetData();
      this.dialog = !this.dialog;
    },
    addActions() {
      this.itemCount++;
    },
    removeImageItem(index) {
      this.itemCount--;
      this.action_id.splice(index, 1);
      this.system_action.splice(index, 1);
    },
    filterFormData() {
      for (let key in this.formData) {
        if (
          key === "is_test" ||
          key === "update_status" ||
          key === "update_inventory"
        ) {
          if (!this.formData[key]) {
            this.formData[key] = false;
          }
        }
      }
    },
    submitCreateForm() {
      this.filterFormData();
      let actionsData = [];
      if (this.action_id) {
        for (let i in this.action_id) {
          let obj = {
            action_id: this.action_id[i],
            system_action: this.system_action[i],
          };
          actionsData.push(obj);
        }
      }
      this.formData.actions = actionsData;
      this.pageLoader(true);
      const updateUrl = `${process.env.VUE_APP_BASE_URL}/datahub/integrations/store`;
      let data = this.formData;
      ApiService.post(updateUrl, data)
        .then(() => {
          this.pageLoader(false);
          Swal.fire({
            title: "Created",
            text: `The Integration data has been Created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
          this.$forceUpdate();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    errorMessage(message) {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetData() {
      this.formData.customer_id = null;
      this.formData.platform_id = null;
      this.formData.instance_id = null;
      this.formData.url = null;
      this.formData.prefix = null;
      this.formData.is_test = null;
      this.formData.update_status = null;
      this.formData.api_key = null;
      this.formData.api_secret = null;
      this.formData.api_url = null;
      this.formData.api_version = null;
      this.formData.update_inventory = null;
      this.formData.shopify_location_id = null;
      this.formData.validations = [];
      this.formData.actions = [];
      this.action_id = [];
      this.itemCount = 0;
      this.system_action = [];
      this.nextStep(3);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    previewStep(n) {
      if (n === 1) this.el = this.steps;
      else this.e1 = n - 1;
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n === 1) {
          this.$v.formData.$touch();
          if (this.$v.formData.$anyError) {
            if (
              this.formData.platform_id !== 12 &&
              !this.formData.instance_id
            ) {
              this.e1 = n + 1;
            } else return;
          } else this.e1 = n + 1;
        } else if (n === 2) {
          let checkVal = this.checkActionValidation();
          if (checkVal) this.e1 = n + 1;
          else this.errorMessage("Should Complete all Action Field!");
        } else this.e1 = n + 1;
      }
    },
    checkActionValidation() {
      return this.itemCount === this.action_id.length;
    },
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>
