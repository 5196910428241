<template>
  <v-card id="consignee" class="mt-4">
    <v-card-title class="primary h5 text-white">{{ item.title }}</v-card-title>
    <v-card-text>
      <div v-for="(element, index) in item.data" :key="index">
        <span class="other_key font-weight-bolder">{{ element.title }}: </span
        ><span class="other_value" :style="element.style">{{
          element.value
        }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PopUpTextGroup",
  props: ["item"],
};
</script>
