<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    persistent
    transition="dialog-bottom-transition"
  >
    <div class="bg-white poppins position-relative">
      <div
        class="modal-header py-3 align-center bg-white position-sticky top-0 left-0 zindex-5"
      >
        <h4 class="mb-0 font-weight-bolder poppins">
          {{ $t("details") }}
        </h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 pb-15">
        <!--begin::Body-->
        <div v-for="(item, i) in details" :key="i">
          <pop-up-selector :item="item"></pop-up-selector>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import PopUpSelector from "@/own/components/datatable/popup/PopUpSelector.vue";
export default {
  name: "RecordDetail",
  components: { PopUpSelector },
  props: {
    title: {
      type: String,
      required: true,
    },
    pageLoader: {
      type: Function,
      required: true,
    },
    detailsUrl: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    details: null,
  }),
  computed: {
    item() {
      return this.$store.getters.getItemForAction;
    },
  },
  methods: {
    async loadDataFromServer() {
      this.pageLoader(true);
      const data = { id: this.item.id };
      await ApiService.post(this.detailsUrl, data)
        .then((response) => {
          this.details = response.data.detail;
        })
        .catch(() => {})
        .finally(() => {
          this.pageLoader(false);
        });
    },

    async toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.details = null;
      } else {
        await this.loadDataFromServer().then(() => {
          this.dialog = !this.dialog;
        });
      }
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
.other_key {
  margin: 2px;
}
.other_value {
  font-weight: bold;
  margin: 2px;
  margin-left: 6px;
}
.main {
  background-color: rgb(245, 245, 245);
}
</style>
