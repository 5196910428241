<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    persistent
    transition="dialog-bottom-transition"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">ASN detail</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div v-if="other">
          <div v-for="(item, key) in other" :key="key">
            <div v-if="key == 'skus'">
              <v-card>
                <v-card-title class="primary h5 text-white">{{
                  item.title
                }}</v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="skuHeaders"
                    :items="item.value"
                    hide-default-footer
                    class="elevation-1"
                  ></v-data-table>
                </v-card-text>
              </v-card>
            </div>
            <div v-else-if="key == 'status_history'">
              <v-card>
                <v-card-title class="primary h5 text-white">{{
                  item.title
                }}</v-card-title>
                <v-card-text>
                  <v-timeline>
                    <v-timeline-item
                      v-for="(status, i) in item.value"
                      :key="i"
                      color="primary"
                      large
                    >
                      <template v-slot:opposite>
                        <span>{{
                          new Date(status.time * 1000).toLocaleDateString()
                        }}</span>
                      </template>
                      <v-card class="elevation-2">
                        <v-card-title class="headline">
                          {{ status.label }}
                        </v-card-title>
                        <v-card-text v-if="status.note">
                          {{ status.note }}
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </v-card-text>
              </v-card>
            </div>
            <div v-else class="">
              <v-card>
                <v-card-text>
                  <div
                    v-if="
                      key != 'creation_time' &&
                      key != 'last_status_time' &&
                      key != 'received_time'
                    "
                  >
                    {{ item.title }}: {{ item.value }}
                  </div>
                  <div v-else>
                    {{ item.title }}:
                    <span>{{ new Date(item.value).toLocaleTimeString() }}</span>
                    <span>{{ new Date(item.value).toLocaleDateString() }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "Others",
  data: () => ({
    dialog: false,
    historyHeaders: [
      { text: "Label", value: "label" },
      { text: "Note", value: "note" },
      { text: "Time", value: "time" },
    ],
    skuHeaders: [
      { text: "Id", value: "id" },
      { text: "System Code", value: "system_code" },
      { text: "description", value: "description" },
      { text: "Expected Qty", value: "expected_qty" },
      { text: "Received Qty", value: "received_qty" },
    ],
    other: null,
  }),
  methods: {
    setData(otherData) {
      this.other = otherData;
    },
    toggleModal() {
      if (this.dialog) {
        this.other = null;
        this.dialog = !this.dialog;
      } else {
        this.dialog = !this.dialog;
      }
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
.other_key {
  margin: 2px;
}
.other_value {
  font-weight: bold;
  margin: 2px;
  margin-left: 6px;
}
.main {
  background-color: rgb(245, 245, 245);
}
</style>
