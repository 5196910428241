<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form class="row">
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.connection_id"
              :label="$t('connection')"
              :items="serverData.connections"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="connection_idErrors"
              @blur="$v.formData.connection_id.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.name"
              :label="$t('name')"
              dense
              outlined
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-text-field
              v-model="formData.ip"
              :label="$t('ip')"
              dense
              outlined
              clearable
              :error-messages="ipErrors"
              @blur="$v.formData.ip.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-text-field
              v-model="formData.zone_id"
              :label="$t('zone_id')"
              dense
              outlined
              clearable
              :error-messages="zone_idErrors"
              @blur="$v.formData.zone_id.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.description"
              :label="$t('description')"
              dense
              outlined
              clearable
            />
          </div>
        </form>

        <!--begin::Actions-->
        <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light me-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "ActionsDialog",
  mixins: [validationMixin],
  props: ["refresher", "pageLoader"],
  validations: {
    formData: {
      connection_id: { required },
      name: { required },
      ip: { required },
      zone_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      connection_id: null,
      name: null,
      ip: null,
      zone_id: null,
      description: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/wes/wesStations/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = data.data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        this.pageLoader(true);
        ApiService.post("/wes/wesStations/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    resetFormData() {
      this.formData = {
        connection_id: null,
        name: null,
        ip: null,
        zone_id: null,
        description: null,
      };
    },

    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);

      // let sendData = {
      //   ...this.formData,
      // };

      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/wes/wesStations/${actionUrl}`, {
        ...this.formData,
      }).then(() => {
        if (this.actionType) {
          SwalService.successMessage({
            html: SwalService.messages.updated(),
          });
        } else {
          SwalService.successMessage({
            html: SwalService.messages.added(),
          });
        }
        this.refresher();
        this.toggleModal();
      });
      // .finally(() => {
      //   this.$store.commit(SET_PAGE_LOADING, false);
      // });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return this.$t("update");
      else return this.$t("submit");
    },
    headerText: function () {
      if (this.actionType) return this.$t("edit_item");
      else return this.$t("add_new_item");
    },

    connection_idErrors: function () {
      return this.handleFormValidation("connection_id", this);
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    ipErrors: function () {
      return this.handleFormValidation("ip", this);
    },
    zone_idErrors: function () {
      return this.handleFormValidation("zone_id", this);
    },
  },
};
</script>
