<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :recordDetailComponent="componentForRecordDetail"
      :delete_url="delete_url"
      :urls="urls"
    >
      <template #datatableTop="data"> </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import RecordDetail from "@/own/components/RecordDetail.vue";
import AddItem from "@/own/components/warehouseManagement/store/AddItem.vue";
import EditItem from "@/own/components/warehouseManagement/store/EditItem.vue";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/warehouseStore.module";
export default {
  name: "Store",
  components: { DataTable },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    componentForRecordDetail: RecordDetail,
    delete_url: `${process.env.VUE_APP_BASE_URL}/warehouse_management/client_stores/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getWARESTORETableFilters",
      getTableState: "getWARESTORETableState",
      getTableData: "getWARESTORETableData",
      getTableHeaders: "getWARESTORETableHeaders",
      getTableProperties: "getWARESTORETableProperties",
      getTableExportUrl: "getWARESTOREExportUrl",
      getTablePermissions: "getWARESTORETablePermissions",
      getUploadType: "getWARESTOREUploadType",
    },
    urls: {
      delete: "/warehouse_management/client_stores/destroy",
      details: "/warehouse_management/client_stores/show",
    },
  }),
  methods: {
    permissionChecker(permType) {
      let result = false;
      this.$store.getters[this.store_names.getTablePermissions].forEach(
        (element) => {
          if (element.name === permType) {
            result = true;
          }
        }
      );
      return result;
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
