<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
    <div class="bg-white poppins" style="background-color: white; height: 100%">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          {{ item ? "Edit" : "Add" }} New Service
        </h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!--        <v-btn rounded color="primary" @click="dialog = false">-->
        <!--          Create-->
        <!--        </v-btn>-->
      </div>
      <v-card-text class="pt-2 h-100">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Select service
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              Service form
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card elevation="0" class="mb-4">
                <form id="editForm" class="pa-3 pt-0 pb-0 row">
                  <div class="col-12">
                    <v-autocomplete
                      v-model="formData.id"
                      label="Service"
                      item-text="text"
                      item-value="index"
                      clearable
                      outlined
                      :items="services"
                      hide-selected
                      :error-messages="idErrors"
                      @input="$v.formData.id.$touch()"
                      @blur="$v.formData.id.$touch()"
                    ></v-autocomplete>
                  </div>

                  <v-main class="text-center mt-4">
                    <v-btn color="primary" @click="handleStage1">
                      {{ item ? "Continue" : "Add" }}
                    </v-btn>
                  </v-main>
                </form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-4 ma-0 pa-0" elevation="0" v-if="isStep2Ready">
                <component
                  ref="step2Form"
                  v-bind:is="step2FormComponent"
                  :setData="setForm2Data"
                  :actionFuntion="handleStage2"
                  :serverData="serverData"
                  :pageLoader="pageLoader"
                  :initData="true"
                ></component>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Swal from "sweetalert2";

import DeliveryForm from "@/own/components/finance/customers/serviceContainer/addForms/DeliveryForm";
import FulfillmentForm from "@/own/components/finance/customers/serviceContainer/addForms/FulfillmentForm";
import StorageForm from "@/own/components/finance/customers/serviceContainer/addForms/StorageForm";
import FulfillmentDelivery from "@/own/components/finance/customers/serviceContainer/addForms/FulfillmentDeliveryForm.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditSku",
  mixins: [validationMixin],
  validations: {
    formData: {
      id: { required },
    },
  },
  props: ["item", "actionFunction", "services", "pageLoader", "contract"],
  data: () => ({
    dialog: false,
    step2Form: null,
    step: 1,
    serverData: null,
    formData: {
      id: null,
      values: null,
    },
  }),
  methods: {
    handleStage1() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loadDataFromServer();
        if (!this.item) {
          this.$nextTick(() => {
            this.handleStage2();
          });
        }
      }
    },
    handleStage2(action = "submit") {
      if (action == "submit") {
        this.submitEditForm();
      } else {
        this.step = 1;
      }
    },
    setForm2Data(item) {
      // console.log("before ap", this.formData.clients);
      let copy = { ...this.formData };
      copy.values = item;
      this.formData = copy;
      // console.log("after ap", this.formData.clients);
    },
    async setStep2Form() {
      if (this.serverData.values.service_type_id == 2) {
        this.step2Form = StorageForm;
      } else if (this.serverData.values.service_type_id == 3) {
        this.step2Form = DeliveryForm;
      } else if (this.serverData.values.service_type_id == 1) {
        this.step2Form = FulfillmentForm;
      } else if (this.serverData.values.service_type_id == 4) {
        this.step2Form = FulfillmentDelivery;
      } else {
        this.step2Form = null;
      }

      await this.$nextTick(function () {
        // console.log(this.$refs, {
        // service: { values: { ...this.serverData.values } }
        // });
        this.$refs.step2Form.resetCreateForm();
        // if (this.step2Form) {
        //   try {
        //     this.$refs.step2Form.resetCreateForm();
        //   } catch (e) {
        //     console.log(e, this.$refs);
        //   }
        // }
      });
      if (this.isStep2Ready) {
        this.step = 2;
      }
      this.pageLoader(false);
    },
    loadDataFromServer() {
      this.step2Form = null;
      this.pageLoader(true);
      let data = { service_id: this.formData.id, contract_id: this.contract };
      // console.log(data, this.formData);
      ApiService.post("/cod/contracts/services", data)
        .then((response) => {
          this.serverData = response.data;
          this.setStep2Form();
        })
        .catch(() => {
          this.step = 1;
        });
    },
    handleFormValidation(fieldName, vueObj) {
      let errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        // this.formData.system_code = this.items.map(sku =>
        //   sku.skuId == this.formData.sku_id ? sku.systemCode : null
        // )[0];
        // console.log("supermode is ", this.formData, this.items);
        this.actionFunction(this.formData);
        this.toggleModal();
        this.pageLoader(false);
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.step = 1;
      if (this.item) {
        // console.log(this.item);
        this.formData = { ...this.item };
      } else {
        this.formData = {
          id: null,
          values: null,
        };
      }
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
      this.serverData = null;
    },
  },
  computed: {
    idErrors: function () {
      return this.handleFormValidation("id", this);
    },
    step2FormComponent: function () {
      return this.step2Form;
    },
    isStep2Ready: function () {
      return !!this.serverData;
    },
  },
};
</script>
