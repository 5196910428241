import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SCAN_CONFIG_TEMPLATE_CREATE_API,
  SET_FIELDS,
  SET_SELECTED_TEMPLATE_DETAILS,
  GET_DATA_SCAN_CONFIG_TEMPLATE,
} from "@/core/services/store/scanningConfigurationTemplates.module";
import ApiService from "@/core/services/api.service";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  mounted() {
    this.$store.dispatch(GET_DATA_SCAN_CONFIG_TEMPLATE).finally(() => {
      this.$store.dispatch(SCAN_CONFIG_TEMPLATE_CREATE_API);
      this.$store.commit(SET_PAGE_LOADING, false);
    });
  },
  methods: {
    toggleNextButton(id, event, tableName) {
      let elem;
      if (event.target.firstElementChild) elem = event.target;
      else elem = event.target.parentElement.parentElement;

      elem.classList.add("bg-light-info");
      elem.firstElementChild.firstElementChild.style.color = "#8950fc";

      const rows = document.querySelectorAll(
        `.${tableName}-tb > tr > td.d-flex .bg-light-info`
      );
      rows.forEach((element) => {
        if (!element.classList.contains(`${tableName}-tb-${id}-next-btn`)) {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        }
      });

      if (tableName === "templates") {
        const fieldsRows = document.querySelectorAll(
          `.fields-tb > tr > td.d-flex .bg-light-info`
        );
        fieldsRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });

        const actionsRows = document.querySelectorAll(
          `.actions-tb > tr > td.d-flex .bg-light-info`
        );
        actionsRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });
      } else if (tableName === "fields") {
        const actionsRows = document.querySelectorAll(
          `.actions-tb > tr > td.d-flex .bg-light-info`
        );
        actionsRows.forEach((element) => {
          element.classList.remove("bg-light-info");
          element.firstElementChild.firstElementChild.style.color =
            "rgba(0, 0, 0, 0.54)";
        });
      }

      this.$store.commit(SET_PAGE_LOADING, false);
    },
    handleTemplateSelect(item, event, tableName, index) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_FIELDS, {
        fields: item.fields,
        module_id: item.module_id,
      });
      this.$store.commit(SET_SELECTED_TEMPLATE_DETAILS, {
        showForm: false,
        template_id: item.id,
        template_index: index,
      });

      this.toggleNextButton(item.id, event, tableName);
    },
    handleFieldSelect(item, event, tableName, template_index) {
      this.$store.commit(SET_PAGE_LOADING, true);

      this.$store.commit(SET_SELECTED_TEMPLATE_DETAILS, {
        showForm: true,
      });

      this.$refs.nestedForm.initForm(item, template_index);
      this.toggleNextButton(item.id, event, tableName);
    },
    handleCreateTemplate(item = null, isForDuplicate = false) {
      this.$refs.createTemplate.toggleModal(item, isForDuplicate);
    },
    submitTemplate() {
      const { template_id, showForm } =
        this.$store.getters.getSCANCONFIGTEMPLATESelectedTemplateDetails;

      if (template_id) {
        if (showForm) {
          this.$refs.nestedForm.$v.$touch();
          if (this.$refs.nestedForm.$v.$invalid) {
            return;
          }
        }
        this.$store.commit(SET_PAGE_LOADING, true);
        const template =
          this.$store.getters.getSCANCONFIGTEMPLATETemplates.find(
            (item) => item.id === template_id
          );

        const payload = {
          ...template,
          fields: template.fields.map((field) => ({
            ...field,
            is_master_scan: Boolean(field.is_master_scan),
            is_required: Boolean(field.is_required),
            is_editable: Boolean(field.is_editable),
            is_parseable: Boolean(field.is_parseable),
            is_persist_input_value: Boolean(field.is_persist_input_value),
          })),
        };
        // console.log(payload);
        // return;

        ApiService.post(
          `/warehouse_management/custom/scanning_configuration_templates/update`,
          payload
        )
          .then(() => {
            swalAdded();
          })
          .then(() => {
            this.$store.dispatch(GET_DATA_SCAN_CONFIG_TEMPLATE);
            // this.$store.dispatch(GET_DATA_SCAN_CONFIG_TEMPLATE).finally(() => {
            //   this.$store.commit(SET_PAGE_LOADING, false);
            // });
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
  },
  computed: {
    tableProperties: function () {
      return {
        title: "Scanning Configuration Templates",
        description: "Manage Scanning Configuration Templates",
      };
    },
  },
};
