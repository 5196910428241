<template>
  <div
    class="p-4 font-size-h6 bg-white rounded m-8"
    style="
      text-align: justify;
      text-align: justify;
      overflow: scroll;
      height: 95vh;
    "
  >
    <div class="mt-20">
      <div class="mb-10 d-lg-block d-none">
        <router-link to="/">
          <div class="logo">
            <img
              class="img-fluid rounded-lg mt-7 ml-10 iq-purple-logo"
              src="/img/iqplatform.png"
              alt="logo"
              width="50px"
            />
          </div>
        </router-link>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 ml-6">
          <!-- back button -->
          <div class="mb-6">
            <div
              style="cursor: pointer"
              @click.prevent="goBack"
              class="mb-6 mb-10"
            >
              <span class="svg-icon">
                <v-icon size="30">mdi-chevron-left</v-icon>
              </span>
              <span style="color: black" class="font-size-h4">Back</span>
            </div>
          </div>
          <!-- heading -->
          <h1 class="mb-7 heading">Terms & Conditions</h1>
          <!--  -->
          <p>
            These Terms and Conditions (hereinafter referred to as the “Terms”)
            govern the access and the use of the application or the website
            accessible from https://app.iqhybrid.com (hereinafter referred
            jointly to as the “Platform”) of IQ HYBRID DMCC, a company duly
            established under the laws of Dubai Multi Commodities Center, under
            the Trade License number DMCC-854544 and having its registered
            address at Unit No: 4688 DMCC Business Centre Level No 1 Jewellery &
            Gemplex 3 Dubai United Arab Emirates (hereinafter referred to as the
            “Company” or “We” or “Us” or “Our”) by individual or corporate users
            (hereinafter referred to as “Users” or “You”)
          </p>
          <p>
            Your access to and use of the Application is conditioned on Your
            acceptance of and compliance with these Terms and Conditions. These
            Terms and Conditions apply to all visitors, users and others who
            access or use the Application.
          </p>
          <p>
            By accessing or using the Application, You agree to be bound by
            these Terms and Conditions. If You disagree with any part of these
            Terms and Conditions then You shall cease to use the Application.
          </p>

          <br /><br />
          <h2 class="mb-8">DEFINITIONS</h2>
          <p>
            For the purposes of these Terms, capitalized terms shall have the
            meaning specified below:
          </p>
          <ol class="ml-8 ordered-list">
            <li>
              <span class="font-weight-bolder">Applicable Law:</span>
              means all applicable federal, emirate and free-zone laws,
              enactments, decrees, orders (having the force of law),
              regulations, regulatory policies and guidelines, industry codes,
              regulatory permits and licenses which are in force in the UAE, and
              any applicable requirement of any person who has regulatory
              authority that has the force of law.
            </li>
            <li>
              <span class="font-weight-bolder">Application </span>
              means the software program provided by the Company downloaded by
              You on any electronic device, named IQ Platform
            </li>
            <li>
              <span class="font-weight-bolder">Application Store</span>
              means the digital distribution service operated and developed by
              Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in
              which the Application has been downloaded.
            </li>
            <li>
              <span class="font-weight-bolder">Affiliate</span>
              means an entity that controls, is controlled by or is under common
              control with the Company
            </li>
            <li>
              <span class="font-weight-bolder">Account </span>
              Account means a unique account created for You to access the
              services provided on the Application or the Website .
            </li>
            <li>
              <span class="font-weight-bolder">Anti-Corruption Laws </span>
              means all Applicable Laws, codes and sanctions relating to the
              prevention, prohibition or outlawing of bribery, money laundering,
              or similar and illegal activities, in the UAE or any other
              jurisdiction relevant to the performance of the Services;
            </li>
            <li>
              <span class="font-weight-bolder">Content </span>
              refers to content such as text, images, or other information that
              can be posted, uploaded, linked to or otherwise made available by
              You, regardless of the form of that content.
            </li>
            <li>
              <span class="font-weight-bolder">Device </span>
              means any device that can access the Platform such as a computer,
              a cellphone or a digital tablet.
            </li>
            <li>
              <span class="font-weight-bolder">Free Trial </span>
              refers to a limited period of time that may be free when
              purchasing a Subscription.
            </li>
            <li>
              <span class="font-weight-bolder">In-app Purchase </span>
              refers to the purchase of a product, item, service or Subscription
              made through the Application and subject to these Terms and
              Conditions and the Application Store's own terms and conditions.
            </li>
            <li>
              <span class="font-weight-bolder">Promotions </span>
              refer to contests, sweepstakes or other promotions offered through
              the Platform.
            </li>
            <li>
              <span class="font-weight-bolder">Platform </span>
              shall have the meaning specified in the recital Sanctions means
              economic or financial sanctions or trade embargoes imposed,
              administered or enforced from time to time by (a) the U.S.
              government, including those administered by OFAC or the U.S.
              Department of State; or (b) the United Nations Security Council,
              the European Union or Her Majesty’s Treasury of the United
              Kingdom.
            </li>
            <li>
              <span class="font-weight-bolder">Subscriptions </span>
              refer to the subscription to use the services available on the
              Platform.
            </li>
            <li>
              <span class="font-weight-bolder">Website </span>
              refers to IQ Platform, accessible from
              <a href="https://app.iqhybrid.com" target="_blank"
                >https://app.iqhybrid.com</a
              >
            </li>
          </ol>

          <h2 class="mb-8 mt-20">ACCEPTANCE OF THE TERMS</h2>
          <p>By accepting the Terms, you agree:</p>
          <ol class="ml-8 ordered-list">
            <li>That you are 18 years or older;</li>
            <li>
              To abide by these Terms and the processes, procedures, and
              guidelines described throughout the Application;
            </li>
            <li>
              To abide to the following terms and conditions of the Application
              Store and third party service provider.
            </li>
            <li>
              That your access to and use of the Application is also conditioned
              on Your acceptance of and compliance with Our
              <router-link to="/privacy_policy">Privacy Policy</router-link>
              . Our Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your personal information when
              You use the Application and tells You about Your privacy rights
              and how the law protects You. Please read Our Privacy Policy
              carefully before using Our Application.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">WE MAY MAKE CHANGES TO THE PLATFORM</h2>
          <p>
            We may update and change our Platform from time to time to reflect
            changes to our services and our business priorities without any
            notice. The use of the Platform after such changes or amendments
            will constitute your consent to the amendments, so if you do not
            want to be bound by an amended version, do not use the Platform and
            cease all use of the content, any services or assistance.
          </p>

          <h2 class="mb-8 mt-20">USER’S CONDUCT</h2>
          <p>
            As a condition of your access and use of the Platform, you agree not
            to use the Platform for any purpose that is unlawful or prohibited
            by these Terms, or any other purpose not reasonably intended by the
            Company. By way of example, and not as a limitation, you agree not
            to:
          </p>
          <ol class="ml-8 ordered-list">
            <li>
              Violate these Terms, other applicable agreement with the Company,
              and any Applicable Law, any rules and regulations having the force
              of law;
            </li>
            <li>
              Use the Platform in any manner that violates any relevant law or
              that infringes, misappropriates or violates any third party’s
              rights, including, but not limited to, transmitting any content on
              the Platform that may infringe, misappropriate or violate the
              Company or a third party’s rights of publicity, contractual
              rights, fiduciary rights or intellectual property rights;
            </li>
            <li>
              Use the Platform or its content for any purposes not authorized by
              these Terms, including commercial, political, or religious
              purposes, including the submission or transmission of any content
              that contains advertisements, promotional materials, junk mail, or
              any other form of solicitation;
            </li>
            <li>
              Claim a relationship with or represent any business, association,
              or other organization with which you are not authorized to claim
              such a relationship or represent;
            </li>
            <li>
              Reproduce, duplicate, copy, modify, sell, re-sell or exploit any
              content or the Platform for any commercial, educational, or any
              other non-personal purpose, without the express written consent of
              the Company, which consent may be withheld by the Company in our
              sole discretion;
            </li>
            <li>
              Post irrelevant content, repeatedly post the same or similar
              content or otherwise impose an unreasonable or disproportionately
              large load on our infrastructure, interfere or attempt to
              interfere with the proper working of the Platform or any
              activities conducted on the Platform;
            </li>
            <li>
              Knowingly provide or submit false or misleading information;
            </li>
            <li>Use the Platform if you are under the age of eighteen (18);</li>
            <li>
              Attempt to gain unauthorized access to the Platform or other
              computer systems or networks connected to the Platform
            </li>
            <li>
              Use the Platform in any way that could interfere with the rights
              of the Company or the rights of other users of the Platform;
            </li>
            <li>
              Attempt to gain unauthorized access to any portion or feature of
              the Platform, or any other systems or networks connected to the
              Platform or to any server used by the Company by hacking, password
              ‘mining’ or any other illegitimate or unauthorized means,
              including attempting to obtain password, account, or any other
              personal or private information from any other Platform user;
            </li>
            <li>
              Transmit or submit any transmission or other materials that are
              encrypted or that contains viruses, Trojan horses, worms, time
              bombs, spiders, cancelbots or other computer programming routines
              that is likely or intended to damage, interfere with, disrupt,
              impair, disable or otherwise overburden the Platform;
            </li>
            <li>
              Access, download, monitor, or copy any information contained on
              our Platform through artificial means (including but not limited
              to use any ‘deep-link’, ‘scraper’, ‘robot’, ‘spider’ or other
              automatic device, program, algorithm or methodology, or any
              similar or equivalent automatic or manual process, or in any way
              reproduce or circumvent the navigational structure or presentation
              of the Platform or any content, to obtain or attempt to obtain any
              Content, materials, document or information through any means not
              purposely made available through the Platform.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">ACCESS TO THE PLATFORM</h2>
          <ol class="ml-8 ordered-list">
            <li>
              Your right to access and use the Platform is personal to you and
              is not transferable by you to any other person or entity. You are
              only entitled to access and use the Platform for lawful purposes
              and pursuant to the Terms. In the event a third party access the
              Platform through your internet connection, you shall also be
              responsible for ensuring that such third party are aware of these
              Terms and other applicable terms and conditions, and that they
              comply with them.
            </li>
            <li>
              We do not guarantee that our Platform, or any content on it, will
              always be available or be uninterrupted. We may suspend or
              withdraw or restrict the availability of all or any part of our
              Platform for business and operational reasons. We will try to give
              you reasonable notice of any suspension or withdrawal.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">USER ACCOUNTS</h2>
          <ol class="ml-8 ordered-list">
            <li>
              When using the Platform you may be requested to create an Account.
              When creating your Account, You must provide Us information that
              is accurate, complete, and current at all times. Failure to do so
              constitutes a breach of the Terms, which may result in immediate
              termination of Your account on the Platform.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account and password and for restricting access to your account
              from any devices, and you agree to accept responsibility for all
              activities that occur under your account or password. We shall not
              be responsible or liable, directly or indirectly, in any way for
              any loss or damage of any kind incurred as a result of, or in
              connection with, your failure to comply with this section.
            </li>
            <li>
              We have the right to disable your code or password, whether chosen
              by you or allocated by us, at any time, if in our reasonable
              opinion you have failed to comply with any of the provisions of
              these Terms.
            </li>
            <li>
              The Company may, upon the creation of your Account and thereafter
              from time to time request you to provide documents, evidence and
              other information required. You authorize us to retain certain
              information about you. We may terminate or suspend Your Account
              immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if You breach these
              Terms.
            </li>
            <li>
              You must notify Us immediately upon becoming aware of any breach
              of security or unauthorized use of Your Account
            </li>
            <li>
              You may not use as a username the name of another person or entity
              or that is not lawfully available for use, a name or trademark
              that is subject to any rights of another person or entity other
              than You without appropriate authorization, or a name that is
              otherwise offensive, vulgar or obscene.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">DO NOT RELY ON INFORMATION ON THIS SITE</h2>
          <ol class="ml-8 ordered-list">
            <li>
              The content on the Platform is provided for general information
              only. It is not intended to amount to advice on which you should
              rely. You must obtain our professional advice before taking, or
              refraining from, any action on the basis of the content on the
              Platform.
            </li>
            <li>
              Although we make reasonable efforts to update the information on
              our Platform, we make no representations, warranties or
              guarantees, whether express or implied, that the content on the
              Platform is accurate, complete or up to date.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">SUBSCRIPTIONS</h2>
          <ol class="ml-8 ordered-list">
            <li>
              If you subscribe to our services provided on the Platform, our
              terms and conditions of supplying such services
              <a href="https://app.iqhybrid.com" target="_blank"
                >app.iqhybrid.com</a
              >
              will apply to the subscription.
            </li>
            <li>
              <span class="font-weight-bold">Subscription period</span>
              <ol class="ml-8 mt-4 ordered-list" type="i">
                <li>
                  The service available on the Platform or some parts of the
                  service are available only with a paid Subscription. You will
                  be billed in advance on a recurring and periodic basis (such
                  as daily, weekly, monthly or annually), depending on the type
                  of Subscription plan you select when subscribing
                </li>
                <li>
                  At the end of each period, Your Subscription will
                  automatically renew under the exact same conditions unless You
                  cancel it or the Company cancels it.
                </li>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold">Subscription cancellations </span>
              <ol class="ml-8 mt-4 ordered-list" type="i">
                <li>
                  You may cancel Your Subscription renewal either through Your
                  Account settings page or by contacting the Company. You will
                  not receive a refund for the fees You already paid for Your
                  current Subscription period and You will be able to access the
                  services subscribed to on the Platform until the end of Your
                  current Subscription period.
                </li>
                <li>
                  If the Subscription has been made through an In-app Purchase,
                  You can cancel the renewal of Your Subscription with the
                  Application Store.
                </li>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold">Billing</span>
              <ol class="ml-8 mt-4 ordered-list" type="i">
                <li>
                  You shall provide the Company with accurate and complete
                  billing information including full name, address, state, zip
                  code, telephone number, and a valid payment method
                  information.
                </li>
                <li>
                  Should automatic billing fail to occur for any reason, the
                  Company will issue an electronic invoice indicating that you
                  must proceed manually, within a certain deadline date, with
                  the full payment corresponding to the billing period as
                  indicated on the invoice.
                </li>
                <li>
                  If the Subscription has been made through an In-app Purchase,
                  all billing is handled by the Application Store and is
                  governed by the Application Store's own terms and conditions.
                </li>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold">Refunds</span>
              <ol class="ml-8 mt-4 ordered-list" type="i">
                <li>
                  Except when required by law, paid Subscription fees are
                  non-refundable.
                </li>
                <li>
                  Certain refund requests for Subscriptions may be considered by
                  the Company on a case-by-case basis and granted at the sole
                  discretion of the Company.
                </li>
              </ol>
            </li>
            <li>
              <span class="font-weight-bold">Free Trial</span>
              <ol class="ml-8 mt-4 ordered-list" type="i">
                <li>
                  The Company may, at its sole discretion, offer a Subscription
                  with a Free Trial for a limited period of time.
                </li>
                <li>
                  You may be required to enter Your billing information in order
                  to sign up for the Free Trial.
                </li>
                <li>
                  If You do enter Your billing information when signing up for a
                  Free Trial, You will not be charged by the Company until the
                  Free Trial has expired. On the last day of the Free Trial
                  period, unless You cancelled Your Subscription, You will be
                  automatically charged the applicable Fees for the type of
                  Subscription You have selected.
                </li>
                <li>
                  At any time and without notice, the Company reserves the right
                  to
                  <ol class="ml-8 mt-4 ordered-list" type="a">
                    <li>
                      modify the terms and conditions of the Free Trial offer,
                      or
                    </li>
                    <li>cancel such Free Trial offer.</li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>

          <h2 class="mb-8 mt-20">IN-APP PURCHASES</h2>
          <p>
            The Application may include In-app Purchases that allow you to buy
            services or Subscriptions.
          </p>
          <ol class="ml-8 ordered-list">
            <li>
              More information about how you may be able to manage In-app
              Purchases using your Device may be set out in the Application
              Store's own terms and conditions or in your Device's help
              settings.
            </li>
            <li>
              In-app Purchases can only be consumed within the Application. If
              you make a In-app Purchase, that In-app Purchase cannot be
              cancelled after you have initiated its download. In-app Purchases
              cannot be redeemed for cash or other consideration or otherwise
              transferred.
            </li>
            <li>
              If any In-app Purchase is not successfully downloaded or does not
              work once it has been successfully downloaded, we will, after
              becoming aware of the fault or being notified to the fault by You,
              investigate the reason for the fault. We will act reasonably in
              deciding whether to provide You with a replacement In-app Purchase
              or issue You with a patch to repair the fault. In no event We will
              charge You to replace or repair the In-app Purchase. In the
              unlikely event that we are unable to replace or repair the
              relevant In-app Purchase or are unable to do so within a
              reasonable period of time and without significant inconvenience to
              You, We will authorize the Application Store to refund You an
              amount up to the cost of the relevant In-app Purchase.
              Alternatively, if You wish to request a refund, You may do so by
              contacting the Application Store directly.
            </li>
            <li>
              You acknowledge and agree that all billing and transaction
              processes are handled by the Application Store from where you
              downloaded the Application and are governed by that Application
              Store's own terms and condition
            </li>
            <li>
              If you have any payment related issues with In-app Purchases, then
              you need to contact the Application Store directly.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">INTELLECTUAL PROPERTY</h2>
          <ol class="ml-8 ordered-list">
            <li>
              We are the owner or the licensee of all intellectual property
              rights in our Platform, and in the material published on it.
              Except as expressly stated herein, these Terms does not grant you
              any rights to, or in, patents, copyright, database right, trade
              secrets, trade names, trademarks (whether registered or
              unregistered), or any other rights or licenses in respect of the
              Platform
            </li>
            <li>
              You may print off one copy, and may download extracts, of any
              page(s) from our Platform for your personal use. You must not use
              any part of the content on our Platform for commercial purposes
              without obtaining a license to do so from us or our licensors.
            </li>
            <li>
              You must not modify the paper or digital copies of any materials
              you have printed off or downloaded in any way, and you must not
              use any illustrations, photographs, video or audio sequences or
              any graphics separately from any accompanying tex
            </li>
            <li>
              The Company confirms that it has all the rights in relation to the
              Platform that are necessary to grant all the rights it purports to
              grant under, and in accordance with these Terms.
            </li>
            <li>
              If you print off, copy, download, share or repost any part of our
              Platform in breach of these Terms, your right to use our Platform
              will cease immediately and you must, at our option, return or
              destroy any copies of the materials you have made.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">LINKS TO OTHER WEBSITES</h2>
          <ol>
            <li>
              Our Platform may contain links to third-party websites or services
              that are not owned or controlled by the Company.
            </li>
            <li>
              The Company has no control over, and assumes no responsibility
              for, the content, privacy policies, or practices of any third
              party web sites or services. You further acknowledge and agree
              that the Company shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused
              by or in connection with the use of or reliance on any such
              content, goods or services available on or through any such web
              sites or services.
            </li>
            <li>
              We strongly advise You to read the terms and conditions and
              privacy policies of any third-party web sites or services that You
              visit.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">INDEMNIFICATION</h2>
          <p>
            You agree to indemnify, defend and hold harmless the Company, from
            and against any and all losses, damage, liability and costs of every
            nature incurred by any of them in connection with any claim, damage
            or loss related to or arising out of Your Content, the use and
            access of the Platform, any alleged unauthorized use of the
            Platform, or any breach or alleged breach by you of these Terms. You
            agree to cooperate fully in the defense of any of the foregoing. We
            reserve the right, to control exclusively the defense of any matter
            otherwise subject to indemnification by you and you will not settle
            any matter without our consent in a non-electronic record.
          </p>

          <h2 class="mb-8 mt-20">"AS IS" AND "AS AVAILABLE" DISCLAIMER</h2>
          <ol class="ml-8 ordered-list">
            <li>
              The Platform, the content and services available on the Platform
              are provided to You "AS IS" and "AS AVAILABLE" and with all faults
              and defects without warranty of any kind. To the maximum extent
              permitted under Applicable Law, the Company, on its own behalf and
              on behalf of its Affiliates and its and their respective licensors
              and service providers, expressly disclaims all warranties, whether
              express, implied, statutory or otherwise, with respect to the
              Platform, including all implied warranties of merchantability,
              fitness for a particular purpose, title and non-infringement, and
              warranties that may arise out of course of dealing, course of
              performance, usage or trade practice. Without limitation to the
              foregoing, the Company provides no warranty or undertaking, and
              makes no representation of any kind that the PLATFORM, the content
              and services available on the Platform will meet Your
              requirements, achieve any intended results, be compatible or work
              with any other software, applications, systems or services,
              operate without interruption, meet any performance or reliability
              standards or be error free or that any errors or defects can or
              will be corrected. You agree that you will obtain (including
              through download) any content entirely at your own risk, and you
              will be solely responsible for any resulting infringement, breach
              of contract, consequence or damage, including (without limitation)
              to your computer system or loss of data.
            </li>
            <li>
              Without limiting the foregoing, neither the Company nor any of the
              Company's provider makes any representation or warranty of any
              kind, express or implied: (i) as to the operation or availability
              of the Platform, or the information, content, and materials or
              products included thereon; (ii) that the Platform will be
              uninterrupted or error-free; (iii) as to the accuracy,
              reliability, or currency of any information or content provided
              through the Platform; or (iv) that the Platform, its servers, the
              content, or e-mails sent from or on behalf of the Company are free
              of viruses, scripts, trojan horses, worms, malware, timebombs or
              other harmful components.
            </li>
            <li>
              Some jurisdictions do not allow the exclusion of certain types of
              warranties or limitations on applicable statutory rights of a
              consumer, so some or all of the above exclusions and limitations
              may not apply to You. But in such a case the exclusions and
              limitations set forth in this section shall be applied to the
              greatest extent enforceable under Applicable Law.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">EXCLUSIVE REMEDY; DAMAGE LIMITATION</h2>
          <p class="font-weight-bold">
            YOU AGREE THAT YOUR EXCLUSIVE REMEDY FOR ANY DAMAGES DUE TO YOU (OR
            OTHERS RELATED TO YOU) BY THE COMPANY FOR ANY REASON RELATING TO THE
            PLATFORM, WILL BE ONE-HUNDRED USD (US $100). THE DAMAGE EXCLUSIONS
            AND LIMITATIONS IN THESE TERMS ARE INDEPENDENT AND WILL APPLY EVEN
            IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </p>
          <br />
          <p>
            To the maximum extent permitted by Applicable Law, in no event shall
            the Company or its suppliers be liable for any special, incidental,
            indirect, or consequential damages whatsoever (including, but not
            limited to, damages for loss of profits, loss of data or other
            information, for business interruption, for personal injury, loss of
            privacy arising out of or in any way related to the use of or
            inability to use the Service, third-party software and/or
            third-party hardware used with the Service, or otherwise in
            connection with any provision of this Terms), even if the Company or
            any supplier has been advised of the possibility of such damages and
            even if the remedy fails of its essential purpose.
          </p>

          <h2 class="mb-8 mt-20">FORCE MAJEURE</h2>
          <p>
            Neither Party shall have liability to the other Party under this
            Agreement if it is prevented from or delayed in performing its
            obligations under this Agreement, or from carrying on its business,
            by acts, events, omissions or accidents beyond its reasonable
            control, including, without limitation, strikes, lock-outs or other
            industrial disputes (whether involving the workforce of the Company
            or any other party), failure of a utility service or transport or
            telecommunications network, act of God, war, riot, pandemics, civil
            commotion, malicious damage, compliance with any law or governmental
            order, rule, regulation or direction, accident, breakdown of plant
            or machinery, fire, flood, storm or default of suppliers or
            sub-contractors, provided that the other Party is notified of such
            an event and its expected duration.
          </p>

          <h2 class="mb-8 mt-20">COMPLIANCE WITH LAWS</h2>
          <ol class="ml-8 ordered-list">
            <li>
              Each party shall in performing their obligations under the
              Agreement comply with all Applicable Laws.
            </li>
            <li>
              Anti-Bribery and Corruption. Without prejudice to the generality
              of Section 18.1:
              <ol class="ml-8 ordered-list" type="a">
                <li>
                  You warrant that you have not done, and in performing its
                  obligations under the Agreement, you shall not do, any act or
                  thing that contravenes Anti-Corruption laws;
                </li>
                <li>
                  You warrant and undertake that you shall comply with, and
                  shall procure that its personnel involved in performing the
                  Agreement shall comply with this Section 16; and
                </li>
                <li>
                  You shall immediately notify the Company in writing on
                  becoming aware of, or suspecting, any failure to comply with
                  any provision of this Section 18.
                </li>
              </ol>
            </li>
            <li>
              You have implemented and maintain in effect policies and
              procedures designed to ensure compliance by You and your
              Affiliates, and your and their respective directors, officers,
              employees and agents with applicable Anti-Corruption laws and
              Sanctions
            </li>
            <li>
              No utilization, use of proceeds or other transactions contemplated
              by this Agreement will violate Anti-Corruption laws or Sanctions.
            </li>
          </ol>

          <h2 class="mb-8 mt-20">GOVERNING LAW</h2>
          <p>
            These Terms are governed by the laws of the Emirate of Dubai and the
            Federal Laws of the United Arab Emirates. Any dispute arising out of
            the formation, performance, interpretation, nullification,
            termination or invalidation of these Terms or arising therefrom or
            related thereto in any manner whatsoever, shall be settled by
            arbitration in accordance with the provisions set forth under the
            DIAC Arbitration Rules (“the Rules”), by one arbitrator appointed in
            compliance with the Rules. The seat of arbitration shall be Dubai,
            UAE. The language to be used in the arbitration shall be English.”
          </p>

          <h2 class="mb-8 mt-20">SEVERABILITY</h2>
          <p>
            If any provision of these Terms is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under Applicable Law and the remaining provisions will
            continue in full force and effect.
          </p>

          <h2 class="mb-8 mt-20">WAIVER</h2>
          <p>
            Except as provided herein, the failure to exercise a right or to
            require performance of an obligation under these Terms shall not
            effect a party's ability to exercise such right or require such
            performance at any time thereafter nor shall the waiver of a breach
            constitute a waiver of any subsequent breach.
          </p>

          <h2 class="mb-8 mt-20">TRANSLATION INTERPRETATION</h2>
          <p>
            These Terms may have been translated if We have made them available
            to You on our Platform. You agree that the original English text
            shall prevail in the case of a dispute.
          </p>

          <h2 class="mb-8 mt-20">ACCEPTANCE</h2>
          <p>
            You acknowledge that you have read these Terms and agree to all its
            provisions. By accessing and using the Platform you agree to be
            bound by these Terms and Conditions, as well as all policies
            published on the Platform or communicated in any way from time to
            time by the Company. If you do not agree to abide by these
            provisions, you are not authorized to access or use the Platform and
            the services.
          </p>

          <h2 class="mb-8 mt-20">REPORTING AND CORRECTING VIOLATIONS</h2>
          <p>
            If you become aware of any violation of these Terms, you must
            immediately report it to
            <a href="mailto:help@iqhybrid.com">help@iqhybrid.com</a>. You agree
            to assist us with any investigation we undertake and to take any
            remedial steps we require in order to correct a violation of these
            Term
          </p>

          <h2 class="mb-8 mt-20">CONTACT US</h2>
          <p>
            If you have any questions about these Terms, You can contact us:
          </p>
          <ol class="ml-8 ordered-list">
            <li>
              By email: <a href="mailto:help@iqhybrid.com">help@iqhybrid.com</a>
            </li>
            <li>
              By visiting this page on our website:
              <a
                href="https://www.iqholding.com/index.php/contact-us/"
                target="_blank"
                >https://www.iqholding.com/index.php/contact-us/</a
              >
            </li>
          </ol>

          <h2 class="mb-8 mt-20">FOR EUROPEAN UNION (EU) USERS</h2>
          <p class="mb-20">
            If You are in Europe, you will benefit from any mandatory provisions
            of the law of the country in which you are resident in.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  methods: {
    goBack(event) {
      event.preventDefault();

      const referrer = document.referrer;
      if (referrer.includes(window.location.origin)) {
        this.$router.go(-1);
      } else {
        window.location.href = "/";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.heading {
  font-size: 35px;
}
.ordered-list {
  li {
    margin-bottom: 10px;
  }
}
.logo {
  img {
    position: absolute;
    top: 37px;
    left: 82px;
  }
}
</style>
