<template>
  <div class="p-4 bg-white rounded-lg">
    <DataTable />
  </div>
</template>

<script>
import DataTable from "@/own/components/fulfillment/fileUpload/DataTable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "FileUpload",
  components: { DataTable },
  data: () => ({
    uploadType: null,
  }),

  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
