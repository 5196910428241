<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="h-75px">
          <v-text-field
            v-model="formData.system"
            label="System"
            clearable
            outlined
            dense
          ></v-text-field>
        </div>

        <div class="h-75px">
          <v-text-field
            v-model="formData.url"
            label="Url"
            clearable
            outlined
            dense
          ></v-text-field>
        </div>

        <v-textarea
          v-model="formData.headers"
          class="mb-8"
          label="Headers"
          clearable
          auto-grow
          outlined
          rows="1"
          dense
          hide-details
        ></v-textarea>

        <v-textarea
          v-model="formData.parameters"
          class="mb-8"
          auto-grow
          label="Parameters"
          clearable
          outlined
          rows="1"
          dense
          hide-details
        ></v-textarea>

        <v-textarea
          v-model="formData.body"
          class="mb-8"
          auto-grow
          label="Body"
          clearable
          outlined
          rows="1"
          hide-details
          dense
        ></v-textarea>

        <v-textarea
          v-model="formData.others"
          class="mb-8"
          auto-grow
          label="Others"
          clearable
          outlined
          rows="1"
          dense
          hide-details
        ></v-textarea>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      system: null,
      url: null,
      headers: null,
      parameters: null,
      body: null,
      others: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.pageLoader(true);
      ApiService.post("/organization/systemCredentials/store", this.formData)
        .then(() => {
          swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.formData = {
        system: null,
        url: null,
        headers: null,
        parameters: null,
        body: null,
        others: null,
      };
    },
  },
};
</script>
