<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add Service</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model.trim="$v.formData.name.$model"
            :error-messages="validateState('name') ? requiredMessage : ''"
            :counter="40"
            label="Name"
            required
            dense
            outlined
            clearable
          ></v-text-field>
          <v-autocomplete
            v-model.trim="$v.formData.target.$model"
            :error-messages="validateState('target') ? requiredMessage : ''"
            :items="targets"
            outlined
            item-name="text"
            item-value="index"
            dense
            clearable
            label="Select Target"
          ></v-autocomplete>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { messages } from "@/core/constants/message";
// import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      target: { required },
    },
  },
  computed: {
    requiredMessage: function () {
      return messages.requiredMessage;
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      name: null,
      target: null,
    },
    targets: [
      { index: "internal", text: "Internal" },
      { index: "external", text: "External" },
    ],
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submitCreateForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/settings/services/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: "The Service setting has been created",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetCreateForm();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.formData = {
        name: null,
        target: null,
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
