<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="800px"
    style="background-color: white"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit SKUs</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <div class="row">
          <div class="col-12">
            <template v-if="isSkusPresent">
              <h5>Selected SKUs</h5>
              <hr class="pb-10" />
              <div class="row align-items-center mt-n12">
                <div class="col-4 mb-n11 font-weight-black">
                  <p>SKU ID</p>
                </div>
                <div class="col-4 mb-n11 font-weight-black">
                  <p>SKU Code</p>
                </div>
                <div class="col-4 mb-n11 font-weight-black">
                  <p>Quantity</p>
                </div>
                <template v-for="(item, index) in skus">
                  <div :key="index" class="col-4">
                    <v-container class="px-0" fluid>
                      <v-checkbox
                        color="indigo"
                        :label="item.systemCode"
                        v-model="item.valid"
                      ></v-checkbox>
                    </v-container>
                  </div>
                  <div :key="index" class="col-4">
                    <p>{{ item?.sku_code }}</p>
                  </div>
                  <div :key="index" class="col-4` mt-7">
                    <v-text-field
                      label="Quantity"
                      v-model="item.quantity"
                      dense
                      type="number"
                      :min="0"
                      outlined
                    ></v-text-field>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="h2 my-5 text-center">No SKU Found</div></template
            >
          </div>
        </div>

        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div class="justify-center align-center d-flex col-12">
            <button
              @click="toggleModal"
              type="submit"
              class="btn btn-info px-5 py-3 mx-2 order-1 mt-8 mb-3 mt-sm-0"
            >
              {{ isSkusPresent ? "Update" : "Close" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_INTERNAL_ORDER_SKUS } from "@/core/services/store/pendingAuditReturns.module";
export default {
  name: "EditSkus",
  props: ["items"],
  data: () => ({
    dialog: false,
    skus: [],
    sku_search_text: null,
    suggestedItems: [],
    isLoading: false,
    selectedOrder: null,
  }),
  computed: {
    isSkusPresent: function() {
      return !!this.skus.length;
    },
  },
  methods: {
    toggleModal(order) {
      if (!this.dialog) {
        this.selectedOrder = order;
        this.skus = [];
        this.items
          .find((item) => item.order_id === this.selectedOrder.order_id)
          .skus.forEach((item) => {
            this.skus.push({ ...item, valid: true });
          });

        this.$store.commit(SET_INTERNAL_ORDER_SKUS, this.skus);
      } else if (this.dialog) {
        // check if atleast one valid sku present
        const isValid = this.skus.some((item) => item.valid);
        if (this.isSkusPresent && !isValid) {
          Swal.fire({
            title: "Warning",
            text: "Please, select a sku!",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        //check if any order has invalid qty
        const isAnyOrderMissingQty = this.skus.some(
          (item) => item.quantity === ""
        );
        if (this.isSkusPresent && isAnyOrderMissingQty) {
          Swal.fire({
            title: "Warning",
            text: "Enter valid SKU quantity",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        //check atleast one sku which has qty > 0
        const hasValidQty = this.skus.every(
          (item) => Number(item.quantity) > 0
        );
        if (this.isSkusPresent && !hasValidQty) {
          Swal.fire({
            title: "Warning",
            text: "Order should contain atleast 1 SKU",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        let hasValidQty2 = true;
        const realOrderSkus = this.items.find(
          (item) => item.order_id === this.selectedOrder.order_id
        ).skus;
        this.skus.forEach((item, index) => {
          if (item.quantity > realOrderSkus[index].quantity) {
            hasValidQty2 = false;
          }
        });
        if (!hasValidQty2) {
          Swal.fire({
            title: "Warning",
            text: "Quantity number exceed than original order",
            icon: "warning",
            showConfirmButton: true,
            timer: 2500,
          });
          return;
        }
        this.$store.commit(SET_INTERNAL_ORDER_SKUS, this.skus);
        this.$emit("updateOrderDetails", this.skus);
      }
      this.dialog = !this.dialog;
    },
    // onChangeSkus() {
    //   this.$store.commit(SET_INTERNAL_ORDER_SKUS, this.skus);
    // },
  },
};
</script>
