import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getRETURNSTableStructure";
export const UPDATE_TABLE_DATA = "getRETURNSTableData";
export const EXPORT_TABLE_DATA = "exportRETURNSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setRETURNSTableStructure";
export const SET_TABLE_DATA = "setRETURNSTableData";
export const SET_TABLE_FILTER = "setRETURNSTableFilter";
// export const SET_FILTER_CHANGE = "setRETURNSFilterChange";
export const SET_TABLE_PAGE = "setRETURNSTablePage";
export const SET_TABLE_PERPAGE = "setRETURNSTablePerPage";
export const SET_TABLE_SORTBY = "setRETURNSTableSortBy";
export const SET_TABLE_SORTORDER = "setRETURNSTableSortOrder";
export const SET_EXPORT_LINK = "setRETURNSExportLink";
export const SET_ITEM_FOR_UPDATE = "setRETURNSItemForUpdate";

const state = {
  queryObject: {},
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  item_for_update: null,
};

const getters = {
  /**
   * for table title, description
   * */
  getRETURNSTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getRETURNSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getRETURNSPrintTypes(state) {
    return state.table_structure.print_labels;
  },
  getRETURNSTableState(state) {
    return state.table_state;
  },
  getRETURNSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getRETURNSTableFilters(state) {
    return state.table_structure.filters;
  },

  getRETURNSTableData(state) {
    return state.table_data.data;
  },

  getRETURNSExportUrl(state) {
    return state.export_link;
  },
  getRETURNSUploadType(state) {
    return state.table_structure.type;
  },
  getRETURNSExtraData(state) {
    return state.table_structure.reason_data;
  },
  getRETURNSItemForUpdate(state) {
    return state.item_for_update;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/shipping/returns_management")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/shipping/returns_management/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/shipping/returns_management/export", payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    // console.log("order state of filter update", filter);
    state.table_state.filters = filter;
  },

  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_ITEM_FOR_UPDATE](state, item) {
    state.item_for_update = item;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
