<template>
  <div
    class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
    :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
  >
    <button @click="createCycleCount" class="btn btn--export-filter ml-2">
      Create
    </button>
    <button @click="closeCycleCount" class="btn btn--export-filter ml-2">
      Close
    </button>
    <CycleCountModal
      ref="cycleCountModal"
      :page-loading="pageLoading"
      :refresher="refresher"
    />
    <CloseCycleCount
      ref="closeCycleCount"
      :page-loading="pageLoading"
      :refresher="refresher"
    />
  </div>
</template>

<script>
import { CREATE_CYCLE_COUNT } from "@/core/services/store/standardCycleCount.module";
import CycleCountModal from "@/own/components/warehouseManagement/standardCycleCount/CycleCountModal.vue";
import CloseCycleCount from "@/own/components/warehouseManagement/standardCycleCount/CloseCycleCount.vue";

export default {
  name: "CycleCountActions",
  props: ["pageLoading", "refresher"],
  components: { CycleCountModal, CloseCycleCount },
  methods: {
    createCycleCount() {
      this.pageLoading(true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
      };
      this.$store.dispatch(CREATE_CYCLE_COUNT, payload).then(() => {
        this.$refs.cycleCountModal.toggleModal();
        this.pageLoading(false);
      });
    },
    closeCycleCount() {
      this.$refs.closeCycleCount.toggleModal();
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>
