<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Create Slider</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-650px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <v-stepper v-model="e1">
            <v-stepper-header>
              <template v-for="n in steps">
                <v-stepper-step :key="`${n}-step`" :complete="e1 > n" :step="n">
                  <span v-if="n == 1">Settings</span>
                  <span v-if="n == 2">Edit Header</span>
                  <span v-if="n == 3">Edit body</span>
                  <span v-if="n == 4">Edit Iamge</span>
                </v-stepper-step>

                <v-divider v-if="n !== steps" :key="n"></v-divider>
              </template>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content :step="1" :key="`1-content`">
                <div
                  class="justify-content-between stepper-container flex-column d-flex"
                >
                  <div class="stepper-content">
                    <v-autocomplete
                      v-model="store_id"
                      :items="stores"
                      outlined
                      item-name="text"
                      item-value="index"
                      dense
                      clearable
                      label="Select Store*"
                      @change="changeLanguage"
                    ></v-autocomplete>
                  </div>
                  <div class="stepper-footer">
                    <div></div>
                    <button
                      type="button"
                      class="btn btn-info px-5 py-3 ls1"
                      @click="nextStep(1)"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content :step="2" :key="`2-content`">
                <div
                  v-if="formData"
                  class="justify-content-between stepper-container flex-column d-flex"
                >
                  <div class="stepper-content">
                    <v-text-field
                      v-for="(item, index) in languages"
                      :key="'header' + index"
                      v-model="formData['header_' + item.index]"
                      :counter="40"
                      :label="'Header(' + item.text + ')*'"
                      required
                      outlined
                      dense
                      clearable
                      :reverse="rtlFlag"
                    ></v-text-field>
                  </div>
                  <div class="stepper-footer">
                    <button
                      type="button"
                      class="btn btn-light mr-3 px-5 py-3 ls1"
                      @click="previewStep(2)"
                    >
                      Previous
                    </button>
                    <button
                      type="button"
                      class="btn btn-info px-5 py-3 ls1"
                      @click="nextStep(2)"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content :step="3" :key="`3-content`">
                <div
                  v-if="formData"
                  class="justify-content-between stepper-container flex-column d-flex"
                >
                  <div class="stepper-content">
                    <v-text-field
                      v-for="(item, index) in languages"
                      :key="'body' + index"
                      v-model="formData['body_' + item.index]"
                      :counter="40"
                      :label="'Body(' + item.text + ')*'"
                      required
                      dense
                      outlined
                      clearable
                      :reverse="rtlFlag"
                    ></v-text-field>
                  </div>
                  <div class="stepper-footer">
                    <button
                      type="button"
                      class="btn btn-light mr-3 px-5 py-3 ls1"
                      @click="previewStep(3)"
                    >
                      Previous
                    </button>

                    <button
                      type="button"
                      class="btn btn-info px-5 py-3 ls1"
                      @click="nextStep(3)"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content :step="4" :key="`4-content`">
                <div
                  v-if="formData"
                  class="justify-content-between stepper-container flex-column d-flex"
                >
                  <div class="stepper-content">
                    <v-text-field
                      v-model="formData.action_url"
                      :counter="40"
                      label="Action  URL*"
                      required
                      dense
                      outlined
                      clearable
                    ></v-text-field>
                    <v-autocomplete
                      v-model="formData.position"
                      :items="positions"
                      outlined
                      item-name="text"
                      item-value="index"
                      dense
                      clearable
                      label="Select Position*"
                    ></v-autocomplete>
                    <v-file-input
                      v-model="formData.image"
                      show-size
                      counter
                      multiple
                      dense
                      outlined
                      accept="image/png, image/jpeg, image/bmp, video/mp4"
                      prepend-icon="mdi-camera"
                      label="Image*"
                      clearable
                    ></v-file-input>
                  </div>
                  <div class="stepper-footer">
                    <button
                      type="button"
                      class="btn btn-light mr-3 px-5 py-3 ls1"
                      @click="previewStep(4)"
                    >
                      Previous
                    </button>

                    <button
                      type="button"
                      class="btn btn-info px-5 py-3 ls1"
                      @click="submitCreateForm"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import Swal from "sweetalert2";
import { UPDATE_CREATE_DATA } from "@/core/services/store/sliders.module";
export default {
  name: "AddSlider",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    rtlFlag: false,
    languages: null,
    store_id: null,
    formData: null,
    e1: 1,
    steps: 4,
  }),
  beforeMount() {
    this.$store.dispatch(UPDATE_CREATE_DATA, {});
  },
  computed: {
    stores: function () {
      if (this.$store.getters.getSLIDERSStoresData) {
        return this.$store.getters.getSLIDERSStoresData;
      }
      return undefined;
    },
    positions: function () {
      if (this.$store.getters.getSLIDERSPositionsData) {
        return this.$store.getters.getSLIDERSPositionsData;
      }
      return undefined;
    },
  },
  mounted() {
    this.clearStore();
  },
  methods: {
    convertToFormData() {
      let data = new FormData();
      data.append("store_id", this.store_id);
      for (var key in this.formData) {
        if (this.formData[key]) {
          if (key == "image") data.append(key, this.formData[key][0]);
          else data.append(key, this.formData[key]);
        } else {
          if (key == "position") {
            this.errorMessage(
              "Should Complete Position Field",
              "error",
              "Error"
            );
            return null;
          } else if (key == "image") {
            this.errorMessage("Should Complete Image Field", "error", "Error");
            return null;
          }
        }
      }
      return data;
    },
    submitCreateForm() {
      let data = this.convertToFormData();
      if (!data) {
        return;
      }
      this.pageLoader(true);
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/stores/portal/sliders/store`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: data,
      };
      axios(config)
        .then(() => {
          this.errorMessage("The Slider Page Created!", "success", "Created");
          this.toggleModal();
          this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.errorMessage("Something went wrong!", "error", "Error");
          this.pageLoader(false);
        });
    },
    clearStore() {
      this.store_id = null;
    },
    resetEditForm() {
      this.formData = {
        image: null,
        position: null,
        action_url: null,
      };
      if (this.languages) {
        this.languages.map((item) => {
          this.formData["header_" + item.index] = "";
          this.formData["body_" + item.index] = "";
        });
      } else this.languages = null;
    },
    errorMessage(message, icon, title) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    toggleModal() {
      this.clearStore();
      this.formData = null;
      this.nextStep(4);
      this.dialog = !this.dialog;
    },
    changeLanguage() {
      if (this.store_id) {
        var selectedItem = this.stores.filter(
          (item) => item.index == this.store_id
        );
        this.languages = selectedItem[0].languages;
      } else this.languages = null;

      this.resetEditForm();
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n == 1) {
          if (!this.store_id)
            this.errorMessage("Should input Store name!", "error", "Error");
          else this.e1 = n + 1;
        } else this.e1 = n + 1;
      }
    },
    previewStep(n) {
      if (n == 1) this.el = this.steps;
      else this.e1 = n - 1;
    },
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>
