<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    "
  >
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form id="createForm" class="pa-3 mx-3 row elevation-0 overflow-y-auto">
      <div class="col-12 col-md-6 h-75px">
        <v-autocomplete
          v-model="formData.collection_address_id"
          label="Collection address"
          :items="collection_addresses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="collection_address_idErrors"
          @input="$v.formData.collection_address_id.$touch()"
          @blur="$v.formData.collection_address_id.$touch()"
        >
          <template v-slot:prepend-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button class="btn btn-info pl-2" @click="createNewItem()">
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!-- <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <button class="btn btn-info pl-2" @click="createNewItem()">
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template> -->
        </v-autocomplete>
      </div>
      <div class="col-12 col-md-6 h-75px">
        <v-select
          v-model="formData.warehouse_id"
          label="Delivery address"
          :items="serverData.warehouses"
          item-text="text"
          item-value="index"
          clearable
          outlined
          dense
          :error-messages="warehouse_idErrors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-select>
      </div>
      <div class="col-12 col-sm-6 col-md-4 h-75px">
        <v-datetime-picker
          ref="datetime"
          label="Collection date and time"
          :textFieldProps="{
            outlined: true,
            dense: true,
            'prepend-icon': 'mdi-calendar',
            'error-messages': collection_datetimeErrors,
          }"
          :datePickerProps="{}"
          :timePickerProps="{}"
          v-model="formData.collection_datetime"
          @input="$v.formData.collection_datetime.$touch()"
          @blur="$v.formData.collection_datetime.$touch()"
        >
        </v-datetime-picker>
      </div>
      <div class="col-sm-6 col-md-4 col-12 h-75px">
        <v-select
          v-model="formData.package_type"
          label="Package type"
          item-text="text"
          item-value="index"
          :items="serverData.package_types"
          clearable
          outlined
          dense
        ></v-select>
      </div>
      <div class="col-12 col-sm-6 col-md-4 h-75px">
        <v-text-field
          v-model="formData.number_of_packages"
          :label="number_of_packages_label"
          type="number"
          @change="() => validateMinValue('number_of_packages', 0, 'formData')"
          min="0"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div
        class="col-12 col-sm-6 col-md-4 h-75px"
        v-if="formData.package_type == '1'"
      >
        <v-text-field
          v-model="formData.total_number_of_boxes"
          label="Total number of boxes"
          type="number"
          @change="
            () => validateMinValue('total_number_of_boxes', 0, 'formData')
          "
          min="0"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>

      <div class="col-12 col-sm-6 col-md-4">
        <v-text-field
          class
          v-model="formData.weight"
          type="number"
          @change="() => validateMinValue('weight', 0, 'formData')"
          min="0"
          label="Weight"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <v-text-field
          class
          v-model="formData.volumetric_weight"
          type="number"
          @change="() => validateMinValue('volumetric_weight', 0, 'formData')"
          min="0"
          label="Volumetric weight"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>

      <CollectionAddress
        ref="collectionAddressComponent"
        :serverData="serverData"
        :pageLoader="pageLoader"
        :setData="setDataOfAddress"
        :initData="initData"
        :customer_id="customer_id"
        :getStep2Data="getStep2Data"
      ></CollectionAddress>
      <!-- <DestinationAddress
        ref="destinationAddressComponent"
        :serverData="serverData"
        :pageLoader="pageLoader"
        :setData="setDataOfAddress"
        :initData="initData"
      ></DestinationAddress> -->
    </form>

    <v-main class="text-center mt-1 mb-4 col-12 elevation-0">
      <v-btn class="mx-1" color="primary" @click="submitCreateForm">
        submit
      </v-btn>
      <v-btn class="mx-1" color="red" dark @click="resetCreateForm">
        clear
      </v-btn>
      <v-btn class="mx-1" @click="goBack"> back </v-btn>
    </v-main>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CollectionAddress from "@/own/components/shipping/pickupOrders/addressComponents/CollectionAddress";
// import DestinationAddress from "@/own/components/shipping/pickupOrders/addressComponents/DestinationAddress";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "StockForm",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    CollectionAddress,
    // DestinationAddress,
  },
  props: [
    "setData",
    "actionFuntion",
    "serverData",
    "pageLoader",
    "initData",
    "userId",
    "customer_id",
    "getStep2Data",
  ],
  validations() {
    return {
      formData: {
        collection_datetime: { required },
        warehouse_id: { required },
        collection_address_id: { required },
      },
    };
  },
  data: () => ({
    formData: {
      package_type: null,
      number_of_packages: null,
      collection_datetime: null,
      total_number_of_boxes: null,
      collection_address_id: null,
      warehouse_id: null,
      weight: null,
      volumetric_weight: null,
    },
    collection_addresses: [],
  }),
  beforeMount() {
    this.collection_addresses = this.serverData.collection_addresses;
  },
  methods: {
    createNewItem() {
      this.$nextTick(() => {
        this.$refs.collectionAddressComponent.toggleModal();
      });
    },
    goBack() {
      this.actionFuntion("back");
    },
    setDataOfAddress(data, type) {
      this.formData[`${type}`] = data;
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitCreateForm() {
      this.$v.$touch();
      // const comp1 = this.$refs.collectionAddressComponent.submitData();
      // const comp2 = this.$refs.destinationAddressComponent.submitData();
      if (this.$v.$invalid) {
        return;
      }
      // if (!comp1) {
      //   return;
      // }
      // if (!comp2) {
      //   return;
      // }

      this.pageLoader(true);
      // let data = JSON.stringify(this.entCreate);
      const data = {
        ...this.formData,
      };
      data.collection_datetime = this.dateFormatter(
        this.formData.collection_datetime
      );
      // data.collection_address_id = this.formData.collection_address_id.index;
      this.setData(data);
      this.actionFuntion();
      // this.resetCreateForm();
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      if (this.initData) {
        // try {
        let copy = { ...this.serverData.pickup_order };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.pickup_order).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });

          if (
            this.formData.collection_datetime &&
            this.formData.collection_datetime != ""
          ) {
            this.formData.collection_datetime = this.dateMaker(
              this.formData.collection_datetime
            );
          }
          // this.$nextTick(() => {

          // });
        });
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // } catch (e) {
        // console.error(e);
        // this.formData = {
        //   package_type: null,
        //   number_of_packages: null,
        //   collection_datetime: null,
        // };
        // }
      } else {
        // this.$nextTick(() => {
        // this.$refs.collectionAddressComponent.resetData();
        // this.$refs.destinationAddressComponent.resetData();
        // });

        this.formData = {
          package_type: null,
          number_of_packages: null,
          collection_datetime: null,
          total_number_of_boxes: null,
          collection_address_id: null,
          warehouse_id: null,
          weight: null,
          volumetric_weight: null,
        };
        this.$refs.datetime.clearHandler();
      }
    },
    dateMaker(data) {
      let date = new Date(data);
      return date;
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    collection_datetimeErrors() {
      return this.handleFormValidation("collection_datetime", this);
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id", this);
    },
    collection_address_idErrors: function () {
      return this.handleFormValidation("collection_address_id", this);
    },
    number_of_packages_label() {
      return this.formData.package_type == "2"
        ? "Number of boxes"
        : "Number of pallet";
    },
  },
};
</script>
