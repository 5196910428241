<template>
  <v-data-table
    dense
    type="number"
    :headers="headers"
    :items="items"
    item-key="tote_id"
    class="my-6 pl-4 pr-8"
    hide-default-footer
    mobile-breakpoint="300"
  >
  </v-data-table>
</template>

<script>
export default {
  name: "B2BPackingTable",
  props: ["customItems"],
  computed: {
    headers: function () {
      return [
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "sku_barcode",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Quantity",
          align: "start",
          sortable: false,
          value: "qty",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Location",
          align: "start",
          sortable: false,
          value: "location",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Box Id",
          align: "start",
          sortable: false,
          value: "order_box_id",
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
      ];
    },
    items: function () {
      const action = this.$store.getters.getB2BPACKINGPICKINGAddBoxActionType;
      if (action === "edit") {
        return [
          ...this.customItems,
          ...this.$store.getters.getB2BPACKINGPICKINGSkuForEdit,
        ];
      }
      return this.customItems;
    },
  },
};
</script>
