<template>
  <v-card id="consignee" class="mt-4">
    <v-card-title class="primary h5 text-white"
      >{{ item.title }}
      <v-spacer />
      <v-btn
        v-if="item?.header_actions?.edit_asn"
        class="bg-light"
        @click="showEditLightModal"
        outlined
        >Edit</v-btn
      >
    </v-card-title>
    <v-card-text>
      <div v-for="(element, index) in item.data" :key="index">
        <span class="other_key font-weight-bolder">{{ element.title }}: </span
        ><span class="other_value" :style="element.style">{{
          element.value
        }}</span>
      </div>
    </v-card-text>
    <EditLight
      ref="editLight"
      :refresher="refresher"
      :toggleDetail="toggleDetail"
    />
  </v-card>
</template>

<script>
import EditLight from "@/own/components/fulfillment/asn/EditLight.vue";

export default {
  name: "PopUpTextGroup",
  components: { EditLight },
  props: ["item", "order_id", "refresher", "toggleDetail"],
  methods: {
    showEditLightModal() {
      this.$refs.editLight.toggleModal(this.order_id);
    },
  },
};
</script>
