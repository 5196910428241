<template>
  <div v-if="value">
    <a
      href="#"
      class="btn btn-sm px-4 custom-btn text-no-wrap"
      :class="
        value.label === 'Show errors'
          ? 'btn-light-google'
          : 'btn-light-facebook'
      "
      @click="toggleModal"
      >{{ buttonText }}</a
    >
    <div>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        max-width="850"
      >
        <div class="bg-white poppins">
          <h3 class="modal-header align-center mb-0 font-weight-bolder">
            {{ $t("errors") }}
          </h3>

          <v-card-text class="mt-4">
            <div class="text-h5 pa-2 overflow-auto">
              <div v-for="(e, i) in value.errors" :key="i" class="mb-2">
                <span class="poppins">{{ e }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end mr-2 pb-4 poppins">
            <v-btn color="red" dark @click="dialog = false">{{
              $t("close")
            }}</v-btn>
          </v-card-actions>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "ListModal",
  props: ["value"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    downloadFile() {
      this.$store.commit(SET_PAGE_LOADING, true);
      try {
        const link = document.createElement("a");
        link.href = this.value.path;
        link.download = "file.txt";
        link.click();
        this.$store.commit(SET_PAGE_LOADING, false);
      } catch {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    // isErrorExists() {
    //   return !!this.value.has_error;
    // },
    toggleModal() {
      if (this.value.has_error) this.dialog = !this.dialog;
    },
  },
  computed: {
    buttonText: function () {
      if (this.value.has_error) return this.value.label;
      return this.value.label;
    },
  },
};
</script>
