<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ $t("add_new_wave") }}</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon svg-icon-5">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-1 mx-xl-6 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0 row">
          <div class="h-75px col-12 ma-0 pa-0">
            <v-select
              v-model="formData.customer_id"
              :label="$t('customer')"
              item-text="text"
              item-value="index"
              :items="serverData.customers"
              clearable
              outlined
              dense
              hide-details
              required
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-select>
          </div>

          <div class="h-75px col-12 ma-0 pa-0">
            <v-text-field
              v-model="formData.wave_id"
              :label="$t('wave_id')"
              clearable
              outlined
              dense
              :error-messages="wave_idErrors"
              @input="$v.formData.wave_id.$touch()"
              @blur="$v.formData.wave_id.$touch()"
            ></v-text-field>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light me-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import ApiService from "@/core/services/api.service";
import { required } from "vuelidate/lib/validators";
import validationMixin from "@/own/mixins/validationMixin";
import SwalService from "@/core/services/swal.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      customer_id: { required },
      wave_id: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      wave_id: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/wes/wesSplWaves/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/wes/wesSplWaves/store", data)
        .then(() => {
          SwalService.successMessage({
            html: SwalService.messages.added(),
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },

    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        wave_id: null,
      };
    },
  },
  computed: {
    isOrganization() {
      return this.formData.type_id === 1;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    wave_idErrors: function () {
      return this.handleFormValidation("wave_id", this);
    },
  },
};
</script>
