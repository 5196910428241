export default {
  methods: {
    getDateRange(type, filterData) {
      const filter = filterData;
      const ranges = {
        today: function () {
          return new Date().toISOString().split("T")[0];
        },

        this_week: function () {
          const now = new Date(); // current date
          const firstDay = now.getDate() - now.getDay(); // date of th 1st day of current week
          const firstDayDate = new Date(now.setDate(firstDay))
            .toISOString()
            .split("T")[0];
          const today = new Date().toISOString().split("T")[0];
          return [firstDayDate, today];
        },

        last_week: function () {
          const lastSunday = new Date().setDate(
            new Date().getDate() - new Date().getDay() - 7
          );
          const lastFriday = new Date().setDate(
            new Date().getDate() - new Date().getDay() - 1
          );

          const lastSundayDate = new Date(lastSunday)
            .toISOString()
            .split("T")[0];
          const lastFridayDate = new Date(lastFriday)
            .toISOString()
            .split("T")[0];

          return [lastSundayDate, lastFridayDate];
        },

        last_7_days: function () {
          const firstDay = new Date().setDate(new Date().getDate() - 7);
          const firstDayDate = new Date(firstDay).toISOString().split("T")[0];

          const lastDay = new Date().setDate(new Date().getDate() - 1);
          const lastDayDate = new Date(lastDay).toISOString().split("T")[0];

          return [firstDayDate, lastDayDate];
        },

        this_month: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          const lastDay = new Date().toISOString().split("T")[0];
          return [firstDay, lastDay];
        },

        last_month: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth() - 1, 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          const lastDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 0) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];
          return [firstDay, lastDay];
        },

        last_30_days: function () {
          const today = new Date();
          const firstDay = new Date(new Date().setDate(today.getDate() - 30))
            .toISOString()
            .split("T")[0];
          return [firstDay, today.toISOString().split("T")[0]];
        },

        this_year: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear(), date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return [firstDay, date.toISOString().split("T")[0]];
        },

        last_year: function () {
          const date = new Date();
          const firstDay = new Date(
            new Date(date.getFullYear() - 1, date.getMonth(), 1) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          const lastDay = new Date(
            new Date(date.getFullYear() - 1, date.getMonth() + 11, 31) -
              date.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return [firstDay, lastDay];
        },

        all_time: function () {
          const firstDay = new Date(0).toISOString().split("T")[0];
          const lastDay = new Date().toISOString().split("T")[0];

          return [firstDay, lastDay];
        },

        custom: function () {
          const firstDay = filter.date_range[1];
          const lastDay = filter.date_range[1];

          return [firstDay, lastDay];
        },
      };
      // return ranges[type]();
      return [
        { name: "date_range.date", value: ranges[type]() },
        {
          name: "date_range.select",
          value: "orderCreationTime",
        },
      ];
    },

    getOrderStatus(name) {
      const status = {
        delivery_orders_counter_chart: function () {
          return [
            1, 2, 3, 4, 5, 6, 8, 10, 11, 12, 15, 20, 30, 40, 50, 54, 57, 60, 70,
            75, 80, 90, 100, 110, 120, 300, 310, 320, 330, 340, 350, 360, 370,
            495, 500, 510, 520, 530, 540, 550, 700, 710, 720, 730, 740, 750,
            760, 770, 780, 790, 800, 810, 811, 812, 813, 814, 820, 1000, 1010,
            1020, 1030, 1100, 1110, 1120, 1130, 1140, 1150, 1200, 1210, 1220,
            1230, 1240, 1250, 1260, 1270, 1280, 1300, 1310, 1320, 1330, 1340,
            1350, 1360, 1370, 1375, 1380, 1385,
          ];
        },
        delivery_under_process_counter_chart: function () {
          return [10, 20, 30, 40];
        },
        delivery_shipped_counter_chart: function () {
          return [60, 75, 80, 90, 100, 110, 120];
        },
        delivery_status_pending_counter_chart: function () {
          return [75, 90];
        },
        delivery_status_out_for_delivery_counter_chart: function () {
          return [60, 80];
        },
        delivery_status_delivered_counter_chart: function () {
          return [100];
        },
        delivery_status_returned_counter_chart: function () {
          return [110];
        },
      };
      return {
        name: "orderStatus",
        value: status[name](),
      };
    },
  },
};
