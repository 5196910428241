<template>
  <div>
    <v-menu left rounded>
      <template v-slot:activator="{ on, attrs }">
        <button class="btn btn-sm btn-icon" v-bind="attrs" v-on="on">
          <span class="svg-icon">
            <v-icon size="19"> mdi-dots-vertical </v-icon>
          </span>
        </button>
      </template>

      <v-list class="py-0">
        <v-list-item
          v-if="!item.is_active"
          class="text-center bg-hover-light-info"
          @click.prevent="() => handleEnablePlan(item.id)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Enable</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          class="text-center bg-hover-light-info"
          @click.prevent="() => $emit('handleEditPlan', item)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Edit</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          class="text-center bg-hover-light-info"
          @click.prevent="() => removeItem(item.id)"
        >
          <v-list-item-title class="poppins font-size-h6"
            >Delete</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import swalDeleteQuery from "@/core/services/AlertServices/SwalDeleteQuery";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { GET_SORT_PLAN_DATA } from "@/core/services/store/wesSortPlan.module";

export default {
  name: "ActionsMenu",
  props: ["item"],
  methods: {
    handleEnablePlan(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/wes/wesSortPlan/enable", {
        id,
      })
        .then(() => {
          Swal.fire("Updated!", "Plan has been activated.", "success");
        })
        .finally(() => {
          this.$store.dispatch(GET_SORT_PLAN_DATA).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        });
    },
    removeItem(id) {
      swalDeleteQuery().then((result) => {
        if (result.isConfirmed) {
          this.$store.commit(SET_PAGE_LOADING, true);
          ApiService.post("/wes/wesSortPlan/destroy", {
            id,
          })
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
            })
            .finally(() => {
              this.$store.dispatch(GET_SORT_PLAN_DATA).finally(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
            });
        }
      });
    },
  },
};
</script>
