<template>
  <v-dialog v-model="dialog" persistent max-width="600px" v-if="exampleFile">
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder text-capitalize">{{ label }}</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div
        v-if="step === 1"
        class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->
        <div class="">
          <v-alert
            dense
            border="left"
            type="warning"
            dismissible
            v-if="exampleFile.need_to_show_message"
          >
            <span v-html="exampleFile.message"></span>
          </v-alert>
        </div>
        <v-card elevation="0" class="mb-4">
          <form id="createForm" class="pa-3 text-center">
            <v-file-input
              v-model="formData.file"
              label="File"
              outlined
              dense
              :error-messages="fileErrors"
              @input="$v.formData.file.$touch()"
              @blur="$v.formData.file.$touch()"
            ></v-file-input>
            <!-- add example file -->
            <v-btn class="primary--text" outlined text :href="exampleFile.url">
              Download Template
            </v-btn>
            <v-main class="text-center mt-4">
              <button
                type="button"
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitEditForm"
              >
                Import
              </button>
            </v-main>
          </form>
        </v-card>
        <!--end::Body-->
      </div>
      <v-card class="pa-2" v-else>
        <div>
          <v-card class="mb-12 pt-0 h-100" height="600px" elevation="0">
            <div class="text-left text-bold h3 ma-0 pa-0 text-success">
              {{ importProcessCurrentTask }}/{{ importProcessTotalTask }}
            </div>
            <div
              class="text-h5 messagebox overflow-y"
              style="height: 300px; overflow-y: scroll"
              v-html="getProcessLogMessage"
            ></div>
          </v-card>
          <div class="text-center">
            <v-btn
              class="pa-4 px-8 h6"
              :href="getFailedDownloadsFile"
              v-if="getFailedDownloadsFile"
              prepend-icon="mdi-file-cancel-outline"
            >
              Download failed
            </v-btn>
          </div>

          <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
            <button
              class="btn btn-info px-5 py-3 mx-2 ls1"
              @click="toggleModal"
              :disabled="!getIsImportProcessEnded"
            >
              Finish
            </button>
          </div>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import getPusherInstance from "@/core/services/pusher.service";

export default {
  name: "ImportData",
  mixins: [validationMixin],
  validations: {
    formData: {
      file: { required },
    },
  },
  props: ["type", "label", "setData", "importType"],
  data: () => ({
    dialog: false,
    formData: {
      file: null,
    },
    step: 1,

    importProcessLog: [],
    isImportProcessEnded: false,
    importProcessTotalTask: 0,
    importProcessCurrentTask: 0,
    importProcessFailedFilePath: null,
    failedDownloadsFile: null,
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      let data = new FormData();

      if (this.importType == "create") {
        data.append("type", this.type);
      } else {
        data.append("type", this.type + "_update");
      }

      data.append("file", this.formData.file, this.formData.file.name);
      data.append(
        "warehouse_id",
        this.$store.getters.getWAREHOUSESSelectedWarehouseId
      );
      if (this.type == "zone_group") {
        data.append(
          "warehouse_id",
          this.$store.getters.getWAREHOUSESSelectedWarehouseId
        );
      } else if (this.type == "zone") {
        data.append(
          "warehouse_id",
          this.$store.getters.getWAREHOUSESSelectedWarehouseId
        );
        data.append(
          "zone_group_id",
          this.$store.getters.getWAREHOUSESSelectedZoneGroupId
        );
      } else if (this.type == "location") {
        data.append(
          "warehouse_id",
          this.$store.getters.getWAREHOUSESSelectedWarehouseId
        );
        data.append(
          "zone_group_id",
          this.$store.getters.getWAREHOUSESSelectedZoneGroupId
        );
        data.append("zone_id", this.$store.getters.getWAREHOUSESSelectedZoneId);
      }
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = this.convertToFormData();
      if (this.type == "location") {
        ApiService.post("/account/uploads/upload", data)
          .then((data) => {
            // Swal.fire({
            //   title: "Imported",
            //   text: `Data is imported!`,
            //   icon: "success",
            //   showConfirmButton: false,
            //   timer: 2500,
            // });

            this.initSocketConnection(data.data.event);
            // this.importComplete();

            // Swal.fire({
            //   title: `<strong>${res.data.data.success} of ${res.data.data.total} imported!</strong>`,
            //   icon: "info",
            //   html: `<div class='text-left'>${message}</div>`,
            // });
          })
          .catch(() => {
            // Swal.fire({
            //   title: "Error",
            //   text: `${res.data.message}`,
            //   icon: "error",
            //   showConfirmButton: false,
            //   timer: 2500,
            // });
            this.step = 1;
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        ApiService.post("/warehouse_management/warehouses/upload", data)
          .then(() => {
            // Swal.fire({
            //   title: "Imported",
            //   text: `Data is imported!`,
            //   icon: "success",
            //   showConfirmButton: false,
            //   timer: 2500,
            // });

            // this.initSocketConnection(data.data.event);
            this.importComplete();

            // Swal.fire({
            //   title: `<strong>${res.data.data.success} of ${res.data.data.total} imported!</strong>`,
            //   icon: "info",
            //   html: `<div class='text-left'>${message}</div>`,
            // });
          })
          .catch(() => {
            // Swal.fire({
            //   title: "Error",
            //   text: `${res.data.message}`,
            //   icon: "error",
            //   showConfirmButton: false,
            //   timer: 2500,
            // });
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    importComplete() {
      this.setData();
      this.toggleModal();
      this.resetEditForm();
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData.file = null;

      this.step = 1;

      this.isImportProcessEnded = false;
      this.importProcessTotalTask = 0;
      this.importProcessCurrentTask = 0;
      this.importProcessFailedFilePath = null;
      this.failedDownloadsFile = null;
      this.importProcessLog = [];
    },

    stopProcess() {},
    addMessageToLog(message, type = "success") {
      const messageClass = {
        state: "text-primary",
        success: "",
        info: "text-info",
        error: "text-danger",
        warning: "text-warning",
      };
      this.importProcessLog.push({
        message: message,
        class: messageClass[`${type}`],
      });
    },
    handleConfirmationMessage(notification) {
      Swal.fire({
        title: "Upload warning!",
        html: notification.message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, continue!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post("/fulfillment/orders/confirm", {
            token: notification.token,
          })
            .then(() => {
              Swal.fire("Confirmed!", "Upload is resumed", "info");
            })
            .catch(() => {
              Swal.fire(
                "Error happened during process!",
                "Something went wrong during import process!",
                "error"
              );
            });
        } else {
          this.stopProcess();
        }
      });
    },
    initSocketConnection(eventName) {
      this.step = 2;
      const pusher = getPusherInstance();
      const channel = pusher.subscribe(this.userSocketData.channel);
      channel.bind(`${eventName}`, (notification) => {
        // console.log(notification);
        switch (notification.type) {
          case "start":
            this.importProcessTotalTask = notification.total;
            this.$store.commit(SET_PAGE_LOADING, false);
            this.addMessageToLog(notification.message, "state");
            break;
          case "end":
            this.addMessageToLog(notification.message, "state");
            this.failedDownloadsFile = notification.failed_file_path;
            this.isImportProcessEnded = true;

            this.updateTableContent();
            break;
          case "info":
            this.addMessageToLog(notification.message, "info");
            break;
          case "success":
            this.importProcessCurrentTask += 1;
            // this.addMessageToLog(notification.message, "success");
            break;
          case "error":
            this.addMessageToLog(notification.message, "error");
            break;
          case "popUpConfirmationMessage":
            this.handleConfirmationMessage(notification);
            this.addMessageToLog(notification.message, "warning");
            break;
        }
      });
    },
  },
  computed: {
    userSocketData: function () {
      /**
       * @type {{key:string,wsHost:string,wsPort:number,wssPort:number,channel:string,event:string}}
       * @example {"key":"BKFNMJNMGF","wsHost":"apitest.iqfulfillment.com","wsPort":6001,"wssPort":6001,"channel":"user.127","event":".notification.created"}
       */
      const socketData = this.$store.getters.getSocketData;
      return socketData;
    },

    getProcessLogMessage() {
      let content = "<div>";
      this.importProcessLog.forEach((data) => {
        content = content + `<div class="${data.class}">${data.message}</div>`;
      });
      content += `</div>`;
      return content;
    },
    getIsImportProcessEnded() {
      return this.isImportProcessEnded;
    },
    getFailedDownloadsFile() {
      return this.failedDownloadsFile;
    },

    fileErrors() {
      return this.handleFormValidation("file", this);
    },
    exampleFile() {
      // let file = {
      //   type: "increments",
      //   url: "https://apitest.iqfulfillment.com/files/increments/increment.xlsx?v=1656928804",
      //   message:
      //     "The Excel template has been updated. Please, be sure to download a new one.",
      //   need_to_show_message: false,
      // };
      let file = null;
      if (this.type == "warehouse") {
        file =
          this.$store.getters.getWAREHOUSESExampleFiles.warehouse_sample_file;
      } else if (this.type == "zone_group") {
        file =
          this.$store.getters.getWAREHOUSESExampleFiles.zone_group_sample_file;
      } else if (this.type == "zone") {
        file = this.$store.getters.getWAREHOUSESExampleFiles.zone_sample_file;
      } else if (this.type == "location") {
        file =
          this.$store.getters.getWAREHOUSESExampleFiles.location_sample_file;
      }
      return file;
    },
  },
};
</script>

<style scoped>
.modal-content {
  height: 76vh;
  max-height: 1000px;
}
.messagebox {
  max-height: 600px;
  overflow-y: scroll;
}
</style>
