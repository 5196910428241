<template>
  <div
    class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
    :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
  >
    <button @click="cancleCases" class="btn btn--export-filter ml-1">
      Cancel task
    </button>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";

export default {
  name: "CancleCases",
  props: ["selectedItems", "pageLoading", "refresher"],
  methods: {
    cancleCases() {
      SwalService.warningConditionMessage(
        {
          title: SwalService.titles.are_you_sure,
          html: SwalService.messages.you_wont_be_able_to_revert_this,
          confirmButtonText: `Yes, cancel it!`,
        },
        () => {
          this.pageLoading(true);
          //TODO: change data sending to id
          const data = this.selectedItems.map((item) => item.id);
          ApiService.post(
            `/warehouse_management/standard_quality_checks/cancel`,
            { ids: data }
          )
            .then(() => {
              SwalService.successMessage({
                title: SwalService.titles.updated,
                html: `Cases are successfully canceled`,
              });

              this.refresher();
            })
            .finally(() => {
              this.pageLoading(false);
            });
        }
      );
    },
  },
};
</script>
