<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Store</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->

        <form id="createForm" class="pa-3 pb-0">
          <v-select
            v-model="formData.customer_id"
            label="Customer"
            item-text="text"
            item-value="index"
            :items="serverData.clients"
            clearable
            outlined
            :error-messages="customer_idErrors"
            @input="$v.formData.customer_id.$touch()"
            @blur="$v.formData.customer_id.$touch()"
          ></v-select>
          <v-text-field
            v-model="formData.name"
            label="Name"
            outlined
            required
            :error-messages="nameErrors"
            @input="$v.formData.name.$touch()"
            @blur="$v.formData.name.$touch()"
          ></v-text-field>

          <v-file-input
            class="col-12"
            v-model="formData.logo_dark"
            show-size
            counter
            multiple
            dense
            outlined
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            label="Logo Dark"
            clearable
            :error-messages="logoDarkErrors"
            @input="$v.formData.logo_dark.$touch()"
            @blur="$v.formData.logo_dark.$touch()"
          ></v-file-input>

          <v-file-input
            class="col-12"
            v-model="formData.logo_light"
            show-size
            counter
            multiple
            dense
            outlined
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            label="Logo Light"
            clearable
            :error-messages="logoLightErrors"
            @input="$v.formData.logo_light.$touch()"
            @blur="$v.formData.logo_light.$touch()"
          ></v-file-input>

          <v-item-group>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div><h3>Color 1</h3></div>
              <div>
                <v-color-picker
                  v-model="formData.color_1"
                  class="ma-2"
                  hide-canvas
                  title="color-1"
                  mode="hexa"
                  dense
                ></v-color-picker>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div><h3>Color 2</h3></div>
              <div>
                <v-color-picker
                  v-model="formData.color_2"
                  class="ma-2"
                  hide-canvas
                  title="color-1"
                  mode="hexa"
                  dense
                ></v-color-picker>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div><h3>Color 3</h3></div>
              <div>
                <v-color-picker
                  v-model="formData.color_3"
                  class="ma-2"
                  hide-canvas
                  title="color-1"
                  mode="hexa"
                  dense
                ></v-color-picker>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: space-evenly;
              "
            >
              <div><h3>Color 4</h3></div>
              <div>
                <v-color-picker
                  v-model="formData.color_4"
                  class="ma-2"
                  hide-canvas
                  title="color-1"
                  mode="hexa"
                  dense
                ></v-color-picker>
              </div>
            </div>
          </v-item-group>

          <v-text-field
            v-model="formData.description"
            label="Description"
            clearable
            outlined
          ></v-text-field>
          <v-select
            v-model="formData.languages"
            label="Languages"
            item-text="text"
            item-value="index"
            :items="serverData.languages"
            multiple
            clearable
            outlined
            :error-messages="languagesErrors"
            @input="$v.formData.languages.$touch()"
            @blur="$v.formData.languages.$touch()"
          ></v-select>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      customer_id: { required },
      name: { required },
      logo_dark: { required },
      logo_light: { required },
      languages: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: {},
    formData: {
      customer_id: null,
      name: null,
      logo_dark: null,
      logo_light: null,
      color_1: null,
      color_2: null,
      color_3: null,
      color_4: null,
      description: null,
      languages: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/stores/portal/stores/create`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: `${error.response.data?.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.resetCreateForm();
      } else {
        this.dialog = !this.dialog;
      }
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] != "logo_dark" && item[0] != "logo_light") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else if (
            typeof item[1] === "object" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], item[1].hex);
          } else {
            data.append(item[0], item[1]);
          }
        } else if (
          item[1] &&
          (item[0] == "logo_dark" || item[0] == "logo_light")
        ) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/stores/portal/stores/store`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            this.pageLoader(false);
            Swal.fire({
              title: "Created",
              text: "New Store has been created successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.resetCreateForm();
            this.toggleModal();
            this.refresher();
          })
          .catch((error) => {
            this.pageLoader(false);
            Swal.fire({
              title: "Error",
              text: `${error?.data?.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoader(false);
          });
        this.pageLoader(false);
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        logo_dark: null,
        logo_light: null,
        name: null,
        color_1: null,
        color_2: null,
        color_3: null,
        color_4: null,
        description: null,
        languages: null,
      };
    },
  },
  computed: {
    color_1: function () {
      return this.formData.color_1;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    logoDarkErrors() {
      return this.handleFormValidation("logo_dark", this);
    },
    logoLightErrors() {
      return this.handleFormValidation("logo_light", this);
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    languagesErrors() {
      return this.handleFormValidation("languages", this);
    },
  },
};
</script>

<style scoped>
.editorStyle {
  max-height: 70vh;
  overflow: hidden;
}
</style>
