<template>
  <span class="second-text my-1">
    <div class="d-flex align-content-left w-auto px-2">
      <span class="symbol symbol-35 symbol-light-success text-left px-1">
        <img
          style="border-radius: 16px !important"
          class="stretched-img"
          v-if="value.logo"
          :alt="value.name"
          :src="value.logo"
        />
      </span>

      <span class="text-dark-50 font-size-base w-auto p-2">
        {{ value.name }}
      </span>
    </div>
  </span>
</template>

<script>
/**
 * component for showing user name and photo in datatable column
 * api name = userPhoto
 * */
export default {
  name: "CarrierPhoto",
  props: ["value"],
};
</script>

<style scoped>
.stretched-img {
  object-fit: scale-down;
}
</style>
