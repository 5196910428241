<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="500"
    v-if="serverData"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">On Hold</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row ma-0 pa-0">
          <div class="col-12">
            <v-autocomplete
              v-model="formData.reason_id"
              label="Reason"
              item-text="text"
              item-value="index"
              :items="serverData.reason_codes"
              clearable
              outlined
              :error-messages="reason_idErrors"
              @input="$v.formData.reason_id.$touch()"
              @blur="$v.formData.reason_id.$touch()"
            ></v-autocomplete>
          </div>
        </div>
        <!--end::Body-->
        <div class="my-1 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            On Hold
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "OnHold.vue",
  mixins: [validationMixin],
  props: {
    setReasonType: {
      type: Function,
      required: true,
    },
  },
  validations() {
    return {
      formData: {
        reason_id: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    formData: {
      reason_id: null,
      ids: null,
    },
    serverData: null,
  }),
  computed: {
    reason_idErrors() {
      return this.handleFormValidation("reason_id", this);
    },
  },
  methods: {
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post(
        "/warehouse_management/inventory_overview/get_reason_codes"
      )
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal(ids = null) {
      this.formData.ids = ids;
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setReasonType(JSON.parse(JSON.stringify(this.formData)));
        this.toggleModal();
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      (this.serverData = null),
        (this.formData = {
          reason_id: null,
          ids: null,
        });
    },
  },
};
</script>
