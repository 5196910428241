<template>
  <!-- <div class="text-center no-warehouse-popup"> -->
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t("select_warehouse") }}
      </v-card-title>

      <v-card-text class="mt-10">
        <div
          class="swal2-icon swal2-warning swal2-icon-show"
          style="display: flex"
        >
          <div class="swal2-icon-content">!</div>
        </div>
        <div class="d-block text-center mb-8">
          <h4>{{ $t("no_warehouse_selected") }}</h4>
          <h5 class="font-weight-light mt-5">
            {{ $t("please_select_an_warehouse_to_proceed") }}
          </h5>
          <WarehouseSelect class="mt-10 px-4" />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- </div> -->
</template>

<script>
import WarehouseSelect from "@/view/layout/extras/offcanvas/WarehouseSelect.vue";

export default {
  name: "NoWarehousePopup",
  components: { WarehouseSelect },
  data: () => ({
    dialog: false,
  }),
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    setOverlayStyle: function () {
      if (window.matchMedia("(min-width: 992px)").matches) {
        return "64px";
      }
      return 0;
    },
  },
  methods: {
    setOverlayPlacement() {
      // in case of no warehouse selected popup, let's keep the sidebar clickable
      setTimeout(() => {
        const OVERLAY_ELEMENT = document.querySelector(".v-overlay--active");
        OVERLAY_ELEMENT.style.marginLeft = this.setOverlayStyle;
      }, 500);
    },
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.dialog = false;
      } else {
        this.dialog = true;
        this.setOverlayPlacement();
      }
    },
  },
};
</script>
