<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Cycle count</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <v-radio-group
          v-model="count_category"
          row
          class="mb-4"
          @change="onChangeCategory"
        >
          <v-radio
            label="Location count"
            value="location"
            color="indigo"
          ></v-radio>
          <v-radio label="SKU count" value="sku" color="indigo"></v-radio>
          <v-radio label="Zone count" value="zone" color="indigo"></v-radio>
        </v-radio-group>

        <div>
          <v-autocomplete
            v-model="client_id"
            label="Client"
            item-text="text"
            item-value="index"
            :items="clients"
            clearable
            outlined
            dense
            :error-messages="clientsErrors"
            @blur="$v.client_id.$touch()"
          ></v-autocomplete>

          <v-autocomplete
            v-if="organizations"
            v-model="organization_id"
            label="Organization"
            item-text="text"
            item-value="index"
            :items="organizations"
            clearable
            outlined
            dense
            :error-messages="organizationsErrors"
            @blur="$v.organization_id.$touch()"
          ></v-autocomplete>

          <v-autocomplete
            v-model="assignedTo"
            label="Assign to"
            item-text="text"
            item-value="index"
            :items="users"
            clearable
            outlined
            dense
            :error-messages="assignedToErrors"
            @blur="$v.assignedTo.$touch()"
          ></v-autocomplete>

          <v-autocomplete
            ref="multiRoleField"
            v-model="multiRoleFieldValue"
            :label="multiRoleFieldLabel"
            dense
            outlined
            multiple
            clearable
            :item-text="multiRoleFieldItemText"
            :item-value="multiRoleFieldItemId"
            :items="serverData"
            :loading="multiRoleFieldLoading"
            :search-input.sync="multiRoleFieldSearchText"
            @keydown="fetchMultiRoleFieldOptions"
            @input="$v.multiRoleFieldValue.$touch()"
            @blur="$v.multiRoleFieldValue.$touch()"
            :error-messages="multiRoleFieldValueErrors"
            small-chips
            deletable-chips
            hide-details
            hide-no-data
            cache-items
          />
        </div>

        <!--begin::Actions-->
        <div class="mb-4 mt-8 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn--print-upload mr-3 px-5 py-3 ls1"
            @click="continueCycleCount"
          >
            Continue
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

import ApiService from "@/core/services/api.service";
import { STORE_CYCLE_COUNT } from "@/core/services/store/cycleCounts.module";
// import Clients from "pages/customers/Clients.vue";

export default {
  name: "CycleCountModal",
  props: ["pageLoading", "refresher"],
  mixins: [validationMixin],
  validations: {
    multiRoleFieldValue: { required },
    client_id: { required },
    organization_id: {
      required: requiredIf(function () {
        return this.organizations;
      }),
    },
    assignedTo: { required },
  },
  data: () => ({
    dialog: false,
    count_category: "location",
    multiRoleFieldValue: [],
    multiRoleFieldLoading: false,
    multiRoleFieldSearchText: "",
    assignedTo: false,
    client_id: null,
    organization_id: null,
    serverData: [],
  }),
  methods: {
    toggleModal() {
      this.clearData();
      this.clearServerData();
      this.count_category = "location";
      this.dialog = !this.dialog;
    },
    clearData() {
      this.$v.$reset();
      this.multiRoleFieldLoading = false;
      this.multiRoleFieldSearchText = "";
      this.assignedTo = null;
      this.multiRoleFieldValue = [];
    },
    clearServerData() {
      this.serverData = [];
    },
    onChangeCategory() {
      this.$refs.multiRoleField.cachedItems = [];
      this.clearData();
      this.clearServerData();
      if (this.count_category === "zone") {
        this.serverData = [...this.zones];
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[fieldName]) {
        !vueObj.$v[fieldName].required && errors.push("This field is required");
      }
      return errors;
    },
    fetchMultiRoleFieldOptions() {
      setTimeout(() => {
        if (this.multiRoleFieldSearchText.trim() !== "") {
          const search = async () => {
            if (this.selectedWarehouse) {
              this.multiRoleFieldLoading = true;
              const url = {
                location: "/reports/reportBinOccupancy/search-location",
                sku: "/skus/search_sku",
              };

              const payload = {
                filters: {
                  warehouse_id: this.selectedWarehouse,
                },
                q: this.multiRoleFieldSearchText.trim(),
              };

              try {
                const { data } = await ApiService.post(
                  url[this.count_category],
                  payload
                );
                this.serverData = data.locations || data.skus;
              } finally {
                this.multiRoleFieldLoading = false;
              }
            }
          };
          if (this.count_category !== "zone") {
            search();
          }
        }
      }, 100);
    },
    continueCycleCount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const payload = {
        warehouse_id: this.selectedWarehouse,
        assigned_to: this.assignedTo,
        count_category: this.count_category,
        count_category_value: this.multiRoleFieldValue,
        customer_id: this.client_id,
        organization_id: this.organization_id,
      };
      this.$store
        .dispatch(STORE_CYCLE_COUNT, payload)
        .then(({ data }) => {
          let editedItemMessage = `<div><ol>`;
          data.messages.forEach((item) => {
            editedItemMessage += `<li>${item}</li>`;
          });
          editedItemMessage = editedItemMessage + `</ol></div>`;
          Swal.fire({
            title: `Result`,
            html: editedItemMessage,
            icon: data.error_locations === 0 ? "success" : "warning",
            showConfirmButton: true,
          });

          this.clearData();
        })
        .then(() => {
          this.refresher();
        });
    },
  },
  computed: {
    users: function () {
      return this.$store.getters.getCYCLECOUNTSUsers;
    },
    clients: function () {
      return this.$store.getters.getCYCLECOUNTSClients;
    },
    organizations: function () {
      return this.$store.getters.getCYCLECOUNTSOrganizations;
    },
    multiRoleFieldLabel: function () {
      const label = {
        sku: "SKU",
        location: "Location",
        zone: "Zone",
      };
      return label[this.count_category];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    zones: function () {
      return this.$store.getters.getCYCLECOUNTZones;
    },
    multiRoleFieldItemId: function () {
      const id = {
        location: "id",
        sku: "skuId",
        zone: "index",
      };
      return id[this.count_category];
    },
    multiRoleFieldItemText: function () {
      const id = {
        location: "name",
        sku: "systemCode",
        zone: "text",
      };
      return id[this.count_category];
    },
    multiRoleFieldValueErrors: function () {
      return this.handleFormValidation("multiRoleFieldValue", this);
    },
    clientsErrors: function () {
      return this.handleFormValidation("client_id", this);
    },
    organizationsErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
    assignedToErrors: function () {
      return this.handleFormValidation("assignedTo", this);
    },
  },
};
</script>
