<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          Add
          {{
            addressType
              ? addressType.charAt(0).toUpperCase() + addressType.slice(1)
              : ""
          }}
          Address
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.name"
              class
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              class
              v-model="formData.company"
              label="Company"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-autocomplete
              @change="onCountryChange"
              v-model="formData.country"
              label="Country"
              item-text="text"
              item-value="index"
              :items="addressServerData.countries"
              clearable
              outlined
              dense
              :error-messages="countryErrors"
              @input="$v.formData.country.$touch()"
              @blur="$v.formData.country.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6">
            <v-combobox
              ref="stateCombo"
              v-model="formData.state"
              :search-input.sync="state_text"
              :items="addressServerData.states"
              :disabled="isStatesLoaded"
              :rules="
                validationValueCheck('state')
                  ? [validationValueCheck('state')]
                  : []
              "
              item-text="title"
              item-value="title"
              :label="getSuggestedRegion('state')"
              persistent-hint
              outlined
              dense
              clearable
              :error-messages="stateErrors"
              @input="$v.formData.state.$touch()"
              @blur="$v.formData.state.$touch()"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ state_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'state',
                            formData.country,
                            onCountryChange,
                            state_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6">
            <v-combobox
              ref="cityCombo"
              v-model="formData.city"
              :search-input.sync="city_text"
              :items="addressServerData.cities"
              :rules="
                validationValueCheck('city')
                  ? [validationValueCheck('city')]
                  : []
              "
              :disabled="isCitiesLoaded"
              item-text="title"
              item-value="title"
              :label="getSuggestedRegion('city')"
              persistent-hint
              outlined
              dense
              clearable
              :error-messages="cityErrors"
              @input="$v.formData.city.$touch()"
              @blur="$v.formData.city.$touch()"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ city_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'city',
                            formData.state.id,
                            onStateChange,
                            city_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6">
            <v-combobox
              v-model="formData.area"
              :search-input.sync="area_text"
              :items="addressServerData.areas"
              :rules="
                validationValueCheck('area')
                  ? [validationValueCheck('area')]
                  : []
              "
              item-text="title"
              item-value="title"
              :label="getSuggestedRegion('area')"
              persistent-hint
              clearable
              outlined
              :disabled="isAreasLoaded"
              dense
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ area_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'area',
                            formData.city.id,
                            onCityChange,
                            area_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              class
              v-model="formData.address"
              label="Address"
              clearable
              outlined
              dense
              :error-messages="addressErrors"
              @input="$v.formData.address.$touch()"
              @blur="$v.formData.address.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <VueTelInputVuetify
              v-model="formData.phone"
              label="Phone"
              type="number"
              clearable
              outlined
              dense
              :inputOptions="{ showDialCode: false, tabindex: 0 }"
              mode="international"
              disabledFetchingCountry
              defaultCountry="AE"
              :error-messages="phoneErrors"
              @input="$v.formData.phone.$touch()"
              @blur="$v.formData.phone.$touch()"
            ></VueTelInputVuetify>
          </div>
        </div>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-10">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="clearFields"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click.prevent="submit()"
          >
            Confirm
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
import {
  SET_CUSTOM_COLLECTION_ADDRESS,
  SET_CUSTOM_DESTINATION_ADDRESS,
  SET_DESTINATION_ADDRESS_TYPE,
  SET_COLLECTION_ADDRESS_TYPE,
} from "@/core/services/store/returns.module";

import {
  handleCityValueChange,
  handleCountryValueChange,
  handleStateValueChange,
} from "@/own/libs/handle-locations";

export default {
  name: "CustomAddressForm",
  mixins: [validationMixin],
  components: { VueTelInputVuetify },
  props: ["pageLoader", "serverData", "addressType"],
  validations() {
    return {
      formData: {
        name: { required },
        address: { required },
        city: { required },
        country: { required },
        phone: {
          required,
          numeric: helpers.regex(
            "numeric",
            /^(?:\+)?(?=(?:.*\d){5})[0-9\s]+$/i
          ),
        },
        state: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    addressServerData: {},
    state_text: "",
    city_text: "",
    area_text: "",
    formData: {
      name: null,
      company: null,
      country: null,
      city: null,
      state: null,
      address: null,
      phone: null,
    },

    updateValueTimer: setTimeout(() => {}, 0),
    locationFetchTriggerTime: 600,
  }),
  methods: {
    updateValue(type) {
      clearTimeout(this.updateValueTimer);
      this.updateValueTimer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, this.locationFetchTriggerTime);
    },
    toggleModal() {
      if (this.dialog) {
        this.clearFields();
      } else {
        const collectionAddress = {
          ...this.$store.getters.getRTRNCustomCollectionAddress,
        };
        const destinationAddress = {
          ...this.$store.getters.getRTRNCustomDestinationAddress,
        };
        if (this.addressType === "collection" && collectionAddress) {
          this.formData = collectionAddress;
          this.fetchAddressDetails();
        } else if (this.addressType === "destination" && destinationAddress) {
          this.formData = destinationAddress;
          this.fetchAddressDetails();
        }
      }
      this.dialog = !this.dialog;
      this.pageLoader(false);
    },
    fetchAddressDetails() {
      // const actionType = this.$store.getters.getRTRNActionType;
      this.addressServerData["countries"] = this.serverData?.countries;
      this.onCountryChange(this.formData.country, true);
    },
    clearFields() {
      this.$v.$reset();
      this.addressServerData = {};
      this.state_text = "";
      this.city_text = "";
      this.area_text = "";
      this.formData = {
        name: null,
        company: null,
        country: null,
        city: null,
        state: null,
        address: null,
        phone: null,
      };
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    createNewItem(type, id, func, value = "") {
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    checkErrors() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
    },
    validationValueCheck(type) {
      let validation = null;
      switch (type) {
        case "state":
          if (this.state_text == "") {
            return;
          }
          try {
            if (
              !this.addressServerData?.states?.find(
                (state) => state.title === this.state_text
              )
            ) {
              validation = "Please, select valid state!";
              // this.addressServerData.cities = null;
              // this.addressServerData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;
        case "city":
          if (this.city_text == "") {
            return;
          }
          try {
            if (
              !this.addressServerData?.cities?.find(
                (city) => city.title === this.city_text
              )
            ) {
              validation = "Please, select valid city!";
              // this.addressServerData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;
        case "area":
          try {
            if (
              !this.addressServerData.areas.find(
                (area) => area.title === this.area_text
              )
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            validation = "Please, select valid city!";
          }
          break;
      }
      return validation;
    },
    async onCountryChange(val, safe = true) {
      this.pageLoader(true);
      try {
        await handleCountryValueChange.call(
          this,
          val,
          safe,
          "formData",
          "addressServerData",
          this.serverData.return_management.organization_id
        );
        await this.updateValue("state");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onStateChange(value, safe = true, is_id = false) {
      this.pageLoader(true);
      try {
        await handleStateValueChange.call(
          this,
          value,
          safe,
          is_id,
          "formData",
          "addressServerData",
          this.serverData.return_management.organization_id
        );
        await this.updateValue("city");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onCityChange(value, safe = true, is_id = false) {
      this.pageLoader(true);
      try {
        await handleCityValueChange.call(
          this,
          value,
          safe,
          is_id,
          "formData",
          "addressServerData",
          this.serverData.return_management.organization_id
        );
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onBlurValueCheck(type) {
      switch (type) {
        case "state": {
          if (this.state_text == "") {
            return;
          }

          const item = this.addressServerData?.states?.find(
            (state) =>
              state.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.state_text?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
          );

          if (!item) {
            this.addressServerData.cities = null;
            this.addressServerData.areas = null;
          } else {
            if (item.title != this.state_text) {
              this.state_text = item.title;
            } else {
              await this.onStateChange(item.title);
              this.$refs.stateCombo.blur();
            }
          }

          break;
        }
        case "city": {
          if (this.city_text == "") {
            return;
          }

          const item = this.addressServerData?.cities?.find(
            (city) =>
              city.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.city_text?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
          );
          if (!item) {
            this.addressServerData.areas = null;
          } else {
            if (item.title != this.city_text) {
              this.city_text = item.title;
            } else {
              await this.onCityChange(item.title);
              this.$refs.cityCombo.blur();
            }
          }

          break;
        }
      }
    },
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        if (this.addressType === "collection") {
          this.$store.commit(SET_CUSTOM_COLLECTION_ADDRESS, this.formData);
          this.$store.commit(SET_COLLECTION_ADDRESS_TYPE, 4);
        } else if (this.addressType === "destination") {
          this.$store.commit(SET_CUSTOM_DESTINATION_ADDRESS, this.formData);
          this.$store.commit(SET_DESTINATION_ADDRESS_TYPE, 4);
        }
        this.toggleModal();
      }
    },
    getSuggestedRegion(type) {
      const typeCapitalize = type.charAt(0).toUpperCase() + type.slice(1);

      if (
        this.serverData.return_management &&
        this.serverData.return_management[`c_${type}`]
      ) {
        return `${typeCapitalize} (Suggested: ${
          this.serverData.return_management[`c_${type}`]
        })`;
      } else {
        return typeCapitalize;
      }
    },
  },
  computed: {
    nameErrors: function () {
      return this.handleFormValidation("name", this, "formData");
    },
    addressErrors: function () {
      return this.handleFormValidation("address", this, "formData");
    },
    cityErrors: function () {
      return this.handleFormValidation("city", this, "formData");
    },
    phoneErrors: function () {
      return this.handleFormValidation("phone", this, "formData");
    },
    mobileErrors: function () {
      return this.handleFormValidation("mobile", this, "formData");
    },
    countryErrors: function () {
      return this.handleFormValidation("country", this, "formData");
    },
    stateErrors: function () {
      return this.handleFormValidation("state", this, "formData");
    },
    isStatesLoaded: function () {
      return !this.addressServerData.states;
    },
    isCitiesLoaded: function () {
      return !this.addressServerData.cities;
    },
    isAreasLoaded: function () {
      return !this.addressServerData.areas;
    },
  },
  watch: {
    state_text: {
      handler(value, oldValue) {
        if (!this.addressServerData.countries) {
          this.addressServerData.states = null;
          this.addressServerData.cities = null;
          this.addressServerData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("state");
      },
      deep: true,
    },
    city_text: {
      handler(value, oldValue) {
        if (!this.addressServerData.states) {
          this.addressServerData.cities = null;
          this.addressServerData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("city");
      },
      deep: true,
    },
  },
};
</script>
