<template>
  <!--  <div class="row mr-3 mt-0">-->
  <div :class="'w-' + width + ' mr-3'">
    <v-autocomplete
      :value="selectedWarehouse"
      @input="setWarehouse"
      :label="$t('warehouse')"
      :items="$store.getters.getUserWareHouses"
      item-text="text"
      item-value="index"
      dense
      outlined
      clearable
      hide-details
      hide-spin-buttons
    ></v-autocomplete>
  </div>
  <!--  </div>-->
</template>
<!--        v-model="$store.getters.getSelectedWarehouse"-->

<script>
import { SET_WAREHOUSE } from "@/core/services/store/auth.module";

export default {
  name: "WarehouseSelect",
  props: { width: { type: String, default: "100" } },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    setWarehouse(value) {
      this.$store.commit(SET_WAREHOUSE, value);
    },
  },
};
</script>
