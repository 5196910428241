<template>
  <div v-if="item.type === 'text'">
    <pop-up-text-group
      :item="item"
      :order_id="order_id"
      :refresher="refresher"
      :toggleDetail="toggleDetail"
    />
  </div>
  <div v-else-if="item.type === 'table'">
    <pop-up-table :item="item" />
  </div>
  <div v-else-if="item.type === 'datatable'">
    <PopUpDataTable :item="item" />
  </div>
  <div v-else-if="item.type === 'tab_datatable_with_search'">
    <PopUpTabDataTableWithSearch :item="item" />
  </div>
  <div v-else-if="item.type === 'timeline'">
    <pop-up-time-line :item="item" />
  </div>
  <div v-else-if="item.type === 'docs'">
    <PopUpDocs :item="item" />
  </div>
  <div v-else-if="item.type === 'dynamic_tab_datatable_with_search'">
    <PopUpDynamicTabDataTableWithSearch
      :item="item"
      :updateDetailsData="refresher"
      :order_id="order_id"
      ref="dataFetchable"
    />
  </div>
  <div
    v-else-if="
      item.type === 'datatable_with_search' ||
      item.type === 'datatable_with_search_and_actions'
    "
  >
    <PopUpDataTableWithSearch
      ref="dataFetchable"
      :itemprop="item"
      :updateDetailsData="refresher"
      :order_id="order_id"
    />
  </div>
</template>

<script>
import PopUpTable from "@/own/components/fulfillment/asn/popup/PopUpTable.vue";
import PopUpDataTable from "@/own/components/fulfillment/asn/popup/PopUpDataTable.vue";
import PopUpTabDataTableWithSearch from "@/own/components/fulfillment/asn/popup/PopUpTabDataTableWithSearch.vue";
import PopUpTextGroup from "@/own/components/fulfillment/asn/popup/PopUpTextGroup.vue";
import PopUpDocs from "@/own/components/fulfillment/asn/popup/PopUpDocs.vue";
import PopUpTimeLine from "@/own/components/fulfillment/orders/popup/PopUpTimeLine.vue";
import PopUpDynamicTabDataTableWithSearch from "@/own/components/datatable/popup/PopUpDynamicTabDataTableWithSearch.vue";
import PopUpDataTableWithSearch from "@/own/components/datatable/popup/PopUpDataTableWithSearch.vue";

export default {
  name: "PopUpSelector",
  props: ["item", "order_id", "refresher", "toggleDetail"],
  components: {
    PopUpDataTableWithSearch,
    PopUpTable,
    PopUpTextGroup,
    PopUpTimeLine,
    PopUpDocs,
    PopUpDataTable,
    PopUpDynamicTabDataTableWithSearch,
    PopUpTabDataTableWithSearch,
  },
  methods: {
    fetchData() {
      try {
        this.$refs?.dataFetchable?.getDataFromApi();
      } catch {
        return;
      }
    },
  },
};
</script>

<style></style>
