<template>
  <div>
    <v-menu
      content-class="m-0 p-0"
      transition="scale-transition"
      offset-y
      bottom
      nudge-bottom="5"
      :left="!$vuetify.rtl"
      :right="$vuetify.rtl"
      rounded
      max-height="75%"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-on="on"
          v-bind="attrs"
          class="poppins btn btn--export-filter px-5"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-dns</v-icon>
          </span>
          <span>Actions</span>
          <span class="svg-icon mr-0 ml-2">
            <v-icon size="18">mdi-chevron-down</v-icon>
          </span>
        </button>
      </template>
      <div class="py-1 w-175px bg-white px-3">
        <div
          class="drop-item poppins px-3 rounded-lg cursor-pointer"
          v-for="(action, index) in types"
          :key="index"
          @click="() => handleClick(action.url)"
        >
          <div
            class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
            :class="[
              { 'mt-2': index === 0 },
              { 'mb-2': index === types.length - 1 },
            ]"
          >
            {{ action.name }}
          </div>
        </div>
      </div>
    </v-menu>
    <AmazonIntegration
      ref="amazonIntegration"
      :submit="actionRequest"
      :items="items"
    />
    <SyncInventory ref="syncInventory" :submit="actionRequest" :items="items" />
    <!--    <component-->
    <!--      ref="reassignCarreir"-->
    <!--      v-bind:is="reassignCarrierModal"-->
    <!--      @onSelectCarrier="onSelectCarrier"-->
    <!--    ></component>-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
// import ReassignCarrierModal from "@/own/components/fulfillment/orders/ReassignCarrierModal.vue";
// import swalEdited from "@/core/services/AlertServices/SwalEdited";
import AmazonIntegration from "@/own/components/fulfillment/skus/AmazonIntegration.vue";
import SyncInventory from "@/own/components/fulfillment/skus/SyncInventory.vue";

export default {
  name: "ActionsButton",
  props: ["items", "types", "refresher"],
  components: { AmazonIntegration, SyncInventory },
  // data: () => ({
  //   reassignCarrierModal: ReassignCarrierModal,
  // }),
  methods: {
    handleClick(url) {
      if (this.items.length > 0) {
        // if (url === "order_reassign_carrier_bulk") {
        //   this.$refs.reassignCarreir.toggleModal();
        // } else {
        // const isConfirmationRequired = this.permissions?.find(
        //   (permission) => permission.name === url
        // )?.is_confirmation_required;

        // if (isConfirmationRequired) {
        //   Swal.fire({
        //     title: "Are you sure?",
        //     text: "You won't be able to revert this!",
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Yes!",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       this.actionRequest(url);
        //     }
        //   });
        // } else {
        if (url === "enable_for_amazon") {
          this.$refs.amazonIntegration.toggleModal();
          return;
        } else if (
          url === "sync_inventory" &&
          this.$store.getters.getSKUSAmazonOrganizations
        ) {
          this.$refs.syncInventory.toggleModal();
          return;
        }
        this.actionRequest(url);
        // }
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "OK",
          cancelButtonColor: "#FFA800",
        });
      }
    },
    actionRequest(url) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let data =
      //   url == "reset_replenishment"
      //     ? { ids: this.items.map((it) => it.id.id) }
      //     : { ids: this.items.map((it) => it.systemId) };
      let data = { ids: this.items.map((it) => it.id) };

      ApiService.post(`/fulfillment/skus/${url}`, data)
        .then(({ data }) => {
          // swalEdited();
          try {
            Swal.fire({
              title: "Completed",
              html: data?.messages?.length
                ? `<div>${data?.messages
                    ?.map((mes) => `<div>${mes}</div>`)
                    .join("")}</div>`
                : "Record modified successfully",
              icon: "success",
              // confirmButtonText: "OK",
              // showConfirmButton: false,
              // confirmButtonColor:'#e8fff3',
              // showCancelButton: true,
              // cancelButtonText: "OK",
            });
          } catch {
            Swal.fire({
              title: "Failed",
              html: "Opps..Something went wrong.",
              icon: "error",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
          }

          this.refresher();
        })
        .catch(() => {
          // Swal.fire({
          //   title: "Error",
          //   html: `<div>${data.messages.map((mes)=> `<div>${mes}</div>`).join('')}</div>`,
          //   icon: "error",
          //   showConfirmButton: false,
          //   showCancelButton: true,
          //   cancelButtonText: "OK",
          // });
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  // computed: {
  //   permissions: function() {
  //     return this.$store.getters.getSKUSTablePermissions;
  //   },
  // },
};
</script>

<style scoped>
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}
.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
