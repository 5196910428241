<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" max-width="1000">
      <template v-slot:activator="{ on, attrs }">
        <span class="symbol symbol-35 symbol-light-success">
          <img
            v-bind="attrs"
            v-on="on"
            v-if="item.url"
            alt="img"
            :src="item.url"
          />
          <span
            v-else
            style="border-radius: 50% !important"
            class="symbol-label font-size-h5"
            :class="getRandomColors()"
          >
            {{ item.first_letter.toUpperCase() }}
          </span>
        </span>
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <!-- <v-toolbar color="primary" dark>Image</v-toolbar> -->
          <v-card-text class="pa-4 text-center">
            <img alt="img" class="h-100" :src="item.url" />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="red" dark @click="dialog.value = false">{{
              $t("close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
/**
 * shows image and pop up a modal on click
 * api name =
 */
export default {
  name: "ShowStatus",
  props: ["item"],
  data: () => ({
    bgColors: [
      "bg-light-success text-success",
      "bg-light-danger text-danger",
      "bg-light-info text-info",
      "bg-light-primary text-primary",
      "bg-light-warning text-warning",
      "bg-light-dark text-dark",
    ],
  }),
  methods: {
    getRandomColors: function () {
      return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
    },
  },
};
</script>
