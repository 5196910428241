<template>
  <!--  v-if="table_permissions"-->
  <div v-if="table_permissions.length > 0" class="custom-content-height">
    <!-- slot top -->
    <div v-if="$slots.top">
      <slot name="top"></slot>
    </div>
    <!-- slot top end -->
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(56)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="order_id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="40"
      @input="itemSelected"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>
          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="bulkRemoveOption && selected.length > 0"
            >
              <!-- <button
                @click.prevent="resetSelectedItems"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-refresh</v-icon>
                </span>
                Reset
              </button> -->
            </div>
            <div
              v-if="selectedPicking.length"
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
            >
              <button
                @click="toggleAssignmentModal"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-account-arrow-left</v-icon>
                </span>
                Assign
              </button>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="handleFilterSidebarClick"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="permissionChecker('export') && exportHeaders"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
      </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <!-- column -->
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>
      <!-- column end -->
      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>

          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissionChecker('cancel_assignment')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.cancel_assignment"
                :class="
                  !item.extra_data.detail.actions.cancel_assignment
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => cancelAssignment(item)"
                text
              >
                Cancel assignment
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <component
      v-if="permissionChecker('update')"
      v-bind:is="assignPicking"
      :pageLoader="pageLoad"
      :refresher="updateTableContent"
      ref="assignPicking"
    ></component>
    <div v-if="$slots.bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import Pagination from "@/own/components/pagination/Pagination.vue";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";

import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { SET_SELECTED_PICKING } from "@/core/services/store/pickingAssignment.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  props: {
    id_field_name: {
      type: String,
      default: "id",
    },
    /** @type {{UPDATE_TABLE_STRUCTURE:string,UPDATE_TABLE_DATA:string,SET_TABLE_SORTBY:string,SET_TABLE_FILTER:string,SET_TABLE_PERPAGE:string,SET_TABLE_PAGE:string,EXPORT_TABLE_DATA:string}} */
    table_state: {
      type: Object,
      required: true,
    },
    /** @type {{getTableFilters:string,getTableState:string,getTableData:string,getTableHeaders:string,getTableProperties:string,getTableExportUrl:string,getTablePermissions:string}} */
    store_names: {
      type: Object,
      required: true,
    },
    /** @type {{activate: string, delete: string, status: string}} */
    assignPicking: {},
    urls: {
      type: Object,
    },
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
    bulkRemoveOption: {
      type: Boolean,
      default: false,
      required: false,
    },
    isFilterRequiredForExport: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    ComponentSelector,
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
  }),
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    table_permissions: function () {
      /** @type {Array<string>} */
      let permissions = [];
      try {
        if (this.$store.getters[this.store_names.getTablePermissions]) {
          permissions = this.$store.getters[
            this.store_names.getTablePermissions
          ].map(function (perm) {
            return perm.name;
          });
        }
      } catch (error) {
        // this.$swal("Can not load table permissions!");
      }
      return permissions;
    },
    table_properties: function () {
      /** @type {{title:string, description: string}} */
      let properties = null;
      try {
        properties = this.$store.getters[this.store_names.getTableProperties];
      } catch (error) {
        // this.$swal("Can not load table properties!");
      }
      return properties;
    },
    orderBy: {
      get: function () {
        /** @type {string} */
        let sortBy = this.$store.getters[this.store_names.getTableState].sortBy;
        return sortBy;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters[this.store_names.getTableState],
          warehouse_id: this.selectedWarehouse,
        };
        if (newValue) {
          this.$store.commit(this.table_state.SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, state);
        } else {
          this.$store.commit(this.table_state.SET_TABLE_SORTBY, "");
          this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, state);
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (
          this.$store.getters[this.store_names.getTableState].sortOrder ===
          "asc"
        ) {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters[this.store_names.getTableState],
          warehouse_id: this.selectedWarehouse,
        };
        if (newValue === true) {
          this.$store.commit(this.table_state.SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(this.table_state.SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, state);
      },
    },
    headers: function () {
      /**
       * @type {Array<
          {
              value: string,
              text: string,
              type: string,
              sortable: string,
              exportable: string,
              visible: string,
              mobile_visible: string,
              align: string,
              itemClass: string,
              width: string,
              class: string,
              export_order: integer,
            }>
                }
        */
      let headers = [];
      try {
        if (this.$vuetify.breakpoint.smAndDown) {
          headers = this.$store.getters[
            this.store_names.getTableHeaders
          ].filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          headers = this.$store.getters[
            this.store_names.getTableHeaders
          ].filter((col) => col.visible === true);
        }
        headers.forEach((header) => {
          header.class =
            "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
          header.itemClass = "second-text";
          header.width = undefined;
          if (header.value === "action") {
            header.class =
              "pr-10 text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
          }
        });
      } catch {
        // headers = this.$store.getters[this.store_names.getTableHeaders].filter(
        //   (col) => col.visible === true
        // );
      }

      return headers;
    },
    exportHeaders: function () {
      /** @type {Array<{order:string,text:string,value:string}>} */
      let exportHeaders = [];
      try {
        exportHeaders = this.$store.getters[
          this.store_names.getTablePermissions
        ].filter((col) => col.name === "export")[0].columns;
      } catch (error) {
        exportHeaders = [];
      }
      return exportHeaders;
    },
    items: function () {
      /**
       * @type {Array<{}>}
       * @example [{"id":891,"email":"rafi.ogchy@gmail.com","extra_data":null}]
       */
      let items = [];
      try {
        items = this.$store.getters[this.store_names.getTableData].data;
      } catch {
        items = [];
      }
      return items;
    },
    //-------------------pagination----------------------
    getitemPerPage: function () {
      /** @type {number} */
      let itemPerPage =
        this.$store.getters[this.store_names.getTableState].per_page;
      return itemPerPage;
    },
    getpageNumber: function () {
      /** @type {number} */
      let pageNumber = this.$store.getters[this.store_names.getTableState].page;
      return pageNumber;
    },
    totalItems: function () {
      /** @type {number} */
      let totalItems = this.$store.getters[this.store_names.getTableData].total;
      return totalItems;
    },
    pageCount: function () {
      /** @type {number} */
      let pageCount =
        this.$store.getters[this.store_names.getTableData].last_page;
      return pageCount;
    },
    itemPerPage: function () {
      /** @type {number} */
      let itemPerPage =
        this.$store.getters[this.store_names.getTableState].per_page;
      return itemPerPage;
    },
    //-------------------filter----------------------
    filters: function () {
      /** @type {Array<{name: string, title: string, type: string, related_columns: string[]}>} */
      let filters = [];
      try {
        filters = this.$store.getters[this.store_names.getTableFilters];
      } catch {
        filters = [];
      }
      return filters;
    },
    selectedPicking() {
      return this.$store.getters.getPICKINGASSIGNMENTTableSelectedPicking;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    handleExportSidebarClick() {
      this.$refs.exportSidebar.$data.exportSidebar = true;
    },
    handleFilterSidebarClick() {
      this.$refs.filterSidebar.$data.filterSidebar = true;
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    //\\
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    permissionChecker(permType) {
      return this.table_permissions.includes(permType);
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      this.pageLoad(true);
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      };
      await this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.selected = [];
        });
      this.$forceUpdate();
      this.pageLoad(false);
    },

    toggleAssignmentModal() {
      this.$store.commit(SET_ITEM_FOR_ACTION, null);
      const IS_ANY_ORDRER_PARTIALLY_ALLOCATED = this.selected.some(
        (item) => item.order_status === "Partially Allocated"
      );
      if (IS_ANY_ORDRER_PARTIALLY_ALLOCATED) {
        Swal.fire({
          title: "Warning",
          text: "Order partially allocated. Do you want to continue ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#8950FC",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$refs.assignPicking.toggleModal();
          }
        });
      } else {
        this.$refs.assignPicking.toggleModal();
      }
    },
    //------------------pagination-------------------
    setitemPerPage(val) {
      this.$store.commit(this.table_state.SET_TABLE_PERPAGE, val);
    },
    setpageNumber(val) {
      this.$store.commit(this.table_state.SET_TABLE_PAGE, val);
    },
    //---------------------export-------------------------
    isAnyFilterSelected() {
      return this.$store.getters[this.store_names.getTableState].filters
        .length > 0
        ? true
        : false;
    },

    //--------------------filter---------------------
    setTableFiltersValues(val) {
      this.$store.commit(this.table_state.SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      };
      state.page = 1;
      this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(this.table_state.SET_TABLE_PAGE, 1);

          // this.$store.commit(SET_PAGE_LOADING, false);
          this.$nextTick(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(this.table_state.SET_TABLE_FILTER, []);
      let state = {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      };
      this.$store
        .dispatch(this.table_state.UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    itemSelected(item) {
      this.$store.commit(SET_SELECTED_PICKING, item);
    },
    cancelAssignment(item) {
      this.pageLoad(true);

      const { order_id } = item;

      ApiService.post(
        "/warehouse_management/picking_assignment/cancel_assignment",
        { order_id: order_id }
      )
        .then(() => {
          Swal.fire("Success!", "Picking task unassigned.", "success");
          this.updateTableContent();
        })
        .finally(() => {
          this.pageLoad(true);
        });
    },
  },
  beforeDestroy() {
    this.$store.commit(this.table_state.SET_TABLE_FILTER, []);
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
