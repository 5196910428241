<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1400px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Create Order</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <!-- <v-tabs
          v-model="tab"
          background-color="transparent"
          color="basil"
          grow
          show-arrows
        >
          <v-tab v-for="item in categories" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs> -->
        <v-stepper
          v-model="tab"
          non-linear
          outlined
          rounded="4"
          flat
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              editable
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="">
            <!-- categories: ["Finance", "Cosignee", "Sku", "Address", "Order Details"], -->
            <v-stepper-content step="0" key="Order Details" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.client_reference"
                    label="Client reference"
                    clearable
                    outlined
                    dense
                    :error-messages="client_referenceErrors"
                    @input="$v.order_details.client_reference.$touch()"
                    @blur="$v.order_details.client_reference.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4" v-if="!isClient">
                  <v-autocomplete
                    v-model="order_details.customer_id"
                    label="Customer"
                    item-text="text"
                    item-value="index"
                    :items="serverData.customers"
                    clearable
                    outlined
                    dense
                    :error-messages="customer_idErrors"
                    @input="$v.order_details.customer_id.$touch()"
                    @blur="$v.order_details.customer_id.$touch()"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.warehouse"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.order_status"
                    label="Order status"
                    item-text="text"
                    item-value="text"
                    :items="serverData.orderStatuses"
                    clearable
                    outlined
                    dense
                    :error-messages="order_statusErrors"
                    @input="$v.order_details.order_status.$touch()"
                    @blur="$v.order_details.order_status.$touch()"
                  ></v-autocomplete>
                </div> -->
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.order_type"
                    label="Order type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.orderTypes"
                    clearable
                    outlined
                    dense
                    :error-messages="order_typeErrors"
                    @input="$v.order_details.order_type.$touch()"
                    @blur="$v.order_details.order_type.$touch()"
                  ></v-autocomplete>
                </div> -->
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.return_type"
                    label="Return type"
                    item-text="text"
                    item-value="text"
                    :items="serverData.returnTypes"
                    clearable
                    outlined
                    dense
                    :error-messages="return_typeErrors"
                    @input="$v.order_details.return_type.$touch()"
                    @blur="$v.order_details.return_type.$touch()"
                  ></v-autocomplete>
                </div> -->
                <!-- <div class="col-12 col-sm-6">
                  <v-autocomplete
                    v-model="order_details.carrier"
                    label="Carrier"
                    item-text="text"
                    item-value="text"
                    :items="serverData.carriers"
                    clearable
                    outlined
                    dense
                    :error-messages="carrierErrors"
                    @input="$v.order_details.carrier.$touch()"
                    @blur="$v.order_details.carrier.$touch()"
                  ></v-autocomplete>
                </div> -->
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.cod_amount"
                    label="Cod Amount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () => validateMinValue('cod_amount', 0, 'order_details')
                    "
                    :error-messages="cod_amountErrors"
                    @input="$v.order_details.cod_amount.$touch()"
                    @blur="$v.order_details.cod_amount.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.awb"
                    label="AWB"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>

                <div class="col-12">
                  <v-text-field
                    class
                    v-model="order_details.note"
                    label="Comment"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.payment_type"
                    label="Payment Type"
                    item-text="text"
                    item-value="text"
                    :items="serverData.paymentTypes"
                    clearable
                    outlined
                    dense
                    :error-messages="payment_typeErrors"
                    @input="$v.order_details.payment_type.$touch()"
                    @blur="$v.order_details.payment_type.$touch()"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.currency"
                    label="Currency"
                    item-text="text"
                    item-value="text"
                    :items="serverData.currencies"
                    clearable
                    outlined
                    dense
                    :error-messages="currencyErrors"
                    @input="$v.order_details.currency.$touch()"
                    @blur="$v.order_details.currency.$touch()"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.total_price"
                    type="Number"
                    @change="
                      () => validateMinValue('total_price', 0, 'order_details')
                    "
                    label="Price"
                    clearable
                    outlined
                    dense
                    :error-messages="total_priceErrors"
                    @input="$v.order_details.total_price.$touch()"
                    @blur="$v.order_details.total_price.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.customs_value"
                    label="Customs amount"
                    type="Number"
                    @change="
                      () =>
                        validateMinValue('customs_value', 0, 'order_details')
                    "
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.subtotal"
                    type="Number"
                    @change="
                      () => validateMinValue('subtotal', 0, 'order_details')
                    "
                    label="Subtotal amount"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.total_discount"
                    type="Number"
                    label="Discount amount"
                    @change="
                      () =>
                        validateMinValue('total_discount', 0, 'order_details')
                    "
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.total_tax"
                    type="Number"
                    @change="
                      () => validateMinValue('total_tax', 0, 'order_details')
                    "
                    label="Tax amount"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.tax_percentage"
                    type="number"
                    @change="
                      () =>
                        validateMinValue('tax_percentage', 0, 'order_details')
                    "
                    :min="0"
                    label="Total percentage"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.total_declared_amount"
                    @change="
                      () =>
                        validateMinValue(
                          'total_declared_amount',
                          0,
                          'order_details'
                        )
                    "
                    type="number"
                    :min="0"
                    label="Total declared amount"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.cod_amount"
                    type="number"
                    @change="
                      () => validateMinValue('cod_amount', 0, 'order_details')
                    "
                    :min="0"
                    label="COD amount"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div> -->
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.shipping_price"
                    type="number"
                    @change="
                      () =>
                        validateMinValue('shipping_price', 0, 'order_details')
                    "
                    :min="0"
                    label="Shipping price"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>

                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="parcel.total_weight"
                    type="Number"
                    label="Total weight"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="parcel.total_volume"
                    type="Number"
                    label="Total volume"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="parcel.box_number"
                    type="Number"
                    min="1"
                    label="Box number"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div> -->
                <!-- <div class="col-12 col-sm-6 col-md-4">

                  <v-autocomplete
                    v-model="order_details.shipping_method"
                    label="Shipping method"
                    item-text="text"
                    item-value="index"
                    :items="serverData.shipping_methods"
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </div> -->
                <!-- <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="order_details.delivery_duty_type"
                    label="Delivery duty type"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div> -->
              </div>
            </v-stepper-content>
            <v-stepper-content step="1" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.company"
                    label="Company"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.name"
                    label="Name"
                    clearable
                    outlined
                    dense
                    :error-messages="nameErrors"
                    @input="$v.consignee.name.$touch()"
                    @blur="$v.consignee.name.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.surname"
                    label="Surname"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="consignee.phone"
                    label="Phone"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="() => validateMinValue('phone', 0, 'consignee')"
                    :error-messages="phoneErrors"
                    @input="$v.consignee.phone.$touch()"
                    @blur="$v.consignee.phone.$touch()"
                  ></v-text-field>
                </div>
                <!-- <div class="col-12 col-sm-6">
                  <VueTelInputVuetify
                    v-model="consignee.mobile"
                    label="Mobile"
                    type="Number"
                    clearable
                    outlined
                    dense
                    :inputOptions="{ showDialCode: false, tabindex: 0 }"
                    mode="international"
                    disabledFetchingCountry
                    defaultCountry="AE"
                    :error-messages="mobileErrors"
                    @input="$v.consignee.mobile.$touch()"
                    @blur="$v.consignee.mobile.$touch()"
                  ></VueTelInputVuetify>
                </div> -->
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="consignee.email"
                    label="Email"
                    clearable
                    outlined
                    dense
                    :error-messages="emailErrors"
                    @input="$v.consignee.email.$touch()"
                    @blur="$v.consignee.email.$touch()"
                  ></v-text-field>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <v-autocomplete
                    @change="onCountryChange"
                    v-model="$v.shipping_address.country.$model"
                    label="Country"
                    item-text="text"
                    item-value="index"
                    :items="serverData.countries"
                    clearable
                    outlined
                    dense
                    :error-messages="countryErrors"
                    @input="$v.shipping_address.country.$touch()"
                    @blur="$v.shipping_address.country.$touch()"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    v-model="$v.shipping_address.state.$model"
                    :search-input.sync="state_text"
                    :items="serverData.states"
                    :disabled="isStatesLoaded"
                    :rules="[validationValueCheck('state')]"
                    item-text="title"
                    item-value="title"
                    label="State"
                    persistent-hint
                    outlined
                    dense
                    :error-messages="stateErrors"
                    @input="$v.shipping_address.state.$touch()"
                    @blur="$v.shipping_address.state.$touch()"
                    @change="onStateChange"
                    @keyup="updateValue('state')"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{
                              state_text
                            }}</strong
                            >".
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                createNewItem(
                                  'state',
                                  shipping_address.country,
                                  onCountryChange,
                                  state_text
                                )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    v-model="$v.shipping_address.city.$model"
                    :search-input.sync="city_text"
                    :items="serverData.cities"
                    :disabled="isCitiesLoaded"
                    :rules="[validationValueCheck('city')]"
                    item-text="title"
                    item-value="title"
                    label="City"
                    persistent-hint
                    outlined
                    dense
                    :error-messages="cityErrors"
                    @input="$v.shipping_address.city.$touch()"
                    @blur="$v.shipping_address.city.$touch()"
                    @change="onCityChange"
                    @keyup="updateValue('city')"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ city_text }}</strong
                            >".
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                createNewItem(
                                  'city',
                                  shipping_address.state.id,
                                  onStateChange,
                                  city_text
                                )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    :disabled="isAreasLoaded"
                    v-model="shipping_address.area"
                    :search-input.sync="area_text"
                    :items="serverData.areas"
                    :rules="[validationValueCheck('area')]"
                    item-text="title"
                    item-value="title"
                    label="Area"
                    persistent-hint
                    outlined
                    dense
                    @keyup="updateValue('area')"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ area_text }}</strong
                            >".
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                createNewItem(
                                  'area',
                                  shipping_address.city.id,
                                  onCityChange,
                                  area_text
                                )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.shipping_address.address_1.$model"
                    label="Address 1"
                    clearable
                    outlined
                    dense
                    :error-messages="address_1Errors"
                    @input="$v.shipping_address.address_1.$touch()"
                    @blur="$v.shipping_address.address_1.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="shipping_address.address_2"
                    label="Address 2"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-menu
                    v-model="required_delivery_timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="shipping_address.required_delivery_time"
                        label="Required delivery time"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="shipping_address.required_delivery_time"
                      @input="required_delivery_timeMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="shipping_address.postal_code"
                    label="Postal code"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12">
                  <sku-datatable
                    :setSku="setSkus"
                    :skus="formData.skus"
                    :skuItem="[]"
                    :clientId="userId"
                    :is_unit_price_required="get_is_unit_price_required"
                  ></sku-datatable>
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <CreateLocation
          :location-id="getLocationId"
          :location-type="getLocationType"
          :location-function="getLocationFunc"
          :locationSetValue="setTextValue"
          :initValue="getLocationInitValue"
          ref="location"
        ></CreateLocation>

        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div class="justify-content-between align-center d-flex col-12">
            <v-btn
              v-if="backState"
              class="order-1 order-sm-1"
              @click="nextStep('prev')"
              color="primary"
              dark
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <div v-else class="order-1 order-sm-1"></div>
            <div
              class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
              :class="{ invisible: nextState }"
            >
              <button
                type="submit"
                class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                @click="toggleModal"
              >
                Close
              </button>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submitEditForm"
              >
                Create Order
              </button>
            </div>
            <v-btn
              v-if="nextState"
              class="order-3 order-sm-3"
              @click="nextStep('next')"
              color="primary"
              dark
              ><v-icon>mdi-arrow-right</v-icon></v-btn
            >
            <div v-else class="order-3 order-sm-3"></div>
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/fulfillment/orders/B2C/skuContainerForAdd/SkuDatatable";
import { validationMixin } from "vuelidate";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import CreateLocation from "./CreateLocation.vue";
// import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
// const greaterThanZero = (value) => value > 0;
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "AddItem",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    SkuDatatable,
    CreateLocation,
    // VueTelInputVuetify,
  },

  // props: ["itemForAction", "refresher", "pageLoader"],

  props: {
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
  },

  validations() {
    return {
      order_details: {
        customer_id: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
        // order_type: { required },
        // order_status: { required },
        // carrier: { required },
        client_reference: { required },
        // return_type: {
        //   required: requiredIf(function () {
        //     return this.order_details.order_type === "RVP";
        //   }),
        // },
        // note: {
        //   required: requiredIf(
        //     () =>
        //       this.order_details.order_type === "XDOCK" ||
        //       this.order_details.order_type === "BOX" ||
        //       this.order_details.order_type === "RT"
        //   ),
        // },
        payment_type: { required },
        currency: { required },
        total_price: { required },
        cod_amount: {
          required: requiredIf(() => this.order_details.payment_type === "COD"),
        },
      },

      shipping_address: {
        country: { required },
        state: { required },
        city: { required },
        address_1: { required },
      },
      consignee: {
        name: { required },
        phone: { required },
        // mobile: { required },
        email: { required, email },
      },

      // parcel: {
      // total_weight: {
      //   required: requiredIf(function () {
      //     return (
      //       this.order_details.order_type === "XDOCK" ||
      //       this.order_details.order_type === "BOX"
      //     );
      //   }),
      // },
      // total_volume: {
      //   required: requiredIf(function () {
      //     return (
      //       this.order_details.order_type === "XDOCK" ||
      //       this.order_details.order_type === "BOX"
      //     );
      //   }),
      // },
      // box_number: {
      //   required: requiredIf(function () {
      //     return (
      //       this.order_details.order_type === "XDOCK" ||
      //       this.order_details.order_type === "BOX"
      //     );
      //   }),
      // },
      // },
    };
  },
  data: () => ({
    tabValidationState: [true, true, true, true, true, true],
    required_delivery_timeMenu: false,

    country_text: null,
    state_text: null,
    city_text: null,
    area_text: null,

    location_initValue: null,

    //shipping_address
    shipping_address: {
      country: null,
      state: null,
      city: null,
      area: null,
      address_1: null,
      address_2: null,
      postal_code: null,
    },
    // consignee
    consignee: {
      company: null,
      name: null,
      surname: null,
      phone: null,
      mobile: null,
      email: null,
    },
    // parcel
    // parcel: {
    //   total_weight: null,
    //   total_volume: null,
    //   box_number: null,
    // },
    order_details: {
      awb: null,
      note: null,
      debug: false,
      customer_id: null,
      // order_type: null,
      // return_type: null,
      order_status: null,
      carrier: null,
      client_reference: null,
      warehouse: null,
      payment_type: null,
      currency: null,
      total_price: null,
      customs_value: null,
      subtotal: null,
      total_discount: null,
      total_tax: null,
      // delivery_duty_type: null,
      required_delivery_time: null,
      // shipping_method: null,
      cod_amount: null,
    },
    finance: {},
    formData: {
      skus: [],
    },

    dialog: false,
    serverData: null,
    tab: 0,
    categories: [
      {
        name: "Order Details",
        valid: true,
        rules: (el) => {
          try {
            return (
              el[`$v`][`order_details`].$invalid || el[`$v`][`parcel`].$invalid
            );
          } catch {
            return true;
          }
        },
      },
      {
        name: "Consignee Details",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`consignee`].$invalid;
          } catch (error) {
            return true;
          }
        },
      },
      {
        name: "Address",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`shipping_address`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "Items",
        valid: true,
        rules: (el) => {
          try {
            return !el.formData.skus.length > 0;
          } catch (error) {
            return true;
          }
        },
      },
    ],

    location_type: null,
    locaiton_id: null,
    location_function: null,
  }),
  mounted() {},
  methods: {
    nextStep(type) {
      this.updateValidationStateOfSteps();
      if (type == "next") {
        if (this.tab < 3) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "state":
          if (
            this.serverData.states.filter(
              (state) => state.title == this.state_text
            ).length === 0
          ) {
            this.serverData.cities = null;
            this.serverData.areas = null;
            // setTimeout(() => {
            // }, 2000);
          } else {
            // this.onStateChange(
            //   this.serverData.states.filter(
            //     (state) => state.title == this.state_text
            //   )[0]
            // );
          }

          break;
        case "city":
          if (
            this.serverData.cities.filter(
              (city) => city.title == this.city_text
            ).length === 0
          ) {
            this.serverData.areas = null;
            // setTimeout(() => {
            // }, 2000);
          } else {
            // this.onCityChange(
            //   this.serverData.cities.filter(
            //     (city) => city.title == this.city_text
            //   )[0]
            // );
          }
          break;
        case "area":
          if (
            this.serverData.areas.filter((area) => area.title == this.area_text)
              .length === 0
          ) {
            setTimeout(() => {
              // this.area_text = "";
            }, 2000);
          } else {
            this.shipping_address.area = this.serverData.areas.filter(
              (area) => area.title == this.area_text
            )[0].title;
          }
          break;
      }
    },
    validationValueCheck(type) {
      let validation = true;
      switch (type) {
        case "state":
          try {
            if (
              this.serverData.states.filter(
                (state) => state.title == this.state_text
              ).length === 0
            ) {
              validation = "Please, select valid state!";
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "city":
          try {
            if (
              this.serverData.cities.filter(
                (city) => city.title == this.city_text
              ).length === 0
            ) {
              validation = "Please, select valid city!";
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "area":
          try {
            if (
              this.serverData.areas.filter(
                (area) => area.title == this.area_text
              ).length === 0
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    async setTextValue(type, value = "") {
      switch (type) {
        case "state":
          this.state_text = value;

          if (value !== "") {
            this.shipping_address.state = this.serverData.states.filter(
              (state) => state.title == this.state_text
            )[0];
            await this.onStateChange(
              this.serverData.states.filter(
                (state) => state.title == this.state_text
              )[0]
            );
          }

          break;
        case "city":
          this.city_text = value;

          if (value !== "") {
            this.shipping_address.city = this.serverData.cities.filter(
              (city) => city.title == this.city_text
            )[0];
            await this.onCityChange(
              this.serverData.cities.filter(
                (city) => city.title == this.city_text
              )[0]
            );
          }
          break;
        case "area":
          this.area_text = value;
          break;
      }
    },
    createNewItem(type, id, func, value = "") {
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    updateValidationStateOfSteps() {
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/fulfillment/orders/create`, data)
        .then((response) => {
          this.serverData = response.data;

          this.resetEditForm().finally(() => {
            this.pageLoader(false);
            this.updateValidationStateOfSteps();
          });
          // this.order_details.order_type = this.serverData.orderTypes[0].index;
          if (this.selectedWarehouse) {
            this.order_details.warehouse = this.serverData.warehouses.find(
              (warehouse) => warehouse.index_ === this.selectedWarehouse
            ).index;
          }
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    submitEditForm() {
      this.updateValidationStateOfSteps();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.formData.skus.length === 0) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one sku is required!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }

      this.pageLoader(true);
      let data = JSON.parse(
        JSON.stringify({
          ...this.formData,
          ...this.order_details,
          // parcel: this.parcel,
          consignee: this.consignee,
          shipping_address: this.shipping_address,
        })
      );
      data.shipping_address.country = this.serverData.countries.filter(
        (country) => country.index === this.shipping_address.country
      )[0].text;
      data.shipping_address.state = this.shipping_address.state.title;
      data.shipping_address.city = this.shipping_address.city.title;
      data.create_type = "b2c";
      if (this.shipping_address.area) {
        data.shipping_address.area = this.shipping_address.area.title;
      }
      ApiService.post(`/fulfillment/orders/store`, data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Order has been created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetEditForm() {
      this.$v.$reset();

      this.formData = {
        skus: [],
      };
      this.order_details = {
        awb: null,
        note: null,
        debug: false,
        customer_id: null,
        // order_type: null,
        // return_type: null,
        order_status: null,
        carrier: null,
        client_reference: null,
        warehouse: null,
        payment_type: null,
        currency: null,
        total_price: null,
        customs_value: null,
        subtotal: null,
        total_discount: null,
        total_tax: null,
        // delivery_duty_type: null,
        required_delivery_time: null,
        // shipping_method: null,
        cod_amount: null,
      };
      // this.parcel = {
      //   total_weight: null,
      //   total_volume: null,
      //   box_number: null,
      // };
      this.consignee = {
        company: null,
        name: null,
        surname: null,
        phone: null,
        // mobile: null,
        email: null,
      };
      this.shipping_address = {
        country: null,
        state: null,
        city: null,
        area: null,
        address_1: null,
        address_2: null,
        postal_code: null,
      };
      this.tab = 0;
      if (this.selectedWarehouse) {
        this.order_details.warehouse = this.selectedWarehouse;
      }
      await this.resetValidationStateOfSteps();
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    onCountryChange(val, safe = false, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            if (!safe) {
              this.shipping_address.state = null;
              this.shipping_address.city = null;
              this.shipping_address.area = null;
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    onStateChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let id = null;
          try {
            id = this.serverData.states.filter(
              (state) => state.title == val.title
            )[0].id;
          } catch (e) {
            return;
          }
          if (!id) {
            return;
          }
          data.state = id;
        }
        this.pageLoader(true);
        ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            if (!safe) {
              this.shipping_address.city = null;
              this.shipping_address.area = null;
              this.serverData.areas = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    onCityChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let id = null;
          try {
            id = this.serverData.cities.filter(
              (city) => city.title == val.title
            )[0].id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }

          data.city = id;
        }
        this.pageLoader(true);
        ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            if (!safe) {
              this.shipping_address.area = null;
            }
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 3;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    get_is_unit_price_required() {
      let data = null;
      try {
        data = this.serverData.countries.filter(
          (country) => country.index === this.shipping_address.country
        )[0].unit_price_required;
      } catch {
        data = null;
      }
      return data;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    userId: function () {
      return this.$store.getters.currentUser.data.is_client
        ? this.$store.getters.currentUser.data.id
        : this.order_details.customer_id;
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },

    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this, "order_details");
    },
    // order_typeErrors: function () {
    //   return this.handleFormValidation("order_type", this, "order_details");
    // },
    // return_typeErrors: function () {
    //   return this.handleFormValidation("return_type", this, "order_details");
    // },
    // order_statusErrors: function () {
    //   return this.handleFormValidation("order_status", this, "order_details");
    // },
    // noteErrors: function () {
    //   return this.handleFormValidation("note", this, "order_details");
    // },
    client_referenceErrors: function () {
      return this.handleFormValidation(
        "client_reference",
        this,
        "order_details"
      );
    },
    // carrierErrors: function () {
    //   return this.handleFormValidation("carrier", this, "order_details");
    // },
    payment_typeErrors: function () {
      return this.handleFormValidation("payment_type", this, "order_details");
    },
    currencyErrors: function () {
      return this.handleFormValidation("currency", this, "order_details");
    },
    total_priceErrors: function () {
      return this.handleFormValidation("total_price", this, "order_details");
    },
    cod_amountErrors: function () {
      return this.handleFormValidation("cod_amount", this, "order_details");
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this, "consignee");
    },
    phoneErrors: function () {
      return this.handleFormValidation("phone", this, "consignee");
    },
    // mobileErrors: function () {
    //   return this.handleFormValidation("mobile", this, "consignee");
    // },
    emailErrors: function () {
      return this.handleFormValidation("email", this, "consignee");
    },
    address_1Errors: function () {
      return this.handleFormValidation("address_1", this, "shipping_address");
    },
    countryErrors: function () {
      return this.handleFormValidation("country", this, "shipping_address");
    },
    stateErrors: function () {
      return this.handleFormValidation("state", this, "shipping_address");
    },
    cityErrors: function () {
      return this.handleFormValidation("city", this, "shipping_address");
    },
    // total_weightErrors: function () {
    //   return this.handleFormValidation("total_weight", this, "parcel");
    // },
    // total_volumeErrors: function () {
    //   return this.handleFormValidation("total_volume", this, "parcel");
    // },
    // box_numberErrors: function () {
    //   return this.handleFormValidation("box_number", this, "parcel");
    // },
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-end;
}
.modal-root {
  background-color: white !important;
  height: 100%;
}
.modal-action {
  justify-self: flex-end;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.form-body {
  max-height: 64vh !important;
  height: 64vh !important;
  overflow-y: scroll;
}
.step-head {
}
.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
