<template>
  <div class="mr-sm-2 mb-1 mr-0 pa-0">
    <button @click.prevent="toggleModal" class="btn btn--export-filter">
      <span class="svg-icon">
        <v-icon size="18">mdi-account-plus</v-icon>
      </span>
      Assign
    </button>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Assign ASN</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <div class="h-55px mt-5">
            <v-select
              dense
              outlined
              clearable
              v-model="formData.user_id"
              label="Select user"
              item-text="text"
              item-value="index"
              :items="users"
              :error-messages="userErrors"
              @blur="$v.formData.user_id.$touch()"
              @input="$v.formData.user_id.$touch()"
            ></v-select>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-center py-7">
            <button
              type="submit"
              class="btn btn--export-filter mr-3 px-5 py-3"
              @click="assignPicker"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
          <!--begin::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { GET_SERVER_DATA } from "@/core/services/store/pickingAssignment.module";

export default {
  name: "AssignAsn",
  mixins: [validationMixin],
  validations: {
    formData: {
      user_id: { required },
    },
  },
  props: {
    refresher: {
      required: true,
      type: Function,
    },
    selected: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      user_id: null,
    },
  }),
  methods: {
    toggleModal() {
      if (!this.selected.length) {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
          // timer: 2500,
        });
        return;
      }
      if (!this.dialog) {
        this.$store.dispatch(GET_SERVER_DATA);
      }
      this.dialog = !this.dialog;
    },

    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    assignPicker() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.$store.commit(SET_PAGE_LOADING, true);

        const SELECTED_ORDERS = this.selected.map((item) => item.id);
        const data = {
          ids: SELECTED_ORDERS,
          user_id: this.formData.user_id,
          warehouse_id: this.selectedWarehouse,
        };

        ApiService.post("fulfillment/asn/assign-asn", data)
          .then(({ data }) => {
            this.toggleModal();
            this.refresher();

            Swal.fire({
              title: "Completed",
              html: `<div>${data.messages
                .map((mes) => `<div>${mes}</div>`)
                .join("")}</div>`,
              icon: "info",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: "OK",
            });
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
  },
  computed: {
    userErrors: function () {
      return this.handleFormValidation("user_id");
    },
    users: function () {
      return this.$store.getters.getASNExampleUsers;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  // mounted() {
  //   this.$store.dispatch(GET_SERVER_DATA);
  // },
};
</script>
