<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Sku</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="createForm" class="pa-3 pb-0">
            <v-text-field
              v-model.trim="$v.formData.price.$model"
              :error-messages="validateState('price') ? errorMessages : ''"
              :disabled="!formData.sku_id"
              type="number"
              @change="() => validateMinValue('price', 0, 'formData')"
              label="Price"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model.trim="formData.discounted_price"
              type="number"
              @change="
                () => validateMinValue('discounted_price', 0, 'formData')
              "
              :disabled="!formData.sku_id"
              label="Discounted Price"
              outlined
              dense
            ></v-text-field>
            <div v-if="!!images">
              <h4>Uploaded Image</h4>
              <div v-for="(item, index) in images" :key="index" class="d-flex">
                <span
                  ><show-image :item="item" class="mr-3"> </show-image
                ></span>
                <span
                  ><v-icon left @click="removeUploadedImage(item.id)">
                    mdi-delete
                  </v-icon></span
                >
              </div>
            </div>
            <v-file-input
              v-model="formData.image"
              :disabled="!formData.sku_id"
              show-size
              counter
              multiple
              dense
              outlined
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Image"
              clearable
            ></v-file-input>
            <add-parameter
              :parameters="parameters"
              @update="insertParameter"
              :paramValues="item.parameters"
            >
            </add-parameter>
            <v-switch
              v-model="formData.is_default"
              :disabled="!formData.sku_id"
              color="primary"
              label="Home Page"
            ></v-switch>
          </form>

          <!--end::Body-->

          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="closeModal"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { UPDATE_TABLE_DATA } from "@/core/services/store/products.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ShowImage from "@/own/components/datatable/ShowImage.vue";
import AddParameter from "@/own/components/stores/products/AddParameter.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditSku",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      sku_id: { required },
      product_id: { required },
      price: { required },
    },
  },
  props: ["item", "product_id", "refresh"],
  components: { AddParameter, ShowImage },
  data: () => ({
    dialog: false,
    formData: {
      sku_id: null,
      product_id: null,
      price: 0,
      discounted_price: 0,
      image: null,
      parameters: null,
      is_default: true,
    },
    images: [],
    errorMessages: ["This Field is required"],
    items: [],
    value: null,
    isLoading: false,
    model: null,
    maxCount: 50,
    search: null,
  }),
  mounted() {
    this.formData.product_id = this.product_id;
    this.resetData();
  },
  computed: {
    brands: function () {
      if (this.$store.getters.getPRODUCTSBrandsData) {
        return this.$store.getters.getPRODUCTSBrandsData;
      }
      return undefined;
    },
    stores: function () {
      if (this.$store.getters.getPRODUCTSStoresData) {
        return this.$store.getters.getPRODUCTSStoresData;
      }
      return undefined;
    },
    parameters: function () {
      if (this.$store.getters.getPRODUCTSParametersData) {
        return this.$store.getters.getPRODUCTSParametersData;
      }
      return undefined;
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    closeModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    insertParameter(val) {
      this.formData.parameters = val;
    },
    getSkuItems() {
      if (this.search.length < 2) {
        this.message(
          "Error",
          "You should input more than 2 Characters",
          "error"
        );
        return;
      }
      this.isLoading = true;
      const url = `${process.env.VUE_APP_BASE_URL}/stores/portal/skus/index`;
      const data = {
        q: this.search,
        max: this.maxCount,
      };
      ApiService.post(url, data)
        .then((data) => {
          if (data.data) {
            this.items = data.data.skus;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    removeUploadedImage(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          const data = { image_id: id };
          ApiService.post("/stores/portal/products/images/destroy", data)
            .then(() => {
              this.images = this.images.filter((item) => item.id != id);
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.pageLoad(false);
              this.refresh();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    submitEditForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      let data = this.convertToFormData();
      if (!data) {
        this.message("Error", "You should input all data", "error");
        return;
      }
      this.pageLoad(true);
      ApiService.post("/stores/portal/skus/store", data)
        .then(() => {
          this.message("Updated", "The sku data has been updated!", "success");
          this.closeModal();
          this.pageLoad(false);
          this.refresh();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      for (var key in this.formData) {
        if (key == "is_default") {
          if (this.formData[key] == false) data.append(key, 0);
          else data.append(key, 1);
        } else if (key == "image") {
          if (this.formData[key]) {
            this.formData[key].map((item) => {
              data.append("sliders[]", item);
            });
          }
        } else if (key == "parameters") {
          if (this.formData[key]) {
            this.formData[key].map((item) => {
              data.append("parameter_values[]", item);
            });
          }
        } else data.append(key, this.formData[key]);
      }
      return data;
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    updateTableContent() {
      this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getPRODUCTSTableState)
        .then(() => {});
      this.$forceUpdate();
    },
    resetData() {
      this.images = this.item.images;
      this.formData.sku_id = this.item.id;
      this.formData.image = null;
      this.formData.price = this.item.price;
      this.formData.discounted_price = this.item.discounted_price;
      this.formData.is_default = this.item.is_default;
      this.items = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
