<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Shipping Method</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-300px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Body-->

        <form id="createForm" class="pa-3 pb-0">
          <v-autocomplete
            v-model.trim="$v.formData.system_code.$model"
            :error-messages="validateState('system_code') ? errorMessages : ''"
            :items="systemCodes"
            item-text="name"
            item-value="id"
            dense
            outlined
            label="Shipping System Method"
            clearable
          ></v-autocomplete>
          <v-text-field
            v-model.trim="$v.formData.store_code.$model"
            :error-messages="validateState('store_code') ? errorMessages : ''"
            label="Store Code"
            clearable
            dense
            outlined
          ></v-text-field>
          <v-main class="text-center mt-4">
            <v-btn class="mr-4" color="primary" @click="submitForm">
              submit
            </v-btn>
            <v-btn @click="resetForm"> clear </v-btn>
          </v-main>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Apiservice from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      store_code: { required },
      system_code: { required },
    },
  },
  name: "EditItem",
  props: [
    "refresher",
    "loader",
    "integration_id",
    "systemCodes",
    "itemForAction",
  ],
  data: () => ({
    dialog: false,
    errorMessages: ["This Field is required"],
    formData: {
      method_id: null,
      store_code: null,
      system_code: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) this.resetForm();
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submitForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      this.loader(true);
      let data = { ...this.formData, integration_id: this.integration_id };
      Apiservice.post("/validations/shippingMethods/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "The Shipping Method has been Updated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    resetForm() {
      this.formData.method_id = this.itemForAction.id;
      this.formData.store_code = this.itemForAction.storeCode;
      this.formData.system_code = this.itemForAction.systemCode;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
