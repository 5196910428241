import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getSTANDARDQUALITYCHECKTableStructure";
export const UPDATE_TABLE_DATA = "updateSTANDARDQUALITYCHECKTableData";
export const EXPORT_TABLE_DATA = "exportSTANDARDQUALITYCHECKTableData";
export const CREATE_QUALITY_CHECK = "createSTANDARDQUALITYCHECK";
export const GET_QUALITY_CHECK = "getSTANDARDQUALITYCHECKCycleCount";
export const STORE_QUALITY_CHECK = "storeSTANDARDQUALITYCHECK";
export const UPDATE_QUALITY_CHECK_DETAILS = "getSTANDARDQUALITYCHECKDetails";

// mutation types
export const SET_TABLE_STRUCTURE = "setSTANDARDQUALITYCHECKTableStructure";
export const SET_TABLE_DATA = "setSTANDARDQUALITYCHECKTableData";
export const SET_TABLE_FILTER = "setSTANDARDQUALITYCHECKTableFilter";
export const SET_TABLE_PAGE = "setSTANDARDQUALITYCHECKTablePage";
export const SET_TABLE_PERPAGE = "setSTANDARDQUALITYCHECKTablePerPage";
export const SET_TABLE_SORTBY = "setSTANDARDQUALITYCHECKTableSortBy";
export const SET_TABLE_SORTORDER = "setSTANDARDQUALITYCHECKTableSortOrder";
export const SET_EXPORT_LINK = "setSTANDARDQUALITYCHECKExportLink";
export const SET_BLIND_QUALITY_CHECK = "setSTANDARDQUALITYCHECKBlintCycleCount";
export const SET_QUALITY_CHECK_DETAILS = "setSTANDARDQUALITYCHECKTaskDetails";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  task_details: null,
};

const getters = {
  /**
   * for table title, description
   * */
  getSTANDARDQUALITYCHECKTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getSTANDARDQUALITYCHECKTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getSTANDARDQUALITYCHECKTableState(state) {
    return state.table_state;
  },

  getSTANDARDQUALITYCHECKTableHeaders(state) {
    return state.table_structure.columns;
  },

  getSTANDARDQUALITYCHECKTableFilters(state) {
    return state.table_structure.filters;
  },

  getSTANDARDQUALITYCHECKTableData(state) {
    return state.table_data.data;
  },

  getSTANDARDQUALITYCHECKExportUrl(state) {
    return state.export_link;
  },
  getSTANDARDQUALITYCHECKUploadType(state) {
    return state.table_structure.type;
  },
  getSTANDARDQUALITYCHECKAssignableUsers(state) {
    return state.table_structure.users;
  },

  getSTANDARDQUALITYCHECKUsers(state) {
    return state.table_structure.users;
  },
  getSTANDARDQUALITYCHECKDetails(state) {
    return state.task_details;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/standard_quality_checks")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/standard_quality_checks/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/standard_quality_checks/export",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  async [UPDATE_QUALITY_CHECK_DETAILS](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const { data } = await ApiService.post(
        "warehouse_management/standard_quality_checks/show",
        { id: payload }
      );
      context.commit(SET_QUALITY_CHECK_DETAILS, data.standard_qc);
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_QUALITY_CHECK_DETAILS](state, data) {
    state.task_details = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
