<template>
  <v-dialog
    v-model="showModal"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    ref="addItem"
  >
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form v-if="isLoaded" class="row ma-0">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.warehouse_id"
                label="Warehouse"
                :items="formFields.warehouses"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.warehouse_id.$touch()"
                :error-messages="warehouseErrors"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.system_id"
                label="System"
                :disabled="!formData.warehouse_id"
                :items="formFields.systems"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                @blur="$v.formData.system_id.$touch()"
                :error-messages="systemErrors"
              />
            </div>

            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-text-field
                v-model="formData.name"
                dense
                required
                clearable
                outlined
                label="Name"
                :error-messages="nameErrors"
                @blur="$v.formData.name.$touch()"
              ></v-text-field>
            </div>
            <div
              class="col-12 col-sm-6 col-md-4"
              v-if="formData.system_id == '1'"
            >
              <v-autocomplete
                v-model="formData.city_id"
                :loading="isLoading2"
                :search-input.sync="search2"
                :items="cities"
                item-text="text"
                item-value="index"
                label="City"
                placeholder="Enter city name!"
                dense
                outlined
                clearable
                hide-selected
                :hide-no-data="!search2"
                @blur="$v.formData.city_id.$touch()"
                :error-messages="city_idErrors"
              >
              </v-autocomplete>
            </div>

            <div
              class="col-12 col-sm-6 col-md-4"
              v-if="formData.system_id == '2'"
            >
              <v-range-slider
                outlined
                class="mx-1"
                v-model="formData.order_value"
                label="Order value"
                :tick-size="1"
                :ticks="true"
                thumb-label="always"
                thumb-size="22"
                :min="1"
                :max="1000"
                dense
                clearable
                @blur="$v.formData.order_value.$touch()"
                :error-messages="order_valueErrors"
              ></v-range-slider>
            </div>

            <div
              class="col-12 col-sm-6 col-md-4 h-75px"
              v-if="formData.system_id == '3'"
            >
              <v-text-field
                v-model="formData.line_items"
                dense
                type="number"
                @change="() => validateMinValue('line_items', 0, 'formData')"
                required
                clearable
                outlined
                label="Number of line items"
                :error-messages="line_itemsErrors"
                @blur="$v.formData.line_items.$touch()"
              ></v-text-field>
            </div>
          </div>

          <div
            class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center h-105px pb-5"
          >
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1 py-3"
              @click="resetFormData"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1 py-3"
              @click="submitForm"
            >
              {{ submitButtonText }}
            </button>
          </div>
        </form>
      </div>
      <!--end::Modal body-->
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {
  UPDATE_CREATE_TEMPLATE_STRUCTURE,
  STORE_NEW_TEMPLATE,
  SET_CREATE_TEMPLATE_STRUCTURE,
} from "@/core/services/store/allocationRuleBasedTemplate.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionDialog",
  mixins: [validationMixin, fieldValueValidation],
  props: ["refresher", "pageLoader"],
  validations() {
    return {
      formData: {
        name: { required },
        warehouse_id: { required },
        system_id: { required },
        city_id: {
          required: requiredIf(function () {
            return this.formData.system_id == 1;
          }),
        },
        line_items: {
          required: requiredIf(function () {
            return this.formData.system_id == 3;
          }),
        },
        order_value: {
          required: requiredIf(function () {
            return this.formData.system_id == 2;
          }),
        },
      },
    };
  },
  data() {
    return {
      showModal: false,
      isLoaded: false,
      formData: {
        warehouse_id: null,
        name: "",
        system_id: null,
        city_id: null,
        line_items: null,
        order_value: [0, 0],
      },
      dependentDropdown: {
        show: false,
        label: "",
        items: [],
        model: null,
      },
      typingTimer: null,
      doneTypingInterval: 1500,
      entries2: [],
      search2: "",
      isLoading2: false,
    };
  },
  methods: {
    toggleModal() {
      // this.showModal = !this.showModal;
      if (this.showModal) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.showModal = !this.showModal;
    },
    async loadDataFromServer() {
      await this.$store.dispatch(UPDATE_CREATE_TEMPLATE_STRUCTURE);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post(
          "/warehouse_management/allocation_rule_based_templates/edit",
          sendData
        ).then(({ data }) => {
          this.formData = data.template;
          if (data.cities) {
            this.entries2.push(data.cities);
          }
          this.$store.commit(SET_CREATE_TEMPLATE_STRUCTURE, data);
          this.pageLoader(false);
        });
      }
      this.isLoaded = true;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    resetFormData() {
      this.formData = {
        warehouse_id: this.warehouseId,
        name: "Defalut",
        system_id: null,

        city_id: null,
        line_items: null,
        order_value: [0, 0],
      };
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store.dispatch(STORE_NEW_TEMPLATE, [
        this.formData,
        this.actionType,
      ]);
      this.refresher();
      this.toggleModal();
    },
    async getData2(value) {
      let requestBody = { q: value };

      await fetch(`${process.env.VUE_APP_BASE_URL}/address/cities/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries2 = res.cities;
        })
        .finally(() => (this.isLoading2 = false));
    },
  },
  computed: {
    cities() {
      return this.entries2;
    },
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    formFields: function () {
      return this.$store.getters
        .getALLOCATIONRULEBASEDTEMPLATECreateFormProperties;
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    warehouseErrors: function () {
      return this.handleFormValidation("warehouse_id");
    },
    systemErrors: function () {
      return this.handleFormValidation("system_id");
    },
    city_idErrors: function () {
      return this.handleFormValidation("city_id");
    },
    line_itemsErrors: function () {
      return this.handleFormValidation("line_items");
    },
    order_valueErrors: function () {
      return this.handleFormValidation("order_value");
    },
  },
  watch: {
    search2(val) {
      clearTimeout(this.typingTimer);
      if (val) {
        this.isLoading2 = true;
        this.typingTimer = setTimeout(() => {
          this.getData2(val.trim());
        }, this.doneTypingInterval);
      } else {
        return;
      }
    },
  },
};
</script>
<style lang="scss">
.putaway-rules-zone-groups,
.putaway-rules-zones {
  .v-chip--select {
    height: 21px !important;
  }
}
</style>
