<template>
  <v-combobox
    outlined
    class="mx-1 poppins"
    :items="entries"
    v-model="filterData"
    :label="$t(filter.title)"
    item-text="text"
    item-value="index"
    multiple
    :search-input.sync="q"
    dense
    clearable
  ></v-combobox>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "FilterMultiAutocomplete",
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: null,
    q: null,
    isLoading: false,
    entries: [],
    doneTypingInterval: 1500,
    typingTimer: null,
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = null;
      this.q = null;
      this.isLoading = false;
      this.entries = [];
    },
    getData(value) {
      ApiService.post(this.filter.url, { q: value })
        .then(({ data }) => {
          this.entries = data.locations;
          this.isLoading = false;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
    q(val) {
      if (val && val !== "") {
        this.isLoading = true;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
          this.isLoading = true;
          this.getData(val.trim());
        }, this.doneTypingInterval);
      }
    },
  },
};
</script>
