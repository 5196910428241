<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row ma-0">
          <div class="col-12 col-sm-6 h-75px">
            <v-autocomplete
              v-model="formData.carrier_account_id"
              label="Carrier account"
              :items="serverData.carrier_accounts"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="carrier_account_idErrors"
              @blur="$v.formData.carrier_account_id.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 h-75px">
            <v-autocomplete
              v-model="formData.payment_type"
              label="Payment type"
              :items="serverData.payment_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="payment_typeErrors"
              @blur="$v.formData.payment_type.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.area"
              label="Area"
              :items="serverData.cities"
              item-text="text"
              item-value="index"
              multiple
              dense
              outlined
              clearable
              :error-messages="areaErrors"
              @blur="$v.formData.area.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.order_value_from"
              label="Order value from"
              type="number"
              dense
              outlined
              clearable
              :min="0"
              :error-messages="order_value_fromErrors"
              @blur="$v.formData.order_value_from.$touch()"
              @change="
                () => validateMinValue('order_value_from', 0, 'formData')
              "
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.order_value_to"
              label="Order value to"
              type="number"
              dense
              outlined
              clearable
              :min="0"
              :error-messages="order_value_toErrors"
              @blur="$v.formData.order_value_to.$touch()"
              @change="
                () =>
                  validateMinValue(
                    'order_value_to',
                    formData.order_value_from,
                    'formData'
                  )
              "
            />
          </div>
        </form>

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations() {
    return {
      formData: {
        carrier_account_id: { required },
        payment_type: { required },
        order_value_from: { required },
        order_value_to: { required },
        area: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      carrier_account_id: null,
      payment_type: null,
      order_value_from: null,
      order_value_to: null,
      area: [],
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        carrier_account_id: null,
        payment_type: null,
        order_value_from: null,
        order_value_to: null,
        area: [],
      };
    },
    loadDataFromServer() {
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = {
          id: this.$store.getters.getItemForAction.id,
        };
        ApiService.post(
          "/shipping/courier_allocation_configurations/edit",
          sendData
        )
          .then(({ data }) => {
            this.serverData = data;
            this.formData = JSON.parse(
              JSON.stringify(data.carrier_allocation_configuration)
            );
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      } else {
        ApiService.post("/shipping/courier_allocation_configurations/create")
          .then(({ data }) => {
            // console.log("data", data);
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("minValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].minValue &&
          errors.push("Minimum value should be 10");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let sendData = {
        ...this.formData,
      };

      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(
        `/shipping/courier_allocation_configurations/${actionUrl}`,
        sendData
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    carrier_account_idErrors: function () {
      return this.handleFormValidation("carrier_account_id");
    },
    payment_typeErrors: function () {
      return this.handleFormValidation("payment_type");
    },
    areaErrors: function () {
      return this.handleFormValidation("area");
    },
    order_value_fromErrors: function () {
      return this.handleFormValidation("order_value_from");
    },
    order_value_toErrors: function () {
      return this.handleFormValidation("order_value_to");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
  },
};
</script>
