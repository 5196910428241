<template>
  <div>
    <v-data-table :headers="headers" :items="skus" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>SKUs</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-info px-5 py-3 ls1 mb-2 mx-1"
            @click="showAddCartonModal"
            :disabled="isReadOnly"
          >
            New Carton
          </button>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }" v-if="!isReadOnly">
        <v-icon small @click="() => removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :skuItem="skuItem"
      :clientId="clientId"
      :item="getItemForAction"
      :oldIndex="getOldIndex"
    ></EditSku>
    <AddCarton
      ref="addCarton"
      :actionFunction="addCartonsSkus"
      :clientId="clientId"
      :warehouse="warehouse"
      :store="store"
    >
    </AddCarton>
  </div>
</template>

<script>
import EditSku from "@/own/components/orderManagement/pendingAuditOrders/skuContainer/EditSku.vue";
import Swal from "sweetalert2";
import AddCarton from "@/own/components/fulfillment/orders/B2B/skuContainerForEdit/AddCarton.vue";
export default {
  name: "SkuDatatable",
  props: [
    "setSku",
    "addSku",
    "skus",
    "skuItem",
    "clientId",
    "warehouse",
    "store",
    "isReadOnly",
  ],
  components: { EditSku, AddCarton },
  data: () => ({
    dialog: false,
    headers: [
      { text: "SKU ID", value: "skuId" },
      { text: "System Code", value: "systemCode" },
      { text: "Quantity", value: "quantity" },
      { text: "Description", value: "description" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemForAction: null,
    oldIndex: null,
  }),
  computed: {
    getItemForAction() {
      return this.itemForAction;
    },
    getOldIndex() {
      return this.oldIndex;
    },
  },
  methods: {
    addCartonsSkus(skus = []) {
      if (skus.length === 0) {
        return;
      }
      let isValid = true;
      let doubles = [];
      skus.forEach((sku) => {
        if (this.findDublicate(sku) > -1) {
          doubles.push(sku);
          isValid = false;
        }
      });
      if (!isValid) {
        Swal.fire({
          title: `Warning`,
          html: `<div>${doubles
            .map((sku) => `<div>SKU ${sku.skuId} has already exist.</div>`)
            .join("")}
          <div>`,
          icon: "warning",
          showConfirmButton: true,
        });
        return;
      }
      this.addSku(skus);
    },
    findDublicate(item) {
      let double = -1;
      try {
        double = this.skus.findIndex(
          (sku) => sku.systemCode == item.systemCode
        );
      } catch {
        double = -1;
      }
      return double;
    },
    showAddCartonModal() {
      if (this.isReadOnly) {
        return;
      }
      if (this.warehouse && this.store) {
        this.$nextTick(() => {
          this.$refs.addCarton.toggleModal();
        });
      } else {
        Swal.fire({
          title: "Warning",
          text: `Warehouse and store fields are required!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    showEditSkuModal(item) {
      if (this.isReadOnly) {
        return;
      }
      let copy = { ...item };
      const index = this.isRecordExist(copy, this.skus);
      this.itemForAction = copy;
      this.oldIndex = index[1];
      this.$nextTick(() => {
        this.$refs.editSku.toggleModal();
      });
    },
    showAddSkuModal() {
      if (this.isReadOnly) {
        return;
      }
      this.oldIndex = false;
      this.itemForAction = null;
      this.$nextTick(() => {
        this.$refs.editSku.toggleModal();
      });
    },
    isRecordExist(item, items, oldAdress = false) {
      let double = [0, 0];
      if (oldAdress) {
        items.forEach((element, index) => {
          if (element.skuId == item.skuId && index != oldAdress) {
            double = [1, index];
          }
        });
        return double;
      } else {
        items.forEach((element, index) => {
          if (element.skuId == item.skuId) {
            double = [1, index];
          }
        });
        return double;
      }
    },
    editSkus(item, oldAdress = false) {
      let new_skus = [...this.skus];
      let double = this.isRecordExist(item, new_skus, oldAdress);
      if (oldAdress && double[0] != 1) {
        new_skus[oldAdress] = item;
        this.setSku(new_skus);
      } else if (oldAdress && double[0] == 1) {
        new_skus[oldAdress] = item;
        this.setSku(new_skus);
        this.removeSkus(new_skus[double[1]]);
      } else {
        if (double[0]) {
          new_skus[double[1]] = item;

          this.setSku(new_skus);
        } else {
          new_skus.push(item);
          this.setSku(new_skus);
        }
      }
    },
    removeSkus(item) {
      if (this.isReadOnly) {
        return;
      }
      let new_skus = [...this.skus];
      let double = this.isRecordExist(item, new_skus);
      if (double[0]) {
        new_skus.splice(double[1], 1);
        this.setSku(new_skus);
      } else {
        Swal.fire({
          title: "Error",
          text: `Something went wrong!`,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
};
</script>
