<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Resend return email</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <div class="col-12 col-12 h-75px">
            <v-select
              v-model="status_id"
              label="Email status"
              item-text="text"
              item-value="index"
              :items="statuses"
              clearable
              outlined
              dense
              :error-messages="status_id_errors"
              @input="checkValidity"
              @blur="checkValidity"
            ></v-select>
          </div>
          <div class="col-12 col-12 h-75px">
            <v-text-field
              v-model="email"
              label="Email address"
              clearable
              type="email"
              outlined
              dense
              :error-messages="email_errors"
              @input="checkValidity"
              @blur="checkValidity"
            ></v-text-field>
          </div>
        </div>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "ResendReturnEmail",
  props: ["items", "updateTableContent"],
  data: () => ({
    dialog: false,
    email: null,
    status_id: null,
    valid: true,
  }),
  methods: {
    toggleModal() {
      this.reset();
      this.dialog = !this.dialog;
    },
    submit() {
      this.checkValidity();
      if (this.valid) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          ids: this.items.map((item) => item.id),
          email_status_id: this.status_id,
          email: this.email,
        };
        ApiService.post(`/shipping/returns/resend_return_email`, payload)
          .then(() => {
            Swal.fire({
              title: "Success",
              text: `Email sent`,
              icon: "success",
              showConfirmButton: true,
            });
            this.toggleModal();
            this.updateTableContent();
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    checkValidity() {
      const emailRegEx = RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      this.valid =
        !!this.status_id && !!this.email && emailRegEx.test(this.email);
    },
    reset() {
      this.status_id = null;
      this.email = null;
      this.valid = true;
    },
  },
  computed: {
    statuses() {
      return this.$store.getters.getRTRNTableEmailStatuses;
    },
    status_id_errors() {
      if (!this.status_id && !this.valid) {
        return "This field is required";
      }
      return null;
    },
    email_errors() {
      const emailRegEx = RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      if (!this.email && !this.valid) {
        return "This field is required";
      } else if (this.email && !emailRegEx.test(this.email)) {
        return "Invalid email address";
      }
      return null;
    },
  },
};
</script>
