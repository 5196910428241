<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    max-width="1000px"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Pickup Order</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="">
        <!--begin::Body-->
        <v-stepper
          v-model="step"
          max-height="920"
          min-height="500"
          flat
          tile
          class="ma-0 pa-0"
        >
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              Select type of pickup
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              {{
                formData.pickup_type === 1
                  ? "Stock collection form"
                  : formData.pickup_type === 2
                  ? "Cross dock collection form"
                  : ""
              }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card elevation="0" class="mb-4">
                <form id="createForm" class="pa-3 pt-0 pb-0 row">
                  <div
                    class="col-sm-6 col-md-6 col-lg-6 col-12"
                    v-if="!$store.getters.isClient"
                  >
                    <v-select
                      v-model="formData.customer"
                      label="Customer"
                      item-text="text"
                      item-value="index"
                      :items="serverData.clients"
                      clearable
                      outlined
                      dense
                      :error-messages="customerErrors"
                      @input="$v.formData.customer.$touch()"
                      @blur="$v.formData.customer.$touch()"
                    ></v-select>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                    <v-select
                      v-model="formData.pickup_type"
                      label="Pickup type"
                      item-text="text"
                      item-value="index"
                      :items="serverData.pickup_types"
                      clearable
                      dense
                      outlined
                      :error-messages="pickup_typeErrors"
                      @input="$v.formData.pickup_type.$touch()"
                      @blur="$v.formData.pickup_type.$touch()"
                    ></v-select>
                  </div>
                  <!-- <div class="col-12 col-sm-6 col-md-4">
                    <v-text-field
                      class
                      v-model="formData.parcel.total_weight"
                      type="number"
                      label="Total weight"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div> -->
                  <!-- <div class="col-12 col-sm-6 col-md-4">
                    <v-text-field
                      class
                      v-model="formData.parcel.total_volume"
                      type="number"
                      label="Total volume"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div> -->
                  <!-- <div class="col-12 col-sm-6 col-md-4">
                    <v-text-field
                      class
                      v-model="formData.parcel.number_of_boxes"
                      type="number"
                      min="1"
                      label="Number of boxes"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <v-text-field
                      class
                      v-model="formData.parcel.length"
                      type="number"
                      label="Length"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <v-text-field
                      class
                      v-model="formData.parcel.width"
                      type="number"
                      label="Width"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4">
                    <v-text-field
                      class
                      v-model="formData.parcel.height"
                      type="number"
                      label="Height"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div> -->

                  <!-- <div class="col-12">
                    <sku-datatable
                      :setSku="setSkus"
                      :skus="formData.skus"
                      :skuItem="serverData.skus"
                      :clientId="userId"
                    ></sku-datatable>
                  </div> -->
                </form>
                <v-main class="text-center mt-4">
                  <button
                    type="button"
                    class="btn btn-info px-5 py-3 ls1"
                    @click="handleStage1"
                  >
                    Continue
                  </button>
                </v-main>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="ma-0 pa-0">
              <v-card class="ma-0 pa-0" elevation="0">
                <component
                  ref="step2Form"
                  v-bind:is="step2FormComponent"
                  :setData="setForm2Data"
                  :actionFuntion="handleStage2"
                  :serverData="serverData"
                  :pageLoader="pageLoader"
                  :initData="true"
                  :userId="userId"
                  :customer_id="customerId"
                ></component>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
// import SkuDatatable from "@/own/components/shipping/pickupOrders/skuContainer/SkuDatatable";
import StockForm from "@/own/components/shipping/pickupOrders/pickupTypeForms/StockForm";
import CrossDockForm from "@/own/components/shipping/pickupOrders/pickupTypeForms/CrossDockForm";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  components: {
    // SkuDatatable,
  },
  validations: {
    formData: {
      customer: {
        required: requiredIf(function () {
          return !this.isClient;
        }),
      },
      pickup_type: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    step: 1,
    step2Form: null,
    serverData: null,
    isDataEnteredToForm: false,
    formData: {
      id: null,
      customer: null,
      pickup_type: null,
      skus: [],
      // parcel: {},
    },
  }),
  methods: {
    // setSkus(item) {
    //   this.formData.skus = [...item];
    // },
    async getStep2Data() {
      this.pageLoader(true);
      await ApiService.post("/shipping/pickup_orders/customer-addresses", {
        customer_id: this.customerId,
      })
        .then((response) => {
          let data = this.serverData;
          data.collection_addresses = response.data.addresses;
          this.serverData = data;

          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleStage1() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.formData.pickup_type == 1) {
        this.step2Form = StockForm;
      } else if (this.formData.pickup_type == 2) {
        this.step2Form = CrossDockForm;
      }
      this.$nextTick(() => {
        if (this.step2Form) {
          this.getStep2Data().then(() => {
            this.$refs.step2Form.resetCreateForm();
          });
        }
      });
      this.step = 2;
    },
    handleStage2(action = "submit") {
      if (action === "submit") {
        this.submitEditForm();
      } else {
        this.step = 1;
      }
    },
    setForm2Data(item) {
      let copy = { ...this.formData, ...item };
      // copy.values = item;
      this.formData = copy;
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/shipping/pickup_orders/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      this.step = 1;
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();

        this.dialog = !this.dialog;
      }
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      return this.formData;
    },
    submitEditForm() {
      this.pageLoader(true);
      let data = this.convertToFormData();

      ApiService.post("/shipping/pickup_orders/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record has been successfully updated!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          // this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.pickup_order };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.pickup_order).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
              // console.log(serverData[0], copy[serverData[0]]);
            }
          });
        });
        this.$nextTick(() => {
          if (this.step2Form) {
            try {
              this.$refs.step2Form.resetCreateForm();
            } catch (e) {
              // console.error(e);
            }
          }
        });
      } else {
        this.formData = {
          id: null,
          customer: null,
          pickup_type: null,
          skus: [],
          // parcel: {},
        };
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    step2FormComponent: function () {
      return this.step2Form;
    },
    customerErrors() {
      return this.handleFormValidation("customer", this);
    },
    pickup_typeErrors() {
      return this.handleFormValidation("pickup_type", this);
    },
    isClient() {
      return this.$store.getters.isClient;
    },
    userId: function () {
      return this.isClient ? false : this.formData.customer;
    },
    customerId: function () {
      return this.isClient
        ? this.$store.getters.currentUser.data.customer_id
        : this.formData.customer;
    },
  },
};
</script>
