<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="700"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Record</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon svg-icon-5">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-6 py-7">
          <!--begin::Body-->
          <form id="editForm row">
            <div class="h-75px col-12">
              <v-text-field
                v-model="formData.name"
                label="Name"
                clearable
                outlined
                dense
                :error-messages="nameErrors"
                @input="$v.formData.name.$touch()"
                @blur="$v.formData.name.$touch()"
              ></v-text-field>
            </div>
            <div class="h-75px col-12">
              <v-text-field
                v-model="formData.description"
                label="Description"
                clearable
                outlined
                dense
                :error-messages="descriptionErrors"
                @input="$v.formData.description.$touch()"
                @blur="$v.formData.description.$touch()"
              ></v-text-field>
            </div>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      description: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      name: null,
      description: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/authorizations/user_types/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.usertype;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/authorizations/user_types/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `User type data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();

      this.formData = {
        name: null,
        description: null,
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    descriptionErrors: function () {
      return this.handleFormValidation("description", this);
    },
  },
};
</script>
