<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Builder</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column h-700px scroll-y mx-5 py-7">
        <RuleBuilder :options="options" :operators="operators" />
        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="dialog = true"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import RuleBuilder from "@/own/components/orderManagement/rules/ruleBuilder/RuleBuilder";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "BuilderDialog",
  components: { RuleBuilder },
  data: () => ({
    dialog: false,
    options: [],
    operators: [],
  }),
  methods: {
    toggleModal() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/order_management/order_management_rules/create")
        .then(({ data }) => {
          this.options = data.operands;
          this.operators = data.operators;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.dialog = !this.dialog;
        });
    },
  },
};
</script>
