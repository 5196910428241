<template>
  <div class="p-4 bg-white rounded-lg custom-container-height">
    <div class="mb-10 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail :table_properties="tableProperties"></datatable-detail>
      <div v-if="hasExport" class="mr-4">
        <button @click="handleExport" class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-export-variant</v-icon>
          </span>
          Export
        </button>
        <Export
          ref="export"
          :headers="exportHeaders"
          :downloadItem="downloadItem"
          class="d-none"
        ></Export>
      </div>
    </div>
    <div class="mx-2">
      <Filters
        ref="filter"
        :submitFilterValues="submitFilterValues"
        :resetFilterValues="resetFilterValues"
        :filters="filter"
        :setTableFiltersValues="setTableFiltersValues"
        :defaultValues="defaultFilterValues"
      ></Filters>
    </div>
    <hr />
    <div class="container poppins" v-if="filtersResult">
      <div class="row">
        <div
          v-for="item in filtersResult"
          :key="item.id"
          class="col-xl-4 col-lg-6 col-md-6 col-sm-6"
        >
          <!--begin::Card-->
          <v-card outlined elevation="2">
            <!--begin::Body-->
            <div class="card-body text-center pt-4">
              <!--begin::User-->
              <div class="mt-7">
                <div
                  v-if="item.photo !== null"
                  class="symbol symbol-circle symbol-lg-90"
                >
                  <img :src="item.photo" alt="image" />
                </div>
                <div
                  v-else
                  class="symbol symbol-lg-90 symbol-circle symbol-light-info"
                >
                  <span
                    class="font-size-h1 symbol-label font-weight-boldest poppins"
                    >{{ item.name[0].toUpperCase() }}</span
                  >
                </div>
              </div>
              <!--end::User-->
              <!--begin::Name-->
              <div class="my-4">
                <a
                  href="#"
                  class="text-dark font-weight-bold text-hover-info font-size-h4"
                  >{{ item.name }}</a
                >
              </div>
              <!--end::Name-->
              <!--begin::Label-->
              <span
                class="btn btn-text btn-light-info btn-sm font-weight-bold"
                >{{ item.total }}</span
              >
              <!--end::Label-->
              <!--begin::Buttons-->
              <div class="mt-9" v-if="item.header && item.body">
                <a
                  href="#"
                  @click="() => handleViewOrder(item.id)"
                  class="btn btn-light-primary font-weight-bolder btn-sm py-3 px-6 text-uppercase"
                  >view details</a
                >
              </div>
              <!--end::Buttons-->
            </div>
            <!--end::Body-->
          </v-card>
          <!--end::Card-->
        </div>
      </div>

      <v-dialog v-model="dialog" scrollable width="500">
        <v-card>
          <v-card-text class="px-8 pt-6 pb-0">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-row-bordered poppins"
              >
                <thead>
                  <tr class="text-left">
                    <th class="pl-7">
                      <span class="text-dark-75">Date</span>
                    </th>
                    <th>
                      <span class="text-dark-75">Total</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in filtersResultBody" :key="index">
                    <td class="pl-7 py-4">
                      <span
                        class="text-dark-75 font-weight-bolder font-size-lg"
                        >{{ item[0] }}</span
                      >
                    </td>
                    <td>
                      <span
                        class="text-dark-75 font-weight-bolder font-size-lg"
                        >{{ item[1] }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <button
              type="reset"
              class="btn btn-light-info mr-3 px-5 py-3 ls1"
              @click="dialog = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Filters from "@/own/components/filter/Filters.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import Swal from "sweetalert2";
import {
  EXPORT_REPORT_DATA,
  GET_REPORT_DATA,
  SET_REPORT_FILTERS_RESULT,
  SHOW_REPORT_DATA,
} from "@/core/services/store/reports.module";
import Export from "@/own/components/Export.vue";

export default {
  name: "ReportsComponent",
  props: {
    /**
     * @description urls to fetch chart data
     * @type {{data_url: string, show_url: string}}
     */
    urls: {
      type: Object,
      default: () => ({ data_url: String, show_url: String }),
      required: true,
    },
    /**
     * @description for setting filters mandatory status
     * @values true, false, ['some_filter_name']
     * @type {boolean, [{title: string, name: string, valueFieldName: string}]}
     */
    mandatoryFilter: {
      type: [Boolean, Array],
      default: false,
    },
  },
  components: {
    Export,
    Filters,
    DatatableDetail,
  },
  beforeMount() {
    this.$store.dispatch(GET_REPORT_DATA, this.urls.data_url).then(() => {});
    this.$store.commit(SET_REPORT_FILTERS_RESULT, null);
  },
  data: () => ({
    filtersData: [],
    filtersResultBody: null,
    dialog: false,
  }),
  computed: {
    tableProperties: function () {
      return this.$store.getters.getREPORTProperties;
    },
    hasExport: function () {
      return this.$store.getters.getREPORTHasExport;
    },
    exportHeaders: function () {
      let headers = [],
        counter = 0;
      this.hasExport &&
        this.filter.forEach((item) => {
          const obj = {
            order: counter++,
            text: item.title,
            value: item.name,
          };
          headers.push(obj);
        });
      return headers;
    },
    filter: function () {
      return this.$store.getters.getREPORTFilters;
    },
    filtersResult: function () {
      return this.$store.getters.getREPORTFiltersResult;
    },
    defaultFilterValues: function () {
      return null;
    },
  },
  watch: {
    exportHeaders: function () {
      this.$nextTick(() => {
        this.$refs.export.$data.columns_to_export = this.exportHeaders;
        this.$refs.export.$data.columns_to_export.forEach((col, index) => {
          this.$refs.export.$data.columns_to_export[index] = {
            exportable: true,
            ...col,
          };
        });
      });
    },
  },
  methods: {
    handleExport() {
      if (this.isAnyFilterSelected()) {
        this.$refs.export.exportColumns();
      } else {
        Swal.fire({
          title: "Warning",
          text: `Please, select at least one column to export!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    handleViewOrder(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.filtersResult.forEach((item) => {
        if (item.id === id) {
          if (item.header && item.body) {
            this.filtersResultBody = item.body;
          }
        }
      });
      this.$store.commit(SET_PAGE_LOADING, false);
      this.dialog = true;
    },
    /**
     * validate requirement of filters
     * @returns Boolean
     */
    validateFilterValues() {
      let is_valid = true;
      // if mandatoryFilter array then checks are all filters located in array are selected
      if (Array.isArray(this.mandatoryFilter)) {
        this.mandatoryFilter.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name + filter.valueFieldName) {
              if (
                data.value !== "" &&
                data.value !== null &&
                data.value !== undefined
              ) {
                is_exist = true;
              }
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      // if mandatoryFilter is true then check all filter values are selected
      else if (this.mandatoryFilter) {
        this.filters.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name) {
              is_exist = true;
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      return is_valid;
    },
    submitFilterValues() {
      if (this.filtersData && this.validateFilterValues()) {
        this.$store.dispatch(SHOW_REPORT_DATA, {
          url: this.urls.show_url,
          filtersData: this.filtersData,
        });
      } else {
        if (Array.isArray(this.mandatoryFilter)) {
          let message = this.mandatoryFilter
            .map((filter) => filter.title)
            .join(",");
          Swal.fire(
            "Warning!",
            `Select ${message} filter${
              this.mandatoryFilter.length > 1 ? "s" : ""
            }!`,
            "warning"
          );
        } else if (this.mandatoryFilter) {
          Swal.fire("Warning!", "Select all filters!", "warning");
        }
      }
    },
    setTableFiltersValues(val) {
      this.filtersData = val;
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$refs.filter.$refs.filter.forEach((filter) => {
        filter.clearData();
      });
      this.filtersData = [];
      this.$store.dispatch(GET_REPORT_DATA, this.urls.data_url);
      this.$store.commit(SET_REPORT_FILTERS_RESULT, null);
      this.$store.commit(SET_PAGE_LOADING, false);
    },

    isAnyFilterSelected() {
      return this.filtersData.length > 0;
    },
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          columns: columns,
          filters: this.filtersData,
        };
        this.$store
          .dispatch(EXPORT_REPORT_DATA, {
            dataUrl: this.urls.data_url,
            payload,
          })
          .then(() => {
            if (this.$store.getters.getREPORTExportUrl) {
              const link = document.createElement("a");
              link.href = this.$store.getters.getREPORTExportUrl;
              link.download = "Export.csv";
              link.click();
              link.remove();
            }
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.export.toggleModal();
          });
      } else
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
    },
  },
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 126px) !important;
  }
}
</style>
