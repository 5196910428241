<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon svg-icon-5">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <div class="h-75px">
            <v-select
              v-model="formData.type_id"
              label="Type"
              item-text="text"
              item-value="index"
              :items="serverData.types"
              clearable
              outlined
              dense
              hide-details
              required
              :error-messages="type_idErrors"
              @input="$v.formData.type_id.$touch()"
              @blur="$v.formData.type_id.$touch()"
            ></v-select>
          </div>
          <div class="h-75px" v-if="isOrganization">
            <v-select
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="serverData.organization"
              clearable
              outlined
              dense
              hide-details
            ></v-select>
          </div>
          <div class="h-75px" v-else>
            <v-autocomplete
              v-model="formData.client_id"
              label="Client"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
              dense
            ></v-autocomplete>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.account_number"
              label="Account number"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.bank"
              label="Bank"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.iban"
              label="Iban"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.currency"
              label="Currency"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.account_name"
              label="Account name"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="h-75px">
            <v-text-field
              v-model="formData.trn"
              label="TRN"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      type_id: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      type_id: 1,
      organization_id: null,
      client_id: null,
      account_number: null,
      bank: null,
      iban: null,
      currency: null,
      account_name: null,
      trn: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/organization/bankAccounts/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },

    submitCreateForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/organization/bankAccounts/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Bank account has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        type_id: null,
        organization_id: null,
        client_id: null,
        account_number: null,
        bank: null,
        iban: null,
        currency: null,
        account_name: null,
        trn: null,
      };
    },
  },
  computed: {
    isOrganization() {
      return this.formData.type_id === 1;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    type_idErrors: function () {
      return this.handleFormValidation("type_id", this);
    },
  },
};
</script>
