<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="skus"
      class="elevation-1"
      :no-data-text="$t('no_data_available')"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("boxes") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            class="btn btn--export-filter px-5 mb-2"
            @click="showAddSkuModal()"
          >
            {{ $t("new_item") }}
          </button>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :skuItem="skuItem"
      :clientId="clientId"
      :is_unit_price_required="is_unit_price_required"
      :item="getItemForAction"
      :oldIndex="getOldIndex"
      :categories="categories"
    ></EditSku>
  </div>
</template>

<script>
import EditSku from "@/own/components/fulfillment/returned/CreateReturned/EditSku.vue";
import SwalService from "@/core/services/swal.service";
export default {
  name: "SkuDatatable",
  props: [
    "setSku",
    "skus",
    "skuItem",
    "clientId",
    "is_unit_price_required",
    "categories",
  ],
  components: { EditSku },
  data: () => ({
    dialog: false,
    itemForAction: null,
    oldIndex: null,
  }),
  computed: {
    headers() {
      return [
        // { text: "SKU ID", value: "skuId" },
        // { text: "Quantity", value: "quantity" },
        { text: this.$t("price"), value: "price" },
        { text: this.$t("weight"), value: "weight" },
        { text: this.$t("length"), value: "length" },
        { text: this.$t("width"), value: "width" },
        { text: this.$t("height"), value: "height" },
        { text: this.$t("category"), value: "category" },
        { text: this.$t("description"), value: "description" },
        { text: this.$t("actions"), value: "actions", sortable: false },
      ];
    },
    getItemForAction() {
      return this.itemForAction;
    },
    getOldIndex() {
      return this.oldIndex;
    },
  },
  methods: {
    showEditSkuModal(item) {
      let copy = { ...item };
      // const index = this.isRecordExist(copy, this.skus);
      this.itemForAction = copy;
      // this.oldIndex = index[1];
      this.oldIndex = { ...item };
      this.$nextTick(() => {
        this.$refs.editSku.toggleModal();
      });
    },
    showAddSkuModal() {
      this.oldIndex = false;
      this.itemForAction = null;
      this.$nextTick(() => {
        this.$refs.editSku.toggleModal();
      });
    },
    isRecordExist(item, items, oldAdress = false) {
      let double = [0, 0];
      if (oldAdress) {
        items.forEach((element, index) => {
          if (element.skuId == item.skuId && index != oldAdress) {
            double = [1, index];
          }
        });
        return double;
      } else {
        items.forEach((element, index) => {
          if (element.skuId == item.skuId) {
            double = [1, index];
          }
        });
        return double;
      }
    },
    // editSkus(item, oldAdress = false) {
    editSkus(item) {
      let new_skus = [...this.skus];
      let index2 = new_skus.findIndex((object) => {
        return (
          object.weight == this.oldIndex.weight &&
          object.price == this.oldIndex.price &&
          object.description == this.oldIndex.description
        );
      });
      if (index2 > -1) {
        new_skus.splice(index2, 1);
        this.setSku(new_skus);
      }

      let index = new_skus.findIndex((object) => {
        return (
          object.weight == item.weight &&
          object.price == item.price &&
          object.description == item.description
        );
      });
      if (index > -1) {
        return;
      }
      // let double = this.isRecordExist(item, new_skus, oldAdress);
      // if (oldAdress && double[0] != 1) {
      //   new_skus[oldAdress] = item;
      //   this.setSku(new_skus);
      // } else if (oldAdress && double[0] == 1) {
      //   new_skus[oldAdress] = item;
      //   this.setSku(new_skus);
      //   this.removeSkus(new_skus[double[1]]);
      // } else {
      //   if (double[0]) {
      //     new_skus[double[1]] = item;

      //     this.setSku(new_skus);
      //   } else {
      //     new_skus.push(item);
      //     this.setSku(new_skus);
      //   }
      // }
      new_skus.push(item);
      this.setSku(new_skus);
    },
    removeSkus(item) {
      let new_skus = [...this.skus];
      // let double = this.isRecordExist(item, new_skus);
      // if (double[0]) {
      //   new_skus.splice(double[1], 1);
      //   this.setSku(new_skus);
      // } else {
      //   Swal.fire({
      //     title: "Error",
      //     text: `Something went wrong!`,
      //     icon: "error",
      //     showConfirmButton: false,
      //     timer: 2500,
      //   });
      // }
      let index = new_skus.findIndex((object) => {
        return (
          object.weight == item.weight &&
          object.price == item.price &&
          object.description == item.description
        );
      });
      if (index > -1) {
        new_skus.splice(index, 1);
        this.setSku(new_skus);
      } else {
        SwalService.errorMessage({
          title: this.$t("error"),
          html: this.$t("something_went_wrong"),
        });
      }
    },
  },
};
</script>
