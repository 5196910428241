<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="600"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-3 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ $t("edit") }}</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <form id="editForm" class="row">
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.weight"
              label="Weight"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.length"
              label="Length"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.width"
              label="Width"
              clearable
              outlined
              dense
            />
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.height"
              label="Height"
              clearable
              outlined
              dense
            />
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            {{ $t("reset") }}
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            {{ $t("submit") }}
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import { SET_HAS_DIMENSIONS_OF_CARTON } from "@/core/services/store/receiving.module";

export default {
  name: "EditCartonForISS",
  data: () => ({
    dialog: false,
    asn_number: null,
    carton_number: null,
    formData: {
      weight: null,
      length: null,
      width: null,
      height: null,
    },
  }),
  methods: {
    loadDataFromServer(sendData) {
      ApiService.post(
        "/warehouse_management/receiving/edit_carton_dimension",
        sendData
      )
        .then(({ data }) => {
          this.formData = {
            weight: data.carton_number.weight,
            length: data.carton_number.length,
            width: data.carton_number.width,
            height: data.carton_number.height,
          };
          this.dialog = !this.dialog;
          this.asn_number = sendData.asn_number;
          this.carton_number = sendData.carton_number;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.resetEditForm();
      } else this.loadDataFromServer();
    },
    submitEditForm() {
      this.$store.commit(SET_PAGE_LOADING, true);
      const data = {
        ...this.formData,
        asn_number: this.asn_number,
        carton_number: this.carton_number,
      };
      ApiService.post(
        "/warehouse_management/receiving/update_carton_dimension",
        data
      )
        .then(() => {
          swalEdited();

          if (
            Number(this.formData.weight) !== 0 &&
            Number(this.formData.length) !== 0 &&
            Number(this.formData.width) !== 0 &&
            Number(this.formData.height) !== 0
          ) {
            this.$store.commit(SET_HAS_DIMENSIONS_OF_CARTON, true);
          } else {
            this.$store.commit(SET_HAS_DIMENSIONS_OF_CARTON, false);
          }

          this.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetEditForm() {
      this.formData = {
        weight: null,
        length: null,
        width: null,
        height: null,
      };
    },
  },
};
</script>
