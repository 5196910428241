<template>
  <div v-if="itemDetails" class="bg-secondary rounded p-6 mb-8 mt-4">
    <v-row>
      <v-col cols="auto" v-for="(item, index) in itemDetails" :key="index">
        <v-row class="bg-secondary rounded mt-0">
          <v-col cols="auto" class="font-weight-black py-0 pr-0">
            {{ item.text }}
          </v-col>
          <v-col class="py-0 mb-2">{{ item.value || "-" }}</v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ItemDetails",
  data: () => ({
    fieldLabels: [
      { key: "from_location", text: "From location" },
      { key: "package_code", text: "Package code" },
      { key: "sku_barcode", text: "SKU barcode" },
      { key: "serial_no", text: "Serial no" },
      { key: "batch_number", text: "Batch number" },
      { key: "left_picked_qty", text: "Left picked qty" },
    ],
  }),
  computed: {
    itemDetails: function () {
      const details =
        this.$store.getters.getSTANDARDORDERPICKINGSuggestedDetails;

      if (!Object.keys(details).length) return null;

      return this.fieldLabels
        .filter((item) => item.key in details)
        .map((item) => ({
          text: item.text,
          value: details[item.key],
        }));
    },
  },
};
</script>
