<template>
  <div
    v-if="$store.getters.getWMSPICKINGTableData"
    class="custom-content-height"
  >
    <v-data-table
      fixed-header
      light
      dense
      :height="getvh(56)"
      :items="items"
      :headers="headers"
      :items-per-page="itemPerPage"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      mobile-breakpoint="300"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>
          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="bulkRemoveOption && selected.length > 0"
            ></div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="handleFilterSidebarClick"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="permissionChecker('export') && exportHeaders"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>
      <template #item.action="{ item, value }">
        <b-button
          @click="() => $refs.pickingScanner.toggleModal(item)"
          variant="-export-filter"
        >
          Scan
        </b-button>
      </template>
    </v-data-table>
    <component
      ref="pickingScanner"
      :is="scanPicking"
      :pageLoader="pageLoad"
      :refresher="updateTableContent"
    ></component>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="itemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import Pagination from "@/own/components/pagination/Pagination.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ScanPicking from "@/own/components/warehouseManagement/picking/ScanPicking";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  SET_TABLE_PERPAGE,
  SET_TABLE_PAGE,
  SET_TABLE_FILTER,
  EXPORT_TABLE_DATA,
} from "@/core/services/store/picking2.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  props: {
    bulkRemoveOption: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    ScanPicking,
    ComponentSelector,
    DatatableDetail,
    Pagination,
    FilterSidebar,
    ExportSidebar,
  },
  data: () => ({
    scanPicking: ScanPicking,
    toolBarVisionState: false,
  }),
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    table_properties: function () {
      return this.$store.getters.getWMSPICKINGTableProperties;
    },
    headers: function () {
      /**
       * @type {[
       {
       value: string,
       text: string,
       type: string,
       sortable: string,
       exportable: string,
       visible: string,
       mobile_visible: string,
       align: string,
       itemClass: string,
       width: string,
       class: string,
       export_order: integer,
       }
       ]}
       */
      let headers;

      if (this.$store.getters.getWMSPICKINGTableHeaders) {
        headers = this.$store.getters.getWMSPICKINGTableHeaders.filter(
          (col) => col.visible === true
        );
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "action") {
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    items: function () {
      let items = null;
      if (this.$store.getters.getWMSPICKINGTableData) {
        items = this.$store.getters.getWMSPICKINGTableData.data;
      }
      return items || [];
    },
    itemPerPage: function () {
      return this.$store.getters.getWMSPICKINGTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getWMSPICKINGTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getWMSPICKINGTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getWMSPICKINGTableData.last_page;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    table_permissions: function () {
      /** @type {Array<string>} */
      let permissions = [];
      try {
        if (this.$store.getters.getWMSPICKINGTablePermissions) {
          permissions = this.$store.getters.getWMSPICKINGTablePermissions.map(
            function (perm) {
              return perm.name;
            }
          );
        }
      } catch (error) {
        // this.$swal("Can not load table permissions!");
      }
      return permissions;
    },
    exportHeaders: function () {
      /** @type {Array<{order:string,text:string,value:string}>} */
      let exportHeaders = [];
      try {
        exportHeaders =
          this.$store.getters.getWMSPICKINGTablePermissions.filter(
            (col) => col.name === "export"
          )[0].columns;
      } catch (error) {
        exportHeaders = [];
      }
      return exportHeaders;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    filters: function () {
      /** @type {Array<{name: string, title: string, type: string, related_columns: string[]}>} */
      let filters = [];
      try {
        filters = this.$store.getters.getWMSPICKINGTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
  },
  methods: {
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          warehouse_id: this.$store.getters.getSelectedWarehouse,
          ...this.$store.getters.getWMSPICKINGTableState,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    handleExportSidebarClick() {
      this.$refs.exportSidebar.$data.exportSidebar = true;
    },
    handleFilterSidebarClick() {
      this.$refs.filterSidebar.$data.filterSidebar = true;
    },
    permissionChecker(permType) {
      return this.table_permissions.includes(permType);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getWMSPICKINGTableState,
        warehouse_id: this.selectedWarehouse,
      };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);

          // this.$store.commit(SET_PAGE_LOADING, false);
          this.$nextTick(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_TABLE_FILTER, []);
      let state = {
        ...this.$store.getters.getWMSPICKINGTableState,
        warehouse_id: this.selectedWarehouse,
      };
      this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    downloadItem(columns) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.export_modal = false;
      this.$store
        .dispatch(EXPORT_TABLE_DATA, {
          columns: columns,
          warehouse_id_id: this.selectedWarehouse,
          filters: this.$store.getters.getWMSPICKINGTableState.filters,
        })
        .then(() => {
          if (this.$store.getters.getWMSPICKINGExportUrl) {
            const link = document.createElement("a");
            link.href = this.$store.getters.getWMSPICKINGExportUrl;
            link.download = "PickingExport.csv";
            link.click();
          }
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.export.toggleModal();
        });
    },
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
