<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    fullscreen
  >
    <div class="bg-white poppins position-relative min-h-100">
      <div
        class="modal-header py-3 align-center bg-white position-sticky top-0 left-0 zindex-5"
      >
        <h4 class="mb-0 font-weight-bolder poppins">Create GRN</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <CreateGrnTabDataTable ref="createGrnTabDataTable" />
        <!--end::Body-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import CreateGrnTabDataTable from "@/own/components/warehouseManagement/standardGrn/CreateGrnTabDataTable.vue";

export default {
  name: "CreateGrnPopup",
  components: { CreateGrnTabDataTable },
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal(itemId) {
      if (this.dialog) {
        this.dialog = !this.dialog;
      } else {
        this.dialog = true;
        setTimeout(() => {
          this.$refs.createGrnTabDataTable.setId(itemId);
        }, 500);
      }
    },
  },
};
</script>
