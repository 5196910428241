<template>
  <div class="menu-nav" :id="item.title">
    <div class="menu-section w-100 ma-0 menu-title">
      <inline-svg width="40px" class="px-1" :src="item.svg" />
      <h2 class="font-weight-bolder pt-1">{{ $t(item.title) }}</h2>
    </div>
    <Navi v-for="navi in item.entities" :key="navi.title" :item="navi"></Navi>
  </div>
</template>

<script>
import Navi from "@/own/components/menu/Navi.vue";
export default {
  name: "NaviCompact",
  props: ["item"],
  components: { Navi },
};
</script>

<style scoped>
.menu-title {
  display: flex;
  align-items: center;
}
</style>
