import { render, staticRenderFns } from "./ShowDetail.vue?vue&type=template&id=ab4a3a80&scoped=true"
import script from "./ShowDetail.vue?vue&type=script&lang=js"
export * from "./ShowDetail.vue?vue&type=script&lang=js"
import style0 from "./ShowDetail.vue?vue&type=style&index=0&id=ab4a3a80&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab4a3a80",
  null
  
)

export default component.exports