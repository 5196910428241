<template>
  <div v-if="table_permissions">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      show-select
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id"
      class="m-2 font-weight-bold"
      hide-default-footer
    >
      <!--    progress-->
      <template v-slot:progress>
        <div style="display: flex" class="justify-content-center">
          <v-progress-circular
            :size="40"
            :width="6"
            style="color: deeppink; display: flex"
            indeterminate
          ></v-progress-circular>
        </div>
      </template>
      <!--    progress end-->
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <!--    no-data end-->
      <!-- header -->
      <template v-slot:header.id="{ header }">
        <div :class="header.class">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.client="{ header }">
        <div :class="header.class">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.name="{ header }">
        <div :class="header.class">
          {{ header.text }}
        </div>
      </template>
      <template v-slot:header.action="{ header }">
        <div :class="header.class">
          {{ header.text }}
        </div>
      </template>
      <!-- header end -->

      <!--    table top-->
      <template v-slot:top>
        <div class="d-flex justify-content-between">
          <div class="card-header flex-wrap border-0 pb-0">
            <div class="card-title" v-if="table_properties">
              <h5 class="card-label" style="">
                {{ table_properties.title }}
                <span class="d-block text-muted pt-2 font-size-sm">{{
                  table_properties.description
                }}</span>
              </h5>
            </div>
          </div>
          <div
            class="card-toolbar row justify-content-end flex-no-wrap align-content-center"
          >
            <div class="mr-md-2 mr-sm-0" v-if="permissionChecker('create')">
              <button
                href="#"
                class="btn btn--export-filter"
                depressed
                small
                @click="showAddItemModal"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-plus-box-outline</v-icon>
                </span>
                Add Supplier
              </button>
              <add-item
                :loader="loader"
                :refresher="updateTableContent"
                :integration_id="integration_id"
                ref="addItem"
              ></add-item>
            </div>
            <div class="mr-md-2 mr-sm-0" v-if="permissionChecker('export')">
              <button
                href="#"
                class="btn btn--export-filter"
                depressed
                small
                @click="exportModal"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
              <DataTableExport
                ref="export"
                :headers="exheaders"
                :downloadItem="downloadItem"
              ></DataTableExport>
            </div>
            <div class="mr-md-2 mr-sm-0">
              <button
                href="#"
                class="btn btn--export-filter"
                depressed
                small
                @click="back"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-keyboard-backspace</v-icon>
                </span>
                Go Back
              </button>
            </div>
          </div>
        </div>
        <!-- filter container -->
        <b-collapse id="filters" class="bg-white">
          <hr />
          <Filters
            ref="filter"
            :submitFilterValues="submitFilterValues"
            :resetFilterValues="resetFilterValues"
            :filters="filters"
            :setTableFiltersValues="setTableFiltersValues"
          ></Filters>
        </b-collapse>
        <!-- filter container end -->
        <hr />
      </template>
      <!-- top end -->
      <!-- column start -->
      <template #item.id="{ item, header, value }">
        <span :class="header.itemClass">
          {{ value }}
        </span>
      </template>
      <template #item.client="{ item, header, value }">
        <user-photo :header="header" :value="value"></user-photo>
      </template>
      <template #item.name="{ item, header, value }">
        <span :class="header.itemClass">
          {{ value }}
        </span>
      </template>
      <template #item.action="{ item, header, value }">
        <edit-item
          :loader="loader"
          :refresher="updateTableContent"
          :integration_id="integration_id"
          :itemForAction="item"
          :ref="`editModal_${item.id}`"
        ></edit-item>
        <v-menu top :offset-y="offset">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" x-small v-on="on" icon color="info">
              <v-icon>mdi-settings</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(action, index) in actions.filter(
                (action) => action.isVisible == true
              )"
              :key="index"
              @click="handle_function_call(action.method, item)"
            >
              <v-card-text>
                <a class="text-left h6 btn ma-0 pa-0">
                  {{ action.title }}
                </a>
              </v-card-text>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
  </div>
</template>

<script>
import {
  UPDATE_TABLE_STRUCTURE,
  UPDATE_TABLE_DATA,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  SET_TABLE_FILTER,
  SET_FILTERS_DATA,
  SET_TABLE_PERPAGE,
  SET_TABLE_PAGE,
  EXPORT_TABLE_DATA,
} from "@/core/services/store/supplierMapping.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import EditItem from "@/own/components/datahub/integrations/validation/supplierMapping/EditItem.vue";
import AddItem from "@/own/components/datahub/integrations/validation/supplierMapping/AddItem.vue";
import UserPhoto from "@/own/components/datatable/UserPhoto.vue";
import Filters from "@/own/components/filter/Filters.vue";
import Pagination from "@/own/components/pagination/Pagination.vue";
import DataTableExport from "@/own/components/DataTableExport.vue";
import Swal from "sweetalert2";
import Apiservice from "@/core/services/api.service";

export default {
  name: "Supplier",
  props: ["integration_id", "goBack", "loader", "refresher"],
  components: {
    Filters,
    DataTableExport,
    Pagination,
    UserPhoto,
    EditItem,
    AddItem,
  },
  data: () => ({
    selected: [],
    offset: true,
    integrationItem: null,
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store
      .dispatch(UPDATE_TABLE_STRUCTURE, this.integration_id, {})
      .then(() => {});
    this.$store
      .dispatch(UPDATE_TABLE_DATA, this.integration_id, {})
      .then(() => {});
  },
  beforeCreate() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
  computed: {
    actions: function () {
      return [
        {
          title: "Validation",
          method: "validationItem",
          isVisible: this.permissionChecker("validation"),
        },
        {
          title: "Reset",
          method: "resetItem",
          isVisible: this.permissionChecker("reset"),
        },
        {
          title: "Edit",
          method: "editItem",
          isVisible: this.permissionChecker("update"),
        },
        {
          title: "Remove",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
      ];
    },
    //pagination
    getitemPerPage: function () {
      return this.$store.getters.getSUPPLIERMAPPINGTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getSUPPLIERMAPPINGTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getSUPPLIERMAPPINGTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getSUPPLIERMAPPINGTableData.last_page;
    },
    //filters
    filters: function () {
      return this.$store.getters.getSUPPLIERMAPPINGTableFilters;
    },
    itemPerPage: function () {
      return this.$store.getters.getSUPPLIERMAPPINGTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getSUPPLIERMAPPINGTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.integration_id,
            this.$store.getters.getSUPPLIERMAPPINGTableState
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.integration_id,
            this.$store.getters.getSUPPLIERMAPPINGTableState
          );
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (
          this.$store.getters.getSUPPLIERMAPPINGTableState.sortOrder === "asc"
        ) {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(
          UPDATE_TABLE_DATA,
          this.integration_id,
          this.$store.getters.getSUPPLIERMAPPINGTableState
        );
      },
    },
    headers: function () {
      let headers = null;
      try {
        if (this.$vuetify.breakpoint.smAndDown) {
          headers = this.$store.getters.getSUPPLIERMAPPINGTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          headers = this.$store.getters.getSUPPLIERMAPPINGTableHeaders.filter(
            (col) => col.visible === true
          );
        }
        headers.forEach((header) => {
          header.class =
            "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
          header.itemClass = "second-text";
          header.width = undefined;
          if (header.value === "action") {
            header.class =
              "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
          }
        });
      } catch {
        // headers = this.$store.getters[this.store_names.getTableHeaders].filter(
        //   (col) => col.visible === true
        // );
      }

      return headers;
    },
    exheaders: function () {
      // let headers = this.$store.getters.getSUPPLIERMAPPINGTableHeaders.filter(
      //   col => col.exportable == true
      // );
      // return headers;

      let headers =
        this.$store.getters.getSUPPLIERMAPPINGTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      return headers;
    },
    items: function () {
      if (this.$store.getters.getSUPPLIERMAPPINGTableData) {
        return this.$store.getters.getSUPPLIERMAPPINGTableData.data;
      }
      return undefined;
    },
    table_permissions: function () {
      if (this.$store.getters.getSUPPLIERMAPPINGTablePermissions) {
        return this.$store.getters.getSUPPLIERMAPPINGTablePermissions.map(
          function (perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getSUPPLIERMAPPINGTableProperties;
    },
  },
  methods: {
    // pagination
    setitemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.integration_id,
          this.$store.getters.getSUPPLIERMAPPINGTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((res) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          Swal.fire({
            title: "",
            text: `${res}`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
      this.$store.commit(SET_FILTERS_DATA, this.men);
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.men = [];
      this.queryObj = {};
      this.$refs.filter.$refs.filter.forEach((filter) => {
        filter.clearData();
      });
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.integration_id,
          this.$store.getters.getSUPPLIERMAPPINGTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    // filters end
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    editItem(item) {
      this.$refs[`editModal_${item.id}`].toggleModal();
    },
    validationItem(item) {
      this.$refs[`validationModal_${item.id}`].toggleModal();
    },
    resetItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to reset this message?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reset it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          let data = {
            message_id: item.id,
          };
          Apiservice.post("/datahub/SUPPLIERMAPPING/reset", data)
            .then(() => {
              Swal.fire("Reset!", "The Message has been reset.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          let data = {
            integration_id: this.integration_id,
            supplier_id: item.id,
          };
          Apiservice.post("/validations/supplierMapping/destroy", data)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "The Supplier Data has been deleted.",
                "success"
              );
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.integration_id,
          this.$store.getters.getSUPPLIERMAPPINGTableState
        )
        .then(() => {});
      this.$forceUpdate();
      this.pageLoad(false);
    },
    showAddItemModal() {
      this.$refs["addItem"].toggleModal();
    },
    exportModal() {
      this.$refs.export.toggleModal();
    },
    downloadItem(columns) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.export_modal = false;
      this.$store
        .dispatch(EXPORT_TABLE_DATA, {
          columns: columns,
          integration_id: this.integration_id,
          filters: this.$store.getters.getSUPPLIERMAPPINGTableState.filters,
        })
        .then(() => {
          if (this.$store.getters.getSUPPLIERMAPPINGExportUrl) {
            const link = document.createElement("a");
            link.href = this.$store.getters.getSUPPLIERMAPPINGExportUrl;
            link.download = "SUPPLIERMAPPINGExport.csv";
            link.click();
          }
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.export.toggleModal();
        });
    },
    back() {
      this.goBack();
    },
  },
};
</script>
