<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Adjustment</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="row pa-0 ma-0">
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-autocomplete
              v-model="formData.warehouse_id"
              label="Warehouse"
              item-text="text"
              item-value="index"
              :items="serverData.warehouses"
              clearable
              outlined
              dense
              :error-messages="warehouse_idErrors"
              @input="$v.formData.warehouse_id.$touch()"
              @blur="$v.formData.warehouse_id.$touch()"
              disabled
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
              dense
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
              disabled
            ></v-autocomplete>
          </div>

          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.batch_number"
              label="Batch number"
              clearable
              outlined
              dense
              :error-messages="batch_numberErrors"
              @input="$v.formData.batch_number.$touch()"
              @blur="$v.formData.batch_number.$touch()"
              disabled
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.location"
              label="Location"
              clearable
              outlined
              dense
              :error-messages="locationErrors"
              @input="$v.formData.location.$touch()"
              @blur="$v.formData.location.$touch()"
              disabled
            ></v-text-field>
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0 h-60px">
            <v-combobox
              v-model="formData.sku_id"
              dense
              label="SKU"
              item-text="systemCode"
              item-value="skuId"
              clearable
              outlined
              :items="items"
              hide-no-data
              hide-selected
              disabled
              :error-messages="sku_idErrors"
              @input="$v.formData.sku_id.$touch()"
              @blur="$v.formData.sku_id.$touch()"
            ></v-combobox>
          </div>
          <div class="h-75px col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="serverData.inventory_balance.sku_component.text2"
              dense
              disabled
              label="SKU description"
              clearable
              outlined
              hide-no-data
              hide-selected
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.available_qty"
              label="Available quantity"
              min="0"
              type="number"
              @change="() => validateMinValue('available_qty', 0, 'formData')"
              outlined
              dense
              readonly
              disabled
              :error-messages="available_qtyErrors"
              @input="$v.formData.available_qty.$touch()"
              @blur="$v.formData.available_qty.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.allocated_qty"
              label="Allocated quantity"
              outlined
              dense
              readonly
              disabled
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.adjusted_qty"
              @change="
                (value) => {
                  onChangePositiveNumberMaker(value, 'adjusted_qty', 0);
                }
              "
              label="Adjusted quantity"
              min="1"
              type="number"
              clearable
              outlined
              dense
              :error-messages="adjusted_qtyErrors"
              @input="$v.formData.adjusted_qty.$touch()"
              @blur="$v.formData.adjusted_qty.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0 h-60px">
            <v-select
              v-model="formData.reason_code_id"
              label="Reason code"
              item-text="text"
              item-value="index"
              :items="serverData.reason_codes"
              clearable
              outlined
              dense
              :error-messages="reason_code_idErrors"
              @input="$v.formData.reason_code_id.$touch()"
              @blur="$v.formData.reason_code_id.$touch()"
            ></v-select>
          </div>

          <div class="col-12 my-0 py-0 h-60px">
            <v-text-field
              v-model="formData.comment"
              label="Comment"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "AddAdjustment",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  components: {},
  validations() {
    return {
      formData: {
        warehouse_id: { required },
        customer_id: {
          required,
        },
        location: { required },
        batch_number: { required },
        sku_id: { required },
        inventory_balance_id: { required },
        available_qty: { required },
        adjusted_qty: { required },
        reason_code_id: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,
    showData: null,
    serverData: null,
    formData: {
      warehouse_id: null,
      customer_id: null,
      location: null,
      batch_number: null,
      sku_id: null,

      inventory_balance_id: null,
      available_qty: null,
      allocated_qty: null,
      adjusted_qty: null,
      reason_code_id: null,
      comment: null,
    },
    doneTypingInterval: 1500,
    typingTimer: null,
  }),
  methods: {
    onChangePositiveNumberMaker(
      value,
      obj_name,
      min = 0,
      max = -1,
      data_name = "formData"
    ) {
      if (value < min) {
        this[data_name][obj_name] = min;
      } else if (max > 0 && value > max) {
        this[data_name][obj_name] = max;
      }
    },
    loadShowDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/warehouse_management/inventory_balances/show", {
        id: this.item.id,
      })
        .then((response) => {
          this.showData = response.data;
          this.loadDataFromServer();
        })
        .catch(() => {})
        .finally(() => {});
    },

    loadDataFromServer() {
      this.pageLoader(true);

      ApiService.post("/warehouse_management/inventory_adjustments/create")
        .then((response) => {
          this.serverData = { ...this.showData, ...response.data };
          this.resetCreateForm();
        })
        .catch(() => {})
        .finally(() => {
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
        this.serverData = null;
        this.showData = null;
      } else {
        this.loadShowDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let data = {
        ...this.formData,
      };
      data.sku_id = this.formData.sku_id.skuId;
      data.sku_barcode = this.formData.sku_id.systemCode;
      this.pageLoader(true);
      ApiService.post("/warehouse_management/inventory_adjustments/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetCreateForm();
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        warehouse_id: null,
        customer_id: null,
        location: null,
        batch_number: null,
        sku_id: null,
        inventory_balance_id: null,
        available_qty: null,
        allocated_qty: null,
        adjusted_qty: null,
        reason_code_id: null,
        comment: null,
      };
      if (this.serverData) {
        this.serverData.warehouses = [
          {
            index: this.serverData.inventory_balance.warehouse_id,
            text: this.serverData.inventory_balance.warehouse,
          },
        ];
        this.serverData.clients = [
          {
            index: this.serverData.inventory_balance.customer_id,
            text: this.serverData.inventory_balance.customer,
          },
        ];
        this.formData.sku_id = {
          skuId: this.serverData.inventory_balance.sku_id,
          systemCode: this.serverData.inventory_balance.sku_barcode,
        };
        this.formData.warehouse_id =
          this.serverData.inventory_balance.warehouse_id;
        this.formData.customer_id =
          this.serverData.inventory_balance.customer_id;
        this.formData.location = this.serverData.inventory_balance.location;
        this.formData.batch_number =
          this.serverData.inventory_balance.batch_number;
        this.formData.available_qty =
          this.serverData.inventory_balance.available_qty;
        this.formData.allocated_qty =
          this.serverData.inventory_balance.allocated_qty;
        this.formData.inventory_balance_id =
          this.serverData.inventory_balance.id;
        this.entries = [
          {
            skuId: this.serverData.inventory_balance.sku_id,
            systemCode: this.serverData.inventory_balance.sku_barcode,
          },
        ];
      }
    },
  },
  computed: {
    items() {
      return this.entries;
    },
    item() {
      return this.$store.getters.getItemForAction;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },

    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id", this);
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    locationErrors: function () {
      return this.handleFormValidation("location", this);
    },
    batch_numberErrors: function () {
      return this.handleFormValidation("batch_number", this);
    },
    sku_idErrors: function () {
      return this.handleFormValidation("sku_id", this);
    },

    inventory_balance_idErrors: function () {
      return this.handleFormValidation("inventory_balance_id", this);
    },
    available_qtyErrors: function () {
      return this.handleFormValidation("available_qty", this);
    },
    adjusted_qtyErrors: function () {
      return this.handleFormValidation("adjusted_qty", this);
    },
    reason_code_idErrors: function () {
      return this.handleFormValidation("reason_code_id", this);
    },
  },
};
</script>
