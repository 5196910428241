<template>
  <v-card class="mt-4">
    <v-toolbar color="primary h5 text-white">
      <div class="w-100 d-flex align-items-center justify-space-between">
        <div>SKU</div>
        <div class="">
          <button class="btn btn-light px-5 py-3 ls1" @click="generateGrn">
            Generate
          </button>
        </div>
      </div>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-data-table
        v-model="selected"
        item-key="id"
        show-select
        disable-sort
        :headers="headersForMainTab"
        :items="getItems"
        :server-items-length="total"
        class="elevation-0"
        disable-pagination
        hide-default-footer
      >
        <template
          v-slot:header.data-table-select="{ props: { value }, on: { input } }"
        >
          <div
            class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              v-model="allSelected"
              @click="selectAll"
            />
          </div>
        </template>
        <template v-slot:item.data-table-select="{ item }">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              v-model="selected"
              :value="item"
            />
          </div>
        </template>

        <!--        <template v-slot:item.sku_id="{ value }">-->
        <!--          <span class="second-text"> {{ value }}</span>-->
        <!--        </template>-->

        <!--        <template v-slot:item.asn_sku_id="{ value }">-->
        <!--          <span class="second-text"> {{ value }}</span>-->
        <!--        </template>-->

        <template v-slot:item.sku_barcode="{ value }">
          <span class="second-text"> {{ value }}</span>
        </template>

        <!--        <template v-slot:item.description="{ value }">-->
        <!--          <span class="max-w-100px"> {{ value }}</span>-->
        <!--        </template>-->
        <!--        <template v-slot:item.status="{ value }">-->
        <!--          <StatusColumn :value="value" />-->
        <!--        </template>-->
      </v-data-table>
    </v-card-text>
    <GenerateGrnPopup
      ref="generateGrnPopup"
      :getDataFromApi="getDataFromApi"
      :type="type"
    />
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import StatusColumn from "@/own/components/datatable/StatusColumn.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import GenerateGrnPopup from "@/own/components/warehouseManagement/standardGrn/GenerateGrnPopup.vue";
import SwalService from "@/core/services/swal.service";

export default {
  name: "CreateGrnTabDataTable",
  components: { GenerateGrnPopup },
  data: () => ({
    id: null,
    // tabs: null,
    total: 0,
    options: {},
    items: [],
    selected: [],
    allSelected: false,
    type: null,
    // item_per_page_list: [20, 30, 40, 50],
  }),
  beforeDestroy() {
    this.selected = [];
    this.allSelected = false;
    this.options = {};
  },
  methods: {
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      if (event.target.checked) {
        this.items.map((item) => {
          this.selected.push(item);
        });
      } else this.selected.splice(0, this.selected.length);
    },
    generateGrn() {
      if (this.selected && this.selected.length && this.type) {
        this.$refs.generateGrnPopup.toggleModal(
          this.id,
          this.selected.map((item) => item[this.type])
        );
      } else {
        SwalService.warningMessage({
          html: this.$t("please_select_at_least_one_record"),
        });
      }
    },
    setId(id) {
      this.id = id;
      this.getDataFromApi();
    },
    getDataFromApi() {
      if (!this.id) return;
      this.$store.commit(SET_PAGE_LOADING, true);

      let data = {
        id: this.id,
        warehouse_id: this.$store.getters.getSelectedWarehouse,
      };

      ApiService.post(
        "/warehouse_management/custom/standard_grn/create-grn",
        data
      )
        .then((response) => {
          this.total = response.data.data.length;
          this.items = response.data.data;
          this.type = response.data.type;
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    getItems() {
      return this.items;
    },
    headersForMainTab() {
      // if (this.getItems?.length) {
      //   if (this.getItems[0].hasOwnProperty("asn_sku_id")) {
      //     headers.unshift({
      //       text: "Asn SKU ID",
      //       value: "asn_sku_id",
      //     });
      //   }
      //   if (this.getItems[0].hasOwnProperty("sku_id")) {
      //     headers.unshift({
      //       text: "SKU ID",
      //       value: "sku_id",
      //     });
      //   }
      // }

      return [
        // { text: "ID", value: "id" },
        // { text: "ASN ID", value: "asn_id" },
        { text: "Package code", value: "package_code" },
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "VIN No", value: "vin_no" },
        { text: "Serial number", value: "serial_number" },
        { text: "Batch number", value: "batch_number" },
        { text: "Lot no", value: "lot_no" },
        { text: "Putaway quantity", value: "putaway_qty" },
      ];
    },
  },

  // watch: {
  //   options: {
  //     handler() {
  //       this.getDataFromApi();
  //     },
  //     deep: true,
  //   },
  // },
};
</script>
