import { validationMixin } from "vuelidate";
export default {
  mixins: [validationMixin],
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData", extraData) {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName]["$dirty"]) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push(this.$t("this_field_is_required"));
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push(this.$t("this_field_must_be_greater_than_0"));
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push(this.$t("this_url_is_invalid"));
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push(this.$t("this_must_be_a_number"));
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push(this.$t("this_email_address_is_invalid"));
      }
      if ("decimal" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].decimal &&
          errors.push(this.$t("this_must_be_a_decimal"));
      }
      if ("minLength" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].minLength &&
          errors.push(
            `${this.$t("minimum_valid_input_length_is_:num:_character").replace(
              ":num:",
              extraData?.minLength
            )}`
          );
      }
      if ("maxLength" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxLength &&
          errors.push(
            `${this.$t("maximum_valid_input_length_is_:num:_character").replace(
              ":num:",
              extraData?.maxLength
            )}`
          );
      }
      if ("sameAsPassword" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].sameAsPassword &&
          errors.push("Passwords are not matched");
        // errors.push(this.$t("password_didnt_mached"));
      }
      return errors;
    },
  },
};
