<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row ma-0">
          <div class="col-12 col-md-6 h-75px" v-if="!$store.getters.isClient">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              :items="serverData.clients"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="customer_idErrors"
              @blur="$v.formData.customer_id.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-autocomplete
              v-model="formData.day_of_week"
              label="Day of the week"
              :items="serverData.days_of_week"
              item-text="value"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="day_of_weekErrors"
              @blur="$v.formData.day_of_week.$touch()"
            />
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-dialog
              ref="start_time_dialog"
              v-model="start_time_dialog"
              :return-value.sync="formData.start_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.start_time"
                  label="Start time"
                  append-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  :error-messages="startTimeErrors"
                  @blur="$v.formData.start_time.$touch()"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="start_time_dialog"
                v-model="formData.start_time"
                full-width
                :max="formData.end_time"
                :use-seconds="false"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_time_dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.start_time_dialog.save(formData.start_time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>

          <div class="col-12 col-md-6 h-75px">
            <v-dialog
              ref="end_time_dialog"
              v-model="end_time_dialog"
              :return-value.sync="formData.end_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.end_time"
                  label="End time"
                  append-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  :error-messages="endTimeErrors"
                  @blur="$v.formData.end_time.$touch()"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="end_time_dialog"
                v-model="formData.end_time"
                full-width
                :min="formData.start_time"
                :use-seconds="false"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_time_dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.end_time_dialog.save(formData.end_time)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>
        </form>

        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        customer_id: {
          required: requiredIf(() => {
            return !this.$store.getters.isClient;
          }),
        },
        day_of_week: { required },
        start_time: { required },
        end_time: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      day_of_week: null,
      start_time: null,
      end_time: null,
    },
    start_time_dialog: false,
    end_time_dialog: false,
    start_time: null,
    end_time: null,
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        day_of_week: null,
        start_time: null,
        end_time: null,
      };
    },
    loadDataFromServer() {
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        ApiService.post("/wes/wesCustomerWorkingHours/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = data.data;

            this.pageLoader(false);
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        ApiService.post("/wes/wesCustomerWorkingHours/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("minValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].minValue &&
          errors.push("Minimum value should be 10");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let sendData = {
        ...this.formData,
      };

      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/wes/wesCustomerWorkingHours/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function() {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function() {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function() {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    userId: function() {
      // return this.$store.getters.isClient
      //   ? this.$store.getters.currentUser.data.id
      //   : this.formData.clients;
      return this.$store.getters.currentUser.data.id;
    },
    day_of_weekErrors: function() {
      return this.handleFormValidation("day_of_week");
    },
    customer_idErrors: function() {
      return this.handleFormValidation("customer_id");
    },
    startTimeErrors: function() {
      return this.handleFormValidation("start_time");
    },
    endTimeErrors: function() {
      return this.handleFormValidation("end_time");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
  },
};
</script>
