<template>
  <section>
    <div
      class="ma-0 row-bg-color custom-container-height"
      v-if="$store.getters.getWMSRECEIVINGTableProperties"
    >
      <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
        <datatable-detail
          class="col-9 col-md-9 col-sm-9"
          :table_properties="$store.getters.getWMSRECEIVINGTableProperties"
        />
        <div
          class="min-w-250px max-w-500px pl-4 mt-8 font-weight-medium w-100 text-capitalize"
        >
          <v-text-field
            v-model="asnBarcode"
            label="Scan ASN"
            type="text"
            autofocus
            outlined
            clearable
            class="poppins"
            append-icon="mdi-barcode-scan"
            @click:append="handleASNScanClick"
            @keyup.enter="handleASNScanClick"
            :disabled="!selectedWarehouse"
          />
        </div>
        <DataTable v-if="selectedWarehouse" />
        <ReceivingDialog ref="receivingDialog" />
      </div>
    </div>
  </section>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DataTable from "@/own/components/warehouseManagement/receiving/DataTable.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import {
  SET_BLIND_RECEIVE,
  SET_CAN_DECIDE_RECEIVING_TYPE,
  SET_IS_BAQ_ASN,
  SET_RECEIVE_TYPE,
  SET_SCANNED_CARTONS_FOR_ISS,
  SET_SCANNED_SKUS,
  SET_SIMPLE_RECEIVE,
  SET_SKUS,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/receiving.module";
import ReceivingDialog from "@/own/components/warehouseManagement/receiving/ReceivingDialog";

export default {
  name: "Receiving",
  components: { ReceivingDialog, DatatableDetail, DataTable },
  beforeMount() {
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  mounted() {
    if (this.selectedWarehouse) {
      this.$store.dispatch(UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
      this.$store.commit(SET_PAGE_LOADING, false);
    }
  },
  data: function () {
    return {
      asnBarcode: "",
    };
  },
  methods: {
    handleASNScanClick() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/receiving/show", {
        asn_number: this.asnBarcode,
      })
        .then(({ data }) => {
          // this.$store.commit(SET_SKUS, data.skus);
          // this.$store.commit(SET_IS_BAQ_ASN, data.is_baq_asn);
          // this.$refs.receivingDialog.toggleModal(this.asnBarcode);

          this.$store.commit(SET_SKUS, data.skus);
          this.$store.commit(SET_BLIND_RECEIVE, false);
          this.$store.commit(SET_SIMPLE_RECEIVE, false);
          this.$store.commit(SET_IS_BAQ_ASN, data.is_baq_asn);
          this.$store.commit(SET_RECEIVE_TYPE, data.receiving_type);
          this.$store.commit(SET_SCANNED_SKUS, []);
          this.$store.commit(SET_SCANNED_CARTONS_FOR_ISS, []);
          this.$refs.receivingDialog.toggleModal(
            this.asnBarcode,
            data.skus.url
          );
          this.$store.commit(
            SET_CAN_DECIDE_RECEIVING_TYPE,
            data.can_decide_receiving_type
          );
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    selectedWarehouse(newCount) {
      if (newCount) {
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store
          .dispatch(UPDATE_TABLE_DATA, {
            warehouse_id: newCount,
          })
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            // console.log(`We have ${newCount} fruits now, yay!`);
          });
      }
    },
  },
};
</script>

<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
