<template>
  <div class="p-4 bg-white rounded-lg">
    <DataTable />
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/picking.module";
import DataTable from "@/own/components/warehouseManagement/picking/DataTable.vue";

export default {
  name: "Picking",
  components: { DataTable },
  async mounted() {
    this.$store.commit(SET_PAGE_LOADING, true);
    await this.$store.dispatch(UPDATE_TABLE_STRUCTURE);

    if (!this.selectedWarehouse) {
      this.$store.commit(SET_PAGE_LOADING, false);
    } else {
      this.updateTableContent();
    }
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    updateTableContent() {
      this.$store
        .dispatch(UPDATE_TABLE_DATA, { warehouse_id: this.selectedWarehouse })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  watch: {
    selectedWarehouse() {
      if (this.selectedWarehouse) {
        this.updateTableContent();
      }
    },
  },
};
</script>
