<template>
  <v-data-table
    :headers="headers"
    :items="statusData"
    class="elevation-1"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <AddStatus
          :statusForCarrier="statusForCarrier"
          :statusForCustomer="statusForCustomer"
          :addStatus="addStatus"
          :cod="cod"
          :statusType="statusType"
          :validateStatus="validateStatus"
        ></AddStatus>
      </v-toolbar>
    </template>
    <template v-slot:item.label="{ item }">
      {{ labelText(item.status_id) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <!-- {{item}} -->
      <EditStatus
        :cod="cod"
        :item="item"
        :statusForCarrier="statusForCarrier"
        :statusForCustomer="statusForCustomer"
        :updateStatus="updateStatus"
        :statusType="statusType"
        :validateStatus="validateStatus"
      ></EditStatus>
      <v-icon small @click="removeStatus(item, statusType)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import EditStatus from "@/own/components/finance/cod/minicomp/EditStatus";
import AddStatus from "@/own/components/finance/cod/minicomp/AddStatus";
export default {
  name: "StatusDatatable",
  components: { EditStatus, AddStatus },
  props: [
    "addStatus",
    "removeStatus",
    "updateStatus",
    "statusData",
    "cod",
    "statusType",
    "editorData",
  ],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    statusForCustomer: [
      {
        index: 340,
        text: "Pending",
      },
      {
        index: 350,
        text: "Confirmed",
      },
      {
        index: 360,
        text: "Remitted",
      },
      {
        index: 370,
        text: "Refunded",
      },
    ],
    statusForCarrier: [
      {
        index: 300,
        text: "Pending",
      },
      {
        index: 310,
        text: "Confirmed",
      },
      {
        index: 320,
        text: "Collected",
      },
      {
        index: 330,
        text: "Refunded",
      },
    ],
    headers: [
      // { text: "Status", value: "status_id" },
      { text: "Label", value: "label" },
      { text: "Rate", value: "rate" },
      { text: "Description", value: "description" },
      { text: "Date", value: "date" },
      { text: "Actions", value: "actions" },
    ],
  }),

  methods: {
    labelText(val) {
      // console.log("status data is ", this.statusData);
      if (this.statusType == "customer") {
        return this.statusForCustomer.filter((status) => status.index == val)[0]
          .text;
      } else {
        return this.statusForCarrier.filter((status) => status.index == val)[0]
          .text;
      }
    },
    validateStatus(status, statusType) {
      // console.log(status, statusType);
      if (statusType === "customer") {
        let modifiedData = [...this.editorData.customer];
        modifiedData.push(status);
        let validatingStatus = modifiedData.map((status) => status.status_id);
        let trueStatus = this.statusForCustomer
          .map((status) => status.index)
          .splice(0, this.validateStatus.length + 2);
        // console.log(trueStatus, validatingStatus);
        if (JSON.stringify(trueStatus) === JSON.stringify(validatingStatus)) {
          return true;
        } else {
          return false;
        }
      } else {
        let modifiedData = [...this.editorData.carrier];
        modifiedData.push(status);
        let validatingStatus = modifiedData.map((status) => status.status_id);
        let trueStatus = this.statusForCarrier
          .map((status) => status.index)
          .splice(0, this.validateStatus.length + 2);
        // console.log(trueStatus, validatingStatus);
        if (JSON.stringify(trueStatus) === JSON.stringify(validatingStatus)) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
};
</script>
