<template>
  <div>
    <v-card>
      <v-card-title
        tag="h1"
        class="text--secondary pb-0 ml-5 font-weight-medium font-size-h3 pt-9"
      >
        Packable Quantities
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="11" class="mt-5 mx-5">
            <div class="bg-secondary rounded p-6">
              <v-row>
                <v-col cols="5" md="6" xl="5">
                  <v-row class="bg-secondary font-size-sm rounded mt-0">
                    <v-col cols="auto" class="font-weight-black py-0 pr-0">
                      System Order Number:
                    </v-col>
                    <v-col class="py-0">{{
                      orderDetails?.system_order_number || "Unknown"
                    }}</v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="bg-secondary font-size-sm rounded">
                    <v-col cols="auto" class="font-weight-black pr-0">
                      Client Reference:
                    </v-col>
                    <v-col
                      >{{ orderDetails?.order_client_reference || "Unknown" }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-row class="bg-secondary font-size-sm rounded">
                    <v-col cols="auto" class="font-weight-black pr-0">
                      Status:
                    </v-col>
                    <v-col>{{ orderDetails?.status || "Unknown" }} </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12">
            <packing-tasks-table :hide-status="false" />
          </v-col>
        </v-row>
        <div class="position-relative">
          <div class="d-flex justify-content-center">
            <div class="zindex-4 bg-white px-8">
              <button
                class="btn btn--export-filter btn-sm py-3 mt-5 z-"
                @click="addBox()"
              >
                <span class="svg-icon mr-0 pr-0">
                  <v-icon size="20">mdi-package-variant</v-icon>
                </span>
                Add Box
              </button>
            </div>
          </div>
          <hr class="position-absolute w-100 divider" />
        </div>
        <!-- summary table start -->
        <v-data-table
          dense
          item-key="id"
          type="number"
          :headers="headers"
          :items="packedItems"
          class="my-6 pl-4 pr-8"
          hide-default-footer
          mobile-breakpoint="300"
        >
          <template v-slot:top>
            <v-card-title
              tag="h1"
              class="text--secondary pb-0 ml-0 pl-0 font-weight-medium font-size-h3 pb-5"
            >
              Packing summary
            </v-card-title>
          </template>
          <template v-slot:item.action="{ item }">
            <div class="d-flex">
              <b-button
                @click="editBox(item)"
                variant="primary"
                class="px-3 py-1 mr-2"
              >
                Edit
              </b-button>
              <b-button
                @click="printBox(item)"
                variant="info"
                class="px-3 py-1"
              >
                Print
              </b-button>
            </div>
          </template>
        </v-data-table>
        <!-- summary table end -->
        <!--begin::Actions-->
        <div class="py-6 d-flex flex-grow-1 align-end justify-center">
          <button
            @click="print"
            type="submit"
            class="btn btn-info px-9 py-3 ls1 mr-3"
          >
            Print
          </button>
          <button
            @click="clearData"
            type="submit"
            class="btn btn--actions px-9 py-3 ls1"
          >
            Close
          </button>
        </div>
        <!--end::Actions-->
      </v-card-text>
    </v-card>
    <component ref="box_details" v-bind:is="boxDetailsModal"></component>
    <component
      ref="invoice_modal"
      :refresher="refresher"
      v-bind:is="invoiceModal"
    ></component>
  </div>
</template>

<script>
// import Swal from "sweetalert2";

import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  // CONFIRM_BATCH_PACKING,
  CREATE_NEW_BOX,
  SET_BOX_INFO,
} from "@/core/services/store/packing2.module";
import PackingTasksTable from "@/own/components/warehouseManagement/packing/PackingTasksTable.vue";
import BoxDetailsModal from "@/own/components/warehouseManagement/packing/BoxDetailsModal.vue";
import InvoiceModal from "@/own/components/warehouseManagement/packing/InvoiceModal.vue";

export default {
  name: "BatchPacking",
  props: ["refresher"],
  components: { PackingTasksTable, BoxDetailsModal },
  data: () => ({
    boxDetailsModal: BoxDetailsModal,
    invoiceModal: InvoiceModal,
    headers: [
      {
        text: "Box",
        align: "start",
        sortable: false,
        value: "box_no",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "No of SKUs",
        align: "center",
        sortable: false,
        value: "no_of_alt_skus",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "No of Alt SKUs",
        align: "center",
        sortable: false,
        value: "no_of_alt_skus",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Total SKU Quantities",
        align: "center",
        sortable: false,
        value: "total_sku_quantites",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
    ],
  }),
  methods: {
    addBox() {
      this.$store
        .dispatch(CREATE_NEW_BOX, {
          system_order_number: this.orderDetails.system_order_number,
        })
        .then(() => {
          this.$refs.box_details.toggleModal();
        });
    },
    editBox(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
        system_order_number: this.orderDetails.system_order_number,
        order_box_id: item.id,
      };
      ApiService.post(`/warehouse_management/packing/edit_box`, payload)
        .then(({ data }) => {
          this.$store.commit(SET_BOX_INFO, data.box);
          this.$refs.box_details.selectedItems = data.skus;
          return data;
        })
        .then((data) => {
          const { height, weight, length, width } = data.box;
          this.$refs.box_details.formData = {
            height,
            weight,
            length,
            width,
          };
        })
        .then(() => {
          this.$refs.box_details.toggleModal();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    print() {
      this.$refs.invoice_modal.toggleModal();
    },
    printBox(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: item.id };
      ApiService.post("/warehouse_management/packing/print", data)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.path;
          link.target = "_blank";
          // link.download = "orders.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 2500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    clearData() {
      this.refresher();
    },
  },
  computed: {
    packedItems: function () {
      return this.$store.getters.getWMSPACKINGBatchPackingBox;
    },
    // packingSummary: function () {
    //   return this.packedItems.map((item, index) => ({
    //     box_no: index + 1,
    //     skus: item.skus.map((item) => item.sku),
    //     tote_id: item.skus.map((item) => item.tote_id),
    //     packed_qty: item.skus.map((item) => item.packed_qty),
    //   }));
    // },
    skuTasks: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
    isAllItemsPacked: function () {
      return this.skuTasks.every((item) => item.qty === 0);
    },
    orderDetails: function () {
      return this.$store.getters.getWMSPACKINGOrderDetails;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>
<style lang="scss" scoped>
.divider {
  bottom: 10px;
  background-color: gainsboro;
}
</style>
