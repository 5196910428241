<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <!--    <div class="py-4 text-h4">Delivery Form</div>-->
    <form id="createForm" class="pa-3 pt-0 pb-0 row elevation-0">
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.delivery_units"
          clearable
          outlined
          :error-messages="unit_idErrors"
          @input="$v.formData.unit_id.$touch()"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.order_date"
          label="Invoicing order date"
          item-text="text"
          item-value="index"
          :items="serverData.order_dates"
          clearable
          outlined
          :error-messages="order_dateErrors"
          @input="$v.formData.order_date.$touch()"
          @blur="$v.formData.order_date.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.order_types"
          label="Order types"
          item-text="text"
          item-value="index"
          :items="serverData.order_types"
          single-line
          multiple
          clearable
          outlined
          :error-messages="order_typesErrors"
          @input="$v.formData.order_types.$touch()"
          @blur="$v.formData.order_types.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.country_origin_id"
          label="Country of origin"
          item-text="text"
          item-value="index"
          :items="serverData.countries"
          clearable
          outlined
          :error-messages="country_origin_idErrors"
          @input="$v.formData.country_origin_id.$touch()"
          @blur="$v.formData.country_origin_id.$touch()"
        ></v-select>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.cod_price"
          label="Cod price"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('cod_price', 0, 'formData')"
          min="0"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.cod_percentage"
          label="Cod percentage"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('cod_percentage', 0, 'formData')"
          min="0"
        ></v-text-field>
      </div>

      <!-- <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.remote_area"
          label="Remote area multiplier"
          clearable
          outlined
          type="Number"
          min="0"
        ></v-text-field>
      </div> -->
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.tax_percentage"
          label="Tax percentage"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('tax_percentage', 0, 'formData')"
          min="0"
          :error-messages="tax_percentageErrors"
          @input="$v.formData.tax_percentage.$touch()"
          @blur="$v.formData.tax_percentage.$touch()"
        ></v-text-field>
      </div>
      <div
        class="col-12 col-sm-6 d-flex align-center justify-space-between mt-0 pt-0"
      >
        <h5 class="mb-0 mt-0 pt-0 font-weight-regular">
          Combine with fulfillment on invoice
        </h5>
        <v-switch
          v-model="formData.combine_with_fulfillment_on_invoice"
          dense
          hide-details
          class="mt-0"
        ></v-switch>
      </div>
      <div class="col-12 d-flex flex-wrap justify-center align-center">
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-radio-group
            label="Remote area mode"
            v-model="formData.remote_area_type"
          >
            <v-radio label="Multiplier" :value="0"></v-radio>
            <v-radio label="Flat rate" :value="1"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-text-field
            v-model="formData.remote_area"
            :label="remoteAreaValueLabel"
            clearable
            outlined
            type="Number"
            @change="() => validateMinValue('remote_area', 0, 'formData')"
            min="0"
          ></v-text-field>
        </div>
      </div>
      <div class="col-12 d-flex flex-wrap justify-center align-center">
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-radio-group label="Discount" v-model="formData.discount_type">
            <v-radio label="Flat rate" :value="0"></v-radio>
            <v-radio label="Percentage" :value="1"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-text-field
            v-model="formData.discount"
            :label="discountValueLabel"
            clearable
            outlined
            type="Number"
            @change="() => validateMinValue('discount', 0, 'formData')"
            min="0"
            :error-messages="discountErrors"
            @input="$v.formData.discount.$touch()"
            @blur="$v.formData.discount.$touch()"
          ></v-text-field>
        </div>
      </div>
      <div class="col-12">
        <v-radio-group
          label="Destination"
          v-model="formData.destination_type"
          row
          :error-messages="destination_typeErrors"
          @input="$v.formData.destination_type.$touch()"
          @blur="$v.formData.destination_type.$touch()"
          @change="destinationTypeUpdate"
        >
          <v-radio
            v-for="(desti, i) in serverData.destination_types"
            :label="desti.text"
            :value="desti.index"
            :key="i"
          ></v-radio>
        </v-radio-group>
        <div v-if="destination_type === 0">
          <v-autocomplete
            v-model="formData.destination_id"
            label="Country"
            item-text="text"
            item-value="index"
            :items="serverData.countries"
            clearable
            outlined
            :error-messages="destination_idErrors"
            @input="$v.formData.destination_id.$touch()"
            @blur="$v.formData.destination_id.$touch()"
          ></v-autocomplete>
        </div>
        <div v-else-if="destination_type === 1">
          <v-autocomplete
            v-model="formData.destination_id"
            label="Regions"
            item-text="text"
            item-value="index"
            :items="serverData.regions"
            clearable
            outlined
            :error-messages="destination_idErrors"
            @input="$v.formData.destination_id.$touch()"
            @blur="$v.formData.destination_id.$touch()"
          ></v-autocomplete>
        </div>

        <div class="col-12">
          <v-radio-group
            label="Invoicing order status"
            v-model="formData.invoicing_order_status_type"
            row
          >
            <v-radio
              v-for="(desti, i) in [
                { text: 'Include', index: 0 },
                { text: 'Exclude', index: 1 },
              ]"
              :label="desti.text"
              :value="desti.index"
              :key="i"
            ></v-radio>
          </v-radio-group>
          <div>
            <v-autocomplete
              v-model="formData.invoicing_order_status"
              label="Invoicing order status"
              item-text="text"
              item-value="index"
              :items="serverData.invoicing_order_statuses"
              clearable
              outlined
              :error-messages="invoicing_order_statusErrors"
              @input="$v.formData.invoicing_order_status.$touch()"
              @blur="$v.formData.invoicing_order_status.$touch()"
            ></v-autocomplete>
          </div>
        </div>

        <!-- new radio btn invoicing order status -> include - exclude -->
        <!-- new dropdown : returned, under process, delivered -->
      </div>
      <div class="col-12">
        <rate-datatable
          name="Rate"
          :setSku="setRates"
          :skus="formData.rates"
          :hasImport="true"
          :importType="1"
          :countryId="formData.destination_id"
          :isCountry="destination_type"
          importLabel="Import Rates"
          :exampleFiles="serverData.example_file"
        ></rate-datatable>
        <hr />

        <rate-datatable
          name="Increment"
          :setSku="setIncrements"
          :skus="formData.increments"
          :hasImport="true"
          :importType="2"
          :countryId="formData.destination_id"
          :isCountry="destination_type"
          importLabel="Import Increment"
          :exampleFiles="serverData.example_file"
        ></rate-datatable>

        <cost-datatable
          name="Additional costs"
          :setSku="setCosts"
          :skus="formData.additional_costs"
          importLabel="Import Additional costs"
          :applyOns="applyOns"
        ></cost-datatable>

        <!-- need to add new section additional cost-> fields name,rate, apply on,apply on position -->
      </div>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <v-btn class="mx-1" color="primary" @click="submitCreateForm">
          submit
        </v-btn>
        <v-btn class="mx-1" @click="resetCreateForm"> clear </v-btn>
        <v-btn class="mx-1" @click="goBack"> back </v-btn>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import RateDatatable from "@/own/components/finance/customers/serviceContainer/addForms/drateContainer/RateDatatable";
import CostDatatable from "@/own/components/finance/customers/serviceContainer/addForms/costContainer/CostDatatable";
import { required, requiredIf } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "DeliveryForm.vue",
  components: { RateDatatable, CostDatatable },
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations() {
    return {
      formData: {
        country_origin_id: { required },
        destination_id: { required },
        destination_type: { required },
        order_types: { required },
        unit_id: { required },
        order_date: { required },
        tax_percentage: { required },
        discount: { required },
        invoicing_order_status: {
          required: requiredIf(() => {
            return this.formData.invoicing_order_status_type === 0;
          }),
        },
      },
    };
  },
  data: () => ({
    formData: {
      id: null,
      unit_id: null,
      country_origin_id: null,
      destination_type: 0,
      destination_id: null,
      cod_price: null,
      cod_percentage: null,
      remote_area: null,
      tax_percentage: null,
      order_date: null,
      order_types: null,

      rates: [],
      increments: [],
      additional_costs: [],
      remote_area_type: 0,
      discount_type: 0,
      discount: null,
      combine_with_fulfillment_on_invoice: null,
      invoicing_order_status_type: 0,
      invoicing_order_status: null,
    },
  }),
  methods: {
    destinationTypeUpdate() {
      this.formData.destination_id = null;
    },
    setIncrements(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.increments = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setCosts(item) {
      // console.log("before update", this.formData.increments, item);
      let copy = { ...this.formData };
      copy.costs = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.increments);
    },
    setRates(item) {
      // console.log("before update", this.formData.rates, item);
      let copy = { ...this.formData };
      copy.rates = [...item];
      this.formData = copy;
      // console.log("after update", this.formData.rates);
    },
    goBack() {
      this.actionFuntion("back");
    },

    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        const data = { ...this.formData };
        this.setData(data);
        this.actionFuntion();
        // this.resetCreateForm();
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.values).forEach((serverData) => {
              if (localData[0] == serverData[0]) {
                this.formData[localData[0]] = copy[serverData[0]];
              }
            });
          });
        } catch {
          // console.log(e);
          this.formData = {
            id: null,
            unit_id: null,
            country_origin_id: null,
            destination_type: 0,
            destination_id: null,
            cod_price: null,
            cod_percentage: null,
            remote_area: null,
            tax_percentage: null,
            order_date: null,
            order_types: null,
            rates: [],
            increments: [],
            additional_costs: [],
            remote_area_type: 0,
            discount_type: 0,
            discount: null,
            combine_with_fulfillment_on_invoice: null,
            invoicing_order_status_type: 0,
            invoicing_order_status: null,
          };
        }
      } else {
        this.formData = {
          id: null,
          unit_id: null,
          country_origin_id: null,
          destination_type: 0,
          destination_id: null,
          cod_price: null,
          cod_percentage: null,
          remote_area: null,
          tax_percentage: null,
          order_date: null,
          order_types: null,
          rates: [],
          increments: [],
          additional_costs: [],
          remote_area_type: 0,
          discount_type: 0,
          discount: null,
          combine_with_fulfillment_on_invoice: null,
          invoicing_order_status_type: 0,
          invoicing_order_status: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    applyOns() {
      return this.serverData.apply_ons;
    },
    remoteAreaValueLabel() {
      return this.formData.remote_area_type == 0 ? "Multiplier" : "Flat rate";
    },
    discountValueLabel() {
      return this.formData.discount_type == 0 ? "Flat rate" : "Percentage";
    },
    destination_type: function () {
      return this.formData.destination_type;
    },
    invoicing_order_status_type() {
      return this.formData.invoicing_order_status_type;
    },
    invoicing_order_statusErrors() {
      return this.handleFormValidation("invoicing_order_status", this);
    },
    country_origin_idErrors() {
      return this.handleFormValidation("country_origin_id", this);
    },
    destination_idErrors() {
      return this.handleFormValidation("destination_id", this);
    },
    destination_typeErrors() {
      return this.handleFormValidation("destination_type", this);
    },
    order_typesErrors() {
      return this.handleFormValidation("order_types", this);
    },
    unit_idErrors() {
      return this.handleFormValidation("unit_id", this);
    },
    order_dateErrors() {
      return this.handleFormValidation("order_date", this);
    },
    tax_percentageErrors() {
      return this.handleFormValidation("tax_percentage", this);
    },
    // nameErrors() {
    //   return this.handleFormValidation("name", this);
    // },
    discountErrors() {
      return this.handleFormValidation("discount", this);
    },
  },
};
</script>
