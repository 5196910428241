<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Courier Account</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="editForm">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.clients"
              clearable
              outlined
            ></v-autocomplete>
            <v-autocomplete
              @change="onCountryChange"
              v-model="formData.country_id"
              label="Country"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
            ></v-autocomplete>
            <v-autocomplete
              @change="onStateChange"
              :disabled="isStatesLoaded"
              v-model="formData.state_id"
              label="State"
              item-text="title"
              item-value="id"
              :items="serverData.states"
              clearable
              outlined
            ></v-autocomplete>
            <v-autocomplete
              @change="onCityChange"
              :disabled="isCitiesLoaded"
              v-model="formData.city_id"
              label="City"
              item-text="title"
              item-value="id"
              :items="serverData.cities"
              clearable
              outlined
            ></v-autocomplete>
            <v-autocomplete
              :disabled="isAreasLoaded"
              v-model="formData.area_id"
              label="Area"
              item-text="title"
              item-value="id"
              :items="serverData.areas"
              clearable
              outlined
            ></v-autocomplete>
            <v-text-field
              v-model="formData.supplier_code"
              label="Supplier Code"
              clearable
              outlined
              :error-messages="supplier_codeErrors"
              @input="$v.formData.supplier_code.$touch()"
              @blur="$v.formData.supplier_code.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.contact_name"
              label="Contact Name"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.phone"
              label="Phone"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.mobile"
              label="Mobile"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.address"
              label="Adress"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.remarks"
              label="Remarks"
              clearable
              outlined
            ></v-text-field>
            <v-text-field
              v-model="formData.instructions"
              label="Instructions"
              clearable
              outlined
            ></v-text-field>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      supplier_code: { required },
    },
  },
  props: ["refresher", "pageLoader", "parents", "roles", "customers"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      supplier_id: null,
      customer_id: null,
      supplier_code: null,
      store_name: null,
      contact_name: null,
      phone: null,
      mobile: null,
      address: null,
      area_id: null,
      city_id: null,
      state_id: null,
      country_id: null,
      remarks: null,
      instructions: null,
    },
  }),
  methods: {
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { supplier_id: this.$store.getters.getItemForAction.id };
      ApiService.post("/fulfillment/suppliers/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
          this.dialog = false;
        });
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        let data = this.formData;
        ApiService.post("/fulfillment/suppliers/update", data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Courier account  data has been updated`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.resetEditForm();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      if (this.serverData) {
        let copy = { ...this.serverData.supplier };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.serverData.supplier).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
              // console.log(serverData[0], copy[serverData[0]]);
            }
          });
        });
      } else {
        this.formData = {
          supplier_id: null,
          customer_id: null,
          supplier_code: null,
          store_name: null,
          contact_name: null,
          phone: null,
          mobile: null,
          address: null,
          area_id: null,
          city_id: null,
          state_id: null,
          country_id: null,
          remarks: null,
          instructions: null,
        };
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    onCountryChange(val) {
      // console.log("country", val);
      if (val) {
        this.pageLoader(true);
        let data = { country: val };
        ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData.states = response.data.states;
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    onStateChange(val) {
      // console.log("state", val);
      if (val) {
        this.pageLoader(true);
        let data = { state: val };
        ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.serverData.cities = response.data.cities;
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    onCityChange(val) {
      // console.log("city", val);
      if (val) {
        this.pageLoader(true);
        let data = { city: val };
        ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.serverData.areas = response.data.areas;
            let i = { ...this.serverData };
            this.serverData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    supplier_codeErrors() {
      return this.handleFormValidation("supplier_code", this);
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
  },
};
</script>
