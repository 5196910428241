<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <FormBuilder
          :formFields="formFields"
          :defaultValues="defaultValues"
          :onSubmit="submitForm"
          :submitButtonText="submitButtonText"
        ></FormBuilder>
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";
import FormBuilder from "@/own/components/FormBuilder.vue";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  components: { FormBuilder },
  data: () => ({
    dialog: false,

    formData: {
      service_id: null,
      abbreviation: null,
      length: null,
      type_id: null,
    },
    formFields: [],
    defaultValues: {},
    severData: null,
  }),
  mounted() {
    this.formFields = [
      {
        field_text: this.$t("type"),
        field_name: "type_id",
        field_type: "select",
        is_required: true,
        is_editable: true,
        default_value: "",
        display_order: 1,
        url: "",
        values: [],
      },
      {
        field_text: this.$t("service_id"),
        field_name: "service_id",
        field_type: "text",
        is_required: true,
        is_editable: true,
        default_value: "",
        display_order: 2,
        url: "",
        values: "",
      },
      {
        field_text: this.$t("abbreviation"),
        field_name: "abbreviation",
        field_type: "text",
        is_required: false,
        is_editable: true,
        default_value: "",
        display_order: 2,
        url: "",
        values: "",
      },
      {
        field_text: this.$t("length"),
        field_name: "code_length",
        field_type: "text",
        is_required: false,
        is_editable: true,
        default_value: "",
        display_order: 4,
        url: "",
        values: "",
      },
    ];
  },
  methods: {
    async toggleModal() {
      if (!this.dialog) {
        if (this.actionItem) {
          await this.loadDataFromServer();
        } else {
          this.formFields.find(
            (field) => field.field_name == "type_id"
          ).values =
            this.$store.getters.getWESSPLBARCODETableStructure.service_types;
        }
      } else {
        this.serverData = null;
      }
      this.dialog = !this.dialog;
    },
    async loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = this.actionItem ? { id: this.actionItem.id } : null;
      const url = this.actionItem ? "edit" : "create";
      await ApiService.post("/wes/wesSplBarcodeConfiguration/" + url, payload)
        .then(({ data }) => {
          const response = data.data;
          this.severData = data;
          this.formFields.find(
            (field) => field.field_name == "type_id"
          ).values = data.service_types;
          this.formFields = this.formFields.map((field) => {
            if (field.field_name in response) {
              field.default_value = response[field.field_name];
            }
            return field;
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },

    submitForm(data) {
      let payload = {
        ...data,
      };
      if (this.actionItem) payload.id = this.actionItem.id;
      let actionUrl = "store";
      if (this.actionItem) actionUrl = "update";
      this.pageLoader(true);
      ApiService.post(`/wes/wesSplBarcodeConfiguration/${actionUrl}`, payload)
        .then(() => {
          if (this.actionType) {
            SwalService.successMessage({
              html: SwalService.messages.updated(),
            });
          } else {
            SwalService.successMessage({
              html: SwalService.messages.added(),
            });
          }
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    actionItem: function () {
      return this.$store.getters.getItemForAction;
    },
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return this.$t("update");
      else return this.$t("submit");
    },
    headerText: function () {
      if (this.actionType) return this.$t("edit_item");
      else return this.$t("add_new_item");
    },
  },
};
</script>
