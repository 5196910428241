import { render, staticRenderFns } from "./EditPlatform.vue?vue&type=template&id=5d3a2a00"
import script from "./EditPlatform.vue?vue&type=script&lang=js"
export * from "./EditPlatform.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports