<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Carton</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form class="pt-4 row">
            <div class="col-12">
              <v-autocomplete
                v-model="formData.carton"
                label="Carton"
                placeholder="Enter carton number"
                item-text="text"
                item-value="index"
                clearable
                outlined
                :items="cartons"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                dense
                :error-messages="cartonErrors"
                @input="$v.formData.carton.$touch()"
                @blur="$v.formData.carton.$touch()"
                @change="updateSkuList"
              ></v-autocomplete>
            </div>
            <div class="col-12">
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 ma-0 pa-0"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>SKUs</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
              </v-data-table>
            </div>
          </form>
          <!--end::Body-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
export default {
  name: "AddCarton",
  mixins: [validationMixin],
  validations: {
    formData: {
      carton: { required },
    },
  },
  props: ["actionFunction", "clientId", "store", "warehouse"],
  data: () => ({
    headers: [
      { text: "SKU ID", value: "skuId" },
      { text: "System Code", value: "systemCode" },
      { text: "Quantity", value: "quantity" },
      { text: "Description", value: "description" },
      { text: "Price", value: "price" },
      // { text: "Store code", value: "store_code" },
      // { text: "Goods price", value: "goods_price" },
    ],
    dialog: false,
    isLoading: false,
    search: null,
    entries: [],
    formData: {
      carton: null,
      skus: [],
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.actionFunction([...this.items]);
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.entries = [];
      this.search = "";
      this.isLoading = false;
      this.formData = {
        carton: null,
        skus: [],
      };
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
    },
    updateSkuList() {
      const data = {
        carton: this.formData.carton,
      };
      ApiService.post(`/wms/inventory-balances/get-carton-skus`, data)
        .then((response) => {
          this.formData.skus = response.data.skus;
        })
        .catch(() => {});
    },
    async getData(value) {
      if (value == null || value == "" || value == undefined) {
        return;
      }
      let data = {};

      data = JSON.stringify({
        q: value,
        store: this.store,
        warehouse: this.warehouse,
      });

      await fetch(
        `${process.env.VUE_APP_BASE_URL}/wms/inventory-balances/search-carton`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: data,
        }
      )
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.cartons;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    items: function () {
      return this.formData.skus;
    },
    cartons() {
      return this.entries;
    },
    cartonErrors: function () {
      return this.handleFormValidation("carton", this);
    },
  },
  watch: {
    search(val) {
      if (val == null || val == undefined || val == "") {
        return;
      }
      this.isLoading = true;

      this.getData(val);
    },
  },
};
</script>
