import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getRANDOMCYCLECOUNTTableStructure";
export const UPDATE_TABLE_DATA = "updateRANDOMCYCLECOUNTTableData";
export const EXPORT_TABLE_DATA = "exportRANDOMCYCLECOUNTTableData";
export const CREATE_CYCLE_COUNT = "createRANDOMCYCLECOUNT";
export const GET_CYCLE_COUNT = "getRANDOMCYCLECOUNTCycleCount";
export const STORE_CYCLE_COUNT = "storeRANDOMCYCLECOUNT";
export const COMPLETE_CYCLE_COUNT = "getRANDOMCYCLECOUNTCompleteCycleCount";
export const UPDATE_RECOUNT_TASK = "updateRANDOMCYCLECOUNTRecountTask";
export const CLOSE_CYCLE_COUNT = "updateRANDOMCYCLECOUNTCloseCycleCount";

// mutation types
export const SET_TABLE_STRUCTURE = "setRANDOMCYCLECOUNTTableStructure";
export const SET_TABLE_DATA = "setRANDOMCYCLECOUNTTableData";
export const SET_TABLE_FILTER = "setRANDOMCYCLECOUNTTableFilter";
export const SET_TABLE_PAGE = "setRANDOMCYCLECOUNTTablePage";
export const SET_TABLE_PERPAGE = "setRANDOMCYCLECOUNTTablePerPage";
export const SET_TABLE_SORTBY = "setRANDOMCYCLECOUNTTableSortBy";
export const SET_TABLE_SORTORDER = "setRANDOMCYCLECOUNTTableSortOrder";
export const SET_EXPORT_LINK = "setRANDOMCYCLECOUNTExportLink";
export const SET_BLIND_CYCLE_COUNT = "setRANDOMCYCLECOUNTBlintCycleCount";
export const SET_NEXT_CYCLE_COUNT_TASK =
  "setRANDOMCYCLECOUNTNextCycleCountTask";
export const SET_COUNT_TYPE = "setRANDOMCYCLECOUNTType";
export const SET_COMPLETED_COUNT_TASKS =
  "setRANDOMCYCLECOUNTCompletedCountTasks";
export const SET_RECOUNT_TASK = "setRANDOMCYCLECOUNTSetRecountTask";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  count_type: "new_count",
  is_blind_cycle_count: false,
  next_cycle_count_task: {},
  completed_count_tasks: [],
};

const getters = {
  /**
   * for table title, description
   * */
  getRANDOMCYCLECOUNTTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getRANDOMCYCLECOUNTTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getRANDOMCYCLECOUNTTableState(state) {
    return state.table_state;
  },

  getRANDOMCYCLECOUNTTableHeaders(state) {
    return state.table_structure.columns;
  },

  getRANDOMCYCLECOUNTTableFilters(state) {
    return state.table_structure.filters;
  },

  getRANDOMCYCLECOUNTTableData(state) {
    return state.table_data.data;
  },

  getRANDOMCYCLECOUNTExportUrl(state) {
    return state.export_link;
  },
  getRANDOMCYCLECOUNTUploadType(state) {
    return state.table_structure.type;
  },
  getRANDOMCYCLECOUNTType(state) {
    return state.count_type;
  },
  getRANDOMCYCLECOUNTBlindCycleCountState(state) {
    return state.is_blind_cycle_count;
  },
  getRANDOMCYCLECOUNTNextCycleCountTask(state) {
    return state.next_cycle_count_task;
  },
  getRANDOMCYCLECOUNTCompletedTasks(state) {
    return state.completed_count_tasks;
  },
  getRANDOMCYCLECOUNTUsers(state) {
    return state.table_structure.users;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/random_cycle_counts")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/random_cycle_counts/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/random_cycle_counts/export",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [CREATE_CYCLE_COUNT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) =>
      ApiService.post(
        "/warehouse_management/random_cycle_counts/create",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_BLIND_CYCLE_COUNT, data.is_blind_cycle_count);
        })
        .finally((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
    );
  },
  async [GET_CYCLE_COUNT](context, payload) {
    try {
      const { data } = await ApiService.post(
        "/warehouse_management/random_cycle_counts/get_cycle_count_task",
        payload
      );
      context.commit(SET_NEXT_CYCLE_COUNT_TASK, data.next_cycle_count_task);
      return data;
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  async [STORE_CYCLE_COUNT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const { data } = await ApiService.post(
        "/warehouse_management/random_cycle_counts/store",
        payload
      );
      context.commit(SET_NEXT_CYCLE_COUNT_TASK, data.next_cycle_count_task);
      context.commit(
        SET_COMPLETED_COUNT_TASKS,
        data.completed_cycle_count_task
      );
      return data;
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  [COMPLETE_CYCLE_COUNT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) =>
      ApiService.post(
        "/warehouse_management/random_cycle_counts/complete_cycle_count",
        payload
      ).finally((data) => {
        context.commit(SET_PAGE_LOADING, false);
        resolve(data);
      })
    );
  },
  [UPDATE_RECOUNT_TASK](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) =>
      ApiService.post(
        "/warehouse_management/random_cycle_counts/recount",
        payload
      ).finally((data) => {
        context.commit(SET_PAGE_LOADING, false);
        resolve(data);
      })
    );
  },
  async [CLOSE_CYCLE_COUNT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const { data } = await ApiService.post(
        "/warehouse_management/random_cycle_counts/close",
        payload
      );
      return data;
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  // [CLOSE_CYCLE_COUNT](context, payload) {
  //   context.commit(SET_PAGE_LOADING, true);
  //   return new Promise((resolve) =>
  //     ApiService.post(
  //       "/warehouse_management/random_cycle_counts/close",
  //       payload
  //     ).finally((data) => {
  //       context.commit(SET_PAGE_LOADING, false);
  //       resolve(data);
  //     })
  //   );
  // },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_COUNT_TYPE](state, type) {
    state.count_type = type;
  },
  [SET_BLIND_CYCLE_COUNT](state, enabled) {
    state.is_blind_cycle_count = enabled;
  },
  [SET_NEXT_CYCLE_COUNT_TASK](state, enabled) {
    state.next_cycle_count_task = enabled;
  },
  [SET_COMPLETED_COUNT_TASKS](state, task) {
    if (task === 0) {
      state.completed_count_tasks = [];
    } else {
      state.completed_count_tasks.push(task);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
