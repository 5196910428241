<template>
  <v-dialog
    v-model="showModal"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    ref="addItem"
  >
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form v-if="isLoaded" class="row ma-0">
          <div class="row">
            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.warehouse_id"
                label="Warehouse"
                :items="formFields.warehouses"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @change="onWarehouseChange"
                @blur="$v.formData.warehouse_id.$touch()"
                :error-messages="warehouseErrors"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.system_id"
                label="System"
                :disabled="!formData.warehouse_id"
                :items="formFields.systems"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.system_id.$touch()"
                :error-messages="systemErrors"
              />
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 h-75px"
              v-if="formData.system_id === 1 || formData.system_id === 4"
            >
              <v-autocomplete
                v-model="formData.zone_groups"
                label="Zone groups"
                :items="formFields.zone_groups"
                class="putaway-rules-zone-groups"
                item-text="text"
                item-value="index"
                dense
                clearable
                chips
                outlined
                multiple
                hide-no-data
                hide-selected
                @blur="getLocationsBasedOnGroups"
                @click:clear="onZoneGroupClear"
                :error-messages="zoneGroupErrors"
              />
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 h-75px"
              v-if="formData.system_id === 1 || formData.system_id === 4"
            >
              <v-autocomplete
                v-model="formData.zones"
                class="putaway-rules-zones"
                label="Zones"
                hide-no-data
                :items="formFields.zones_based_on_groups"
                :disabled="
                  !formFields.zones_based_on_groups ||
                  !formData.zone_groups.length
                "
                item-text="text"
                item-value="index"
                dense
                chips
                multiple
                clearable
                outlined
                hide-selected
                @blur="$v.formData.zones.$touch()"
                :error-messages="zonesErrors"
              >
                <template #append-item>
                  <div
                    class="cursor-pointer bg-hover-secondary-o-3 px-4 py-3"
                    @click="selectAllZones"
                  >
                    <div>Select all zones</div>
                  </div>
                </template>
              </v-autocomplete>
            </div>
            <div
              v-if="formData.system_id === 2"
              class="col-12 col-sm-6 col-md-4"
            >
              <v-autocomplete
                v-model="formData.from_zone_id"
                label="From Zone"
                :items="this.formFields.zones"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.from_zone_id.$touch()"
                :error-messages="fromZoneErrors"
              />
            </div>
            <div
              v-if="formData.system_id === 2"
              class="col-12 col-sm-6 col-md-4"
            >
              <v-autocomplete
                v-model="formData.to_zone_id"
                label="To Zone"
                :items="this.formFields.zones"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.to_zone_id.$touch()"
                :error-messages="toZoneErrors"
              />
            </div>
            <div
              v-if="formData.system_id === 3"
              class="col-12 col-sm-6 col-md-4"
            >
              <v-autocomplete
                v-model="formData.from_location_id"
                label="From Location"
                :items="this.formFields.locations"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.from_location_id.$touch()"
                :error-messages="fromLocationErrors"
              />
            </div>
            <div
              v-if="formData.system_id === 3"
              class="col-12 col-sm-6 col-md-4"
            >
              <v-autocomplete
                v-model="formData.to_location_id"
                label="To Location"
                :items="this.formFields.locations"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.to_location_id.$touch()"
                :error-messages="toLocationErrors"
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-text-field
                v-model="formData.name"
                dense
                required
                clearable
                outlined
                label="Name"
                :error-messages="nameErrors"
                @blur="$v.formData.name.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.capacity_id"
                disabled
                label="Capacity"
                :items="formFields.capacities"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
              />
            </div>
            <div class="col-12 col-sm-6 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.restriction_id"
                label="Restriction"
                :items="formFields.restrictions"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
              />
            </div>
            <div class="col-12 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.receipt_type_id"
                label="Receipt Type"
                :items="formFields.receipt_types"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
              />
            </div>
            <div class="col-12 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.sku_cycle_id"
                disabled
                label="SKU cycle"
                :items="formFields.sku_cycles"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
              />
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 h-75px"
              v-if="formData.system_id === 1"
            >
              <v-autocomplete
                v-model="formData.stock_type"
                label="Stock Type"
                :items="this.formFields.stock_type"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.stock_type.$touch()"
                :error-messages="stockTypeErrors"
              />
            </div>
            <div
              class="col-12 col-sm-6 col-md-4 h-75px"
              v-if="formData.system_id === 4"
            >
              <v-autocomplete
                v-model="formData.uom_type_id"
                label="UOM Type"
                :items="this.formFields.uom_types"
                item-text="text"
                item-value="index"
                dense
                clearable
                outlined
                hide-no-data
                hide-selected
                @blur="$v.formData.uom_type_id.$touch()"
                :error-messages="umoTypeErrors"
              />
            </div>
            <div class="col-12 col-sm-5 col-md-4 h-75px">
              <div class="d-flex align-start ma-0 py-0 px-3">
                <h5 class="pr-4 pt-5 font-weight-regular">
                  Restriction Allowed
                </h5>
                <v-switch
                  v-model="formData.restriction_status"
                  hide-details
                  dense
                  inset
                ></v-switch>
              </div>
            </div>
          </div>
          <div
            class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center h-105px pb-5"
          >
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1 py-3"
              @click="resetFormData"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1 py-3"
              @click="submitForm"
            >
              {{ submitButtonText }}
            </button>
          </div>
        </form>
      </div>
      <!--end::Modal body-->
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import {
  UPDATE_CREATE_TEMPLATE_STRUCTURE,
  UPDATE_WAREHOUSE_SPECIFTC_DATA,
  STORE_NEW_TEMPLATE,
  SET_CREATE_TEMPLATE_STRUCTURE,
  GET_ZONES_BASED_ON_GROUPS,
  SET_ZONES_BASED_ON_GROUPS,
} from "@/core/services/store/putawayRuleBasedTemplate.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ActionDialog",
  mixins: [validationMixin],
  props: ["refresher", "pageLoader"],
  validations: {
    formData: {
      name: { required },
      warehouse_id: { required },
      system_id: { required },
      stock_type: {
        required: requiredIf(function () {
          return this.formData.system_id === 1;
        }),
      },
      from_zone_id: {
        required: requiredIf(function () {
          return this.formData.system_id === 2;
        }),
      },
      to_zone_id: {
        required: requiredIf(function () {
          return this.formData.system_id === 2;
        }),
      },
      from_location_id: {
        required: requiredIf(function () {
          return this.formData.system_id === 3;
        }),
      },
      to_location_id: {
        required: requiredIf(function () {
          return this.formData.system_id === 3;
        }),
      },
      uom_type_id: {
        required: requiredIf(function () {
          return this.formData.system_id === 4;
        }),
      },
      zone_groups: {
        required: requiredIf(function () {
          return this.formData.system_id === 1 || this.formData.system_id === 4;
        }),
      },
      zones: {
        required: requiredIf(function () {
          return this.formData.system_id === 1 || this.formData.system_id === 4;
        }),
      },
    },
  },
  data() {
    return {
      showModal: false,
      isLoaded: false,
      formData: {
        warehouse_id: null,
        name: "",
        system_id: null,
        restriction_id: null,
        restriction_status: true,
        capacity_id: null,
        receipt_type_id: null,
        sku_cycle_id: null,
        stock_type: null,
        from_location_id: null,
        to_location_id: null,
        from_zone_id: null,
        to_zone_id: null,
        uom_type_id: null,
        zone_groups: [],
        zones: [],
      },
      dependentDropdown: {
        show: false,
        label: "",
        items: [],
        model: null,
      },
    };
  },
  mounted() {
    this.formData.warehouse_id = this.selectedWarehouse;
  },
  methods: {
    toggleModal() {
      // this.showModal = !this.showModal;
      if (this.showModal) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.showModal = !this.showModal;
    },
    async loadDataFromServer() {
      await this.$store.dispatch(UPDATE_CREATE_TEMPLATE_STRUCTURE);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post(
          "/warehouse_management/putaway_rule_based_templates/edit",
          sendData
        ).then(({ data }) => {
          this.formData = data.template;
          this.$store.commit(SET_CREATE_TEMPLATE_STRUCTURE, data);
          this.pageLoader(false);
        });
      }
      this.isLoaded = true;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    resetFormData() {
      this.formData = {
        warehouse_id: this.warehouseId,
        name: "Defalut",
        system_id: null,
        restriction_id: null,
        restriction_status: true,
        capacity_id: null,
        receipt_type_id: null,
        sku_cycle_id: null,
        stock_type: null,
        from_location_id: null,
        to_location_id: null,
        from_zone_id: null,
        to_zone_id: null,
        uom_type_id: null,
        zone_groups: [],
        zones: [],
      };
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store.dispatch(STORE_NEW_TEMPLATE, [
        this.formData,
        this.actionType,
      ]);
      this.refresher();
      this.toggleModal();
    },
    onWarehouseChange() {
      if (this.formData.warehouse_id) {
        const payload = { warehouse_id: this.formData.warehouse_id };
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store.dispatch(UPDATE_WAREHOUSE_SPECIFTC_DATA, payload);
      }
    },
    selectAllZones() {
      this.formFields.zones.map((item) => {
        this.formData.zones.push(item.index);
      });
    },
    getLocationsBasedOnGroups() {
      if (this.formData.zone_groups.length) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          id: this.formData.zone_groups,
        };
        this.$store.dispatch(GET_ZONES_BASED_ON_GROUPS, payload);
      }
      this.$v.formData.zone_groups.$touch();
    },
    onZoneGroupClear() {
      this.$store.commit(SET_ZONES_BASED_ON_GROUPS, null);
      this.formData.zones = [];
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    formFields: function () {
      return this.$store.getters
        .getPUTAWAYRULEBASEDTEMPLATECreateFormProperties;
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    warehouseErrors: function () {
      return this.handleFormValidation("warehouse_id");
    },
    systemErrors: function () {
      return this.handleFormValidation("system_id");
    },
    zoneGroupErrors: function () {
      return this.handleFormValidation("zone_groups");
    },
    zonesErrors: function () {
      return this.handleFormValidation("zones");
    },
    stockTypeErrors: function () {
      return this.handleFormValidation("stock_type");
    },
    fromZoneErrors: function () {
      return this.handleFormValidation("from_zone_id");
    },
    toZoneErrors: function () {
      return this.handleFormValidation("to_zone_id");
    },
    fromLocationErrors: function () {
      return this.handleFormValidation("from_location_id");
    },
    toLocationErrors: function () {
      return this.handleFormValidation("to_location_id");
    },
    umoTypeErrors: function () {
      return this.handleFormValidation("uom_type_id");
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>

<style lang="scss">
.putaway-rules-zone-groups,
.putaway-rules-zones {
  .v-chip--select {
    height: 21px !important;
  }
}
</style>
