import ApiService from "@/core/services/api.service";

// action types
export const GET_WAREHOUSES = "getWAREHOUSESWarehouses";
export const GET_ZONES = "getWAREHOUSESZones";
export const GET_ZONEGROUPS = "getWAREHOUSESZoneGroups";
export const GET_LOCATIONS = "getWAREHOUSESLocations";
export const GET_SEARCH_LOCATIONS = "getWAREHOUSESSearchLocations";
export const GET_MORE_LOCATIONS = "getWAREHOUSESMoreLocations";
export const EXPORT_TABLE_DATA = "exportWAREHOUSESTableData";

/*
country = warehouse
state = zone group
city = zone
area = location


*/

// mutation types
export const SET_WAREHOUSES = "setWAREHOUSESWarehouses";
export const SET_ZONEGROUPS = "setWAREHOUSESZoneGroups";
export const SET_ZONES = "setWAREHOUSESZones";
export const SET_LOCATIONS = "setWAREHOUSESLocations";
export const APPEND_LOCATIONS = "appendWAREHOUSESLocations";
export const SET_ZONEGROUPS_LENGTH = "setWAREHOUSESZoneGroupsLength";
export const SET_ZONES_LENGTH = "setWAREHOUSESZonesLength";
export const SET_LOCATIONS_LENGTH = "setWAREHOUSESLocationsLength";
export const SET_ZONEGROUPS_LABEL = "setWAREHOUSESZoneGroupsLabel";
export const SET_ZONES_LABEL = "setWAREHOUSESZonesLabel";
export const SET_LOCATIONS_LABEL = "setWAREHOUSESLocationsLabel";
export const SET_EXPORT_LINK = "setWAREHOUSESExportLink";
export const SET_SELECTED_WAREHOUSE_ID = "setWAREHOUSESSelectedWarehouseId";
export const SET_SELECTED_ZONEGROUP_ID = "setWAREHOUSESSelectedZoneGroupId";
export const SET_SELECTED_ZONE_ID = "setWAREHOUSESSelectedZoneId";
export const SET_EXAMPLE_FILE = "setWAREHOUSESExampleFile";
export const SET_LOCATION_MORE = "setWAREHOUSESLoadMore";
export const SET_EXPORT_COLUMNS = "setWAREHOUSESExportColumns";
export const SET_LOCATION_COUNT = "setWAREHOUSESLocationCount";

const state = {
  export_link: null,
  warehouses: [],
  zoneGroups: [],
  zones: [],
  locations: [],
  zoneGroupLabel: "Select a warehouse",
  zoneLabel: "Select a zone group",
  locationLabel: "Select a zone",
  selectedWarehouseId: null,
  selectedZoneGroupId: null,
  selectedZoneId: null,
  example_file: {},
  loadMoreLocation: null,
  exportColumns: null,
  locationCount: 0,
};

const getters = {
  getWAREHOUSESWarehousesData(state) {
    return state.warehouses;
  },

  getWAREHOUSESZoneGroupsData(state) {
    return state.zoneGroups;
  },

  getWAREHOUSESZonesData(state) {
    return state.zones;
  },

  getWAREHOUSESLocationsData(state) {
    return state.locations;
  },

  getWAREHOUSESZoneGroupsLabel(state) {
    return state.zoneGroupLabel;
  },

  getWAREHOUSESZonesLabel(state) {
    return state.zoneLabel;
  },

  getWAREHOUSESLocationsLabel(state) {
    return state.locationLabel;
  },

  getWAREHOUSESExportUrl(state) {
    return state.export_link;
  },

  getWAREHOUSESSelectedWarehouseId(state) {
    return state.selectedWarehouseId;
  },
  getWAREHOUSESSelectedZoneGroupId(state) {
    return state.selectedZoneGroupId;
  },
  getWAREHOUSESSelectedZoneId(state) {
    return state.selectedZoneId;
  },
  getWAREHOUSESExampleFiles(state) {
    return state.example_file;
  },
  getWarehouseLoadMoreLocation(state) {
    return state.loadMoreLocation;
  },
  getWarehouseExportColumns(state) {
    return state.exportColumns;
  },
  getWarehouseLocationCount(state) {
    return state.locationCount;
  },
};

const actions = {
  [GET_WAREHOUSES](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/warehouses", { type: "warehouse" })
        .then(({ data }) => {
          context.commit(SET_ZONEGROUPS_LENGTH);
          context.commit(SET_ZONES_LENGTH);
          context.commit(SET_LOCATIONS_LENGTH);
          context.commit(SET_SELECTED_WAREHOUSE_ID, null);
          context.commit(SET_SELECTED_ZONEGROUP_ID, null);
          context.commit(SET_SELECTED_ZONE_ID, null);

          context.commit(SET_LOCATION_COUNT, 0);
          context.commit(SET_EXAMPLE_FILE, data.example_file);
          context.commit(SET_EXPORT_COLUMNS, data.export_columns);
          if (data.values.length === 1) {
            context.commit(SET_SELECTED_ZONEGROUP_ID, data.values[0].id);
            context.commit(SET_WAREHOUSES, data.values);
            context.dispatch(GET_ZONEGROUPS, {
              warehouse_id: data.values[0].id,
              type: "zone_group",
            });
          } else {
            context.commit(SET_WAREHOUSES, data.values);
          }

          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_ZONEGROUPS](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/warehouses", payload)
        .then(({ data }) => {
          context.commit(SET_ZONEGROUPS_LENGTH);
          context.commit(SET_ZONES_LENGTH);
          context.commit(SET_LOCATIONS_LENGTH);
          context.commit(SET_LOCATION_COUNT, 0);

          context.commit(SET_ZONES_LABEL, "Select a zone group");
          context.commit(SET_LOCATIONS_LABEL, "Select a zone");

          if (data.values.length === 0)
            context.commit(SET_ZONEGROUPS_LABEL, "Zone group not found");
          else if (data.values.length === 1) {
            context.commit(SET_ZONEGROUPS, data.values);
            context.commit(SET_SELECTED_ZONEGROUP_ID, data.values[0].id);

            context.dispatch(GET_ZONES, {
              warehouse_id: state.selectedWarehouseId,
              zone_group_id: state.selectedZoneGroupId,
              type: "zone",
            });
          } else {
            context.commit(SET_ZONEGROUPS, data.values);
          }

          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_ZONES](context, payload) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/warehouses", payload)
        .then(({ data }) => {
          context.commit(SET_LOCATION_COUNT, 0);
          context.commit(SET_ZONES_LENGTH);
          context.commit(SET_LOCATIONS_LENGTH);

          context.commit(SET_LOCATIONS_LABEL, "Select a zone");

          if (data.values.length === 0)
            context.commit(SET_ZONES_LABEL, "Zone not found");
          else if (data.values.length === 1) {
            context.commit(SET_SELECTED_ZONE_ID, data.values[0].id);
            context.commit(SET_ZONES, data.values);
            context.dispatch(GET_LOCATIONS, {
              warehouse_id: state.selectedWarehouseId,
              zone_group_id: state.selectedZoneGroupId,
              zone_id: data.values[0].id,
              type: "location",
            });
          } else context.commit(SET_ZONES, data.values);

          resolve(data);
        })
        .catch((data) => {
          resolve(data);
        });
    });
  },
  [GET_LOCATIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/warehouse_management/warehouses", payload)
        .then(({ data }) => {
          context.commit(SET_LOCATION_COUNT, 0);
          context.commit(SET_LOCATIONS_LENGTH);
          if (data.next_page_url) {
            context.commit(SET_LOCATION_MORE, data.next_page_url);
          } else {
            context.commit(SET_LOCATION_MORE, null);
          }

          if (data.values.length === 0)
            context.commit(SET_LOCATIONS_LABEL, "Search to get locations");
          else {
            context.commit(SET_LOCATIONS, data.values);
          }

          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  },
  [GET_SEARCH_LOCATIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/wms/warehouses/search-location-by-zone", payload)
        .then(({ data }) => {
          context.commit(SET_LOCATIONS_LENGTH);
          if (data.next_page_url) {
            context.commit(SET_LOCATION_MORE, data.next_page_url);
          } else {
            context.commit(SET_LOCATION_MORE, null);
          }

          if (data.locations.length === 0)
            context.commit(SET_LOCATIONS_LABEL, "Location not found");
          else {
            context.commit(SET_LOCATIONS, data.locations);
          }

          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  },
  [GET_MORE_LOCATIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(payload.next_page_url, payload.data)
        .then(({ data }) => {
          // context.commit(SET_LOCATIONS_LENGTH);
          if (data.next_page_url) {
            context.commit(SET_LOCATION_MORE, data.next_page_url);
          } else {
            context.commit(SET_LOCATION_MORE, null);
          }
          context.commit(APPEND_LOCATIONS, data.values);
          resolve(data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  },
};

const mutations = {
  [SET_WAREHOUSES](state, warehouses) {
    state.warehouses = warehouses;
  },
  [SET_ZONEGROUPS](state, zoneGroups) {
    state.zoneGroups = zoneGroups;
  },
  [SET_ZONES](state, zones) {
    state.zones = zones;
  },
  [SET_LOCATIONS](state, locations) {
    state.locations = locations;
  },
  [APPEND_LOCATIONS](state, locations) {
    let all_locations = [...state.locations, ...locations];
    state.locations = [...all_locations];
  },
  [SET_ZONEGROUPS_LENGTH](state) {
    state.zoneGroups.splice(0);
  },
  [SET_ZONES_LENGTH](state) {
    state.zones.splice(0);
  },
  [SET_LOCATIONS_LENGTH](state) {
    state.locations.splice(0);
  },
  [SET_ZONEGROUPS_LABEL](state, label) {
    state.zoneGroupLabel = label;
  },
  [SET_ZONES_LABEL](state, label) {
    state.zoneLabel = label;
  },
  [SET_LOCATIONS_LABEL](state, label) {
    state.locationLabel = label;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_SELECTED_WAREHOUSE_ID](state, id) {
    state.selectedWarehouseId = id;
  },
  [SET_SELECTED_ZONEGROUP_ID](state, id) {
    state.selectedZoneGroupId = id;
  },
  [SET_SELECTED_ZONE_ID](state, id) {
    state.selectedZoneId = id;
  },
  [SET_EXAMPLE_FILE](state, files) {
    state.example_file = files;
  },
  [SET_LOCATION_MORE](state, data) {
    state.loadMoreLocation = data;
  },
  [SET_EXPORT_COLUMNS](state, data) {
    state.exportColumns = data;
  },
  [SET_LOCATION_COUNT](state, data = 0) {
    state.locationCount = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
