<template>
  <v-row>
    <v-col v-if="!isClient" cols="12" md="4">
      <v-autocomplete
        v-model="formData.customer_id"
        label="Customer"
        item-text="text"
        item-value="index"
        :items="serverData.customers"
        clearable
        outlined
        dense
        :error-messages="customerIdErrors"
        @change="onChangeCustomer"
        @input="$v.formData.customer_id.$touch()"
        @blur="$v.formData.customer_id.$touch()"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="formData.client_reference"
        class
        label="Client reference"
        clearable
        outlined
        dense
        :error-messages="clientReferenceErrors"
        @input="$v.formData.client_reference.$touch()"
        @blur="$v.formData.client_reference.$touch()"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-datetime-picker
        v-model="formData.expected_collection_date"
        label="Expected Collection Time"
        :textFieldProps="{
          outlined: true,
          clearable: true,
          dense: true,
          'append-icon': 'mdi-calendar',
        }"
      >
      </v-datetime-picker>
    </v-col>
    <!-- <v-col cols="12" md="4">
      <v-select
        v-model="formData.warehouse_id"
        label="Warehouse"
        item-text="text"
        item-value="index"
        :items="serverData.warehouses"
        clearable
        outlined
        dense
        :error-messages="warehouseErrors"
        @input="$v.formData.warehouse_id.$touch()"
        @blur="$v.formData.warehouse_id.$touch()"
      ></v-select>
    </v-col> -->
    <v-col cols="12" md="4">
      <v-select
        v-model="formData.return_type_id"
        label="Return Type"
        item-text="text"
        item-value="index"
        :items="serverData.return_types"
        clearable
        outlined
        dense
        :error-messages="returnTypeErrors"
        @input="$v.formData.return_type_id.$touch()"
        @blur="$v.formData.return_type_id.$touch()"
      ></v-select>
    </v-col>
    <v-col v-if="isCurrencyAndAmountRequired" cols="12" md="4">
      <v-text-field
        v-model="formData.amount"
        class
        label="Amount"
        clearable
        outlined
        type="number"
        dense
        @change="() => validateMinValue('amount', 0, 'formData')"
        :error-messages="amountErrros"
        @input="$v.formData.amount.$touch()"
        @blur="$v.formData.amount.$touch()"
      ></v-text-field>
    </v-col>
    <v-col v-if="isCurrencyAndAmountRequired" cols="12" md="4">
      <v-select
        v-model="formData.currency_id"
        :items="serverData.currencies"
        class
        label="Currency"
        clearable
        outlined
        item-text="text"
        item-value="index"
        dense
        :error-messages="currencyErrros"
        @input="$v.formData.currency_id.$touch()"
        @blur="$v.formData.currency_id.$touch()"
      ></v-select>
    </v-col>
    <v-col cols="12" md="4">
      <v-select
        v-model="formData.destination_customer_address_type"
        label="Destination Address Type"
        item-text="title"
        item-value="index"
        :items="destinationAddressType"
        clearable
        outlined
        dense
        @change="onChangeDestinationAddressType"
        :error-messages="addressTypeErrors"
        @input="$v.formData.destination_customer_address_type.$touch()"
        @blur="$v.formData.destination_customer_address_type.$touch()"
      ></v-select>
    </v-col>
    <v-col cols="12" md="4" v-if="isDestinationWarehouse">
      <v-select
        v-model="formData.destination_warehouse_id"
        label="Destination Warehouse"
        item-text="text"
        item-value="index"
        :items="serverData.warehouses"
        clearable
        outlined
        dense
        :error-messages="warehouseErrors"
        @input="$v.formData.destination_warehouse_id.$touch()"
        @blur="$v.formData.destination_warehouse_id.$touch()"
      ></v-select>
    </v-col>
    <v-col v-if="isDestinationCustomer" cols="12" md="4">
      <v-autocomplete
        v-model="formData.destination_customer_address_id"
        label="Address"
        item-text="text"
        item-value="index"
        :items="serverData.customer_addresses"
        clearable
        outlined
        dense
        :error-messages="customerAddressErrors"
        @input="$v.formData.destination_customer_address_id.$touch()"
        @blur="$v.formData.destination_customer_address_id.$touch()"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="formData.original_awb"
        label="Original Order AWB"
        :disabled="isCustomerNotSelected"
        clearable
        outlined
        dense
        :error-messages="originalAwbError"
        @input="$v.formData.original_awb.$touch()"
        @blur="$v.formData.original_awb.$touch()"
      ></v-text-field>
      <!-- <v-autocomplete
        v-model="formData.original_awb"
        label="Original Order AWB"
        placeholder="Search with atleast 3 characters"
        item-text="text"
        item-value="text"
        :items="serverData.original_awb"
        :disabled="isCustomerNotSelected"
        clearable
        outlined
        dense
        @update:search-input="searchOriginalAwbValues"
        :loading="originalAwbLoading"
        :error-messages="originalAwbError"
        @input="$v.formData.original_awb.$touch()"
        @blur="$v.formData.original_awb.$touch()"
      ></v-autocomplete> -->
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="formData.original_client_reference"
        label="Original Client Reference"
        :disabled="isCustomerNotSelected"
        clearable
        outlined
        dense
        :error-messages="originalClientReferenceError"
        @input="$v.formData.original_client_reference.$touch()"
        @blur="$v.formData.original_client_reference.$touch()"
      ></v-text-field>
      <!-- <v-autocomplete
        v-model="formData.original_client_reference"
        label="Original Client Reference"
        item-text="text"
        :disabled="isCustomerNotSelected"
        :loading="originalClientReferenceLoading"
        placeholder="Search with atleast 3 characters"
        item-value="text"
        @update:search-input="searchOriginalClientReferenceValues"
        :items="serverData.original_client_reference"
        clearable
        outlined
        dense
        :error-messages="originalClientReferenceError"
        @input="$v.formData.original_client_reference.$touch()"
        @blur="$v.formData.original_client_reference.$touch()"
      ></v-autocomplete> -->
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="parcel.number_of_boxes"
        class
        label="Number of Boxes"
        clearable
        outlined
        type="number"
        :min="0"
        dense
        @change="() => validateMinValue('number_of_boxes', 0, 'parcel')"
        :error-messages="numberOfBoxesErrors"
        @input="$v.parcel.number_of_boxes.$touch()"
        @blur="$v.parcel.number_of_boxes.$touch()"
      ></v-text-field>
    </v-col>
    <!-- <v-col cols="12" md="4">
      <v-text-field
        v-model="parcel.total_weight"
        class
        label="Total Weight"
        clearable
        outlined
        type="number"
        :min="0"
        dense
      ></v-text-field>
    </v-col> -->
    <v-col cols="12" md="4">
      <v-text-field
        v-model="parcel.length"
        class
        label="Length"
        clearable
        :min="0"
        outlined
        type="number"
        dense
        @change="() => validateMinValue('length', 0, 'parcel')"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="parcel.height"
        class
        label="Height"
        clearable
        outlined
        type="number"
        :min="0"
        dense
        @change="() => validateMinValue('height', 0, 'parcel')"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model="formData.comment"
        class
        label="Comment"
        clearable
        outlined
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {
  SET_SERVER_DATA,
  SET_DESTINATION_ADDRESS_TYPE,
  SET_SELECTED_CUSTOMER,
} from "@/core/services/store/pendingAuditReturns.module";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ExternalOrderDetails",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        client_reference: { required },
        return_type_id: { required },
        customer_id: {
          required: requiredIf(function() {
            return !this.isClient;
          }),
        },
        // warehouse_id: { required },
        original_client_reference: { required },
        original_awb: { required },
        destination_customer_address_type: { required },
        destination_customer_address_id: {
          required: requiredIf(function() {
            return this.isDestinationCustomer;
          }),
        },
        destination_warehouse_id: {
          required: requiredIf(function() {
            return this.isDestinationWarehouse;
          }),
        },
        currency_id: {
          required: requiredIf(function() {
            return this.isCurrencyAndAmountRequired;
          }),
        },
        amount: {
          required: requiredIf(function() {
            return this.isCurrencyAndAmountRequired;
          }),
        },
      },

      parcel: {
        number_of_boxes: { required },
      },
    };
  },
  mounted() {
    if (!this.isClient) {
      this.formData.customer_id = this.$store.getters.currentUser.data.customer_id;
    }
  },
  data() {
    return {
      formData: {
        client_reference: null,
        return_type_id: null,
        customer_id: null,
        // warehouse_id: null,
        original_client_reference: null,
        original_awb: null,
        currency_id: null,
        amount: null,
        comment: null,
        expected_collection_date: null,
        destination_customer_address_type: null,
        destination_customer_address_id: null,
        destination_warehouse_id: null,
      },

      parcel: {
        number_of_boxes: 1,
        total_weight: null,
        length: null,
        width: null,
        height: null,
      },

      originalAwbLoading: false,
      originalClientReferenceLoading: false,
    };
  },
  computed: {
    isClient: function() {
      return this.$store.getters.currentUser.data.is_client;
    },
    serverData: function() {
      return this.$store.getters.getPENDINGRETURNSServerData;
    },
    customerIdErrors: function() {
      return this.handleFormValidation("customer_id", this, "formData");
    },
    warehouseErrors: function() {
      return this.handleFormValidation(
        "destination_warehouse_id",
        this,
        "formData"
      );
    },
    originalAwbError: function() {
      return this.handleFormValidation("original_awb", this, "formData");
    },
    clientReferenceErrors: function() {
      return this.handleFormValidation("client_reference", this, "formData");
    },
    returnTypeErrors: function() {
      return this.handleFormValidation("return_type_id", this, "formData");
    },
    amountErrros: function() {
      return this.handleFormValidation("amount", this, "formData");
    },
    currencyErrros: function() {
      return this.handleFormValidation("currency_id", this, "formData");
    },
    addressTypeErrors: function() {
      return this.handleFormValidation(
        "destination_customer_address_type",
        this,
        "formData"
      );
    },
    customerAddressErrors: function() {
      return this.handleFormValidation(
        "destination_customer_address_id",
        this,
        "formData"
      );
    },
    originalClientReferenceError: function() {
      return this.handleFormValidation(
        "original_client_reference",
        this,
        "formData"
      );
    },
    numberOfBoxesErrors: function() {
      return this.handleFormValidation("number_of_boxes", this, "parcel");
    },
    isCustomerNotSelected: function() {
      if (!this.isClient) {
        return !this.formData.customer_id;
      }
      return false;
    },
    isCurrencyAndAmountRequired: function() {
      return (
        this.formData.return_type_id !== 1 &&
        this.formData.return_type_id !== null &&
        this.formData.return_type_id !== 6
      );
    },
    isDestinationCustomer: function() {
      return this.formData.destination_customer_address_type === 3;
    },
    isDestinationWarehouse: function() {
      return this.formData.destination_customer_address_type === 2;
    },
    isFormValid: function() {
      return !this.$v.$invalid;
    },
    selectedDestinationType() {
      return this.$store.getters
        .getPENDINGRETURNSSelectedDestinationAddressType;
    },
    editData() {
      return this.$store.getters.getPENDINGRETURNSEditData;
    },
    destinationAddressType() {
      if (
        this.serverData.destination_address_types &&
        this.createType === "iq"
      ) {
        return this.serverData?.destination_address_types.slice(1);
      } else if (
        this.serverData.destination_address_types &&
        this.createType === "external"
      ) {
        return this.serverData?.destination_address_types.slice(1, -1);
      }
      return [];
    },
    createType() {
      return this.$store.getters.getPENDINGRETURNSCreateReturnType;
    },
  },
  methods: {
    checkErrors() {
      this.$v.$touch();
      // if (this.$v.$invalid) {
      //   return;
      // }
    },
    searchOriginalAwbValues(val) {
      this.searchOriginalValues("awb", "original_awb", val);
    },
    async searchOriginalClientReferenceValues(val) {
      this.searchOriginalValues(
        "client_reference",
        "original_client_reference",
        val
      );
    },
    async searchOriginalOrderNoValues(val) {
      this.searchOriginalValues("order_no", "original_order_number", val);
    },
    async searchOriginalValues(type, field, val) {
      if (val && val.length > 2) {
        switch (type) {
          case "awb":
            this.originalAwbLoading = true;
            break;
          case "order_no":
            this.originalOrderLoading = true;
            break;
          case "client_reference":
            this.originalClientReferenceLoading = true;
            break;
        }
        const data = {
          type,
          query: val,
          customer_id: this.formData.customer_id,
        };
        const res = await ApiService.post(
          "/shipping/returns_management/search",
          data
        );
        this.serverData[field] = res.data[type];
        let i = { ...this.serverData };
        this.$store.commit(SET_SERVER_DATA, i);
        this.originalAwbLoading = false;
        this.originalOrderLoading = false;
        this.originalClientReferenceLoading = false;
      }
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    onChangeDestinationAddressType() {
      this.$store.commit(
        SET_DESTINATION_ADDRESS_TYPE,
        this.formData.destination_customer_address_type
      );
    },
    clearFields() {
      this.formData = {
        client_reference: null,
        return_type_id: null,
        customer_id: null,
        warehouse_id: null,
        original_client_reference: null,
        original_awb: null,
        currency_id: null,
        amount: null,
        comment: null,
        expected_collection_date: null,
        destination_customer_address_type: null,
        destination_customer_address_id: null,
        destination_warehouse_id: null,
      };

      this.parcel = {
        number_of_boxes: 1,
        total_weight: null,
        length: null,
        width: null,
        height: null,
      };

      this.originalAwbLoading = false;
      this.originalClientReferenceLoading = false;

      //reset validation
      this.$v.$reset();
    },
    onChangeCustomer() {
      this.$store.commit(SET_SELECTED_CUSTOMER, this.formData.customer_id);
    },
    dateMaker(inputDate) {
      // Split the date and time parts
      const [datePart, timePart] = inputDate.split(" ");
      // Split the date into day, month, and year
      const [day, month, year] = datePart.split("-").map(Number);
      // Split the time into hours, minutes, and seconds
      const [hours, minutes, seconds] = timePart.split(":").map(Number);
      // Create a new Date object
      const date = new Date(year, month - 1, day, hours, minutes, seconds);
      // Output the date object
      return date;
    },
    getEditData() {
      const expected_collection_date = this.editData.orderDetails
        .expected_collection_date
        ? this.dateMaker(this.editData.orderDetails.expected_collection_date)
        : null;
      this.formData = {
        ...this.editData.orderDetails,
        expected_collection_date,
      };
      this.parcel = {
        ...this.editData.parcel,
      };
    },
  },
  watch: {
    "formData.collection_customer_address_type": function(val) {
      if (val === 2) {
        this.formData = {
          ...this.formData,
          destination_customer_address_id: null,
        };
      } else if (val === 3) {
        this.formData = {
          ...this.formData,
          destination_warehouse_id: null,
        };
      }
    },
  },
};
</script>
