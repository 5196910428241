<template>
  <div>
    <v-dialog transition="dialog-bottom-transition" max-width="1000">
      <template v-slot:activator="{ on, attrs }">
        <span class="symbol symbol-35 symbol-light-success">
          <img v-bind="attrs" v-on="on" v-if="url" :alt="url" :src="url" />
          <span
            v-else
            class="symbol-label font-size-h5 font-weight-bold"
            style="background-color: white"
          >
          </span>
        </span>
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-card-text class="pa-4 text-center">
            <v-carousel>
              <v-carousel-item
                v-for="(item, i) in items"
                :key="i"
                :src="item.url"
                reverse-transition="fade-transition"
                transition="fade-transition"
              ></v-carousel-item>
            </v-carousel>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="red" dark @click="dialog.value = false">{{
              $t("close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ShowImageCarousel",
  props: ["items"],
  computed: {
    url: function () {
      if (this.items) {
        return this.items[0].url;
      } else return null;
    },
  },
};
</script>
