<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{ 'items-per-page-options': item_per_page_list }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>SKUs</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-info px-4 py-2 ls1 mb-2 mr-1"
            @click="() => showEditSkuModal(null)"
            :disabled="isEditable"
          >
            New Item
          </button>
          <button
            type="button"
            class="btn btn-info px-4 py-2 ls1 mb-2"
            :disabled="isEditable"
            @click="showImportModal()"
          >
            Import
          </button>
        </v-toolbar>
      </template>

      <template v-slot:item.label_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.transfer_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.bundle_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.qc_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.assemble_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.special_pack_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :carriers="carriers"
      :clientId="clientId"
      :receiving_type="receiving_type"
    ></EditSku>
    <ImportData
      ref="importData"
      label="SKU"
      :setData="setImportDataToDataTable"
      :customer="customer"
    >
    </ImportData>
  </div>
</template>

<script>
import EditSku from "@/own/components/fulfillment/asn/skuEditContainer/EditSku";
import ImportData from "@/own/components/fulfillment/asn/skuEditContainer/ImportData";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
export default {
  name: "SkuDatatable",
  props: [
    "carriers",
    "clientId",
    "customer",
    "isEditable",
    "receiving_type",
    "item",
  ],
  components: { EditSku, ImportData },
  data: () => ({
    page: 1,
    loading: true,
    options: {},
    totalItems: 0,
    items: [],
    item_per_page_list: [50, 75, 100],
  }),
  mounted() {
    // this.updateSkus();
  },
  computed: {
    headers() {
      let headers = [
        { text: "SKU ID", value: "sku_id" },
        { text: "SKU Barcode", value: "sku_barcode" },
        { text: "Batch Number", value: "batch_number" },
        // { text: "SKU Carton Number", value: "sku_carton_number" },
        { text: "Expected Quantity", value: "expected_qty" },
        { text: "Require Labeling", value: "label_flag" },
        { text: "Transfer", value: "transfer_flag" },
        { text: "Require Bundling", value: "bundle_flag" },
        { text: "Require QC", value: "qc_flag" },
        { text: "Require Assembling", value: "assemble_flag" },
        { text: "Expire Date", value: "exp_date" },
      ];
      if (this.receiving_type == "2" || this.receiving_type == "3") {
        headers.splice(3, 0, {
          text: "Carton number",
          value: "carton_number",
        });
      } else {
        headers.splice(3, 0, {
          text: "SKU Carton Number",
          value: "carton_number",
        });
      }
      if (!this.isEditable) {
        headers.push({ text: "Actions", value: "actions", sortable: false });
      }
      return headers;
    },
  },
  methods: {
    updateSkus() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      this.$store.commit(SET_PAGE_LOADING, true);
      let data = {
        id: this.item,
        page: page,
        per_page: itemsPerPage,
      };
      ApiService.post("/fulfillment/asn/asn-skus", data)
        .then((response) => {
          this.totalItems = response.data.data.total;
          this.items = response.data.data.data;
          this.resetEditForm();
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showImportModal() {
      this.$refs.importData.toggleModal();
    },
    setImportDataToDataTable(items) {
      // this.setSku([]);
      // this.setSku(importedData);
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = {
        id: this.item,
        skus: [items],
      };
      ApiService.post("/fulfillment/asn/store-asn-skus", data)
        .then(() => {
          this.updateSkus();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.updateSkus();
    },
    showEditSkuModal(item) {
      if (this.isEditable) {
        return;
      }
      if (!this.customer && !this.clientId) {
        Swal.fire({
          title: "",
          text: "Please, select customer for adding SKUs!",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      this.$refs.editSku.toggleModal(item);
    },

    editSkus(item, isEdit = false) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = {
        asn_id: this.item,
        skus: [item],
      };
      ApiService.post("/fulfillment/asn/store-asn-skus", data)
        .then(() => {
          Swal.fire({
            title: isEdit ? "Updated" : "Created",
            text: `SKU ${isEdit ? "updated" : "created"}`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.updateSkus();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    removeSkus(item) {
      if (this.isEditable) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = {
        asn_id: this.item,
        asn_sku: {
          sku_id: item.sku_id,
          sku_barcode: item.sku_barcode,

          batch_number: item.batch_number,

          carton_number: item.carton_number,

          exp_date: item.exp_date,
        },
      };
      ApiService.post("/fulfillment/asn/destroy-asn-skus", data)
        .then(() => {
          Swal.fire({
            title: "Deleted",
            text: `SKU deleted!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.updateSkus();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.updateSkus();
      },
      deep: true,
    },
  },
};
</script>
