<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="600px"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">SKU</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5">
        <!--begin::Body-->
        <form class="pb-8 row">
          <div class="col-12 h-75px">
            <v-autocomplete
              v-model="formData.sku_id"
              label="SKU"
              item-text="systemCode"
              item-value="skuId"
              no-filter
              clearable
              outlined
              dense
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              :error-messages="sku_idErrors"
              @input="$v.formData.sku_id.$touch()"
              @blur="$v.formData.sku_id.$touch()"
            ></v-autocomplete>
          </div>
        </form>

        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
export default {
  name: "EditSku",
  mixins: [validationMixin],
  validations: {
    formData: {
      sku_id: { required },
    },
  },
  props: ["actionFunction", "skuItem", "clientId"],
  data: () => ({
    dialog: false,
    isLoading: false,
    menu2: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      sku_id: null,
      system_code: null,
    },
    isEditing: false,
    doneTypingInterval: 1500,
    typingTimer: null,
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.formData.system_code = this.items.map((sku) =>
          sku.skuId === this.formData.sku_id ? sku.systemCode : null
        )[0];
        this.actionFunction(this.formData, this.isEditing, this.isEditingBatch);
        this.dialog = false;
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        sku_id: null,
        system_code: null,
      };
    },
    toggleModal(item) {
      if (item) {
        this.formData = { ...item };
        this.getData(this.formData.system_code);
        this.isEditing = item.sku_id;
      } else {
        this.resetFormData();
        this.entries = this.skuItem;
        this.isEditing = false;
      }
      this.dialog = true;
    },
    async getData(value) {
      let data = { q: value };
      if (this.clientId) data.client = this.clientId;

      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    sku_idErrors: function () {
      return this.handleFormValidation("sku_id", this);
    },
  },
  watch: {
    // search(val) {
    //   this.isLoading = true;
    //   this.getData(val.trim());
    // },
    search(val) {
      clearTimeout(this.typingTimer);
      if (val) {
        this.isLoading = true;
        this.typingTimer = setTimeout(() => {
          this.getData(val.trim());
        }, this.doneTypingInterval);
      } else {
        return;
      }
    },
  },
};
</script>
