<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Currency</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model.trim="$v.formData.name.$model"
            :error-messages="validateState('name') ? errorMessages : ''"
            label="Name"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="$v.formData.system_code.$model"
            :error-messages="validateState('system_code') ? errorMessages : ''"
            label="System Code"
            dense
            outlined
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="$v.formData.symbol.$model"
            :error-messages="validateState('symbol') ? errorMessages : ''"
            label="Symbol"
            dense
            outlined
            clearable
          ></v-text-field>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetData"
          >
            Reset
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      system_code: { required },
      symbol: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    errorMessages: ["This Field is required"],
    formData: {
      id: null,
      name: null,
      system_code: null,
      symbol: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submit() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/cod/currencies/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "The currency has been Updated!",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetData();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    loadDataFromServer() {
      this.formData.id = this.$store.getters.getItemForAction.id;
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/cod/currencies/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.resetData();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    resetData() {
      if (this.serverData) {
        let copy = { ...this.serverData.currency };
        Object.entries(this.formData).forEach((localD) => {
          Object.entries(this.serverData.currency).forEach((serverD) => {
            if (localD[0] === serverD[0]) {
              this.formData[localD[0]] = copy[serverD[0]];
            }
          });
        });
      } else {
        this.formData = {
          name: "",
          systemCode: "",
          symbol: "",
        };
      }
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
