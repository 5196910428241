<template>
  <div
    class="p-4 bg-white rounded m-8"
    style="text-align: justify; overflow: scroll; height: 95vh"
  >
    <div class="mt-20">
      <div class="mb-10 d-lg-block d-none">
        <router-link to="/">
          <div class="logo">
            <img
              class="img-fluid rounded-lg mt-7 ml-10 iq-purple-logo"
              src="/img/iqplatform.png"
              alt="logo"
              width="50px"
            />
          </div>
        </router-link>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-6 ml-6">
          <!-- back button -->
          <div class="mb-6">
            <div
              style="cursor: pointer"
              @click.prevent="goBack"
              class="mb-6 mb-10"
            >
              <span class="svg-icon">
                <v-icon size="30">mdi-chevron-left</v-icon>
              </span>
              <span style="color: black" class="font-size-h4">Back</span>
            </div>
          </div>
          <!-- heading -->
          <h1 class="mb-7 heading">Privacy Policy</h1>
          <!--  -->
          <p class="font-size-h6">
            This Privacy Policy (the ”Policy”) sets out the basis on which the
            personal data, including but not limited to personnel information,
            payment details and other information (the “Data”) collected by IQ
            HYBRID DMCC, a company duly established under the laws of Dubai
            Multi Commodities Center, under the Trade License number DMCC-854544
            and having its registered address at Unit No: 4688 DMCC Business
            Centre Level No 1 Jewellery & Gemplex 3 Dubai United Arab Emirates
            (hereinafter referred to as the “Company” or “We” or “Us” or “Our”)
            through its website accessible from
            <a href="https://app.iqhybrid.com" target="_blank"
              >https://app.iqhybrid.com</a
            >
            or its mobile application (hereinafter referred to jointly as the
            “Platform”) from any user or other sources (hereinafter referred to
            as “Users” or “You”) will be handled. We understand the importance
            you place on the Data, and we are committed to protecting and
            respecting your privacy. Please read the following Policy carefully
            to understand our practices regarding your Data. It is important
            that you read this Policy together with our
            <router-link to="/terms_and_conditions"
              >Terms and Conditions</router-link
            >
            accessible from or any other policy we may provide on specific
            occasions when we are collecting or processing Data about you so
            that you are fully aware of how and why we are using your Data. By
            using the Platform, you represent and warrant that you have also
            read and understood, and agree to be bound by both this Policy and
            the Terms and Conditions.
          </p>
          <!--  -->
          <p class="font-size-h6">
            We may make changes to this Policy from time to time in order to
            keep it up to date and compliant with legal requirements.
          </p>
          <!--  -->
          <p class="font-size-h6">
            This Policy is in compliance with The Federal Decree-Law No. 45 of
            2021 regarding the Protection of Personal Data (PDPL) and any
            implementation or amendments made to it (the “<span
              class="font-weight-bold"
              >Applicable Law</span
            >”). For our international Users, please note that by using our
            Platform, you clearly consent to this collection, transfer, storage,
            and processing of Data in accordance with the Applicable Law.
          </p>

          <br /><br />

          <h2 class="mb-8">WHAT INFORMATION WE MAY COLLECT FROM YOU?</h2>
          <ol class="ml-3 ordered-list">
            <div class="font-size-h6">
              While using the Platform, we may ask you to provide us, or we may
              collect different kind of Data about you. These information may
              include, but is not limited to:

              <ol class="mt-4 ml-6" type="a">
                <li>
                  Data that you provide by filling in forms on our Platform (
                  passport, date of birth, email address, first and last name,
                  phone number, address, state, ZIP, bank account information,
                  etc.), including information provided at the time of
                  registering to use our Platform and other co- registrations
                  (e.g. social media logins), subscribing to our services
                  provided on the Platform, posting material or requesting
                  further services;
                </li>
                <li>
                  the Data you provide when you enter a competition or promotion
                  via our Platform, provide reviews, testimonials or feedback on
                  our Platform;
                </li>
                <li>
                  Data you provide us, or that we may collect from you, when you
                  report a problem with our Platform;
                </li>
                <li>a record of correspondence if you contact us;</li>
                <li>general, aggregated, demographic and non-personal Data;</li>
                <li>
                  if you download or use our mobile application, we may have
                  access to details about your location and your mobile device,
                  including a unique identifier for your device;
                </li>
                <li>
                  details of transactions you carry out through our Platform and
                  of the fulfilment of your request;
                </li>
                <li>
                  details about your computer, including but not limited to your
                  IP address, operating system and browser type, as well as
                  information about your general internet usage (e.g. by using
                  technology that stores information on or gains access to your
                  device, such as cookies, tracking pixels, web beacons, etc.,
                  (together, "Cookies"));
                </li>
                <li>
                  your email address from a third party if you indicate that you
                  have consented to that third party sharing your Data with us;
                  and
                </li>
                <li>
                  any other Data we consider necessary to enhance your
                  experience on the Platform.
                </li>
              </ol>
            </div>
          </ol>

          <h2 class="mb-8 mt-14">HOW DO WE USE THE COLLECTED INFORMATION?</h2>
          <ol class="ml-3 ordered-list">
            <div class="font-size-h6">
              The Company may use Data for the following purposes:
              <ol class="ml-6" type="a">
                <li>
                  to provide you with information, products or services that you
                  request from us or which we feel may interest you, where you
                  have consented to be contacted for such purpose
                </li>
                <li>
                  to provide you with location-based services, such as
                  advertising, search results and other personalised content;
                </li>
                <li>
                  to manage your account on the Platform; to manage your
                  registration as a user of the Platform.
                </li>
                <li>
                  to carry out our obligations arising from any contracts
                  entered into between you and the Company;
                </li>
                <li>to verify the legitimacy of reviews and ratings;</li>
                <li>
                  To contact you by email, telephone calls, SMS, or other
                  equivalent forms of electronic communication, such as a mobile
                  application's push notifications regarding updates or
                  informative communications related to the functionalities,
                  products or contracted services, including the security
                  updates, when necessary or reasonable for their
                  implementation.
                </li>
                <li>
                  for our internal review and business analysis or other
                  business purposes consistent with our mission; and to carry
                  out other purposes that are disclosed to you and to which you
                  consent.
                </li>
                <li>
                  to improve our services and to deliver a better and more
                  personalised service to you;
                </li>
                <li>
                  to ensure that content from our Platform is presented in the
                  most effective manner for you and the device you use to access
                  our Platform;
                </li>
                <li>
                  to notify you about changes to our services, news, special
                  offers and general information about other goods, services and
                  events which we offer that are similar to those that you have
                  already purchased or enquired about unless you have opted not
                  to receive such information available on the Platform;
                </li>
                <li>
                  to evaluate or conduct a merger, divestiture, restructuring,
                  reorganization, dissolution, or other sale or transfer of some
                  or all of our assets, whether as a going concern or as part of
                  bankruptcy, liquidation, or similar proceeding, in which
                  Personal Data held by Us about our Service users is among the
                  assets transferred.
                </li>
                <li>
                  for any other reason which we deem necessary to enhance your
                  experience on the Platform;
                </li>
              </ol>
            </div>
          </ol>

          <h2 class="mb-8 mt-14">TO WHOM DO WE SHARE THE COLLECTED DATA?</h2>
          <ol class="ml-3 ordered-list">
            <div class="font-size-h6">
              We may share your Data in the following situations:
              <ol class="mt-4 ml-6" type="a">
                <li>
                  <span class="font-weight-bolder"
                    >With Service Providers:</span
                  >
                  We may share your Data with service providers to monitor and
                  analyze the use of our Platform, for payment processing, to
                  contact You.
                </li>
                <li>
                  <span class="font-weight-bolder"
                    >For business transfers:</span
                  >
                  We may share or transfer your Data in connection with, or
                  during negotiations of, any merger, sale of Company assets,
                  financing, or acquisition of all or a portion of Our business
                  to another company.
                </li>
                <li>
                  <span class="font-weight-bolder">With Affiliates:</span>
                  We may share the Data with our affiliates, in which case we
                  will require those affiliates to honor this Privacy Policy.
                  Affiliates include but shall not be limited to our parent
                  company, subsidiaries, joint venture partners or other
                  companies that we control or that are under common control
                  with us.
                </li>
                <li>
                  <span class="font-weight-bolder"
                    >With business partners:</span
                  >
                  We may share your Data with our business partners, suppliers
                  and sub-contractors for the performance of our obligations to,
                  to provide us with products and services or to offer you
                  certain products, services or promotions
                </li>
                <li>
                  <span class="font-weight-bolder"
                    >Marketing and Promotional Offers:</span
                  >
                  We may also use your Data to provide you with information
                  about goods and services which may be of interest to you and
                  enhance your Platform experience, service messages, new
                  features, enhancements, special offers and events of interest.
                  We may contact you via various channels, including without
                  limitation emails, push notifications, web notifications,
                  post, telephone, in-app messages and news feed cards.
                </li>
                <li>
                  <span class="font-weight-bolder">Technology Providers:</span>
                  Technology, analytics and search engine providers that assist
                  us in the improvement and optimization of our Platform.
                </li>
                <li>
                  <span class="font-weight-bolder">With other users:</span>
                  when you share Data or otherwise interact in the public areas
                  with other users, such information may be viewed by all users
                  and may be publicly distributed outside
                </li>
                <li>
                  <span class="font-weight-bolder">With Your consent:</span>
                  We may disclose your Data for any other purpose with your
                  consent.
                </li>
              </ol>
              <div class="mt-6">
                Note that our Platform may, from time to time, contain links to
                and from the websites of our partner networks, advertisers and
                affiliates. If you follow a link to any of these websites,
                please note that these websites have their own privacy policies
                and that we do not accept any responsibility or liability for
                these policies. Please check these policies before you submit
                any personal data or other information to these websites.
              </div>
            </div>
          </ol>

          <h2 class="mb-8 mt-14">HOW DO WE STORE YOUR DATA?</h2>
          <ol class="ml-3 ordered-list font-size-h6">
            <ol class="mt-4 ml-6" type="a">
              <li>
                The Company will retain your Data only for as long as it is
                necessary for the purposes set out in this Policy. We will
                retain and use your Data to the extent necessary to comply with
                the Applicable Law and with our legal obligations, resolve
                disputes, and enforce our legal agreements and policies
              </li>
              <li>
                Unfortunately, the transmission of information via the internet
                is not completely secure. Although we will do our best to
                protect your Data, we cannot guarantee the security of your Data
                transmitted to our Platform; any transmission is at your own
                risk. Once we have received your information, we will use strict
                procedures and security features to try to prevent unauthorized
                access.
              </li>
              <li>
                Your Data may be processed at the Company's operating offices in
                the UAE as well as in other places where the parties involved in
                the processing are located. This means that this information may
                be transferred to — and maintained on — computers located
                outside of your state, province, country or other governmental
                jurisdiction where the data protection laws may differ than
                those from your jurisdiction. Whenever we transfer your personal
                data out of the UAE, we ensure a similar degree of protection is
                afforded to it by ensuring at least one of the following
                safeguards is implemented:
                <ul>
                  <li>
                    We will only transfer your personal data to countries that
                    have been deemed to provide an adequate level of protection
                    for personal data.
                  </li>
                  <li>
                    Where we use certain service providers, we may use specific
                    contracts approved for use in the UAE which give personal
                    data the same protection it has in the UAE.
                  </li>
                  <div class="mt-6 font-weight-bold">
                    YOUR CONSENT TO THIS POLICY FOLLOWED BY YOUR SUBMISSION OF
                    SUCH DATA REPRESENTS YOUR CONSENT TO THAT TRANSFER.
                  </div>
                </ul>
              </li>
            </ol>
          </ol>

          <h2 class="mb-8 mt-14">WHAT ARE YOUR RIGHTS?</h2>
          <div class="font-size-h6">
            You may exercise certain rights regarding your Data processed by us.
            In particular, you have the right to do the following:
          </div>
          <ol class="ml-3 ordered-list">
            <ol class="mt-4 ml-6 font-size-h6" type="a">
              <li>
                Be informed if your Personal Information is being processed by
                us, obtain disclosure regarding certain aspects of the
                processing and obtain a copy of the information undergoing
                processing and be informed about third parties with which your
                personal information has been shared;
              </li>
              <li>
                Access Data , including:
                <ol class="ml-6 mt-3">
                  <li>confirming whether we are processing your Data</li>
                  <li>obtaining access to or a copy of your Data.</li>
                </ol>
              </li>
              <li>
                Right to rectification of the accuracy of your Data and ask for
                it to be updated or corrected
              </li>
              <li>
                Request deletion of your Data from us under certain
                circumstances when Data is no longer required for the purposes
                it was collected for, or your consent for the processing of that
                data has been withdraw, or the Data has been unlawfully
                processed
              </li>
              <li>
                Request data portability and receive an electronic copy of Data
                that you have provided to us in a structured, commonly used and
                machine-readable format.
              </li>
              <li>
                In order to exercise any of these rights, simply contact us at:
              </li>
            </ol>
          </ol>

          <h2 class="mb-8 mt-14">HOW TO ENSURE YOUR CHILDREN’S PRIVACY?</h2>
          <div class="font-size-h6 ml-6">
            Our Platform and services available on the Platform are not intended
            for or directed to children. Children under 18 are not eligible to
            use our Platform. By using the Platform, you represent that you are
            at least 18 years old and understand that you must be at least 18
            years old in order to create an account and purchase the services
            provided on the Platform. We do not knowingly collect or maintain
            Data from children under the age of 18. If we learn that we have
            inadvertently gathered Data from children younger than the age of
            18, we will take reasonable measures to delete or destroy such
            information from our records
          </div>

          <h2 class="mb-8 mt-14">FACEBOOK FAN PAGE</h2>
          <div></div>
          <ol class="ml-3 ordered-list">
            <ol class="mt-4 ml-6 font-size-h6" type="a">
              <li>
                The Company is the controller of your Data collected while using
                the Platform. As operator of the Facebook fan page accessible
                from https://www.facebook.com/iqfulfillment/ (the “Facebook Fan
                Page”), the Company and the operator of the social network
                Facebook are joint controllers.
              </li>
              <li>
                The Company has entered into agreements with Facebook that
                define the terms for use of the Facebook Fan Page, among other
                things. These terms are mostly based on the Facebook terms of
                service: https://www.facebook.com/terms.php
              </li>
              <li>
                Visit the Facebook privacy policy at
                https://www.facebook.com/policy.php for more information about
                how Facebook manages personal data or contact Facebook online,
                or by mail: Facebook, Inc. ATTN, Privacy Operations, 1601 Willow
                Road, Menlo Park, CA 94025, United States.
              </li>
              <li>
                We use the Facebook insights function in connection with the
                operation of the Facebook Fan Page in order to obtain anonymized
                statistical data about our users. For this purpose, Facebook
                places a Cookie on the device of the user visiting our Facebook
                Fan Page. Each cookie contains a unique identifier code and
                remains active for a period of two years, except when it is
                deleted before the end of this period.
              </li>
              <li>
                Facebook receives, records and processes the information stored
                in the cookie, especially when the user visits the Facebook
                services, services that are provided by other members of the
                Facebook Fan Page and services by other companies that use
                Facebook services.
              </li>
              <li>
                For more information on the privacy practices of Facebook,
                please visit Facebook Privacy Policy here:
                <a
                  href="https://www.facebook.com/privacy/explanation"
                  target="_blank"
                  >https://www.facebook.com/privacy/explanation</a
                >.
              </li>
            </ol>
          </ol>

          <h2 class="mb-8 mt-14">COOKIES</h2>
          <ol class="mt-4 ml-6 font-size-h6 ordered-list" type="a">
            <li>
              Our Platform may place and access certain first-party cookies on
              your computer or device. First-party cookies are those placed
              directly by us and are used only by us. We use cookies to
              facilitate and improve your experience of our Platform and to
              provide and improve our and/or third-party products and/or
              services. We have carefully chosen these cookies and have taken
              steps to ensure that your privacy and personal data is protected
              in accordance with this Privacy Policy.
            </li>
            <li>
              All cookies used by us on our Platform are used in accordance with
              UAE law as applicable in the Emirate of Dubai.
            </li>
            <li>
              Our Platform may use analytics services provided by third parties.
              Analytics refers to a set of tools used to collect and analyse
              anonymous usage information, enabling us to better understand how
              Our Platform is used. This, in turn, enables us to improve our
              Platform and the services offered through it.
            </li>
            <li>
              The analytics service(s) used by our Platform use(s) cookies to
              gather the required information.
            </li>
            <li>
              In addition to the controls that we provide, you can choose to
              enable or disable cookies in your internet browser. Most internet
              browsers also enable you to choose whether you wish to disable all
              cookies. By default, most internet browsers accept cookies, but
              this can be changed. If you disable or refuse cookies, please note
              that some parts of the Platform may become inaccessible or not
              function properly.
            </li>
            <li>
              You can choose to delete cookies on your computer or device at any
              time, however, in certain cases, you may lose any information that
              enables you to access our Platform more quickly and efficiently
              including, but not limited to, login and personalization settings.
            </li>
            <li>
              It is recommended that you keep your internet browser and/or your
              device’s operating system up-to-date and that you consult the help
              and guidance provided by the developer of your internet browser
              and manufacturer of your device if you are unsure about adjusting
              your privacy settings
            </li>
          </ol>

          <h2 class="mb-8 mt-14">
            WHAT HAPPENS IF WE CHANGE OUR PRIVACY POLICY?
          </h2>
          <ol class="mt-4 ml-6 font-size-h6 ordered-list" type="a">
            <li>
              Our business changes constantly, and our Policy may therefore also
              need to change. We will post the current version of this Privacy
              Policy on the Platform and each such change will be effective upon
              posting on the Platform or upon the date designated by us as the
              "effective date".
            </li>
            <li>
              We may e-mail periodic reminders of our notices and conditions,
              but you should check our Platform frequently to see recent
              changes.
            </li>
            <li>
              It is your obligation to regularly check the Policy. Your
              continued use of the Platform following any such change
              constitutes your agreement to this Privacy Policy as so modified.
            </li>
          </ol>

          <h2 class="mb-8 mt-14">GOVERNING LAW AND JURISDICTION</h2>
          <div class="font-size-h6 ml-6">
            This Privacy Policy, and the relationship between you and us
            (whether contractual or otherwise) shall be governed by and
            construed in accordance with the laws of the Emirate of Dubai and
            the Federal Law of the UAE. The Dubai Courts shall have exclusive
            jurisdiction over this Policy.
          </div>

          <h2 class="mb-8 mt-14">HOW CAN YOU CONTACT US?</h2>
          <div class="font-size-h6 ml-6 mb-20">
            If you have any questions about this Policy or our privacy
            practices, or wish to contact us concerning any matter relating to
            individual rights and your Data, you may contact us on the following
            addresses:
            <ul class="ml-6 mt-4">
              <li>
                By email:
                <a href="mailto: help@iqhybrid.com">help@iqhybrid.com</a>
              </li>
              <li>
                By visiting this page on our website:
                <a
                  href="https://www.iqholding.com/index.php/contact-us/"
                  target="_blank"
                  >https://www.iqholding.com/index.php/contact-us/</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "PrivacyPolicy",
  methods: {
    goBack(event) {
      event.preventDefault();

      this.$store.commit(SET_PAGE_LOADING, true);
      const referrer = document.referrer;
      if (referrer.includes(window.location.origin)) {
        this.$router.go(-1);
      } else {
        window.location.href = "/";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.heading {
  font-size: 35px;
}
.ordered-list {
  li {
    margin-bottom: 10px;
  }
}
.logo {
  img {
    position: absolute;
    top: 37px;
    left: 82px;
  }
}
</style>
