<template>
  <div class="mx-4 mb-8 position-relative">
    <FilterSelector
      :filters="filters"
      ref="filter"
      :submitFilterValues="applyFilters"
    ></FilterSelector>
    <div
      class="w-100 pa-6 bg-white d-flex justify-center align-center position-fixed zindex-5 bottom-0 right-0"
    >
      <div class="w-50 mr-4">
        <button
          @click="resetFilters"
          class="w-100 btn btn--export-filter d-flex align-center justify-center poppins font-size-lg line-height-xl ls1 text-uppercase"
        >
          <span class="svg-icon">
            <v-icon size="24"> mdi-chevron-left</v-icon>
          </span>
          {{ $t("clear") }}
        </button>
      </div>
      <div class="w-50 mr-1">
        <button
          class="w-100 btn iq-btn poppins text-no-wrap font-size-lg line-height-xl ls1 text-uppercase"
          @click.prevent="applyFilters"
        >
          {{ buttonTitle }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FilterSelector from "@/own/components/filter/FilterSelector.vue";
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
export default {
  name: "FilterContainer",
  components: { FilterSelector },
  props: {
    submitFilterValues: {
      required: true,
      type: Function,
    },
    resetFilterValues: {
      required: true,
      type: Function,
    },
    setTableFiltersValues: {
      required: true,
      type: Function,
    },
    buttonTitle: {
      required: false,
      type: String,
    },
    filters: {
      required: true,
    },
  },
  methods: {
    applyFilters() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.setTableFiltersValues(this.formatFilterData());
          this.submitFilterValues();
          this.$emit("submit");
        }, 60);
      });
    },
    resetFilters() {
      this.$store.commit(SET_FILTERS_DATA, {});
      this.clearFiltersState();
      this.resetFilterValues();
    },
    clearFiltersState() {
      this.$refs.filter.clearData();
    },

    formatFilterData() {
      let men = [];
      let val = this.$store.getters.getFiltersData;

      Object.entries(val).forEach((filter) => {
        if (
          filter[0] != null &&
          filter[1] != null &&
          filter[1] != undefined &&
          filter[1].length !== 0
        ) {
          men.push({ name: filter[0], value: filter[1] });
        }
      });

      return men;
    },
  },
};
</script>
