<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
    v-if="$store.getters.getWEBHOOKSActionsDialogServerData"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row flex-grow-0 flex-shrink-0">
          <div
            class="col-12 h-75px"
            v-if="
              $store.getters.getWEBHOOKSActionsDialogServerData.hasOwnProperty(
                'organizations'
              )
            "
          >
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              item-text="text"
              item-value="index"
              :items="
                $store.getters.getWEBHOOKSActionsDialogServerData.organizations
              "
              clearable
              outlined
              dense
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="!isClient">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="filteredClients"
              clearable
              outlined
              dense
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" :class="{ 'col-md-6': isClient }">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              item-text="title"
              item-value="index"
              :items="$store.getters.getWEBHOOKSActionsDialogServerData?.status"
              clearable
              outlined
              dense
              :error-messages="statusErrors"
              @input="$v.formData.status.$touch()"
              @blur="$v.formData.status.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.maximum_retry"
              label="Maximum retry"
              clearable
              outlined
              dense
              type="number"
              :min="0"
              @change="() => validateMinValue('maximum_retry', 0)"
              :error-messages="maximumRetryErrors"
              @input="$v.formData.maximum_retry.$touch()"
              @blur="$v.formData.maximum_retry.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" :class="{ 'col-md-6': isClient }">
            <v-autocomplete
              v-model="formData.action_event"
              label="Action event"
              item-text="title"
              item-value="index"
              :items="
                $store.getters.getWEBHOOKSActionsDialogServerData?.action_events
              "
              clearable
              outlined
              dense
              :error-messages="actionEventErrors"
              @input="$v.formData.action_event.$touch()"
              @blur="$v.formData.action_event.$touch()"
            />
          </div>

          <div
            class="col-12 h-75px"
            :class="{ 'col-md-6': isClient }"
            v-if="formData.action_event > 1"
          >
            <v-autocomplete
              v-model="formData.action_states"
              label="Action statuses"
              item-text="text"
              item-value="index"
              :items="getActionStatus"
              clearable
              outlined
              multiple
              dense
              :error-messages="actionStatusErrors"
              @input="$v.formData.action_states.$touch()"
              @blur="$v.formData.action_states.$touch()"
            ></v-autocomplete>
          </div>

          <div class="col-12 h-75px" v-if="formData.action_event > 1">
            <v-autocomplete
              v-model="formData.related_types"
              label="Related types"
              item-text="text"
              item-value="index"
              :items="getRelatedTypes"
              clearable
              outlined
              multiple
              dense
              :error-messages="related_typesErrors"
              @input="$v.formData.related_types.$touch()"
              @blur="$v.formData.related_types.$touch()"
            ></v-autocomplete>
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.url"
              label="URL"
              clearable
              outlined
              dense
              :error-messages="urlErrors"
              @input="$v.formData.url.$touch()"
              @blur="$v.formData.url.$touch()"
            />
          </div>
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import {
  maxValue,
  minValue,
  required,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_ACTIONS_DIALOG_SERVER_DATA } from "@/core/services/store/webhooks.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return this.$store.getters.getWEBHOOKSActionsDialogServerData.hasOwnProperty(
            "organizations"
          );
        }),
      },
      customer_id: {
        required: requiredIf(function () {
          return !this.isClient;
        }),
      },
      action_event: { required },
      action_states: {
        required: requiredIf(function () {
          return this.formData.action_event > 1;
        }),
      },
      related_types: {
        required: requiredIf(function () {
          return this.formData.action_event > 1;
        }),
      },
      url: { required, url },
      status: { required },
      maximum_retry: { minValue: minValue(0), maxValue: maxValue(5) },
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      organization_id: null,
      customer_id: null,
      action_event: null,
      url: null,
      status: null,
      maximum_retry: null,
      action_states: null,
      related_types: [],
    },
  }),
  methods: {
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/datahub/webhooks/edit", sendData)
          .then(({ data }) => {
            this.dialog = true;
            if (!this.$store.getters.getWEBHOOKSActionsDialogServerData) {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, {
                ...data,
                status: data.status,
                clients: data.clients,
                action_events: data.action_events,
                order_statuses: data.order_statuses,
                return_statuses: data.return_statuses,
                asn_statuses: data.asn_statuses,
              });
            }
            this.formData = {
              id: data.webhook.id,
              customer_id: data.webhook.customer_id,
              organization_id: data.webhook?.organization_id,
              action_event: data.webhook.action_event,
              url: data.webhook.url,
              status: data.webhook.status,
              maximum_retry: data.webhook.maximum_retry,
              action_states: data.webhook.action_states,
              related_types: data.webhook.related_types,
            };

            this.dialog = true;
            this.pageLoader(false);
          })
          .catch(() => {
            // console.log("err", err);
            this.dialog = false;
            this.pageLoader(false);
          })
          .finally(() => {
            // this.$refs.jsonEditor.updateProps();
          });
      } else {
        if (!this.$store.getters.getWEBHOOKSActionsDialogServerData) {
          this.pageLoader(true);
          ApiService.post("/datahub/webhooks/create")
            .then(({ data }) => {
              this.$store.commit(SET_ACTIONS_DIALOG_SERVER_DATA, {
                ...data,
                status: data.status,
                clients: data.clients,
                action_events: data.action_events,
                order_statuses: data.order_statuses,
                return_statuses: data.return_statuses,
                asn_statuses: data.asn_statuses,
              });
              this.pageLoader(false);
            })
            .catch(() => {
              this.dialog = false;
              this.pageLoader(false);
            });
        }
        // this.resetFormData();
        this.dialog = true;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        // this.dialog = !this.dialog;
        this.dialog = false;
      } else {
        this.loadDataFromServer();
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("minValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].minValue &&
          errors.push("Value should be greater than 0");
      }
      if ("maxValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].maxValue &&
          errors.push("Value should be less than 5");
      }
      if ("url" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].url && errors.push("This url is invalid");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.$store.getters.isClient) delete this.formData.customer_id;

      const payload = {
        customer_id: this.formData.customer_id,
        action_event: this.formData.action_event,
        url: this.formData.url,
        status: this.formData.status,
        maximum_retry: this.formData.maximum_retry,
        action_states: this.formData.action_states,
        related_types: this.formData.related_types,
      };

      if (
        this.$store.getters.getWEBHOOKSActionsDialogServerData.hasOwnProperty(
          "organizations"
        )
      ) {
        payload.organization_id = this.formData.organization_id;
      }

      let actionUrl = "store";
      if (this.actionType) {
        actionUrl = "update";
        payload.id = this.formData.id;
      }
      this.pageLoader(true);
      ApiService.post(`/datahub/webhooks/${actionUrl}`, payload)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetFormData() {
      this.formData = {
        organization_id: null,
        customer_id: null,
        action_event: null,
        url: null,
        status: null,
        maximum_retry: null,
        action_states: null,
        related_types: [],
      };
    },
  },
  computed: {
    filteredClients() {
      return this.$store.getters.getWEBHOOKSActionsDialogServerData?.clients.filter(
        (client) => client.organization_id === this.formData.organization_id
      );
    },
    getActionStatus() {
      if (this.formData.action_event == 1) {
        return [];
      } else if (
        this.formData.action_event == 3 ||
        this.formData.action_event == 10
      ) {
        return this.$store.getters.getWEBHOOKSActionsDialogServerData
          .order_statuses;
      } else if (this.formData.action_event == 8) {
        return this.$store.getters.getWEBHOOKSActionsDialogServerData
          .asn_statuses;
      } else if (this.formData.action_event == 9) {
        return this.$store.getters.getWEBHOOKSActionsDialogServerData
          .return_statuses;
      } else {
        return [];
      }
    },
    getRelatedTypes() {
      if (this.formData.action_event == 1) {
        return [];
      } else if (
        this.formData.action_event == 3 ||
        this.formData.action_event == 10
      ) {
        return this.$store.getters.getWEBHOOKSActionsDialogServerData
          .order_types;
      } else if (this.formData.action_event == 8) {
        return this.$store.getters.getWEBHOOKSActionsDialogServerData.asn_types;
      } else if (this.formData.action_event == 9) {
        return this.$store.getters.getWEBHOOKSActionsDialogServerData
          .return_types;
      } else {
        return [];
      }
    },
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id");
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    actionEventErrors: function () {
      return this.handleFormValidation("action_event");
    },
    actionStatusErrors: function () {
      return this.handleFormValidation("action_states");
    },
    related_typesErrors: function () {
      return this.handleFormValidation("related_types");
    },
    urlErrors: function () {
      return this.handleFormValidation("url");
    },
    maximumRetryErrors: function () {
      return this.handleFormValidation("maximum_retry");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
  },
};
</script>
