<template>
  <div class="docs-card mt-8">
    <div class="docs-card-header">
      <h4 class="font-size-h2 text-dark-65 my-3">
        {{ $t("get_return") }}
      </h4>
    </div>
    <div class="docs-card-body px-8 pb-8">
      <p class="text-dark-65 w-1000px mt-3">
        {{
          $t("our_return_plugin_can_be_easily_integrated_with_any_application")
        }}
      </p>
      <h5 class="font-size-h3 text-dark-65 mt-6 mb-4">
        {{ $t("request_endpoint") }}
      </h5>
      <!-- request type -->
      {{ $t("the_request_method") }}:<code>GET</code>
      <p class="mt-6 mb-6 text-dark-65">
        {{ $t("the_request_end_point") }}:<code>/api/v3/returns</code>
      </p>
      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("request_header") }}
      </h5>
      <div class="highlight mb-5">
        <div class="highlight-code">
          <span class="token-tag">Authorization: </span>
          <span class="token-value">"Bearer [TOKEN]",</span>
          <br />
          <span class="token-tag">Content-type: </span>
          <span class="token-value">"application/json; charset=utf-8",</span>
          <br />
          <span class="token-tag">Accept: </span>
          <span class="token-value">"application/json</span>
          <br />
        </div>
      </div>

      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("request_payload") }}
      </h5>
      <div class="highlight mb-5">
        <div class="highlight-code">
          <span class="token-string">{</span>
          <div class="ml-4">
            <span class="token-tag">"per_page": </span>
            <span class="token-value">"nullable|numeric",</span>
            <br />
            <span class="token-tag">"page": </span>
            <span class="token-value">"nullable|numeric",</span>
            <br />
            <span class="token-tag">"client_references ": </span>
            <span class="token-value">"nullable|numeric",</span>
            <br />
          </div>
          <span class="token-string">}</span>
        </div>
      </div>
      <h5 class="font-size-h3 text-dark-65 mb-4 mt-10">
        {{ $t("example_response") }}
      </h5>
      <div class="highlight">
        <div class="highlight-code">
          <span class="token-string">{</span>
          <div class="ml-4">
            <span class="token-tag">success: </span>
            <span class="token-value">true,</span>
            <br />
            <span class="token-tag">pagination: </span>
            <span class="token-value">{</span>
            <br />
            <div class="ml-4">
              <span class="token-tag">per_page: </span>
              <span class="token-value">50,</span>
              <br />

              <span class="token-tag">current_page: </span>
              <span class="token-value">1,</span>
              <br />

              <span class="token-tag">last_page: </span>
              <span class="token-value">1,</span>
              <br />

              <span class="token-tag">total: </span>
              <span class="token-value">3</span>
              <br />
            </div>
            <span class="token-value">},</span>
            <br />
            <span class="token-tag">applied_filters: </span>
            <span class="token-value">[],</span>
            <br />

            <span class="token-tag">data: </span>
            <span class="token-value">[</span>
            <br />
            <div class="ml-4">
              <span class="token-value">{ </span>
              <br />
              <div class="ml-4">
                <span class="token-tag">id: </span>
                <span class="token-value">3005,</span>
                <br />

                <span class="token-tag">warehouse: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">sender_name: </span>
                <span class="token-value">Test Name,</span>
                <br />

                <span class="token-tag">sender_phone: </span>
                <span class="token-value">555444333,</span>
                <br />

                <span class="token-tag">sender_email: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">return_type_id: </span>
                <span class="token-value">Reverse Pick,</span>
                <br />

                <span class="token-tag">order_id: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">collection_type_id: </span>
                <span class="token-value">Pick up,</span>
                <br />

                <span class="token-tag">collection_address_type: </span>
                <span class="token-value">Descriptive Address,</span>
                <br />

                <span class="token-tag">currency_id: </span>
                <span class="token-value">-,</span>
                <br />

                <span class="token-tag">system_order_number: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">client_reference: </span>
                <span class="token-value">SPLRT002987,</span>
                <br />

                <span class="token-tag">delivery_no: </span>
                <span class="token-value">CRDSAL9965209999,</span>
                <br />

                <span class="token-tag">original_awb: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">original_client_reference: </span>
                <span class="token-value">OTO2406061210,</span>
                <br />

                <span class="token-tag">original_order_number: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">comment: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">amount: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">expected_collection_date: </span>
                <span class="token-value">2024-07-03 08:00:00,</span>
                <br />

                <span class="token-tag">last_status_id: </span>
                <span class="token-value">Under process,</span>
                <br />

                <span class="token-tag">last_status_date: </span>
                <span class="token-value">2024-08-14 15:50:19,</span>
                <br />

                <span class="token-tag">total_price: </span>
                <span class="token-value">100,</span>
                <br />

                <span class="token-tag">number_of_boxes: </span>
                <span class="token-value">1,</span>
                <br />

                <span class="token-tag">total_weight: </span>
                <span class="token-value">1,</span>
                <br />

                <span class="token-tag">drop_off_office: </span>
                <span class="token-value">[],</span>
                <br />
                <span class="token-tag">collection_addres: </span>
                <span class="token-value">{,</span>
                <br />
                <div class="ml-4">
                  <span class="token-tag">country: </span>
                  <span class="token-value">Saudi Arabia,</span>
                  <br />

                  <span class="token-tag">state: </span>
                  <span class="token-value">Riyadh,</span>
                  <br />

                  <span class="token-tag">city: </span>
                  <span class="token-value">Riyadh,</span>
                  <br />

                  <span class="token-tag">area: </span>
                  <span class="token-value">null,</span>
                  <br />

                  <span class="token-tag">address: </span>
                  <span class="token-value">Test address,</span>
                  <br />

                  <span class="token-tag">address_2: </span>
                  <span class="token-value">Riyadh KSA,</span>
                  <br />

                  <span class="token-tag">short_address: </span>
                  <span class="token-value">null,</span>
                  <br />

                  <span class="token-tag">postal_code: </span>
                  <span class="token-value">null,</span>
                  <br />
                </div>
                <span class="token-value">},</span>
                <br />
                <span class="token-tag">destination_address: </span>
                <span class="token-value">{</span>
                <br />
                <span class="token-tag">name: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">phone: </span>
                <span class="token-value">+966 59 117 0077,</span>
                <br />

                <span class="token-tag">country_label: </span>
                <span class="token-value">Saudi Arabia,</span>
                <br />

                <span class="token-tag">state_label: </span>
                <span class="token-value">Riyadh,</span>
                <br />

                <span class="token-tag">city_label: </span>
                <span class="token-value">Riyadh,</span>
                <br />

                <span class="token-tag">area_label: </span>
                <span class="token-value">null,</span>
                <br />

                <span class="token-tag">street: </span>
                <span class="token-value">King Fahad Branch,</span>
                <br />

                <span class="token-tag">address: </span>
                <span class="token-value"
                  >9159 King Fahad Branch - Al Olaya Dist.,</span
                >
                <br />

                <span class="token-tag">address_2: </span>
                <span class="token-value">RIYADH 12214 - 2301,</span>
                <br />

                <span class="token-tag">postal_code: </span>
                <span class="token-value">12214,</span>
                <br />

                <span class="token-tag">building_no: </span>
                <span class="token-value">9159,</span>
                <br />

                <span class="token-tag">unit_no: </span>
                <span class="token-value">1,</span>
                <br />

                <span class="token-tag">additional_no: </span>
                <span class="token-value">2301,</span>
                <br />

                <span class="token-tag">short_address: </span>
                <span class="token-value">RHOA9159,</span>
                <br />

                <span class="token-tag">items: </span>
                <span class="token-value">[</span>
                <br />
                <div class="ml-4">
                  <span class="token-value">{</span>
                  <br />
                  <div class="ml-4">
                    <span class="token-tag">delivery_number: </span>
                    <span class="token-value">CRDSAL9965209999,</span>
                    <br />
                    <span class="token-tag">description: </span>
                    <span class="token-value">item desc,</span>
                    <br />
                    <span class="token-tag">category: </span>
                    <span class="token-value">Other,</span>
                    <br />
                    <span class="token-tag">length: </span>
                    <span class="token-value">10,</span>
                    <br />
                    <span class="token-tag">width: </span>
                    <span class="token-value">10,</span>
                    <br />
                    <span class="token-tag">height: </span>
                    <span class="token-value">10,</span>
                    <br />
                    <span class="token-tag">price: </span>
                    <span class="token-value">100,</span>
                    <br />
                    <span class="token-tag">weight: </span>
                    <span class="token-value">1,</span>
                    <br />
                    <span class="token-tag">label: </span>
                    <span class="token-value">Under process,</span>
                    <br />
                    <span class="token-tag">status_history: </span>
                    <span class="token-value">[</span>
                    <div class="ml-4">
                      <span class="token-value">{</span>
                      <br />
                      <div class="ml-4">
                        <span class="token-tag">label: </span>
                        <span class="token-value">Under process,</span>
                        <br />
                        <span class="token-tag">time: </span>
                        <span class="token-value">1723636219,</span>
                        <br />
                        <span class="token-tag">note: </span>
                        <span class="token-value"
                          >Preparation. We received your shipment details,</span
                        >
                      </div>

                      <span class="token-value">},</span>
                    </div>
                    <div class="ml-4">
                      <span class="token-value">{</span>
                      <br />
                      <div class="ml-4">
                        <span class="token-tag">label: </span>
                        <span class="token-value">Created,</span>
                        <br />
                        <span class="token-tag">time: </span>
                        <span class="token-value">1723636210,</span>
                        <br />
                        <span class="token-tag">note: </span>
                        <span class="token-value">Item created.</span>
                      </div>

                      <span class="token-value">},</span>
                    </div>
                    <span class="token-value">]</span>
                  </div>
                  <span class="token-value">}, </span>
                </div>
                <span class="token-value">}</span>
              </div>
              <span class="token-value">} </span>
              <br />
            </div>
            <span class="token-value">]</span>
          </div>
          <span class="token-string">}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GetReturn",
};
</script>
