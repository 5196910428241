<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :recordDetailComponent="componentForRecordDetail"
      :urls="urls"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/sortationOrders.module";
import RecordDetail from "@/own/components/RecordDetail.vue";

export default {
  name: "SortationOrders",
  components: { DataTable },
  data: () => ({
    // componentForEditing: ActionsDialog,
    // componentForAdding: ActionsDialog,
    componentForRecordDetail: RecordDetail,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      // EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getSORTATIONORDERSTableFilters",
      getTableState: "getSORTATIONORDERSTableState",
      getTableData: "getSORTATIONORDERSTableData",
      getTableHeaders: "getSORTATIONORDERSTableHeaders",
      getTableProperties: "getSORTATIONORDERSTableProperties",
      // getTableExportUrl: "getSORTATIONORDERSExportUrl",
      getTablePermissions: "getSORTATIONORDERSTablePermissions",
    },
    urls: {
      details: "/warehouse_management/sortation_orders/show",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.$store.getters.getSelectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
      }
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
      }
    },
  },
  beforeMount() {
    this.initTableContent();
  },
};
</script>
