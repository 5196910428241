import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const GET_REPORT_AGENT_DATA = "getREPORTAGENTData";
export const SHOW_REPORT_AGENT_DATA = "showREPORTAGENTData";
export const EXPORT_REPORT_AGENT_DATA = "exportREPORTAGENTData";
export const SHOW_REPORT_DATATABLE = "showREPORTDATATABLE";

// mutation types
export const SET_REPORT_AGENT_PROPERTIES = "setREPORTAGENTProperties";
export const SET_REPORT_AGENT_HAS_EXPORT = "setREPORTAGENTHasExport";
export const SET_REPORT_AGENT_FILTERS = "setREPORTAGENTFilters";
export const SET_REPORT_AGENT_FILTERS_RESULT = "setREPORTAGENTFiltersResult";
export const SET_EXPORT_LINK = "setREPORTAGENTExportLink";
export const SET_TABLE_PAGE = "setREPORTAGENTTablePage";
export const SET_TABLE_PER_PAGE = "setREPORTAGENTTablePerPage";
export const SET_TABLE_SORT_BY = "setREPORTAGENTTableSortBy";
export const SET_TABLE_SORT_ORDER = "setREPORTAGENTTableSortOrder";
export const SET_REPORT_DATATABLE_RESULT = "setREPORTDATATABLEResult";

const state = {
  properties: {},
  hasExport: false,
  exportLink: undefined,
  filters: [
    {
      name: null,
      title: null,
      icon: null,
      type: null,
      related_columns: [],
      values: [],
    },
    {
      name: null,
      title: null,
      type: null,
      related_columns: [],
    },
  ],
  filtersResult: {
    header: [],
    body: [],
  },
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  dataTableQuantity: 0,
  titlesOfDataTables: [],
  filtersDataTableResultHeaders: [],
  filtersDataTableResultBody: [],
  filterDataTableMaxValue: [],
};

const getters = {
  /**
   * for table title, description
   * */
  getREPORTAGENTProperties(state) {
    return state.properties;
  },
  getREPORTAGENTHasExport(state) {
    return state.hasExport;
  },
  getREPORTAGENTExportUrl(state) {
    return state.exportLink;
  },
  getREPORTAGENTFilters(state) {
    return state.filters;
  },
  getREPORTAGENTFiltersResult(state) {
    return state.filtersResult;
  },

  getREPORTAGENTTableState(state) {
    return state.table_state;
  },

  getREPORTDATATABLEQuantity(state) {
    return state.dataTableQuantity;
  },
  getREPORTDATATABLETitlesOfDataTables(state) {
    return state.titlesOfDataTables;
  },
  getREPORTDATATABLEResultHeaders(state) {
    return state.filtersDataTableResultHeaders;
  },
  getREPORTDATATABLEResultBody(state) {
    return state.filtersDataTableResultBody;
  },
  getREPORTDATATABLEMaxValue(state) {
    return state.filterDataTableMaxValue;
  },
};

const actions = {
  [GET_REPORT_AGENT_DATA](context, url) {
    return new Promise((resolve) => {
      context.commit(SET_PAGE_LOADING, true);
      ApiService.post(`${url}`)
        .then(({ data }) => {
          context.commit(SET_REPORT_AGENT_PROPERTIES, data.data.properties);
          context.commit(SET_REPORT_AGENT_FILTERS, data.data.filters);
          data.data?.export && context.commit(SET_REPORT_AGENT_HAS_EXPORT);
          resolve(data.data);
        })
        .catch((data) => {
          resolve(data.data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [SHOW_REPORT_AGENT_DATA](context, { url, filtersData }) {
    return new Promise((resolve) => {
      context.commit(SET_PAGE_LOADING, true);
      ApiService.post(`${url}`, {
        filters: filtersData,
      })
        .then(({ data }) => {
          context.commit(
            SET_REPORT_AGENT_FILTERS_RESULT,
            data.charts[0].values
          );
          resolve(data.charts);
        })
        .catch(({ data }) => {
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [EXPORT_REPORT_AGENT_DATA](context, { dataUrl, payload }) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(`${dataUrl}/export`, payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [SHOW_REPORT_DATATABLE](context, { url, filtersData }) {
    return new Promise((resolve) => {
      context.commit(SET_PAGE_LOADING, true);
      ApiService.post(`${url}`, {
        filters: filtersData,
      })
        .then(({ data }) => {
          context.commit(SET_REPORT_DATATABLE_RESULT, data.charts);
          resolve(data.charts);
        })
        .catch(({ data }) => {
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
};

const mutations = {
  [SET_REPORT_DATATABLE_RESULT](state, charts) {
    // state.filtersDataTableResult = charts;
    const percentHeaders = [
      "AO",
      "A1",
      "A2",
      "A3",
      "A4",
      "A5",
      "AA",
      "DO",
      "D1",
      "D2",
      "D3",
      "D4",
      "D5",
      "DD",
    ];
    state.dataTableQuantity = charts.length;
    state.titlesOfDataTables = [];
    if (state.dataTableQuantity) {
      charts.forEach((chart, chartIndex) => {
        state.filtersDataTableResultHeaders[chartIndex] = [];
        state.filtersDataTableResultBody[chartIndex] = [];
        state.filterDataTableMaxValue[chartIndex] = 0;
        let dataArray = [];

        state.titlesOfDataTables.push(chart.title);

        chart.values.header.forEach((head) => {
          state.filtersDataTableResultHeaders[chartIndex].push({
            text: percentHeaders.includes(head) ? head + " %" : head,
            value: head.replaceAll(" ", ""),
            class:
              "py-4 text-muted font-size-sm fw-bolder text-uppercase poppins-ls",
            cellClass: "text-dark-75 font-weight-medium poppins-ls",
          });
        });

        chart.values.body.forEach((items) => {
          let obj = {};
          items.forEach((item, index) => {
            obj[state.filtersDataTableResultHeaders[chartIndex][index].value] =
              item;
          });
          state.filtersDataTableResultBody[chartIndex].push(obj);
          if (
            chart.name == "matrixAttempts" ||
            chart.name == "matrixDelivered"
          ) {
            items.shift();
            const itemNumArr = items.map((str) => {
              return str * 1;
            });
            dataArray = [...dataArray, ...itemNumArr];
          }
        });
        if (chart.name == "matrixAttempts" || chart.name == "matrixDelivered") {
          state.filterDataTableMaxValue[chartIndex] = Math.max(...dataArray);
        }
      });
    } else {
      state.filtersDataTableResultHeaders = [];
      state.filtersDataTableResultBody = [];
      state.dataTableQuantity = 0;
    }
  },
  [SET_REPORT_AGENT_PROPERTIES](state, properties) {
    state.properties = properties;
  },
  [SET_REPORT_AGENT_HAS_EXPORT](state) {
    state.hasExport = true;
  },
  [SET_REPORT_AGENT_FILTERS](state, filters) {
    state.filters = filters;
  },
  [SET_REPORT_AGENT_FILTERS_RESULT](state, filters) {
    state.filtersResult = filters;
  },
  [SET_EXPORT_LINK](state, link) {
    state.exportLink = link;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PER_PAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORT_BY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORT_ORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
