<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      fixed-header
      light
      dense
      :height="getvh(56)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items-per-page="itemPerPage"
      item-key="id"
      class="mx-2 mt-0 font-weight-bold"
      item-class="headerZindex"
      hide-default-footer
      @dblclick:row="showDetail"
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> {{ $t("no_data_here") }}</template>
      <template v-slot:no-results>
        {{ $t("no_results_here") }}
      </template>
      <!--    no-data end-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>

          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <!--begin::Button-->
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export')"
            >
              <button
                @click="$refs.exportSidebar.$data.exportSidebar = true"
                class="btn btn--export-filter font-weight-bolder"
              >
                <span class="svg-icon mx-2">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                {{ $t("export") }}
              </button>
            </div>
            <!--end::Button-->
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter font-weight-bolder"
              >
                <span class="svg-icon mx-2">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                {{ $t("filter") }}
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('upload')"
            >
              <button
                class="btn btn--export-filter font-weight-bolder"
                @click.prevent="showUploadModal"
              >
                <span class="svg-icon mx-2">
                  <v-icon size="18">mdi-cloud-upload</v-icon>
                </span>
                {{ $t("import") }}
              </button>
            </div>
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('create')"
            >
              <button
                class="btn btn--export-filter font-weight-bolder"
                @click.prevent="showAddItemModal"
              >
                <span class="svg-icon mx-2">
                  <v-icon size="18">mdi-package-variant</v-icon>
                </span>
                {{ $t("create_return") }}
              </button>
              <AddItem
                :refresher="updateTableContent"
                :pageLoader="pageLoad"
                ref="addItem"
              ></AddItem>
            </div>

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
            >
              <Print
                :selectedItems="selected"
                :types="$store.getters.getRETURNSPrintTypes"
                :refresher="updateTableContent"
                :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
              />
            </div>
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->
        <!-- Filter Sidebar start -->
        <FilterSidebar
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
        <Others ref="otherData"></Others>
      </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>
      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>
      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-secondary show poppins min-w-100px min-w-md-110px"
            >
              {{ $t("actions") }}
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                @click="() => setItemForAction(item)"
                text
                >{{ $t("edit") }}
              </v-btn>
            </div>

            <div v-if="permissionChecker('destroy')">
              <v-btn
                class="w-100 text-left text-danger"
                @click="() => handleCancelOrder(item)"
                text
                >{{ $t("delete") }}
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <EditItem
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal"
      :itemForAction="itemForEdit"
    ></EditItem>

    <QueueUploadModal
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="uploadDoc"
    />
  </div>
</template>

<script>
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  SET_ITEM_FOR_UPDATE,
} from "@/core/services/store/returned.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar";

import EditItem from "@/own/components/fulfillment/returned/EditItem.vue"; //TODO:ReturnManagement: change required
import AddItem from "@/own/components/fulfillment/returned/CreateReturned/CreateItemNew.vue";
import Others from "@/own/components/fulfillment/returned/Others.vue";
import Print from "@/own/components/fulfillment/returned/Print.vue";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";

import Pagination from "@/own/components/pagination/Pagination.vue";

import ApiService from "@/core/services/api.service";

import QueueUploadModal from "@/own/components/Modals/QueueUploadModal";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    QueueUploadModal,
    DatatableDetail,
    Pagination,
    FilterSidebar,
    ComponentSelector,
    ExportSidebar,
    AddItem,
    Others,
    EditItem,
    Print,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    offset: true,
    columnsModal: false,

    // editItemId: null,
    dialog2: false,
    dialog3: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    menu2: false,
    status_filter: null,
  }),
  async beforeMount() {
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    this.$store
      .dispatch(UPDATE_TABLE_DATA, {
        filters: this.$store.getters.getRETURNSTableState.filters,
      })
      .then(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
  },
  beforeDestroy() {
    this.$store.commit(SET_TABLE_FILTER, []);
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getRETURNSTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getRETURNSTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getRETURNSTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getRETURNSTableData.last_page;
    },
    //filters
    filters: function () {
      return this.$store.getters.getRETURNSTableFilters;
    },

    table_permissions: function () {
      if (this.$store.getters.getRETURNSTablePermissions) {
        return this.$store.getters.getRETURNSTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getRETURNSTableProperties;
    },

    itemPerPage: function () {
      return this.$store.getters.getRETURNSTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getRETURNSTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getRETURNSTableState //TODO:ReturnManagement: change required
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getRETURNSTableState //TODO:ReturnManagement: change required
          );
        }
        this.$store.commit(SET_PAGE_LOADING, false);
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        //TODO:ReturnManagement: change required
        if (this.$store.getters.getRETURNSTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store
          .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getRETURNSTableState) //TODO:ReturnManagement: change required
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      },
    },
    headers: function () {
      /**
       * @type {[
       {
       value: string,
       text: string,
       type: string,
       sortable: string,
       exportable: string,
       visible: string,
       mobile_visible: string,
       align: string,
       itemClass: string,
       width: string,
       class: string,
       export_order: integer,
       }
       ]}
       */
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getRETURNSTableHeaders) {
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters.getRETURNSTableHeaders.filter(
                  (col) => col.visible === true && col.mobile_visible === true
                )
              )
            ),
          ];
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters.getRETURNSTableHeaders.filter(
                  (col) => col.visible === true && col.mobile_visible === true
                )
              )
            ),
          ];
        }
      } else {
        if (this.$store.getters.getRETURNSTableHeaders) {
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters.getRETURNSTableHeaders.filter(
                  (col) => col.visible === true
                )
              )
            ),
          ];
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = [
            ...JSON.parse(
              JSON.stringify(
                this.$store.getters.getRETURNSTableHeaders.filter(
                  (col) => col.visible === true
                )
              )
            ),
          ];
        }
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        // header.text = this.$t(
        //   header.text.toLocaleLowerCase().split(" ").join("_")
        // );
        header.text = this.$t(header.text);
        header.width = undefined;
        if (header.value === "aging_day")
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function () {
      try {
        return this.$store.getters.getRETURNSTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch (error) {
        return null;
      }
    },
    types: function () {
      return [
        { name: this.$t("shipping_label"), value: "label" },
        { name: this.$t("barcode"), value: "barcode" },
        { name: this.$t("invoice"), value: "invoice" },
        { name: this.$t("manifest"), value: "manifest" },
        { name: this.$t("packing_summary"), value: "packing_summary" },
        { name: this.$t("packing_slip"), value: "packing_slip" },
      ];
    },

    items: function () {
      let items = [];
      const serverData = this.$store.getters.getRETURNSTableData;
      if (serverData) {
        items = serverData.data;
      }
      return items;
    },
    // extraData() {
    //   return this.$store.getters.getRETURNSExtraData;
    // },
    // editItemIdData() {
    //   return this.editItemId;
    // },
    itemForEdit: function () {
      return this.$store.getters.getRETURNSItemForUpdate;
    },
    // call_statuses() {
    //   return this.$store.getters.getRETURNSCallStatuses;
  },
  methods: {
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoad(true);
        this.dialog3 = false;
        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_orders/cancel`, data)
          .then(() => {
            SwalService.successMessage({
              title: this.$t("updated"),
              html: this.$t("cancel_reason_registered"),
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoad(true);
        this.dialog2 = false;

        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            SwalService.successMessage({
              title: this.$t("updated"),
              html: this.$t("on_hold_reason_registered"),
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getItemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getItemPerPage * this.getpageNumber - this.getItemPerPage;
          innerItems = this.items.slice(startFrom, this.getItemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    // editItem(item) {
    //   // this.$refs.editModal.$props.itemForAction = item;
    //   this.$store.commit(SET_ITEM_FOR_ACTION, item);
    //   this.$refs.editModal.toggleModal();
    // },
    actionReopen(id) {
      this.pageLoad(true);
      let data = { id: id };
      ApiService.post("/fulfillment/orders/reopen", data)
        .then(() => {
          SwalService.successMessage({
            title: this.$t("modified"),
            html: this.$t("order_modified"),
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    // actionCall(item) {
    //   this.$refs.orderCall.$props.item = item;
    //   this.$refs.orderCall.toggleModal();
    // },
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = { ...this.$store.getters.getRETURNSTableState };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);

      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getRETURNSTableState)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    loadDetailData(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: id };
      ApiService.post("/shipping/returns_management/show", data)
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.$refs.otherData.setData(response.data.return_management);
          this.$refs.otherData.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    showAddItemModal() {
      this.$refs.addItem.toggleModal();
    },
    showItemDetail(item) {
      this.loadDetailData(item.id.id);
    },
    showUploadDocModal(item) {
      // this.$refs.uploadDoc.$props.itemForAction = item;
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.uploadDoc.dialog = true;
    },
    handleConfirmOrder(id) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/orders/order_confirm", { id })
        .then(() => {
          SwalService.successMessage({
            title: this.$t("confirmed"),
            html: this.$t("order_has_been_confirmed"),
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    handleCancelOrder(item) {
      SwalService.warningConditionMessage(
        {
          title: this.$t("are_you_sure"),
          html: this.$t("notificationMessage.you_wont_be_able_to_revert_this"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("notificationMessage.yes_delete_it"),
        },
        () => {
          this.pageLoad(true);
          //TODO: change data sending to id
          const data = { id: item.id };
          ApiService.post("/shipping/returns_management/destroy", data)
            .then(() => {
              SwalService.successMessage({
                title: this.$t("deleted"),
                html: this.$t(
                  "notificationTitle.record_has_been_deleted_successfully"
                ),
              });
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      );
    },
    // eslint-disable-next-line no-unused-vars
    showDetail(event, { item }) {
      this.loadDetailData(item.id);
    },
    isAnyFilterSelected() {
      let isSelected = false;
      if (this.$store.getters.getRETURNSTableState.filters.length > 0) {
        isSelected = true;
      }
      return isSelected;
    },
    // exports
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store
          .dispatch(EXPORT_TABLE_DATA, {
            columns: columns,
            filters: this.$store.getters.getRETURNSTableState.filters,
          })
          .then(() => {
            if (this.$store.getters.getRETURNSExportUrl) {
              const link = document.createElement("a");
              link.href = this.$store.getters.getRETURNSExportUrl;
              link.download = "Export.csv";
              link.click();
              link.remove();
            }
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.exportSidebar.$refs.export.toggleModal();
          });
      } else {
        SwalService.successMessage({
          title: this.$t("warning"),
          html: this.$t("please_select_at_least_one_filter"),
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    // exports end
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    removeItem(item) {
      SwalService.warningConditionMessage({
        title: this.$t("are_you_sure"),
        text: this.$t("notificationMessage.you_wont_be_able_to_revert_this"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("notificationMessage.yes_delete_it"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post(`/authorizations/clients/${item.id}/destroy`)
            .then(() => {
              SwalService.successMessage({
                title: this.$t("deleted"),
                html: this.$t("your_file_has_been_deleted"),
              });

              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    toggleColumnsModal() {
      this.columnsModal = !this.columnsModal;
    },

    showUploadModal() {
      // this.$refs.upload.toggleUploadModal();
      this.$router.push({
        name: "settings.uploads",
        query: { type: this.$store.getters.getRETURNSUploadType },
      });
    },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getRETURNSTableState)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.$forceUpdate();
      this.selected = [];
      this.pageLoad(false);
    },

    async findNextOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -1;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index + 1;
        }
      });
      if (parseInt(this.getItemPerPage) == nextIdIndex) {
        // console.log("last item");
        if (this.pageCount > this.getPageNumber) {
          this.setPageNumber(this.getPageNumber + 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGAUDITRETURNSTableState
            )
            .then(() => {
              this.$forceUpdate();
            });
          nextId = this.items[0].id.id;
        }
      } else if (this.items.length - 1 < nextIdIndex) {
        throw TypeError("there is no next order");
      } else {
        try {
          nextId = this.items[nextIdIndex].id.id;
        } catch (e) {
          // console.log(e);
        }
      }
      return nextId;
    },
    async findPrevOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -2;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index - 1;
        }
      });
      if (nextIdIndex < 0) {
        if (this.getPageNumber > 1) {
          this.setPageNumber(this.getPageNumber - 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGAUDITRETURNSTableState
            )
            .then(() => {
              this.$forceUpdate();
              // console.log("on update", this.items[0].id.id);
            });
          nextId = this.items[parseInt(this.getItemPerPage) - 1].id.id;
        }
      } else {
        try {
          nextId = this.items[nextIdIndex].id.id;
        } catch (e) {
          // console.log(e);
          throw TypeError("Can not find previous order");
        }
      }

      return nextId;
    },
    showNextOrder(id) {
      this.$refs.editModal.toggleModal();
      this.findNextOrderId(id)
        .then((data) => {
          if (data) {
            this.editItem(data);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    showPrevOrder(id) {
      this.$refs.editModal.toggleModal();
      this.findPrevOrderId(id)
        .then((data) => {
          if (data) {
            this.editItem(data);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    setItemForAction(item) {
      this.$store.commit(SET_ITEM_FOR_UPDATE, item);
      // this.editItemId = item;
      this.$refs.editModal.toggleModal();
    },
  },
};
</script>
