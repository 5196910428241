<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New ASN</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <v-stepper
          v-model="tab"
          non-linear
          outlined
          rounded="4"
          flat
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              editable
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="">
            <v-stepper-content
              step="0"
              key="Pre-Alert Information"
              class="pt-2"
            >
              <div class="row">
                <div class="col-12 col-sm-6 h-75px" v-if="!isClient">
                  <v-select
                    v-model="pre_alert.customer_id"
                    label="Customer"
                    item-text="text"
                    item-value="index"
                    :items="serverData.clients"
                    clearable
                    outlined
                    dense
                    :error-messages="customer_idErrors"
                    @input="$v.pre_alert.customer_id.$touch()"
                    @blur="$v.pre_alert.customer_id.$touch()"
                  />
                </div>

                <div
                  class="col-12 col-sm-6 h-75px"
                  :class="{ 'col-lg-6 col-md-6': isClient }"
                >
                  <v-autocomplete
                    v-model="pre_alert.warehouse_id"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    clearable
                    outlined
                    dense
                    :error-messages="warehouse_idErrors"
                    @input="$v.pre_alert.warehouse_id.$touch()"
                    @blur="$v.pre_alert.warehouse_id.$touch()"
                  />
                </div>
                <div
                  class="col-12 col-sm-6 h-75px"
                  :class="{ 'col-lg-6 col-md-6': isClient }"
                >
                  <v-text-field
                    v-model="pre_alert.carrier"
                    label="Carrier"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 h-75px">
                  <v-text-field
                    v-model="pre_alert.supplier_reference_code"
                    label="Supplier reference code"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div class="col-12 col-sm-6 h-75px">
                  <v-datetime-picker
                    label="Expected arrival time"
                    :textFieldProps="{
                      outlined: true,

                      dense: true,
                      'prepend-icon': 'mdi-calendar',
                      'error-messages': expected_arrival_timeErrors,
                    }"
                    :datePickerProps="{}"
                    :timePickerProps="{}"
                    v-model="pre_alert.expected_arrival_time"
                    @input="$v.pre_alert.expected_arrival_time.$touch()"
                    @blur="$v.pre_alert.expected_arrival_time.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 h-75px">
                  <v-datetime-picker
                    label="Actual Arrival Time"
                    :textFieldProps="{
                      outlined: true,
                      dense: true,
                      'prepend-icon': 'mdi-calendar',
                    }"
                    :datePickerProps="{}"
                    :timePickerProps="{}"
                    v-model="pre_alert.actual_arrival_time"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.po_number"
                    label="Purchase Order Number"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.vehicle_number"
                    label="Vehicle Number"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.vehicle_type"
                    label="Vehicle Type"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.invoice_number"
                    label="Invoice Number"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.number_of_boxes"
                    label="Number of Boxes "
                    clearable
                    outlined
                    dense
                    type="number"
                    :min="0"
                    @change="
                      () => validateMinValue('number_of_boxes', 0, 'pre_alert')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.shipper_code"
                    label="Shipper code"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.shipper_reference"
                    label="Shipper reference"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.consignee_code"
                    label="Consignee code"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4 h-75px">
                  <v-text-field
                    v-model="pre_alert.consignee_reference"
                    label="Consignee reference"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 h-75px">
                  <v-text-field
                    v-model="pre_alert.note"
                    label="Comment"
                    clearable
                    outlined
                    dense
                  />
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="1" key="ASN Information" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6 h-75px">
                  <v-select
                    v-model="asn_info.asn_type_id"
                    label="ASN type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.asn_types"
                    clearable
                    outlined
                    dense
                    :error-messages="asn_type_idErrors"
                    @input="$v.asn_info.asn_type_id.$touch()"
                    @blur="$v.asn_info.asn_type_id.$touch()"
                  />
                </div>
                <div
                  class="col-12 col-sm-6 h-75px d-flex align-start justify-space-between ma-0 pa-0 px-3"
                >
                  <h4 class="pr-4 pt-5 font-weight-regular">Put on hold</h4>
                  <v-switch
                    v-model="asn_info.release_status"
                    hide-details
                    dense
                  />
                </div>
                <div class="col-12">
                  <v-radio-group
                    label="Receiving type"
                    v-model="asn_info.receiving_type"
                    row
                  >
                    <v-radio
                      v-for="(desti, i) in types"
                      :label="desti.text"
                      :value="desti.index"
                      :key="i"
                    />
                  </v-radio-group>
                </div>
                <div class="col-12">
                  <sku-datatable
                    :setSku="setSkus"
                    :skus="formData.skus"
                    :skuItem="serverData.skus"
                    :carriers="serverData.carriers"
                    :clientId="userId"
                    :customer="getCustomerData"
                    :receiving_type="asn_info.receiving_type"
                  />
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <!--begin::Body-->

        <!--end::Body-->
        <div class="d-flex align-center modal-action flex-wrap pt-4">
          <div class="justify-content-between align-center d-flex col-12">
            <v-btn
              elevation="0"
              color="primary"
              dark
              class="order-1 order-sm-1"
              :class="{ invisible: !backState }"
              @click="nextStep('prev')"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
            <div
              class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
              :class="{ invisible: !backState }"
            >
              <button
                type="submit"
                class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                @click="resetCreateForm"
              >
                Clear
              </button>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submitCreateForm"
              >
                Submit
              </button>
            </div>
            <v-btn
              elevation="0"
              class="order-3 order-sm-3"
              color="primary"
              dark
              :class="{ invisible: !nextState }"
              @click="nextStep('next')"
              ><v-icon>mdi-arrow-right</v-icon></v-btn
            >
          </div>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/fulfillment/asn/skuContainer/SkuDatatable";
import ApiService from "@/core/services/api.service";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  mixins: [validationMixin, fieldValueValidation],
  validations() {
    return {
      pre_alert: {
        warehouse_id: { required },
        expected_arrival_time: { required },
        customer_id: {
          required: requiredIf(function() {
            return !this.isClient;
          }),
        },
      },
      asn_info: {
        asn_type_id: { required },
      },
    };
  },
  components: { SkuDatatable },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    updateFlag: false,
    dialog: false,
    serverData: null,
    menu2: false,
    formData: {
      skus: [],
    },
    types: [
      { text: "By Scanning each", index: 1 },
      { text: "Bulk", index: 2 },
      { text: "Carton", index: 3 },
    ],
    pre_alert: {
      customer_id: null,
      warehouse_id: null,
      carrier: null,
      supplier_reference_code: null,
      expected_arrival_time: null,
      po_number: null, // Purchase Order Number
      vehicle_number: null,
      vehicle_type: null,
      invoice_number: null,
      number_of_boxes: null,
      shipper_code: null,
      shipper_reference: null,
      consignee_code: null,
      consignee_reference: null,
      actual_arrival_time: null,
      note: null,
    },
    asn_info: {
      asn_type_id: null,
      receiving_type: 3,
      release_status: true, // put on hold
    },
    tab: 0,
    categories: [
      {
        name: "Pre-Alert Information",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`pre_alert`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "ASN Information",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`asn_info`].$invalid;
          } catch {
            return true;
          }
        },
      },
    ],
  }),

  methods: {
    nextStep(type) {
      this.updateValidationStateOfSteps();
      if (type == "next") {
        if (this.tab < 1) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    dateFormatter(date) {
      if (!Object.prototype.toString.call(date) === "[object Date]") {
        return date;
      }
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    updateValidationStateOfSteps() {
      // console.log(`working`);
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
        // console.log(cat);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/fulfillment/asn/create")
        .then((response) => {
          this.serverData = response.data;
          this.updateValidationStateOfSteps();
          this.resetEditForm();
        })
        .catch(() => {})
        .finally(() => {
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetCreateForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.updateValidationStateOfSteps();
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      // if (this.formData.skus.length === 0) {
      //   Swal.fire({
      //     title: "Warning",
      //     html: `<div>At least one sku is required!</div>`,
      //     icon: "warning",
      //     showConfirmButton: false,
      //     timer: 2500,
      //   });
      //   return;
      // }
      this.pageLoader(true);
      let data = JSON.parse(
        JSON.stringify({
          ...this.formData,
          ...this.pre_alert,
          ...this.asn_info,
        })
      );
      if (data.expected_arrival_time && data.expected_arrival_time !== "") {
        data.expected_arrival_time = this.dateFormatter(
          this.pre_alert.expected_arrival_time
        );
      }

      if (data.actual_arrival_time && data.actual_arrival_time !== "") {
        data.actual_arrival_time = this.dateFormatter(
          this.pre_alert.actual_arrival_time
        );
      }

      ApiService.post("/fulfillment/asn/store", data)
        .then(() => {
          swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        skus: [],
      };
      this.pre_alert = {
        customer_id: null,
        warehouse_id: null,
        carrier: null,
        supplier_reference_code: null,
        expected_arrival_time: null,
        actual_arrival_time: null,
        po_number: null, // Purchase Order Number
        vehicle_number: null,
        vehicle_type: null,
        invoice_number: null,
        number_of_boxes: null,
        shipper_code: null,
        shipper_reference: null,
        consignee_code: null,
        consignee_reference: null,
        note: null,
      };
      this.asn_info = {
        asn_type_id: null,
        receiving_type: 3,
        release_status: true, // put on hold
      };
      await this.resetValidationStateOfSteps();
      this.tab = 0;
    },
  },
  mounted() {
    this.pre_alert.warehouse_id = this.selectedWarehouse;
  },
  computed: {
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      return this.tab !== 1;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    userId: function() {
      return this.isClient ? false : this.pre_alert.customer_id;
    },
    getCustomerData() {
      return this.pre_alert.customer_id ? this.pre_alert.customer_id : false;
    },
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this, "pre_alert");
    },

    asn_type_idErrors() {
      return this.handleFormValidation("asn_type_id", this, "asn_info");
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this, "pre_alert");
    },
    expected_arrival_timeErrors() {
      return this.handleFormValidation(
        "expected_arrival_time",
        this,
        "pre_alert"
      );
    },
    selectedWarehouse: function() {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    "asn_info.receiving_type": {
      handler(newValue, oldValue) {
        if (this.formData.skus.length == 0) {
          return;
        }
        if (this.updateFlag) {
          this.updateFlag = false;
          return;
        }
        Swal.fire({
          title: "Are you sure?",
          text: `After changing reviving type you need to recheck item details!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "NO",
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          } else {
            this.updateFlag = true;
            this.asn_info.receiving_type = oldValue;
          }
        });
      },
      deep: true,
    },
  },
};
</script>
