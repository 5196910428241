<template>
  <div
    class="ma-0 row-bg-color custom-container-height"
    v-if="$store.getters.getWMSSHIPPINGTableProperties"
  >
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
      <div class="row justify-content-between mx-2 mx-lg-0">
        <div class="col-auto col-lg-4">
          <datatable-detail
            class="col-9 col-md-9 col-sm-9"
            :table_properties="$store.getters.getWMSSHIPPINGTableProperties"
          />
        </div>
        <div class="col-auto d-flex justify-content-end mr-3 mt-5 p-0">
          <div>
            <button
              class="btn btn--export-filter"
              @click="$refs.filterSidebar.$data.filterSidebar = true"
            >
              <span class="svg-icon">
                <v-icon size="18">mdi-filter</v-icon>
              </span>
              Filter
            </button>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-flex justify-content-between align-items-center">
        <div
          class="min-w-250px max-w-500px pl-4 mt-8 py-0 font-weight-medium w-100 text-capitalize col-sm-12 col-12 col-md-8"
        >
          <v-text-field
            v-model="order_details_no"
            label="Scan Order Delivery No."
            type="text"
            autofocus
            outlined
            clearable
            class="poppins"
            append-icon="mdi-barcode-scan"
            @click:append="handleOrderScan"
            @keyup.enter="handleOrderScan"
            :disabled="!selectedWarehouse"
          />
        </div>
        <div class="d-flex justify-content-center">
          <div class="px-1">
            <button
              @click.prevent="handleDeliveryByBox"
              class="btn btn--export-filter"
            >
              Delivery by box
            </button>
          </div>
          <div class="px-1">
            <button
              @click.prevent="handleShipByBox"
              class="btn btn--export-filter"
            >
              Ship by box
            </button>
          </div>
          <div class="px-1">
            <button
              @click.prevent="handleShipAction"
              class="btn btn--export-filter"
            >
              Ship
            </button>
          </div>
          <div class="px-1">
            <button
              @click.prevent="handlePalletizeAction"
              class="btn btn--export-filter"
            >
              Palletize
            </button>
          </div>
        </div>
      </div>

      <DataTable
        class="px-2"
        ref="datatable"
        v-if="selectedWarehouse"
        :updateTableContent="updateTableContent"
        :toggleConfirmShippingModal="toggleConfirmShippingModal"
      />
      <ConfirmShippingModal
        ref="confirm_shipping"
        :confirmShipping="confirmShipping"
        :processingUrl="processingUrl"
      />
      <!-- Filter Sidebar start -->
      <FilterSidebar
        v-if="filters.length > 0"
        ref="filterSidebar"
        :submitFilterValues="submitFilterValues"
        :resetFilterValues="resetFilterValues"
        :filters="filters"
        :setTableFiltersValues="setTableFiltersValues"
      />
      <!--end::Filter Sidebar-->
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import DataTable from "@/own/components/warehouseManagement/shipping/DataTable.vue";
import ConfirmShippingModal from "@/own/components/warehouseManagement/shipping/ConfirmShippingModal.vue";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  UPDATE_CONFIRM_ORDER_DATA,
} from "@/core/services/store/shipping_wms.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";

export default {
  name: "Shippping",
  components: {
    DatatableDetail,
    DataTable,
    ConfirmShippingModal,
    FilterSidebar,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
    },
    store_names: {
      getTableFilters: "getWMSSHIPPINGTableFilters",
      getTableState: "getWMSSHIPPINGTableState",
      getTableData: "getWMSSHIPPINGTableData",
      getTableHeaders: "getWMSSHIPPINGTableHeaders",
      getTableProperties: "getWMSSHIPPINGTableProperties",
      getTableExportUrl: "getWMSSHIPPINGExportUrl",
      getTablePermissions: "getWMSSHIPPINGTablePermissions",
    },
    order_details_no: null,
    processingUrl: null,
    box_no: null,
  }),
  methods: {
    handleShipAction() {
      this.$refs.datatable.actionShipping();
    },
    handlePalletizeAction() {
      this.$refs.datatable.actionPalletize();
    },
    handleDeliveryByBox() {
      this.$refs.datatable.actionDeliveryByBox();
    },
    handleShipByBox() {
      this.$refs.datatable.actionShipByBox();
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store
          .dispatch(this.table_state.UPDATE_TABLE_DATA, {
            warehouse_id: this.selectedWarehouse,
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    handleOrderScan() {
      this.toggleConfirmShippingModal({
        order_delivery_number: this.order_details_no,
      });
    },
    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getWMSSHIPPINGTableState,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        })
        .then(() => {})
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleConfirmShippingModal(item, url = false, box_no = null) {
      if (!url) {
        this.$store.dispatch(UPDATE_CONFIRM_ORDER_DATA, {
          warehouse_id: this.selectedWarehouse,
          order_delivery_number: item.order_delivery_number,
        });
      } else {
        this.processingUrl = url;
        this.box_no = box_no;
      }

      this.$refs.confirm_shipping.toggleModal();
    },
    confirmShipping(url = null) {
      if (url) {
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post(url, {
          warehouse_id: this.selectedWarehouse,
          box_no: this.box_no,
        })
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
            Swal.fire({
              title: "Update",
              text: `Shiping has been created`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.updateTableContent();
            this.order_details_no = null;
            this.processingUrl = null;
            this.box_no = null;
            this.$refs.confirm_shipping.toggleModal();
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        if (
          this.$store.getters.getWMSSHIPPINGConfirmOrderData.order
            .order_status === "Partially Packed"
        ) {
          Swal.fire({
            title: "Warning",
            text: "Order partially packed. Do you want to continue ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#8950FC",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.commit(SET_PAGE_LOADING, true);
              const data = {
                warehouse_id: this.selectedWarehouse,
                orders: [
                  {
                    order_delivery_number:
                      this.$store.getters.getWMSSHIPPINGConfirmOrderData.order
                        .order_delivery_number,
                  },
                ],
              };

              ApiService.post("/warehouse_management/shipping/update", data)
                .then(() => {
                  Swal.fire({
                    title: "Update",
                    text: `Shiping has been created`,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500,
                  });
                  this.updateTableContent();
                  this.order_details_no = null;
                  this.processingUrl = null;
                  this.$refs.confirm_shipping.toggleModal();
                })
                .finally(() => {
                  this.$store.commit(SET_PAGE_LOADING, false);
                });
            }
          });
        } else {
          this.$store.commit(SET_PAGE_LOADING, true);
          const data = {
            warehouse_id: this.selectedWarehouse,
            orders: [
              {
                order_delivery_number:
                  this.$store.getters.getWMSSHIPPINGConfirmOrderData.order
                    .order_delivery_number,
              },
            ],
          };

          ApiService.post("/warehouse_management/shipping/update", data)
            .then(() => {
              Swal.fire({
                title: "Update",
                text: `Shiping has been created`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.updateTableContent();
              this.order_details_no = null;
              this.processingUrl = null;
              this.$refs.confirm_shipping.toggleModal();
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        }
      }
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getWMSSHIPPINGTableState,
        warehouse_id: this.selectedWarehouse,
      };
      // console.log("state", state);
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      // this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store
        .dispatch(
          UPDATE_TABLE_DATA,
          this.$store.getters.getWMSSHIPPINGTableState
        )
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    setTableFiltersValues(val) {
      // console.log("setting value to filter -->", val);
      this.$store.commit(SET_TABLE_FILTER, val);
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
    filters: function () {
      let filters = null;
      try {
        filters = this.$store.getters.getWMSSHIPPINGTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
  },
  beforeMount() {
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>

<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
