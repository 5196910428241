<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
      transition="dialog-bottom-transition"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Manual Putaway</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column h-550px scroll-y mx-5 py-7">
          <!--begin::Body-->
          <div>
            <div>
              <v-row>
                <v-col cols="12" class="d-flex justify-content-center">
                  <b-button
                    class="mr-5"
                    @click="toggleScannerModal('manual_single')"
                    variant="-export-filter"
                    >Scan
                  </b-button>
                  <b-button
                    class="mr-5"
                    @click="toggleScannerModal('manual_bulk')"
                    variant="-export-filter"
                    >Bulk scan</b-button
                  >
                  <b-button
                    @click="toggleScannerModal('tote')"
                    variant="-export-filter"
                    >Tote scan</b-button
                  >
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="putawayTableData"
                    item-key="id"
                    class="my-6 pl-4 pr-8"
                    hide-default-footer
                    mobile-breakpoint="300"
                    :item-class="secondText"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </div>
          <!--end::Body-->
          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn--export-filter px-5 py-3 mx-8"
              @click="toggleModal"
            >
              Close
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
    <ManualScannerModal ref="manual_scanner" />
    <ScanToteModal ref="scan_tote" />
  </div>
</template>

<script>
import {
  SET_SCANNING_ACTION_TYPE,
  SET_PUTAWAY_MODAL_EMPTY,
} from "@/core/services/store/putawayTasks.module";
import ManualScannerModal from "@/own/components/warehouseManagement/putawayTasks/ManualScannerModal.vue";
import ScanToteModal from "@/own/components/warehouseManagement/putawayTasks/ScanToteModal";

export default {
  name: "ManualPutaway",
  props: ["refresher"],
  components: {
    ManualScannerModal,
    ScanToteModal,
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Tote",
        align: "start",
        sortable: false,
        value: "tote_id",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "SKU",
        align: "start",
        sortable: false,
        value: "sku",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Quantity",
        align: "start",
        sortable: false,
        value: "qty",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Location",
        align: "start",
        sortable: false,
        value: "location",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
    ],
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.$store.commit(SET_PUTAWAY_MODAL_EMPTY);
        this.refresher();
      }
      this.dialog = !this.dialog;
    },
    toggleScannerModal(type) {
      this.$store.commit(SET_SCANNING_ACTION_TYPE, type);
      if (type === "tote") {
        this.$refs.scan_tote.toggleModal();
        return;
      }
      this.$refs.manual_scanner.toggleModal();
    },
    secondText() {
      return "second-text";
    },
  },
  computed: {
    putawayTableData() {
      return this.$store.getters.getPUTAWAYModalTableData;
    },
  },
  mounted() {},
};
</script>
