<template>
  <div class="d-inline">
    <v-dialog v-model="dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <button
          @click="toggleModal"
          class="btn iq-btn px-3 px-sm-4 mx-3 py-2 poppins-ls"
        >
          {{ $t("generate") }}
        </button>
      </template>

      <v-card>
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Generate report</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <v-card-text class="pt-4">
          <div class="p-10 pb-0">
            <v-text-field
              v-model="formData.email"
              :label="$t('email')"
              clearable
              outlined
              dense
              :error-messages="emailErrors"
              @input="$v.formData.email.$touch()"
              @blur="$v.formData.email.$touch()"
            ></v-text-field>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <button
            @click="submit"
            class="btn iq-btn px-3 px-sm-4 mx-3 mb-5 py-2 poppins-ls"
          >
            {{ $t("submit") }}
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import validationMixin from "@/own/mixins/validationMixin.js";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "GenerateEmail",
  mixins: [validationMixin],
  props: ["filters"],
  validations: {
    formData: {
      email: { required, email },
    },
  },
  data() {
    return {
      dialog: false,
      formData: { email: null },
    };
  },
  methods: {
    toggleModal() {
      if (!this.dialog) {
        if (!this.filters.length) {
          Swal.fire({
            title: this.$t("warning"),
            text: this.$t("please_select_at_least_one_filter"),
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
          return;
        }
        this.clear();
      }
      this.dialog = !this.dialog;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit("generateEmail", this.formData.email);
    },
    clear() {
      this.$v.$reset();
      this.formData = { email: null };
    },
  },
  computed: {
    emailErrors: function () {
      return this.handleFormValidation("email", this);
    },
  },
};
</script>
