<template>
  <b-sidebar
    bg-variant="white"
    :width="$vuetify.breakpoint.xsOnly ? '320px' : '420px'"
    right
    backdrop
    shadow
    z-index="2222"
    v-model="filterSidebar"
  >
    <template v-slot:header>
      <div class="w-100 d-flex align-items-center justify-content-between pa-5">
        <h3 class="font-weight-bolder m-0 font-size-h3 poppins iq-color ls1">
          {{ $t("filter") }}
        </h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="filterSidebar = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
    </template>
    <v-card flat color="white">
      <v-card-text class="pa-0">
        <FilterContainer
          ref="filter"
          :key="2"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :buttonTitle="$t('apply_filters')"
          :setTableFiltersValues="setTableFiltersValues"
          @submit="filterSidebar = false"
        ></FilterContainer>
      </v-card-text>
    </v-card>
  </b-sidebar>
</template>

<script>
import FilterContainer from "@/own/components/filter/FilterContainer.vue";

export default {
  name: "FilterSidebar",
  components: {
    FilterContainer,
  },
  props: [
    "submitFilterValues",
    "resetFilterValues",
    "filters",
    "setTableFiltersValues",
  ],
  data: () => ({
    filterSidebar: false,
  }),
  watch: {
    filterSidebar: function (newValue) {
      newValue === true
        ? document.body.classList.add("overflow-hidden")
        : document.body.classList.remove("overflow-hidden");
    },
  },
};
</script>
