<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon svg-icon-5">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="w-100">
          <div class="row">
            <div
              class="h-75px my-0 py-0 col-12 col-sm-6"
              v-if="serverData?.organizations"
            >
              <v-select
                v-model="formData.organization_id"
                label="Organization"
                item-text="text"
                item-value="index"
                :items="serverData.organizations"
                clearable
                outlined
                dense
                required
                :error-messages="organization_idErrors"
                @input="$v.formData.organization_id.$touch()"
                @blur="$v.formData.organization_id.$touch()"
              ></v-select>
            </div>
            <div class="h-75px my-0 py-0 col-12 col-sm-6">
              <v-select
                v-model="formData.category_id"
                label="Category"
                item-text="text"
                item-value="index"
                :items="serverData.categories"
                clearable
                outlined
                dense
                required
                :error-messages="category_idErrors"
                @input="$v.formData.category_id.$touch()"
                @blur="$v.formData.category_id.$touch()"
              ></v-select>
            </div>
            <div class="h-75px my-0 py-0 col-12 col-sm-6">
              <v-select
                v-model="formData.size_id"
                label="Size"
                item-text="text"
                item-value="index"
                :items="serverData.sizes"
                clearable
                outlined
                dense
                required
                @blur="$v.formData.size_id.$touch()"
              ></v-select>
            </div>
            <div class="h-75px my-0 py-0 col-12 col-sm-6">
              <v-select
                v-model="formData.type_id"
                label="Type"
                item-text="text"
                item-value="index"
                :items="serverData.types"
                clearable
                outlined
                dense
                hide-details
                required
                :error-messages="type_idErrors"
                @input="$v.formData.type_id.$touch()"
                @blur="$v.formData.type_id.$touch()"
              ></v-select>
            </div>

            <div class="h-75px my-0 py-0 col-12 col-sm-6">
              <v-text-field
                v-model="formData.vehicle_number"
                label="Vehicle number"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px my-0 py-0 col-12 col-sm-6" v-if="isContainer">
              <v-text-field
                v-model="formData.serial_number"
                label="Seal number"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px my-0 py-0 col-12 col-sm-6">
              <v-text-field
                v-model="formData.driver_number"
                label="Driver name"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="h-75px my-0 py-0 col-12 col-sm-6">
              <v-text-field
                v-model="formData.mobile_number"
                label="Mobile number"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        type_id: { required },
        organization_id: {
          required: requiredIf(function () {
            return this.serverData?.organizations;
          }),
        },
        category_id: { required },
      },
    };
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      organization_id: null,
      category_id: null,
      type_id: null,
      size_id: null,
      vehicle_number: null,
      serial_number: null,
      driver_number: null,
      mobile_number: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/organization/dispatchVehicle/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },

    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/organization/dispatchVehicle/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `The record is created!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        organization_id: null,
        category_id: null,
        type_id: null,
        size_id: null,
        vehicle_number: null,
        serial_number: null,
        driver_number: null,
        mobile_number: null,
      };
    },
  },
  computed: {
    isContainer() {
      return this.serverData?.categories.find(
        (cat) => cat.index == this.formData.category_id
      )?.text == "Container"
        ? true
        : false;
    },
    isOrganization() {
      return this.formData.type_id === 1;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    type_idErrors: function () {
      return this.handleFormValidation("type_id", this);
    },
    category_idErrors: function () {
      return this.handleFormValidation("category_id", this);
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
  },
};
</script>
