<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Ship by box</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model="formData.box_no"
            label="Scan box number"
            clearable
            outlined
            append-icon="mdi-barcode-scan"
            :error-messages="box_noErrors"
            @input="$v.formData.box_no.$touch()"
            @blur="$v.formData.box_no.$touch()"
            @keydown.enter.prevent="submitCreateForm"
          ></v-text-field>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button class="btn btn-info px-5 py-3 ls1" @click="submitCreateForm">
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_CONFIRM_ORDER_DATA } from "@/core/services/store/shipping_wms.module";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      box_no: { required },
    },
  },
  name: "ShipByBox",
  props: ["items", "refresher", "confirmation"],
  data: () => ({
    dialog: false,
    formData: {
      box_no: null,
    },
  }),
  beforeMount() {},
  methods: {
    pageLoader(state = false) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },

    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      } else {
        // this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        let data = { warehouse_id: this.selectedWarehouse, ...this.formData };
        ApiService.post("/warehouse_management/shipping/show_by_box_no", data)
          .then(({ data }) => {
            this.$store.commit(SET_CONFIRM_ORDER_DATA, data);
            // this.refresher();
            this.confirmation(
              null,
              "/warehouse_management/shipping/ship_by_box",
              this.formData.box_no
            );
            this.pageLoader(false);
            this.resetCreateForm();
            this.toggleModal();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        box_no: null,
      };
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },

    box_noErrors: function () {
      return this.handleFormValidation("box_no", this);
    },
  },
};
</script>
