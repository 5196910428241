<template>
  <div v-if="isDataLoadedFromServer">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Edit Record</h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
          <!--begin::Body-->
          <div class="row">
            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                v-model="formData.name"
                label="Name"
                clearable
                outlined
                dense
                :error-messages="nameErrors"
                @input="$v.formData.name.$touch()"
                @blur="$v.formData.name.$touch()"
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-autocomplete
                v-model="formData.timezone"
                label="Timezone"
                item-text="text"
                item-value="index"
                :items="serverData.timezones"
                clearable
                outlined
                dense
                hide-details
              />
            </div>

            <div class="h-75px col-12 col-sm-6">
              <v-autocomplete
                v-model="formData.currency_id"
                label="Currency"
                item-text="text"
                item-value="index"
                :items="serverData.currencies"
                clearable
                outlined
                dense
                hide-details
                :error-messages="currency_idErrors"
                @input="$v.formData.currency_id.$touch()"
                @blur="$v.formData.currency_id.$touch()"
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-autocomplete
                v-model="formData.divisions"
                label="Divisions"
                item-text="text"
                item-value="index"
                :items="serverData.divisions"
                multiple
                clearable
                outlined
                dense
                hide-details
                :error-messages="divisionsErrors"
                @input="$v.formData.divisions.$touch()"
                @blur="$v.formData.divisions.$touch()"
              />
            </div>

            <div class="h-75px col-12">
              <v-autocomplete
                v-model="formData.uom_and_package_types"
                label="UOM and package types"
                item-text="text"
                item-value="index"
                :items="serverData.uom_and_package_types"
                multiple
                clearable
                outlined
                dense
                hide-details
                :error-messages="uom_and_package_typesErrors"
                @input="$v.formData.uom_and_package_types.$touch()"
                @blur="$v.formData.uom_and_package_types.$touch()"
              />
            </div>

            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                v-model="formData.trn"
                label="TRN"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-file-input
                dense
                outlined
                v-model="formData.shipping_label_logo"
                multiple
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                label="Shipping label logo"
                clearable
              />
            </div>
            <div class="h-75px col-12">
              <v-text-field
                v-model="formData.description"
                label="Description"
                clearable
                outlined
                dense
              />
            </div>
          </div>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required } from "vuelidate/lib/validators";
import SwalService from "@/core/services/swal.service";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      divisions: { required },
      currency_id: { required },
      uom_and_package_types: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      name: null,
      timezone: null,
      trn: null,
      description: null,
      shipping_label_logo: null,
      divisions: null,
      currency_id: null,
      uom_and_package_types: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/organization/organizations/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.organization;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] &&
            item[0] != "image" &&
            item[0] != "photo" &&
            item[0] != "shipping_label_logo") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else if (
            typeof item[1] === "object" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], JSON.stringify(item[1]));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (
          item[1] &&
          (item[0] == "image" ||
            item[0] == "photo" ||
            item[0] == "shipping_label_logo")
        ) {
          // console.log(item[1], item[0], item[1][0]);
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        ApiService.post(
          "/organization/organizations/update",
          this.convertToFormData(this.formData)
        )
          .then(() => {
            SwalService.successMessage({
              html: SwalService.messages.updated(),
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        id: null,
        name: null,
        timezone: null,
        trn: null,
        description: null,
        shipping_label_logo: null,
        divisions: null,
        currency_id: null,
        uom_and_package_types: null,
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    divisionsErrors() {
      return this.handleFormValidation("divisions", this);
    },
    currency_idErrors() {
      return this.handleFormValidation("currency_id", this);
    },
    uom_and_package_typesErrors() {
      return this.handleFormValidation("uom_and_package_types", this);
    },
  },
};
</script>
