<template v-if="isDataLoaded">
  <div class="p-4">
    <dashboard-layout
      :pageTitle="pageTitle"
      :lineChart="true"
      :additionalIfo="false"
    ></dashboard-layout>
  </div>
</template>

<script>
import DashboardLayout from "@/own/components/dashboard/DashboardLayoutNew.vue";
import { SET_DASHBOARD_URL } from "@/core/services/store/warehouseDashboard.module";

export default {
  components: { DashboardLayout },
  name: "Delivery",
  data() {
    return {
      pageTitle: {
        header: "My Orders",
        subHeader: "Orders Dashboard",
      },
    };
  },
  computed: {
    isDataLoaded: function () {
      return (
        this.$store.getters.getDELIVERYTableData.length > 0 &&
        this.$store.getters.getDELIVERYFilter.length > 0
      );
    },
  },
  created() {
    this.$store.commit(SET_DASHBOARD_URL, "/dashboards/dashboardDelivery");
  },
};
</script>
