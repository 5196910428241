<template>
  <div v-if="value" class="d-flex align-center align-content-left w-auto py-1">
    <span
      class="symbol symbol-35 symbol-light-success overflow-hidden"
      :class="{ 'ml-3': $vuetify.rtl, 'mr-3': !$vuetify.rtl }"
    >
      <img
        style="border-radius: 50% !important"
        v-if="value.photo.url"
        alt="photo"
        :src="value.photo.url"
      />
      <span
        v-else
        style="border-radius: 50% !important"
        class="symbol-label font-size-h5"
        :class="getRandomColors()"
      >
        {{ value.name[0].toUpperCase() }}
      </span>
    </span>

    <div class="name-main">
      <span class="first-text ml-3 text-left" style="font-size: 12px">
        {{ value.name }}
      </span>
      <span class="first-text ml-3 text-left" style="font-size: 12px">
        {{ value.email }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NamePhotoEmail",
  props: ["value"],
  data: () => ({
    bgColors: [
      "bg-light-success text-success",
      "bg-light-danger text-danger",
      "bg-light-info text-info",
      "bg-light-primary text-primary",
      "bg-light-warning text-warning",
      "bg-light-dark text-dark",
    ],
  }),
  methods: {
    getRandomColors: function () {
      return this.bgColors[Math.floor(Math.random() * this.bgColors.length)];
    },
  },
};
</script>

<style scoped>
.name-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
</style>
