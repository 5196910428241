<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <div class="bg-white poppins">
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">{{ $t("box") }}</h3>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form class="pt-4 row">
            <!-- <div class="col-12">
              <v-autocomplete
                v-model="formData.skuId"
                label="SKU"
                item-text="systemCode"
                item-value="skuId"
                clearable
                outlined
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                @change="updateSystemCode"
                :error-messages="skuIdErrors"
                @input="$v.formData.skuId.$touch()"
                @blur="$v.formData.skuId.$touch()"
                dense
              ></v-autocomplete>
            </div> -->
            <!-- <div class="col-12 col-sm-4">
              <v-text-field
                v-model="formData.quantity"
                label="Quantity"
                type="Number"
                clearable
                outlined
                dense
                :error-messages="quantityErrors"
                @input="$v.formData.quantity.$touch()"
                @blur="$v.formData.quantity.$touch()"
              ></v-text-field>
            </div> -->
            <div class="col-12 col-sm-6">
              <v-autocomplete
                v-model="formData.category"
                :label="$t('category')"
                clearable
                item-text="text"
                item-value="text"
                outlined
                :items="categories"
                :error-messages="categoryErrors"
                @input="$v.formData.category.$touch()"
                @blur="$v.formData.category.$touch()"
                dense
              ></v-autocomplete>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.description"
                :label="$t('description')"
                clearable
                outlined
                dense
                :error-messages="descriptionErrors"
                @input="$v.formData.description.$touch()"
                @blur="$v.formData.description.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.price"
                :label="$t('price')"
                type="Number"
                clearable
                outlined
                dense
                min="1"
                @change="
                  (value) => {
                    if (value < 1) {
                      formData.price = 1;
                    }
                  }
                "
                :error-messages="priceErrors"
                @input="$v.formData.price.$touch()"
                @blur="$v.formData.price.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.weight"
                :label="$t('weight')"
                type="Number"
                clearable
                outlined
                dense
                max="30"
                min="1"
                hint="Enter value greater then 0 and less then 31"
                @change="updateWeight"
                @keyup="updateWeight"
                :error-messages="weightErrors"
                @input="$v.formData.weight.$touch()"
                @blur="$v.formData.weight.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-4">
              <v-text-field
                class
                v-model="formData.length"
                :label="$t('length')"
                clearable
                outlined
                type="number"
                @change="() => validateMinValue('length', 0, 'formData')"
                dense
                min="0"
                :error-messages="lengthErrors"
                @input="$v.formData.length.$touch()"
                @blur="$v.formData.length.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-4">
              <v-text-field
                class
                v-model="formData.width"
                :label="$t('width')"
                clearable
                outlined
                type="number"
                @change="() => validateMinValue('width', 0, 'formData')"
                dense
                min="0"
                :error-messages="widthErrors"
                @input="$v.formData.width.$touch()"
                @blur="$v.formData.width.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-4">
              <v-text-field
                class
                v-model="formData.height"
                :label="$t('height')"
                clearable
                outlined
                type="number"
                @change="() => validateMinValue('height', 0, 'formData')"
                dense
                min="0"
                :error-messages="heightErrors"
                @input="$v.formData.height.$touch()"
                @blur="$v.formData.height.$touch()"
              ></v-text-field>
            </div>
            <!-- <div class="col-12 col-sm-4">
              <v-text-field
                v-model="formData.old_delivery_number"
                :label="$t('old_awb')"
                clearable
                outlined
                dense
              ></v-text-field>
            </div> -->
          </form>
          <!--end::Body-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
            <button
              type="reset"
              class="btn btn-light mx-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              {{ $t("reset") }}
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              {{ $t("submit") }}
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, maxValue, minValue } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "EditSku",
  mixins: [validationMixin, fieldValueValidation],
  validations() {
    return {
      formData: {
        // skuId: { required },
        // quantity: { required },
        price: { required },
        weight: { required, maxValue: maxValue(30), minValue: minValue(1) },
        description: { required },
        length: { required },
        width: { required },
        height: { required },
        category: { required },
      },
    };
  },
  props: [
    "item",
    "actionFunction",
    "skuItem",
    "oldIndex",
    "clientId",
    "categories",
  ],
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    count: null,
    entries: [],
    weightRule: [
      (v) => (v && v >= 1) || this.$t("weight_should_be_above_0"),
      (v) => (v && v <= 30) || this.$t("weight_should_not_be_above_30"),
    ],
    formData: {
      // skuId: null,
      // systemCode: null,
      weight: 1,
      // quantity: null,
      old_delivery_number: null,
      price: 1,
      description: null,
      length: 0,
      width: 0,
      height: 0,
      category: null,
    },
  }),
  methods: {
    updateWeight(newValue) {
      if (newValue > 30) {
        this.formData.weight = 30;
      } else if (newValue < 1) {
        this.formData.weight = 1;
      }
    },
    updateSystemCode() {
      let data = false;
      this.items.forEach((item) => {
        if (item.skuId == this.formData.skuId) {
          data = item;
        }
      });
      for (const [key, value] of Object.entries(data)) {
        this.formData[`${key}`] = value;
      }
      this.formData.code = data.systemCode;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let copy = { ...this.formData };
        this.actionFunction(copy, this.oldIndex);
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.entries = this.skuItem;
      if (this.item) {
        this.getData(this.item.systemCode);
        let copy = { ...this.item };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.item).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });
        });
      } else {
        this.formData = {
          // skuId: null,
          // systemCode: null,
          weight: 1,
          // quantity: null,
          price: 1,
          description: null,
          old_delivery_number: null,
          length: 1,
          width: 1,
          height: 1,
          category: null,
        };
      }
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
    },
    async getData(value) {
      let data = {};
      if (this.clientId) {
        data = JSON.stringify({ q: value, client: this.clientId });
      } else {
        data = JSON.stringify({ q: value });
      }
      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    // skuIdErrors: function () {
    //   return this.handleFormValidation("skuId", this);
    // },
    // quantityErrors: function () {
    //   return this.handleFormValidation("quantity", this);
    // },
    descriptionErrors: function () {
      return this.handleFormValidation("description", this);
    },
    priceErrors: function () {
      return this.handleFormValidation("price", this);
    },
    weightErrors: function () {
      return this.handleFormValidation("weight", this);
    },
    lengthErrors: function () {
      return this.handleFormValidation("length", this);
    },
    widthErrors: function () {
      return this.handleFormValidation("width", this);
    },
    heightErrors: function () {
      return this.handleFormValidation("height", this);
    },
    categoryErrors: function () {
      return this.handleFormValidation("category", this);
    },
  },
  // watch: {
  //   search(val) {
  //     this.isLoading = true;

  //     this.getData(val);
  //   },
  // },
};
</script>
