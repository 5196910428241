<template>
  <div
    class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
    :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
  >
    <button @click="createQualityCheck" class="btn btn--export-filter ml-2">
      Create
    </button>
    <CreateQualityCheckModal
      ref="createQualityCheckModal"
      :page-loading="pageLoading"
    />
  </div>
</template>

<script>
import CreateQualityCheckModal from "@/own/components/warehouseManagement/standardQualityCheck/CreateQualityCheckModal.vue";

export default {
  name: "StandardQualityCheckActions",
  props: ["pageLoading", "refresher"],
  components: { CreateQualityCheckModal },

  methods: {
    createQualityCheck() {
      this.$refs.createQualityCheckModal.toggleModal();
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>
