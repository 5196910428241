<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="serverDataLoaded"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Update Asn</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-4">
        <!--begin::Body-->
        <div class="row ma-0 pa-0">
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.shipper_code"
              label="Shipper code"
              clearable
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.shipper_reference"
              label="Shipper reference"
              clearable
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.consignee_code"
              label="Consignee code"
              clearable
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.consignee_reference"
              label="Consignee reference"
              clearable
              outlined
              dense
              hide-details
            ></v-text-field>
          </div>
          <div class="col-12">
            <v-textarea
              v-model="formData.note"
              label="Note"
              clearable
              outlined
              dense
              hide-details
            ></v-textarea>
          </div>

          <!--end::Body-->
        </div>
        <div class="my-2 py-0 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 mt-0 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1 mt-0"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "EditLight",
  components: {},
  props: ["refresher", "toggleDetail"],
  data: () => ({
    dialog: false,
    serverData: null,
    menu1: false,
    menu2: false,
    formData: {},
  }),
  methods: {
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    loadDataFromServer(item_id) {
      this.pageLoader(true);
      ApiService.post("/fulfillment/asn/edit-standard-asn", { id: item_id })
        .then((response) => {
          this.serverData = response.data;
          this.formData = this.serverData.asn;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal(item_id) {
      if (!this.dialog) {
        this.loadDataFromServer(item_id);
      } else {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.pageLoader(true);
      let data = { ...this.formData };
      ApiService.post("/fulfillment/asn/update-standard-asn", data)
        .then(() => {
          SwalService.successMessage({ html: SwalService.messages.updated() });

          this.pageLoader(false);
          // this.toggleDetail();
          this.refresher(this.formData.id, true, false);
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.formData = {};
    },
  },
  computed: {
    serverDataLoaded: function () {
      return !!this.serverData;
    },
  },
};
</script>
