<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.bundle_id"
            dense
            label="System Code"
            item-text="systemCode"
            item-value="skuId"
            clearable
            outlined
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            :error-messages="bundleIdErrors"
            @input="$v.formData.bundle_id.$touch()"
            @blur="$v.formData.bundle_id.$touch()"
          ></v-autocomplete>
        </div>

        <div class="mt-4 pa-0 w-100">
          <SkuDatatable
            :setSku="setSkus"
            :bundleId="formData.bundle_id"
            :skus="formData.skus"
          ></SkuDatatable>
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/fulfillment/bundles/skuAddContainer/SkuDatatable";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";

export default {
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { SkuDatatable },
  validations: {
    formData: {
      bundle_id: { required },
    },
  },
  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,
    serverData: {
      systemCode: null,
      skus: [],
    },
    formData: {
      bundle_id: null,
      skus: [],
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      let sendData = {
        bundle_id: this.formData.bundle_id,
        skus: this.formData.skus.map((item) => {
          return {
            sku_id: item.skuId,
            quantity: item.quantity,
          };
        }),
      };
      this.pageLoader(true);
      ApiService.post("/fulfillment/bundles/store", sendData)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetCreateForm();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.formData = {
        bundle_id: null,
        skus: [],
      };
      this.dialog = false;
    },
    async getData(value) {
      await fetch(
        `${process.env.VUE_APP_BASE_URL}/skus/search?sku_type=bundle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify({ q: value }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    bundleIdErrors: function () {
      return this.handleFormValidation("bundle_id", this);
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;
      this.getData(val);
    },
  },
};
</script>
