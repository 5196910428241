<template>
  <div>
    <v-menu
      content-class="m-0 p-0"
      transition="scale-transition"
      offset-y
      bottom
      nudge-bottom="5"
      :left="!$vuetify.rtl"
      :right="$vuetify.rtl"
      rounded
      max-height="75%"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-on="on"
          v-bind="attrs"
          class="poppins btn btn--export-filter px-5"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-dns</v-icon>
          </span>
          <span>Actions</span>
          <span class="svg-icon mr-0 ml-2">
            <v-icon size="18">mdi-chevron-down</v-icon>
          </span>
        </button>
      </template>
      <div class="py-1 w-175px bg-white px-3">
        <div
          class="drop-item poppins px-3 rounded-lg cursor-pointer"
          v-for="(action, index) in types"
          :key="index"
          @click="() => handleClick(action.index)"
        >
          <div
            class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
            :class="[
              { 'mt-2': index === 0 },
              { 'mb-2': index === types.length - 1 },
            ]"
          >
            {{ action.name }}
          </div>
        </div>
      </div>
    </v-menu>

    <MultipleEdit
      :refresher="refresher"
      :clearSelected="clearSelected"
      ref="multipleEdit"
    />
  </div>
</template>

<script>
import SwalService from "@/core/services/swal.service";
import MultipleEdit from "@/own/components/warehouseManagement/inventoryOverview/MultipleEdit.vue";

export default {
  name: "ActionsButton",
  components: { MultipleEdit },
  props: ["permissions", "selectedItems", "refresher", "clearSelected"],
  data: () => ({}),
  methods: {
    handleClick(index) {
      if (this.selectedItems?.length) {
        if (index === 1) {
          this.handleEdit(this.selectedItems);
        } else if (index === 2) {
          if (this.selectedItems?.length === 1) {
            this.handleCopy(this.selectedItems);
          } else {
            SwalService.warningMessage({
              title: this.$t("please_select_only_one_item"),
            });
          }
        }
      } else {
        SwalService.warningMessage({
          title: this.$t("please_select_at_least_one_item"),
        });
      }
    },
    handleEdit(selectedItems) {
      this.$refs.multipleEdit.loadDataFromServer(
        selectedItems.map((item) => item.id)
      );
    },
    handleCopy(selectedItems) {
      this.$refs.multipleEdit.loadDataFromServer(
        selectedItems.map((item) => item.id),
        false
      );
    },
  },
  computed: {
    types: function() {
      const types = [
        { index: 1, name: this.$t("edit"), perm: "update" },
        { index: 2, name: this.$t("copy"), perm: "copy_inventory" },
      ];

      return types.filter((item) => this.permissions(item.perm));
    },
  },
};
</script>

<style scoped>
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}

.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
