<template>
  <v-dialog
    v-model="dialog"
    elevation-0
    persistent
    max-width="800px"
    v-if="importResponse"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder text-capitalize">
          {{ $t("import_data_report") }}
        </h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="closeDialog"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
    </div>
    <div>
      <v-card elevation="0" class="px-1 py-2">
        <div class="text-left text-bold h5 ma-0 pa-2 py-3 text-success">
          {{ importResponse.success }} {{ $t("pagination.of") }}
          {{ importResponse.total }}
          {{ $t("imported_successfully") }}.
        </div>

        <ul
          class="list-group"
          style="
            height: 300px;
            overflow-y: scroll;
            padding-left: 0px !important;
            padding-right: 0px !important;
          "
          v-if="importResponse.message.length > 0"
        >
          <li
            class="list-group-item"
            v-for="(mes, i) in importResponse.message"
            :key="i"
          >
            <div class="h5 text-danger">{{ mes }}</div>
          </li>
        </ul>
        <div v-else class="h5" style="height: 300px; overflow-y: scroll">
          {{ $t("no_errors") }}
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import {
  SET_IMPORT_REPORT_DIALOG,
  SET_IMPORT_REPORT_DATA,
} from "@/core/services/store/config.module";
export default {
  name: "ImportReport",
  data: () => ({}),
  methods: {
    closeDialog() {
      this.$store.commit(SET_IMPORT_REPORT_DIALOG, false);
      this.$store.commit(SET_IMPORT_REPORT_DATA, null);
    },
  },
  computed: {
    dialog() {
      return this.$store.getters.getImportReportDialog;
    },
    importResponse() {
      return this.$store.getters.getImportReportData;
    },
  },
};
</script>

<style></style>
