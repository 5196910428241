<template>
  <v-range-slider
    :id="filter.name"
    outlined
    class="mx-1"
    v-model="filterData"
    :label="$t(filter.title)"
    :tick-size="1"
    :ticks="true"
    thumb-label="always"
    thumb-size="22"
    :min="filter.min"
    :max="filter.max"
    dense
    clearable
    @change="updateData"
  ></v-range-slider>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
export default {
  name: "FilterRangeSlider",
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: [0, 0],
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = [0, 0];
    },
    updateData() {
      const all = this.filterAllData;
      all[`${this.filter.name}`] = this.filterData;
      this.$store.commit(SET_FILTERS_DATA, all);
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
  },
};
</script>
