<template>
  <div v-if="isDataLoaded">
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Edit Carrier Account</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column justify-space-between scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->

          <div class="row pb-4">
            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                dense
                outlined
                v-model="formData.name"
                label="Name"
                required
                clearable
                :error-messages="nameErrors"
                @input="$v.formData.name.$touch()"
                @blur="$v.formData.name.$touch()"
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-text-field
                dense
                outlined
                v-model="formData.email"
                :label="$t('email')"
                clearable
              />
            </div>

            <div class="h-75px col-12 col-sm-4">
              <v-text-field
                dense
                outlined
                v-model="formData.username"
                :label="$t('Username')"
              />
            </div>

            <div class="h-75px col-12 col-sm-4">
              <v-text-field
                dense
                outlined
                v-model="formData.password"
                :label="$t('password')"
              />
            </div>

            <div class="h-75px col-12 col-sm-4">
              <v-text-field
                dense
                outlined
                v-model="formData.password_confirmation"
                :label="$t('confirm_password')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6">
              <v-autocomplete
                dense
                outlined
                v-model="formData.warehouse_id"
                :items="serverData.warehouses"
                item-name="text"
                item-value="index"
                label="Warehouse"
                clearable
                :error-messages="warehouse_idErrors"
                @input="$v.formData.warehouse_id.$touch()"
                @blur="$v.formData.warehouse_id.$touch()"
              />
            </div>
            <div class="h-75px col-12 col-sm-6">
              <v-autocomplete
                dense
                outlined
                v-model="formData.carrier_id"
                :items="serverData.carriers"
                item-name="text"
                item-value="index"
                label="Carrier"
                clearable
                :error-messages="carrier_idErrors"
                @input="$v.formData.carrier_id.$touch()"
                @blur="$v.formData.carrier_id.$touch()"
              />
            </div>

            <div class="h-75px col-12">
              <v-file-input
                outlined
                v-model="formData.photo"
                show-size
                counter
                multiple
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                label="Photo"
                clearable
              />
            </div>

            <div class="h-75px col-12">
              <v-text-field
                dense
                outlined
                v-model="formData.description"
                label="Description"
                clearable
              />
            </div>

            <v-card v-if="serverData.is_role_required" class="col-12">
              <v-toolbar-title
                class="primary lighten-1 h3 text-white text-center mt-4 pa-1"
                >Roles</v-toolbar-title
              >
              <div
                v-if="formData.roles.length === 0"
                class="text-danger text-left text-h6 ml-4"
              >
                Role is required!
              </div>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    lg="4"
                    v-for="role in serverData.roles"
                    :key="role.value"
                  >
                    <v-switch
                      class="mt-0 pt-0"
                      hide-details
                      v-model="formData.roles"
                      :color="$store.getters.currentUser.theme['text_color']"
                      :label="role.text"
                      :value="role.index"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <!--end::Body-->
          </div>
          <!--end::Body-->

          <!--begin::Actions-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
// import { validationMixin } from "vuelidate";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { editItemMessage } from "@/core/constants/globalVariables";
import ApiService from "@/core/services/api.service";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        name: { required },
        // email: { required, email },
        // password: {
        //   required: requiredIf(() => {
        //     return Boolean(this.formData.username.length);
        //   }),
        // },
        // password_confirmation: {
        //   // sameAsPassword: sameAs(this.formData.password),
        //   sameAsPassword: () => {
        //     return (
        //       this.formData.password === this.formData.password_confirmation
        //     );
        //   },
        //   required: requiredIf(() => {
        //     return Boolean(this.formData.username.length);
        //   }),
        // },
        roles: { required },
        carrier_id: { required },
        warehouse_id: { required },
      },
    };
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      name: "",
      email: "",
      username: "",
      password: "",
      password_confirmation: "",
      description: "",
      photo: null,
      roles: [],
      carrier_id: null,
      warehouse_id: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: { ...this.$store.getters.getItemForAction }.id };
      ApiService.post("/authorizations/carrier_accounts/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData }.user;
          this.formData.photo = null;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.pageLoader(true);
        let data = this.convertToFormData();

        ApiService.post("/authorizations/carrier_accounts/update", data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `${editItemMessage}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        name: "",
        email: "",
        username: "",
        password: "",
        password_confirmation: "",
        description: "",
        parent_id: null,
        photo: null,
        roles: [],
        carrier_id: null,
        warehouse_id: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    is_client_required: function () {
      return !!this.serverData.is_client_required;
    },
    is_role_required: function () {
      return !!this.serverData.is_role_required;
    },
    isDataLoaded: function () {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    // passwordErrors() {
    //   return this.handleFormValidation("password", this);
    // },
    // password_confirmationErrors() {
    //   if (this.formData.username && this.formData.password)
    //     return this.handleFormValidation("password_confirmation", this);
    //   else return [];
    // },
    rolesErrors() {
      if (this.is_role_required) {
        return this.formData.roles.length > 0 ? null : "Roles are required.";
      } else {
        return null;
      }
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this);
    },
    carrier_idErrors() {
      return this.handleFormValidation("carrier_id", this);
    },
  },
};
</script>
