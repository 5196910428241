<template>
  <v-card class="mt-4">
    <v-toolbar color="primary h5 text-white">
      <div class="w-100 d-flex align-items-center justify-space-between">
        <div>
          {{ item.title }}
        </div>
        <div class="bg-white px-3 rounded">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            clearable
            single-line
            hide-details
            class="min-w-250px"
            @keyup="debounceSearch"
            @click:clear="debounceSearch"
          />
        </div>
      </div>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-tabs v-model="tabs" mobile-breakpoint="300">
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Main</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Pallet</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Batch</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">Other</h3>
        </v-tab>
        <v-tab>
          <h3 class="font-weight-medium ma-0 font-size-lg poppins">
            Additional
          </h3>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <v-data-table
            disable-sort
            :headers="headersForMainTab"
            :items="getItems"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': item_per_page_list }"
          >
            <template v-slot:item.sku_id="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.asn_sku_id="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>
            <template v-slot:item.status="{ value }">
              <StatusColumn :value="value" />
            </template>

            <template
              v-slot:item.actions="{ item }"
              v-if="$store.getters.getASNIsActionBtnShownInViewAsn"
            >
              <div class="d-flex justify-content-center">
                <v-icon class="" @click="() => editSKU(item)" size="24">
                  mdi-pencil
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            disable-sort
            :headers="headersForPalletTab"
            :items="getItems"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': item_per_page_list }"
          >
            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>

            <template v-slot:item.carton_number="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template
              v-slot:item.actions="{ item }"
              v-if="$store.getters.getASNIsActionBtnShownInViewAsn"
            >
              <div class="d-flex justify-content-center">
                <v-icon class="" @click="() => editSKU(item)" size="24">
                  mdi-pencil
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            disable-sort
            :headers="headersForBatchTab"
            :items="getItems"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': item_per_page_list }"
          >
            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>

            <template
              v-slot:item.actions="{ item }"
              v-if="$store.getters.getASNIsActionBtnShownInViewAsn"
            >
              <div class="d-flex justify-content-center">
                <v-icon class="" @click="() => editSKU(item)" size="24">
                  mdi-pencil
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            disable-sort
            :headers="headersForOtherTab"
            :items="getItems"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': item_per_page_list }"
          >
            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>

            <template
              v-slot:item.actions="{ item }"
              v-if="$store.getters.getASNIsActionBtnShownInViewAsn"
            >
              <div class="d-flex justify-content-center">
                <v-icon class="" @click="() => editSKU(item)" size="24">
                  mdi-pencil
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item>
          <v-data-table
            disable-sort
            :headers="headersForAdditionalTab"
            :items="getItems"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-0"
            :footer-props="{ 'items-per-page-options': item_per_page_list }"
          >
            <template v-slot:item.sku_barcode="{ value }">
              <span class="second-text"> {{ value }}</span>
            </template>

            <template v-slot:item.description="{ value }">
              <span class="max-w-100px"> {{ value }}</span>
            </template>

            <template
              v-slot:item.actions="{ item }"
              v-if="$store.getters.getASNIsActionBtnShownInViewAsn"
            >
              <div class="d-flex justify-content-center">
                <v-icon class="" @click="() => editSKU(item)" size="24">
                  mdi-pencil
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <EditSKUDetails ref="editSKUDetails" :getDataFromApi="getDataFromApi" />
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
import StatusColumn from "@/own/components/datatable/StatusColumn.vue";
import EditSKUDetails from "@/own/components/fulfillment/asn/editSKUDetails/EditSKUDetails.vue";

export default {
  name: "PopUpTabDataTableWithSearch",
  components: { StatusColumn, EditSKUDetails },
  props: ["item"],
  data: () => ({
    tabs: null,
    debounce: null,
    search: "",
    total: 0,
    options: {},
    loading: true,
    items: [],
    item_per_page_list: [20, 30, 40, 50],
  }),
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    debounceSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getDataFromApi();
      }, 600);
    },
    getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      let data = {
        id: this.item.id,
        page: page,
        per_page: itemsPerPage,
      };

      if (this.search) {
        data.q = this.search;
      }

      ApiService.post(this.item.url, data)
        .then((response) => {
          this.total = response.data.data.total;
          this.items = response.data.data.data;

          this.dialog = false;
        })
        .catch(() => {
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editSKU(item) {
      // console.log(item);
      this.$refs.editSKUDetails.toggleModal({
        id: item.asn_sku_id,
        sku_barcode: item.sku_barcode,
        expected_qty: item.expected_quantity,
        received_qty: item.received_qty,
        carton_number: item.carton_number,
        carton_uom_id: item.carton_uom_id,
        carton_description: item.carton_description,
        carton_length: item.carton_length,
        carton_width: item.carton_width,
        carton_height: item.carton_height,
        carton_dimension_uom_id: item.carton_dimension_uom_id,
        carton_volume: item.carton_volume,
        carton_volume_uom_id: item.carton_volume_uom_id,
        batch_number: item.batch_number,
        serial_no: item.serial_number,
        lot_no: item.lot_no,
        mfg_date: item.mfg_date,
        weight_uom_id: item.weight_uom_id,
        net_weight: item.net_weight,
        gross_weight: item.gross_weight,
        volume_uom_id: item.volume_uom_id,
        volume: item.volume,
        unit_rate: item.unit_rate,
        unit_currency_id: item.currency_id,
        coo: item.coo_id,
        import_entry_no: item.import_entry_no,
        custom_entry_no: item.custom_entry_no,
        invoice_no: item.invoice_no,
        po_no: item.po_no,
        roll_no: item.roll_no,
        bl_no: item.bl_no,
        hs_code: item.hs_code,
        grade: item.grade,
        vin_no: item.vin_no,
        body_model: item.body_model,
        specification: item.specification,
        drive: item.drive,
        condition: item.condition,
        key_no: item.key_no,
        engine: item.engine_no,
        capacity: item.engine_capacity,
        year_of_manufacture: item.manufacture_year,
      });
    },
  },
  computed: {
    getItems() {
      return this.items;
    },
    headersForMainTab() {
      const headers = [
        { text: "Line No", value: "line_no" },
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "UOM", value: "uom" },
        { text: "Description", value: "description" },
        { text: "Expected quantity", value: "expected_quantity" },
        // { text: "Total quantity", value: "total_quantity" },
        { text: "Received quantity", value: "received_qty" },
        {
          text: "Pending received quantity",
          value: "pending_received_quantity",
        },
        { text: "Packed quantity", value: "packed_quantity" },
        { text: "Pending Packed quantity", value: "packing_pending_quantity" },
        { text: "Putaway quantity", value: "putaway_quantity" },
        { text: "Pending Putaway quantity", value: "putaway_pending_quantity" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ];

      if (this.getItems.length) {
        if (this.getItems[0].hasOwnProperty("asn_sku_id")) {
          headers.unshift({
            text: "Asn SKU ID",
            value: "asn_sku_id",
          });
        }
        if (this.getItems[0].hasOwnProperty("sku_id")) {
          headers.unshift({
            text: "SKU ID",
            value: "sku_id",
          });
        }
      }

      return headers;
    },
    headersForPalletTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "Carton Number", value: "carton_number" },
        { text: "Carton UOM", value: "carton_uom" },
        { text: "Carton Description", value: "carton_description" },
        { text: "Length", value: "carton_length" },
        { text: "Width", value: "carton_width" },
        { text: "Height", value: "carton_height" },
        { text: "Dimension UOM", value: "carton_dimension_uom" },
        { text: "Volume", value: "carton_volume" },
        { text: "Volume UOM", value: "carton_volume_uom" },
        { text: "Actions", value: "actions" },
      ];
    },
    headersForBatchTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "Batch number", value: "batch_number" },
        { text: "Serial number", value: "serial_number" },
        { text: "Lot no", value: "lot_no" },
        { text: "Invoice no", value: "invoice_no" },
        { text: "PO no", value: "po_no" },
        { text: "MFG date", value: "mfg_date" },
        { text: "Expiry date", value: "expiry_date" },
        { text: "Location", value: "location" },
        { text: "Damaged", value: "damaged" },
        { text: "Damage reason", value: "damage_reason" },
        { text: "Actions", value: "actions" },
      ];
    },
    headersForOtherTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "Net weight", value: "net_weight" },
        { text: "Gross weight", value: "gross_weight" },
        { text: "Volume", value: "volume" },
        { text: "User", value: "user" },
        { text: "HSCODE", value: "hs_code" },
        { text: "COO", value: "coo" },
        { text: "Roll No", value: "roll_no" },
        { text: "BL no", value: "bl_no" },
        { text: "Unit Rate", value: "unit_rate" },
        { text: "Currency", value: "currency" },
        { text: "Container no", value: "container_no" },
        { text: "Import entry no", value: "import_entry_no" },
        { text: "Custom entry no", value: "custom_entry_no" },
        { text: "Actions", value: "actions" },
      ];
    },
    headersForAdditionalTab() {
      return [
        { text: "SKU barcode", value: "sku_barcode" },
        { text: "Description", value: "description" },
        { text: "VIN no", value: "vin_no" },
        { text: "Engine no", value: "engine_no" },
        { text: "Body model", value: "body_model" },
        { text: "Specification", value: "specification" },
        { text: "Drive", value: "drive" },
        { text: "Condition", value: "condition" },
        { text: "Key no", value: "key_no" },
        { text: "Engine capacity", value: "engine_capacity" },
        { text: "Manufacture year", value: "manufacture_year" },
        { text: "Actions", value: "actions" },
      ];
    },
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
};
</script>
