<template>
  <v-container class="d-flex flex-column flex-fill ml-2 mr-4">
    <v-row v-if="serverData">
      <v-col cols="12" lg="8">
        <FieldContainer label="Rotation rules">
          <v-autocomplete
            v-model="formData.rotation_rules"
            class="rounded-lg h-45px"
            :items="serverData.rotation_rules"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            :error-messages="rotation_rulesErrors"
            @blur="$v.formData.rotation_rules.$touch()"
          />
        </FieldContainer>

        <FieldContainer label="Expire control">
          <v-switch
            v-model="formData.expire_control"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Serial number control">
          <v-switch
            v-model="formData.serial_number_control"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is decimal controlled">
          <v-switch
            v-model="formData.is_decimal_controlled"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is perishable">
          <v-switch
            v-model="formData.is_perishable"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is DG">
          <v-switch
            v-model="formData.is_dg"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is shelf life">
          <v-switch
            v-model="formData.is_shell_life"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Shelf life UOM">
          <v-autocomplete
            v-model="formData.shell_life_uom_id"
            class="rounded-lg h-45px"
            :items="serverData.uoms"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Shelf life alert days">
          <v-text-field
            v-model="formData.shell_life_alert_days"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
            type="number"
            :min="0"
          />
        </FieldContainer>

        <FieldContainer label="Shelf life">
          <v-text-field
            v-model="formData.shell_life"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is UOM breakable">
          <v-switch
            v-model="formData.is_uom_breakable"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Min threshold">
          <v-text-field
            v-model="formData.min_threshold"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
            type="number"
            :min="0"
          />
        </FieldContainer>

        <FieldContainer label="Reorder quantity">
          <v-text-field
            v-model="formData.reorder_qty"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
            type="number"
            :min="0"
          />
        </FieldContainer>

        <FieldContainer label="Is kit">
          <v-switch
            v-model="formData.is_kit"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is over receive">
          <v-switch
            v-model="formData.is_over_receive"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Over receive percentage">
          <v-text-field
            v-model="formData.over_receive_percentage"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
            type="number"
            :min="0"
          />
        </FieldContainer>

        <FieldContainer label="Is batch control required">
          <v-switch
            v-model="formData.is_batch_control_required"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is batch control by mfg">
          <v-switch
            v-model="formData.is_batch_control_by_mfg"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is batch control by exp">
          <v-switch
            v-model="formData.is_batch_control_by_exp"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is batch control auto calc">
          <v-switch
            v-model="formData.is_batch_control_auto_calc"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is QC inbound required">
          <v-switch
            v-model="formData.is_qc_inbound_required"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="QC inbound method">
          <v-autocomplete
            v-model="formData.qc_inbound_method_id"
            class="rounded-lg h-45px"
            :items="serverData.qc_methods"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="QC inbound sampling quantity">
          <v-text-field
            v-model="formData.qc_inbound_sampling_qty"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
            type="number"
            :min="0"
          />
        </FieldContainer>

        <FieldContainer label="QC inbound sampling UOM">
          <v-autocomplete
            v-model="formData.qc_inbound_sampling_uom_id"
            class="rounded-lg h-45px"
            :items="serverData.uoms"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is QC outbound required">
          <v-switch
            v-model="formData.is_qc_outbound_required"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="QC outbound method">
          <v-autocomplete
            v-model="formData.qc_outbound_method_id"
            class="rounded-lg h-45px"
            :items="serverData.qc_methods"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="QC outbound sampling quantity">
          <v-text-field
            v-model="formData.qc_outbound_sampling_qty"
            class="rounded-lg h-45px"
            clearable
            outlined
            dense
            hide-details
            type="number"
            :min="0"
          />
        </FieldContainer>

        <FieldContainer label="QC outbound sampling UOM">
          <v-autocomplete
            v-model="formData.qc_outbound_sampling_uom_id"
            class="rounded-lg h-45px"
            :items="serverData.uoms"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is QR Code available">
          <v-switch
            v-model="formData.qr_code_available"
            class="h-50px"
            inset
            dense
            hide-details
          />
          <!--          <v-autocomplete-->
          <!--            v-model="formData.qr_code_available"-->
          <!--            class="rounded-lg h-45px"-->
          <!--            :items="booleanValues"-->
          <!--            item-text="text"-->
          <!--            item-value="index"-->
          <!--            clearable-->
          <!--            outlined-->
          <!--            dense-->
          <!--            hide-details-->
          <!--          />-->
        </FieldContainer>

        <FieldContainer label="QR Code area">
          <v-autocomplete
            v-model="formData.qr_code_area_id"
            class="rounded-lg h-45px"
            :items="serverData.qr_code_areas"
            item-text="text"
            item-value="index"
            clearable
            outlined
            dense
            hide-details
          />
        </FieldContainer>

        <FieldContainer label="Is Sealed">
          <v-switch
            v-model="formData.is_sealed"
            class="h-50px"
            inset
            dense
            hide-details
          />
          <!--          <v-autocomplete-->
          <!--            v-model="formData.is_sealed"-->
          <!--            class="rounded-lg h-45px"-->
          <!--            :items="booleanValues"-->
          <!--            item-text="text"-->
          <!--            item-value="index"-->
          <!--            clearable-->
          <!--            outlined-->
          <!--            dense-->
          <!--            hide-details-->
          <!--          />-->
        </FieldContainer>

        <FieldContainer label="Is Returnable" :divider="false">
          <v-switch
            v-model="formData.is_returnable"
            class="h-50px"
            inset
            dense
            hide-details
          />
        </FieldContainer>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col cols="12" lg="8" class="pr-0">
        <v-row class="d-flex justify-content-end">
          <v-col cols="auto">
            <button
              @click="submit"
              type="submit"
              class="btn btn-info px-5 py-3"
            >
              {{ this.$t("submit") }}
            </button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";

import validationMixin from "@/own/mixins/validationMixin.js";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";
import FieldContainer from "@/own/components/fulfillment/skus/forms/FieldContainer.vue";

export default {
  name: "ControlFormFields",
  mixins: [validationMixin],
  components: { FieldContainer },
  props: ["pageLoader", "actionItem", "refresher", "tabName"],
  validations: {
    formData: {
      rotation_rules: { required },
    },
  },
  data: () => ({
    formData: {
      rotation_rules: null,
      expire_control: null,
      serial_number_control: null,
      is_decimal_controlled: null,
      is_perishable: null,
      is_dg: null,
      is_shell_life: null,
      shell_life_uom_id: null,
      shell_life_alert_days: null,
      shell_life: null,
      is_uom_breakable: null,
      min_threshold: null,
      reorder_qty: null,
      is_kit: null,
      is_over_receive: null,
      over_receive_percentage: null,
      is_batch_control_required: null,
      is_batch_control_by_mfg: null,
      is_batch_control_by_exp: null,
      is_batch_control_auto_calc: null,
      is_qc_inbound_required: null,
      qc_inbound_method_id: null,
      qc_inbound_sampling_qty: null,
      qc_inbound_sampling_uom_id: null,
      is_qc_outbound_required: null,
      qc_outbound_method_id: null,
      qc_outbound_sampling_qty: null,
      qc_outbound_sampling_uom_id: null,
      qr_code_available: null,
      qr_code_area_id: null,
      is_sealed: null,
      is_returnable: false,
    },
    serverData: null,
  }),
  // created() {
  //   this.loadServerData();
  // },
  methods: {
    loadServerData() {
      // if (!this.serverData) {
      this.pageLoader(true);
      let payload = {
        tab_name: this.tabName,
      };

      if (this.actionItem) {
        payload.id = this.actionItem.id;
      }

      return ApiService.post("/fulfillment/skus/edit", payload)
        .then(({ data }) => {
          if (!this.serverData) {
            this.serverData = data;
          }

          Object.entries(data?.sku).forEach(([key, value]) => {
            this.formData[key] = value;
          });
        })
        .finally(() => {
          this.pageLoader(false);
        });
      // }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);

      let url = `/fulfillment/skus/${this.actionItem ? `update` : `store`}`;

      const payload = {
        tab_name: this.tabName,
        id: this.actionItem.id,
        ...this.formData,
      };

      ApiService.post(url, payload)
        .then(({ data }) => {
          if (data.sku) {
            this.$store.commit(SET_ITEM_FOR_ACTION, data.sku);
          }
          const title = this.actionItem
            ? this.$t("updated")
            : this.$t("created");
          SwalService.successMessage({
            title: title,
            html: this.$t("submitted_successfully"),
          });
          this.refresher();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    reset() {
      this.$v.$reset();
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = null;
      });
    },
  },
  computed: {
    rotation_rulesErrors() {
      return this.handleFormValidation("rotation_rules", this);
    },
    booleanValues: function() {
      return [
        { index: 0, text: "No" },
        { index: 1, text: "Yes" },
      ];
    },
  },
};
</script>
