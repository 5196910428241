<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-black font-size-lg ms-5">
        <!-- <v-icon class="mr-3">mdi-{{ headerIcon }}-circle-outline</v-icon> -->
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- is allow partial allocation -->
          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.is_allow_partial_allocation"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow partial allocation
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row v-if="formData.is_allow_partial_allocation" class="ms-3">
                <v-col md="6">
                  <v-select
                    v-model="formData.is_allow_partial_allocation_rules"
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="partialAllocationRulesErrors"
                    @input="
                      $v.formData.is_allow_partial_allocation_rules.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_partial_allocation_rules.$touch()
                    "
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.is_allow_partial_allocation_rules !== 'all' &&
                    formData.is_allow_partial_allocation_rules !== null
                  "
                >
                  <v-select
                    v-model="formData.is_allow_partial_allocation_customers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="partialAllocationCustomersError"
                    @input="
                      $v.formData.is_allow_partial_allocation_customers.$touch()
                    "
                    @blur="
                      $v.formData.is_allow_partial_allocation_customers.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- lock location while skipping a task -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_lock_location_while_skipping_a_task"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Lock location while skipping a task
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- validate stging location -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_validate_staging_location_while_palletize"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Validate staging location while palletize
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- allow partial shipping -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_allow_partial_shipping"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow partial shipping
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- switch task while skip -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_switch_the_task_while_skip"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Switch task while skip
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- cancel order handing option for proceed items -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="
                  formData.cancel_order_handling_options_for_processed_items
                "
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Cancel order handing options for processed items
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="" md="6" v-if="serverData">
              <v-row
                v-if="
                  formData.cancel_order_handling_options_for_processed_items
                "
                class="ms-3"
              >
                <v-col md="9">
                  <v-select
                    v-model="
                      formData.cancel_order_handling_options_for_processed_items_option
                    "
                    label="Option"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.processed_items_handling_options"
                    :error-messages="processedItemsHandingOptionsError"
                    @input="
                      $v.formData.cancel_order_handling_options_for_processed_items_option.$touch()
                    "
                    @blur="
                      $v.formData.cancel_order_handling_options_for_processed_items_option.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- number  of orders to auto assign -->
          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.number_of_orders_to_auto_assign"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Number of orders to auto assign
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row
                v-if="formData.number_of_orders_to_auto_assign"
                class="ms-3"
              >
                <v-col md="6">
                  <v-text-field
                    v-model="formData.number_of_orders_to_auto_assign_option"
                    label="Quantity"
                    dense
                    outlined
                    rounded
                    :error-messages="numberOfOrdersAutoAssignOptionsError"
                    @input="
                      $v.formData.number_of_orders_to_auto_assign_option.$touch()
                    "
                    @blur="
                      $v.formData.number_of_orders_to_auto_assign_option.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Allow user to update number of orders to auto assign -->
          <v-row class="mt-0">
            <v-col md="12">
              <v-checkbox
                v-model="
                  formData.is_allow_user_to_update_number_of_orders_to_auto_assign
                "
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow user to update number of orders to auto assign
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Control picking assignment using wave -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_control_picking_assignment_using_wave"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Control picking assignment using wave
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Show partially shipped orders in orders page -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="
                  formData.is_show_partially_shipped_orders_in_orders_page
                "
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Show partially shipped orders in orders page
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Allow auto save for Packing -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_allow_auto_save_for_packing"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow auto save for Packing
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- Allow replenishment using wave -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.is_allow_replenishment_using_wave"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Allow replenishment using wave
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col md="3">
              <v-checkbox
                v-model="formData.order_replenishment_retry_limit"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="font-size-md font-weight-medium">
                    Order replenishment retry limit
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="6" v-if="serverData">
              <v-row
                v-if="formData.order_replenishment_retry_limit"
                class="ms-3"
              >
                <v-col md="6">
                  <v-text-field
                    v-model="formData.order_replenishment_retry_limit_option"
                    label="Limit"
                    dense
                    outlined
                    rounded
                    :error-messages="
                      order_replenishment_retry_limit_optionsError
                    "
                    @input="
                      $v.formData.order_replenishment_retry_limit_option.$touch()
                    "
                    @blur="
                      $v.formData.order_replenishment_retry_limit_option.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- location -->
          <v-row>
            <v-col md="6">
              <v-autocomplete
                v-model="formData.default_outbound_staging_location"
                label="Default Outbound Staging location"
                dense
                outlined
                rounded
                clearable
                item-text="name"
                item-value="id"
                :items="serverData.locations"
                :loading="stagingLocaitonLoading"
                :search-input.sync="stagingLocationSearch"
                @change="clearDefaultMissingLocation"
                @keydown="searchStagingLocations"
              />
            </v-col>
          </v-row>

          <!-- Submit -->
          <v-row class="justify-center mb-3">
            <v-col md="2" class="justify-content-end">
              <!-- <v-btn dark color="purple" @click="submitConfiguration">
                Submit
              </v-btn> -->
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "ExpansionPanel",
  mixins: [validationMixin],
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  validations: {
    formData: {
      is_allow_partial_allocation_rules: {
        required: requiredIf(function () {
          return this.formData.is_allow_partial_allocation;
        }),
      },
      is_allow_partial_allocation_customers: {
        required: requiredIf(function () {
          return (
            (this.formData.is_allow_partial_allocation &&
              this.formData.is_allow_partial_allocation_rules === "only") ||
            this.formData.is_allow_partial_allocation_rules === "except"
          );
        }),
      },
      cancel_order_handling_options_for_processed_items_option: {
        required: requiredIf(function () {
          return this
            .formData.cancel_order_handling_options_for_processed_items;
        }),
      },
      order_replenishment_retry_limit_option: {
        required: requiredIf(function () {
          return this.formData.order_replenishment_retry_limit;
        }),
      },
      number_of_orders_to_auto_assign_option: {
        required: requiredIf(function () {
          return this.formData.number_of_orders_to_auto_assign;
        }),
      },
    },
  },
  components: {},
  data() {
    return {
      expanded: false,
      serverData: null,
      serverDataLoaded: false,
      stagingLocationSearch: null,
      stagingLocaitonLoading: false,

      formData: {
        is_validate_staging_location_while_palletize: false,

        is_allow_partial_allocation: false,
        is_allow_partial_allocation_rules: null,
        is_allow_partial_allocation_customers: [],

        is_lock_location_while_skipping_a_task: false,
        is_show_partially_shipped_orders_in_orders_page: false,
        is_allow_auto_save_for_packing: false,
        is_allow_replenishment_using_wave: false,

        is_allow_partial_shipping: false,

        is_switch_the_task_while_skip: false,

        cancel_order_handling_options_for_processed_items: false,
        cancel_order_handling_options_for_processed_items_option: null,

        order_replenishment_retry_limit: false,
        order_replenishment_retry_limit_option: null,

        number_of_orders_to_auto_assign: null,
        number_of_orders_to_auto_assign_option: null,

        is_allow_user_to_update_number_of_orders_to_auto_assign: false,
        is_control_picking_assignment_using_wave: false,
        default_outbound_staging_location: null,
      },
    };
  },

  methods: {
    togglePanel: function () {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function () {
      this.serverDataLoaded = false;
      const payload = {
        type: "outbound",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.formData.default_outbound_staging_location =
              data.configurations.default_location_id;
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    setConfigurationData: function (data) {
      this.formData = {
        // ...this.formData,
        is_validate_staging_location_while_palletize: data[0].value,

        is_lock_location_while_skipping_a_task: data[1].value,

        is_switch_the_task_while_skip: data[2].value,

        is_allow_partial_shipping: data[3].value,

        is_allow_partial_allocation: data[4].value,
        is_allow_partial_allocation_rules: data[4].apply_rule_type,
        is_allow_partial_allocation_customers: data[4].customers,

        cancel_order_handling_options_for_processed_items: !!data[5].value,

        cancel_order_handling_options_for_processed_items_option: data[5].value
          ? Number(data[5].value)
          : null,

        number_of_orders_to_auto_assign: !!data[6].value,
        number_of_orders_to_auto_assign_option: data[6].value
          ? Number(data[6].value)
          : null,

        order_replenishment_retry_limit: !!data[7].value,

        order_replenishment_retry_limit_option: data[7].value
          ? Number(data[7].value)
          : null,

        is_allow_user_to_update_number_of_orders_to_auto_assign: data[8].value,
        is_control_picking_assignment_using_wave: data[9].value,
        is_show_partially_shipped_orders_in_orders_page: data[10].value,
        is_allow_auto_save_for_packing: data[11].value,
        is_allow_replenishment_using_wave: data[12].value,
      };
    },

    handleFormValidation: function (fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },

    submitConfiguration: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = this.formData;
        const payload = {
          type: "outbound",
          warehouse_id: this.warehouseDetails.index,
          default_location_id: this.formData.default_outbound_staging_location,
          configurations: [
            {
              key: "is_validate_staging_location_while_palletize",
              value: data.is_validate_staging_location_while_palletize,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_allow_partial_allocation",
              value: data.is_allow_partial_allocation,
              apply_rule_type: data.is_allow_partial_allocation_rules,
              customers: data.is_allow_partial_allocation_customers,
            },
            {
              key: "is_lock_location_while_skipping_a_task",
              value: data.is_lock_location_while_skipping_a_task,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_allow_partial_shipping",
              value: data.is_allow_partial_shipping,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_switch_the_task_while_skip",
              value: data.is_switch_the_task_while_skip,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "cancel_order_handling_options_for_processed_items",
              value: data.cancel_order_handling_options_for_processed_items
                ? data.cancel_order_handling_options_for_processed_items_option
                : null,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "order_replenishment_retry_limit",
              value: data.order_replenishment_retry_limit
                ? data.order_replenishment_retry_limit_option
                : null,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "number_of_orders_to_auto_assign",
              value: data.number_of_orders_to_auto_assign
                ? data.number_of_orders_to_auto_assign_option
                : null,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_allow_user_to_update_number_of_orders_to_auto_assign",
              value:
                data.is_allow_user_to_update_number_of_orders_to_auto_assign,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_control_picking_assignment_using_wave",
              value: data.is_control_picking_assignment_using_wave,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_show_partially_shipped_orders_in_orders_page",
              value: data.is_show_partially_shipped_orders_in_orders_page,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_allow_auto_save_for_packing",
              value: data.is_allow_auto_save_for_packing,
              apply_rule_type: "all",
              customers: [],
            },
            {
              key: "is_allow_replenishment_using_wave",
              value: data.is_allow_replenishment_using_wave,
              apply_rule_type: "all",
              customers: [],
            },
          ],
        };
        this.$store.commit(SET_PAGE_LOADING, true);
        return new Promise(() => {
          ApiService.post(
            `/warehouse_management/main_configuration/store`,
            payload
          )
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: "The Configuration settings are updated",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              // this.expanded = false;
            })
            .catch()
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        });
      }
    },

    searchStagingLocations() {
      setTimeout(() => {
        if (this.stagingLocationSearch) {
          this.stagingLocaitonLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.stagingLocationSearch,
            location_type: "outbound_staging",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload)
              .then(({ data }) => {
                this.serverData.locations = data.locations;

                resolve();
              })
              .finally(() => {
                this.stagingLocaitonLoading = false;
              });
          });
        }
      }, 200);
    },
    clearDefaultMissingLocation() {
      if (
        !this.stagingLocationSearch &&
        !this.formData.default_outbound_staging_location
      ) {
        this.serverData.locations = [];
      }
    },
  },
  computed: {
    partialAllocationRulesErrors: function () {
      return this.handleFormValidation(
        "is_allow_partial_allocation_rules",
        this
      );
    },
    partialAllocationCustomersError: function () {
      return this.handleFormValidation(
        "is_allow_partial_allocation_customers",
        this
      );
    },
    processedItemsHandingOptionsError: function () {
      return this.handleFormValidation(
        "cancel_order_handling_options_for_processed_items_option",
        this
      );
    },
    order_replenishment_retry_limit_optionsError: function () {
      return this.handleFormValidation(
        "order_replenishment_retry_limit_option",
        this
      );
    },
    numberOfOrdersAutoAssignOptionsError: function () {
      return this.handleFormValidation(
        "number_of_orders_to_auto_assign_option",
        this
      );
    },
  },
};
</script>
