<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column scroll-y overflow-y-auto mx-5 py-2"
      >
        <form class="row ma-0 max-h-450px">
          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              dense
              outlined
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px" v-if="serverData.organizations">
            <v-autocomplete
              v-model="formData.organization_id"
              label="Organization"
              :items="serverData.organizations"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="organization_idErrors"
              @blur="$v.formData.organization_id.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-select
              v-model="formData.module_id"
              label="Module"
              :items="serverData.modules"
              item-text="text"
              item-value="index"
              dense
              outlined
              hide-no-data
              hide-selected
              @change="onModuleChange"
              :error-messages="module_idErrors"
              @blur="$v.formData.module_id.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-select
              v-model="formData.format_type_id"
              label="Format type"
              :items="serverData.format_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              hide-no-data
              hide-selected
              :error-messages="format_type_idErrors"
              @blur="$v.formData.format_type_id.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-select
              v-model="formData.parcelable_key"
              label="Parcelable key"
              :items="moduleParcelableKeys"
              item-text="text"
              item-value="index"
              dense
              outlined
              hide-no-data
              hide-selected
              :error-messages="parcelable_keyErrors"
              @blur="$v.formData.parcelable_key.$touch()"
            />
          </div>
          <div
            class="col-12 col-sm-6 h-75px"
            v-if="formData.format_type_id == '1'"
          >
            <v-text-field
              v-model="formData.input_length"
              label="Input length"
              dense
              outlined
              clearable
              type="number"
              :min="1"
              @change="
                () => {
                  if (!formData.format_type_id == 1) {
                    return;
                  }
                  if (formData.input_length < 1) {
                    formData.input_length = 1;
                  }
                }
              "
              :error-messages="input_lengthErrors"
              @blur="$v.formData.input_length.$touch()"
            />
          </div>

          <div
            class="col-12 col-sm-6 h-75px"
            v-if="formData.format_type_id == '2'"
          >
            <v-text-field
              v-model="formData.number_of_parcelable_output"
              label="Number of parcelable output"
              dense
              outlined
              clearable
              type="number"
              :max="5"
              :min="1"
              :error-messages="number_of_parcelable_outputErrors"
              @blur="$v.formData.number_of_parcelable_output.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-select
              v-model="formData.apply_type_id"
              label="Apply type"
              :items="serverData.apply_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              hide-no-data
              hide-selected
              :error-messages="apply_type_idErrors"
              @blur="$v.formData.apply_type_id.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              :items="statusValues"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="statusErrors"
              @blur="$v.formData.status.$touch()"
            />
          </div>
          <div
            class="col-12 h-75px"
            v-if="!$store.getters.isClient && formData.apply_type_id == '2'"
          >
            <v-combobox
              v-model="formData.clients"
              label="Customer"
              :items="listOfClients"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              multiple
              small-chips
              deletable-chips
              :error-messages="clientsErrors"
              @blur="$v.formData.clients.$touch()"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                  <v-list-item-action>
                    <v-icon>
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="font-size-sm">
                      Select All
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              dense
              outlined
              clearable
            />
          </div>
        </form>
        <div class="mt-4 pa-0 w-100">
          <ActionBuilder
            :query="formData.rule"
            :options="moduleActionKey"
            :format-type="formData.format_type_id"
            :input-length="formData.input_length"
            :count="formData.number_of_parcelable_output"
            ref="actionBuilder"
          />
        </div>

        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import ActionBuilder from "@/own/components/warehouseManagement/dataParsing/actionBuilder/ActionBuilder";
import Swal from "sweetalert2";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: {
    ActionBuilder,
  },
  validations() {
    return {
      formData: {
        name: { required },
        status: { required },
        module_id: { required },
        organization_id: {
          required: requiredIf(function () {
            return this.serverData?.organizations;
          }),
        },
        parcelable_key: { required },
        apply_type_id: { required },
        format_type_id: { required },
        input_length: {
          required: requiredIf(function () {
            return this.formData.format_type_id == 1;
          }),
        },
        number_of_parcelable_output: {
          required: requiredIf(function () {
            return this.formData.format_type_id == 2;
          }),
        },
        clients: {
          required: requiredIf(function () {
            return (
              this.formData.apply_type_id == "2" &&
              !this.$store.getters.isClient
            );
          }),
        },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      name: null,
      status: null,
      description: null,
      rule: [],
      apply_type_id: 1,
      module_id: 1,
      format_type_id: 1,
      parcelable_key: null,
      input_length: null,
      number_of_parcelable_output: null,
      organization_id: null,
      clients: [],
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.formData = {
        name: null,
        status: null,
        description: null,
        rule: [],
        apply_type_id: 1,
        module_id: null,
        format_type_id: 1,
        parcelable_key: null,
        input_length: null,
        number_of_parcelable_output: null,
        organization_id: null,
        clients: [],
      };
    },
    loadDataFromServer() {
      // this.$store.dispatch(GET_DATA);
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        ApiService.post("/warehouse_management/parsing_rules/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = data.parsing_rule;
            this.formData.clients = data.clients.filter((item) =>
              data.parsing_rule.clients.includes(item.index)
            );
            // console.log(" this.query", this.query);
            // console.log(" this.formData", this.formData);
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        ApiService.post("/warehouse_management/parsing_rules/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    validateActionBuilderData() {
      let isNotValid = false;
      this.formData.rule.forEach((param) => {
        if (
          param.from === undefined ||
          param.from === null ||
          param.from === "" ||
          param.to === undefined ||
          param.to === null ||
          param.to === ""
        ) {
          isNotValid = true;
        }
      });
      return isNotValid;
    },
    submitForm() {
      // console.log("sendData 1");
      this.$v.$touch();
      // console.log("sendData 2");

      // console.log("sendData 3");
      if (
        this.$v.$invalid ||
        this.$store.getters.getALLOCATIONSTATEGYRULESNotValid
      ) {
        return;
      } //else console.log("noooo");
      // if (this.formData.actions === 3) {
      if (!this.formData.rule.length) {
        Swal.fire({
          title: "Warning",
          text: "Please, add at least one action!",
          icon: "warning",
        });
        return;
      }
      if (this.validateActionBuilderData()) {
        return;
      }
      // } else {
      //   this.formData.rule = [];
      // }

      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let sendData = {
        ...this.formData,
      };
      if (this.formData.apply_type_id != "1") {
        sendData.clients = this.formData.clients.map((item) => item.index);
      }

      if (this.formData.format_type_id == "1") {
        delete sendData.number_of_parcelable_output;
      } else {
        delete sendData.input_length;
      }

      // sendData.rule = [];

      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      // console.log("sendData", sendData);
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(
        `warehouse_management/parsing_rules/${actionUrl}`,
        sendData
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    toggle() {
      if (this.selectedAll) {
        this.$set(this.formData, "clients", null);
      } else {
        let arr = [...this.customers];
        this.$set(this.formData, "clients", arr);
      }
    },
    onModuleChange() {
      this.serverData = this.serverData;
      this.formData.rule = [];
      // this.formData.parcelable_key = null;
      this.$nextTick(() => {
        this.$refs.actionBuilder.$refs.actionGroup.addRule();
      });
    },
  },
  computed: {
    listOfClients() {
      if (this.serverData?.organizations) {
        return this.serverData?.clients?.filter(
          (client) => client.organization_id == this.formData.organization_id
        );
      } else {
        return this.serverData.clients;
      }
    },
    moduleParcelableKeys() {
      return this.serverData?.parcelable_keys?.find(
        (parcelableKey) => parcelableKey.module_id == this.formData.module_id
      )?.parcelable_keys;
    },
    moduleActionKey() {
      return this.serverData?.action_keys?.find(
        (action_key) => action_key.module_id === this.formData.module_id
      )?.keys;
    },
    selectedAll() {
      if (Array.isArray(this.formData.clients)) {
        return this.customers.length === this.formData.clients.length;
      } else return false;
    },
    selectedSome() {
      if (Array.isArray(this.formData.clients)) {
        return this.formData.clients.length > 0;
      } else return false;
    },
    icon() {
      if (this.selectedAll) return "mdi-checkbox-marked";
      if (this.selectedSome) return "mdi-minus-box-outline";
      return "mdi-checkbox-blank-outline";
    },
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    customers: function () {
      return this.serverData.clients;
    },
    statusValues: function () {
      return this.serverData.statuses;
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    module_idErrors: function () {
      return this.handleFormValidation("module_id");
    },
    apply_type_idErrors: function () {
      return this.handleFormValidation("apply_type_id");
    },
    format_type_idErrors: function () {
      return this.handleFormValidation("format_type_id");
    },
    parcelable_keyErrors: function () {
      return this.handleFormValidation("parcelable_key");
    },
    input_lengthErrors: function () {
      return this.handleFormValidation("input_length");
    },
    number_of_parcelable_outputErrors: function () {
      return this.handleFormValidation("number_of_parcelable_output");
    },
    clientsErrors: function () {
      return this.handleFormValidation("clients");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
  },
};
</script>
