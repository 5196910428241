<template>
  <v-menu
    content-class="m-0 p-0"
    transition="scale-transition"
    offset-y
    bottom
    nudge-bottom="5"
    :left="!$vuetify.rtl"
    :right="$vuetify.rtl"
    rounded
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
        :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
      >
        <button v-bind="attrs" v-on="on" class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-printer</v-icon>
          </span>
          Print
        </button>
      </div>
    </template>
    <div class="py-2 w-150px bg-white px-3">
      <div
        class="drop-item poppins px-3 rounded-lg cursor-pointer my-4 bg-light bg-hover-light-info"
        v-for="(print, index) in types"
        :key="index"
        @click="onPrint(print.type)"
      >
        <div class="py-2 w-100 poppins font-size-md text-center">
          {{ print.name }}
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
export default {
  name: "Print",
  props: ["items", "types"],

  methods: {
    // showReport(type) {
    //   let total = this.items.length;
    //   let available = this.items.filter(
    //     item => item.id.detail.print[`${type}`] == true
    //   );
    //   let notAvailable = this.items.filter(
    //     item => item.id.detail.print[`${type}`] == false
    //   );
    //   let typeAmountAvailable = available.length;
    //   if (typeAmountAvailable != total) {
    //     Swal.fire({
    //       title: "Warning",
    //       html: `<div>
    //               <div>The ${typeAmountAvailable} of ${total} available for ${type}</div>
    //               <div><span>Orders that are not printed:</span>${notAvailable
    //                 .map(item => item.id.id)
    //                 .toString()}</div>
    //             </div>`,
    //       icon: "warning",
    //       showConfirmButton: true
    //     });
    //   }
    // console.log(type, total, typeAmountAvailable, available, notAvailable);
    // return {
    //   total: total,
    //   availableAmount: typeAmountAvailable,
    //   available: available,
    //   notAvailable: notAvailable
    // };
    // },
    onPrint(val) {
      if (this.items.length > 0) {
        // let isRepotShown = this.showReport(val);
        let isRepotShown = { availableAmount: 12 };
        if (isRepotShown.availableAmount > 0) {
          this.$store.commit(SET_PAGE_LOADING, true);
          const formdata = { type: val, ids: this.itemsCol };
          // console.log(val, this.itemsCol);
          let data = formdata;
          ApiService.post("/fulfillment/skus/sku_barcode_generate", data)
            .then((response) => {
              const link = document.createElement("a");
              link.href = response.data.path;
              link.target = "_blank";
              link.download = "orders.csv";
              link.click();
              setTimeout(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              }, 2500);
            })
            .catch(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        } else {
          Swal.fire({
            title: "Warning",
            text: "Can not create report about print!",
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select a sku!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
  },
  computed: {
    itemsCol: function () {
      return this.items.map((item) => item.id);
    },
  },
};
</script>
