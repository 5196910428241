<template>
  <div v-if="item.type === 'text'">
    <PopUpTextGroup :item="item"></PopUpTextGroup>
  </div>
  <div v-else-if="item.type === 'datatable'">
    <PopUpDataTable :item="item"></PopUpDataTable>
  </div>
  <div v-else-if="item.type === 'datatable_with_search'">
    <PopUpDataTableWithSearch :itemprop="item" />
  </div>
</template>

<script>
import PopUpDataTable from "@/own/components/warehouseManagement/standardQualityCheck/PopUpDataTable.vue";
import PopUpTextGroup from "@/own/components/warehouseManagement/standardQualityCheck/PopUpTextGroup.vue";
import PopUpDataTableWithSearch from "@/own/components/datatable/popup/PopUpDataTableWithSearch.vue";
export default {
  name: "PopUpSelector",
  props: ["item"],
  components: {
    PopUpDataTableWithSearch,
    PopUpTextGroup,
    PopUpDataTable,
  },
};
</script>

<style></style>
