<template>
  <div v-if="value">
    <a
      href="#"
      class="btn btn-light-linkedin btn-sm px-4 ls1 text-no-wrap"
      @click="toggleModal"
      >{{ value.title }}</a
    >
    <div>
      <v-dialog
        v-model="dialog"
        transition="dialog-bottom-transition"
        persistent
        max-width="1000px"
      >
        <div class="bg-white poppins">
          <div class="modal-header align-center">
            <h3 class="mb-0 font-weight-bolder">{{ value.title }}</h3>
            <div
              class="btn btn-sm btn-icon btn-active-light-info"
              @click="dialog = false"
            >
              <span class="svg-icon">
                <v-icon size="22">mdi-close</v-icon>
              </span>
            </div>
          </div>
          <div
            class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7"
          >
            <!--begin::Body-->
            <div class="pa-1">
              <div
                v-for="(item, index) in value.data"
                :key="index"
                class="mb-4"
              >
                <h5 class="text-capitalize">{{ index }}</h5>
                <div v-if="item">{{ item }}</div>
                <div v-else>{{ $t("no_data") }}</div>
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
/**
 * show html content on pop up
 */
export default {
  name: "ShowHtmlPopUp",
  props: ["value"],
  data: () => ({
    dialog: false,
  }),

  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
