<template>
  <div v-if="selectedWarehouse" class="bg-white rounded-lg">
    <!-- page header -->
    <v-col cols="12" class="mt-4">
      <div class="card-header flex-wrap border-0 ma-0 pa-0">
        <div class="card-title ma-0 pa-0">
          <h1
            class="poppins card-label pl-4"
            :class="{ 'text-right pr-4': $vuetify.rtl }"
          >
            <span
              class="text-dark-65 font-size-h2 display4-md font-weight-bolder"
            >
              {{ $t(table_properties.title) }}
            </span>
            <span class="d-block text-dark-50 mt-1 font-size-lg">{{
              $t(table_properties.description)
            }}</span>
          </h1>
        </div>
      </div>
    </v-col>
    <!-- form -->
    <v-row class="mt-5 ml-2">
      <v-col lg="8">
        <BasicInfo />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { UPDATE_CUSTOMER_DATA } from "@/core/services/store/standardPackageMovement.module.js";
import BasicInfo from "@/own/components/warehouseManagement/StandardPackageMovement/BasicInfoForm.vue";

export default {
  name: "StandardMovement",
  components: { BasicInfo },
  data: () => ({
    table_properties: {
      title: "Standard Package Movement",
      description: "Manage standard package movement",
    },
  }),
  beforeMount() {
    if (this.selectedWarehouse) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.initFormContent();
    }
  },
  methods: {
    async initFormContent() {
      await this.$store.dispatch(UPDATE_CUSTOMER_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  watch: {
    selectedWarehouse() {
      if (this.selectedWarehouse) {
        this.initFormContent();
      }
    },
  },
};
</script>
