<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-600px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="min-h-75px">
          <v-autocomplete
            v-model="formData.customer_id"
            dense
            label="Customer"
            item-text="text"
            item-value="index"
            clearable
            outlined
            :items="customers"
            hide-no-data
            hide-selected
            :error-messages="customerIdErrors"
            @blur="$v.formData.customer_id.$touch()"
          />
        </div>

        <div class="min-h-75px">
          <v-text-field
            v-model="formData.name"
            label="Name"
            dense
            outlined
            clearable
            :error-messages="nameErrors"
            @blur="$v.formData.name.$touch()"
          />
        </div>

        <div class="min-h-75px">
          <v-autocomplete
            v-model="formData.status"
            label="Status"
            item-text="text"
            item-value="index"
            dense
            outlined
            clearable
            :items="statusValues"
            hide-no-data
            hide-selected
            :error-messages="statusErrors"
            @blur="$v.formData.status.$touch()"
          />
        </div>

        <div class="min-h-75px">
          <v-combobox
            v-model="formData.checklist_skus"
            :items="skus"
            item-text="systemCode"
            item-value="skuId"
            label="SKUS"
            multiple
            dense
            outlined
            clearable
            small-chips
            deletable-chips
            :loading="isLoading"
            :search-input.sync="search"
            hide-no-data
            hide-selected
            :error-messages="checklistSkusErrors"
            @blur="$v.formData.checklist_skus.$touch()"
          />
        </div>

        <div class="mt-4 pa-0 w-100">
          <InstructionDatatable />
        </div>

        <!--begin::Actions-->
        <div
          class="my-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import InstructionDatatable from "@/own/components/warehouseManagement/qcChecklist/InstructionDatatable";
import {
  SET_CHECKLIST_INSTRUCTIONS,
  GET_CLIENTS_AND_QC_CATEGORIES,
} from "@/core/services/store/qcChecklists.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getToken } from "@/core/services/jwt.service";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { InstructionDatatable },
  validations: {
    formData: {
      customer_id: { required },
      name: { required },
      status: { required },
      checklist_skus: { required },
    },
  },
  data: () => ({
    dialog: false,
    entries: [],
    search: null,
    isLoading: false,
    formData: {
      id: null,
      customer_id: null,
      name: null,
      status: null,
      checklist_skus: [],
    },
  }),
  methods: {
    loadDataFromServer() {
      this.$store.dispatch(GET_CLIENTS_AND_QC_CATEGORIES);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/warehouse_management/qc_checklists/edit", sendData)
          .then(({ data }) => {
            this.formData = data.checklist;
            data.skus.forEach((item, index) => {
              this.formData.checklist_skus[index] = {
                systemCode: item.text,
                skuId: item.id,
              };
            });
            this.$store.commit(
              SET_CHECKLIST_INSTRUCTIONS,
              data.checklist.checklist_instructions
            );
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else this.$store.commit(SET_CHECKLIST_INSTRUCTIONS, []);
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitForm() {
      let sendData = {
        ...this.formData,
        checklist_skus: this.formData.checklist_skus.map((item) => item.skuId),
        checklist_instructions: this.$store.getters.getQCCHECKLISTSInstructions,
      };
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      this.pageLoader(true);
      ApiService.post(
        `/warehouse_management/qc_checklists/${actionUrl}`,
        sendData
      )
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async getData(value) {
      const requestBody = { q: value };
      if (this.formData.customer_id)
        requestBody.client = this.formData.customer_id;

      await fetch(`${process.env.VUE_APP_BASE_URL}/skus/search`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((res) => res.json())
        .then((res) => {
          this.entries = res.skus;
        })
        .finally(() => (this.isLoading = false));
    },
    resetFormData() {
      this.formData = {
        customer_id: null,
        name: null,
        status: null,
        checklist_skus: [],
      };
    },
    exportChecklist(item) {
      const payload = {
        id: item.id,
        type: "instructions",
      };
      ApiService.post(
        "warehouse_management/qc_checklists/export_instructions",
        payload
      )
        .then(({ data }) => {
          this.$store.commit(SET_PAGE_LOADING, true);
          const link = document.createElement("a");
          link.href = data.url;
          link.download = "QC_Checklist.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          link.remove();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
  },
  computed: {
    //if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    customers: function () {
      return this.$store.getters.getQCCHECKLISTSClients;
    },
    skus: function () {
      return this.entries;
    },
    statusValues: function () {
      return this.$store.getters.getQCCHECKLISTSStatus;
    },
    customerIdErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    checklistSkusErrors: function () {
      return this.handleFormValidation("checklist_skus");
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;
      this.getData(val);
    },
  },
};
</script>
