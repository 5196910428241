import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getB2BPACKINGPICKINGTableStructure";
export const UPDATE_TABLE_DATA = "getB2BPACKINGPICKINGTableData";
export const UPDATE_CONFIRM_ORDER_DATA = "getB2BPACKINGPICKINGConfirmOrderData";
export const UPDATE_PICKING_PACKING_TASKS = "getB2BPACKINGPICKINGTasks";
export const UPDATE_CREATE_BOX_DATA = "getB2BPACKINGPICKINGCreateBoxData";
export const UPDATE_EDIT_BOX_DATA = "getB2BPACKINGPICKINGEditBoxData";

// mutation types
export const SET_TABLE_STRUCTURE = "setB2BPACKINGPICKINGTableStructure";
export const SET_TABLE_DATA = "setB2BPACKINGPICKINGTableData";
export const SET_TABLE_FILTER = "setB2BPACKINGPICKINGTableFilter";
export const SET_TABLE_PAGE = "setB2BPACKINGPICKINGTablePage";
export const SET_TABLE_PERPAGE = "setB2BPACKINGPICKINGTablePerPage";
export const SET_TABLE_SORTBY = "setB2BPACKINGPICKINGTableSortBy";
export const SET_PICKING_PACKING_TASKS = "setB2BPACKINGPICKINGTasks";
export const SET_PICKING_PACKING_BOXES = "setB2BPACKINGPICKINGBoxes";
export const SET_CREATE_BOX_DATA = "setB2BPACKINGPICKINGCreateBoxData";
export const SET_SYSTEM_ORDER_NUMBER = "setB2BPACKINGPICKINGSystemOrderNumber";
export const SET_PRINT_TYPES = "setB2BPACKINGPICKINGPrintTypes";
export const SET_ORDER_SYSTEM_ID = "setB2BPACKINGPICKINGOrderSystemId";
export const SET_SUGGESTED_ITEM = "setB2BPACKINGPICKINGSuggestedItem";
export const SET_ORDER_SUMMARY = "setB2BPACKINGPICKINGOrderSummary";
export const SET_ADD_BOX_ACTION_TYPE = "setB2BPACKINGPICKINGAddBoxActionType";
export const SET_SKU_FOR_EDIT = "setB2BPACKINGPICKINGSkuForEdit";
export const SET_CHECK_WITH_MASTER = "setB2BPACKINGPICKINGSetCheckWithMaster";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  confirm_order: {},
  boxes: [],
  tasks: [],
  create_box: {},
  system_order_number: null,
  order_system_id: null,
  suggested_item: null,
  order_summary: null,
  add_box_action_type: "create",
  skus_for_edit: [],
  is_check_with_master: false,
};

const getters = {
  /**
   * for table title, descripton
   * */
  getB2BPACKINGPICKINGTableProperties(state) {
    return state.table_structure.properties;
  },
  /**
   * for table permissions
   **/
  getB2BPACKINGPICKINGTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getB2BPACKINGPICKINGTableState(state) {
    return state.table_state;
  },
  getB2BPACKINGPICKINGTableHeaders(state) {
    return state.table_structure.columns;
  },
  getB2BPACKINGPICKINGTableFilters(state) {
    return state.table_structure.filters;
  },
  getB2BPACKINGPICKINGTableData(state) {
    return state.table_data.data;
  },
  getB2BPACKINGPICKINGBoxesData(state) {
    return state.boxes;
  },
  getB2BPACKINGPICKINGTasksData(state) {
    return state.tasks;
  },
  getB2BPACKINGPICKINGCreateBoxInfo(state) {
    return state.create_box;
  },
  getB2BPACKINGPICKINGSystemOrderNumber(state) {
    return state.system_order_number;
  },
  getB2BPACKINGPICKINGOrderSystemId(state) {
    return state.order_system_id;
  },
  getB2BPACKINGPICKINGPrintTypes(state) {
    return state.table_structure.print_labels;
  },
  getB2BPACKINGPICKINGSuggestedItem(state) {
    return state.suggested_item;
  },
  getB2BPACKINGPICKINGOrderSummary(state) {
    return state.order_summary;
  },
  getB2BPACKINGPICKINGAddBoxActionType(state) {
    return state.add_box_action_type;
  },
  getB2BPACKINGPICKINGSkuForEdit(state) {
    return state.skus_for_edit;
  },
  getB2BPACKINGPICKINGIsCheckWithMaster(state) {
    return state.is_check_with_master;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    // console.log(payload);
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/b2b_pick_and_pack")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/b2b_pick_and_pack/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  async [UPDATE_PICKING_PACKING_TASKS](context, payload) {
    try {
      context.commit(SET_PAGE_LOADING, true);
      const { data } = await ApiService.post(
        `/warehouse_management/b2b_pick_and_pack/show`,
        payload
      );
      context.commit(SET_PICKING_PACKING_TASKS, data.tasks);
      context.commit(SET_PICKING_PACKING_BOXES, data.boxes);
      context.commit(SET_ORDER_SUMMARY, data.order_summary);
      context.commit(SET_CHECK_WITH_MASTER, data.is_check_with_master);

      return true;
    } catch {
      return false;
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  [UPDATE_CREATE_BOX_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    ApiService.post(`/warehouse_management/b2b_pick_and_pack/create`, payload)
      .then(({ data }) => {
        context.commit(SET_CREATE_BOX_DATA, data.box);
      })
      .finally(() => {
        context.commit(SET_PAGE_LOADING, false);
      });
  },
  [UPDATE_EDIT_BOX_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    ApiService.post(`/warehouse_management/b2b_pick_and_pack/edit_box`, payload)
      .then(({ data }) => {
        context.commit(SET_CREATE_BOX_DATA, data.box);
        const skus = data.skus.map((item) => {
          return {
            sku_barcode: item.sku,
            qty: item.qty,
            location: item.location,
            order_box_id: item.box_id,
          };
        });
        context.commit(SET_SKU_FOR_EDIT, skus);
      })
      .finally(() => {
        context.commit(SET_PAGE_LOADING, false);
      });
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_PICKING_PACKING_TASKS](state, tasks) {
    state.tasks = tasks;
  },
  [SET_PICKING_PACKING_BOXES](state, boxes) {
    state.boxes = boxes;
  },
  [SET_CREATE_BOX_DATA](state, data) {
    state.create_box = data;
  },
  [SET_SYSTEM_ORDER_NUMBER](state, data) {
    state.system_order_number = data;
  },
  [SET_ORDER_SYSTEM_ID](state, data) {
    state.order_system_id = data;
  },
  [SET_SUGGESTED_ITEM](state, item) {
    state.suggested_item = item;
  },
  [SET_ORDER_SUMMARY](state, summary) {
    state.order_summary = summary;
  },
  [SET_ADD_BOX_ACTION_TYPE](state, type) {
    state.add_box_action_type = type;
  },
  [SET_SKU_FOR_EDIT](state, skus) {
    state.skus_for_edit = skus;
  },
  [SET_CHECK_WITH_MASTER](state, value) {
    state.is_check_with_master = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
