<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      :urls="urls"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import ActionsDialog from "@/own/components/settings/emailReports/ActionsDialog.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PER_PAGE,
  SET_TABLE_SORT_BY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/emailReports.module";
import {
  SET_FILTERS_DATA,
  SET_SKIP_ADDING_WAREHOUSE,
} from "@/core/services/store/filter.module";

export default {
  name: "EmailReports",
  components: { DataTable },
  data: () => ({
    componentForEditing: ActionsDialog,
    componentForAdding: ActionsDialog,
    delete_url: `${process.env.VUE_APP_BASE_URL}/settings/email_reports/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORT_BY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PER_PAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getSETTEMAILREPORTSTableFilters",
      getTableState: "getSETTEMAILREPORTSTableState",
      getTableData: "getSETTEMAILREPORTSTableData",
      getTableHeaders: "getSETTEMAILREPORTSTableHeaders",
      getTableProperties: "getSETTEMAILREPORTSTableProperties",
      getTableExportUrl: "getSETTEMAILREPORTSExportUrl",
      getTablePermissions: "getSETTEMAILREPORTSTablePermissions",
    },
    urls: {
      status: `${process.env.VUE_APP_BASE_URL}/settings/email_reports/status`,
    },
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store.commit(SET_FILTERS_DATA, {});
    this.$store.commit(SET_SKIP_ADDING_WAREHOUSE, true);
    this.initTableContent();
  },
  beforeDestroy() {
    this.$store.commit(SET_FILTERS_DATA, {});
    this.$store.commit(SET_SKIP_ADDING_WAREHOUSE, false);
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      // if (this.selectedWarehouse) {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
      // } else {
      //   this.$store.commit(SET_PAGE_LOADING, false);
      // }
    },
  },

  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
