import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getCYCLECOUNTSTableStructure";
export const UPDATE_TABLE_DATA = "updateCYCLECOUNTSTableData";
export const EXPORT_TABLE_DATA = "exportCYCLECOUNTSTableData";
export const CREATE_CYCLE_COUNT = "createCYCLECOUNTS";
export const CLOSE_CYCLE_COUNT = "updateCYCLECOUNTSCloseCycleCount";
export const STORE_CYCLE_COUNT = "updateCYCLECOUNTSStoreCount";
export const UPDATE_RECOUNT_TASK = "updateCYCLECOUNTSRecountTask";
export const UPDATE_ASSIGN_USER = "updateCYCLECOUNTSAssignUser";
export const UPDATE_EXPORT_TASKS = "updateCYCLECOUNTSExportTasks";
export const UPDATE_CYCLE_COUNT_TASK_DETAILS = "updateCYCLECOUNTSTaskDetails";

// mutation types
export const SET_TABLE_STRUCTURE = "setCYCLECOUNTSTableStructure";
export const SET_TABLE_DATA = "setCYCLECOUNTSTableData";
export const SET_TABLE_FILTER = "setCYCLECOUNTSTableFilter";
export const SET_TABLE_PAGE = "setCYCLECOUNTSTablePage";
export const SET_TABLE_PERPAGE = "setCYCLECOUNTSTablePerPage";
export const SET_TABLE_SORTBY = "setCYCLECOUNTSTableSortBy";
export const SET_TABLE_SORTORDER = "setCYCLECOUNTSTableSortOrder";
export const SET_EXPORT_LINK = "setCYCLECOUNTSExportLink";
export const SET_ZONES = "setCYCLECOUNTSSetZones";
export const SET_CLIENTS = "setCYCLECOUNTSSetClients";
export const SET_ORGANIZATIONS = "setCYCLECOUNTSSetOrganizations";
export const SET_ACTION_TYPE = "setCYCLECOUNTSSetActionType";
export const SET_TASK_EXPORT_LINK = "setCYCLECOUNTSTaskExportLink";
export const SET_CYCLE_COUNT_TASK_DETAILS = "setCYCLECOUNTSTaskDetails";
export const SET_SUGGESTED_DETAILS = "setCYCLECOUNTSSuggestedDetails";
export const SET_SCANNED_ITEMS = "setCYCLECOUNTSScannedItems";

const state = {
  export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
  completed_count_tasks: [],
  zones: [],
  clients: [],
  organizations: [],
  action_type: "recount",
  task_export_link: null,
  task_details: null,
  suggestions: {},
  scanned_items: [],
};

const getters = {
  getCYCLECOUNTSTableProperties(state) {
    return state.table_structure.properties;
  },
  getCYCLECOUNTSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getCYCLECOUNTSTableState(state) {
    return state.table_state;
  },
  getCYCLECOUNTSTableHeaders(state) {
    return state.table_structure.columns;
  },
  getCYCLECOUNTSTableFilters(state) {
    return state.table_structure.filters;
  },
  getCYCLECOUNTSTableData(state) {
    return state.table_data.data;
  },
  getCYCLECOUNTSExportUrl(state) {
    return state.export_link;
  },
  getCYCLECOUNTSUsers(state) {
    return state.table_structure.users;
  },
  getCYCLECOUNTSZones(state) {
    return state.zones;
  },
  getCYCLECOUNTSClients(state) {
    return state.clients;
  },
  getCYCLECOUNTSOrganizations(state) {
    return state.organizations;
  },
  getCYCLECOUNTSActionType(state) {
    return state.action_type;
  },
  getCYCLECOUNTSTaskExportUrl(state) {
    return state.task_export_link;
  },
  getCYCLECOUNTSTaskDetails(state) {
    return state.task_details;
  },
  getCYCLECOUNTSSuggestedDetails(state) {
    return state.suggestions;
  },
  getCYCLECOUNTSScannedItems(state) {
    return state.scanned_items;
  },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/warehouse_management/custom/cycle_counts")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/custom/cycle_counts/datatable",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);
          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [EXPORT_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(
        "/warehouse_management/custom/cycle_counts/export",
        payload
      )
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  async [CREATE_CYCLE_COUNT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const { data } = await ApiService.post(
        "/warehouse_management/custom/cycle_counts/create",
        payload
      );
      context.commit(SET_ZONES, data.zones);
      context.commit(SET_CLIENTS, data.clients);
      context.commit(SET_ORGANIZATIONS, data.organizations);
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  async [STORE_CYCLE_COUNT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const res = await ApiService.post(
        "/warehouse_management/custom/cycle_counts/store",
        payload
      );
      return res;
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  async [UPDATE_RECOUNT_TASK](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      await ApiService.post(
        "/warehouse_management/custom/cycle_counts/recount",
        payload
      );
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  async [UPDATE_ASSIGN_USER](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      await ApiService.post(
        "/warehouse_management/custom/cycle_counts/assign_user",
        payload
      );
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  async [UPDATE_EXPORT_TASKS](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const { data } = await ApiService.post(
        "/warehouse_management/custom/cycle_counts/export_cycle_count_tasks",
        { id: payload }
      );
      context.commit(SET_TASK_EXPORT_LINK, data.url);
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  async [CLOSE_CYCLE_COUNT](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const { data } = await ApiService.post(
        "/warehouse_management/custom/cycle_counts/close",
        payload
      );
      return data;
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
  async [UPDATE_CYCLE_COUNT_TASK_DETAILS](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    try {
      const { data } = await ApiService.post(
        "/warehouse_management/custom/cycle_counts/show",
        { id: payload }
      );
      context.commit(SET_CYCLE_COUNT_TASK_DETAILS, data.standard_cycle_count);
    } finally {
      context.commit(SET_PAGE_LOADING, false);
    }
  },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.export_link = link;
  },
  [SET_ZONES](state, zones) {
    state.zones = zones;
  },
  [SET_ORGANIZATIONS](state, organizations) {
    state.organizations = organizations;
  },
  [SET_CLIENTS](state, clients) {
    state.clients = clients;
  },
  [SET_ACTION_TYPE](state, type) {
    state.action_type = type;
  },
  [SET_TASK_EXPORT_LINK](state, link) {
    state.task_export_link = link;
  },
  [SET_CYCLE_COUNT_TASK_DETAILS](state, data) {
    state.task_details = data;
  },
  [SET_SUGGESTED_DETAILS](state, data) {
    if (data.operation === "spread") {
      state.suggestions = { ...state.suggestions, ...data.info };
    } else {
      state.suggestions = data.info;
    }
  },
  [SET_SCANNED_ITEMS](state, data) {
    if (data.operation === "spread") {
      state.scanned_items = { ...state.scanned_items, ...data.data };
    } else {
      state.scanned_items = data.data;
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
