<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div
        id="kt_wrapper"
        class="d-flex flex-column flex-row-fluid wrapper"
        :class="{
          'wrapper-rtl': $vuetify.rtl,
          'wrapper-ltr': !$vuetify.rtl,
        }"
      >
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
              <PageLoader :mode="page_loading" />
              <ImportReport />
              <ResultModal />
              <v-app class="rounded-lg">
                <NoWarehousePopup ref="noWarehousePopup" />
                <transition name="fade-in-up">
                  <router-view />
                </transition>
              </v-app>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeader from "@/view/layout/header/Header.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import PageLoader from "@/own/components/PageLoader.vue";
import ImportReport from "@/own/components/ImportReport.vue";
import ResultModal from "@/own/components/Modals/ResultModal.vue";
import NoWarehousePopup from "@/own/components/NoWarehousePopup.vue";
import { ROUTES } from "@/core/constants/warehouseSpecificRoutes";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    PageLoader,
    ImportReport,
    ResultModal,
    NoWarehousePopup,
  },
  data: () => ({
    warehouseRoutes: ROUTES,
  }),
  beforeMount() {
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    } else {
      setTimeout(() => {
        this.checkRouteChange(this.$route.path);
      }, 500);
    }
  },
  watch: {
    $route(to) {
      this.checkRouteChange(to.path);
    },
  },
  methods: {
    checkRouteChange(route) {
      if (this.warehouseRoutes.includes(route) && !this.selectedWarehouse) {
        this.$refs.noWarehousePopup.dialog = true;
        this.$refs.noWarehousePopup.setOverlayPlacement();
      } else {
        this.$refs.noWarehousePopup.dialog = false;
      }
    },
  },
  computed: {
    page_loading: function () {
      return this.$store.getters.getPageLoading;
    },
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return false;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
};
</script>
<style scoped>
@media (min-width: 991px) {
  .wrapper-rtl {
    padding-left: 0px !important;
    padding-right: 70px !important;
  }
  .wrapper-ltr {
    padding-left: 70px !important;
    padding-right: 0px !important;
  }
}
</style>
