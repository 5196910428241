import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// action types
export const GET_REPORTDATATABLE_DATA = "getREPORTDATATABLEData";
export const SHOW_REPORTDATATABLE_DATA = "showREPORTDATATABLEData";
export const EXPORT_REPORTDATATABLE_DATA = "exportREPORTDATATABLEData";

// mutation types
export const SET_REPORTDATATABLE_PROPERTIES = "setREPORTDATATABLEProperties";
export const SET_REPORTDATATABLE_HAS_EXPORT = "setREPORTDATATABLEHasExport";
export const SET_REPORTDATATABLE_FILTERS = "setREPORTDATATABLEFilters";
export const SET_REPORTDATATABLE_FILTERS_RESULT =
  "setREPORTDATATABLEFiltersResult";
export const SET_EXPORT_LINK = "setREPORTDATATABLEExportLink";
export const SET_OTHER_TABLE_DATA = "setREPORTDATATABLEOTHER_TABLE_DATA";
const state = {
  properties: {},
  hasExport: false,
  exportLink: undefined,
  filters: [
    {
      name: null,
      title: null,
      icon: null,
      type: null,
      related_columns: [],
      values: [],
    },
    {
      name: null,
      title: null,
      type: null,
      related_columns: [],
    },
  ],
  filtersResult: {
    header: [],
    body: [],
  },
  otherTableData: {
    dateLabel: "Report",
    name: "reverseLogistics",
    title: "Returns",
    type: "datatable",
  },
};

const getters = {
  /**
   * for table title, description
   * */
  getREPORTDATATABLEProperties(state) {
    return state.properties;
  },
  getREPORTDATATABLEHasExport(state) {
    return state.hasExport;
  },
  getREPORTDATATABLEExportUrl(state) {
    return state.exportLink;
  },
  getREPORTDATATABLEFilters(state) {
    return state.filters;
  },
  getREPORTDATATABLEDATATABLEResultHeaders(state) {
    return state.filtersResult.header;
  },
  getREPORTDATATABLEDATATABLEResultBody(state) {
    return state.filtersResult.body;
  },
  getREPORTDATATABLETitlesOfSimpleDataTables(state) {
    return state.otherTableData;
  },
};

const actions = {
  [GET_REPORTDATATABLE_DATA](context, url) {
    return new Promise((resolve) => {
      context.commit(SET_PAGE_LOADING, true);
      ApiService.post(`${url}`)
        .then(({ data }) => {
          context.commit(SET_REPORTDATATABLE_PROPERTIES, data.data.properties);
          context.commit(SET_REPORTDATATABLE_FILTERS, data.data.filters);
          data.data?.export && context.commit(SET_REPORTDATATABLE_HAS_EXPORT);
          resolve(data.data);
        })
        .catch((data) => {
          resolve(data.data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [SHOW_REPORTDATATABLE_DATA](context, { url, filtersData }) {
    return new Promise((resolve) => {
      context.commit(SET_PAGE_LOADING, true);
      ApiService.post(`${url}`, {
        filters: filtersData,
      })
        .then(({ data }) => {
          context.commit(
            SET_REPORTDATATABLE_FILTERS_RESULT,
            data.charts[0].values
          );
          context.commit(SET_OTHER_TABLE_DATA, data.charts[0]);
          resolve(data.charts);
        })
        .catch(({ data }) => {
          resolve(data);
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  [EXPORT_REPORTDATATABLE_DATA](context, { dataUrl, payload }) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post(`${dataUrl}/export`, payload)
        .then(({ data }) => {
          context.commit(SET_EXPORT_LINK, data.url);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
};

const mutations = {
  [SET_REPORTDATATABLE_PROPERTIES](state, properties) {
    state.properties = properties;
  },
  [SET_REPORTDATATABLE_HAS_EXPORT](state) {
    state.hasExport = true;
  },
  [SET_REPORTDATATABLE_FILTERS](state, filters) {
    state.filters = filters;
  },
  [SET_REPORTDATATABLE_FILTERS_RESULT](state, data) {
    state.filtersResult = data;
  },
  [SET_EXPORT_LINK](state, link) {
    state.exportLink = link;
  },
  [SET_OTHER_TABLE_DATA](state, data) {
    state.otherTableData = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
