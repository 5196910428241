<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    transition="dialog-bottom-transition"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          {{ detail_data.store.name }} - Payment Gateways
        </h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <show-detail-item
          v-for="(item, key) in detail_data.gateways"
          :key="key"
          :detail="item"
        ></show-detail-item>
        <!--end::Body-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ShowDetailItem from "./ShowDetailItem";

export default {
  name: "Others",
  components: {
    ShowDetailItem,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    detail_data: function () {
      return this.$store.getters.getPAYMENTSDetailData;
    },
  },
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.isLoaded = false;
        this.dialog = !this.dialog;
      } else {
        this.dialog = !this.dialog;
      }
    },
  },
};
</script>

<style scoped>
div {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}
.other_key {
  margin: 2px;
}
.other_value {
  font-weight: bold;
  margin: 2px;
  margin-left: 6px;
}
.main {
  background-color: rgb(245, 245, 245);
}
</style>
