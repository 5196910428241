<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary ma-0 px-2" text v-bind="attrs" v-on="on">
        {{ item.title }}
      </v-btn>
    </template>
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ item.values.title }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-simple-table class="pt-2">
          <template v-slot:default>
            <thead>
              <th
                class=""
                v-for="(head, i) in item.values.data.header"
                :key="i"
              >
                {{ head }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(data, index) in item.values.data.body" :key="index">
                <td v-for="(el, j) in data" :key="j">{{ el }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "PopUpDatatable",
  props: ["item"],
  data: () => ({
    dialog: false,
  }),
};
</script>
