<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit App</h3>
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="h-75px">
          <v-text-field
            v-model="formData.name"
            label="Name"
            required
            outlined
            dense
            :error-messages="nameErrors"
            @blur="$v.formData.name.$touch()"
          ></v-text-field>
        </div>
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.client"
            :items="serverData.clients"
            solo
            item-name="text"
            item-value="index"
            dense
            clearable
            label="Customer"
          ></v-autocomplete>
        </div>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn--export-filter px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
export default {
  name: "EditItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      name: null,
      client: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/authorizations/apps/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData.app };
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.pageLoader(true);
      ApiService.post("/authorizations/apps/update", this.formData)
        .then(() => {
          swalEdited();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        name: null,
        client: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name");
    },
  },
};
</script>
