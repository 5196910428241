<template>
  <div
    style="
      display: flex;
      flex-flow: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    "
  >
    <!--    <div class="py-4 text-h4">Storage Form</div>-->
    <form id="createForm" class="pa-3 pt-0 pb-0 row elevation-0">
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.unit_id"
          label="Unit"
          item-text="text"
          item-value="index"
          :items="serverData.storage_units"
          clearable
          outlined
          disabled
          :error-messages="unit_idErrors"
          @input="$v.formData.unit_id.$touch()"
          @blur="$v.formData.unit_id.$touch()"
        ></v-select>
      </div>
      <div
        v-if="formData.unit_id == 5"
        class="col-sm-6 col-md-6 col-lg-4 col-12"
      >
        <v-text-field
          v-model="formData.number_of_bins"
          label="Number of bins"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('number_of_bins', 0, 'formData')"
          min="0"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-select
          v-model="formData.warehouse_id"
          label="Warehouse"
          item-text="text"
          item-value="index"
          :items="serverData.warehouses"
          clearable
          outlined
          disabled
          :error-messages="warehouse_idErrors"
          @input="$v.formData.warehouse_id.$touch()"
          @blur="$v.formData.warehouse_id.$touch()"
        ></v-select>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.rate"
          label="Rate"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('rate', 0, 'formData')"
          min="0"
          disabled
          :error-messages="rateErrors"
          @input="$v.formData.rate.$touch()"
          @blur="$v.formData.rate.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.tax_percentage"
          label="Tax percentage"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('tax_percentage', 0, 'formData')"
          min="0"
          disabled
          :error-messages="tax_percentageErrors"
          @input="$v.formData.tax_percentage.$touch()"
          @blur="$v.formData.tax_percentage.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.minimum_charge"
          label="Minimum charge"
          clearable
          outlined
          @change="() => validateMinValue('minimum_charge', 0, 'formData')"
          type="Number"
          min="0"
          disabled
          :error-messages="minimum_chargeErrors"
          @input="$v.formData.minimum_charge.$touch()"
          @blur="$v.formData.minimum_charge.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.buffer"
          label="Buffer"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('buffer', 0, 'formData')"
          min="0"
          max="100"
          :error-messages="bufferErrors"
          @input="$v.formData.buffer.$touch()"
          @blur="$v.formData.buffer.$touch()"
        ></v-text-field>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4 col-12">
        <v-text-field
          v-model="formData.duration"
          label="Duration"
          clearable
          outlined
          type="Number"
          @change="() => validateMinValue('duration', 0, 'formData')"
          min="0"
          :placeholder="durationPlaceholder"
          :error-messages="durationErrors"
          @input="$v.formData.duration.$touch()"
          @blur="$v.formData.duration.$touch()"
        ></v-text-field>
      </div>
      <div class="col-12 d-flex flex-wrap justify-center align-center">
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-radio-group label="Discount" v-model="formData.discount_type">
            <v-radio label="Flat rate" :value="0"></v-radio>
            <v-radio label="Percentage" :value="1"></v-radio>
          </v-radio-group>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 col-12">
          <v-text-field
            v-model="formData.discount"
            :label="discountValueLabel"
            clearable
            outlined
            type="Number"
            @change="() => validateMinValue('discount', 0, 'formData')"
            min="0"
            :error-messages="discountErrors"
            @input="$v.formData.discount.$touch()"
            @blur="$v.formData.discount.$touch()"
          ></v-text-field>
        </div>
      </div>

      <v-main class="text-center mt-2 col-12 elevation-0">
        <v-btn class="mx-1" color="primary" @click="submitCreateForm">
          submit
        </v-btn>
        <v-btn class="mx-1" @click="resetCreateForm"> clear </v-btn>
        <v-btn class="mx-1" @click="goBack"> back </v-btn>
      </v-main>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "StorageForm.vue",
  mixins: [validationMixin, fieldValueValidation],
  props: ["setData", "actionFuntion", "serverData", "pageLoader", "initData"],
  validations: {
    formData: {
      unit_id: { required },
      warehouse_id: { required },
      rate: { required },
      tax_percentage: { required },
      duration: { required },
      minimum_charge: { required },
      buffer: { required },
      discount: { required },
    },
  },
  data: () => ({
    formData: {
      id: null,
      unit_id: null,
      warehouse_id: null,
      rate: null,
      tax_percentage: null,
      duration: 1,
      minimum_charge: null,
      buffer: null,
      discount_type: 0,
      discount: null,
      number_of_bins: null,
    },
  }),
  methods: {
    goBack() {
      this.actionFuntion("back");
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        const data = { ...this.formData };
        this.setData(data);
        this.actionFuntion();
        this.resetCreateForm();
      }
    },
    resetCreateForm() {
      // console.log("reset create form");
      this.$v.$reset();
      if (this.initData) {
        try {
          let copy = { ...this.serverData.values };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(this.serverData.values).forEach((serverData) => {
              if (localData[0] == serverData[0]) {
                this.formData[localData[0]] = copy[serverData[0]];
              }
            });
          });
        } catch (e) {
          // console.error(e);
          this.formData = {
            id: null,
            unit_id: null,
            warehouse_id: null,
            rate: null,
            tax_percentage: null,
            duration: 1,
            minimum_charge: null,
            buffer: null,
            discount_type: 0,
            discount: null,
            number_of_bins: null,
          };
        }
      } else {
        this.formData = {
          id: null,
          unit_id: null,
          warehouse_id: null,
          rate: null,
          tax_percentage: null,
          duration: 1,
          minimum_charge: null,
          buffer: null,
          discount_type: 0,
          discount: null,
          number_of_bins: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    discountValueLabel() {
      return this.formData.discount_type == 0 ? "Flat rate" : "Percentage";
    },
    durationPlaceholder() {
      return this.serverData.duration;
    },
    unit_idErrors() {
      return this.handleFormValidation("unit_id", this);
    },
    warehouse_idErrors() {
      return this.handleFormValidation("warehouse_id", this);
    },
    rateErrors() {
      return this.handleFormValidation("rate", this);
    },
    tax_percentageErrors() {
      return this.handleFormValidation("tax_percentage", this);
    },
    durationErrors() {
      return this.handleFormValidation("duration", this);
    },
    minimum_chargeErrors() {
      return this.handleFormValidation("minimum_charge", this);
    },
    bufferErrors() {
      return this.handleFormValidation("buffer", this);
    },
    discountErrors() {
      return this.handleFormValidation("discount", this);
    },
  },
};
</script>
