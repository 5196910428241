<template>
  <div>
    <a
      href="#"
      class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
      @click="showBody"
      >{{ $t("view") }}</a
    >
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="1000px"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder text-uppercase">
            {{ header.value }} {{ $t("content") }}
          </h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->

          <b-tabs
            v-model="tabIndex"
            fixed-tabs
            background-color="indigo"
            dark
            no-key-nav
            center-active
          >
            <b-tab
              :title="item.text"
              v-for="(item, index) in languages"
              :key="item.index + index"
            >
              <div
                v-html="itemForAction[item.index]"
                v-if="itemForAction[item.index]"
                class="tab-box m-3"
              ></div>
            </b-tab>
          </b-tabs>
          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ShowCustomContent",
  props: ["itemForAction", "item", "header"],
  data: () => ({
    dialog: false,
    tabIndex: 0,
  }),
  computed: {
    languages: function () {
      return this.item.store_group.languages;
    },
  },
  methods: {
    showBody() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
