<template>
  <v-dialog
    v-model="dialog"
    :max-width="table.header && table.header.length < 6 ? 850 : 1050"
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }" v-if="table.header">
      <button
        class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
        @click="toggleModal"
      >
        {{ $t("view") }}
      </button>
    </template>
    <div class="bg-white poppins">
      <div class="modal-header py-4 ps-8 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ $t(`${header}`) }}</h4>
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <v-card-text class="px-8 pt-6 pb-0">
        <div class="table-responsive">
          <table
            v-if="table.header"
            class="table table-head-custom table-vertical-center table-head-bg table-row-bordered poppins"
          >
            <thead>
              <tr>
                <th
                  class="pl-3"
                  v-for="(item, index) in table.header"
                  :key="index"
                >
                  <span class="text-dark-75">{{ item }}</span>
                </th>
              </tr>
            </thead>
            <tbody v-if="table.body.length > 0">
              <tr v-for="(item, index) in table.body" :key="index">
                <td
                  class="px-3 text-dark-65 font-weight-bolder font-size-lg"
                  v-for="(col, colIndex) in item"
                  :key="colIndex"
                >
                  {{ col }}
                </td>
              </tr>
            </tbody>
            <caption
              v-else
              class="mt-2 text-dark-50 text-center font-weight-bolder font-size-lg"
            >
              {{
                $t("no_data")
              }}
            </caption>
          </table>
        </div>

        <v-divider></v-divider>

        <PopUpTimeLine :itemArray="timeline" />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <button
          type="reset"
          class="btn btn-secondary mr-3 px-5 py-3 ls1"
          @click="dialog = false"
        >
          {{ $t("close") }}
        </button>
      </v-card-actions>
    </div>
  </v-dialog>
</template>

<script>
import PopUpTimeLine from "@/own/components/fulfillment/returned/popup/PopUpTimeLine.vue";
export default {
  name: "ShowTableWithTimeline",
  props: ["header", "table", "timeline"],
  components: { PopUpTimeLine },
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
