<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Create Category</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div
          class="modal-body d-flex flex-column h-650px scroll-y mx-5 mx-xl-15 py-7"
        >
          <!--begin::Body-->
          <form id="createForm" class="pa-3 pb-0">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <template v-for="n in steps">
                  <v-stepper-step
                    :key="`${n}-step`"
                    :complete="e1 > n"
                    :step="n"
                  >
                    <span v-if="n === 1">Settings</span>
                    <span v-if="n === 2">Edit Title</span>
                    <span v-if="n === 3">Edit Description</span>
                    <span v-if="n === 4">Edit Iamge</span>
                  </v-stepper-step>

                  <v-divider v-if="n !== steps" :key="n"></v-divider>
                </template>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content :step="1" :key="`1-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-autocomplete
                        v-model="store_id"
                        :items="stores"
                        outlined
                        item-name="text"
                        item-value="index"
                        dense
                        clearable
                        label="Select Store"
                        @change="ChangeLanguage"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-if="store_id"
                        v-model="parent_id"
                        :items="store_categories"
                        outlined
                        item-name="text"
                        item-value="index"
                        dense
                        clearable
                        label="Categories"
                      ></v-autocomplete>
                    </div>
                    <div class="stepper-footer">
                      <div></div>
                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(1)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="2" :key="`2-content`">
                  <div
                    class="justify-content-between stepper-container flex-column d-flex"
                    v-if="formData"
                  >
                    <div class="stepper-content">
                      <v-text-field
                        v-for="(item, index) in languages"
                        :key="'title' + index"
                        v-model="formData['title_' + item.index]"
                        :counter="40"
                        :label="'Title(' + item.text + ')'"
                        required
                        dense
                        outlined
                        clearable
                      ></v-text-field>
                    </div>
                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(2)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(2)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="3" :key="`3-content`">
                  <div
                    v-if="formData"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-text-field
                        v-for="(item, index) in languages"
                        :key="'description' + index"
                        v-model="formData['description_' + item.index]"
                        :counter="40"
                        :label="'Description(' + item.text + ')'"
                        required
                        dense
                        outlined
                        clearable
                      ></v-text-field>
                    </div>

                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(3)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="nextStep(3)"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content :step="4" :key="`4-content`">
                  <div
                    v-if="formData"
                    class="justify-content-between stepper-container flex-column d-flex"
                  >
                    <div class="stepper-content">
                      <v-file-input
                        v-if="formData"
                        v-model="formData.image"
                        show-size
                        counter
                        multiple
                        dense
                        outlined
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-camera"
                        label="Image"
                        clearable
                      ></v-file-input>
                      <v-switch
                        v-model="formData.home_page"
                        color="primary"
                        label="Home Page"
                      ></v-switch>
                    </div>
                    <div class="stepper-footer">
                      <button
                        type="button"
                        class="btn btn-light mr-3 px-5 py-3 ls1"
                        @click="previewStep(4)"
                      >
                        Previous
                      </button>

                      <button
                        type="button"
                        class="btn btn-info px-5 py-3 ls1"
                        @click="submitCreateForm"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </form>

          <!--end::Body-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "EditItem",
  props: ["refresher", "loader", "categories", "stores"],
  data: () => ({
    dialog: false,
    languages: null,
    store_id: null,
    parent_id: null,
    formData: null,
    e1: 1,
    steps: 4,
  }),
  computed: {
    store_categories: function () {
      if (this.store_id) {
        let result = this.categories.filter(
          (item) => item.store_id === this.store_id
        );
        let item = {
          index: null,
          store_id: this.store_id,
          text: "Origin",
        };
        result.push(item);
        return result;
      } else return null;
    },
  },
  mounted() {
    this.resetData();
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    closeModal() {
      this.resetData();
      this.dialog = false;
    },
    converFormData() {
      let data = new FormData();
      data.append("store_id", this.store_id);
      if (this.parent_id) data.append("parent_id", this.parent_id);
      for (let key in this.formData) {
        if (this.formData[key]) {
          if (key === "home_page") {
            data.append(key, 1);
          } else if (key === "image") {
            data.append(key, this.formData[key][0]);
          } else data.append(key, this.formData[key]);
        } else {
          if (key === "home_page") data.append(key, 0);
        }
      }
      return data;
    },
    submitCreateForm() {
      const data = this.converFormData();
      this.loader(true);
      ApiService.post("/stores/portal/categories/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `The Category data has been Created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
          this.$forceUpdate();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    errorMessage(message) {
      Swal.fire({
        title: "Error",
        text: message,
        icon: "error",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetData() {
      this.formData = null;
      this.store_id = null;
      this.languages = null;
      this.nextStep(4);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.e1 = 1;
      } else {
        if (n === 1) {
          if (!this.store_id)
            this.errorMessage("You should input Store and Category!");
          else this.e1 = n + 1;
        } else if (n === 2) {
          let test = true;
          this.languages.map((item) => {
            if (
              !this.formData["title_" + item.index] ||
              this.formData["title_" + item.index] === ""
            ) {
              test = false;
              return;
            }
          });
          if (test) this.e1 = n + 1;
          else
            this.errorMessage("Should Complete Title data in all languages!");
        } else this.e1 = n + 1;
      }
    },
    previewStep(n) {
      if (n === 1) this.el = this.steps;
      else this.e1 = n - 1;
    },
    ChangeLanguage() {
      if (this.store_id) {
        let selectedItem = this.stores.filter(
          (item) => item.index === this.store_id
        );
        this.languages = selectedItem[0].languages;
      } else this.languages = null;

      this.createFormData();
    },
    createFormData() {
      this.formData = {
        image: null,
        home_page: null,
      };
      if (this.languages) {
        this.languages.map((item) => {
          this.formData["title_" + item.index] = "";
          this.formData["description_" + item.index] = "";
        });
      }
    },
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
};
</script>
