<template>
  <section class="custom-width d-flex flex-column px-3">
    <table :id="`${tableName}`" style="table-layout: auto">
      <thead>
        <tr>
          <th class="border px-4 py-3 d-flex align-items-center">
            <div
              v-if="tableName !== 'roles'"
              class="min-w-20px"
              style="flex-basis: 10%"
            >
              <div
                v-if="roleDetails?.role_id"
                class="float-left form-check form-check-sm form-check-custom form-check-solid min-w-20px min-h-20px"
              >
                <input
                  :disabled="!items.length"
                  class="form-check-input"
                  type="checkbox"
                  @click="(event) => toggleSelectAll(event.target.checked)"
                />
              </div>
            </div>
            <span
              class="text-center text-capitalize font-weight-bolder font-size-h5"
              style="flex-basis: 80%"
            >
              {{ tableName }}</span
            >
            <button
              v-if="tableName === 'roles'"
              @click="() => handleCreateRole()"
              class="float-right pa-0 btn btn-icon-info ms-8 ms-lg-16"
            >
              <span class="svg-icon ma-0">
                <v-icon size="24">mdi-plus-box-outline</v-icon>
              </span>
            </button>
          </th>
        </tr>
        <tr>
          <th class="border px-4 py-2">
            <input
              :id="`search-${tableName}`"
              type="search"
              class="border custom-input rounded w-100 py-2 px-3 text-dark-75"
              @keyup="filterInputs"
              :placeholder="`Search ${tableName}..`"
            />
          </th>
        </tr>
      </thead>
      <tbody :class="`${tableName}-tb`">
        <template v-if="items.length === 0 && tableName !== 'roles'">
          <tr>
            <td class="border px-4 py-2">{{ label }}</td>
          </tr>
        </template>
        <template v-else>
          <div class="list-container">
            <tr
              v-for="(item, index) in items"
              :key="item.name"
              class="d-block w-100"
            >
              <td
                class="border px-4 py-2 d-flex justify-space-between align-center"
              >
                <div class="d-flex align-center">
                  <div
                    v-if="tableName !== 'roles'"
                    class="form-check form-check-sm form-check-custom form-check-solid me-2 min-w-20px min-h-20px"
                  >
                    <input
                      :disabled="!roleDetails?.role_id"
                      class="form-check-input"
                      type="checkbox"
                      v-model="item.is_selected"
                      @click="
                        (event) =>
                          toggleSelect(event.target.checked, item.name, index)
                      "
                    />
                  </div>
                  <span class="font-size-lg py-1 font-weight-medium ml-2">
                    {{ item.title }}
                  </span>
                </div>
                <div class="d-flex align-center justify-end">
                  <ActionsMenu
                    v-if="editType === 'role' || editType === 'action'"
                    :item="item"
                    :editType="editType"
                    @handleEditRole="(role) => handleCreateRole(role)"
                    @handleDuplicateRole="
                      (role) => handleCreateRole(role, true)
                    "
                  />

                  <button
                    v-if="tableName !== 'columns'"
                    :disabled="item?.is_selected === false"
                    @click="(event) => handleNextPress(event, item, index)"
                    class="btn btn-sm btn-icon btn-circle btn-bg-light btn-hover-light-info"
                    :class="`${tableName}-tb-${item.name}-next-btn`"
                  >
                    <span class="svg-icon">
                      <v-icon size="19"> mdi-arrow-right </v-icon>
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </div>
        </template>
      </tbody>
    </table>
  </section>
</template>

<script>
import ActionsMenu from "@/own/components/roles/ActionsMenu.vue";
import {
  ADD_REMOVE_ACTION_TO_SELECTED_PAGE,
  ADD_REMOVE_COLUMN_TO_SELECTED_ACTION,
  ADD_REMOVE_PAGE_TO_SELECTED_ROLE,
  SELECT_ALL_ACTIONS,
  SELECT_ALL_COLUMNS,
  SELECT_ALL_PAGES,
  SET_ACTIONS,
  SET_COLUMNS,
} from "@/core/services/store/roles.module";

export default {
  name: "NestedDataTable",
  components: { ActionsMenu },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    editType: {
      type: String,
      required: true,
    },
    handleGetFunction: {
      type: Function,
      required: false,
    },
    handleCreateRole: {
      type: Function,
      required: false,
    },
  },
  computed: {
    roleDetails: function () {
      return this.$store.getters.getROLESelectedRoleDetails;
    },
    items: function () {
      if (this.editType === "role") return this.$store.getters.getROLERoles;
      else if (this.editType === "page")
        return this.$store.getters.getROLEPages;
      else if (this.editType === "action")
        return this.$store.getters.getROLEActions;
      else return this.$store.getters.getROLEColumns;
    },
    label: function () {
      if (this.editType === "action")
        return this.$store.getters.getROLEActionLabel;
      else return this.$store.getters.getROLEColumnLabel;
    },
  },
  methods: {
    toggleSelectAll(isChecked) {
      if (this.tableName === "pages") {
        this.$store.commit(SELECT_ALL_PAGES, isChecked);

        this.$store.commit(SET_ACTIONS, { page_id: null, actions: null });
        this.$store.commit(SET_COLUMNS, { action_id: null, columns: null });
      } else if (this.tableName === "actions") {
        this.$store.commit(SELECT_ALL_ACTIONS, isChecked);

        this.$store.commit(SET_COLUMNS, { action_id: null, columns: null });
      } else if (this.tableName === "columns") {
        this.$store.commit(SELECT_ALL_COLUMNS, isChecked);
      }
    },
    toggleSelect(isChecked, id, index) {
      if (this.tableName === "pages") {
        this.$store.commit(ADD_REMOVE_PAGE_TO_SELECTED_ROLE, {
          id,
          index,
          forAdding: isChecked,
        });

        this.$store.commit(SET_ACTIONS, { page_id: null, actions: null });
        this.$store.commit(SET_COLUMNS, { action_id: null, columns: null });
      } else if (this.tableName === "actions") {
        this.$store.commit(ADD_REMOVE_ACTION_TO_SELECTED_PAGE, {
          id,
          index,
          forAdding: isChecked,
        });

        this.$store.commit(SET_COLUMNS, { action_id: null, columns: null });
      } else if (this.tableName === "columns") {
        this.$store.commit(ADD_REMOVE_COLUMN_TO_SELECTED_ACTION, {
          id,
          index,
          forAdding: isChecked,
        });
      }
    },
    handleNextPress(event, item, index) {
      if (this.tableName === "roles") {
        this.handleGetFunction(
          item.entities,
          item.name,
          event,
          this.tableName,
          index
        );
      } else if (this.tableName === "pages") {
        this.handleGetFunction(
          item.permissions,
          item.name,
          event,
          this.tableName
        );
      } else if (this.tableName === "actions") {
        this.handleGetFunction(item.columns, item.name, event, this.tableName);
      }
    },
    filterInputs() {
      let input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("search-" + this.tableName);
      filter = input.value.toUpperCase();
      table = document.getElementById(this.tableName);
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) === -1) {
            tr[i].classList.replace("d-block", "d-none");
          } else {
            tr[i].classList.replace("d-none", "d-block");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
td:hover {
  background: #fafafa;
}

.custom-input {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  outline: none;
  font-weight: normal;
}

.custom-input:focus {
  box-shadow: 0 0 0 3px rgb(66 153 225 / 50%);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.custom-width {
  width: 100%;
}

@media (min-width: 769px) {
  .custom-width:nth-child(2) {
    width: 31%;
  }

  .custom-width {
    width: 23%;
  }
}

.list-container {
  overflow-y: scroll;
  max-height: 36vh !important;
}

@media (min-width: 1550px) {
  .list-container {
    max-height: 42vh !important;
  }
}

@media (min-width: 1800px) {
  .list-container {
    max-height: 48vh !important;
  }
}

@media (min-width: 2000px) {
  .list-container {
    max-height: 52vh !important;
  }
}

@media (min-width: 2200px) {
  .list-container {
    max-height: 56vh !important;
  }
}
</style>
