<template>
  <div v-if="item.type === 'text'">
    <pop-up-text-group :item="item"></pop-up-text-group>
  </div>
  <div v-else-if="item.type === 'table'">
    <pop-up-table :item="item"></pop-up-table>
  </div>
  <div v-else-if="item.type === 'docs'">
    <PopUpDocs :item="item"></PopUpDocs>
  </div>
  <div v-else-if="item.type === 'timeline'">
    <pop-up-time-line :itemArray="item.data"></pop-up-time-line>
  </div>
</template>

<script>
import PopUpTable from "@/own/components/fulfillment/returned/popup/PopUpTable.vue";
import PopUpTextGroup from "@/own/components/fulfillment/returned/popup/PopUpTextGroup.vue";
import PopUpDocs from "@/own/components/fulfillment/returned/popup/PopUpDocs.vue";
import PopUpTimeLine from "@/own/components/fulfillment/returned/popup/PopUpTimeLine.vue";
export default {
  name: "PopUpSelector",
  props: ["item"],
  components: { PopUpTable, PopUpTextGroup, PopUpDocs, PopUpTimeLine },
};
</script>
