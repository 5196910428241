<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    persistent
    max-width="650"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-2 align-center">
        <h5 class="mb-0 font-weight-medium">{{ modalText }}</h5>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <form class="row" v-if="is_Ready_Case">
          <div class="col-12 col-md-7 h-75px">
            <v-text-field
              v-model="case_code_input_value"
              label="Case code"
              clearable
              outlined
              dense
              @keydown.enter="handleCaseScan"
            />
          </div>

          <div class="col-12 col-md-5 pt-0">
            <v-radio-group
              v-model="good_or_damage"
              label="Case condition"
              class="mt-0 pt-0"
              row
              hide-details
              hide-spin-buttons
              @change="(value) => toggleGoodOrDamage(value)"
            >
              <v-radio label="Good" :value="1" />
              <v-radio label="Bad" :value="2" />
            </v-radio-group>
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="comment"
              label="Comment"
              clearable
              outlined
              dense
            />
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-info px-5 py-3 ls1"
            @click="() => editBAQ(case_code)"
          >
            {{ $t("edit") }}
          </button>

          <button
            type="reset"
            class="btn btn-info mx-5 px-5 py-3 ls1"
            @click="() => printBAQ(case_code)"
          >
            {{ $t("print") }}
          </button>

          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="saveForm"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import {
  SET_HAS_DIMENSIONS_OF_CARTON,
  SET_SCANNED_CARTONS_FOR_ISS,
  SET_SCANNED_SKUS,
  SET_SKUS_TABLE_DATA,
  TOGGLE_SCANNED_SKUS_GOOD_OR_DAMAGE,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/receiving.module";

export default {
  name: "ScanCaseCodeModal",
  props: ["editBAQ", "printBAQ", "resetReceivingData"],
  data: () => ({
    dialog: false,
    asn_number: null,
    modalText: null,
    is_Ready_Case: false,
    case_code: null,
    case_code_input_value: null,
    good_or_damage: 1,
    comment: null,
    skuName: null,
  }),
  methods: {
    toggleModal(asn_number, case_code, is_Ready_Case, modalText, skuName) {
      this.case_code = case_code;
      this.modalText = modalText;
      this.asn_number = asn_number;
      this.is_Ready_Case = is_Ready_Case;
      this.skuName = skuName;

      this.case_code_input_value = null;
      this.good_or_damage = 1;
      this.comment = null;

      this.loadCartonDimensionDataFromServer();
      this.dialog = true;
    },
    loadCartonDimensionDataFromServer() {
      const sendData = {
        // warehouse_id: this.$store.getters.getSelectedWarehouse,
        asn_number: this.asn_number,
        carton_number: this.case_code,
      };
      ApiService.post(
        "/warehouse_management/receiving/edit_carton_dimension",
        sendData
      ).then(({ data }) => {
        if (
          data.carton_number.weight !== 0 &&
          data.carton_number.length !== 0 &&
          data.carton_number.width !== 0 &&
          data.carton_number.height !== 0
        ) {
          this.$store.commit(SET_HAS_DIMENSIONS_OF_CARTON, true);
        } else {
          this.$store.commit(SET_HAS_DIMENSIONS_OF_CARTON, false);
        }
      });
    },
    toggleGoodOrDamage(inputValue) {
      // this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.dispatch(TOGGLE_SCANNED_SKUS_GOOD_OR_DAMAGE, {
        inputValue,
        skuName: this.skuName,
      });
      // this.$store.commit(SET_PAGE_LOADING, false);
    },
    handleCaseScan() {
      if (
        this.case_code_input_value &&
        this.case_code_input_value !== this.case_code
      ) {
        Swal.fire({
          title: "Warning",
          text: "Wrong case code",
          icon: "warning",
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
    handleFormSave() {
      if (this.$store.getters.getWMSRECEIVINGHasDimensionsOfCarton) {
        this.$store.commit(SET_PAGE_LOADING, true);

        const sendingSKUS = this.$store.getters.getWMSRECEIVINGScannedSKUS.map(
          // eslint-disable-next-line no-unused-vars
          ({ photo, description, ...rest }) => rest
        );
        ApiService.post("/warehouse_management/receiving/update", {
          asn_number: this.asn_number,
          type: this.$store.getters.getWMSRECEIVINGBlindReceive
            ? "blind_receiving"
            : "receiving",
          skus: sendingSKUS,
        })
          .then(async () => {
            await this.$store
              .dispatch(UPDATE_TABLE_DATA, {
                ...this.$store.getters.getWMSRECEIVINGTableState,
                warehouse_id: this.$store.getters.getSelectedWarehouse,
              })
              .then(() => {
                const unReceivedSKUS =
                  this.$store.getters.getWMSRECEIVINGTableData?.data.find(
                    (item) => item.asn_number === this.asn_number
                  );

                if (
                  unReceivedSKUS &&
                  unReceivedSKUS.status_label === "Partially received"
                ) {
                  this.$store.commit(SET_SCANNED_SKUS, []);
                  this.$store.commit(SET_SCANNED_CARTONS_FOR_ISS, []);

                  ApiService.post(
                    "/warehouse_management/receiving/get_receiving_skus",
                    { asn_number: this.asn_number }
                  ).then(({ data }) => {
                    this.$store.commit(SET_SKUS_TABLE_DATA, data.data);
                  });

                  Swal.fire({
                    title: "Success",
                    text: "Successfully received, please scan other items also",
                    icon: "success",
                    showConfirmButton: true,
                  });

                  this.dialog = false;
                } else if (unReceivedSKUS === undefined) {
                  Swal.fire({
                    title: "Success",
                    text: "Successfully received",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2500,
                  });
                  this.resetReceivingData();
                }
              });
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, add dimensions",
          icon: "warning",
          showConfirmButton: true,
          timer: 5000,
        });
      }
    },
    saveForm() {
      if (!this.is_Ready_Case) {
        this.handleFormSave();
      } else {
        if (this.case_code_input_value) {
          if (this.case_code_input_value === this.case_code) {
            this.handleFormSave();
          } else {
            Swal.fire({
              title: "Warning",
              text: "Wrong case code",
              icon: "warning",
              showConfirmButton: true,
              timer: 5000,
            });
          }
        } else {
          Swal.fire({
            title: "Warning",
            text: "Scan case code",
            icon: "warning",
            showConfirmButton: true,
            timer: 5000,
          });
        }
      }
    },
  },
};
</script>
