<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row ma-0">
          <div class="col-12 col-sm-6 h-75px">
            <v-autocomplete
              v-model="formData.warehouse_id"
              label="Warehouse"
              :items="serverData.warehouses"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="warehouse_idErrors"
              @blur="$v.formData.warehouse_id.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.name"
              label="Name"
              dense
              outlined
              clearable
              :error-messages="nameErrors"
              @blur="$v.formData.name.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.priority"
              label="Priority"
              type="number"
              dense
              :min="0"
              outlined
              clearable
              :error-messages="priorityErrors"
              @blur="$v.formData.priority.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-text-field
              v-model="formData.select_limit"
              label="Select Limit"
              dense
              type="number"
              outlined
              clearable
              :min="0"
              :error-messages="selectLimitErrors"
              @blur="$v.formData.select_limit.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-autocomplete
              v-model="formData.status"
              label="Status"
              :items="statusValues"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="statusErrors"
              @blur="$v.formData.status.$touch()"
            />
          </div>

          <div class="col-12 col-sm-6 h-75px">
            <v-autocomplete
              v-model="formData.type"
              label="Type"
              :items="serverData.types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="typesErrors"
              @blur="$v.formData.type.$touch()"
            />
          </div>

          <template v-if="formData.type === 3">
            <div class="col-12 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.source_warehouse_id"
                label="Source warehouse"
                :items="source_warehouses"
                item-text="text"
                item-value="index"
                dense
                outlined
                clearable
                hide-no-data
                hide-selected
                :error-messages="source_warehouse_idErrors"
                @blur="$v.formData.source_warehouse_id.$touch()"
              />
            </div>

            <div class="col-12 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.replenishment_customer_id"
                label="Replenishment customers"
                :items="serverData.replenishment_customers"
                item-text="text"
                item-value="index"
                dense
                outlined
                clearable
                hide-no-data
                hide-selected
                :error-messages="replenishment_customer_idErrors"
                @blur="$v.formData.replenishment_customer_id.$touch()"
              />
            </div>

            <div class="col-12 col-md-4 h-75px">
              <v-autocomplete
                v-model="formData.replenishment_order_type_id"
                label="Replenishment order types"
                :items="serverData.replenishment_order_types"
                item-text="text"
                item-value="index"
                dense
                outlined
                clearable
                hide-no-data
                hide-selected
                :error-messages="replenishment_order_type_idErrors"
                @blur="$v.formData.replenishment_order_type_id.$touch()"
              />
            </div>
          </template>

          <div
            class="col-12 h-75px"
            :class="{ 'col-sm-6': formData.schedule_type_id === 2 }"
          >
            <v-autocomplete
              v-model="formData.schedule_type_id"
              label="Schedule Type"
              :items="serverData.schedule_types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              hide-no-data
              hide-selected
              :error-messages="scheduleTypeErrors"
              @blur="$v.formData.schedule_type_id.$touch()"
            />
          </div>

          <div
            v-if="formData.schedule_type_id === 2"
            class="col-12 col-sm-6 h-75px"
          >
            <v-autocomplete
              v-model="formData.schedule_days"
              label="Schedule Days"
              :items="serverData.schedule_days"
              item-text="text"
              item-value="index"
              dense
              outlined
              multiple
              chips
              small-chips
              deletable-chips
              clearable
              hide-no-data
              hide-selected
              :error-messages="scheduleDaysErrors"
              @blur="$v.formData.schedule_days.$touch()"
            />
          </div>

          <div class="col-12 py-0 px-5">
            <v-radio-group
              label="Frequency"
              v-model="formData.frequency_type"
              row
              class="mt-0"
            >
              <v-radio label="Once" :value="1"></v-radio>
              <v-radio label="Interval" :value="2"></v-radio>
            </v-radio-group>
          </div>

          <div
            class="col-12 h-75px"
            :class="{ 'col-md-6': formData.frequency_type === 2 }"
          >
            <v-dialog
              ref="start_time_dialog"
              v-model="start_time_dialog"
              :return-value.sync="formData.schedule_start_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.schedule_start_time"
                  label="Start time"
                  append-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                  :error-messages="startTimeErrors"
                  @blur="$v.formData.schedule_start_time.$touch()"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="start_time_dialog"
                v-model="formData.schedule_start_time"
                full-width
                :max="formData.schedule_end_time"
                :use-seconds="false"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_time_dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.start_time_dialog.save(formData.schedule_start_time)
                  "
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>

          <div
            class="col-12 col-md-6 h-75px"
            v-if="formData.frequency_type === 2"
          >
            <v-dialog
              ref="end_time_dialog"
              v-model="end_time_dialog"
              :return-value.sync="formData.schedule_end_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.schedule_end_time"
                  label="End time"
                  append-icon="mdi-clock-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  clearable
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="end_time_dialog"
                v-model="formData.schedule_end_time"
                full-width
                :min="formData.schedule_start_time"
                :use-seconds="false"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_time_dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.end_time_dialog.save(formData.schedule_end_time)
                  "
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </div>
          <div
            v-if="formData.frequency_type === 2"
            class="col-12 col-md-6 h-75px"
          >
            <v-text-field
              v-model="formData.frequency_hour"
              label="Frequency Hour"
              dense
              outlined
              type="number"
              :min="0"
              :max="frequencyHourLimit"
              clearable
              @change="hourLimitter"
              :error-messages="frequencyHourErrors"
              @blur="$v.formData.frequency_hour.$touch()"
            />
          </div>

          <div
            v-if="formData.frequency_type === 2"
            class="col-12 col-md-6 h-75px"
          >
            <v-text-field
              v-model="formData.frequency_minute"
              label="Frequency Minute"
              dense
              type="number"
              :min="0"
              :max="frequencyMinuteLimit"
              outlined
              clearable
              @change="minuteLimitter"
              :error-messages="frequencyMinuteErrors"
              @blur="$v.formData.frequency_minute.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              dense
              outlined
              clearable
              hide-details
            />
          </div>
        </form>

        <div class="mt-4 pa-0 w-100 px-3">
          <RuleBuilder
            :query="query"
            :options="operands"
            :operators="operators"
            :userId="userId"
          />
        </div>

        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import RuleBuilder from "@/own/components/warehouseManagement/waves/rules/ruleBuilder/RuleBuilder.vue";
import Swal from "sweetalert2";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { RuleBuilder },
  validations() {
    return {
      formData: {
        warehouse_id: { required },
        name: { required },
        priority: { required },
        select_limit: { required },
        status: { required },
        schedule_type_id: { required },
        type: { required },
        frequency_hour: { required },
        frequency_minute: { required },
        schedule_start_time: { required },
        schedule_days: {
          required: requiredIf(() => {
            return this.formData.schedule_type_id === 2;
          }),
        },
        source_warehouse_id: {
          required: requiredIf(() => {
            return this.formData.type === 3;
          }),
        },
        replenishment_customer_id: {
          required: requiredIf(() => {
            return this.formData.type === 3;
          }),
        },
        replenishment_order_type_id: {
          required: requiredIf(() => {
            return this.formData.type === 3;
          }),
        },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      warehouse_id: null,
      name: null,
      priority: null,
      select_limit: null,
      status: null,
      schedule_type_id: null,
      type: null,
      frequency_hour: 0,
      frequency_minute: 0,
      frequency_type: 1,
      description: null,
      schedule_days: [],
      schedule_start_time: null,
      schedule_end_time: null,
      source_warehouse_id: null,
      replenishment_customer_id: null,
      replenishment_order_type_id: null,
    },
    query: {
      condition: "and",
      rules: [],
    },
    start_time_dialog: false,
    end_time_dialog: false,
    start_time: null,
    end_time: null,
    disableTypeWatcher: true,
  }),
  methods: {
    hourLimitter() {
      if (this.formData.frequency_hour > 23) {
        this.formData.frequency_hour = 23;
      }
    },
    minuteLimitter() {
      if (this.formData.frequency_minute > 59) {
        this.formData.frequency_minute = 59;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        warehouse_id: null,
        name: null,
        priority: null,
        select_limit: null,
        status: null,
        description: null,
        schedule_days: [],
        schedule_start_time: null,
        schedule_end_time: null,
        type: null,
        frequency_hour: 0,
        frequency_minute: 0,
        frequency_type: 1,
        source_warehouse_id: null,
        replenishment_customer_id: null,
        replenishment_order_type_id: null,
      };
      this.query = {
        condition: "and",
        rules: [],
      };
      this.disableTypeWatcher = true;
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    dateMaker(data) {
      let date = new Date(data);
      return date;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        ApiService.post("/warehouse_management/waves/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            if (data.wave.condition) {
              this.query = JSON.parse(data.wave.condition);
            }
            delete data.wave.condition;
            this.formData = JSON.parse(JSON.stringify(data.wave));
            if (!("frequency_type" in this.formData)) {
              this.formData.frequency_type = 1;
            }
            this.pageLoader(false);
          })
          .finally(() => {
            this.pageLoader(false);
            this.disableTypeWatcher = false;
          });
      } else {
        ApiService.post("/warehouse_management/waves/create")
          .then(({ data }) => {
            this.serverData = data;
            this.formData.warehouse_id = this.selectedWarehouse;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("minValue" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].minValue &&
          errors.push("Minimum value should be 10");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();

      if (!this.query.rules.length) {
        Swal.fire({
          title: "Warning",
          text: "Please, add at least one rule!",
          icon: "warning",
        });
        return;
      }

      if (this.$v.$invalid || this.$store.getters.getOMSRULESNotValid) {
        return;
      }

      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let sendData = {
        ...this.formData,
        actions: [this.formData.actions], //spartanskiyy
      };

      sendData.condition = JSON.stringify(this.query);

      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/warehouse_management/waves/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    frequencyMinuteLimit() {
      let validMinuteCount = 59;
      let scheduleStartTimeMinute = 0;
      if (this.formData.schedule_start_time != null) {
        scheduleStartTimeMinute = parseInt(
          this.formData.schedule_start_time.split(":")[1]
        );
      }
      let scheduleEndTimeMinute = 0;
      if (this.formData.schedule_end_time != null) {
        scheduleEndTimeMinute = parseInt(
          this.formData.schedule_end_time.split(":")[1]
        );
      }
      if (scheduleStartTimeMinute > scheduleEndTimeMinute) {
        validMinuteCount =
          parseInt(scheduleStartTimeMinute) - parseInt(scheduleEndTimeMinute);
      } else {
        validMinuteCount =
          60 +
          parseInt(scheduleStartTimeMinute) -
          parseInt(scheduleEndTimeMinute);
      }
      return validMinuteCount;
    },
    frequencyHourLimit() {
      let validHourCount = 23;
      let scheduleStartTimeHour = 0;
      let scheduleStartTimeMinute = 0;
      if (this.formData.schedule_start_time != null) {
        scheduleStartTimeHour = this.formData.schedule_start_time.split(":")[0];
        scheduleStartTimeMinute =
          this.formData.schedule_start_time.split(":")[1];
      }
      // let scheduleStartTime =
      //   ? this.formData.schedule_start_time.split(":")[0]
      //   : 0;
      let scheduleEndTimeHour = 23;
      let scheduleEndTimeMinute = 0;
      if (this.formData.schedule_end_time != null) {
        scheduleEndTimeHour = this.formData.schedule_end_time.split(":")[0];
        scheduleEndTimeMinute = this.formData.schedule_end_time.split(":")[1];
      }
      // let scheduleEndTimeHour = this.formData.schedule_end_time.split(":")[0]
      //   ? this.formData.schedule_end_time
      //   : 23;
      if (scheduleStartTimeHour > scheduleEndTimeHour) {
        validHourCount =
          24 -
          scheduleStartTimeHour +
          scheduleEndTimeHour -
          (scheduleEndTimeMinute > scheduleStartTimeMinute ? 1 : 0);
      } else {
        validHourCount =
          scheduleEndTimeHour -
          scheduleStartTimeHour -
          (scheduleEndTimeMinute > scheduleStartTimeMinute ? 1 : 0);
      }
      return validHourCount;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    userId: function () {
      // return this.$store.getters.isClient
      //   ? this.$store.getters.currentUser.data.id
      //   : this.formData.clients;
      return this.$store.getters.currentUser.data.id;
    },
    statusValues: function () {
      return this.serverData.statuses || [];
    },
    ruleTypes: function () {
      return this.serverData.types;
    },
    actions: function () {
      return this.serverData.actions;
    },
    operands: function () {
      let operands = [];
      if (this.formData.type === 1)
        operands = this.serverData.operands_for_allocation;
      else if (this.formData.type === 2)
        operands = this.serverData.operands_for_picking;
      else if (this.formData.type === 3)
        operands = this.serverData.operands_for_replenishment;
      return operands;
    },
    operators: function () {
      return this.serverData.operators;
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    priorityErrors: function () {
      return this.handleFormValidation("priority");
    },
    selectLimitErrors: function () {
      return this.handleFormValidation("select_limit");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id");
    },
    typesErrors: function () {
      return this.handleFormValidation("type");
    },
    frequencyHourErrors: function () {
      return this.handleFormValidation("frequency_hour");
    },
    frequencyMinuteErrors: function () {
      return this.handleFormValidation("frequency_minute");
    },
    scheduleTypeErrors: function () {
      return this.handleFormValidation("schedule_type_id");
    },
    scheduleDaysErrors: function () {
      return this.handleFormValidation("schedule_days");
    },
    source_warehouses: function () {
      return this.serverData.warehouses.filter(
        (item) => item.index !== this.formData.warehouse_id
      );
    },
    source_warehouse_idErrors: function () {
      return this.handleFormValidation("source_warehouse_id");
    },
    replenishment_customer_idErrors: function () {
      return this.handleFormValidation("replenishment_customer_id");
    },
    replenishment_order_type_idErrors: function () {
      return this.handleFormValidation("replenishment_order_type_id");
    },
    startTimeErrors: function () {
      return this.handleFormValidation("schedule_start_time");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
    formDataType() {
      return this.formData.type;
    },
  },
  watch: {
    formDataType(newValue, oldValue) {
      if (oldValue !== newValue && !this.disableTypeWatcher) {
        // console.log("newValue", newValue);
        const localRules = [];
        if (this.operands.length && newValue) {
          const newGroup = {
            id: this.operands[0].name,
            operator: this.operators[0].name,
            value: "",
          };
          localRules.push(newGroup);
        }
        this.query = {
          condition: "and",
          rules: localRules,
        };
      }
    },
  },
};
</script>
