<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="750"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header h-60px align-center">
        <h4 class="mb-0 font-weight-bolder">
          {{ !dialogForEdit ? "Add" : "Edit" }} Item
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="19">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-370px scroll-y mx-5 py-7">
        <v-row>
          <v-col cols="12" md="8" class="h-75px">
            <v-text-field
              v-model="scanValue"
              label="SKU"
              autofocus
              dense
              clearable
              outlined
              @keydown.enter="
                () => formData.receiving_type !== 2 && handleIncrementQty()
              "
            />
          </v-col>
          <v-col cols="12" md="4" class="h-75px">
            <v-text-field
              v-model="formData.qty"
              label="Quantity"
              type="number"
              :disabled="formData.receiving_type !== 2"
              dense
              outlined
              clearable
              :rules="[rules]"
              @change="() => validateMinValue('qty', 0, 'formData')"
            />
          </v-col>

          <v-col cols="12" class="h-75px">
            <v-text-field
              v-model="formData.description"
              label="Description"
              readonly
              dense
              outlined
            />
          </v-col>

          <template v-if="isForTransfer">
            <v-col cols="12" md="8" class="h-75px">
              <v-text-field
                v-model="toteId"
                label="Tote"
                dense
                clearable
                outlined
              />
            </v-col>
            <v-col cols="12" md="4" class="h-75px">
              <v-text-field
                v-model="splitQty"
                label="Split quantity"
                type="number"
                dense
                @change="() => validateMinValue('splitQty', 0)"
                outlined
                clearable
                :rules="[splitRules]"
              />
            </v-col>
          </template>

          <v-col cols="12" md="3" class="h-75px">
            <v-checkbox
              v-model="formData.assemble_flag"
              label="Assembled"
              color="#7e04b7"
              dense
            />
          </v-col>
          <v-col cols="12" md="3" class="h-75px">
            <v-checkbox
              v-model="formData.label_flag"
              label="Labelled"
              color="#7e04b7"
              dense
            />
          </v-col>
          <v-col cols="12" md="3" class="h-75px">
            <v-checkbox
              v-model="formData.bundle_flag"
              label="Bundled"
              color="#7e04b7"
              dense
            />
          </v-col>
          <v-col cols="12" md="3" class="h-75px">
            <v-checkbox
              v-model="formData.qc_flag"
              label="QC"
              color="#7e04b7"
              dense
            />
          </v-col>
          <v-col cols="12" md="8" class="h-75px">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              outlined
              offset-y
              dense
            >
              <v-date-picker
                v-model="formData.exp_date"
                clearable
              ></v-date-picker>

              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.exp_date"
                  label="Expire date"
                  append-icon="mdi-calendar"
                  v-on="on"
                  v-bind="attrs"
                  dense
                  outlined
                  clearable
                  hide-details
                />
              </template>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4" class="h-75px pt-0">
            <v-radio-group
              v-model="formData.good_or_damage"
              label="In good condition"
              class="mt-0 pt-0 px-3"
              row
              hide-details
              hide-spin-buttons
            >
              <v-radio label="Yes" :value="1" />
              <v-radio label="No" :value="2" />
            </v-radio-group>
          </v-col>
        </v-row>
        <!--begin::Actions-->
        <div
          class="mb-4 min-h-75px d-flex flex-grow-1 align-end justify-center"
        >
          <template v-if="!dialogForEdit">
            <button
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Clear
            </button>

            <button class="btn btn-info px-5 py-3 ls1" @click="submitForm">
              Submit
            </button>
          </template>

          <template v-else>
            <button
              v-if="!isForTransfer"
              class="btn btn-light-danger mr-3 px-5 py-3 ls1"
              @click="removeItem"
            >
              Delete
            </button>
            <button class="btn btn-info px-5 py-3 ls1" @click="saveEditForm">
              Save
            </button>
          </template>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import {
  EDIT_SCANNED_SKUS,
  SET_SCANNED_SKUS,
  REMOVE_SCANNED_SKUS,
} from "@/core/services/store/receiving.module";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "SKUActionsDialog",
  mixins: [fieldValueValidation],
  data: () => ({
    dialog: false,
    dialogForEdit: false,
    formData: {
      id: null,
      sku: null,
      tote_id: null,
      qty: 1,
      description: "",
      label_flag: false,
      bundle_flag: false,
      qc_flag: false,
      assemble_flag: false,
      exp_flag: false,
      exp_date: null,
      good_or_damage: 1,
      photo: null,
      receiving_type: null,
      expected_qty: null,
      is_allow_over_receiving: null,
    },
    scanValue: "",
    scanValueLength: 0,
    isForTransfer: false,
    toteId: "",
    splitQty: undefined,
  }),
  methods: {
    rules(value) {
      return (
        Number(value) <= this.formData.expected_qty ||
        "Max quantity " + +this.formData.expected_qty
      );
    },
    splitRules(value) {
      return (
        Number(value) <= this.formData.qty - 1 ||
        "Max quantity " + +(this.formData.qty - 1)
      );
    },
    handleIncrementQty() {
      // if (this.formData.receiving_type !== 2) {
      const scanValueLocalLength = this.scanValue.length - this.scanValueLength;

      if (this.scanValueLength > 0)
        this.scanValue = this.scanValue.slice(this.scanValueLength);

      if (this.scanValue !== this.formData.sku) {
        Swal.fire({
          title: "Warning",
          text: "You scanned different SKU",
          icon: "warning",
        }).then(() => {
          this.scanValue = "";
          this.scanValueLength = 0;
        });
      } else {
        // this.scanValue = "";
        if (this.formData.is_allow_over_receiving) this.formData.qty++;
        else {
          const skus = this.$store.getters.getWMSRECEIVINGScannedSKUS.filter(
            (item) => item.sku === this.formData.sku
          );

          let leftQty = this.formData.expected_qty;
          if (skus.length) {
            skus.forEach((item) => {
              leftQty -= item.qty;
            });
          }

          // console.log("leftQty", leftQty);
          if (leftQty > this.formData.qty) this.formData.qty++;
          else if (leftQty <= this.formData.qty)
            Swal.fire({
              title: "Warning",
              text: "You have reached the expected quantity",
              icon: "warning",
            });
        }

        this.scanValueLength = scanValueLocalLength;
      }
      // }
    },
    editSKU(item, isForTransfer = false) {
      // console.log(item);
      this.formData = { ...item };

      this.scanValue = item.sku;
      this.scanValueLength = item.sku.length;
      this.dialogForEdit = true;
      this.isForTransfer = isForTransfer;
      this.dialog = true;
      // console.log("editSKU", this.formData);
    },
    toggleModal(data, tote_id) {
      if (this.dialog) this.resetFormData();
      else {
        // console.log("data", data);
        this.formData.sku = data.sku;
        this.scanValue = data.sku;
        this.scanValueLength = data.sku.length;
        this.formData.tote_id = tote_id;

        this.formData.transfer_flag = data.transfer_flag;
        this.formData.description = data.description;
        this.formData.photo = data.photo;
        this.formData.receiving_type = data.receiving_type;
        this.formData.expected_qty = data.qty;
        this.formData.is_allow_over_receiving = data.is_allow_over_receiving;
        // this.formData = { ...data };
        this.dialogForEdit = false;
      }
      this.dialog = !this.dialog;
    },
    saveEditForm() {
      if (this.isForTransfer) {
        if (this.formData.expected_qty < this.splitQty || !this.splitQty)
          return;

        const lastElementIndex = this.$store.getters.getWMSRECEIVINGScannedSKUS.findIndex(
          (elem) =>
            elem.id === this.formData.id &&
            elem.tote_id === this.formData.tote_id
        );
        let sendDataForEditingQty = {
          newItem: {
            ...this.formData,
            qty: this.formData.qty - this.splitQty,
            // expected_qty: this.formData.expected_qty - this.splitQty,
          },
          lastElementIndex,
        };
        this.$store.commit(EDIT_SCANNED_SKUS, sendDataForEditingQty);

        let newItem = {
          ...this.formData,
          id: new Date().getTime(),
          // index: new Date().getTime(),
          qty: this.splitQty,
          expected_qty: this.formData.expected_qty - this.formData.qty,
          transfer_flag: false,
          tote_id: this.toteId,
          lpn_id: "",
        };
        // console.log("saveEditForm newItem", newItem);
        this.$store.commit(SET_SCANNED_SKUS, newItem);
      } else {
        if (this.formData.expected_qty < this.formData.qty) return;

        const lastElementIndex = this.$store.getters.getWMSRECEIVINGScannedSKUS.findIndex(
          (elem) =>
            elem.id === this.formData.id &&
            elem.tote_id === this.formData.tote_id
        );
        let sendData = {
          newItem: { ...this.formData },
          lastElementIndex,
        };
        this.$store.commit(EDIT_SCANNED_SKUS, sendData);
      }
      this.toggleModal();
    },
    submitForm() {
      const sku = this.$store.getters.getWMSRECEIVINGScannedSKUS.find(
        (item) =>
          item.sku === this.formData.sku &&
          item.good_or_damage === this.formData.good_or_damage
      );

      if (sku) {
        sku.qty += this.formData.qty;
      } else {
        let sendData = {
          ...this.formData,
          id: new Date().getTime(),
          // tote_id: "",
          lpn_id: "",
        };
        // console.log("submitForm ssssss", sendData);
        this.$store.commit(SET_SCANNED_SKUS, sendData);
      }
      this.toggleModal();
    },
    removeItem() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const index = this.$store.getters.getWMSRECEIVINGScannedSKUS.findIndex(
            (item) => item.id === this.formData.id
          );
          this.$store.commit(REMOVE_SCANNED_SKUS, index);
          this.toggleModal();
        }
      });
    },
    resetFormData() {
      this.formData.qty = 1;
      this.formData.description = "";
      this.formData.label_flag = false;
      this.formData.bundle_flag = false;
      this.formData.qc_flag = false;
      this.formData.assemble_flag = false;
      this.formData.exp_flag = false;
      this.formData.exp_date = null;
      this.formData.good_or_damage = 1;
      this.isForTransfer = false;
      this.toteId = "";
      this.splitQty = undefined;
      this.formData.id = undefined;
    },
  },
};
</script>
