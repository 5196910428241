<template>
  <v-card style="height: 40%">
    <v-card-title
      class="d-flex justify-content-between h-75px custom-card-title"
    >
      <div>
        <span class="font-weight-medium m-4 text-h5"
          >{{ $t("order_details") }}
        </span>
      </div>
      <button @click="toggleModal">
        <span class="svg-icon mr-2">
          <v-icon size="18">mdi-close</v-icon>
        </span>
      </button>
    </v-card-title>
    <v-card-text class="mt-3">
      <h2 v-if="!suggestedDetails" class="text-center">
        {{ $t("not_available") }}
      </h2>
      <div class="suggestions-block" v-else>
        <v-row
          v-for="(item, index) in suggestedDetails"
          class="px-5"
          :key="index"
          style="color: rgba(0, 0, 0, 0.87)"
        >
          <v-col cols="5" class="font-size-lg pb-0 font-weight-bolder">
            {{ item.text }}</v-col
          >
          <v-col cols="4" class="font-size-h6">
            {{ item.value ? item.value : "-" }}
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SuggestedDetails",
  props: ["toggleModal"],
  data: () => ({
    fieldLabels: [
      { key: "asn_number", text: "ASN number" },
      { key: "client", text: "Client" },
      { key: "reference_number", text: "Reference number" },
      { key: "sku_id", text: "SKU" },
      { key: "sku_barcode", text: "Barcode" },
      { key: "description", text: "Description" },
      { key: "expected_qty", text: "Expected quantity" },
      { key: "pending_qty", text: "Pending quantity" },
      { key: "total_picked_qty", text: "Total picked quantity" },
      { key: "total_packed_qty", text: "Total packed quantity" },
      { key: "total_scanned_qty", text: "Total scanned quantity" },
      { key: "total_pending_qty", text: "Total pending quantity" },
    ],
  }),
  computed: {
    suggestedDetails: function () {
      const suggestions =
        this.$store.getters.getSTANDARDORDERPACKINGSuggestedDetails;

      if (!Object.keys(suggestions).length) return null;

      return this.fieldLabels
        .filter((item) => item.key in suggestions)
        .map((item) => ({
          text: item.text,
          value: suggestions[item.key],
        }));
    },
  },
};
</script>

<style scoped lang="scss">
.suggestions-block {
  height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (min-height: 982px) {
    height: 250px;
  }
}
</style>
