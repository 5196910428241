<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :isFilterRequiredForExport="true"
      :isWarehouseRequired="true"
      :skipAddButton="true"
      :overrideActionsWith="actions"
      :recordDetailComponent="standardQualityDetails"
      @assignUser="assignUser"
      @makeRecount="makeRecount"
      @closeCount="closeCount"
      @exportTasks="exportTasks"
      @downloadQcDocument="downloadQcDocument"
      @item-selected="itemSelected"
    >
      <template v-slot:topActions="{ permissions }">
        <StandardQualityCheckActions
          :page-loading="setPageLoading"
          :refresher="updateTableData"
          v-if="permissions('create')"
        />
      </template>

      <template v-slot:topRightActions="{ permissions }">
        <CancelCases
          :selected-items="selected"
          :page-loading="setPageLoading"
          :refresher="updateTableData"
          v-if="permissions('create')"
        />
      </template>
    </DataTable>
    <AssignUserModal ref="assignUserModal" :page-loading="setPageLoading" />
    <DownloadQcDocument
      ref="downloadQcDocument"
      :page-loading="setPageLoading"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
// import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/standardQualityCheck.module";
import StandardQualityCheckActions from "@/own/components/warehouseManagement/standardQualityCheck/StandardQualityCheckActions.vue";
import CancelCases from "@/own/components/warehouseManagement/standardQualityCheck/CancelCases.vue";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import AssignUserModal from "@/own/components/warehouseManagement/standardQualityCheck/AssignUserModal.vue";
import StandardQualityDetails from "@/own/components/warehouseManagement/standardQualityCheck/StandardQualityDetails.vue";
import DownloadQcDocument from "@/own/components/warehouseManagement/standardQualityCheck/DownloadQcDocument.vue";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "StandardQualityCheck",
  components: {
    DataTable,
    StandardQualityCheckActions,
    CancelCases,
    AssignUserModal,
    DownloadQcDocument,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getSTANDARDQUALITYCHECKTableFilters",
      getTableState: "getSTANDARDQUALITYCHECKTableState",
      getTableData: "getSTANDARDQUALITYCHECKTableData",
      getTableHeaders: "getSTANDARDQUALITYCHECKTableHeaders",
      getTableProperties: "getSTANDARDQUALITYCHECKTableProperties",
      getTableExportUrl: "getSTANDARDQUALITYCHECKExportUrl",
      getTablePermissions: "getSTANDARDQUALITYCHECKTablePermissions",
      getUploadType: "getSTANDARDQUALITYCHECKUploadType",
    },
    standardQualityDetails: StandardQualityDetails,
    selected: [],
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
    },
    setPageLoading(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    updateTableData() {
      this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      });
    },
    assignUser(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.assignUserModal.toggleModal();
    },
    makeRecount(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/standard_quality_checks/recount", {
        id: item.id,
      })
        .then(() => {
          Swal.fire({
            title: `Success`,
            html: `Recount created successfully`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .then(() => {
          this.updateTableData();
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    closeCount(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
        standard_qc_number: item.standard_qc_number,
      };
      ApiService.post(
        "/warehouse_management/standard_quality_checks/close",
        payload
      )
        .then(() => {
          Swal.fire({
            title: `Success`,
            html: `QC closed successfully`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .then(() => {
          this.updateTableData();
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    exportTasks(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        "/warehouse_management/standard_quality_checks/export_qc_tasks",
        {
          id: item.id,
        }
      ).then(({ data }) => {
        const link = document.createElement("a");
        link.href = data.url;
        link.download = "Export.csv";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        }, 1500);
        link.remove();
      });
    },
    itemSelected(items) {
      this.selected = items;
    },
    downloadQcDocument(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.downloadQcDocument.toggleModal();
    },
  },
  computed: {
    isDataLoaded: function() {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function() {
      return this.$store.getters.getSelectedWarehouse;
    },
    actions: function() {
      return [
        {
          name: "assign_user",
          title: "Assign User",
          type: "normal",
          method: "assignUser",
          isVisible: true,
        },
        {
          name: "recount",
          title: "Recount",
          type: "normal",
          method: "makeRecount",
          isVisible: true,
        },
        {
          name: "export_tasks",
          title: "Export Tasks",
          type: "normal",
          method: "exportTasks",
          isVisible: true,
        },
        {
          name: "close",
          title: "Close",
          type: "normal",
          method: "closeCount",
          isVisible: true,
        },
        {
          name: "download_qc_document",
          title: "Download QC Document",
          type: "normal",
          method: "downloadQcDocument",
          isVisible: true,
        },
      ];
    },
  },
  beforeMount() {
    if (this.selectedWarehouse) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.initTableContent();
    }
  },
  watch: {
    selectedWarehouse: function(value) {
      if (value) {
        this.setPageLoading(true);
        this.initTableContent();
      }
    },
  },
};
</script>
