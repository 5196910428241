<template>
  <div v-if="value" class="second-text" :class="{ 'text-right': $vuetify.rtl }">
    {{ dateTimeFormatter(new Date(value * 1000)) }}
  </div>
  <div class="second-text" v-else>-</div>
</template>

<script>
/**
 * component for displaying date in datatable columns
 * api name = date
 */
export default {
  name: "DateTimeColumn",
  props: ["value"],
  methods: {
    dateTimeFormatter(date) {
      let d = date;
      return (
        [
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getFullYear(),
        ].join("/") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
  },
};
</script>
