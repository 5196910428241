<template>
  <div class="d-flex">
    <b-container fluid class="p-0">
      <b-row no-gutters>
        <b-col cols="3" class="pr-3">
          <slot name="ruleID" :rule="rule" :options="ruleIDOptions">
            <v-select
              v-model="rule.id"
              :items="ruleIDOptions"
              item-text="title"
              item-value="name"
              dense
              outlined
            />
          </slot>
        </b-col>
        <b-col cols="4" class="pr-3">
          <slot name="ruleOperator" :rule="rule" :options="ruleOperatorOptions">
            <v-select
              v-model="rule.operator"
              label="Condition"
              :items="ruleOperatorOptions"
              item-text="title"
              item-value="name"
              dense
              outlined
            />
          </slot>
        </b-col>

        <b-col cols="5" class="pr-3" v-if="ruleParams">
          <slot
            name="dropdown_multiple"
            :rule="rule"
            :multiple="multiple"
            :options="ruleParams.options"
            v-if="ruleParams.type === 'dropdown_multi_search'"
          >
            <v-autocomplete
              v-model="rule.value"
              :label="ruleParams.title"
              :items="ruleParams.options"
              item-text="text"
              return-object
              cache-items
              dense
              outlined
              clearable
              :multiple="multiple"
              small-chips
              deletable-chips
              hide-no-data
              hide-selected
              placeholder="Enter at least 2 characters."
              :loading="isLoading"
              :search-input.sync="search"
              :error-messages="ruleValueErrors"
              @blur="$v.rule.value.$touch()"
            />
          </slot>

          <slot
            name="number"
            :rule="rule"
            v-else-if="ruleParams.type === 'number'"
          >
            <v-text-field
              v-model="rule.value"
              :label="ruleParams.title"
              dense
              outlined
              clearable
              type="number"
              hide-details
              :error-messages="ruleValueErrors"
              @blur="$v.rule.value.$touch()"
            />
          </slot>

          <slot
            name="text"
            :rule="rule"
            v-else-if="
              ruleParams.type === 'text' && rule.operator !== 'is_empty'
            "
          >
            <v-text-field
              v-model="rule.value"
              :label="ruleParams.title"
              dense
              outlined
              clearable
              hide-details
              :error-messages="ruleValueErrors"
              @blur="$v.rule.value.$touch()"
            />
          </slot>

          <slot
            name="dropdown"
            :rule="rule"
            v-else-if="ruleParams.type === 'dropdown'"
          >
            <v-autocomplete
              v-model="rule.value"
              :label="ruleParams.title"
              :items="ruleParams.values"
              item-text="text"
              item-value="index"
              return-object
              dense
              outlined
              clearable
              hide-details
              :error-messages="ruleValueErrors"
              @blur="$v.rule.value.$touch()"
            />
          </slot>

          <slot
            name="dropdown"
            :rule="rule"
            v-else-if="ruleParams.type === 'dropdown_multi_select'"
          >
            <v-autocomplete
              v-model="rule.value"
              :label="ruleParams.title"
              :items="ruleParams.values"
              item-text="text"
              item-value="index"
              small-chips
              deletable-chips
              return-object
              multiple
              dense
              outlined
              clearable
              hide-details
              :error-messages="ruleValueErrors"
              @blur="$v.rule.value.$touch()"
            />
          </slot>
        </b-col>
      </b-row>
    </b-container>
    <div class="max-h-65">
      <button
        class="h-40px w-40px btn btn-sm btn-icon btn-light-info btn-hover-light"
        @click="deleteRule"
      >
        <span class="svg-icon">
          <v-icon size="18">mdi-delete</v-icon>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { SET_NOT_VALID } from "@/core/services/store/allocationStrategyRules.module";

export default {
  name: "Rule",
  mixins: [validationMixin],
  validations: {
    rule: {
      value: { required },
    },
  },
  props: {
    rule: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
    },
    userId: {
      required: false,
    },
  },
  mounted() {
    this.$v.rule.value.$touch();
    if (
      this.ruleParams.name === "city" ||
      this.ruleParams.name === "country" ||
      this.ruleParams.name === "area"
    ) {
      // console.log('rule',this.rule)
      this.ruleParams.options = this.rule.value;
      // this.search = this.rule.value[0].text;
      this.$store.commit(SET_NOT_VALID, false);
    }
  },
  data: () => ({
    search: null,
    isLoading: false,
    doneTypingInterval: 1500,
    typingTimer: null,
  }),
  computed: {
    ruleParams() {
      let res;
      res = this.options.find((option) => option.name === this.rule.id);
      // console.log("res", res);
      return res;
    },
    ruleIDOptions() {
      const options = this.options;
      return options.map(function (option) {
        return {
          name: option.name,
          title: option.title,
        };
      });
    },
    currentRuleID: function () {
      return this.rule.id;
    },
    currentRuleOperator: function () {
      return this.rule.operator;
    },
    multiple: function () {
      return this.rule.operator.includes("in");
    },
    ruleOperatorOptions: function () {
      if (this.ruleParams)
        switch (this.ruleParams.type) {
          case "dropdown_multiple":
            return [
              { name: "in", title: "belongs to" },
              { name: "not_in", title: "doesn't belong to" },
              { name: "equal", title: "equals" },
            ];
          case "text":
            return [
              { name: "equal", title: "equals" },
              { name: "not_equal", title: "not equal" },
              { name: "like", title: "like" },
              { name: "is_empty", title: "is empty" },
            ];
          case "number":
            return [
              { name: "equal", title: "equals" },
              { name: "not_equal", title: "not equal" },
              { name: "greater_than", title: "greater than" },
              { name: "less_than", title: "less than" },
            ];
          default:
            return [
              { name: "in", title: "belongs to" },
              { name: "not_in", title: "doesn't belong to" },
              { name: "equal", title: "equals" },
              { name: "not_equal", title: "not equal" },
              { name: "greater_than", title: "greater than" },
              { name: "less_than", title: "less than" },
              { name: "like", title: "like" },
            ];
        }
      else return [];
    },
    ruleValueErrors: function () {
      return this.handleFormValidation("value");
    },
  },
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.rule[fieldName].$dirty) return errors;
      if ("required" in this.$v.rule[fieldName]) {
        !this.$v.rule[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    resetRuleValue: function () {
      if (this.multiple && this.ruleParams.type === "dropdown_multiple") {
        this.rule.value = [];
      } else {
        this.rule.value = "";
      }
      if (
        this.ruleParams.hasOwnProperty("options") &&
        this.ruleParams.options.length
      ) {
        this.ruleParams.options = [];
      }
    },
    deleteRule: function () {
      const self = this;
      const parentRules = self.$parent.currentQuery.rules;
      parentRules.splice(parentRules.indexOf(self.rule), 1);
    },
    async getData(value) {
      let requestBody = { q: value };
      if (this.userId && this.ruleParams.name === "sku")
        requestBody.clients = this.userId.map((item) => item.index);

      await fetch(
        `${process.env.VUE_APP_BASE_URL}${this.ruleParams.search_url}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(requestBody),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          if (this.ruleParams.name === "sku" && res.skus && this.userId) {
            this.ruleParams.options = res.skus.map((item) => ({
              index: item.skuId,
              text: item.systemCode,
            }));
          } else if (this.ruleParams.name === "country" && res.countries) {
            this.ruleParams.options = res.countries;
          } else if (this.ruleParams.name === "city" && res.cities) {
            this.ruleParams.options = res.cities;
          } else if (this.ruleParams.name === "area" && res.areas) {
            this.ruleParams.options = res.areas;
          }
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    currentRuleID: function () {
      this.resetRuleValue();
    },
    currentRuleOperator: function (to, from) {
      if (to.includes("in") !== from.includes("in")) {
        this.resetRuleValue();
      }
      return false;
    },
    search(val) {
      clearTimeout(this.typingTimer);
      if (!val || val === "") {
        return;
      }
      this.typingTimer = setTimeout(() => {
        this.isLoading = true;
        this.getData(val.trim());
      }, this.doneTypingInterval);
    },
    rule: {
      handler(newValue) {
        // console.log("newValue", newValue);
        if (Array.isArray(newValue.value)) {
          if (newValue.value.length) this.$store.commit(SET_NOT_VALID, false);
          else this.$store.commit(SET_NOT_VALID, true);
        } else {
          if (newValue.value) this.$store.commit(SET_NOT_VALID, false);
          else this.$store.commit(SET_NOT_VALID, true);
          // else {
          //   if (
          //     newValue.id === "consignee_document_number" &&
          //     newValue.operator === "is_empty"
          //   )
          //     this.$store.commit(SET_NOT_VALID, false);
          //   else this.$store.commit(SET_NOT_VALID, true);
          // }
        }
      },
      deep: true,
    },
  },
};
</script>
