<template>
  <div class="ma-0 row-bg-color custom-content-height">
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="table_properties"
      />
      <div class="my-10 ml-4" :class="{ 'text-right pr-4': $vuetify.rtl }">
        <h3 class="font-size-h1 text-dark-65">
          {{ $t("overview") }}
        </h3>
        <p class="text-dark-65 font-size-h4 w-1000px mt-3">
          {{
            $t(
              "our_return_plugin_can_be_easily_integrated_with_any_application"
            )
          }}
        </p>
        <div class="row mt-8">
          <div class="col-lg-8">
            <!-- Authorization token -->
            <h3 class="font-size-h1 text-dark-65 mt-2">
              {{ $t("authorization_token") }}
            </h3>
            <p class="mt-8">
              {{ $t("you_can_get_the") }}
              <code>Authorization Token</code>
              {{ $t("from_customer_service") }}
            </p>
            <!-- create -->
            <CreateReturn />
            <!-- get return -->
            <GetReturn />

            <!-- Get Location -->
            <GetLocation />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateReturn from "@/own/components/integrations/CreateReturn.vue";
import GetReturn from "@/own/components/integrations/GetReturn.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail.vue";
import GetLocation from "@/own/components/integrations/GetLocation.vue";

export default {
  name: "ReturnClientAPIDoc",
  components: {
    DatatableDetail,
    CreateReturn,
    GetReturn,
    GetLocation,
  },
};
</script>

<style>
.highlight {
  position: relative;
  background: #1e1e3f;
  border-radius: 0.5rem;
  padding: 1rem;
}

.highlight .highlight-copy {
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}
.highlight .highlight-copy-rtl {
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}

.highlight .highlight-copy:hover {
  transition: all 0.2s ease;
  background-color: #323268;
  color: #009ef7;
}

.highlight span {
  width: 85%;
  color: #a5c261;
  text-align: left;
  word-break: break-all;
  font-family: "Operator Mono", "Fira Code", Consolas, Monaco, "Andale Mono",
    "Ubuntu Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.token-tag {
  color: rgb(255, 157, 0) !important;
}
.token-comment {
  color: rgb(128, 128, 128) !important;
}
.token-attr {
  color: rgb(255, 180, 84) !important;
}
.token-value {
  color: #a5c261 !important;
}
.token-string {
  color: #b362ff !important;
}
code {
  color: #b93993 !important;
}

.highlight-code {
  direction: ltr !important;
  text-align: left;
}
</style>
