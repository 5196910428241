import { render, staticRenderFns } from "./AddItem.vue?vue&type=template&id=5879668c&scoped=true"
import script from "./AddItem.vue?vue&type=script&lang=js"
export * from "./AddItem.vue?vue&type=script&lang=js"
import style0 from "./AddItem.vue?vue&type=style&index=0&id=5879668c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5879668c",
  null
  
)

export default component.exports