<template>
  <v-expansion-panel
    class="mt-0 bg-secondary-o-50 mb-3 rounded-lg"
    @click="togglePanel"
  >
    <v-expansion-panel-header class="ps-0">
      <span class="font-weight-black font-size-lg ms-5">
        {{ warehouseDetails.text }}
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ps-0">
      <div class="ms-5 border-top">
        <div
          v-if="!serverDataLoaded"
          class="d-flex justify-content-center mt-5"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="#7e04b7"
            indeterminate
          ></v-progress-circular>
        </div>
        <form v-else-if="serverDataLoaded" class="form-body mt-5">
          <!-- Allow over receiving -->
          <v-row>
            <v-col md="4">
              <v-checkbox
                v-model="formData.scanEachUnitEnabled"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Scan each unit when putaway
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="8" v-if="serverData">
              <v-row v-if="formData.scanEachUnitEnabled" class="ms-3">
                <v-col md="6">
                  <v-select
                    v-model="formData.scanEachUnitRules"
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="scanEachEunitRuleErrors"
                    @input="$v.formData.scanEachUnitRules.$touch()"
                    @blur="$v.formData.scanEachUnitRules.$touch()"
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.scanEachUnitRules !== 'all' &&
                    formData.scanEachUnitRules !== null
                  "
                >
                  <v-select
                    v-model="formData.scanEachUnitCustomers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="scanEachUnitCustomersErrors"
                    @input="$v.formData.scanEachUnitCustomers.$touch()"
                    @blur="$v.formData.scanEachUnitCustomers.$touch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Allow Putaway Across Zones -->
          <v-row class="mt-0">
            <v-col md="4">
              <v-checkbox
                v-model="formData.allowPutawayArossZonesEnabled"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Allow Putaway Across Zones
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="8" v-if="serverData">
              <v-row v-if="formData.allowPutawayArossZonesEnabled" class="ms-3">
                <v-col md="6">
                  <v-select
                    v-model="formData.allowPutawayArossZonesRules"
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="allowPutawayAcroosZonesRuleErrors"
                    @input="$v.formData.allowPutawayArossZonesRules.$touch()"
                    @blur="$v.formData.allowPutawayArossZonesRules.$touch()"
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.allowPutawayArossZonesRules !== 'all' &&
                    formData.allowPutawayArossZonesRules !== null
                  "
                >
                  <v-select
                    v-model="formData.allowPutawayArossZonesCustomers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="
                      allowPutawayAcroosZonesCustomersSelectErrors
                    "
                    @input="
                      $v.formData.allowPutawayArossZonesCustomers.$touch()
                    "
                    @blur="$v.formData.allowPutawayArossZonesCustomers.$touch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Allow to split Putaway task -->
          <v-row class="mt-0">
            <v-col md="4">
              <v-checkbox
                v-model="formData.allowToSplitPutawayTaskEnabled"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Allow to split Putaway Task
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col class="mt-2" md="8" v-if="serverData">
              <v-row
                v-if="formData.allowToSplitPutawayTaskEnabled"
                class="ms-3"
              >
                <v-col md="6">
                  <v-select
                    v-model="formData.allowToSplitPutawayTaskRules"
                    label="Choose Rule"
                    dense
                    outlined
                    rounded
                    item-text="text"
                    item-value="index"
                    :items="serverData.apply_rule_types"
                    :error-messages="allotToSplitPutawayTaskRuleErrors"
                    @input="$v.formData.allowToSplitPutawayTaskRules.$touch()"
                    @blur="$v.formData.allowToSplitPutawayTaskRules.$touch()"
                  />
                </v-col>
                <v-col
                  md="6"
                  v-if="
                    formData.allowToSplitPutawayTaskRules !== 'all' &&
                    formData.allowToSplitPutawayTaskRules !== null
                  "
                >
                  <v-select
                    v-model="formData.allowToSplitPutawayTaskCustomers"
                    label="Choose Customer(s)"
                    multiple
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    rounded
                    :items="serverData.clients"
                    :error-messages="
                      allotToSplitPutawayTaskCustomersSelectErrors
                    "
                    @input="
                      $v.formData.allowToSplitPutawayTaskCustomers.$touch()
                    "
                    @blur="
                      $v.formData.allowToSplitPutawayTaskCustomers.$touch()
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Should preserve tote after putaway -->
          <v-row class="mt-0">
            <v-col md="6">
              <v-checkbox
                v-model="formData.should_preserve_tote_after_putaway"
                dense
                color="#7e04b7"
              >
                <template #label>
                  <span class="mt-2 font-size-md font-weight-medium">
                    Should preserve tote after putaway
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <!-- location -->
          <v-row>
            <v-col md="6">
              <v-autocomplete
                v-model="defaultStagingLocation"
                label="Default Missing location"
                dense
                outlined
                rounded
                clearable
                item-text="name"
                item-value="id"
                :items="serverData.locations"
                :loading="warehouseLoading"
                @change="clearDefaultMissingLocation"
                :search-input.sync="warehouseSearch"
                @keydown="searchWarehouseLocation"
                :error-messages="stagingLocationError"
              />
            </v-col>
          </v-row>
          <!-- Submit -->
          <v-row class="justify-center mb-3">
            <v-col md="2" class="justify-content-end">
              <!-- <v-btn dark color="purple" @click="submitConfiguration">
                Submit
              </v-btn> -->
              <button
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitConfiguration"
              >
                Submit
              </button>
            </v-col>
          </v-row>
        </form>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  // required,
  requiredIf,
} from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "ExpansionPanel",
  mixins: [validationMixin],
  props: {
    warehouseDetails: { type: Object, required: true },
  },
  components: {},
  data() {
    return {
      expanded: false,
      serverData: null,
      serverDataLoaded: false,
      warehouseSearch: "",
      warehouseLoading: false,
      stagingLocationError: null,
      defaultStagingLocation: null,
      formData: {
        scanEachUnitEnabled: false,
        scanEachUnitRules: null,
        scanEachUnitCustomers: [],

        allowPutawayArossZonesEnabled: false,
        allowPutawayArossZonesRules: null,
        allowPutawayArossZonesCustomers: [],

        allowToSplitPutawayTaskEnabled: false,
        allowToSplitPutawayTaskRules: null,
        allowToSplitPutawayTaskCustomers: [],

        stagingLocation: null,
        should_preserve_tote_after_putaway: false,
      },
    };
  },
  validations: {
    formData: {
      scanEachUnitRules: {
        required: requiredIf(function () {
          return this.formData.scanEachUnitEnabled;
        }),
      },
      scanEachUnitCustomers: {
        required: requiredIf(function () {
          return (
            (this.formData.scanEachUnitEnabled &&
              this.formData.scanEachUnitRules === "only") ||
            this.formData.scanEachUnitRules === "except"
          );
        }),
      },

      allowPutawayArossZonesRules: {
        required: requiredIf(function () {
          return this.formData.allowPutawayArossZonesEnabled;
        }),
      },
      allowPutawayArossZonesCustomers: {
        required: requiredIf(function () {
          return (
            (this.formData.allowPutawayArossZonesEnabled &&
              this.formData.allowPutawayArossZonesRules === "only") ||
            this.formData.allowPutawayArossZonesRules === "except"
          );
        }),
      },

      allowToSplitPutawayTaskRules: {
        required: requiredIf(function () {
          return this.formData.allowToSplitPutawayTaskEnabled;
        }),
      },
      allowToSplitPutawayTaskCustomers: {
        required: requiredIf(function () {
          return (
            (this.formData.allowToSplitPutawayTaskEnabled &&
              this.formData.allowToSplitPutawayTaskRules === "only") ||
            this.formData.allowToSplitPutawayTaskRules === "except"
          );
        }),
      },
      // stagingLocation: { required },
    },
  },
  methods: {
    togglePanel: function () {
      if (!this.expanded) {
        this.loadDataFromServer();
      }
      this.expanded = !this.expanded;
    },

    loadDataFromServer: function () {
      this.serverDataLoaded = false;
      const payload = {
        type: "putaway",
        warehouse_id: this.warehouseDetails.index,
      };
      return new Promise((resolve) => {
        ApiService.post(
          `/warehouse_management/main_configuration/create`,
          payload
        )
          .then(({ data }) => {
            this.serverData = data;
            this.setConfigurationData(data.configurations.details);
            this.defaultStagingLocation =
              data.configurations.default_location_id;
            this.serverDataLoaded = true;
            resolve(data);
          })
          .catch();
      });
    },

    setConfigurationData: function (data) {
      this.formData = {
        scanEachUnitEnabled: data[0].value,
        scanEachUnitRules: data[0].apply_rule_type,
        scanEachUnitCustomers: data[0].customers,

        allowPutawayArossZonesEnabled: data[1].value,
        allowPutawayArossZonesRules: data[1].apply_rule_type,
        allowPutawayArossZonesCustomers: data[1].customers,

        allowToSplitPutawayTaskEnabled: data[2].value,
        allowToSplitPutawayTaskRules: data[2].apply_rule_type,
        allowToSplitPutawayTaskCustomers: data[2].customers,

        should_preserve_tote_after_putaway: data[3].value,
      };
    },

    handleFormValidation: function (fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },

    setStagingLocationError: function () {
      this.stagingLocationError = !this.defaultStagingLocation
        ? "This field is required"
        : null;
    },

    submitConfiguration: function () {
      this.$v.$touch();
      this.setStagingLocationError();
      if (!this.$v.$invalid && !this.stagingLocationError) {
        const data = this.formData;
        const payload = {
          type: "putaway",
          warehouse_id: this.warehouseDetails.index,
          default_location_id: this.defaultStagingLocation,
          configurations: [
            {
              key: "is_scan_each_unit_when_putaway",
              value: data.scanEachUnitEnabled,
              apply_rule_type: data.scanEachUnitRules,
              customers:
                data.scanEachUnitRules === "all"
                  ? []
                  : data.scanEachUnitCustomers,
            },
            {
              key: "is_allow_putaway_across_zones",
              value: data.allowPutawayArossZonesEnabled,
              apply_rule_type: data.allowPutawayArossZonesRules,
              customers:
                data.allowPutawayArossZonesRules === "all"
                  ? []
                  : data.allowPutawayArossZonesCustomers,
            },
            {
              key: "is_allow_to_split_putaway_task",
              value: data.allowToSplitPutawayTaskEnabled,
              apply_rule_type: data.allowToSplitPutawayTaskRules,
              customers:
                data.allowToSplitPutawayTaskRules === "all"
                  ? []
                  : data.allowToSplitPutawayTaskCustomers,
            },
            {
              key: "should_preserve_tote_after_putaway",
              value: data.should_preserve_tote_after_putaway,
              apply_rule_type: "all",
              customers: [],
            },
          ],
        };
        this.$store.commit(SET_PAGE_LOADING, true);
        return new Promise(() => {
          ApiService.post(
            `/warehouse_management/main_configuration/store`,
            payload
          )
            .then(() => {
              Swal.fire({
                title: "Updated",
                text: "The Configuration settings are updated",
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              // this.expanded = false;
            })
            .catch()
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        });
      }
    },
    searchWarehouseLocation() {
      setTimeout(() => {
        if (this.warehouseSearch) {
          this.warehouseLoading = true;
          const payload = {
            warehouse_id: this.warehouseDetails.index,
            q: this.warehouseSearch,
            location_type: "missing",
          };
          new Promise((resolve) => {
            ApiService.post(`/wms/warehouses/search-location`, payload).then(
              ({ data }) => {
                this.serverData.locations = data.locations;
                this.warehouseLoading = false;
                resolve();
              }
            );
          });
        }
      }, 200);
    },
    clearDefaultMissingLocation() {
      if (!this.warehouseSearch && !this.defaultStagingLocation) {
        this.serverData.locations = [];
      }
    },
  },
  computed: {
    scanEachEunitRuleErrors: function () {
      return this.handleFormValidation("scanEachUnitRules", this);
    },
    scanEachUnitCustomersErrors: function () {
      return this.handleFormValidation("scanEachUnitCustomers", this);
    },
    allowPutawayAcroosZonesRuleErrors: function () {
      return this.handleFormValidation("allowPutawayArossZonesRules", this);
    },
    allowPutawayAcroosZonesCustomersSelectErrors: function () {
      return this.handleFormValidation("allowPutawayArossZonesCustomers", this);
    },
    allotToSplitPutawayTaskRuleErrors: function () {
      return this.handleFormValidation("allowToSplitPutawayTaskRules", this);
    },
    allotToSplitPutawayTaskCustomersSelectErrors: function () {
      return this.handleFormValidation(
        "allowToSplitPutawayTaskCustomers",
        this
      );
    },
  },
  watch: {
    defaultStagingLocation: function () {
      this.setStagingLocationError();
    },
  },
};
</script>
