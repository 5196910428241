<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    persistent
    transition="dialog-bottom-transition"
  >
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center py-3">
        <h4 class="mb-0 font-weight-bolder">
          {{ $t("details") }}
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--begin::Modal header-->
      <div
        class="modal-body d-flex flex-column max-h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!--begin::Row-->
        <div
          class="d-flex align-center mb-2"
          v-for="(item, index) in data"
          :key="index"
        >
          <label class="mb-0 font-weight-bolder text-body">
            {{ $t(`${item.title}`) }}:
          </label>
          <!--begin::Col-->
          <div class="ml-4">
            <span class="font-weight-medium text-dark-75">
              {{ $t(`${item.value}`) }}</span
            >
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            v-if="
              $store.getters.getWESConnectorPermissions.find(
                (perm) => perm.name === 'update'
              )
            "
            class="btn btn-info px-5 py-3 ls1"
            @click="triggerActionDialogForEdit"
          >
            {{ $t("edit") }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "TitleValueListModal",
  props: ["data", "triggerActionDialogForEdit"],
  data: () => ({
    dialog: false,
  }),
};
</script>
