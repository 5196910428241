<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="row w-100">
          <div class="col-12 col-sm-6">
            <v-select
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.customers"
              outlined
              dense
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6">
            <v-select
              v-model="formData.platform_id"
              label="Platform"
              item-text="text"
              item-value="index"
              :items="serverData.platform"
              outlined
              dense
              :error-messages="platform_idErrors"
              @input="$v.formData.platform_id.$touch()"
              @blur="$v.formData.platform_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.store_name"
              label="Store name"
              clearable
              outlined
              dense
              :error-messages="store_nameErrors"
              @input="$v.formData.store_name.$touch()"
              @blur="$v.formData.store_name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.store_reference"
              label="Store reference"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.store_url"
              label="Store url"
              clearable
              outlined
              dense
              :error-messages="store_urlErrors"
              @input="$v.formData.store_url.$touch()"
              @blur="$v.formData.store_url.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-select
              v-model="formData.default_order_type_id"
              label="Order type"
              item-text="text"
              item-value="index"
              :items="serverData.order_types"
              outlined
              dense
              :error-messages="default_order_type_idErrors"
              @input="$v.formData.default_order_type_id.$touch()"
              @blur="$v.formData.default_order_type_id.$touch()"
            ></v-select>
          </div>

          <!--          <div class="col-12 col-sm-6">-->
          <!--            <v-select-->
          <!--              v-model="formData.status"-->
          <!--              label="Status"-->
          <!--              item-text="text"-->
          <!--              item-value="index"-->
          <!--              :items="serverData.statuses"-->
          <!--              outlined-->
          <!--              dense-->
          <!--              :error-messages="statusErrors"-->
          <!--              @input="$v.formData.status.$touch()"-->
          <!--              @blur="$v.formData.status.$touch()"-->
          <!--            ></v-select>-->
          <!--          </div>-->
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.prefix_replace"
              label="Prefix replace"
              clearable
              min="0"
              type="number"
              @change="() => validateMinValue('prefix_replace', 0, 'formData')"
              outlined
              dense
              :error-messages="prefix_replaceErrors"
              @input="$v.formData.prefix_replace.$touch()"
              @blur="$v.formData.prefix_replace.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 text-center pb-4">
            <h3>Credentials</h3>
            <v-jsoneditor v-model="formData.credentials" :options="options" />
          </div>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="dialog = false"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
import VJsoneditor from "v-jsoneditor/src/index";

export default {
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  components: { VJsoneditor },
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      default_order_type_id: { required },
      prefix_replace: { required },
      status: { required },
      store_name: { required },
      store_url: { required },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      default_order_type_id: null,
      prefix_replace: null,
      status: 0,
      store_name: null,
      store_reference: null,
      store_url: null,
      credentials: {},
    },
    options: { mode: "code", navigationBar: true, mainMenuBar: false },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/datahub/integrationsNew/create")
        .then((response) => {
          this.serverData = response.data;
          this.resetEditForm();
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let sendData = this.formData;
      this.pageLoader(true);
      ApiService.post("/datahub/integrationsNew/store", sendData)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Integration data has been created`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = !this.dialog;
          // this.resetEditForm();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.formData = {
        default_order_type_id: null,
        prefix_replace: null,
        status: 0,
        store_name: null,
        store_reference: null,
        store_url: null,
        credentials: {},
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    default_order_type_idErrors: function () {
      return this.handleFormValidation("default_order_type_id", this);
    },
    prefix_replaceErrors: function () {
      return this.handleFormValidation("prefix_replace", this);
    },
    statusErrors: function () {
      return this.handleFormValidation("status", this);
    },
    store_nameErrors: function () {
      return this.handleFormValidation("store_name", this);
    },
    store_urlErrors: function () {
      return this.handleFormValidation("store_url", this);
    },
  },
};
</script>
