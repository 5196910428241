<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon svg-icon-5">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-6 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 px-0 pb-0 row">
          <div class="h-75px col-12">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="h-75px col-12">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
              :error-messages="descriptionErrors"
              @input="$v.formData.description.$touch()"
              @blur="$v.formData.description.$touch()"
            ></v-text-field>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      description: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      name: null,
      description: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },

    submitCreateForm() {
      this.$v.$touch();
      this.pageLoader(true);
      let data = this.formData;
      ApiService.post("/authorizations/user_types/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `User type has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        name: null,
        description: null,
      };
    },
  },
  computed: {
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    descriptionErrors: function () {
      return this.handleFormValidation("description", this);
    },
  },
};
</script>
