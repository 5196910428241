<template>
  <v-text-field
    outlined
    class="mx-1"
    v-model="filterData"
    @keydown="isTextInputEnterHited"
    :label="$t(filter['title'])"
    required
    clearable
    dense
  ></v-text-field>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";
export default {
  name: "FilterTextInput",
  props: {
    /**
      * @type {
          {
              name: String,
              title: String,
              related_columns: Array,
              type: String,
              values: Array,
          }
                }
    */
    filter: {
      required: true,
      type: Object,
    },
    submitFilterValues: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    filterData: null,
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = null;
    },
    isTextInputEnterHited(e) {
      if (e.keyCode === 13) {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
        this.submitFilterValues();
      }
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
  },
};
</script>
