<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :detailsComponent="componentForDetails"
      :delete_url="delete_url"
      :isFilterRequiredForExport="true"
      :urls="urls"
      :isWarehouseRequired="true"
    >
      <template
        v-slot:topActions="{
          permissions,
          selectedItems,
          pageLoader,
          updateTable,
        }"
      >
        <ScanCarton
          ref="scanCarton"
          :pageLoader="pageLoader"
          :refresher="updateTable"
        ></ScanCarton>
        <!-- <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('perform_putaway')"
        >
          <button
            class="btn btn--export-filter"
            @click.prevent="bulkCreateAction(selectedItems, updateTable)"
          >
            Perform Putaway
          </button>
        </div> -->
        <v-menu
          content-class="m-0 p-0"
          transition="scale-transition"
          offset-y
          bottom
          nudge-bottom="5"
          auto
          rounded
          v-if="permissions('perform_putaway')"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 mx-8': $vuetify.breakpoint.xsOnly }"
            >
              <button v-bind="attrs" v-on="on" class="btn btn--export-filter">
                <span class="svg-icon">
                  <v-icon size="18">mdi-package-variant</v-icon>
                </span>
                Perform Putaway
              </button>
            </div>
          </template>
          <div class="py-2 bg-white px-3 ma-0">
            <div
              class="drop-item poppins px-3 rounded-lg cursor-pointer my-2 py-1 bg-light bg-hover-light-info"
              @click.prevent="bulkCreateAction(selectedItems, updateTable)"
              v-if="permissions('perform_putaway')"
            >
              <div class="py-2 w-100 poppins font-size-lg text-center">
                Bulk
              </div>
            </div>
            <div
              class="drop-item poppins px-3 rounded-lg cursor-pointer my-2 py-1 bg-light bg-hover-light-info"
              @click="() => showScanCartonModal()"
              v-if="permissions('perform_putaway')"
            >
              <div class="py-2 w-100 poppins font-size-lg text-center">
                Scan Carton
              </div>
            </div>
          </div>
        </v-menu>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddAdjustment from "@/own/components/warehouseManagement/inventoryBalance/AddAdjustment";
import ScanCarton from "@/own/components/warehouseManagement/inventoryBalance/ScanCarton.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/inventoryBalance.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
export default {
  name: "InventoryBalance",
  components: { DataTable, ScanCarton },
  data: () => ({
    componentForEditing: null,
    componentForAdding: null,
    componentForDetails: AddAdjustment,

    delete_url: `${process.env.VUE_APP_BASE_URL}/fulfillment/bundles/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINVENTBALANCETableFilters",
      getTableState: "getINVENTBALANCETableState",
      getTableData: "getINVENTBALANCETableData",
      getTableHeaders: "getINVENTBALANCETableHeaders",
      getTableProperties: "getINVENTBALANCETableProperties",
      getTableExportUrl: "getINVENTBALANCEExportUrl",
      getTablePermissions: "getINVENTBALANCETablePermissions",
      getUploadType: "getINVENTBALANCEUploadType",
    },
    urls: {
      adjustment: `/warehouse_management/inventory_adjustments/create`,
      bulkCreate: "",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      if (this.selectedWarehouse) {
        await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
    showScanCartonModal() {
      this.$refs.scanCarton.toggleModal();
    },
    bulkCreateAction(items, updateTable) {
      if (items.length > 0) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const data = { ids: items.map((row) => row.id) };
        ApiService.post(
          "/warehouse_management/inventory_balances/create_putaway_task",
          data
        )
          .then(() => {
            Swal.fire("Putaway!", "Putaway performed.", "success");
            items = [];
            updateTable();
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one record!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
