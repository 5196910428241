<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1200"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          {{ this.mode === "copy" ? "Copy Record" : "Edit Record" }}
        </h3>
        <!--begin::Close-->
        <div class="btn btn-sm btn-icon btn-active-light" @click="toggleModal">
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row py-0 my-0">
          <input-group label="">
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.asn_number"
                label="ASN number"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model.number="formData.qty"
                label="Quantity"
                type="number"
                :min="0"
                required
                outlined
                dense
              />
            </div>
            <div
              class="my-0 py-0 col-12 col-sm-6 col-md-4"
              v-if="mode == 'edit'"
            >
              <v-text-field
                v-model="formData.available_qty"
                label="Available qty"
                type="number"
                :min="0"
                required
                outlined
                dense
                disabled
              />
            </div>
            <div
              class="my-0 py-0 col-12 col-sm-6 col-md-4"
              v-if="mode == 'edit'"
            >
              <v-text-field
                v-model="formData.allocated_qty"
                label="Allacoted qty"
                type="number"
                :min="0"
                required
                outlined
                dense
                read-only
                disabled
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.location_id"
                :items="serverData.locations"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="Location"
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.tote_id"
                label="Tote"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.lpn_id"
                label="LPN"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.good_or_damage"
                :items="serverData.goods"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="Good/Damage"
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.damage_reason_id"
                :items="serverData.damage_reasons"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="Damage reason"
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.is_on_hold"
                :items="[
                  { text: 'Yes', index: 1 },
                  { text: 'No', index: 0 },
                ]"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="Is on hold"
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.on_hold_reason_id"
                :items="serverData.on_hold_reasons"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="On hold reason"
              />
            </div>
          </input-group>
          <input-group label="">
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.batch_number"
                label="Batch number"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.customer_batch_number"
                label="Customer batch number"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.serial_no"
                label="Serial no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.serial_2_no"
                label="Serial(2) no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.lot_no"
                label="Lot no"
                required
                outlined
                dense
              />
            </div>

            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.invoice_no"
                label="Invoice no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.po_no"
                label="PO no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.roll_no"
                label="Roll no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
              >
                <v-date-picker
                  v-model="formData.exp_date"
                  clearable
                ></v-date-picker>

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.exp_date"
                    label="Expire date"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    outlined
                    clearable
                    hide-details
                  />
                </template>
              </v-menu>
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
              >
                <v-date-picker
                  v-model="formData.mfg_at"
                  clearable
                ></v-date-picker>

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.mfg_at"
                    label="Manufacture date"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    outlined
                    clearable
                    hide-details
                  />
                </template>
              </v-menu>
            </div>
          </input-group>
          <input-group label="">
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.net_weight"
                label="Net weight"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.gross_weight"
                label="Gross weight"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.base_uom_id"
                :items="serverData.base_uoms"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="UOM"
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.volume"
                label="Volume"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.volume_uom_id"
                :items="serverData.volume_uoms"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="Volume UOM"
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.container_no"
                label="Container no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.hs_code"
                label="HS code"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.unit_rate"
                label="Unit rate"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.unit_rate_currency_id"
                :items="serverData.currencies"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="Currency"
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.country_id"
                :items="serverData.countries"
                outlined
                item-name="text"
                item-value="index"
                dense
                clearable
                label="Country"
              ></v-autocomplete>
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.import_entry_no"
                label="Import entry"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.custom_entry_no"
                label="Customer entry"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.bl_no"
                label="BL no"
                required
                outlined
                dense
              />
            </div>
          </input-group>
          <input-group label="">
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.vin_no"
                label="Vin no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.engine_no"
                label="Engine no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.body_model"
                label="Body model"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.specification"
                label="Specification"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.drive"
                label="Drive"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.key_no"
                label="Key no"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.engine_capacity"
                label="Engine capacity"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.grade"
                label="Grade"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
              >
                <v-date-picker
                  v-model="formData.mfg_at"
                  clearable
                ></v-date-picker>

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.mfg_at"
                    label="Manufacture date"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    outlined
                    clearable
                    hide-details
                  />
                </template>
              </v-menu>
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                outlined
                offset-y
                dense
              >
                <v-date-picker
                  v-model="formData.year_of_mnf"
                  clearable
                  type="year"
                ></v-date-picker>

                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.year_of_mnf"
                    label="Manufacture year"
                    append-icon="mdi-calendar"
                    v-on="on"
                    v-bind="attrs"
                    dense
                    outlined
                    clearable
                    hide-details
                  />
                </template>
              </v-menu>
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.customer_pallet_id"
                label="Customer pallet"
                required
                outlined
                dense
              />
            </div>
            <div class="my-0 py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.condition"
                label="Condition"
                required
                outlined
                dense
              />
            </div>
          </input-group>
        </div>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn--export-filter px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { required } from "vuelidate/lib/validators";
import validationMixin from "@/own/mixins/validationMixin";
import SwalService from "@/core/services/swal.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import InputGroup from "@/own/components/fulfillment/skus/InputGroup.vue";

export default {
  name: "EditItem",
  mixins: [validationMixin],
  components: { InputGroup },
  validations: {
    formData: {
      id: { required },
    },
  },
  props: {
    refresher: {
      required: true,
      type: Function,
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      id: null,
      batch_number: null, //Batch Number
      customer_batch_number: null, //Customer Batch Number
      good_or_damage: null, //1 => good, 2 => damage // Good/Damage
      damage_reason_id: null, //Damage Reason
      qty: null, //Quantity
      available_qty: null, //Available Qty
      allocated_qty: null, //Allocated Qty
      location_id: null, //Location
      exp_date: null, //Exp Date
      tote_id: null, //Tote
      lpn_id: null, //LPN
      asn_number: null, //ASN Number
      base_uom_id: null, //UOM
      net_weight: null, //Net Weight
      gross_weight: null, //Gross Weight
      volume: null, //Volume
      volume_uom_id: null, //Volume UOM
      lot_no: null, //LOT No
      serial_no: null, //Serial No
      serial_2_no: null, //Serial(2) No
      invoice_no: null, //Invoice No
      po_no: null, //PO No
      mfg_at: null, //Manufacture Date
      country_id: null, //Country
      bl_no: null, //BL NO
      container_no: null, //Container No
      import_entry_no: null, //Import Entry No
      custom_entry_no: null, //Custom Entry No
      customer_pallet_id: null, //Customer Pallet
      is_on_hold: null, //1 => true, 0 => false //Is On Hold
      on_hold_reason_id: null, //On Hold Reason
      vin_no: null, //Vin No
      engine_no: null, //Engine No
      body_model: null, //Body Model
      specification: null, //Specification
      drive: null, //Drive
      key_no: null, //Key No
      engine_capacity: null, //Engine Capacity
      year_of_mnf: null, //Manufacture Year
      hs_code: null, //HS Code
      unit_rate: null, //Unit Rate
      unit_rate_currency_id: null, //Currency
      roll_no: null, //Roll No
      grade: null, //Grade
      condition: null, //Condition
    },
  }),
  methods: {
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post(
        `/warehouse_management/inventory_overview/${
          this.mode == "edit" ? "edit" : "get_source_inventory"
        }`,
        { ids: [this.$store.getters.getItemForAction.id] }
      )
        .then((response) => {
          this.serverData = response.data;
          this.formData = { ...this.serverData.inventories[0] };
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.pageLoader(true);
      const payload = { inventories: [{ ...this.formData }] };

      if (this.mode == "copy") {
        payload.source_inventory_id = this.$store.getters.getItemForAction.id;
      }
      ApiService.post(
        `/warehouse_management/inventory_overview/${
          this.mode == "edit" ? "update" : "copy"
        }`,
        payload
      )
        .then(() => {
          SwalService.successMessage({
            title: SwalService.titles.success,
            html: SwalService.messages.successifully_completed,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        id: null,
        batch_number: null, //Batch Number
        customer_batch_number: null, //Customer Batch Number
        good_or_damage: null, //1 => good, 2 => damage // Good/Damage
        damage_reason_id: null, //Damage Reason
        qty: null, //Quantity
        available_qty: null, //Available Qty
        allocated_qty: null, //Allocated Qty
        location_id: null, //Location
        exp_date: null, //Exp Date
        tote_id: null, //Tote
        lpn_id: null, //LPN
        asn_number: null, //ASN Number
        base_uom_id: null, //UOM
        net_weight: null, //Net Weight
        gross_weight: null, //Gross Weight
        volume: null, //Volume
        volume_uom_id: null, //Volume UOM
        lot_no: null, //LOT No
        serial_no: null, //Serial No
        serial_2_no: null, //Serial(2) No
        invoice_no: null, //Invoice No
        po_no: null, //PO No
        mfg_at: null, //Manufacture Date
        country_id: null, //Country
        bl_no: null, //BL NO
        container_no: null, //Container No
        import_entry_no: null, //Import Entry No
        custom_entry_no: null, //Custom Entry No
        customer_pallet_id: null, //Customer Pallet
        is_on_hold: null, //1 => true, 0 => false //Is On Hold
        on_hold_reason_id: null, //On Hold Reason
        vin_no: null, //Vin No
        engine_no: null, //Engine No
        body_model: null, //Body Model
        specification: null, //Specification
        drive: null, //Drive
        key_no: null, //Key No
        engine_capacity: null, //Engine Capacity
        year_of_mnf: null, //Manufacture Year
        hs_code: null, //HS Code
        unit_rate: null, //Unit Rate
        unit_rate_currency_id: null, //Currency
        roll_no: null, //Roll No
        grade: null, //Grade
        condition: null, //Condition
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function() {
      return !!this.serverData;
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
  },
};
</script>
