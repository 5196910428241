<template>
  <v-autocomplete
    outlined
    class="mx-1 poppins"
    :items="filter['values']"
    v-model="filterData"
    :label="$t(filter.title)"
    item-text="text"
    item-value="index"
    dense
    clearable
  ></v-autocomplete>
</template>

<script>
import { SET_FILTERS_DATA } from "@/core/services/store/filter.module";

export default {
  name: "FilterSelect",
  props: {
    /**
     * @type {
     {
     name: String,
     title: String,
     related_columns: Array,
     type: String,
     values: Array,
     }
     }
     */
    filter: {
      required: true,
      type: Object,
    },
  },
  data: () => ({
    filterData: null,
  }),
  mounted() {
    if (this.filterAllData[`${this.filter.name}`]) {
      this.filterData = this.filterAllData[`${this.filter.name}`];
    }
    if (
      this.filter.name === "warehouse" &&
      this.selectedWarehouse &&
      !this.$store.getters.getSkipAddingWarehouse
    ) {
      this.filterData = this.selectedWarehouse;
    }
  },
  computed: {
    filterAllData() {
      return this.$store.getters.getFiltersData;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    resetFilterData() {
      this.filterData = null;
    },
  },
  watch: {
    filterData: {
      handler() {
        const all = this.filterAllData;
        all[`${this.filter.name}`] = this.filterData;
        this.$store.commit(SET_FILTERS_DATA, all);
      },
      deep: true,
    },
    selectedWarehouse: function (val) {
      if (this.filter.name === "warehouse") {
        this.filterData = val;
      }
    },
  },
};
</script>
