<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :actionsComponent="actionsComponent"
      isWarehouseRequired
      disableSelectAllCheckbox
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import {
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  EXPORT_TABLE_DATA,
} from "@/core/services/store/standardPutaway.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ActionsComponent from "@/own/components/warehouseManagement/standardPutaway/ActionsComponent.vue";

export default {
  name: "StandardPutaway",
  components: { DataTable },
  data: () => ({
    actionsComponent: ActionsComponent,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getSTANDARDPUTAWAYTableFilters",
      getTableState: "getSTANDARDPUTAWAYTableState",
      getTableData: "getSTANDARDPUTAWAYTableData",
      getTableHeaders: "getSTANDARDPUTAWAYTableHeaders",
      getTableProperties: "getSTANDARDPUTAWAYTableProperties",
      getTablePermissions: "getSTANDARDPUTAWAYTablePermissions",
      getUploadType: "getSTANDARDPUTAWAYUploadType",
      getTableExportUrl: "getSTANDARDPUTAWAYExportUrl",
    },
  }),
  methods: {
    async initTableContent(skipIndexReq = false) {
      this.$store.commit(SET_PAGE_LOADING, true);

      if (!skipIndexReq) {
        this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
      }
      if (this.selectedWarehouse) {
        this.$store
          .dispatch(UPDATE_TABLE_DATA, { warehouse_id: this.selectedWarehouse })
          .then(() => {});
      } else {
        this.$store.commit(SET_PAGE_LOADING, false);
      }
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  beforeMount() {
    this.initTableContent();
  },
  watch: {
    selectedWarehouse() {
      if (this.selectedWarehouse) {
        this.initTableContent(true);
      }
    },
  },
};
</script>
