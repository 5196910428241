<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add zone</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row pb-4">
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              dense
              outlined
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              dense
              outlined
            ></v-text-field>
          </div>
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.notes"
              label="Notes"
              clearable
              dense
              outlined
            ></v-text-field>
          </div>

          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Active</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="activeLabel"
              v-model="formData.is_active"
              dense
            ></v-switch>
          </div>

          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Locked</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="lockedLabel"
              v-model="formData.is_locked"
              dense
            ></v-switch>
          </div>
          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Replenishment control</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="controlLabel"
              v-model="formData.is_replenishment_control"
              dense
            ></v-switch>
          </div>
        </div>
        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { GET_ZONES } from "@/core/services/store/warehouses.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddZone",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      is_active: { required },
      is_replenishment_control: { required },
      is_locked: { required },
    },
  },
  data: () => ({
    dialog: false,
    // selectedStateId: null,
    formData: {
      name: null,
      description: null,
      is_active: false,
      is_locked: false,
      is_replenishment_control: false,
      notes: null,
    },
  }),
  computed: {
    activeLabel() {
      return this.formData.is_active ? "Active" : "In active";
    },
    lockedLabel() {
      return this.formData.is_locked ? "Yes" : "No";
    },
    controlLabel() {
      return this.formData.is_replenishment_control ? "Yes" : "No";
    },
    selectedWarehouseId: function () {
      return this.$store.getters.getWAREHOUSESSelectedWarehouseId;
    },
    selectedZoneGroupId: function () {
      return this.$store.getters.getWAREHOUSESSelectedZoneGroupId;
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    is_activeErrors: function () {
      return this.handleFormValidation("is_active", this);
    },
    is_replenishment_controlErrors: function () {
      return this.handleFormValidation("is_replenishment_control", this);
    },
    is_lockedErrors: function () {
      return this.handleFormValidation("is_locked", this);
    },
  },
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let sendData = {
        type: "zone",
        warehouse_id: this.selectedWarehouseId,
        zone_group_id: this.selectedZoneGroupId,
        ...this.formData,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/warehouses/store", sendData)
        .then(() => {
          swalAdded();
          this.toggleModal();
          let payload = {
            zone_group_id: this.selectedZoneGroupId,
            warehouse_id: this.selectedWarehouseId,
            type: "zone",
          };
          this.$store.dispatch(GET_ZONES, payload).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetCreateForm() {
      this.formData = {
        name: null,
        description: null,
        is_active: false,
        is_locked: false,
        is_replenishment_control: false,
        notes: null,
      };
    },
  },
};
</script>
