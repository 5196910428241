<template>
  <v-card
    class="ma-0 pa-0 d-block shadow-none rounded-lg mt-0 mt-lg-3"
    v-if="isLoaded"
  >
    <v-card-title class="font-weight-bolder text-dark-65 py-2 mb-1 pt-5">{{
      item.title
    }}</v-card-title>
    <v-card-subtitle class="font-weight-bolder text-dark-25 py-2 mb-6"
      >Visualizing Shipping Rates</v-card-subtitle
    >
    <v-card-text class="d-block ma-0 pa-0 pb-2">
      <apexchart
        :options="chartOptions"
        :series="series"
        height="317px"
      ></apexchart>
    </v-card-text>
  </v-card>
  <v-skeleton-loader v-else type="card"></v-skeleton-loader>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "FulfillmentPerformanceDonutChartNew",
  props: ["item", "filterData", "updateChart"],
  data() {
    return {
      serverData: null,
      series: [],
      isLoaded: false,
    };
  },
  methods: {
    loadDataFromServer() {
      this.serverData = null;
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart.values;
          this.series = response.data.chart.values.series;
          setTimeout(() => {
            this.isLoaded = true;
          }, 50);
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadDataFromServer();
  },
  computed: {
    chartOptions: function () {
      return {
        colors: [
          "#5DA5DA",
          "#FAA43A",
          "#B2912F",
          "#F17CB0",
          "#DECF3F",
          "#3C1668",
        ],
        chart: {
          type: "donut",
        },
        // plotOptions: {
        //   pie: {
        //     startAngle: -90,
        //     endAngle: 90,
        //     offsetY: 10,
        //     customScale: 1,
        //   },
        // },
        grid: {
          padding: {
            bottom: 50,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 390,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        // fill: {
        //   // colors: this.serverData?.colors,
        //   colors: [
        //     "#5DA5DA",
        //     "#FAA43A",
        //     "#B2912F",
        //     "#F17CB0",
        //     "#DECF3F",
        //     "#3C1668",
        //   ],
        // },

        labels: this.labels,
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return String(val).slice(0, 3) + "%";
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => String(val).slice(0, 5) + "%",
          },
        },
      };
    },
    labels: function () {
      if (this.serverData?.labels.length) {
        return this.serverData.labels;
      }
      return [];
    },
  },
  watch: {
    // filterData: {
    //   deep: true,
    //   immediate: false,
    //   handler(newData, oldData) {
    //     if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
    //       this.serverData = null;
    //       this.loadDataFromServer();
    //     }
    //   },
    // },
    updateChart: function (val) {
      if (val !== 0) {
        this.loadDataFromServer();
      }
    },
  },
};
//TODO::Dasboard:: delete old component
</script>

<style>
@media (max-width: 480px) {
  .apexcharts-legend.apx-legend-position-bottom {
    padding-bottom: 3rem !important;
  }
}
</style>
