<template>
  <div class="mt-6">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      show-select
      :items="items"
      item-key="id"
      :server-items-length="items.length"
      :headers="headers"
      :items-per-page="itemPerPage"
      hide-default-footer
      mobile-breakpoint="300"
      @dblclick:row="dblClickOpen"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            v-model="allSelected"
            name="select-all"
            :value="true"
            class="form-check-input"
            type="checkbox"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            name="single-select"
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <!-- column -->
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item, value }">
        <v-btn
          class="btn iq-btn poppins mr-2"
          @click="() => toggleConfirmShippingModal(item)"
        >
          Ship
        </v-btn>
      </template>
    </v-data-table>
    <Pagination
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <Palletize
      ref="palletizer"
      :items="selected"
      :refresher="refresher"
    ></Palletize>
    <ShipByBox
      ref="shipbybox"
      :refresher="refresher"
      :confirmation="toggleConfirmShippingModal"
    ></ShipByBox>
    <DeliveryByBox
      ref="deliverybybox"
      :refresher="refresher"
      :confirmation="toggleConfirmShippingModal"
    ></DeliveryByBox>
  </div>
</template>

<script>
import ShipByBox from "@/own/components/warehouseManagement/shipping/ShipByBox.vue";
import DeliveryByBox from "@/own/components/warehouseManagement/shipping/DeliveryByBox.vue";

import Palletize from "@/own/components/warehouseManagement/shipping/Palletize.vue";
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import Pagination from "@/own/components/pagination/Pagination";
import {
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/shipping_wms.module";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "DataTable",
  props: {
    updateTableContent: {
      type: Function,
      required: true,
    },
    toggleConfirmShippingModal: {
      type: Function,
      required: true,
    },
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    Pagination,
    ComponentSelector,
    Palletize,
    ShipByBox,
    DeliveryByBox,
  },
  data: () => ({
    selected: [],
    allSelected: false,
  }),
  methods: {
    actionPalletize() {
      if (this.selected.length === 0) {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one record!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.$refs.palletizer.toggleModal();
    },
    actionShipByBox() {
      this.$refs.shipbybox.toggleModal();
    },
    actionDeliveryByBox() {
      this.$refs.deliverybybox.toggleModal();
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length > this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    refresher() {
      this.$store.dispatch(UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
      this.selected = [];
    },
    actionShipping() {
      if (this.selected.length === 0) {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one record!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      const hasPartiallyPackedItems = this.selected.find(
        (item) => item.status === "Partially Packed"
      );

      if (hasPartiallyPackedItems)
        Swal.fire({
          title: "Warning",
          text: "Selected items has partially packed ones. Do you want to continue ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#8950FC",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.commit(SET_PAGE_LOADING, true);
            const data = {
              orders: [],
              warehouse_id: this.selectedWarehouse,
            };
            this.selected.map((order) => {
              data.orders.push({
                order_delivery_number: order.order_delivery_number,
              });
            });

            ApiService.post("/warehouse_management/shipping/update", data)
              .then((response) => {
                let message = "<div>";
                response.data.messages.map((mes) => {
                  message = message + `<div>${mes}</div>`;
                });
                message = message + "</div>";
                Swal.fire({
                  title: `${response.data.success_orders} of ${response.data.total} completed!`,
                  html: `${message}`,
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2500,
                });
                this.refresher();
              })
              .finally(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
          }
        });
      else {
        this.$store.commit(SET_PAGE_LOADING, true);
        const data = {
          orders: [],
          warehouse_id: this.selectedWarehouse,
        };
        this.selected.map((order) => {
          data.orders.push({
            order_delivery_number: order.order_delivery_number,
          });
        });

        ApiService.post("/warehouse_management/shipping/update", data)
          .then((response) => {
            let message = "<div>";
            response.data.messages.map((mes) => {
              message = message + `<div>${mes}</div>`;
            });
            message = message + "</div>";
            Swal.fire({
              title: `${response.data.success_orders} of ${response.data.total} completed!`,
              html: `${message}`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.refresher();
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    dblClickOpen(_, item) {
      this.toggleConfirmShippingModal(item.item);
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getWMSSHIPPINGTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getWMSSHIPPINGTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getWMSSHIPPINGTableData?.total;
    },
    pageCount: function () {
      return this.$store.getters.getWMSSHIPPINGTableData?.last_page;
    },

    items: function () {
      let items = [];
      if (this.$store.getters.getWMSSHIPPINGTableData) {
        items = this.$store.getters.getWMSSHIPPINGTableData?.data;
      }
      return items;
    },
    itemPerPage: function () {
      return this.$store.getters.getWMSSHIPPINGTableState.per_page;
    },

    headers: function () {
      return this.$store.getters.getWMSSHIPPINGTableHeaders;
    },
  },
};
</script>
