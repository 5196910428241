<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Cycle count</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div
          v-if="!!isNextCycleAvailable"
          class="bg-secondary rounded p-6 mb-4"
        >
          <NextCycleCountInfo />
        </div>

        <v-radio-group
          v-model="count_category"
          row
          class="mb-4"
          @change="clearData"
        >
          <v-radio
            label="Location count"
            value="location"
            color="indigo"
          ></v-radio>
          <v-radio label="SKU count" value="sku" color="indigo"></v-radio>
        </v-radio-group>

        <div>
          <v-text-field
            v-model.trim="primary_field"
            :label="primaryFieldLabel"
            type="text"
            ref="primary"
            outlined
            autofocus
            :disabled="disabled.primary"
            dense
            clearable
            class="poppins"
            append-icon="mdi-barcode-scan"
            @click:append="enterPrimaryField"
            @keyup.enter="enterPrimaryField"
          />
        </div>

        <div v-if="showSecondaryField">
          <v-text-field
            v-model.trim="secondary_field"
            label="SKU"
            ref="secondary"
            type="text"
            outlined
            :disabled="disabled.secondary"
            dense
            clearable
            class="poppins"
            append-icon="mdi-barcode-scan"
            @click:append="enterSecondaryField"
            @keyup.enter="enterSecondaryField"
          />
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn--export-filter mr-3 px-5 py-3 ls1"
            @click="completeCount"
          >
            Complete
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <CompletedTasksSummary ref="cycleCountSummary" />
    <v-snackbar v-model="snackbarAlert" :timeout="2000" class="mb-10">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbarAlert = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2";
import {
  GET_CYCLE_COUNT,
  SET_NEXT_CYCLE_COUNT_TASK,
  SET_BLIND_CYCLE_COUNT,
  // SET_COUNT_TYPE,
  STORE_CYCLE_COUNT,
  UPDATE_TABLE_DATA,
  COMPLETE_CYCLE_COUNT,
} from "@/core/services/store/randomCycleCount.module";
import CompletedTasksSummary from "@/own/components/warehouseManagement/randomCycleCount/CompletedTasksSummary.vue";
import NextCycleCountInfo from "@/own/components/warehouseManagement/randomCycleCount/NextCycleCountInfo.vue";

export default {
  name: "CycleCountModal",
  props: ["pageLoading"],
  components: {
    CompletedTasksSummary,
    NextCycleCountInfo,
  },
  data: () => ({
    dialog: false,
    count_category: "location",
    snackbarAlert: null,
    snackbarMessage: "",
    primary_field: null,
    secondary_field: null,
    formData: {
      location: null,
      sku: null,
    },
    disabled: {
      primary: false,
      secondary: false,
    },
    sku_verified: false,
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearData();
      }
      this.count_category = "location";
      this.dialog = !this.dialog;
    },
    clearData() {
      this.$store.commit(SET_NEXT_CYCLE_COUNT_TASK, {});
      this.$store.commit(SET_BLIND_CYCLE_COUNT, false);
      // this.$store.commit(SET_COUNT_TYPE, "new_count");
      this.primary_field = null;
      this.secondary_field = null;
      this.formData = {
        location: null,
        sku: null,
      };
      this.disabled = {
        primary: false,
        secondary: false,
      };
      this.sku_verified = false;
    },
    enterPrimaryField() {
      if (this.primary_field) {
        /**
         * If count category is sku & main sku is verified, then
         * just shift the curson to location field. However if
         * the condition doesn't match, that means it's location
         * count & we're in first step of that.
         */
        if (this.count_category === "sku" && this.sku_verified) {
          this.$refs.secondary.focus();
        } else {
          this.pageLoading(true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            count_type: this.countType,
            count_category: this.count_category,
            /**
             * For location scan sku is not needed in request & for sku can location is not needed
             */
            ...(this.count_category === "location" && {
              location: this.primary_field,
            }),
            ...(this.count_category === "sku" && { sku: this.primary_field }),
          };
          this.$store.dispatch(GET_CYCLE_COUNT, payload).then((data) => {
            if (data?.success) {
              if (this.count_category === "location") {
                this.$store.dispatch(UPDATE_TABLE_DATA, {
                  warehouse_id: this.selectedWarehouse,
                });
                this.disabled.primary = true;
                this.$refs.secondary.focus();
              } else {
                /**
                 * For SKU count, we'll verify the main sku first. later
                 * we can reuse the two fields by an extra variable 'sku_verified'.
                 * once sku is verified we'll clear the sku field and show the location
                 * field for next request.
                 */
                Swal.fire({
                  title: "Verified",
                  icon: "success",
                  text: `SKU Verified!`,
                  timer: 2500,
                });
                this.sku_verified = true;
                this.primary_field = null;
              }
            }
          });
        }
      }
    },
    enterSecondaryField() {
      if (this.secondary_field) {
        this.pageLoading(true);
        const payload = {
          warehouse_id: this.selectedWarehouse,
          count_type: this.countType,
          count_category: this.count_category,
          location: this.primary_field,
          sku: this.secondary_field,
        };
        this.$store.dispatch(STORE_CYCLE_COUNT, payload).then((data) => {
          if (data?.success) {
            this.secondary_field = null;
            this.showSnackbarAlert(true, "Processed");
          } else {
            this.disabled.primary = false;
            this.secondary_field = null;
            this.$refs.primary.focus();
          }
        });
      }
    },
    completeCount() {
      if (!this.completedTasks.length) {
        Swal.fire({
          title: "No Cycle count",
          icon: "warning",
          text: `No Cycle count summary is available`,
          // timer: 2500,
        });
        return;
      }
      const payload = {
        warehouse_id: this.selectedWarehouse,
        cycle_count_number: this.nextCycleCount.cycle_count_number,
      };
      this.$store.dispatch(COMPLETE_CYCLE_COUNT, payload).then(() => {
        this.$refs.cycleCountSummary.toggleModal();
        this.toggleModal();
      });
    },
    showSnackbarAlert(val = true, message) {
      this.snackbarAlert = val;
      this.snackbarMessage = message;
    },
  },
  computed: {
    primaryFieldLabel: function () {
      return this.count_category === "sku" && !this.sku_verified
        ? "SKU"
        : "Location";
    },
    showSecondaryField: function () {
      return (
        (this.count_category === "sku" && this.sku_verified) ||
        this.count_category === "location"
      );
    },
    countType: function () {
      return this.$store.getters.getRANDOMCYCLECOUNTType;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    nextCycleCount: function () {
      return this.$store.getters.getRANDOMCYCLECOUNTNextCycleCountTask;
    },
    isNextCycleAvailable: function () {
      return JSON.stringify(this.nextCycleCount) !== "{}";
    },
    completedTasks: function () {
      return this.$store.getters.getRANDOMCYCLECOUNTCompletedTasks;
    },
  },
  mounted() {},
};
</script>
