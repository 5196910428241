section
<template>
  <section class="custom-width d-flex flex-column px-3">
    <table :id="`${tableName}`" style="table-layout: auto">
      <thead>
        <tr>
          <th
            class="border px-4 py-2 text-center text-capitalize font-weight-bolder font-size-h5"
          >
            {{ tableName }}
            <div v-if="amount && editType == 'location'">
              : <span>{{ amount ? amount : "" }}</span>
            </div>
            <div class="float-right">
              <button
                @click="handleAddFunction"
                class="pa-0 btn btn-icon-info ma-0"
              >
                <span class="svg-icon">
                  <v-icon size="24">mdi-plus-box-outline</v-icon>
                </span>
              </button>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <button
                    class="pa-0 btn btn-icon-info ml-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="svg-icon">
                      <v-icon size="24">mdi-file-upload-outline</v-icon>
                    </span>
                  </button>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in importTypes"
                    :key="index"
                    @click="handleImportFunction(item.name)"
                  >
                    <v-list-item-title>
                      <div
                        class="text-capitalize font-weight-bolder font-size-h6 text-dark-75"
                      >
                        {{ item.title }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <ImportData
                ref="importData"
                :type="editType"
                :label="tableName"
                :setData="importDataHandler"
                :importType="importType"
              >
              </ImportData>
              <button
                @click="handleExportFunction"
                class="pa-0 btn btn-icon-info ma-0"
                v-if="editType !== 'warehouse'"
              >
                <span class="svg-icon">
                  <v-icon size="24">mdi-file-download-outline</v-icon>
                </span>
              </button>
            </div>
          </th>
        </tr>
        <tr>
          <th class="border px-4 py-2">
            <input
              :id="`search-${tableName}`"
              type="search"
              class="border custom-input rounded w-100 py-2 px-3 text-dark-75"
              @keyup="filterInputs"
              :placeholder="`Search ${tableName}...`"
            />
          </th>
        </tr>
      </thead>
      <tbody :class="`${tableName}-tb`">
        <template v-if="items.length === 0 && tableName !== 'warehouses'">
          <tr>
            <td class="border px-4 py-2">{{ label }}</td>
          </tr>
        </template>
        <template v-else>
          <div class="" style="max-height: 55vh !important; overflow-y: scroll">
            <tr v-for="item in items" :key="item.id" class="d-block">
              <td
                class="border px-4 py-2 d-flex justify-space-between align-center"
              >
                <span
                  v-if="tableName !== 'warehouses'"
                  class="font-size-lg py-1 font-weight-medium ml-2"
                >
                  {{ item.name }}
                </span>
                <span v-else class="font-size-lg py-1 font-weight-medium ml-2">
                  {{ item.name }}
                  <span
                    class="d-inline-block bg-gray-200 rounded-lg ml-2 px-3 text-dark-65"
                  >
                    {{
                      item.system_code !== null
                        ? `${item.system_code}`
                        : `${item.name}`
                    }}
                  </span>
                </span>
                <div class="d-flex align-center justify-end">
                  <ActionsMenu :item-id="item.id" :editType="editType" />

                  <button
                    v-if="tableName !== 'locations'"
                    @click="(event) => handleGetFunction(item.id, event)"
                    class="btn btn-sm btn-icon btn-circle btn-bg-light btn-hover-light-info"
                    :ref="editType + 'ArrowButton'"
                  >
                    <span class="svg-icon">
                      <v-icon size="19"> mdi-arrow-right </v-icon>
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </div>

          <tr v-if="getWarehouseLoadMoreLocation && tableName == 'locations'">
            <td class="border">
              <v-btn class="w-100 ma-0 py-3" text @click="loadMoreLocations"
                >Load More
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </section>
</template>

<script>
import ImportData from "@/own/components/warehouseManagement/warehouses/ImportData.vue";
import ActionsMenu from "@/own/components/warehouseManagement/warehouses/ActionsMenu";

import swalCountry from "@/core/services/AlertServices/SwalCountry";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  GET_WAREHOUSES,
  GET_ZONEGROUPS,
  GET_ZONES,
  GET_LOCATIONS,
  GET_SEARCH_LOCATIONS,
  GET_MORE_LOCATIONS,
  SET_LOCATION_COUNT,
} from "@/core/services/store/warehouses.module";

export default {
  name: "NestedDataTable",
  components: { ActionsMenu, ImportData },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    editType: {
      type: String,
      required: true,
    },
    handleAddFunction: {
      type: Function,
      required: true,
    },
    handleGetFunction: {
      type: Function,
      required: false,
    },
    showExportSideBar: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    doneTypingInterval: 1500,
    typingTimer: null,
    importTypes: [
      { name: "create", title: "Import Create" },
      { name: "update", title: "Import Update" },
    ],
    importType: null,
  }),
  computed: {
    amount() {
      return this.$store.getters.getWarehouseLocationCount;
    },
    exportHeaders() {
      return this.editType == "warehouse"
        ? []
        : this.$store.getters.getWarehouseExportColumns[`${this.editType}`]
            .columns;
    },
    getImportType() {
      return this.importType;
    },
    getWarehouseLoadMoreLocation() {
      return this.$store.getters.getWarehouseLoadMoreLocation;
    },
    items: function () {
      if (this.editType === "warehouse")
        return this.$store.getters.getWAREHOUSESWarehousesData;
      else if (this.editType === "zone_group")
        return this.$store.getters.getWAREHOUSESZoneGroupsData;
      else if (this.editType === "zone")
        return this.$store.getters.getWAREHOUSESZonesData;
      else return this.$store.getters.getWAREHOUSESLocationsData;
    },
    label: function () {
      if (this.editType === "zone_group")
        return this.$store.getters.getWAREHOUSESZoneGroupsLabel;
      else if (this.editType === "zone")
        return this.$store.getters.getWAREHOUSESZonesLabel;
      else return this.$store.getters.getWAREHOUSESLocationsLabel;
    },
  },
  methods: {
    handleExportFunction() {
      if (this.editType === "warehouse")
        this.showExportSideBar(this.exportHeaders, this.editType);
      else if (this.editType === "zone_group") {
        if (!this.$store.getters.getWAREHOUSESSelectedWarehouseId) {
          swalCountry("Please, select warehouse");
        } else this.showExportSideBar(this.exportHeaders, this.editType);
      } else if (this.editType === "zone") {
        if (!this.$store.getters.getWAREHOUSESSelectedZoneGroupId) {
          swalCountry("Please, select zone group");
        } else this.showExportSideBar(this.exportHeaders, this.editType);
      } else {
        if (!this.$store.getters.getWAREHOUSESSelectedZoneId) {
          swalCountry("Please, select zone");
        } else this.showExportSideBar(this.exportHeaders, this.editType);
      }
    },
    loadMoreLocations() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store
        .dispatch(GET_MORE_LOCATIONS, {
          next_page_url: this.getWarehouseLoadMoreLocation,
          data: {
            warehouse_id: this.$store.getters.getWAREHOUSESSelectedWarehouseId,
            zone_group_id: this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
            zone_id: this.$store.getters.getWAREHOUSESSelectedZoneId,
            type: "location",
          },
        })
        .then((response) => {
          this.$store.commit(
            SET_LOCATION_COUNT,
            response.total ? response.total : 0
          );
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    importDataHandler() {
      if (this.editType === "warehouse") {
        this.$store
          .dispatch(GET_WAREHOUSES)
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else if (this.editType === "zone_group") {
        let payload = {
          warehouse_id: this.$store.getters.getWAREHOUSESSelectedWarehouseId,
          type: "zone_group",
        };
        this.$store
          .dispatch(GET_ZONEGROUPS, payload)
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else if (this.editType === "zone") {
        let payload = {
          warehouse_id: this.$store.getters.getWAREHOUSESSelectedWarehouseId,
          zone_group_id: this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
          type: "zone",
        };
        this.$store
          .dispatch(GET_ZONES, payload)
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      } else if (this.editType === "location") {
        let payload = {
          warehouse_id: this.$store.getters.getWAREHOUSESSelectedWarehouseId,
          zone_group_id: this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
          zone_id: this.$store.getters.getWAREHOUSESSelectedZoneId,
          type: "location",
        };
        this.$store
          .dispatch(GET_LOCATIONS, payload)
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    handleImportFunction(importType) {
      this.importType = importType;
      if (this.editType === "warehouse") this.showImportModal();
      else if (this.editType === "zone_group") {
        if (!this.$store.getters.getWAREHOUSESSelectedWarehouseId) {
          swalCountry("Please, select warehouse");
        } else this.showImportModal();
      } else if (this.editType === "zone") {
        if (!this.$store.getters.getWAREHOUSESSelectedWarehouseId) {
          swalCountry("Please, select warehouse");
        } else this.showImportModal();
      } else {
        if (!this.$store.getters.getWAREHOUSESSelectedWarehouseId) {
          swalCountry("Please, select warehouse");
        } else this.showImportModal();
      }
    },
    showImportModal() {
      this.$nextTick(() => {
        this.$refs.importData.toggleModal();
      });
    },
    filterInputs() {
      if (this.editType === "location") {
        clearTimeout(this.typingTimer);
        let input = document.getElementById("search-" + this.tableName);
        if (input.value == "") {
          this.typingTimer = setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, true);
            let payload = {
              warehouse_id:
                this.$store.getters.getWAREHOUSESSelectedWarehouseId,
              zone_group_id:
                this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
              zone_id: this.$store.getters.getWAREHOUSESSelectedZoneId,
              type: "location",
            };
            this.$store.dispatch(GET_LOCATIONS, payload).finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
          }, this.doneTypingInterval);
          return;
        }
        this.typingTimer = setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, true);
          let payload = {
            warehouse_id: this.$store.getters.getWAREHOUSESSelectedWarehouseId,
            zone_group_id: this.$store.getters.getWAREHOUSESSelectedZoneGroupId,
            zone_id: this.$store.getters.getWAREHOUSESSelectedZoneId,
            type: "location",
            q: input.value,
          };
          this.$store.dispatch(GET_SEARCH_LOCATIONS, payload).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        }, this.doneTypingInterval);
      } else {
        let input, filter, table, tr, td, i, txtValue;
        input = document.getElementById("search-" + this.tableName);
        filter = input.value.toUpperCase();
        table = document.getElementById(this.tableName);
        tr = table.getElementsByTagName("tr");

        // Loop through all table rows, and hide those who don't match the search query
        for (i = 0; i < tr.length; i++) {
          td = tr[i].getElementsByTagName("td")[0];
          if (td) {
            txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
td:hover {
  background: #fafafa;
}

.custom-input {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  outline: none;
  font-weight: normal;
}

.custom-input:focus {
  box-shadow: 0 0 0 3px rgb(66 153 225 / 50%);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.custom-width {
  width: 100%;
}

@media (min-width: 769px) {
  .custom-width:first-of-type {
    width: 31%;
  }

  .custom-width {
    width: 23%;
  }
}
</style>
