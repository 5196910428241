<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="650"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add region</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="h-75px">
          <v-text-field
            v-model="formData.title"
            dense
            label="Title"
            clearable
            outlined
          ></v-text-field>
        </div>
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.countries"
            dense
            label="Countries"
            item-text="text"
            item-value="index"
            clearable
            outlined
            multiple
            deletable-chips
            chips
            :items="serverData.countries"
            hide-no-data
          ></v-autocomplete>
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { GET_REGIONS } from "@/core/services/store/shipping_regions.module";

export default {
  name: "AddCountry",
  data: () => ({
    dialog: false,
    serverData: {},
    formData: {
      title: "",
      countries: [],
    },
  }),
  computed: {
    currencies: function () {
      return this.$store.getters.getSHIPPINGCOUNTRIESCurrencies;
    },
  },
  mounted() {
    this.loadDataFromServer();
  },
  methods: {
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/shipping/regions/create?type = regions")
        .then((response) => {
          this.serverData = response.data;
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.dialog = false;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    submitCreateForm() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/shipping/regions/store", this.formData)
        .then(() => {
          swalAdded();
          this.toggleModal();
          this.$store.dispatch(GET_REGIONS).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetCreateForm() {
      this.formData = {
        title: null,
        countries: null,
      };
    },
  },
};
</script>
