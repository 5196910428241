<template>
  <div>
    <v-row
      align="center"
      justify="space-between"
      class="pb-0"
      style="height: 110px"
    >
      <v-col cols="12" md="5" class="p-0 m-0">
        <p class="font-size-lg font-weight-medium mb-0">{{ label }}</p>
      </v-col>
      <v-col cols="7" class="p-0 m-0 d-flex justify-content-end">
        <slot></slot>
      </v-col>
    </v-row>
    <v-row class="p-0 p-0" style="margin-top: -20px">
      <v-col v-if="divider" cols="12" class="p-0 m-0">
        <div class="separator separator-dashed"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FieldContainer",
  props: ["label", "divider"],
};
</script>
