<template>
  <v-dialog v-model="dialog" persistent max-width="850">
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ headerText }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column h-600px scroll-y mx-5 py-7">
        <div class="min-h-75px">
          <v-autocomplete
            v-model="formData.qc_category_id"
            label="QC Category"
            item-text="text"
            item-value="index"
            dense
            outlined
            clearable
            :items="qcCategories"
            hide-no-data
            hide-selected
            :error-messages="qcCategoryIdErrors"
            @input="$v.formData.qc_category_id.$touch()"
            @blur="$v.formData.qc_category_id.$touch()"
          />
        </div>
        <div class="min-h-75px">
          <v-autocomplete
            v-model="formData.status"
            label="Status"
            item-text="text"
            item-value="index"
            dense
            outlined
            clearable
            :items="statusValues"
            hide-no-data
            hide-selected
            :error-messages="statusErrors"
            @input="$v.formData.status.$touch()"
            @blur="$v.formData.status.$touch()"
          />
        </div>
        <v-textarea
          v-model="formData.instructions"
          label="Instructions"
          class="mb-6"
          height="300"
          dense
          outlined
          clearable
          hide-details
          :error-messages="instructionsErrors"
          @input="$v.formData.instructions.$touch()"
          @blur="$v.formData.instructions.$touch()"
        />
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  EDIT_CHECKLIST_INSTRUCTIONS,
  SET_CHECKLIST_INSTRUCTIONS,
} from "@/core/services/store/qcChecklists.module";

export default {
  name: "AddEditInstruction",
  mixins: [validationMixin],
  validations: {
    formData: {
      qc_category_id: { required },
      instructions: { required },
      status: { required },
    },
  },
  data: () => ({
    dialog: false,
    lastElementIndex: null,
    formData: {
      id: null,
      qc_category_id: null,
      instructions: null,
      status: null,
    },
  }),
  methods: {
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();

      if (this.lastElementIndex !== null) {
        let data = {
          newItem: this.formData,
          lastElementIndex: this.lastElementIndex,
        };
        this.$store.commit(EDIT_CHECKLIST_INSTRUCTIONS, data);
      } else this.$store.commit(SET_CHECKLIST_INSTRUCTIONS, this.formData);

      this.toggleModal();
    },
    resetFormData() {
      this.$v.$reset();
      this.formData = {
        qc_category_id: null,
        instructions: null,
        status: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetFormData();
      this.dialog = !this.dialog;
    },
    initData(index, newItem) {
      this.lastElementIndex = index;
      this.formData = newItem;
    },
  },
  computed: {
    headerText: function () {
      if (this.lastElementIndex !== null) return "Edit Item";
      else return "Add New Item";
    },
    qcCategories: function () {
      return this.$store.getters.getQCCHECKLISTSQCCategories;
    },
    statusValues: function () {
      return this.$store.getters.getQCCHECKLISTSStatus;
    },
    qcCategoryIdErrors: function () {
      return this.handleFormValidation("qc_category_id");
    },
    instructionsErrors: function () {
      return this.handleFormValidation("instructions");
    },
    statusErrors: function () {
      return this.handleFormValidation("status");
    },
  },
};
</script>
