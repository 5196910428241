<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Column</h3>
        <!--begin::Close-->
        <!--        <div-->
        <!--          class="btn btn-sm btn-icon btn-active-light-info"-->
        <!--          @click="toggleModal(null)"-->
        <!--        >-->
        <!--          <span class="svg-icon">-->
        <!--            <v-icon size="22">mdi-close</v-icon>-->
        <!--          </span>-->
        <!--        </div>-->
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-8 row">
          <div class="col-12">
            <v-text-field
              v-model="formData.label"
              label="Label"
              clearable
              hide-details
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12">
            <v-radio-group
              label="Options"
              v-model="formData.type"
              row
              @change="
                () => {
                  formData.type_value = null;
                }
              "
            >
              <v-radio label="None" :value="null"></v-radio>
              <v-radio label="Formula" value="formula"></v-radio>
              <v-radio label="Static" value="static"></v-radio>
            </v-radio-group>
          </div>

          <div class="col-12" v-if="formData.type === 'formula'">
            <v-select
              v-model="formData.type_value"
              :items="availableFormulas"
              item-text="text"
              item-value="index"
              clearable
              dense
              hide-details
              outlined
              label="Value"
            />
          </div>
          <div class="col-12" v-if="formData.type === 'static'">
            <v-text-field
              v-model="formData.type_value"
              label="Value"
              clearable
              hide-details
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-checkbox
              v-model="formData.has_total"
              :true-value="true"
              :false-value="false"
              dense
              hide-details
              label="Has total"
            />
          </div>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-2 d-flex flex-grow-1 align-end justify-center">
          <!--          <button-->
          <!--            type="reset"-->
          <!--            class="btn btn-light mr-3 px-5 py-3 ls1"-->
          <!--            @click="resetCreateForm"-->
          <!--          >-->
          <!--            Clear-->
          <!--          </button>-->
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import SwalService from "@/core/services/swal.service";

export default {
  name: "EditColumn",
  props: ["availableFormulas"],
  data: () => ({
    dialog: false,
    formData: {},
  }),
  methods: {
    toggleModal(column = null) {
      if (this.dialog) {
        this.formData = {};
      } else {
        this.formData = column;
      }
      this.dialog = !this.dialog;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    submitCreateForm() {
      if (this.formData?.type) {
        if (this.formData.type_value) {
          this.toggleModal(null);
        } else {
          SwalService.warningMessage({
            html: "Please, fill the option value!",
          });
          return;
        }
      } else {
        this.toggleModal(null);
      }
    },
  },
};
</script>
