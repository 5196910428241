<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="700"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column max-h-700px scroll-y mx-5 py-7">
        <form class="row">
          <div
            class="col-12 h-75px"
            v-if="
              !$store.getters.currentUser.data.is_client && !isOrganizationSPL
            "
          >
            <v-autocomplete
              v-model="formData.customer_id"
              :label="$t('customer')"
              :items="serverData.customers"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="customer_idErrors"
              @blur="$v.formData.customer_id.$touch()"
            />
          </div>

          <div class="col-12 h-75px" v-if="!isOrganizationSPL">
            <v-text-field
              v-model="formData.reference_number"
              :label="$t('reference-number')"
              dense
              outlined
              clearable
              :error-messages="reference_numberErrors"
              @blur="$v.formData.reference_number.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.username"
              :label="$t('username')"
              dense
              outlined
              clearable
              :error-messages="usernameErrors"
              @blur="$v.formData.username.$touch()"
            />
          </div>

          <div class="col-12 h-75px">
            <v-text-field
              v-model="formData.password"
              :label="$t('password')"
              dense
              outlined
              clearable
              :error-messages="passwordErrors"
              @blur="$v.formData.password.$touch()"
            />
          </div>
        </form>

        <!--begin::Actions-->
        <div class="mb-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light me-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            {{ $t("clear") }}
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import validationMixin from "@/own/mixins/validationMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "ActionsDialog",
  mixins: [validationMixin],
  props: ["refresher", "pageLoader"],
  validations: {
    formData: {
      customer_id: {
        required: requiredIf(function () {
          return !this.isOrganizationSPL;
        }),
      },
      reference_number: {
        required: requiredIf(function () {
          return !this.isOrganizationSPL;
        }),
      },
      username: { required },
      password: {
        required: requiredIf(function () {
          return !this.actionType;
        }),
      },
    },
  },
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      reference_number: null,
      username: null,
      password: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        this.pageLoader(true);
        ApiService.post("/wes/wesAccounts/edit", sendData)
          .then(({ data }) => {
            this.serverData = data;
            this.formData = { ...data.data };
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        this.pageLoader(true);
        ApiService.post("/wes/wesAccounts/create")
          .then(({ data }) => {
            this.serverData = data;
            this.pageLoader(false);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    resetFormData() {
      this.formData = {
        customer_id: null,
        reference_number: null,
        username: null,
        password: null,
      };
    },

    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$store.commit(SET_PAGE_LOADING, true);

      let sendData = {
        username: this.formData.username,
        password: this.formData.password,
      };

      if (!this.isOrganizationSPL) {
        sendData.customer_id = this.formData.customer_id;
        sendData.reference_number = this.formData.reference_number;
      }

      let actionUrl = "store";
      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
        actionUrl = "update";
      }
      ApiService.post(`/wes/wesAccounts/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) {
            SwalService.successMessage({
              html: SwalService.messages.updated(),
            });
          } else {
            SwalService.successMessage({
              html: SwalService.messages.added(),
            });
          }
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    isOrganizationSPL() {
      return (
        this.$store.getters.currentUser.data.organization_system_code === "SPL"
      );
    },
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return this.$t("update");
      else return this.$t("submit");
    },
    headerText: function () {
      if (this.actionType) return this.$t("edit_item");
      else return this.$t("add_new_item");
    },

    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    reference_numberErrors: function () {
      return this.handleFormValidation("reference_number", this);
    },
    usernameErrors: function () {
      return this.handleFormValidation("username", this);
    },
    passwordErrors: function () {
      return this.handleFormValidation("password", this);
    },
  },
};
</script>
