<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="resetCreateForm"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="d-flex justify-space-between align-start my-3">
          <v-file-input
            v-model="files[0]"
            @change="handleChange"
            accept="application/pdf"
            :error-messages="
              errorState[0] === true ? 'File should be the pdf' : null
            "
            id="fileInput0"
            outlined
            show-size
            truncate-length="18"
            placeholder="Click to add file"
            class="w-50 pr-3"
            clearable
            prepend-icon="mdi-file-pdf-box"
            hide-details="auto"
            dense
          ></v-file-input>
          <div class="d-flex align-center w-50 justify-space-between">
            <input
              type="text"
              v-model="titles[0]"
              class="form-control h-40px mr-3"
              placeholder="File title"
            />
            <a
              href="#"
              class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm"
              @click="() => handleRemove(0)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <v-icon size="20">mdi-delete</v-icon>
              </span>
            </a>
          </div>
        </div>
        <template v-for="item in count">
          <div
            class="d-flex justify-space-between align-start my-3"
            :key="item"
          >
            <v-file-input
              v-model="files[item]"
              @change="handleChange"
              accept="application/pdf"
              :error-messages="
                errorState[item] === true ? 'File should be the pdf' : null
              "
              outlined
              :id="'fileInput' + item"
              show-size
              truncate-length="18"
              placeholder="Click to add file"
              class="w-50 pr-3"
              clearable
              prepend-icon="mdi-file-pdf-box"
              hide-details="auto"
              dense
            ></v-file-input>
            <div class="d-flex align-center w-50 justify-space-between">
              <input
                type="text"
                v-model="titles[item]"
                class="form-control h-40px mr-3"
                placeholder="File title"
              />
              <a
                href="#"
                class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm"
                @click="() => handleRemove(item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <v-icon size="20">mdi-delete</v-icon>
                </span>
              </a>
            </div>
          </div>
        </template>

        <div class="d-flex justify-end my-2">
          <a
            class="w-120px py-3 px-4 float-right btn btn-sm btn-primary me-2"
            @click="handleAttach"
            >Add more files</a
          >
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Cancel
          </button>
          <button class="btn btn-info px-5 py-3 ls1" @click="submitCreateForm">
            Upload
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
// import QueueUpload from "@/own/components/dropzone/QueueUpload";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
export default {
  name: "QueueUploadModal",
  // components: { QueueUpload },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    files: [null],
    titles: [""],
    errorState: [false],
    count: 0,
  }),
  methods: {
    handleAttach() {
      this.count++;
      this.files.push(null);
      this.titles.push("");
      this.errorState.push(false);
    },
    handleChange(file) {
      if (file.type === "application/pdf") {
        this.$set(this.errorState, this.count, false);
      } else this.$set(this.errorState, this.count, true);
    },
    handleRemove(index) {
      // document.getElementById(`fileInput${index}`).click();
      if (this.files.length !== 1) {
        this.files.splice(index, 1);
        this.titles.splice(index, 1);
        this.errorState.splice(index, 1);
        this.count--;
      }
    },
    toggleModal() {
      this.resetCreateForm();
    },
    async submitCreateForm() {
      let formData = new FormData();
      formData.append("id", this.$store.getters.getItemForAction.id.id);
      this.files.forEach((item, index) => {
        if (item.type === "application/pdf") {
          formData.append(`files[]`, item);
          this.$set(this.errorState, index, false);
        } else this.$set(this.errorState, index, true);
      });

      this.titles.forEach((item) => {
        formData.append(`titles[]`, item);
      });
      let hasError = this.errorState.find((item) => item === true);
      if (!hasError) {
        this.pageLoader(true);
        await ApiService.post(
          `${process.env.VUE_APP_BASE_URL}/fulfillment/orders/upload_doc`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then(() => {
            Swal.fire({
              title: "Uploaded",
              text: `Record has been successfully uploaded!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoader(false);
            this.resetCreateForm();
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.files.splice(1);
      this.$set(this.files, 0, null);

      this.titles.splice(1);
      this.$set(this.titles, 0, "");

      this.errorState.splice(1);
      this.$set(this.errorState, 0, false);

      this.count = 0;
      this.dialog = !this.dialog;
    },
  },
};
</script>
