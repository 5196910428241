<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="860"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add location</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <div class="row pb-6">
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.name"
              dense
              label="Name"
              clearable
              outlined
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-autocomplete
              v-model="formData.location_type_id"
              label="Location type"
              item-text="text"
              item-value="index"
              :items="serverData.location_types"
              clearable
              outlined
              dense
              :error-messages="location_type_idErrors"
              @input="$v.formData.location_type_id.$touch()"
              @blur="$v.formData.location_type_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-autocomplete
              v-model="formData.location_attribute_id"
              label="Location attribute"
              item-text="text"
              item-value="index"
              :items="serverData.location_attributes"
              clearable
              outlined
              dense
              :error-messages="location_attribute_idErrors"
              @input="$v.formData.location_attribute_id.$touch()"
              @blur="$v.formData.location_attribute_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-autocomplete
              v-model="formData.location_category_id"
              label="Location category"
              item-text="text"
              item-value="index"
              :items="serverData.location_categories"
              clearable
              outlined
              dense
              :error-messages="location_category_idErrors"
              @input="$v.formData.location_category_id.$touch()"
              @blur="$v.formData.location_category_id.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 my-0 py-0">
            <v-text-field
              v-model="formData.notes"
              dense
              label="Notes"
              clearable
              outlined
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.putaway_sequence"
              type="number"
              min="0"
              dense
              label="Putaway sequence"
              clearable
              outlined
              :error-messages="putaway_sequenceErrors"
              @input="$v.formData.putaway_sequence.$touch()"
              @blur="$v.formData.putaway_sequence.$touch()"
              @change="
                (value) =>
                  onChangePositiveNumberMaker(value, 'putaway_sequence', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.pick_sequence"
              type="number"
              min="0"
              dense
              label="Pick sequence"
              clearable
              outlined
              :error-messages="pick_sequenceErrors"
              @input="$v.formData.pick_sequence.$touch()"
              @blur="$v.formData.pick_sequence.$touch()"
              @change="
                (value) =>
                  onChangePositiveNumberMaker(value, 'pick_sequence', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.capacity_length"
              type="number"
              min="0"
              dense
              label="Capacity length"
              clearable
              outlined
              @change="
                (value) =>
                  onChangePositiveNumberMaker(value, 'capacity_length', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.capacity_width"
              type="number"
              min="0"
              dense
              label="Capacity width"
              clearable
              outlined
              @change="
                (value) =>
                  onChangePositiveNumberMaker(value, 'capacity_width', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.capacityheight"
              type="number"
              min="0"
              dense
              label="Capacity height"
              clearable
              outlined
              @change="
                (value) =>
                  onChangePositiveNumberMaker(value, 'capacityheight', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.weight_capacity"
              type="number"
              min="0"
              dense
              label="Weight capacity"
              clearable
              outlined
              @change="
                (value) =>
                  onChangePositiveNumberMaker(value, 'weight_capacity', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.cubic_capacity"
              type="number"
              min="0"
              dense
              label="Cubic capacity"
              clearable
              outlined
              @change="
                (value) =>
                  onChangePositiveNumberMaker(value, 'cubic_capacity', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.sku_count"
              type="number"
              min="0"
              dense
              label="Sku count"
              clearable
              outlined
              @change="
                (value) => onChangePositiveNumberMaker(value, 'sku_count', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.each_count"
              type="number"
              min="0"
              dense
              label="Each count"
              clearable
              outlined
              @change="
                (value) => onChangePositiveNumberMaker(value, 'each_count', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.case_count"
              type="number"
              min="0"
              dense
              label="Case count"
              clearable
              outlined
              @change="
                (value) => onChangePositiveNumberMaker(value, 'case_count', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-4 my-0 py-0">
            <v-text-field
              v-model="formData.pallet_count"
              type="number"
              min="0"
              dense
              label="Pallet count"
              clearable
              outlined
              @change="
                (value) => onChangePositiveNumberMaker(value, 'pallet_count', 0)
              "
            ></v-text-field>
          </div>
          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Allow mix sku</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="mixSkuLabel"
              v-model="formData.is_allow_mix_sku"
              dense
            ></v-switch>
          </div>
          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Allow mix lot</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="mixLotLabel"
              v-model="formData.is_allow_mix_lot"
              dense
            ></v-switch>
          </div>
          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Active</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="activeLabel"
              v-model="formData.is_active"
              dense
            ></v-switch>
          </div>

          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Locked</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="lockedLabel"
              v-model="formData.is_locked"
              dense
            ></v-switch>
          </div>
          <div class="col-12 d-flex align-center my-0 py-0 h-50px">
            <h4 class="pl-2 font-weight-regular">Replenishment control</h4>
            <div class="col-4 col-sm-2"></div>
            <v-switch
              :label="controlLabel"
              v-model="formData.is_replenishment_control"
              dense
            ></v-switch>
          </div>
        </div>

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import { GET_LOCATIONS } from "@/core/services/store/warehouses.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddLocation",
  mixins: [validationMixin],
  validations: {
    formData: {
      name: { required },
      location_type_id: { required },
      location_attribute_id: { required },
      location_category_id: { required },
      putaway_sequence: { required },
      pick_sequence: { required },
    },
  },
  data: () => ({
    dialog: false,
    // selectedCityId: null,
    serverData: null,
    formData: {
      location_type_id: null,
      location_attribute_id: null,
      location_category_id: null,
      name: null,
      putaway_sequence: null,
      pick_sequence: null,
      is_allow_mix_sku: false,
      is_allow_mix_lot: false,
      capacity_length: null,
      capacity_width: null,
      capacity_height: null,
      weight_capacity: null,
      cubic_capacity: null,
      sku_count: null,
      each_count: null,
      case_count: null,
      pallet_count: null,
      notes: null,

      is_active: false,
      is_locked: false,
      is_replenishment_control: false,
    },
  }),
  computed: {
    mixLotLabel() {
      return this.formData.is_allow_mix_lot ? "Yes" : "No";
    },
    mixSkuLabel() {
      return this.formData.is_allow_mix_sku ? "Yes" : "No";
    },
    selectedWarehouseId: function () {
      return this.$store.getters.getWAREHOUSESSelectedWarehouseId;
    },
    selectedZoneGroupId: function () {
      return this.$store.getters.getWAREHOUSESSelectedZoneGroupId;
    },
    selectedZoneId: function () {
      return this.$store.getters.getWAREHOUSESSelectedZoneId;
    },
    nameErrors: function () {
      return this.handleFormValidation("name", this);
    },
    location_type_idErrors: function () {
      return this.handleFormValidation("location_type_id", this);
    },
    location_attribute_idErrors: function () {
      return this.handleFormValidation("location_attribute_id", this);
    },
    location_category_idErrors: function () {
      return this.handleFormValidation("location_category_id", this);
    },
    putaway_sequenceErrors: function () {
      return this.handleFormValidation("putaway_sequence", this);
    },
    pick_sequenceErrors: function () {
      return this.handleFormValidation("pick_sequence", this);
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    activeLabel() {
      return this.formData.is_active ? "Active" : "In active";
    },
    lockedLabel() {
      return this.formData.is_locked ? "Yes" : "No";
    },
    controlLabel() {
      return this.formData.is_replenishment_control ? "Yes" : "No";
    },
  },
  methods: {
    onChangePositiveNumberMaker(
      value,
      obj_name,
      min = 0,
      max = -1,
      data_name = "formData"
    ) {
      if (value < min) {
        this[data_name][obj_name] = min;
      } else if (max > 0 && value > max) {
        this[data_name][obj_name] = max;
      }
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    toggleModal() {
      if (!this.dialog) {
        this.loadDataFromServer();
      } else {
        this.resetCreateForm();
        this.serverData = null;
      }
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = {
        type: "location",
        zone_group_id: this.selectedZoneGroupId,
        warehouse_id: this.selectedWarehouseId,
        zone_id: this.selectedZoneId,
      };
      ApiService.post(`/warehouse_management/warehouses/create`, data)
        .then((response) => {
          this.serverData = response.data;
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.dialog = false;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    submitCreateForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let sendData = {
        zone_group_id: this.selectedZoneGroupId,
        warehouse_id: this.selectedWarehouseId,
        zone_id: this.selectedZoneId,
        type: "location",
        ...this.formData,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/warehouses/store", sendData)
        .then(() => {
          swalAdded();
          this.toggleModal();
          let payload = {
            zone_group_id: this.selectedZoneGroupId,
            warehouse_id: this.selectedWarehouseId,
            zone_id: this.selectedZoneId,
            type: "location",
          };
          this.$store.dispatch(GET_LOCATIONS, payload).finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    resetCreateForm() {
      this.formData = {
        location_type_id: null,
        location_attribute_id: null,
        location_category_id: null,
        name: null,
        putaway_sequence: null,
        pick_sequence: null,
        is_allow_mix_sku: false,
        is_allow_mix_lot: false,
        capacity_length: null,
        capacity_width: null,
        capacity_height: null,
        weight_capacity: null,
        cubic_capacity: null,
        sku_count: null,
        each_count: null,
        case_count: null,
        pallet_count: null,
        notes: null,

        is_active: false,
        is_locked: false,
        is_replenishment_control: false,
      };
    },
  },
};
</script>
