<template>
  <div class="mr-sm-2 mb-1 mr-0 pa-0">
    <button
      :disabled="!packageCode || !packageId"
      @click.prevent="toggleModal"
      class="btn btn--export-filter"
    >
      {{ $t("update_package") }}
    </button>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      max-width="850"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins min-h-500px">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">
            {{ $t("update_package_code") }}
          </h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <FormBuilder
            v-if="isLoaded"
            :formFields="formFields"
            :defaultValues="defaultValues"
            :onSubmit="submitForm"
          ></FormBuilder>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import FormBuilder from "@/own/components/FormBuilder.vue";

export default {
  name: "PackageDimensionUpdate",
  props: {
    submitUrl: { required: true },
    packageCode: { required: true },
    packageId: { required: true },
    uoms: { required: true },
    asnId: { required: true },
  },
  components: { FormBuilder },
  data() {
    return {
      dialog: false,

      formData: {
        service_id: null,
        abbreviation: null,
        length: null,
      },

      defaultValues: {},
      isLoaded: false,
    };
  },
  methods: {
    async toggleModal() {
      if (!this.dialog) {
        this.isLoaded = false;
        this.defaultValues = {};
        this.getDefaultDimension();
      }
      this.dialog = !this.dialog;
    },

    submitForm(data) {
      let payload = {
        package_type_id: this.packageId,
        warehouse_id: this.selectedWarehouse,
        id: this.asnId,
        ...data,
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(this.submitUrl, payload)
        .then(() => {
          swalEdited();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    getDefaultDimension() {
      let payload = {
        filters: [{ name: "system_code", value: this.packageCode }],
      };
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        "warehouse_management/custom/package_codes/datatable",
        payload
      )
        .then(({ data }) => {
          const dimensions = data.data.data[0];
          const { height, length, width, gross_weight } = dimensions;
          this.defaultValues = { height, length, width, weight: gross_weight };
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.isLoaded = true;
        });
    },
  },
  computed: {
    actionItem: function () {
      return this.$store.getters.getItemForAction;
    },
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    formFields: function () {
      return [
        {
          field_text: "",
          field_name: "package_code",
          field_type: "text",
          is_required: true,
          is_editable: false,
          default_value: this.packageCode,
          display_order: 1,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "dimension_uom_id",
          field_type: "select",
          is_required: true,
          is_editable: true,
          default_value: this.defaultDimensionUom?.index,
          display_order: 2,
          url: "",
          values: this.uoms,
        },
        {
          field_text: "",
          field_name: "length",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: this.defaultValues.length,
          display_order: 3,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "width",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: this.defaultValues.width,
          display_order: 5,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "height",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: this.defaultValues.height,
          display_order: 4,
          url: "",
          values: "",
        },
        {
          field_text: "",
          field_name: "weight_uom_id",
          field_type: "select",
          is_required: true,
          is_editable: true,
          default_value: this.defaultWeighUom?.index,
          display_order: 6,
          url: "",
          values: this.uoms,
        },
        {
          field_text: "",
          field_name: "weight",
          field_type: "number",
          is_required: true,
          is_editable: true,
          default_value: this.defaultValues.weight,
          display_order: 7,
          url: "",
          values: "",
        },
      ];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    defaultWeighUom: function () {
      return this.uoms.find((uom) => uom.index === 5 || uom.index === 5);
    },
    defaultDimensionUom: function () {
      return this.uoms.find((uom) => uom.index === 8 || uom.index === 8);
    },
  },
};
</script>
