<template>
  <div class="mt-5 ml-4" v-if="!selectedWarehouse && !!table_properties">
    <datatable-detail
      class="col-9 col-md-9 col-sm-9"
      :table_properties="table_properties"
    />
    <h1
      v-if="!selectedWarehouse"
      class="text-center grey--text lighten-5 mt-20"
    >
      No data available
    </h1>
  </div>
  <div
    class="p-4 bg-white rounded-lg"
    v-else-if="selectedWarehouse && isDataLoaded"
  >
    <DataTable />
  </div>
</template>

<script>
import DataTable from "@/own/components/warehouseManagement/skippedFulfillmentTasks/DataTable.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import {
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/skippedFulfillmentTasks.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "SkippedFulfillmentTasks",
  components: { DataTable, DatatableDetail },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
    },
    store_names: {
      getTableData: "getSKIPPEDFULFILLMENTTASKSSTableData",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        warehouse_id: this.selectedWarehouse,
      });
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    table_properties: function () {
      return this.$store.getters.getSKIPPEDFULFILLMENTTASKSSTableProperties;
    },
  },
  async beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    if (!this.selectedWarehouse) {
      this.$store.commit(SET_PAGE_LOADING, false);
    } else {
      this.initTableContent();
    }
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
        this.$store.dispatch(UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
