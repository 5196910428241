import { render, staticRenderFns } from "./LoginNew.vue?vue&type=template&id=3c2a732a&scoped=true"
import script from "./LoginNew.vue?vue&type=script&lang=js"
export * from "./LoginNew.vue?vue&type=script&lang=js"
import style0 from "./LoginNew.vue?vue&type=style&index=0&id=3c2a732a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c2a732a",
  null
  
)

export default component.exports