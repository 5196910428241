<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Confim shipping</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div v-if="orderDetails.order" class="bg-secondary rounded p-6 mb-3">
          <v-row>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded mt-0">
                <v-col cols="auto" class="font-weight-black py-0 pr-0">
                  Order id:
                </v-col>
                <v-col class="py-0">{{ orderDetails.order.ordersId }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  System order number:
                </v-col>
                <v-col>{{ orderDetails.order.system_order_number }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Order delivery number:
                </v-col>
                <v-col>{{ orderDetails.order.order_delivery_number }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Warehouse:
                </v-col>
                <v-col>{{ orderDetails.order.warehouse }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div v-if="orderDetails.order" class="bg-secondary rounded p-6 mb-3">
          <v-row>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded mt-0">
                <v-col cols="auto" class="font-weight-black py-0 pr-0">
                  Customer:
                </v-col>
                <v-col class="py-0">{{ orderDetails.order.customer }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Order type:
                </v-col>
                <v-col>{{ orderDetails.order.order_type }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Comment:
                </v-col>
                <v-col>{{ orderDetails.order.comment }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Total ordered quantity:
                </v-col>
                <v-col>{{ orderDetails.order.total_ordered_qty }}</v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-row class="bg-secondary rounded">
                <v-col cols="auto" class="font-weight-black pr-0">
                  Total packed quantity:
                </v-col>
                <v-col>{{ orderDetails.order.total_packed_qty }}</v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="mt-6">
          <v-data-table
            item-key="id"
            :item-class="secondText"
            :headers="headers"
            :items="orderDetails.order_boxes"
            :items-per-page="10"
            dense
            class="elevation-1"
          >
            <template #item.action="{ item, header, value }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <b-btn variant="info" size="sm" v-bind="attrs" v-on="on"
                    >Print</b-btn
                  >
                </template>
                <v-list>
                  <v-list-item
                    dense
                    v-for="(print_type, index) in print_types"
                    :key="index"
                    @click="printBarcode(item, print_type.index)"
                  >
                    <v-list-item-title>{{ print_type.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="toggleModal"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="confirmShipping(processingUrl)"
          >
            Confirm
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { SET_CONFIRM_ORDER_DATA } from "@/core/services/store/shipping_wms.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ConfirmShippingModal",
  props: ["refresher", "pageLoader", "confirmShipping", "processingUrl"],
  data: () => ({
    dialog: false,
    print_types: [
      { text: "ZPL", index: "zpl" },
      { text: "PDF", index: "pdf" },
    ],
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.$store.commit(SET_CONFIRM_ORDER_DATA, {});
      }
      this.dialog = !this.dialog;
    },
    secondText() {
      return "second-text";
    },
    printBarcode(item, print_type) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: item.id, type: print_type };
      ApiService.post("/warehouse_management/b2b_pick_and_pack/print", data)
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.path;
          link.target = "_blank";
          // link.download = "orders.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 2500);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
  },
  computed: {
    orderDetails: function () {
      return this.$store.getters.getWMSSHIPPINGConfirmOrderData;
    },
    headers: function () {
      return [
        {
          text: "Box number",
          value: "box_no",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Number of SKUS",
          value: "no_of_skus",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Total SKU Quantities",
          value: "total_sku_quantites",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Description",
          value: "description",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          class: "text-muted fw-bolder text-uppercase poppins-ls",
          cellClass: "text-dark-75 font-weight-medium poppins-ls",
        },
      ];
    },
  },
};
</script>
