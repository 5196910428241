<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Palletize</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model="formData.location"
            label="Location"
            clearable
            outlined
            append-icon="mdi-barcode-scan"
            :error-messages="locationErrors"
            @input="$v.formData.location.$touch()"
            @blur="$v.formData.location.$touch()"
          ></v-text-field>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import { UPDATE_ENTITYCOLUMNS_DATA } from "@/core/services/store/entities.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      location: { required },
    },
  },
  name: "Palletize",
  props: ["items", "refresher"],
  data: () => ({
    dialog: false,
    formData: {
      location: null,
    },
  }),
  beforeMount() {},
  methods: {
    pageLoader(state = false) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },

    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      // if ("email" in vueObj.$v[fieldName]) {
      //   !vueObj.$v[fieldName].email &&
      //     errors.push("This email address is invalid");
      // }
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal() {
      if (this.dialog) {
        this.resetCreateForm();
      } else {
        // this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      // let data = new FormData();
      // Object.entries(this.formData).forEach((item) => {
      //   if ((item[1] && item[0] != "image") || item[1] == 0) {
      //     data.append(item[0], item[1]);
      //   } else if (item[1] && item[0] == "image") {
      //     data.append(item[0], item[1][0]);
      //   }
      // });
      let data = { ...this.formData };
      data.order_ids = [...this.items.map((item) => item.id)];
      data.warehouse_id = this.selectedWarehouse;
      // let date = { ...this.formData }.received_time;
      // this.formData.received_time = `${date.getTime() / 1000}`;
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          // let data = JSON.stringify(this.entCreate);
          let data = this.convertToFormData();
          // console.log("data to send is ", data);
          ApiService.post(
            "/warehouse_management/shipping/palletize-orders",
            data
          )
            .then(() => {
              Swal.fire({
                title: "Palletized",
                text: `Successfully palletized!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.refresher();
              this.resetCreateForm();
              this.toggleModal();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        location: null,
      };
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },

    locationErrors: function () {
      return this.handleFormValidation("location", this);
    },
  },
};
</script>
