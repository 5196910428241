<template>
  <div>
    <div class="">
      <v-alert
        dense
        border="left"
        type="warning"
        dismissible
        v-if="exampleFileData[0].need_to_show_message"
      >
        <span v-html="exampleFileData[0].message"></span>
      </v-alert>
    </div>
    <div class="px-6">
      <div class="" v-if="organizations">
        <v-autocomplete
          clearable
          dense
          outlined
          v-model="formData.organization_id"
          :items="organizations"
          item-text="text"
          item-value="index"
          :placeholder="$t('organization')"
          :error-messages="organization_idErrors"
          @input="$v.formData.organization_id.$touch()"
          @blur="$v.formData.organization_id.$touch()"
        />
      </div>
      <div class="" v-if="!isClient">
        <v-autocomplete
          clearable
          dense
          outlined
          v-model="formData.client"
          :items="filteredClients"
          item-text="text"
          item-value="index"
          :placeholder="$t('choose_client')"
          :error-messages="clientErrors"
          @input="$v.formData.client.$touch()"
          @blur="$v.formData.client.$touch()"
        />
      </div>
      <div class="row">
        <div class="col-12 col-sm-8 col-md-9">
          <v-file-input
            v-model="formData.file"
            clearable
            ref="file_uploader"
            :label="$t('file_input')"
            outlined
            dense
            required
            :error-messages="fileErrors"
            @input="$v.formData.file.$touch()"
            @blur="$v.formData.file.$touch()"
          />
        </div>
        <div class="col-12 col-sm-4 col-md-3 text-center">
          <v-btn class="primary--text" outlined text :href="exampleFileData">
            {{ $t("download_template") }}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
      <button
        type="reset"
        class="btn btn-light mr-3 px-5 py-3 ls1"
        @click="resetForm"
      >
        {{ $t("clear") }}
      </button>
      <button
        type="submit"
        class="btn btn--export-filter px-5 py-3 ls1"
        @click="submitForm"
      >
        {{ $t("upload") }}
      </button>
    </div>
  </div>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  name: "SkuForm",
  mixins: [validationMixin],
  validations() {
    return {
      formData: {
        file: { required },
        organization_id: {
          required: requiredIf(function () {
            return Boolean(this.organizations);
          }),
        },
        client: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
      },
    };
  },
  props: {
    importTypeData: {
      required: true,
      type: Object,
    },
    submit: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    formData: {
      organization_id: null,
      client: null,
      file: null,
    },
    serverFilteredClients: [],
  }),
  mounted() {
    this.serverFilteredClients = [...this.clients];
  },
  watch: {
    selectedOrganization(newValue) {
      // console.log("newValue", newValue);
      if (newValue) {
        this.serverFilteredClients = this.clients.filter(
          (client) => client.organization_id === newValue
        );
      } else this.serverFilteredClients = [...this.clients];
    },
  },
  computed: {
    selectedOrganization() {
      return this.formData.organization_id;
    },
    filteredClients() {
      return this.serverFilteredClients;
    },
    organization_idErrors() {
      return this.handleFormValidation("organization_id", this);
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    organizations: function () {
      if (this.importTypeData.data.hasOwnProperty("organizations"))
        return this.importTypeData.data.organizations;
      else return null;
    },
    clients: function () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.serverFilteredClients = [...this.importTypeData.data.customers];
      return this.importTypeData.data.customers;
    },
    exampleFileData: function () {
      const sample = this.importTypeData.data.files[0].url;
      return sample;
    },
    clientErrors() {
      return this.handleFormValidation("client", this);
    },
    fileErrors() {
      return this.handleFormValidation("file", this);
    },
  },
  methods: {
    submitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      let data = new FormData();
      data.append("file", this.formData.file, this.formData.file.name);
      data.append("type", this.importTypeData.type);
      if (!this.isClient) {
        data.append("customer_id", this.formData.client);
      }
      // if (this.importTypeData.data.hasOwnProperty("organizations")) {
      if (this.organizations) {
        data.append("organization_id", this.formData.organization_id);
      }
      this.submit(data);
      this.$nextTick(() => {
        this.resetForm();
      });
    },
    resetForm() {
      this.formData = {
        organization_id: null,
        client: null,
        file: null,
      };
    },
  },
};
</script>

<style></style>
