<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Scan Putaway</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div>
          <div>
            <v-row>
              <v-col cols="12">
                <div>
                  <v-text-field
                    v-model.trim="formData.tote_id"
                    label="Tote"
                    clearable
                    outlined
                    autofocus
                    dense
                    :disabled="disabled.tote_id"
                    append-icon="mdi-barcode-scan"
                    @click:append="onEnterTote"
                    @keyup.enter="onEnterTote"
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model.trim="formData.location"
                    ref="location"
                    label="Location"
                    clearable
                    outlined
                    dense
                    :disabled="disabled.location"
                    append-icon="mdi-barcode-scan"
                    @click:append="onEnterLocation"
                    @keyup.enter="onEnterLocation"
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model.trim="formData.sku"
                    ref="sku"
                    label="SKU"
                    clearable
                    outlined
                    dense
                    :disabled="disabled.sku"
                    append-icon="mdi-barcode-scan"
                    @click:append="onEnterSku"
                    @keyup.enter="onEnterSku"
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model.trim="formData.qty"
                    ref="qty"
                    label="Quantity"
                    clearable
                    outlined
                    dense
                    :disabled="disabled.qty"
                    :hint="'Expected quantity is ' + expected_qty"
                    append-icon="mdi-barcode-scan"
                    @click:append="onEnterQty"
                    @keyup.enter="onEnterQty"
                    type="number"
                    @change="
                      () => validateMinValue('number_of_boxes', 0, 'pre_alert')
                    "
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            class="btn btn-info px-5 mx-5 py-3 ls1"
            @click.prevent="clearLocation"
          >
            Clear Location
          </button>
        </div>
        <!--end::Actions-->
        <v-snackbar
          v-model="putaway_success"
          class="mb-5"
          bottom
          color="light-green darken-3"
          elevation="24"
          width="initial"
          min-width="100"
        >
          <span class="text-xl">Putaway updated successfully.</span>
        </v-snackbar>
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_PUTAWAY_MODAL_TABLE_DATA,
  ADJUST_PUTAWAY_MODAL_TABLE_DATA,
} from "@/core/services/store/putawayTasks.module";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "ManualScannerModal",
  mixins: [fieldValueValidation],
  data: () => ({
    dialog: false,
    formData: {
      tote_id: null,
      location: null,
      sku: null,
      qty: 1,
    },
    disabled: {
      tote_id: false,
      location: true,
      sku: true,
      qty: true,
    },
    expected_qty: null,
    putaway_success: false,
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.formData = {
          tote_id: null,
          location: null,
          sku: null,
          qty: 1,
        };
        this.disabled = {
          tote_id: false,
          location: true,
          sku: true,
          qty: true,
        };
      }
      this.dialog = !this.dialog;
    },
    validateField(field, value) {
      this.$store.commit(SET_PAGE_LOADING, true);

      const payload = {
        warehouse_id: this.selectedWarehouse,
        validate_field: field,
        ...(field === "tote_id" && { tote_id: value }),
        ...(field === "location" && { location: value }),
      };

      ApiService.post(
        `/warehouse_management/putaway_tasks/validate_manual_putaway`,
        payload
      )
        .then(({ data }) => {
          if (data.success) {
            const nextField = field == "tote_id" ? "location" : "sku";
            this.disabled[field] = true;
            this.disabled[nextField] = false;
            setTimeout(() => {
              this.$refs[nextField].focus();
            }, 100);
          }
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    onEnterTote() {
      if (this.formData.tote_id) {
        this.putaway_success = false;
        this.validateField("tote_id", this.formData.tote_id);
      }
    },
    onEnterLocation() {
      if (this.formData.location) {
        this.validateField("location", this.formData.location);
      }
    },
    updatePutaway() {
      if (this.scanningType === "manual_bulk") {
        if (this.formData.qty > this.expected_qty) {
          Swal.fire({
            title: "Invalid qunatity",
            text: "Quantity limit exceeds.",
            icon: "warning",
            showConfirmButton: true,
            showCancelButton: false,
            focusConfirm: true,
            timer: 2500,
          });
          this.$store.commit(SET_PAGE_LOADING, false);
          return;
        }
      }

      const payload = {
        warehouse_id: this.selectedWarehouse,
        ...this.formData,
      };

      ApiService.post(
        `/warehouse_management/putaway_tasks/update_manual_putaway`,
        payload
      )
        .then(({ data }) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          // Swal.fire({
          //   title: "Success",
          //   text: "Putaway updated successfully.",
          //   icon: "success",
          //   showConfirmButton: false,
          //   showCancelButton: false,
          //   focusConfirm: true,
          //   timer: 2500,
          // });
          this.putaway_success = true;

          //tote
          if (data.is_empty_tote) {
            this.disabled.tote_id = false;
          }

          //location
          if (data.is_location_full) {
            this.disabled.location = false;
            this.formData.location = null;
          }

          this.formData.sku = null;

          if (this.scanningType === "manual_bulk") {
            this.formData.qty = 1;
            setTimeout(() => {
              this.$refs.sku.focus();
            }, 2510);
            this.disabled.qty = true;
          }

          const task = data.putaway_task;
          const duplicateIndex = this.putawayTableData.findIndex(
            (item) =>
              item.tote_id === task.tote_id &&
              item.sku === task.sku &&
              item.location === task.location
          );
          // const duplicateIndex = this.putawayTableData.findIndex(
          //   (item) =>
          //     item.tote_id === task.tote_id && item.location === task.location
          // );
          if (duplicateIndex === -1) {
            this.$store.commit(SET_PUTAWAY_MODAL_TABLE_DATA, task);
          } else {
            this.$store.commit(ADJUST_PUTAWAY_MODAL_TABLE_DATA, {
              index: duplicateIndex,
              payload: task,
            });
          }
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    onEnterSku() {
      this.$store.commit(SET_PAGE_LOADING, true);
      if (this.scanningType === "manual_single") {
        this.updatePutaway();
      } else if (this.scanningType === "manual_bulk") {
        const payload = {
          warehouse_id: this.selectedWarehouse,
          show_type: "manual",
          tote_id: this.formData.tote_id,
          sku: this.formData.sku,
          location: this.formData.location,
        };
        ApiService.post(
          `/warehouse_management/putaway_tasks/show`,
          payload
        ).then(({ data }) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.expected_qty = data.putaway_task.expected_qty;
          this.disabled.qty = false;
          setTimeout(() => {
            this.$refs.qty.focus();
          }, 100);
        });
      }
    },
    onEnterQty() {
      this.submit();
    },
    submit() {
      if (this.scanningType === "manual_single") {
        this.toggleModal();
      } else if (this.scanningType === "manual_bulk") {
        this.$store.commit(SET_PAGE_LOADING, true);
        this.updatePutaway();
      }
    },
    clearModal() {
      this.formData = {
        tote_id: null,
        location: null,
        sku: null,
        qty: 1,
      };
      this.disabled = {
        tote_id: false,
        location: true,
        sku: true,
        qty: true,
      };
    },
    clearLocation() {
      if (this.formData.location) {
        this.formData.location = null;
        this.disabled.location = false;
        setTimeout(() => {
          this.$refs.location.focus();
        });
      }
    },
  },
  computed: {
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
    scanningType() {
      return this.$store.getters.getPUTAWAYScanningActionType;
    },
    putawayTableData() {
      return this.$store.getters.getPUTAWAYModalTableData;
    },
  },
  mounted() {},
};
</script>
