<template>
  <ConfigurationSettings />
</template>

<script>
import ConfigurationSettings from "@/own/components/warehouseManagement/mainConfiguration/ConfigurationSettings";
export default {
  components: { ConfigurationSettings },
  name: "MainConfiguration",
};
</script>
