import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

// action types
export const UPDATE_TABLE_STRUCTURE = "getWESBFLCONTAINERSTableStructure";
export const UPDATE_TABLE_DATA = "getWESBFLCONTAINERSTableData";
// export const EXPORT_TABLE_DATA = "exportWESBFLCONTAINERSTableData";

// mutation types
export const SET_TABLE_STRUCTURE = "setWESBFLCONTAINERSTableStructure";
export const SET_TABLE_DATA = "setWESBFLCONTAINERSTableData";
export const SET_TABLE_FILTER = "setWESBFLCONTAINERSTableFilter";
export const SET_TABLE_PAGE = "setWESBFLCONTAINERSTablePage";
export const SET_TABLE_PERPAGE = "setWESBFLCONTAINERSTablePerPage";
export const SET_TABLE_SORTBY = "setWESBFLCONTAINERSTableSortBy";
export const SET_TABLE_SORTORDER = "setWESBFLCONTAINERSTableSortOrder";
// export const SET_EXPORT_LINK = "setWESBFLCONTAINERSExportLink";

const state = {
  // export_link: null,
  table_structure: {},
  table_data: {},
  table_state: {
    page: 1,
    per_page: 50,
    sortBy: "id",
    sortOrder: "desc",
    filters: [],
  },
};

const getters = {
  getWESBFLCONTAINERSTableProperties(state) {
    return state.table_structure.properties;
  },
  getWESBFLCONTAINERSTablePermissions(state) {
    return state.table_structure.permissions;
  },
  getWESBFLCONTAINERSTableState(state) {
    return state.table_state;
  },

  getWESBFLCONTAINERSTableHeaders(state) {
    return state.table_structure.columns;
  },

  getWESBFLCONTAINERSTableFilters(state) {
    return state.table_structure.filters;
  },

  getWESBFLCONTAINERSTableData(state) {
    return state.table_data.data;
  },

  // getWESBFLCONTAINERSExportUrl(state) {
  //   return state.export_link;
  // },
};

const actions = {
  [UPDATE_TABLE_STRUCTURE](context) {
    return new Promise((resolve) => {
      ApiService.post("/wes/wesBflContainers")
        .then(({ data }) => {
          context.commit(SET_TABLE_STRUCTURE, data);
          resolve(data);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  [UPDATE_TABLE_DATA](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return new Promise((resolve) => {
      ApiService.post("/wes/wesBflContainers/datatable", payload)
        .then(({ data }) => {
          context.commit(SET_TABLE_DATA, data);
          resolve(data);

          context.commit(SET_PAGE_LOADING, false);
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 100);
        })
        .catch((data) => {
          context.commit(SET_PAGE_LOADING, false);
          resolve(data);
        });
    });
  },
  // [EXPORT_TABLE_DATA](context, payload) {
  //   context.commit(SET_PAGE_LOADING, true);
  //   return new Promise((resolve) => {
  //     ApiService.post("/wes/wesBflContainers/export", payload)
  //       .then(({ data }) => {
  //         context.commit(SET_EXPORT_LINK, data.url);
  //         resolve(data);
  //       })
  //       .catch((data) => {
  //         context.commit(SET_PAGE_LOADING, false);
  //         resolve(data);
  //       });
  //   });
  // },
};

const mutations = {
  [SET_TABLE_FILTER](state, filter) {
    state.table_state.filters = filter;
  },
  [SET_TABLE_PAGE](state, page) {
    state.table_state.page = page;
  },
  [SET_TABLE_PERPAGE](state, perPage) {
    state.table_state.per_page = perPage;
  },
  [SET_TABLE_SORTBY](state, sortBy) {
    state.table_state.sortBy = sortBy;
  },
  [SET_TABLE_SORTORDER](state, sortOrder) {
    state.table_state.sortOrder = sortOrder;
  },
  [SET_TABLE_STRUCTURE](state, table_structure) {
    state.table_structure = table_structure.data;
  },
  [SET_TABLE_DATA](state, table_data) {
    state.table_data = table_data;
  },
  // [SET_EXPORT_LINK](state, link) {
  //   state.export_link = link;
  // },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
