<template>
  <!-- begin:: Footer -->
  <div
    :class="[
      'footer bg-white py-4 d-flex flex-lg-column poppins position-fixed bottom-0 w-100',
      { 'rtl-view': $vuetify.rtl },
    ]"
    id="kt_footer"
  >
    <div
      class="d-flex align-content-center justify-content-between mr-8"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center">
        <a
          href="https://iqfulfillment.com"
          target="_blank"
          class="text-dark-75 poppins d-flex align-items-center"
        >
          <span class="mx-1 font-size-lg">Powered by: </span>
          <img width="68" src="/img/IQ-Hybrid-logo.png" />

          <!--          <span class="mx-1 font-size-lg">with</span>-->

          <!--          <inline-svg-->
          <!--            height="100%"-->
          <!--            width="15"-->
          <!--            src="https://api.iqhybrid.com/images/heart.svg"-->
          <!--          />-->
        </a>
        <span class="mx-1">&nbsp;&copy;&nbsp;</span>
        <span class="font-weight-bold mx-0">
          {{ new Date().getFullYear() }}
        </span>
      </div>
      <div :class="[$vuetify.rtl ? 'ml-lg-24' : 'mr-lg-24']">
        <router-link
          to="/privacy_policy"
          class="font-weight-bold text-hover-primary text-body"
          >{{ $t("privacy_policy") }}</router-link
        >

        <router-link
          class="font-weight-bold text-hover-primary text-body"
          :class="[$vuetify.rtl ? 'mr-6' : 'ml-6']"
          to="/terms_and_conditions"
          >{{ $t("terms") }} {{ $t("and_conditions") }}</router-link
        >
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooter",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
